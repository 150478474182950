// Sidebar.js
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import WindowIcon from '@mui/icons-material/Window';
import SpeedIcon from '@mui/icons-material/Speed';
import TimelineIcon from '@mui/icons-material/Timeline';
import GroupIcon from '@mui/icons-material/Group';
import BakeryDiningIcon from '@mui/icons-material/BakeryDining';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Euro from '@mui/icons-material/Euro';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import AddShoppingCart from '@mui/icons-material/AddShoppingCart';
import Timer from '@mui/icons-material/Timer';
import Vaccines from '@mui/icons-material/Vaccines';
import GroupRemove from '@mui/icons-material/GroupRemove';
import ManageSearch from '@mui/icons-material/ManageSearch';
import Battery3Bar from '@mui/icons-material/Battery3Bar';
import AutoGraph from '@mui/icons-material/AutoGraph';
import HighlightOff from '@mui/icons-material/HighlightOff';
import PublishedWithChanges from '@mui/icons-material/PublishedWithChanges';
import CurrencyExchange from '@mui/icons-material/CurrencyExchange';
import AppsIcon from '@mui/icons-material/Apps';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Button from '@mui/material/Button';
import { ReactComponent as DatabitesLogo } from '../../../../assets/images/logo_dashboard.svg';
import { ReactComponent as DatabitesLogoSmall } from '../../../../assets/images/logo_dashboard_small.svg';
import DropdownFilter from '../../../filter/DropdownFilter';
import axiosInstance from '../../../../services/axiosInstance';
import { FilterContext } from '../../../../provider/FilterProvider';
import useMediaQuery from '@mui/material/useMediaQuery';
import dayjs from 'dayjs'; // Importiere dayjs für Datumsmanipulation

const drawerWidth = 275;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const PermanentDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const Sidebar = ({ open, toggleDrawer, variant }) => {
  const { filterState, handleDatumChange } = useContext(FilterContext);
  const [datumOptions, setDatumOptions] = useState([]);
  const [openSections, setOpenSections] = useState({});
  const navigate = useNavigate();
  const location = useLocation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    fetchDatumOptions();
  }, []);

  const fetchDatumOptions = async () => {
    try {
      const datumResponse = await axiosInstance.get('stammdaten/unique-jahr-monat/');
      const allDatumOptions = datumResponse.data;

      // Bestimme den letzten Monat
      const lastMonth = dayjs().subtract(1, 'month').format('YYYY.MM');

      // Filtere die Datum-Optionen auf Monate bis zum letzten Monat (exklusive aktueller und zukünftiger Monate)
      const filteredDatum = allDatumOptions
        .filter((item) => dayjs(item, 'YYYY.MM').isBefore(dayjs().startOf('month'), 'month'))
        .sort((a, b) => dayjs(b, 'YYYY.MM').diff(dayjs(a, 'YYYY.MM'))); // Sortiere absteigend

      const datumOpts = filteredDatum.map((item) => ({ label: item, value: item }));

      setDatumOptions(datumOpts);

      // Setze den Standardwert für Datum auf den letzten Monat, wenn noch kein Wert ausgewählt ist
      if (!filterState.datum && datumOpts.length > 0) {
        const defaultDatum = datumOpts.find((opt) => opt.value === lastMonth) || datumOpts[0];
        handleDatumChange(defaultDatum);
      }
    } catch (error) {
      console.error('Fehler beim Abrufen der Datumsoptionen:', error);
    }
  };

  const handleClick = (section) => {
    setOpenSections((prev) => ({ ...prev, [section]: !prev[section] }));
  };

  const isMenuItemActive = (path) => location.pathname === path;
  const isSectionActive = (subItems) => subItems.some((item) => location.pathname === item.path);

  const menuItems = [
    {
      text: 'Übersicht',
      path: '/unternehmen/overview/cockpit',
      icon: <WindowIcon sx={{ color: 'primary.main' }} />,
      subItems: [],
    },
    {
      text: 'Performance',
      icon: <TimelineIcon sx={{ color: 'primary.main' }} />,
      subItems: [
        { text: 'Umsatz', path: '/unternehmen/performance/umsatz', icon: <Euro sx={{ color: 'primary.main' }} /> },
        { text: 'Kunden', path: '/unternehmen/performance/kundenanzahl', icon: <ShoppingCart sx={{ color: 'primary.main' }} /> },
        { text: 'Kundenbon', path: '/unternehmen/performance/kundenbon', icon: <AddShoppingCart sx={{ color: 'primary.main' }} /> },
        { text: 'Leistung', path: '/unternehmen/performance/leistung', icon: <Timer sx={{ color: 'primary.main' }} /> },
      ],
    },
    {
      text: 'Personal',
      icon: <GroupIcon sx={{ color: 'primary.main' }} />,
      subItems: [
        { text: 'Krank', path: '/unternehmen/personal/krankenquote', icon: <Vaccines sx={{ color: 'primary.main' }} /> },
        { text: 'Fluktuation', path: '/unternehmen/personal/fluktuation', icon: <GroupRemove sx={{ color: 'primary.main' }} /> },
        { text: 'Bedarf', path: '/unternehmen/personal/bedarf', icon: <ManageSearch sx={{ color: 'primary.main' }} /> },
        { text: 'Auslastung', path: '/unternehmen/personal/auslastung', icon: <Battery3Bar sx={{ color: 'primary.main' }} /> },
      ],
    },
    {
      text: 'Ware',
      icon: <BakeryDiningIcon sx={{ color: 'primary.main' }} />,
      subItems: [
        { text: 'Verkaufsmenge', path: '/unternehmen/ware/verkaufsmenge', icon: <WindowIcon sx={{ color: 'primary.main' }} /> },
        { text: 'Retoure', path: '/unternehmen/ware/retoure', icon: <AutoGraph sx={{ color: 'primary.main' }} /> },
      ],
    },
  ];

  // Sidebar-Inhalt
  const SidebarContent = (
    <Box
      className="db_sidbar_head"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        overflowY: 'auto',
        paddingBottom: isMobile ? theme.spacing(10) : 0, // Padding nur für kleine Bildschirme hinzufügen
      }}
    >
      <Box>
        {open ? <DatabitesLogo className="db_logo" /> : <DatabitesLogoSmall className="db_logo db_logo_small" />}
        <Typography
          variant="h4"
          className="db_sidebar_headline"
          sx={{ display: open ? 'block' : 'none' }}
        >
          Scorecard Unternehmen
        </Typography>
        <br />
        {open ? (
          <>
            <DropdownFilter
              label="Datum"
              options={datumOptions}
              value={filterState.datum}
              onChange={handleDatumChange}
              sx={{ marginBottom: 1 }} // Kleiner Abstand nach unten
            />
          </>
        ) : null}

        <List className="db_sidbar_menu">
          {menuItems.map((menuItem, index) => (
            <React.Fragment key={menuItem.text}>
              <ListItem
                disablePadding
                sx={{ display: 'block' }}
                className={
                  'db_sidebar_menu_item' +
                  (isSectionActive(menuItem.subItems) || isMenuItemActive(menuItem.path)
                    ? ' active'
                    : '')
                }
              >
                <ListItemButton
                  onClick={() => {
                    if (!open) {
                      toggleDrawer();
                    }
                    if (menuItem.subItems.length > 0) {
                      handleClick(menuItem.text);
                    } else {
                      navigate(menuItem.path);
                    }
                  }}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    borderRadius: '8px',
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                      color: 'primary.main',
                    }}
                  >
                    {menuItem.icon}
                  </ListItemIcon>
                  <ListItemText
                    className="db_sidebar_menu_item_text"
                    primary={menuItem.text}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                  {open && menuItem.subItems.length > 0 && (
                    openSections[menuItem.text] ? <ExpandLess /> : <ExpandMore />
                  )}
                </ListItemButton>
              </ListItem>
              {open && menuItem.subItems.length > 0 && (
                <Collapse
                  in={openSections[menuItem.text]}
                  timeout="auto"
                  unmountOnExit
                  className="db_sidebar_submenu"
                >
                  <List component="div" disablePadding>
                    {menuItem.subItems.map((subItem) => (
                      <ListItem
                        key={subItem.text}
                        disablePadding
                        sx={{ display: 'block' }}
                        className={
                          'db_sidebar_submenu_item ' +
                          (isMenuItemActive(subItem.path) ? 'active' : '')
                        }
                      >
                        <ListItemButton
                          onClick={() => navigate(subItem.path)}
                          sx={{
                            minHeight: 36,
                            justifyContent: open ? 'initial' : 'center',
                            pl: 7,
                            borderRadius: '8px',
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: 3,
                              justifyContent: 'center',
                              color: 'primary.main',
                            }}
                          >
                            {subItem.icon}
                          </ListItemIcon>
                          <ListItemText
                            className="db_sidebar_submenu_item_text"
                            primary={subItem.text}
                            sx={{ opacity: open ? 1 : 0 }}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          ))}
        </List>
      </Box>
      <Box
        className="db_sidbar_foot"
        sx={{
          display: 'flex',
          flexDirection: open ? 'row' : 'column', // Wechsel zwischen Zeilen- und Spaltenrichtung
          alignItems: 'center', // Zentriere Icons horizontal
          marginTop: 'auto', // Schiebe den Footer-Bereich nach unten
          padding: theme.spacing(2),
          gap: theme.spacing(2), // Abstand zwischen den Icons
          justifyContent: open ? 'space-between' : 'center', // Gleichmäßiger Abstand im offenen Zustand
        }}
      >
        {open ? (
          <Button
            className="db_all_apps_button"
            variant="outlined"
            startIcon={<AppsIcon sx={{ color: 'primary.main' }} />}
            onClick={() => navigate('/')}
          >
            <Typography sx={{ opacity: open ? 1 : 0 }}>Data Apps</Typography>
          </Button>
        ) : (
          <IconButton onClick={() => navigate('/')}>
            <AppsIcon sx={{ color: 'primary.main' }} />
          </IconButton>
        )}
        <IconButton onClick={toggleDrawer}>
          {open ? (
            <ChevronLeftIcon sx={{ color: 'primary.main' }} />
          ) : (
            <ChevronRightIcon sx={{ color: 'primary.main' }} />
          )}
        </IconButton>
      </Box>
    </Box>
  );

  return variant === 'temporary' ? (
    <MuiDrawer
      variant="temporary"
      open={open}
      onClose={toggleDrawer}
      className="db_sidebar"
      ModalProps={{
        keepMounted: true,
      }}
      PaperProps={{
        style: { width: drawerWidth, height: '100vh' },
      }}
    >
      {SidebarContent}
    </MuiDrawer>
  ) : (
    <PermanentDrawer variant="permanent" open={open} className="db_sidebar">
      {SidebarContent}
    </PermanentDrawer>
  );
};

export default Sidebar;
