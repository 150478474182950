import React, { useState, useEffect } from 'react';
import { Box, Typography, List, ListItem, ListItemText, CardHeader, ListItemIcon, CircularProgress, Divider, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, Card, CardContent, Switch } from '@mui/material';
import axiosInstance from '../../services/axiosInstance';
import CategoryIcon from '@mui/icons-material/Category';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import KompetenzMatrixHinzufuegen from './KompetenzMatrixHinzufuegen';
import KompetenzMatrixKonfiguration from './KompetenzMatrixKonfiguration';

const KompetenzMatrixListe = ({ refresh }) => {
    const [matrices, setMatrices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dialogOpen, setDialogOpen] = useState(false);  
    const [editDialogOpen, setEditDialogOpen] = useState(false);  
    const [selectedMatrix, setSelectedMatrix] = useState(null);  

    const fetchMatrices = async () => {
        try {
            const response = await axiosInstance.get('/personalentwicklung/kompetenz-matrix/');  // API-Endpunkt anpassen
            if (Array.isArray(response.data)) {
                setMatrices(response.data);
            } else if (response.data.results && Array.isArray(response.data.results)) {
                setMatrices(response.data.results);
            } else {
                console.error('Unerwartetes Antwortformat:', response.data);
            }
        } catch (error) {
            console.error('Fehler beim Laden der Kompetenzmatrizen:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchMatrices();
    }, [refresh]);

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        fetchMatrices();
    };

    const handleEditDialogOpen = (matrix) => {
        setSelectedMatrix(matrix);  
        setEditDialogOpen(true);  
    };

    const handleEditDialogClose = () => {
        setEditDialogOpen(false);
        fetchMatrices();
    };

    const handleDelete = async (id) => {
        const confirmed = window.confirm("Möchtest du diese Kompetenzmatrix wirklich löschen?");
        if (confirmed) {
            try {
                await axiosInstance.delete(`/personalentwicklung/kompetenz-matrix/${id}/`);
                setMatrices(matrices.filter(matrix => matrix.id !== id));
            } catch (error) {
                console.error('Fehler beim Löschen der Kompetenzmatrix:', error);
            }
        }
    };

    const handleToggleActive = async (matrix, event) => {
        event.stopPropagation();
        try {
            await axiosInstance.patch(`/personalentwicklung/kompetenz-matrix/${matrix.id}/`, { is_active: !matrix.is_active });
            setMatrices(matrices.map(m => m.id === matrix.id ? { ...m, is_active: !matrix.is_active } : m));
        } catch (error) {
            console.error('Fehler beim Ändern des Status:', error);
        }
    };

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                justifyContent: 'left',
                padding: 2,
            }}
        >
            <Card sx={{ width: '100%' }}>
                <CardHeader
                    title={
                        <Typography variant="h6" component="div">
                            Liste der Kompetenzmatrizen
                        </Typography>
                    }
                    action={
                        <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            startIcon={<AddCircleIcon />}
                            onClick={handleDialogOpen}
                        >
                            Kompetenzmatrix hinzufügen
                        </Button>
                    }
                />
                <CardContent>
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        {Array.isArray(matrices) && matrices.length > 0 ? (
                            matrices.map((matrix) => (
                                <React.Fragment key={matrix.id}>
                                    <ListItem>  
                                        <ListItemIcon>
                                            <CategoryIcon />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={matrix.name} 
                                            secondary={matrix.is_active ? "Aktiv" : "Passiv"} 
                                        />
                                        <Switch
                                            edge="end"
                                            checked={matrix.is_active}
                                            onChange={(e) => handleToggleActive(matrix, e)}
                                            inputProps={{ 'aria-label': 'Aktiv/Passiv umschalten' }}
                                        />
                                        <IconButton edge="end" aria-label="edit" onClick={(e) => {e.stopPropagation(); handleEditDialogOpen(matrix);}}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton edge="end" aria-label="delete" onClick={(e) => {e.stopPropagation(); handleDelete(matrix.id);}}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            ))
                        ) : (
                            <Typography variant="h6">Keine Kompetenzmatrizen gefunden</Typography>
                        )}
                    </List>
                </CardContent>
            </Card>
            
            <Dialog open={dialogOpen} onClose={handleDialogClose} maxWidth="md" fullWidth>
                <DialogTitle>Neue Kompetenzmatrix hinzufügen</DialogTitle>
                <DialogContent>
                    <KompetenzMatrixHinzufuegen open={dialogOpen} handleClose={handleDialogClose} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Schließen
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={editDialogOpen} onClose={handleEditDialogClose} maxWidth="md" fullWidth>
                <DialogTitle>Kompetenzmatrix Konfiguration</DialogTitle>
                <DialogContent>
                    {selectedMatrix && (
                        <KompetenzMatrixKonfiguration
                            open={editDialogOpen}
                            handleClose={handleEditDialogClose}
                            matrixId={selectedMatrix.id}
                            matrixName={selectedMatrix.name}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEditDialogClose} color="primary">
                        Schließen
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default KompetenzMatrixListe;
