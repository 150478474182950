import {Box, Dialog, DialogContent, DialogTitle, Typography} from "@mui/material";
import DropdownFilter from '../filter/DropdownFilter';
import React, {useContext, useState} from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {handleChangeTeig, handleChangeGeschmack} from "../../services/teigGeschmackService";
import {InfoBarContext} from "../../provider/InfoBar";

const ArtikelDetailPopup = ({open, onClose, artikel, teigOptions, geschmackOptions, setArtikel}) => {
  const {showError, showSuccess} = useContext(InfoBarContext);
  const [, setRefresh] = useState(0);

  // const handleTeigChange = async (teig) => {
  //   await handleChangeTeig(
  //     artikel.artikelnummer,
  //     setArtikel,
  //     teig,
  //     { showError, showSuccess }
  //   );
  //   setRefresh(prev => prev + 1);
  // };
  //
  // const handleGeschmackChange = async (geschmack) => {
  //   await handleChangeGeschmack(
  //     artikel.artikelnummer,
  //     setArtikel,
  //     geschmack,
  //     { showError, showSuccess }
  //   );
  //   console.log("should refresh")
  //   setRefresh(prev => prev + 1);
  // };

  if (artikel) {
    console.log("current artikel", artikel)
  }
  const fakeWareneinsatz = Math.floor(Math.random() * 100);
  const displayedText = artikel?.artikelbezeichnung.replace(/[a-zäöüß]+/gi, word =>
      word.toLowerCase().replace(/^./, c => c.toUpperCase())
  );

  return (
      <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
        <DialogTitle>Artikel Details
          <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
          >
            <CloseIcon/>
          </IconButton></DialogTitle>
        <DialogContent>
          {artikel ?
              <Box sx={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%'}}>
                <Typography
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: 'vertical',
                      wordBreak: 'break-word',
                      hyphens: 'auto',
                      WebkitHyphens: 'auto',
                      msHyphens: 'auto',
                      marginBottom: 1,
                      height: '4.5em',
                      fontSize: '1.1rem'
                    }}
                >
                  {displayedText}
                </Typography>
                <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: 1}}>
                  <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                    <Typography sx={{color: 'text.main', fontSize: '1rem'}}>Wareneinsatz:</Typography>
                    <Typography sx={{color: 'text.main', fontSize: '1rem'}}>{fakeWareneinsatz}</Typography>
                  </Box>
                  <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                    <Typography sx={{color: 'text.main', fontSize: '1rem'}}>Retoure:</Typography>
                    <Typography sx={{color: 'text.main', fontSize: '1rem'}}>{artikel.retoure}%</Typography>
                  </Box>
                  <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', gap: 2}}>
                    <Typography sx={{color: 'text.secondary', fontSize: '1rem', minWidth: '80px'}}>Geschmack:</Typography>
                    <Typography sx={{color: 'text.main', fontSize: '1rem'}}>{artikel.geschmack ? artikel.geschmack : "-"}</Typography>

                    {/*<DropdownFilter*/}
                    {/*    label=""*/}
                    {/*    options={geschmackOptions}*/}
                    {/*    value={artikel.geschmack}*/}
                    {/*    onChange={handleGeschmackChange}*/}
                    {/*    useNameAsLabel={false}*/}
                    {/*    small={true}*/}
                    {/*    noExplicitLabel={true}*/}
                    {/*/>*/}
                  </Box>
                  <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', gap: 2}}>
                    <Typography sx={{color: 'text.secondary', fontSize: '1rem', minWidth: '80px'}}>Teig:</Typography>
                    <Typography sx={{color: 'text.main', fontSize: '1rem'}}>{artikel.teig ? artikel.teig : "-"}</Typography>

                    {/*<DropdownFilter*/}
                    {/*    label=""*/}
                    {/*    options={teigOptions}*/}
                    {/*    value={artikel.teig}*/}
                    {/*    onChange={handleTeigChange}*/}
                    {/*    useNameAsLabel={false}*/}
                    {/*    small={true}*/}
                    {/*    noExplicitLabel={true}*/}
                    {/*/>*/}
                  </Box>
                  <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                    <Typography sx={{color: 'success.main', fontSize: '1rem'}}>Umsatz:</Typography>
                    <Typography sx={{color: 'success.main', fontSize: '1rem'}}>{artikel.umsatz} €</Typography>
                  </Box>
                </Box>
              </Box> : null}
        </DialogContent>
      </Dialog>
  );
};

export default ArtikelDetailPopup;