import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import logo from '../../../assets/images/logo.png'; // Korrigierter Logo-Import

// Festgelegte Farben für die Themen
const themeColors = {
  analytics: '#63AC73', // Grün
  personal: '#FF8C00',  // Orange
  qualitaet: '#D03B56', // Rot
  team: '#4B568F',      // Dunkelblau
  databites: '#CA6790', // Rosa
  sortimente: '#EFB258' // Gold
};

// Array der Themenfarben
const themeColorsArray = Object.values(themeColors);

// Zufälligen Radius innerhalb eines Bereichs erzeugen
const getRandomRadius = () => 250 + Math.random() * 200; // Zwischen 250 und 450 px

const Ball = () => {
  const randomX = Math.random() * window.innerWidth;
  const randomY = Math.random() * window.innerHeight;
  const delay = Math.random() * 0.3; // Verzögerung weiter reduziert
  const randomColor = themeColorsArray[
    Math.floor(Math.random() * themeColorsArray.length)
  ];
  const initialSize = Math.random() * 50 + 20;
  const duration = Math.random() * 0.5 + 0.5;

  return (
    <motion.div
      style={{
        width: `${initialSize}px`,
        height: `${initialSize}px`,
        borderRadius: '50%',
        backgroundColor: randomColor,
        position: 'absolute',
        top: randomY,
        left: randomX,
      }}
      initial={{ scale: 1, opacity: 1 }}
      animate={{
        x: window.innerWidth / 3 + randomX / 2,
        y: window.innerHeight / 2 - randomY,
        scale: 0.1,
        opacity: 0,
      }}
      transition={{
        duration: duration,
        delay: delay,
        ease: 'easeIn',
      }}
    />
  );
};

const Header = () => (
  <div
    style={{
      position: 'fixed',
      top: 0,
      width: '100%',
      height: '60px',
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0 20px',
      zIndex: 100,
    }}
  >
    <nav>
      <ul
        style={{
          display: 'flex',
          listStyle: 'none',
          margin: 0,
          padding: 0,
        }}
      >
        {Object.keys(themeColors).map((section) => (
          <li key={section} style={{ margin: '0 15px' }}>
            <a
              href={`#${section}`}
              style={{ color: '#737373', textDecoration: 'none' }}
            >
              {section.charAt(0).toUpperCase() + section.slice(1)}
            </a>
          </li>
        ))}
      </ul>
    </nav>
    <img src={logo} alt="Logo" style={{ height: '40px' }} />
  </div>
);

const Section = ({ id, title, content, color }) => (
  <section
    id={id}
    style={{
      minHeight: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      backgroundColor: color,
      color: 'white',
      padding: '40px 20px',
    }}
  >
    <div style={{ maxWidth: '800px' }}>
      <h1 style={{ fontSize: '3em', marginBottom: '20px' }}>{title}</h1>
      <p style={{ fontSize: '1.2em', lineHeight: '1.6' }}>{content}</p>
    </div>
  </section>
);

const FallingBallsPage = () => {
  const [balls, setBalls] = useState([]);
  const [showLogo, setShowLogo] = useState(false);

  const sections = [
    {
      id: 'analytics',
      title: 'Analytics',
      content:
        'Entdecke die Kraft datengetriebener Erkenntnisse. Mit unseren fortschrittlichen Analysetools verwandeln wir Rohdaten in umsetzbares Wissen.',
      color: themeColors.analytics,
    },
    {
      id: 'personal',
      title: 'Personal',
      content:
        'Stärken Sie Ihr Team mit einem personalisierten Ansatz. Wir fördern individuelles Wachstum und eine dynamische Kultur.',
      color: themeColors.personal,
    },
    {
      id: 'qualitaet',
      title: 'Qualität',
      content:
        'Exzellenz steht im Mittelpunkt unseres Handelns. Unser Engagement für Qualität stellt sicher, dass wir Produkte und Dienstleistungen liefern, die die Erwartungen übertreffen.',
      color: themeColors.qualitaet,
    },
    {
      id: 'team',
      title: 'Team',
      content:
        'Zusammenarbeit ist der Schlüssel. Unser Team arbeitet nahtlos zusammen und kombiniert vielfältige Fähigkeiten und Hintergründe.',
      color: themeColors.team,
    },
    {
      id: 'databites',
      title: 'Databites',
      content:
        'Datenhäppchen mit großer Wirkung. Databites liefert schnelle, verdauliche Einblicke, damit Sie agil und reaktionsfähig bleiben.',
      color: themeColors.databites,
    },
    {
      id: 'sortimente',
      title: 'Sortimente',
      content:
        'Für den Erfolg kuratiert. Unsere Sortimente sind darauf ausgelegt, den Bedürfnissen einer vielfältigen Kundschaft gerecht zu werden.',
      color: themeColors.sortimente,
    },
  ];

  useEffect(() => {
    const ballsArray = Array.from({ length: 150 }, (_, i) => <Ball key={i} />);
    setBalls(ballsArray);

    const logoTimer = setTimeout(() => {
      setShowLogo(true);
    }, 1000);

    return () => {
      clearTimeout(logoTimer);
    };
  }, []);

  return (
    <div style={{ overflowX: 'hidden' }}>
      <Header />
      <div
        style={{
          position: 'relative',
          height: '100vh',
          overflow: 'hidden',
        }}
      >
        {balls}
        {showLogo && (
          <motion.img
            src={logo}
            alt="Logo"
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '300px',
            }}
            initial={{ scale: 0, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 1 }}
          />
        )}
      </div>
      {sections.map((section) => (
        <Section
          key={section.id}
          id={section.id}
          title={section.title}
          content={section.content}
          color={section.color}
        />
      ))}
    </div>
  );
};

export default FallingBallsPage;
