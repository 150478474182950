import React, {useState} from 'react';
import {Box, Button, CircularProgress, TextField, Typography} from '@mui/material';
import axiosInstance from "../../../services/axiosInstance";
import SortimentLayout from "../../../components/layout/Sortiment/SortimentLayout";

const Upload = () => {
    const [title, setTitle] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [previewUrl, setPreviewUrl] = useState(null);

    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            // Create preview URL
            const url = URL.createObjectURL(file);
            setPreviewUrl(url);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!selectedFile || !title) {
            setMessage('Please select a file and provide a title');
            return;
        }

        const formData = new FormData();
        formData.append('image', selectedFile);
        formData.append('title', title);

        setLoading(true);
        setMessage('');

        try {
            const response = await axiosInstance.post('/pictures/upload-hetzner/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
            console.log("response", response);
            setMessage('Upload successful!');
            // Reset form
            setTitle('');
            setSelectedFile(null);
            setPreviewUrl(null);
        } catch (error) {
            console.error('Upload failed:', error);
            setMessage(error.response?.data?.error || 'Upload failed');
        } finally {
            setLoading(false);
        }
    };

    return (
        <SortimentLayout>
            <Box sx={{maxWidth: 600, margin: 'auto', padding: 2}}>
                <Typography variant="h4" gutterBottom>
                    Upload Image
                </Typography>

                <form onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        label="Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        margin="normal"
                        required
                    />

                    <Box sx={{my: 2}}>
                        <input
                            accept="image/*"
                            type="file"
                            id="image-upload"
                            onChange={handleFileSelect}
                            style={{display: 'none'}}
                        />
                        <label htmlFor="image-upload">
                            <Button variant="contained" component="span">
                                Select Image
                            </Button>
                        </label>
                    </Box>

                    {previewUrl && (
                        <Box sx={{my: 2}}>
                            <img
                                src={previewUrl}
                                alt="Preview"
                                style={{
                                    maxWidth: '100%',
                                    maxHeight: '200px',
                                    objectFit: 'contain'
                                }}
                            />
                        </Box>
                    )}

                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={loading || !selectedFile || !title}
                        sx={{mt: 2}}
                    >
                        {loading ? <CircularProgress size={24}/> : 'Upload'}
                    </Button>

                    {message && (
                        <Typography
                            color={message.includes('successful') ? 'success.main' : 'error.main'}
                            sx={{mt: 2}}
                        >
                            {message}
                        </Typography>
                    )}
                </form>
            </Box>
        </SortimentLayout>
    );
};

export default Upload;