// ManualCard.jsx
import React from 'react';
import { Card, CardActionArea, Badge, CardContent, Typography } from '@mui/material';

const ManualCard = ({ manual, onSelect }) => {
    return (
        <Card sx={{ width: 300, height: 150 }}>
            <CardActionArea
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                }}
                onClick={() => onSelect(manual)}
            >
                <Badge badgeContent={manual.badge} color="primary" invisible={!manual.badge} />
                <CardContent sx={{ textAlign: 'left', width: '100%' }}>
                    <Typography variant="subtitle1">{manual.title}</Typography>
                    <Typography variant="body2" color="textSecondary">
                        {manual.description}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default ManualCard;
