// src/pages/unternehmenwoche/cockpit.jsx

import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, Typography, Stack, Card } from '@mui/material';
import Layout from '../../../components/layout/Scorecards/Woche/Unternehmen/UnternehmenMonatWoche';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import BarChartIcon from '@mui/icons-material/BarChart';
import DataCard from '../../../components/card/scorecardsmonat/DataCardMonat';
import { FilterContext } from '../../../provider/FilterProvider';
import axiosInstance from '../../../services/axiosInstance';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import DropdownFilter from '../../../components/filter/DropdownFilter';
import { calculatePerformanceData } from '../../../utils/calculationPerformance';
import VariableTable from '../../../components/table/VariableTable';
import { useTheme } from '@mui/material/styles';
import { formatPercentage, tausenderTrennung, formatCurrency } from "../../../utils/mathStuff";

dayjs.extend(isoWeek);
dayjs.extend(customParseFormat);

const Cockpit = () => {
    const { filterState, handleDatumWocheChange } = useContext(FilterContext);
    const [performanceData, setPerformanceData] = useState({
        umsatz: 0,
        umsatzVJ: 0,
        umsatzAbweichungVW: 0,
        umsatzAbweichung6W: 0,
        umsatzAbweichungVJ: 0,
        kundenanzahl: 0,
        kundenanzahlVJ: 0,
        kundenanzahlAbweichungVW: 0,
        kundenanzahlAbweichung6W: 0,
        kundenanzahlAbweichungVJ: 0,
        leistung: 0,
        leistungVJ: 0,
        kundenbon: 0,
        kundenbonVJ: 0,
        chartData: [],
    });
    const [datumOptions, setDatumOptions] = useState([]);
    const [retourenData, setRetourenData] = useState([]);
    const [warengruppenData, setWarengruppenData] = useState([]);
    const theme = useTheme();

    const [performanceLast6Weeks, setPerformanceLast6Weeks] = useState([]);

    // Neuer State für die aufgelaufenen Monatsdaten
    const [monatsDaten, setMonatsDaten] = useState({
        umsatz: 0,
        kundenanzahl: 0,
        kundenbon: 0,
        leistung: 0,
        umsatzVJ: 0,
        kundenanzahlVJ: 0,
        kundenbonVJ: 0,
        leistungVJ: 0,
        umsatzAbweichungVJ: 0,
        kundenanzahlAbweichungVJ: 0,
        kundenbonAbweichungVJ: 0,
        leistungAbweichungVJ: 0,
    });

    useEffect(() => {
        fetchDatumOptions();
    }, []);

    useEffect(() => {
        if (filterState.datumWoche) {
            fetchPerformanceData();
            fetchPerformanceDataLast6Weeks();
            fetchMonatsDaten(); // Neue Funktion zum Abrufen der Monatsdaten
            fetchWarengruppenData();
            fetchRetourenData();
        }
    }, [filterState]);

    const fetchDatumOptions = async () => {
        try {
            const response = await axiosInstance.get('/stammdaten/unique-jahr-woche/');
            setDatumOptions(response.data.map(item => ({ label: item, value: item })));
        } catch (error) {
            console.error('Error while fetching datum options:', error);
        }
    };

    const fetchPerformanceData = async () => {
        try {
            const response = await axiosInstance.get('/scorecard_week/performance-woche/', {
                params: {
                    jahrKw: filterState.datumWoche?.value,
                    ...(filterState.bestehendeFlaeche && { vorjahr: true }),
                },
            });

            const allFilialenData = response.data.results;

            // Berechnungen für die Performance-Daten
            const {
                umsatzSumme,
                umsatzVJSumme,
                umsatzVMSumme,
                umsatz6MSumme,
                umsatzVWSumme,
                umsatz6WSumme,
                umsatzAbweichungVM,
                umsatzAbweichung6M,
                umsatzAbweichungVJ,
                umsatzAbweichungVW,
                umsatzAbweichung6W,
                kundenanzahlSumme,
                kundenanzahlVJSumme,
                kundenanzahlVMSumme,
                kundenanzahl6MSumme,
                kundenanzahlVWSumme,
                kundenanzahl6WSumme,
                kundenanzahlAbweichungVM,
                kundenanzahlAbweichung6M,
                kundenanzahlAbweichungVJ,
                kundenanzahlAbweichungVW,
                kundenanzahlAbweichung6W,
                leistung,
                leistungVJ,
                kundenbon,
                kundenbonVJ,
                kundenbonVM,
                kundenbon6M,
                kundenbonVW,
                kundenbon6W,
                kundenbonAbweichungVM,
                kundenbonAbweichung6M,
                kundenbonAbweichungVJ,
                kundenbonAbweichungVW,
                kundenbonAbweichung6W,
            } = calculatePerformanceData(allFilialenData);

            setPerformanceData({
                umsatz: umsatzSumme,
                umsatzVJ: umsatzVJSumme,
                umsatzAbweichungVW,
                umsatzAbweichung6W,
                umsatzAbweichungVJ,
                kundenanzahl: kundenanzahlSumme,
                kundenanzahlVJ: kundenanzahlVJSumme,
                kundenanzahlAbweichungVW,
                kundenanzahlAbweichung6W,
                kundenanzahlAbweichungVJ,
                leistung,
                leistungVJ,
                kundenbon,
                kundenbonVJ,
                chartData: allFilialenData.map((item) => ({
                    umsatz: item.umsatz || 0,
                    kundenanzahl: item.kundenanzahl || 0,
                    leistung: item.leistung || 0,
                    kundenbon: item.kundenbon || 0,
                })),
            });
        } catch (error) {
            console.error('Error fetching performance data:', error);
        }
    };

    // Neue Funktion zum Abrufen der aufgelaufenen Monatsdaten
    const fetchMonatsDaten = async () => {
        try {
            const today = dayjs();
            let startDate;
            let endDate = today.subtract(1, 'day').format('YYYY-MM-DD');

            if (today.date() === 1) {
                // Wenn heute der erste Tag des Monats ist, den gesamten Vormonat abfragen
                startDate = today.subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
                endDate = today.subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
            } else {
                // Ansonsten vom ersten Tag des aktuellen Monats bis gestern
                startDate = today.startOf('month').format('YYYY-MM-DD');
            }

            // Berechnung des gleichen Zeitraums im Vorjahr
            const startDateLastYear = dayjs(startDate).subtract(1, 'year').format('YYYY-MM-DD');
            const endDateLastYear = dayjs(endDate).subtract(1, 'year').format('YYYY-MM-DD');

            // Abfrage der aktuellen Daten und der Vorjahresdaten parallel
            const [currentDataResponse, lastYearDataResponse] = await Promise.all([
                axiosInstance.get('/fact/aggregated/gesamtsumme/', {
                    params: {
                        startdatum: startDate,
                        enddatum: endDate,
                        ...(filterState.bestehendeFlaeche && { vorjahr: true }),
                    },
                }),
                axiosInstance.get('/fact/aggregated/gesamtsumme/', {
                    params: {
                        startdatum: startDateLastYear,
                        enddatum: endDateLastYear,
                        ...(filterState.bestehendeFlaeche && { vorjahr: true }),
                    },
                }),
            ]);

            const currentData = currentDataResponse.data;
            const lastYearData = lastYearDataResponse.data;

            // Aktuelle Daten
            const umsatz = currentData.total_umsatz || 0;
            const kundenanzahl = currentData.total_kundenanzahl || 0;
            const produktivstunden = currentData.total_produktivstunden || 0;

            // Vorjahresdaten
            const umsatzVJ = lastYearData.total_umsatz || 0;
            const kundenanzahlVJ = lastYearData.total_kundenanzahl || 0;
            const produktivstundenVJ = lastYearData.total_produktivstunden || 0;

            // Berechnungen gemäß Ihrer Anweisungen
            const kundenbon = kundenanzahl > 0 ? umsatz / kundenanzahl : 0;
            const leistung = produktivstunden > 0 ? umsatz / produktivstunden : 0;

            const kundenbonVJ = kundenanzahlVJ > 0 ? umsatzVJ / kundenanzahlVJ : 0;
            const leistungVJ = produktivstundenVJ > 0 ? umsatzVJ / produktivstundenVJ : 0;

            // Prozentuale Veränderungen
            const umsatzAbweichungVJ = umsatzVJ > 0 ? ((umsatz - umsatzVJ) / umsatzVJ) * 100 : 0;
            const kundenanzahlAbweichungVJ = kundenanzahlVJ > 0 ? ((kundenanzahl - kundenanzahlVJ) / kundenanzahlVJ) * 100 : 0;
            const kundenbonAbweichungVJ = kundenbonVJ > 0 ? ((kundenbon - kundenbonVJ) / kundenbonVJ) * 100 : 0;
            const leistungAbweichungVJ = leistungVJ > 0 ? ((leistung - leistungVJ) / leistungVJ) * 100 : 0;

            setMonatsDaten({
                umsatz,
                kundenanzahl,
                kundenbon,
                leistung,
                umsatzVJ,
                kundenanzahlVJ,
                kundenbonVJ,
                leistungVJ,
                umsatzAbweichungVJ,
                kundenanzahlAbweichungVJ,
                kundenbonAbweichungVJ,
                leistungAbweichungVJ,
            });

        } catch (error) {
            console.error('Error fetching monthly data:', error);
        }
    };

    // Hilfsfunktion, um die letzten 6 Wochen basierend auf dem ausgewählten Datum zu berechnen
    const getLast6Weeks = (currentWeek) => {
        const last6Weeks = [];
        const [year, week] = currentWeek.split('.').map(Number);
        let date = dayjs(`${year}-W${String(week).padStart(2, '0')}-1`, 'YYYY-[W]WW-E');
        for (let i = 0; i < 6; i++) {
            last6Weeks.unshift(date.format('YYYY.WW')); // Verwenden von unshift, um die Wochen in der richtigen Reihenfolge zu erhalten
            date = date.subtract(1, 'week');
        }
        return last6Weeks;
    };

    const fetchPerformanceDataLast6Weeks = async () => {
        const currentWeek = filterState.datumWoche?.value || dayjs().format('YYYY.WW');
        const last6Weeks = getLast6Weeks(currentWeek);

        try {
            const responses = await Promise.all(
                last6Weeks.map(week => axiosInstance.get('/scorecard_week/performance-woche/', {
                    params: {
                        jahrKw: week,
                        ...(filterState.bestehendeFlaeche && { vorjahr: true }),
                    },
                }))
            );

            const last6WeeksData = responses.map((response, index) => {
                const umsatzSumme = response.data.results
                    .filter(filiale => filiale.umsatz > 1)
                    .reduce((sum, filiale) => sum + (filiale.umsatz || 0), 0);

                const kundenanzahlSumme = response.data.results
                    .reduce((sum, filiale) => sum + (filiale.kundenanzahl || 0), 0);

                const produktivstundenSumme = response.data.results
                    .reduce((sum, filiale) => sum + (filiale.produktivstunden || 0), 0);

                const kundenbon = kundenanzahlSumme > 0 ? umsatzSumme / kundenanzahlSumme : 0;
                const leistung = produktivstundenSumme > 0 ? umsatzSumme / produktivstundenSumme : 0;

                return {
                    week: last6Weeks[index],
                    umsatz: umsatzSumme,
                    kundenanzahl: kundenanzahlSumme,
                    kundenbon: kundenbon,
                    leistung: leistung
                };
            });

            setPerformanceLast6Weeks(last6WeeksData);
        } catch (error) {
            console.error('Error fetching data for last 6 weeks:', error);
        }
    };

    const fetchRetourenData = async () => {
        try {
            const response = await axiosInstance.get('/scorecard_week/ware-warengruppe-woche/', {
                params: {
                    jahrKw: filterState.datumWoche?.value,
                    ...(filterState.bestehendeFlaeche && { vorjahr: true }),
                },
            });

            const aggregatedRetourenData = response.data.results.reduce((acc, item) => {
                const existingEntry = acc.find(entry => entry.warengruppennummer === item.warengruppennummer);
                const retourwert = item.retourwert || 0;
                const retourwertA = item.retourwertA || 0;
                const retourwertBC = item.retourwertBC || 0;
                const lieferwert = item.lieferwert || 0;
                const lieferwertA = item.lieferwertA || 0;
                const lieferwertBC = item.lieferwertBC || 0;
                const korrekturwert = item.korrekturwert || 0;
                const korrekturwertA = item.korrekturwertA || 0;
                const korrekturwertBC = item.korrekturwertBC || 0;

                if (existingEntry) {
                    existingEntry.retourwert += retourwert;
                    existingEntry.retourwertA += retourwertA;
                    existingEntry.retourwertBC += retourwertBC;
                    existingEntry.lieferwert += lieferwert;
                    existingEntry.lieferwertA += lieferwertA;
                    existingEntry.lieferwertBC += lieferwertBC;
                    existingEntry.korrekturwert += korrekturwert;
                    existingEntry.korrekturwertA += korrekturwertA;
                    existingEntry.korrekturwertBC += korrekturwertBC;
                } else {
                    acc.push({
                        warengruppennummer: item.warengruppennummer,
                        warengruppe: item.warengruppe,
                        retourwert,
                        retourwertA,
                        retourwertBC,
                        lieferwert,
                        lieferwertA,
                        lieferwertBC,
                        korrekturwert,
                        korrekturwertA,
                        korrekturwertBC,
                    });
                }

                return acc;
            }, []);

            // Nach der Akkumulation die Retourenquoten berechnen
            const retourenDataWithRates = aggregatedRetourenData.map(item => {
                const { retourwert, lieferwert, korrekturwert, retourwertA, lieferwertA, korrekturwertA, retourwertBC, lieferwertBC, korrekturwertBC } = item;
                const totalLieferwert = lieferwert + korrekturwert;
                const totalLieferwertA = lieferwertA + korrekturwertA;
                const totalLieferwertBC = lieferwertBC + korrekturwertBC;

                const retourQuote = totalLieferwert > 0 ? ((retourwert / totalLieferwert) * 100).toFixed(2) : '0.00';
                const retourQuoteA = totalLieferwertA > 0 ? ((retourwertA / totalLieferwertA) * 100).toFixed(2) : '0.00';
                const retourQuoteBC = totalLieferwertBC > 0 ? ((retourwertBC / totalLieferwertBC) * 100).toFixed(2) : '0.00';

                return {
                    ...item,
                    retourQuote,
                    retourQuoteA,
                    retourQuoteBC,
                };
            });

            setRetourenData(retourenDataWithRates);
        } catch (error) {
            console.error('Error fetching retouren data:', error);
        }
    };

    const fetchWarengruppenData = async () => {
        try {
            const response = await axiosInstance.get('/scorecard_week/ware-warengruppe-woche/', {
                params: {
                    jahrKw: filterState.datumWoche?.value,
                    ...(filterState.bestehendeFlaeche && { vorjahr: true }),
                },
            });

            // Struktur erstellen, um kumulative Werte zu speichern
            const aggregatedData = response.data.results.reduce((acc, item) => {
                const existingEntry = acc.find(entry => entry.warengruppennummer === item.warengruppennummer);
                const verkaufsmenge = item.verkaufsmenge || 0;
                const verkaufsmengeVJ = item.verkaufsmengeVJ || 0;

                if (existingEntry) {
                    // Verkaufsmenge und Verkaufsmenge des Vorjahres akkumulieren
                    existingEntry.verkaufsmenge += verkaufsmenge;
                    existingEntry.verkaufsmengeVJ += verkaufsmengeVJ;
                } else {
                    // Neue Warengruppe hinzufügen
                    acc.push({
                        warengruppennummer: item.warengruppennummer,
                        warengruppe: item.warengruppe,
                        verkaufsmenge,
                        verkaufsmengeVJ,
                    });
                }

                return acc;
            }, []);

            // Nach der Akkumulation die Steigerung berechnen
            const finalData = aggregatedData.map((entry) => {
                const { verkaufsmenge, verkaufsmengeVJ } = entry;

                // Prozentuale Steigerung berechnen
                const steigerungProzent = verkaufsmengeVJ > 0
                    ? ((verkaufsmenge - verkaufsmengeVJ) / verkaufsmengeVJ) * 100
                    : 0;

                return {
                    ...entry,
                    steigerungProzent: steigerungProzent.toFixed(2),
                };
            });

            // Top 6 Warengruppen auswählen
            const top6Warengruppen = finalData
                .sort((a, b) => b.verkaufsmenge - a.verkaufsmenge)
                .slice(0, 6);

            setWarengruppenData(top6Warengruppen);
        } catch (error) {
            console.error('Error fetching warengruppen data:', error);
        }
    };

    // Formatierung der Daten für die Anzeige
    const filteredRetourenData = retourenData.filter(item => parseFloat(item.retourQuote) !== 0);

    const formattedWarengruppenData = warengruppenData.map(item => ({
        ...item,
        verkaufsmenge: item.verkaufsmenge ? tausenderTrennung(item.verkaufsmenge, 0) : '0',
        verkaufsmengeVJ: item.verkaufsmengeVJ ? tausenderTrennung(item.verkaufsmengeVJ, 0) : '0',
        steigerungProzent: item.steigerungProzent ? `${item.steigerungProzent.replace('.', ',')} %` : '0,00 %',
    }));

    const formattedRetourenData = filteredRetourenData.map(item => ({
        ...item,
        retourQuote: item.retourQuote ? `${item.retourQuote.replace('.', ',')} %` : '0,00 %',
        retourQuoteA: item.retourQuoteA ? `${item.retourQuoteA.replace('.', ',')} %` : '0,00 %',
        retourQuoteBC: item.retourQuoteBC ? `${item.retourQuoteBC.replace('.', ',')} %` : '0,00 %',
    }));

    const columns = [
        { accessorKey: 'warengruppe', header: 'Warengruppe' },
        { accessorKey: 'verkaufsmenge', header: 'Verkaufsmenge' },
        { accessorKey: 'verkaufsmengeVJ', header: 'Verkaufsmenge VJ' },
        { accessorKey: 'steigerungProzent', header: 'Abweichung VJ %' },
    ];

    const retourenColumns = [
        { accessorKey: 'warengruppe', header: 'Warengruppe' },
        { accessorKey: 'retourQuote', header: 'Retourquote Gesamt' },
        { accessorKey: 'retourQuoteA', header: 'Retourquote A' },
        { accessorKey: 'retourQuoteBC', header: 'Retourquote BC' },
    ];

    return (
        <Layout>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', mt: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <PageHeader
                        title="Wochen Scorecards Unternehmen"
                        subtitle="Wöchentliche Übersicht der Kernkennzahlen für das gesamte Unternehmen, um relevante Fokus-Themen abzuleiten und in die strategische Planung zu integrieren."
                        Icon={BarChartIcon}
                    />
                </Box>

                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <DropdownFilter
                        label="Datum"
                        options={datumOptions}
                        value={filterState.datumWoche}
                        onChange={handleDatumWocheChange}
                    />
                </Box>
            </Box>

            <Grid container spacing={3} mt={4}>
                {/* Erste Spalte */}
                <Grid item xs={12} md={3}>
                    <Stack direction="column" spacing={2} sx={{ minHeight: '200px' }}>
                        {/* Umsatz */}
                        <DataCard
                            category="Umsatz"
                            value={performanceData.umsatz ? tausenderTrennung(performanceData.umsatz.toFixed(2), 0) : '0,00'}
                            sector="performance"
                            subHeaders={[
                                `${performanceData.umsatzAbweichungVW?.toFixed(2) || '0,00'}% zur Vorwoche`,
                                `${performanceData.umsatzAbweichung6W?.toFixed(2) || '0,00'}% zum 6-Wochen-Schnitt`,
                                `${performanceData.umsatzAbweichungVJ?.toFixed(2) || '0,00'}% zum Vorjahr`
                            ]}
                            chartData={performanceLast6Weeks.map(item => item.umsatz)}
                        />

                        {/* Kundenanzahl */}
                        <DataCard
                            category="Kundenanzahl"
                            sector="performance"
                            value={performanceData.kundenanzahl ? tausenderTrennung(performanceData.kundenanzahl.toFixed(2), 0) : '0,00'}
                            subHeaders={[
                                `${performanceData.kundenanzahlAbweichungVW?.toFixed(2) || '0,00'}% zur Vorwoche`,
                                `${performanceData.kundenanzahlAbweichung6W?.toFixed(2) || '0,00'}% zum 6-Wochen-Schnitt`,
                                `${performanceData.kundenanzahlAbweichungVJ?.toFixed(2) || '0,00'}% zum Vorjahr`
                            ]}
                            chartData={performanceLast6Weeks.map(item => item.kundenanzahl)}
                        />

                        {/* Kundenbon */}
                        <DataCard
                            category="Kundenbon"
                            sector="performance"
                            value={performanceData.kundenbon ? formatCurrency(performanceData.kundenbon) : '0,00 €'}
                            subHeaders={[
                                `${formatCurrency(performanceData.kundenbonVJ)} im Vorjahr`,
                                `${performanceData.kundenbonVJ > 0 ? ((performanceData.kundenbon - performanceData.kundenbonVJ) / performanceData.kundenbonVJ * 100).toFixed(2).replace('.', ',') : '0,00'}% zum Vorjahr`
                            ]}
                            chartData={performanceLast6Weeks.map(item => item.kundenbon)}
                        />

                        {/* Leistung */}
                        <DataCard
                            category="Leistung"
                            sector="performance"
                            value={performanceData.leistung ? performanceData.leistung.toFixed(0) : '0,00'}
                            subHeaders={[
                                `${performanceData.leistungVJ?.toFixed(2) || '0,00'} € im Vorjahr`,
                                `${performanceData.leistungVJ > 0 ? ((performanceData.leistung - performanceData.leistungVJ) / performanceData.leistungVJ * 100).toFixed(2) : '0,00'}% zum Vorjahr`
                            ]}
                            chartData={performanceLast6Weeks.map(item => item.leistung)}
                        />
                    </Stack>
                </Grid>

                {/* Zweite Spalte - Neue Karten */}
                <Grid item xs={12} md={3}>
                    <Stack direction="column" spacing={2} sx={{ minHeight: '200px' }}>
                        {/* Umsatz aufgelaufener Monat */}
                        <DataCard
                            category="Umsatz aufgelaufener Monat"
                            value={monatsDaten.umsatz ? tausenderTrennung(monatsDaten.umsatz.toFixed(2), 0) : '0,00'}
                            sector="performance"
                            subHeaders={[
                                `${monatsDaten.umsatzVJ ? tausenderTrennung(monatsDaten.umsatzVJ.toFixed(2), 0) : '0,00'} im Vorjahr`,
                                `${monatsDaten.umsatzAbweichungVJ ? monatsDaten.umsatzAbweichungVJ.toFixed(2).replace('.', ',') : '0,00'}% zum Vorjahr`
                            ]}
                            chartData={[]}
                        />

                        {/* Kundenanzahl aufgelaufener Monat */}
                        <DataCard
                            category="Kundenanzahl aufgelaufener Monat"
                            value={monatsDaten.kundenanzahl ? tausenderTrennung(monatsDaten.kundenanzahl.toFixed(0), 0) : '0'}
                            sector="performance"
                            subHeaders={[
                                `${monatsDaten.kundenanzahlVJ ? tausenderTrennung(monatsDaten.kundenanzahlVJ.toFixed(0), 0) : '0'} im Vorjahr`,
                                `${monatsDaten.kundenanzahlAbweichungVJ ? monatsDaten.kundenanzahlAbweichungVJ.toFixed(2).replace('.', ',') : '0,00'}% zum Vorjahr`
                            ]}
                            chartData={[]}
                        />

                        {/* Kundenbon aufgelaufener Monat */}
                        <DataCard
                            category="Kundenbon aufgelaufener Monat"
                            value={monatsDaten.kundenbon ? formatCurrency(monatsDaten.kundenbon) : '0,00 €'}
                            sector="performance"
                            subHeaders={[
                                `${monatsDaten.kundenbonVJ ? formatCurrency(monatsDaten.kundenbonVJ) : '0,00 €'} im Vorjahr`,
                                `${monatsDaten.kundenbonAbweichungVJ ? monatsDaten.kundenbonAbweichungVJ.toFixed(2).replace('.', ',') : '0,00'}% zum Vorjahr`
                            ]}
                            chartData={[]}
                        />

                        {/* Leistung aufgelaufener Monat */}
                        <DataCard
                            category="Leistung aufgelaufener Monat"
                            value={monatsDaten.leistung ? monatsDaten.leistung.toFixed(0) : '0,00'}
                            sector="performance"
                            subHeaders={[
                                `${monatsDaten.leistungVJ ? monatsDaten.leistungVJ.toFixed(0) : '0,00'} € im Vorjahr`,
                                `${monatsDaten.leistungAbweichungVJ ? monatsDaten.leistungAbweichungVJ.toFixed(2).replace('.', ',') : '0,00'}% zum Vorjahr`
                            ]}
                            chartData={[]}
                        />
                    </Stack>
                </Grid>

                {/* Dritte Spalte */}
                <Grid item xs={12} md={6}>
                    <Stack direction="column" spacing={3}>
                        {/* Tabelle mit Verkaufsdaten nach Warengruppe */}
                        <Card
                            sx={{
                                padding: theme.spacing(2),
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                backdropFilter: 'blur(10px)',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <BarChartIcon />
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            fontWeight: theme.typography.h5.fontWeight,
                                            fontSize: theme.typography.h6.fontSize,
                                            color: theme.palette.primary.main,
                                        }}
                                    >
                                        Verkaufsmenge nach Warengruppe
                                    </Typography>
                                </Stack>
                            </Box>

                            <Box>
                                <VariableTable
                                    columns={columns}
                                    data={formattedWarengruppenData}
                                    onRowClick={(row) => console.log('Clicked row:', row)}
                                />
                            </Box>
                        </Card>

                        {/* Tabelle für Retourenwerte */}
                        <Card
                            sx={{
                                padding: theme.spacing(2),
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                backdropFilter: 'blur(10px)',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <BarChartIcon />
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            fontWeight: theme.typography.h5.fontWeight,
                                            fontSize: theme.typography.h6.fontSize,
                                            color: theme.palette.primary.main,
                                        }}
                                    >
                                        Retourenwerte nach Warengruppe
                                    </Typography>
                                </Stack>
                            </Box>

                            <Box>
                                <VariableTable
                                    columns={retourenColumns}
                                    data={formattedRetourenData}
                                    onRowClick={(row) => console.log('Clicked row:', row)}
                                />
                            </Box>
                        </Card>
                    </Stack>
                </Grid>
            </Grid>
        </Layout>
    );
};

export default Cockpit;
