// Overview.jsx

import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Typography,
  IconButton,
} from '@mui/material';
import axiosInstance from '../../../services/axiosInstance';
import Layout from '../../../components/layout/Personalentwicklung/Personalentwicklung';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import HailOutlinedIcon from '@mui/icons-material/HailOutlined';
import { useNavigate } from 'react-router-dom';
import { FilterContext } from '../../../provider/FilterProvider'; // Importiere den FilterContext
import Button from '@mui/material/Button';

// **Import der Icons**
import EditIcon from '@mui/icons-material/Edit';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';

const Overview = () => {
  const [employees, setEmployees] = useState([]);
  const [kompetenzMatrices, setKompetenzMatrices] = useState([]);
  const [kompetenzAssignments, setKompetenzAssignments] = useState([]);
  const [filterState] = useState({ filiale: null });
  const [openAssignDialog, setOpenAssignDialog] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedMatrix, setSelectedMatrix] = useState('');
  const [editingAssignment, setEditingAssignment] = useState(null);

  const navigate = useNavigate();
  const { handleEmployeeChange } = useContext(FilterContext);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axiosInstance.get('personaldaten/stammdaten-mitarbeiter/', {
          params: {
            filiale: filterState.filiale?.label,
          },
        });

        setEmployees(response.data.results || response.data);
      } catch (error) {
        console.error('Fehler beim Laden der Mitarbeiterdaten:', error);
      }
    };

    const fetchKompetenzMatrices = async () => {
      try {
        const response = await axiosInstance.get('/personalentwicklung/kompetenz-matrix/');
        setKompetenzMatrices(response.data.results || response.data);
      } catch (error) {
        console.error('Fehler beim Laden der Kompetenzmatrizen:', error);
      }
    };

    const fetchKompetenzAssignments = async () => {
      try {
        const response = await axiosInstance.get('/personalentwicklung/mitarbeiter-kompetenz/');
        setKompetenzAssignments(response.data.results || response.data);
      } catch (error) {
        console.error('Fehler beim Laden der Zuweisungen:', error);
      }
    };

    fetchEmployees();
    fetchKompetenzMatrices();
    fetchKompetenzAssignments();
  }, [filterState]);

  const handleAssignMatrix = (employee) => {
    const assignment = kompetenzAssignments.find((a) => a.employee === employee.mitarbeiterId);
    setSelectedEmployee(employee);
    setSelectedMatrix(assignment ? assignment.matrix : '');
    setEditingAssignment(assignment || null);
    setOpenAssignDialog(true);
  };

  const handleSaveAssignment = async () => {
    try {
      const data = {
        employee: selectedEmployee.mitarbeiterId,
        matrix: selectedMatrix,
      };

      if (editingAssignment) {
        // Aktualisieren der bestehenden Zuweisung
        await axiosInstance.patch(`/personalentwicklung/mitarbeiter-kompetenz/${editingAssignment.id}/`, data);
      } else {
        // Erstellen einer neuen Zuweisung
        await axiosInstance.post('/personalentwicklung/mitarbeiter-kompetenz/', data);
      }

      // Aktualisieren der Zuweisungen
      const response = await axiosInstance.get('/personalentwicklung/mitarbeiter-kompetenz/');
      setKompetenzAssignments(response.data.results || response.data);

      setOpenAssignDialog(false);
      setSelectedEmployee(null);
      setSelectedMatrix('');
      setEditingAssignment(null);
    } catch (error) {
      if (error.response) {
        console.error('Fehler beim Zuweisen der Kompetenzmatrix:', error.response.data);
      } else {
        console.error('Fehler beim Zuweisen der Kompetenzmatrix:', error);
      }
    }
  };

  const handleViewKompetenz = (employeeId) => {
    handleEmployeeChange(employeeId); // Setze den ausgewählten Mitarbeiter im Kontext
    navigate('/personalentwicklung/overview/mitarbeiter'); // Navigiere zur Mitarbeiter-Seite
  };

  return (
    <Layout>
      <Box sx={{ flexGrow: 1, p: 3, maxWidth: 1200, margin: '0 auto', bgcolor: 'transparent' }}>
        <PageHeader
          title="Kompetenzübersicht"
          subtitle="Verwalte die Kompetenzmatrizen deiner Mitarbeiter."
          Icon={HailOutlinedIcon}
        />
        <TableContainer component={Paper} sx={{ mt: 4 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Mitarbeiter-ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Stammkostenstelle</TableCell>
                <TableCell>Eintritt</TableCell>
                <TableCell>Kompetenzmatrix</TableCell>
                <TableCell>Aktionen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {employees.map((employee) => {
                const assignment = kompetenzAssignments.find((a) => a.employee === employee.mitarbeiterId);
                const matrixName = assignment
                  ? kompetenzMatrices.find((m) => m.id === assignment.matrix)?.name || 'Unbekannte Matrix'
                  : 'Keine Matrix zugewiesen';

                return (
                  <TableRow key={employee.mitarbeiterId}>
                    <TableCell>{employee.mitarbeiterId}</TableCell>
                    <TableCell>{employee.name}</TableCell>
                    <TableCell>{employee.stammkostenstelle}</TableCell>
                    <TableCell>
                      {employee.eintritt
                        ? new Date(employee.eintritt).toLocaleDateString('de-DE')
                        : 'N/A'}
                    </TableCell>
                    <TableCell>{matrixName}</TableCell>
                    <TableCell>
                      {/* Icon für Matrix zuweisen/Bearbeiten */}
                      <IconButton
                        color="primary"
                        onClick={() => handleAssignMatrix(employee)}
                        sx={{ mr: 1 }}
                      >
                        <EditIcon />
                      </IconButton>
                      {/* Icon für Kompetenzübersicht anzeigen */}
                      <IconButton
                        color="primary"
                        onClick={() => handleViewKompetenz(employee.mitarbeiterId)}
                      >
                        <ArrowForwardOutlinedIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Dialog zum Zuweisen einer Kompetenzmatrix */}
      <Dialog open={openAssignDialog} onClose={() => setOpenAssignDialog(false)}>
        <DialogTitle>
          {editingAssignment ? 'Zuweisung bearbeiten' : 'Kompetenzmatrix zuweisen'}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="matrix-label">Kompetenzmatrix</InputLabel>
            <Select
              labelId="matrix-label"
              value={selectedMatrix}
              onChange={(e) => setSelectedMatrix(e.target.value)}
              label="Kompetenzmatrix"
            >
              {kompetenzMatrices.map((matrix) => (
                <MenuItem key={matrix.id} value={matrix.id}>
                  {matrix.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAssignDialog(false)}>Abbrechen</Button>
          <Button onClick={handleSaveAssignment} variant="contained" disabled={!selectedMatrix}>
            {editingAssignment ? 'Speichern' : 'Zuweisen'}
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};

export default Overview;
