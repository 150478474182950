import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';

const KompetenzFeldDialog = ({ open, onClose, feldData, setFeldData, handleSaveFeld }) => (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>{feldData.id ? 'Kompetenzfeld bearbeiten' : 'Neues Kompetenzfeld anlegen'}</DialogTitle>
        <DialogContent>
            <TextField
                label="Name des Kompetenzfelds"
                fullWidth
                value={feldData.name}
                onChange={(e) => setFeldData({ ...feldData, name: e.target.value })}
                margin="normal"
                required
            />
            <TextField
                label="Beschreibung"
                fullWidth
                value={feldData.beschreibung}
                onChange={(e) => setFeldData({ ...feldData, beschreibung: e.target.value })}
                margin="normal"
                multiline
                rows={2}
            />
            <TextField
                label="Maximale Skala"
                type="number"
                fullWidth
                value={feldData.skala_max}
                onChange={(e) => setFeldData({ ...feldData, skala_max: e.target.value })}
                margin="normal"
                required
                inputProps={{ min: 1 }}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleSaveFeld} variant="contained" color="primary">
                Speichern
            </Button>
            <Button onClick={onClose} variant="outlined">
                Abbrechen
            </Button>
        </DialogActions>
    </Dialog>
);

export default KompetenzFeldDialog;
