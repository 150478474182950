import React, { useEffect, useState, useContext } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Grid, Typography, Card, CardHeader, CardContent, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import axiosInstance from '../../../services/axiosInstance';
import { FilterContext } from '../../../provider/FilterProvider';
import BasicLines from '../../charts/LineChartSameYAxis';  // Importiere die BasicLines Komponente
import VariableTable from "../../table/VariableTable";  // Verwende die VariableTable-Komponente
import { formatPercentage, roundToDecimals, tausenderTrennung } from "../../../utils/mathStuff";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const TAPerformanceLeistung = ({ open, setOpen }) => {
    const { filterState } = useContext(FilterContext);
    const [tableData, setTableData] = useState([]);
    const [columns, setColumns] = useState([]); // State für die Spalten
    const [chartData, setChartData] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (filterState.filiale && filterState.datum) {
            fetchTableData();
        }
    }, [filterState.filiale, filterState.datum]);

    const fetchTableData = async () => {
        setLoading(true);
        try {
            const endDate = dayjs(`${filterState.datum?.value}-01`, 'YYYY-MM-DD');
            const startDate = endDate.subtract(6, 'month');

            const responseFiliale = await axiosInstance.get('scorecards_month/performance-monat/', {
                params: {
                    startdatum: startDate.format('YYYY-MM-DD'),
                    enddatum: endDate.format('YYYY-MM-DD'),
                    filiale: filterState.filiale?.label,
                }
            });

            const responseUnternehmen = await axiosInstance.get('scorecards_month/performance-unternehmen-monat/', {
                params: {
                    startdatum: startDate.format('YYYY-MM-DD'),
                    enddatum: endDate.format('YYYY-MM-DD'),
                }
            });

            if (Array.isArray(responseFiliale.data.results) && Array.isArray(responseUnternehmen.data.results)) {
                const months = responseFiliale.data.results.map((item) => dayjs(item.datum).format('YYYY.MM'));

                // Set columns for the table (Datum)
                setColumns([
                    { accessorKey: 'kennzahl', header: 'Kennzahl' },
                    ...months.map((month) => ({
                        accessorKey: month,
                        header: month,
                    }))
                ]);

                // Set rows for the table (Kennzahlen)
                setTableData([
                    {
                        kennzahl: "Leistung Filiale €",
                        ...responseFiliale.data.results.reduce((acc, item) => {
                            const month = dayjs(item.datum).format('YYYY.MM');
                            acc[month] = roundToDecimals(item.leistung, 0);
                            return acc;
                        }, {})
                    },
                    {
                        kennzahl: "Leistung Unternehmen €",
                        ...responseUnternehmen.data.results.reduce((acc, item) => {
                            const month = dayjs(item.datum).format('YYYY.MM');
                            acc[month] = item.leistung !== 0 ? roundToDecimals(item.leistung, 0) : '-';
                            return acc;
                        }, {})
                    },
                    {
                        kennzahl: "% Differenz",
                        ...responseFiliale.data.results.reduce((acc, item, index) => {
                            const filialeLeistung = item.leistung;
                            const unternehmenLeistung = responseUnternehmen.data.results[index]?.leistung || 0;
                            const differenz = unternehmenLeistung
                                ? ((filialeLeistung - unternehmenLeistung) / unternehmenLeistung * 100).toFixed(2)
                                : '-';
                            const month = dayjs(item.datum).format('YYYY.MM');
                            acc[month] = differenz !== '-' ? `${tausenderTrennung(differenz, 2)} %` : '-';
                            return acc;
                        }, {})
                    }
                ]);

                // Prepare data for the chart
                const chartDataFormatted = responseFiliale.data.results.map((item) => ({
                    x: item.datum,
                    y: item.leistung,
                    label: 'Filiale'
                })).concat(responseUnternehmen.data.results.map((item) => ({
                    x: item.datum,
                    y: item.leistung,
                    label: 'Unternehmen'
                })));

                setChartData(chartDataFormatted);
            }
        } catch (error) {
            console.error('Error fetching table data:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle>
                Tiefenanalyse Leistung
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                padding: '0px',
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                backdropFilter: 'blur(10px)',
                            }}
                        >
                            <CardHeader
                                title={
                                    <Typography variant="h6" component="div">
                                        Verlauf der Stundenleistung in den letzten 6 Monaten im Vergleich zum Unternehmensdurchschnitt
                                    </Typography>
                                }
                                action={
                                    <Tooltip
                                        title={
                                            <>
                                                Leistungsverlauf - Informationen<br /><br />
                                                Dieses Diagramm zeigt den Verlauf der Leistung der ausgewählten Filiale im Vergleich zum Gesamtunternehmen.<br /><br />
                                                <strong>Filiale:</strong> Die dunklere Linie zeigt die Leistung der Filiale über die Zeit.<br />
                                                <strong>Unternehmen:</strong> Die hellere Linie repräsentiert die Leistung des gesamten Unternehmens.<br /><br />
                                                So kannst du Trends und Unterschiede zwischen der Filiale und dem Unternehmen erkennen.
                                            </>
                                        }
                                    >
                                        <IconButton>
                                            <InfoOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                }
                            />
                            <CardContent sx={{ flexGrow: 1 }}>
                                <BasicLines data={chartData} />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                padding: '0px',
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                backdropFilter: 'blur(10px)',
                            }}
                        >
                            <CardHeader
                                title={
                                    <Typography variant="h6" component="div">
                                        Letzten 6 Monate im Unternehmensvergleich
                                    </Typography>
                                }
                            />
                            <CardContent sx={{ flexGrow: 1 }}>
                                {loading ? (
                                    <div>Loading...</div>
                                ) : (
                                    <VariableTable
                                        columns={columns}
                                        data={tableData}
                                    />
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default TAPerformanceLeistung;
