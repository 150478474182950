import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Alert,
    CircularProgress,
    IconButton,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    MenuItem,
    Divider,
    Chip,
    Card,
    CardContent
} from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import PeopleIcon from '@mui/icons-material/People';
import Layout from '../../../components/layout/Filialfeed/Filialfeed';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import axiosInstance from '../../../services/axiosInstance';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const visibilityOptions = ['Alle', 'Spezifische Filialen', 'Verkaufsgebiete'];

const PostList = () => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [tags, setTags] = useState([]);
    const [tagsLoading, setTagsLoading] = useState(true);
    const [tagsError, setTagsError] = useState(null);

    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [editPost, setEditPost] = useState(null);
    const [saving, setSaving] = useState(false);

    // Zustände für den Vote-Dialog
    const [voteDialogOpen, setVoteDialogOpen] = useState(false);
    const [voteDialogPollId, setVoteDialogPollId] = useState(null);
    const [voteDialogPostId, setVoteDialogPostId] = useState(null);
    const [pollVotes, setPollVotes] = useState([]);
    const [pollVotesLoading, setPollVotesLoading] = useState(false);
    const [pollVotesError, setPollVotesError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [postsRes, tagsRes] = await Promise.all([
                    axiosInstance.get('/filialfeed/posts/'),
                    axiosInstance.get('/filialfeed/tags/')
                ]);
                setPosts(postsRes.data.results || postsRes.data);
                const tagData = tagsRes.data.results || tagsRes.data;
                setTags(tagData);
                setLoading(false);
                setTagsLoading(false);
            } catch (err) {
                console.error('Fehler beim Laden der Posts oder Tags:', err);
                setError('Fehler beim Laden der Daten');
                setLoading(false);
                setTagsLoading(false);
            }
        };
        fetchData();
    }, []);

    const futurePosts = posts.filter(post => new Date(post.publish_date) > new Date());
    const currentPosts = posts.filter(post => new Date(post.publish_date) <= new Date());

    const handleDelete = async (id) => {
        if (window.confirm('Diesen Post wirklich löschen?')) {
            try {
                await axiosInstance.delete(`/filialfeed/posts/${id}/`);
                setPosts(prev => prev.filter(p => p.id !== id));
            } catch (err) {
                console.error('Fehler beim Löschen des Posts:', err);
                alert('Fehler beim Löschen des Posts.');
            }
        }
    };

    const handleEdit = (post) => {
        setEditPost({ ...post, tag_id: post.tag ? post.tag.id : null });
        setEditDialogOpen(true);
    };

    const handleEditDialogClose = () => {
        setEditDialogOpen(false);
        setEditPost(null);
    };

    const handleEditSave = async () => {
        if (!editPost) return;
        setSaving(true);
        const { id, title, content, sender, tag_id, visibility, publish_date } = editPost;
        const updatedPost = {
            title,
            content,
            sender,
            tag_id,
            visibility,
            publish_date
        };

        try {
            await axiosInstance.put(`/filialfeed/posts/${id}/`, updatedPost);
            setPosts(prev => prev.map(p => p.id === id ? { ...p, ...updatedPost, tag: tags.find(t => t.id === tag_id) } : p));
            setEditDialogOpen(false);
            setEditPost(null);
        } catch (err) {
            console.error('Fehler beim Bearbeiten des Posts:', err);
            alert('Fehler beim Bearbeiten des Posts.');
        } finally {
            setSaving(false);
        }
    };

    // Funktion, um den Vote-Dialog zu öffnen
    const handleOpenVoteDialog = async (pollId, postId) => {
        setVoteDialogOpen(true);
        setVoteDialogPollId(pollId);
        setVoteDialogPostId(postId);
        setPollVotesLoading(true);
        setPollVotesError(null);

        try {
            const response = await axiosInstance.get('/filialfeed/poll-votes/', { params: { poll: pollId } });
            setPollVotes(response.data.results || response.data);
        } catch (err) {
            console.error('Fehler beim Laden der Votes:', err);
            setPollVotesError('Fehler beim Laden der Votes.');
        } finally {
            setPollVotesLoading(false);
        }
    };

    const handleCloseVoteDialog = () => {
        setVoteDialogOpen(false);
        setPollVotes([]);
        setVoteDialogPollId(null);
        setVoteDialogPostId(null);
        setPollVotesError(null);
        setPollVotesLoading(false);
    };

    const selectedPost = voteDialogPostId ? posts.find(p => p.id === voteDialogPostId) : null;

    return (
        <Layout>
            <Box sx={{ p:3 }}>
                <PageHeader
                    title="Post Liste"
                    subtitle="Hier sehen Sie alle Posts. Sie können Posts bearbeiten, löschen, geplante Posts einsehen und die Abstimmungen ansehen."
                />

                {loading || tagsLoading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
                        <CircularProgress />
                    </Box>
                ) : (error || tagsError) ? (
                    <Alert severity="error">{error || tagsError}</Alert>
                ) : (
                    <>
                        <Typography variant="h6" sx={{ mt:2 }}>
                            Zukünftige (Geplante) Posts
                        </Typography>
                        {futurePosts.length === 0 ? (
                            <Typography variant="body2">Keine zukünftigen Posts vorhanden.</Typography>
                        ) : (
                            futurePosts.map(post => (
                                <Box key={post.id} sx={{display:'flex', alignItems:'center', justifyContent:'space-between', borderBottom:'1px solid #ddd', p:1, mb:1}}>
                                    <Box>
                                        <Typography variant="subtitle1">{post.title}</Typography>
                                        <Typography variant="caption">Sichtbarkeit: {post.visibility}</Typography><br/>
                                        <Typography variant="caption">Veröffentlichung: {new Date(post.publish_date).toLocaleString()}</Typography><br/>
                                        {post.tag && <Chip label={`Tag: ${post.tag.name}`} size="small" sx={{mt:1}} />}
                                    </Box>
                                    <Box>
                                        <IconButton onClick={() => handleEdit(post)} color="primary"><EditIcon /></IconButton>
                                        <IconButton onClick={() => handleDelete(post.id)} color="secondary"><DeleteIcon /></IconButton>
                                    </Box>
                                </Box>
                            ))
                        )}

                        <Divider sx={{my:2}} />

                        <Typography variant="h6" sx={{ mt:2 }}>
                            Aktuelle / Vergangene Posts
                        </Typography>
                        {currentPosts.length === 0 ? (
                            <Typography variant="body2">Keine aktuellen oder vergangenen Posts.</Typography>
                        ) : (
                            currentPosts.map(post => {
                                const publishDate = new Date(post.publish_date);
                                const formattedDate = publishDate.toLocaleDateString('de-DE', {
                                    day: 'numeric',
                                    month: 'long',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                });

                                return (
                                    <Box key={post.id} sx={{display:'flex', alignItems:'center', justifyContent:'space-between', borderBottom:'1px solid #ddd', p:1, mb:1}}>
                                        <Box>
                                            <Typography variant="subtitle1">{post.title}</Typography>
                                            <Typography variant="caption">Sichtbarkeit: {post.visibility}</Typography><br/>
                                            <Typography variant="caption">Veröffentlicht am: {formattedDate}</Typography><br/>
                                            {post.tag && <Chip label={`Tag: ${post.tag.name}`} size="small" sx={{mt:1}} />}
                                        </Box>
                                        <Box>
                                            <IconButton onClick={() => handleEdit(post)} color="primary"><EditIcon /></IconButton>
                                            <IconButton onClick={() => handleDelete(post.id)} color="secondary"><DeleteIcon /></IconButton>
                                            {post.poll && (
                                                <IconButton onClick={() => handleOpenVoteDialog(post.poll.id, post.id)} color="info">
                                                    <PeopleIcon />
                                                </IconButton>
                                            )}
                                        </Box>
                                    </Box>
                                );
                            })
                        )}
                    </>
                )}
            </Box>

            {/* Edit Dialog */}
            <Dialog open={editDialogOpen} onClose={handleEditDialogClose} fullWidth maxWidth="sm">
                <DialogTitle>Post bearbeiten</DialogTitle>
                {editPost && (
                    <DialogContent>
                        <TextField
                            label="Titel"
                            variant="outlined"
                            fullWidth
                            value={editPost.title || ''}
                            onChange={(e) => setEditPost(prev => ({...prev, title:e.target.value}))}
                            sx={{ mb:2 }}
                            required
                        />

                        <Typography variant="subtitle2" sx={{ mb:1 }}>Inhalt</Typography>
                        <ReactQuill
                            value={editPost.content || ''}
                            onChange={(value) => setEditPost(prev => ({...prev, content:value}))}
                            modules={{
                                toolbar: [
                                    [{ header: [1, 2, false] }],
                                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                    [{ list: 'ordered' }, { list: 'bullet' }],
                                    ['link', 'image'],
                                    ['clean'],
                                ],
                            }}
                            style={{ marginBottom: '16px' }}
                        />

                        <TextField
                            label="Absender"
                            variant="outlined"
                            fullWidth
                            value={editPost.sender || ''}
                            onChange={(e) => setEditPost(prev => ({...prev, sender:e.target.value}))}
                            sx={{ mb:2 }}
                            required
                            InputProps={{
                                readOnly: true
                            }}
                        />

                        <TextField
                            label="Tag"
                            variant="outlined"
                            fullWidth
                            select
                            value={editPost.tag_id || ''}
                            onChange={(e) => setEditPost(prev => ({...prev, tag_id:e.target.value}))}
                            sx={{ mb:2 }}
                            required
                        >
                            {tags.map((tag) => (
                                <MenuItem key={tag.id} value={tag.id}>
                                    {tag.name}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            label="Sichtbarkeit"
                            variant="outlined"
                            fullWidth
                            select
                            value={editPost.visibility || 'Alle'}
                            onChange={(e) => setEditPost(prev => ({...prev, visibility:e.target.value}))}
                            sx={{ mb:2 }}
                            required
                        >
                            {visibilityOptions.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            label="Veröffentlichungsdatum"
                            type="datetime-local"
                            fullWidth
                            value={editPost.publish_date ? editPost.publish_date.slice(0,16) : ''}
                            onChange={(e) => {
                                setEditPost(prev => ({...prev, publish_date: e.target.value + ':00'}));
                            }}
                            InputLabelProps={{ shrink: true }}
                            sx={{ mb:2 }}
                            required
                        />

                    </DialogContent>
                )}
                <DialogActions>
                    <Button onClick={handleEditDialogClose} disabled={saving}>Abbrechen</Button>
                    <Button onClick={handleEditSave} variant="contained" color="primary" disabled={saving}>
                        {saving ? 'Speichere...' : 'Speichern'}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Vote Dialog */}
            <Dialog open={voteDialogOpen} onClose={handleCloseVoteDialog} fullWidth maxWidth="sm">
                <DialogTitle>Abstimmungsübersicht</DialogTitle>
                <DialogContent>
                    {pollVotesLoading ? (
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <CircularProgress />
                        </Box>
                    ) : pollVotesError ? (
                        <Alert severity="error">{pollVotesError}</Alert>
                    ) : (
                        // Wir haben jetzt poll_id, option_id in pollVotes
                        // und können diese verwenden, um Votes per Option zu gruppieren
                        selectedPost && selectedPost.poll && (
                            <>
                                <Box sx={{ mb:2 }}>
                                    <Typography variant="h6">{selectedPost.poll.question}</Typography>
                                    {selectedPost.poll.options.map(opt => (
                                        <Typography key={opt.id} variant="body2" sx={{ pl:2 }}>
                                            {opt.option_text} ({opt.votes_count})
                                        </Typography>
                                    ))}
                                </Box>
                                <Divider sx={{my:2}} />
                                {selectedPost.poll.options.map(opt => {
                                    const votesForOption = pollVotes.filter(v => v.option_id === opt.id);
                                    return (
                                        <Box key={opt.id} sx={{ mb:2 }}>
                                            <Typography variant="body1"><strong>{opt.option_text}:</strong></Typography>
                                            {votesForOption.length === 0 ? (
                                                <Typography variant="body2" sx={{ pl:2 }}>Keine Stimmen für diese Option.</Typography>
                                            ) : (
                                                votesForOption.map(vote => (
                                                    <Typography key={vote.id} variant="body2" sx={{ mb:1, pl:2 }}>
                                                        <strong>{vote.user}</strong> hat für <strong>{vote.option_text}</strong> gestimmt.
                                                    </Typography>
                                                ))
                                            )}
                                        </Box>
                                    );
                                })}
                            </>
                        )
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseVoteDialog} color="primary">Schließen</Button>
                </DialogActions>
            </Dialog>
        </Layout>
    );
};

export default PostList;
