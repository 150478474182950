import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, ListItem, ListItemText, DialogActions, Button, Box, Typography, List, Alert, IconButton, Divider } from '@mui/material';
import axiosInstance from '../../services/axiosInstance';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import KompetenzFeldDialog from './KompetenzFeldDialog';

const KompetenzMatrixKonfiguration = ({ open, handleClose, matrixId, matrixName }) => {
    const [felder, setFelder] = useState([]);
    const [feldData, setFeldData] = useState({ name: '', beschreibung: '', skala_max: 5 });
    const [openFeldDialog, setOpenFeldDialog] = useState(false);
    const [error, setError] = useState(null);
    const [editingFeld, setEditingFeld] = useState(null);

    useEffect(() => {
        if (open && matrixId) {
            // Kompetenzfelder für die ausgewählte Kompetenzmatrix abrufen
            axiosInstance.get(`/personalentwicklung/kompetenz-feld/?matrix=${matrixId}`)
                .then(response => {
                    if (Array.isArray(response.data)) {
                        setFelder(response.data);
                    } else if (Array.isArray(response.data.results)) {
                        setFelder(response.data.results);
                    } else {
                        console.error('Unerwartetes Antwortformat:', response.data);
                        setFelder([]);
                    }
                })
                .catch(error => {
                    console.error('Fehler beim Laden der Kompetenzfelder:', error);
                    setFelder([]);
                });
        }
    }, [open, matrixId]);
    
    

    const handleAddFeld = () => {
        if (felder.length >= 6) {
            setError('Es können maximal 6 Kompetenzfelder hinzugefügt werden.');
            return;
        }
        setFeldData({ name: '', beschreibung: '', skala_max: 5 });
        setEditingFeld(null);
        setOpenFeldDialog(true);
    };

    const handleSaveFeld = async () => {
        if (!feldData.name.trim() || !feldData.skala_max) {
            setError('Name und maximale Skala sind erforderlich.');
            return;
        }
        setError(null);
        if (editingFeld) {
            // Kompetenzfeld aktualisieren
            try {
                const response = await axiosInstance.patch(`/personalentwicklung/kompetenz-feld/${editingFeld.id}/`, {
                    name: feldData.name,
                    beschreibung: feldData.beschreibung,
                    skala_max: feldData.skala_max,
                    matrix: matrixId
                });
                setFelder(felder.map(feld => feld.id === editingFeld.id ? response.data : feld));
                setEditingFeld(null);
                setFeldData({ name: '', beschreibung: '', skala_max: 5 });
                setOpenFeldDialog(false);
            } catch (error) {
                console.error('Fehler beim Aktualisieren des Kompetenzfelds:', error);
                setError('Fehler beim Aktualisieren des Kompetenzfelds.');
            }
        } else {
            // Kompetenzfeld erstellen
            try {
                const response = await axiosInstance.post('/personalentwicklung/kompetenz-feld/', {
                    name: feldData.name,
                    beschreibung: feldData.beschreibung,
                    skala_max: feldData.skala_max,
                    matrix: matrixId
                });
                setFelder([...felder, response.data]);
                setFeldData({ name: '', beschreibung: '', skala_max: 5 });
                setOpenFeldDialog(false);
            } catch (error) {
                console.error('Fehler beim Hinzufügen des Kompetenzfelds:', error);
                setError('Fehler beim Hinzufügen des Kompetenzfelds.');
            }
        }
    };

    const handleEditFeld = (feld) => {
        setEditingFeld(feld);
        setFeldData({ name: feld.name, beschreibung: feld.beschreibung, skala_max: feld.skala_max });
        setOpenFeldDialog(true);
    };

    const handleDeleteFeld = async (feldId) => {
        try {
            await axiosInstance.delete(`/personalentwicklung/kompetenz-feld/${feldId}/`);
            setFelder(felder.filter(feld => feld.id !== feldId));
        } catch (error) {
            console.error('Fehler beim Löschen des Kompetenzfelds:', error);
        }
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogTitle>Kompetenzmatrix Konfiguration - {matrixName}</DialogTitle>
                <DialogContent>
                    {error && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            {error}
                        </Alert>
                    )}
                    <Box>
                        <Typography variant="h6" gutterBottom>Kompetenzfelder</Typography>
                        <List>
                            {felder.map((feld) => (
                                <React.Fragment key={feld.id}>
                                    <ListItem
                                        secondaryAction={
                                            <>
                                                <IconButton edge="end" aria-label="edit" onClick={() => handleEditFeld(feld)}>
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteFeld(feld.id)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </>
                                        }
                                    >
                                        <ListItemText
                                            primary={`${feld.name} (Maximale Skala: ${feld.skala_max})`}
                                            secondary={feld.beschreibung}
                                        />
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            ))}
                        </List>
                        {felder.length < 6 && (
                            <Box
                                sx={{
                                    border: '2px dashed #ddd',
                                    borderRadius: '8px',
                                    padding: '16px',
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                    color: '#888',
                                    mt: 2,
                                    width: '100%',
                                    '&:hover': { backgroundColor: '#f5f5f5' },
                                    boxSizing: 'border-box',
                                }}
                                onClick={handleAddFeld}
                            >
                                <AddIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                                Neues Kompetenzfeld anlegen
                            </Box>
                        )}
                        {felder.length >= 6 && (
                            <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                                Maximale Anzahl von 6 Kompetenzfeldern erreicht.
                            </Typography>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained">Schließen</Button>
                </DialogActions>
            </Dialog>

            {/* Kompetenzfeld-Dialog */}
            <KompetenzFeldDialog
                open={openFeldDialog}
                onClose={() => {
                    setOpenFeldDialog(false);
                    setEditingFeld(null);
                    setFeldData({ name: '', beschreibung: '', skala_max: 5 });
                    setError(null);
                }}
                feldData={feldData}
                setFeldData={setFeldData}
                handleSaveFeld={handleSaveFeld}
            />
        </>
    );
};

export default KompetenzMatrixKonfiguration;
