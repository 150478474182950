// src/components/layout/FilterstateAnzeigen.jsx

import React, { useContext } from 'react';
import { FilterContext } from '../../provider/FilterProvider';
import { Box, Typography } from '@mui/material';

const FilterStatus = () => {
    const { filterState } = useContext(FilterContext);
    const {  datum } = filterState;

    return (
        <Box
            sx={{
                display: 'flex',
                gap: '20px',
                fontSize: '16px',
                boxSizing: 'border-box',
                padding: '10px 20px',
                backgroundColor: 'transparent', // Hintergrund entfernen
                // Positionierung entfernen, damit es nicht überlappt
            }}
        >
            <Typography variant="subtitle1">
                {datum?.value || 'Nicht ausgewählt'}
            </Typography>
        </Box>
    );
};

export default FilterStatus;
