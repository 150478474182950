import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
} from '@mui/material';
import ChecklistIcon from '@mui/icons-material/Checklist';
import Layout from '../../../components/layout/Qualität/Qualität';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import axiosInstance from '../../../services/axiosInstance';

const Analyse = () => {
  const [ratings, setRatings] = useState([]);
  const [artikelBerichte, setArtikelBerichte] = useState([]);
  const [todos, setTodos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingTodos, setLoadingTodos] = useState(true);

  const fetchRatings = async () => {
    try {
      const response = await axiosInstance.get('/qualitaet/ratings/');
      const ratingsData = Array.isArray(response.data)
        ? response.data
        : response.data.results || [];
      setRatings(ratingsData);
    } catch (error) {
      console.error('Fehler beim Abrufen der Ratings:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchArtikelBerichte = async () => {
    try {
      const response = await axiosInstance.get('/qualitaet/artikel-tagesberichte/');
      const artikelBerichteData = Array.isArray(response.data)
        ? response.data
        : response.data.results || [];
      setArtikelBerichte(artikelBerichteData);
    } catch (error) {
      console.error('Fehler beim Abrufen der Artikel-Tagesberichte:', error);
    }
  };

  const fetchTodos = async () => {
    try {
      const response = await axiosInstance.get('/qualitaet/todos/');
      const todosData = Array.isArray(response.data)
        ? response.data
        : response.data.results || [];
      setTodos(todosData);
    } catch (error) {
      console.error('Fehler beim Abrufen der To-Dos:', error);
    } finally {
      setLoadingTodos(false);
    }
  };

  const updateTodo = async (todoId, updatedTodo) => {
    try {
      await axiosInstance.put(`/qualitaet/todos/${todoId}/`, updatedTodo);
      // Nach dem Update die To-Do-Liste aktualisieren
      fetchTodos();
    } catch (error) {
      console.error('Fehler beim Aktualisieren des To-Dos:', error);
    }
  };

  useEffect(() => {
    fetchRatings();
    fetchArtikelBerichte();
    fetchTodos();
  }, []);

  if (loading) {
    return (
      <Layout>
        <Box sx={{ p: 3 }}>
          <CircularProgress />
        </Box>
      </Layout>
    );
  }

  return (
    <Layout>
      <Grid container spacing={2} sx={{ p: 3 }}>
        <Grid item xs={12}>
          <PageHeader
            title="Analyse und Doku Produktion"
            subtitle="Nutze die Analysen der durchgeführten Filialchecks, um wertvolle Einblicke zu gewinnen und gezielt Verbesserungen voranzutreiben."
            Icon={ChecklistIcon}
          />
        </Grid>

        {/* Artikel-Tagesberichte */}
        <Grid item xs={12}>
          <Typography variant="h6">Artikel-Tagesberichte</Typography>
          {artikelBerichte.length > 0 ? (
            <TableContainer component={Paper} sx={{ mb: 2 }}>
              <Table aria-label="Artikel Tagesberichte Tabelle">
                <TableHead>
                  <TableRow>
                    <TableCell><strong>Datum</strong></TableCell>
                    <TableCell><strong>Artikel</strong></TableCell>
                    <TableCell><strong>Warengruppe</strong></TableCell>
                    <TableCell><strong>Anzahl Meldungen</strong></TableCell>
                    <TableCell><strong>Details</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {artikelBerichte.map((bericht) => (
                    <TableRow key={bericht.id}>
                      <TableCell>{bericht.datum}</TableCell>
                      <TableCell>{bericht.artikel_name}</TableCell>
                      <TableCell>{bericht.warengruppe_name}</TableCell>
                      <TableCell>{bericht.anzahl_meldungen}</TableCell>
                      <TableCell>{bericht.details || 'N/A'}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography variant="body1">Keine Artikel-Tagesberichte verfügbar.</Typography>
          )}
        </Grid>

        {/* To-Dos */}
        <Grid item xs={12}>
          <Typography variant="h6">To-Dos</Typography>
          {loadingTodos ? (
            <CircularProgress />
          ) : todos.length > 0 ? (
            <TableContainer component={Paper} sx={{ mb: 2 }}>
              <Table aria-label="To-Do Tabelle">
                <TableHead>
                  <TableRow>
                    <TableCell><strong>Datum</strong></TableCell>
                    <TableCell><strong>Artikel</strong></TableCell>
                    <TableCell><strong>Warengruppe</strong></TableCell>
                    <TableCell><strong>Benutzergruppen</strong></TableCell>
                    <TableCell><strong>To-Do</strong></TableCell>
                    <TableCell><strong>Status</strong></TableCell>
                    <TableCell><strong>Aktionen</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {todos.map((todo) => (
                    <TableRow key={todo.id}>
                      <TableCell>{todo.datum}</TableCell>
                      <TableCell>{todo.artikel_name}</TableCell>
                      <TableCell>{todo.warengruppe_name}</TableCell>
                      <TableCell>
                        {todo.benutzergruppen && todo.benutzergruppen.length > 0
                          ? todo.benutzergruppen.join(', ')
                          : 'Keine Benutzergruppen'}
                      </TableCell>
                      <TableCell>
                        <TextField
                          value={todo.aufgabe}
                          onChange={(e) => {
                            const updatedTodos = todos.map((t) =>
                              t.id === todo.id ? { ...t, aufgabe: e.target.value } : t
                            );
                            setTodos(updatedTodos);
                          }}
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <FormControl fullWidth>
                          <InputLabel>Status</InputLabel>
                          <Select
                            value={todo.status}
                            label="Status"
                            onChange={(e) => {
                              const updatedTodos = todos.map((t) =>
                                t.id === todo.id ? { ...t, status: e.target.value } : t
                              );
                              setTodos(updatedTodos);
                            }}
                          >
                            <MenuItem value="offen">Offen</MenuItem>
                            <MenuItem value="in_bearbeitung">In Bearbeitung</MenuItem>
                            <MenuItem value="erledigt">Erledigt</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => updateTodo(todo.id, {
                            artikelbericht: todo.artikelbericht,
                            datum: todo.datum,
                            anzahl_meldungen: todo.anzahl_meldungen,
                            aufgabe: todo.aufgabe,
                            status: todo.status,
                            // Füge weitere erforderliche Felder hinzu, falls nötig
                          })}
                        >
                          Speichern
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography variant="body1">Keine To-Dos verfügbar.</Typography>
          )}
        </Grid>

        {/* Ratings Tabelle */}
        <Grid item xs={12}>
          {ratings.length > 0 ? (
            <TableContainer component={Paper}>
              <Table aria-label="Ratings Tabelle">
                <TableHead>
                  <TableRow>
                    <TableCell><strong>Datum</strong></TableCell>
                    <TableCell><strong>Filiale</strong></TableCell>
                    <TableCell><strong>User</strong></TableCell>
                    <TableCell><strong>Checkpoint</strong></TableCell>
                    <TableCell><strong>Wert</strong></TableCell>
                    <TableCell><strong>Kommentar</strong></TableCell>
                    <TableCell><strong>Artikel</strong></TableCell>
                    <TableCell><strong>Warengruppe</strong></TableCell>
                    <TableCell><strong>Benutzergruppen</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ratings.map((rating) => (
                    <TableRow key={rating.id}>
                      <TableCell>{rating.datum}</TableCell>
                      <TableCell>{rating.filiale}</TableCell>
                      <TableCell>{rating.user}</TableCell>
                      <TableCell>{rating.checkpoint_name}</TableCell>
                      <TableCell>{rating.value}</TableCell>
                      <TableCell>{rating.comment || 'N/A'}</TableCell>
                      <TableCell>{rating.artikel || 'N/A'}</TableCell>
                      <TableCell>{rating.warengruppe || 'N/A'}</TableCell>
                      <TableCell>
                        {rating.benutzergruppen && rating.benutzergruppen.length > 0
                          ? rating.benutzergruppen.join(', ')
                          : 'N/A'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography variant="body1">Keine Ratings verfügbar.</Typography>
          )}
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Analyse;
