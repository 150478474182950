import React, { useState, useEffect } from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';
import Layout from '../../../components/layout/personalcontrolling/personalcontrolling';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import Battery3Bar from '@mui/icons-material/Battery3Bar';
import {
  Card,
  Typography,
  Box,
  Paper,
  CardHeader,
  CardContent,
  Grid,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  IconButton,
} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';

import axiosInstance from '../../../services/axiosInstance';

const OrgChart = () => {
  const [nodes, setNodes] = useState([]);
  const [formData, setFormData] = useState({
    label: '',
    staffId: '',
    parentLabel: '',
    deleteLabel: '',
  });

  // Dialog-Statusvariablen
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);
  const [dialogFormData, setDialogFormData] = useState({
    label: '',
    staffId: '',
  });

  // Mitarbeiterliste aus der API
  const [staffList, setStaffList] = useState([]);

  // Mitarbeiterdaten beim Laden des Komponenten abrufen
  useEffect(() => {
    const fetchStaff = async () => {
      try {
        const response = await axiosInstance.get('/api/personaldaten/stammdaten-mitarbeiter/');
        setStaffList(response.data.results);
      } catch (error) {
        console.error('Fehler beim Abrufen der Mitarbeiterdaten:', error);
      }
    };
    fetchStaff();
  }, []);

  // Funktionen zum Verwalten der Formulardaten
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleDialogInputChange = (e) => {
    const { name, value } = e.target;
    setDialogFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Funktionen zum Hinzufügen, Bearbeiten und Löschen von Knoten
  const addNode = () => {
    const selectedStaff = staffList.find((staff) => staff.mitarbeiterId.toString() === formData.staffId);

    const newNode = {
      id: `${Date.now()}`,
      label: formData.label,
      staff: selectedStaff,
      children: [],
    };

    if (formData.parentLabel) {
      const updatedNodes = [...nodes];
      const parentNode = findNodeByLabel(updatedNodes, formData.parentLabel);
      if (parentNode) {
        parentNode.children.push(newNode);
      } else {
        alert('Übergeordnete Position nicht gefunden');
        return;
      }
      setNodes(updatedNodes);
    } else {
      setNodes([...nodes, newNode]);
    }

    setFormData({ label: '', staffId: '', parentLabel: '', deleteLabel: '' });
  };

  const findNodeByLabel = (nodesList, label) => {
    for (const node of nodesList) {
      if (node.label === label) {
        return node;
      }
      if (node.children.length > 0) {
        const found = findNodeByLabel(node.children, label);
        if (found) {
          return found;
        }
      }
    }
    return null;
  };

  const handleNodeClick = (nodeData) => {
    setSelectedNode(nodeData);
    setDialogFormData({
      label: nodeData.label,
      staffId: nodeData.staff ? nodeData.staff.mitarbeiterId.toString() : '',
    });
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedNode(null);
    setDialogFormData({ label: '', staffId: '' });
  };

  const saveDialogEdit = () => {
    const updatedNodes = [...nodes];
    updateNodeById(updatedNodes, selectedNode.id, dialogFormData);
    setNodes(updatedNodes);
    handleDialogClose();
  };

  const updateNodeById = (nodesList, id, data) => {
    for (const node of nodesList) {
      if (node.id === id) {
        const selectedStaff = staffList.find((staff) => staff.mitarbeiterId.toString() === data.staffId);
        node.label = data.label;
        node.staff = selectedStaff;
        return true;
      }
      if (node.children.length > 0) {
        const updated = updateNodeById(node.children, id, data);
        if (updated) {
          return true;
        }
      }
    }
    return false;
  };

  const deleteNode = () => {
    const updatedNodes = [...nodes];
    const deleted = deleteNodeByLabel(updatedNodes, formData.deleteLabel);
    if (deleted) {
      setNodes(updatedNodes.filter((node) => node !== null));
    } else {
      alert('Position nicht gefunden');
    }
    setFormData({ ...formData, deleteLabel: '' });
  };

  const deleteNodeByLabel = (nodesList, label) => {
    for (let i = 0; i < nodesList.length; i++) {
      const node = nodesList[i];
      if (node.label === label) {
        nodesList.splice(i, 1);
        return true;
      }
      if (node.children.length > 0) {
        const deleted = deleteNodeByLabel(node.children, label);
        if (deleted) {
          return true;
        }
      }
    }
    return false;
  };

  // Komponente zur Darstellung eines Knotens mit Ein-/Ausklappfunktion
  const NodeComponent = ({ node }) => {
    const [collapsed, setCollapsed] = useState(false);

    const handleCollapse = (e) => {
      e.stopPropagation(); // Verhindert das Öffnen des Bearbeitungsdialogs
      setCollapsed(!collapsed);
    };

    return (
      <TreeNode
        label={
          <div
            style={{
              padding: '10px',
              border: '1px solid #ccc',
              borderRadius: '5px',
              cursor: 'pointer',
              backgroundColor: '#fff',
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={() => handleNodeClick(node)}
          >
            {node.children.length > 0 && (
              <IconButton size="small" onClick={handleCollapse}>
                {collapsed ? <ExpandMore /> : <ExpandLess />}
              </IconButton>
            )}
            <div>
              <Typography variant="subtitle1">{node.label}</Typography>
              {node.staff && (
                <>
                  <Typography variant="body2">
                    <strong>Name:</strong> {node.staff.name}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Eintritt:</strong>{' '}
                    {new Date(node.staff.eintritt).toLocaleDateString()}
                  </Typography>
                </>
              )}
            </div>
          </div>
        }
      >
        {!collapsed &&
          node.children.map((childNode) => (
            <NodeComponent key={childNode.id} node={childNode} />
          ))}
      </TreeNode>
    );
  };

  return (
    <Layout>
      <PageHeader
        title="Organigramm"
        subtitle="Orga-Chart."
        Icon={Battery3Bar}
      />

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginBottom: 2 }}>
        <Card sx={{ padding: 2 }}>
          <Typography variant="h6">Position hinzufügen</Typography>
          <Box display="flex" flexDirection="column" gap={2} mt={2}>
            <TextField
              label="Position"
              name="label"
              value={formData.label}
              onChange={handleInputChange}
              variant="outlined"
            />
            <Autocomplete
              options={staffList}
              getOptionLabel={(option) => `${option.name} (${option.mitarbeiterId})`}
              renderInput={(params) => <TextField {...params} label="Mitarbeiter" variant="outlined" />}
              value={staffList.find((staff) => staff.mitarbeiterId.toString() === formData.staffId) || null}
              onChange={(event, newValue) => {
                setFormData((prevData) => ({
                  ...prevData,
                  staffId: newValue ? newValue.mitarbeiterId.toString() : '',
                }));
              }}
            />
            <TextField
              label="Übergeordnete Position (optional)"
              name="parentLabel"
              value={formData.parentLabel}
              onChange={handleInputChange}
              variant="outlined"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={addNode}
            >
              Position hinzufügen
            </Button>
          </Box>
        </Card>

        <Card sx={{ padding: 2 }}>
          <Typography variant="h6">Position löschen</Typography>
          <Box display="flex" flexDirection="row" gap={2} mt={2}>
            <TextField
              label="Position (zu löschen)"
              name="deleteLabel"
              value={formData.deleteLabel}
              onChange={handleInputChange}
              variant="outlined"
            />
            <Button
              variant="contained"
              color="secondary"
              onClick={deleteNode}
            >
              Position löschen
            </Button>
          </Box>
        </Card>
      </Box>

      <Grid item xs={12} md={12} mt={3}>
        <Paper>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              padding: '0px',
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              backdropFilter: 'blur(10px)',
            }}
          >
            <CardHeader
              title={
                <Typography variant="h6" component="div">
                  Organigramm
                </Typography>
              }
            />
            <CardContent sx={{ flexGrow: 1 }}>
              {nodes.length > 0 ? (
                <div style={{ overflow: 'auto' }}>
                  <Tree
                    lineWidth={'2px'}
                    lineColor={'#ccc'}
                    lineBorderRadius={'10px'}
                    label={<div></div>}
                  >
                    {nodes.map((node) => (
                      <NodeComponent key={node.id} node={node} />
                    ))}
                  </Tree>
                </div>
              ) : (
                <Typography variant="body1">Fügen Sie Positionen zum Organigramm hinzu.</Typography>
              )}
            </CardContent>
          </Card>
        </Paper>
      </Grid>

      {/* Dialog-Komponente */}
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Position bearbeiten</DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" gap={2} mt={2}>
            <TextField
              label="Position"
              name="label"
              value={dialogFormData.label}
              onChange={handleDialogInputChange}
              variant="outlined"
              fullWidth
            />
            <Autocomplete
              options={staffList}
              getOptionLabel={(option) => `${option.name} (${option.mitarbeiterId})`}
              renderInput={(params) => <TextField {...params} label="Mitarbeiter" variant="outlined" />}
              value={staffList.find((staff) => staff.mitarbeiterId.toString() === dialogFormData.staffId) || null}
              onChange={(event, newValue) => {
                setDialogFormData((prevData) => ({
                  ...prevData,
                  staffId: newValue ? newValue.mitarbeiterId.toString() : '',
                }));
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Abbrechen
          </Button>
          <Button onClick={saveDialogEdit} color="primary">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};

export default OrgChart;
