// src/pages/unternehmenwoche/cockpit.jsx

import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, Typography, Stack, Card } from '@mui/material';
import Layout from '../../../components/layout/Scorecards/Woche/Unternehmen/UnternehmenMonatWoche';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import BarChartIcon from '@mui/icons-material/BarChart';
import DataCard from '../../../components/card/scorecardsmonat/DataCardMonat';
import { FilterContext } from '../../../provider/FilterProvider';
import axiosInstance from '../../../services/axiosInstance';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import DropdownFilter from '../../../components/filter/DropdownFilter';
import { calculatePerformanceData } from '../../../utils/calculationPerformance';
import VariableTable from '../../../components/table/VariableTable';
import { useTheme } from '@mui/material/styles';
import { formatPercentage, tausenderTrennung, formatCurrency } from "../../../utils/mathStuff";

dayjs.extend(isoWeek);
dayjs.extend(customParseFormat);

const Cockpit = () => {
    const { filterState, handleDatumWocheChange } = useContext(FilterContext);
    const [performanceData, setPerformanceData] = useState({
        umsatz: 0,
        umsatzVJ: 0,
        umsatzAbweichungVW: 0,
        umsatzAbweichung6W: 0,
        umsatzAbweichungVJ: 0,
        kundenanzahl: 0,
        kundenanzahlVJ: 0,
        kundenanzahlAbweichungVW: 0,
        kundenanzahlAbweichung6W: 0,
        kundenanzahlAbweichungVJ: 0,
        leistung: 0,
        leistungVJ: 0,
        kundenbon: 0,
        kundenbonVJ: 0,
        chartData: [],
    });
    const [datumOptions, setDatumOptions] = useState([]);
    const [retourenData, setRetourenData] = useState([]);
    const [warengruppenData, setWarengruppenData] = useState([]);
    const theme = useTheme();

    const [performanceLast6Weeks, setPerformanceLast6Weeks] = useState([]);
    const [personalData, setPersonalData] = useState({
        krankenquote: [],
        fluktuation: [],
    });

    useEffect(() => {
        fetchDatumOptions();
    }, []);

    useEffect(() => {
        if (filterState.datumWoche) {
            fetchPerformanceData();
            fetchPerformanceDataLast6Weeks();
            fetchPersonalData();
            fetchWarengruppenData();
            fetchRetourenData();
        }
    }, [filterState]);

    const fetchDatumOptions = async () => {
        try {
            const response = await axiosInstance.get('/stammdaten/unique-jahr-woche/');
            setDatumOptions(response.data.map(item => ({ label: item, value: item })));
        } catch (error) {
            console.error('Error while fetching datum options:', error);
        }
    };

    const fetchPerformanceData = async () => {
        try {
            const response = await axiosInstance.get('/scorecard_week/performance-woche/', {
                params: {
                    jahrKw: filterState.datumWoche?.value,
                },
            });

            const allFilialenData = response.data.results;

            // Achten Sie darauf, dass die Funktion calculatePerformanceData die richtigen Variablen zurückgibt
            const {
                umsatzSumme,
                umsatzVJSumme,
                umsatzAbweichungVW,
                umsatzAbweichung6W,
                umsatzAbweichungVJ,
                kundenanzahlSumme,
                kundenanzahlVJSumme,
                kundenanzahlAbweichungVW,
                kundenanzahlAbweichung6W,
                kundenanzahlAbweichungVJ,
                leistung,
                leistungVJ,
                kundenbon,
                kundenbonVJ,
            } = calculatePerformanceData(allFilialenData);

            setPerformanceData({
                umsatz: umsatzSumme,
                umsatzVJ: umsatzVJSumme,
                umsatzAbweichungVW,
                umsatzAbweichung6W,
                umsatzAbweichungVJ,
                kundenanzahl: kundenanzahlSumme,
                kundenanzahlVJ: kundenanzahlVJSumme,
                kundenanzahlAbweichungVW,
                kundenanzahlAbweichung6W,
                kundenanzahlAbweichungVJ,
                leistung,
                leistungVJ,
                kundenbon,
                kundenbonVJ,
                chartData: allFilialenData.map((item) => ({
                    umsatz: item.umsatz || 0,
                    kundenanzahl: item.kundenanzahl || 0,
                    leistung: item.leistung || 0,
                    kundenbon: item.kundenbon || 0,
                })),
            });
        } catch (error) {
            console.error('Error fetching performance data:', error);
        }
    };

    // Helper function to calculate the last 6 weeks based on the selected date
    const getLast6Weeks = (currentWeek) => {
        const last6Weeks = [];
        const [year, week] = currentWeek.split('.').map(Number);
        let date = dayjs(`${year}-W${String(week).padStart(2, '0')}-1`, 'YYYY-[W]WW-E');
        for (let i = 0; i < 6; i++) {
            last6Weeks.push(date.format('YYYY.WW'));
            date = date.subtract(1, 'week');
        }
        return last6Weeks.reverse(); // To show oldest first
    };

    const fetchPerformanceDataLast6Weeks = async () => {
        const currentWeek = filterState.datumWoche?.value || dayjs().format('YYYY.WW');
        const last6Weeks = getLast6Weeks(currentWeek);

        try {
            const responses = await Promise.all(
                last6Weeks.map(week => axiosInstance.get('/scorecard_week/performance-woche/', {
                    params: { jahrKw: week },
                }))
            );

            const last6WeeksData = responses.map((response, index) => {
                const umsatzSumme = response.data.results
                    .filter(filiale => filiale.umsatz > 1)
                    .reduce((sum, filiale) => sum + (filiale.umsatz || 0), 0);

                const kundenanzahlSumme = response.data.results
                    .reduce((sum, filiale) => sum + (filiale.kundenanzahl || 0), 0);

                const produktivstundenSumme = response.data.results
                    .reduce((sum, filiale) => sum + (filiale.produktivstunden || 0), 0);

                const kundenbon = kundenanzahlSumme > 0 ? umsatzSumme / kundenanzahlSumme : 0;
                const leistung = produktivstundenSumme > 0 ? umsatzSumme / produktivstundenSumme : 0;

                return {
                    week: last6Weeks[index],
                    umsatz: umsatzSumme,
                    kundenanzahl: kundenanzahlSumme,
                    kundenbon: kundenbon,
                    leistung: leistung
                };
            });

            setPerformanceLast6Weeks(last6WeeksData);
        } catch (error) {
            console.error('Error fetching data for last 6 weeks:', error);
        }
    };

    const fetchRetourenData = async () => {
        try {
            const response = await axiosInstance.get('/scorecard_week/ware-warengruppe-woche/', {
                params: {
                    jahrKw: filterState.datumWoche?.value,
                },
            });

            const aggregatedRetourenData = response.data.results.reduce((acc, item) => {
                const existingEntry = acc.find(entry => entry.warengruppennummer === item.warengruppennummer);
                const retourwert = item.retourwert || 0;
                const retourwertA = item.retourwertA || 0;
                const retourwertBC = item.retourwertBC || 0;
                const lieferwert = item.lieferwert || 0;
                const lieferwertA = item.lieferwertA || 0;
                const lieferwertBC = item.lieferwertBC || 0;
                const korrekturwert = item.korrekturwert || 0;
                const korrekturwertA = item.korrekturwertA || 0;
                const korrekturwertBC = item.korrekturwertBC || 0;

                if (existingEntry) {
                    existingEntry.retourwert += retourwert;
                    existingEntry.retourwertA += retourwertA;
                    existingEntry.retourwertBC += retourwertBC;
                    existingEntry.lieferwert += lieferwert;
                    existingEntry.lieferwertA += lieferwertA;
                    existingEntry.lieferwertBC += lieferwertBC;
                    existingEntry.korrekturwert += korrekturwert;
                    existingEntry.korrekturwertA += korrekturwertA;
                    existingEntry.korrekturwertBC += korrekturwertBC;
                } else {
                    acc.push({
                        warengruppennummer: item.warengruppennummer,
                        warengruppe: item.warengruppe,
                        retourwert,
                        retourwertA,
                        retourwertBC,
                        lieferwert,
                        lieferwertA,
                        lieferwertBC,
                        korrekturwert,
                        korrekturwertA,
                        korrekturwertBC,
                    });
                }

                return acc;
            }, []);

            // After accumulation, calculate return rates
            const retourenDataWithRates = aggregatedRetourenData.map(item => {
                const { retourwert, lieferwert, korrekturwert, retourwertA, lieferwertA, korrekturwertA, retourwertBC, lieferwertBC, korrekturwertBC } = item;
                const totalLieferwert = lieferwert + korrekturwert;
                const totalLieferwertA = lieferwertA + korrekturwertA;
                const totalLieferwertBC = lieferwertBC + korrekturwertBC;

                const retourQuote = totalLieferwert > 0 ? ((retourwert / totalLieferwert) * 100).toFixed(2) : '0.00';
                const retourQuoteA = totalLieferwertA > 0 ? ((retourwertA / totalLieferwertA) * 100).toFixed(2) : '0.00';
                const retourQuoteBC = totalLieferwertBC > 0 ? ((retourwertBC / totalLieferwertBC) * 100).toFixed(2) : '0.00';

                return {
                    ...item,
                    retourQuote,
                    retourQuoteA,
                    retourQuoteBC,
                };
            });

            setRetourenData(retourenDataWithRates);
        } catch (error) {
            console.error('Error fetching retouren data:', error);
        }
    };

    const fetchPersonalData = async () => {
        const currentWeek = filterState.datumWoche?.value || dayjs().format('YYYY.WW');
        const last6Weeks = getLast6Weeks(currentWeek);

        try {
            const responses = await Promise.all(
                last6Weeks.map(week => axiosInstance.get('/scorecards_month/personal-kennzahl/', {
                    params: { jahrKw: week },
                }))
            );

            // Cumulative Krankenquote data
            const krankenquoteData = responses.map((response, index) => {
                const data = response.data.results;

                const arbeitszeitKrankenqoute = data.reduce((sum, item) => sum + (item.arbeitszeitKrankenqoute || 0), 0);
                const krankenzeit = data.reduce((sum, item) => sum + (item.krankenzeit || 0), 0);
                const arbeitszeitKrankenqouteVM = data.reduce((sum, item) => sum + (item.arbeitszeitKrankenqouteVM || 0), 0);
                const krankenzeitVM = data.reduce((sum, item) => sum + (item.krankenzeitVM || 0), 0);
                const arbeitszeitKrankenqouteVJ = data.reduce((sum, item) => sum + (item.arbeitszeitKrankenqouteVJ || 0), 0);
                const krankenzeitVJ = data.reduce((sum, item) => sum + (item.krankenzeitVJ || 0), 0);

                // Calculate Krankenquote
                const krankenquote = arbeitszeitKrankenqoute > 0 ? (krankenzeit / arbeitszeitKrankenqoute) * 100 : 0;
                const krankenquoteVM = arbeitszeitKrankenqouteVM > 0 ? (krankenzeitVM / arbeitszeitKrankenqouteVM) * 100 : 0;
                const krankenquoteVJ = arbeitszeitKrankenqouteVJ > 0 ? (krankenzeitVJ / arbeitszeitKrankenqouteVJ) * 100 : 0;

                return {
                    week: last6Weeks[index],
                    arbeitszeitKrankenqoute,
                    krankenzeit,
                    krankenquote,
                    krankenquoteVM,
                    krankenquoteVJ,
                };
            });

            // Cumulative Fluktuation data
            const fluktuationData = responses.map((response, index) => {
                const data = response.data.results;

                const austritte6M = data.reduce((sum, item) => sum + (item.austritte6M || 0), 0);
                const mitarbeiterbestand6M = data.reduce((sum, item) => sum + (item.mitarbeiterbestand6M || 0), 0);
                const eintritte6M = data.reduce((sum, item) => sum + (item.eintritte6M || 0), 0);
                const köpfeDifferenz = data.reduce((sum, item) => sum + (item.köpfeDifferenz || 0), 0);
                const stundenDifferenz = data.reduce((sum, item) => sum + (item.stundenDifferenz || 0), 0);
                const überstundenAbs = data.reduce((sum, item) => sum + (item.überstundenAbs || 0), 0);

                // Calculate Fluktuation
                const fluktuation = (mitarbeiterbestand6M + eintritte6M) > 0
                    ? (austritte6M / (mitarbeiterbestand6M + eintritte6M)) * 100
                    : 0;

                // Only entries and exits of the filtered week
                const eintritteFiltered = data.reduce((sum, item) => sum + (item.eintritte || 0), 0);
                const austritteFiltered = data.reduce((sum, item) => sum + (item.austritte || 0), 0);

                // Cumulative entriesM and exitsM for all branches
                const eintritteM = data.reduce((sum, item) => sum + (item.eintritteM || 0), 0);
                const austritteM = data.reduce((sum, item) => sum + (item.austritteM || 0), 0);

                return {
                    week: last6Weeks[index],
                    austritte6M,
                    mitarbeiterbestand6M,
                    eintritte6M,
                    fluktuation,
                    eintritteFiltered,
                    austritteFiltered,
                    eintritteM,
                    austritteM,
                    köpfeDifferenz,
                    stundenDifferenz,
                    überstundenAbs,
                };
            });

            setPersonalData({
                krankenquote: krankenquoteData,
                fluktuation: fluktuationData,
            });
        } catch (error) {
            console.error('Error fetching personal data:', error);
        }
    };

    const fetchWarengruppenData = async () => {
        try {
            const response = await axiosInstance.get('/scorecard_week/ware-warengruppe-woche/', {
                params: {
                    jahrKw: filterState.datumWoche?.value,
                },
            });

            // Create a structure to store cumulative values
            const aggregatedData = response.data.results.reduce((acc, item) => {
                const existingEntry = acc.find(entry => entry.warengruppennummer === item.warengruppennummer);
                const verkaufsmenge = item.verkaufsmenge || 0;
                const verkaufsmengeVJ = item.verkaufsmengeVJ || 0;

                if (existingEntry) {
                    // Accumulate sales quantity and last year's sales quantity
                    existingEntry.verkaufsmenge += verkaufsmenge;
                    existingEntry.verkaufsmengeVJ += verkaufsmengeVJ;
                } else {
                    // Add new product group entry
                    acc.push({
                        warengruppennummer: item.warengruppennummer,
                        warengruppe: item.warengruppe,
                        verkaufsmenge,
                        verkaufsmengeVJ,
                    });
                }

                return acc;
            }, []);

            // After accumulating sales quantity and last year's sales quantity, calculate the increase
            const finalData = aggregatedData.map((entry) => {
                const { verkaufsmenge, verkaufsmengeVJ } = entry;

                // Calculate the percentage increase for each product group
                const steigerungProzent = verkaufsmengeVJ > 0
                    ? ((verkaufsmenge - verkaufsmengeVJ) / verkaufsmengeVJ) * 100
                    : 0;

                return {
                    ...entry,
                    steigerungProzent: steigerungProzent.toFixed(2), // Round to 2 decimal places
                };
            });

            // Sort the product groups by sales quantity and select the top 6
            const top6Warengruppen = finalData
                .sort((a, b) => b.verkaufsmenge - a.verkaufsmenge)
                .slice(0, 6);

            setWarengruppenData(top6Warengruppen);
        } catch (error) {
            console.error('Error fetching warengruppen data:', error);
        }
    };

    // Filter retourenData for product groups that do not have 0 in the return rate
    const filteredRetourenData = retourenData.filter(item => parseFloat(item.retourQuote) !== 0);

    const formattedWarengruppenData = warengruppenData.map(item => ({
        ...item,
        verkaufsmenge: item.verkaufsmenge ? tausenderTrennung(item.verkaufsmenge, 0) : '0',
        verkaufsmengeVJ: item.verkaufsmengeVJ ? tausenderTrennung(item.verkaufsmengeVJ, 0) : '0',
        steigerungProzent: item.steigerungProzent ? `${item.steigerungProzent.replace('.', ',')} %` : '0,00 %',
    }));

    const formattedRetourenData = filteredRetourenData.map(item => ({
        ...item,
        retourQuote: item.retourQuote ? `${item.retourQuote.replace('.', ',')} %` : '0,00 %',
        retourQuoteA: item.retourQuoteA ? `${item.retourQuoteA.replace('.', ',')} %` : '0,00 %',
        retourQuoteBC: item.retourQuoteBC ? `${item.retourQuoteBC.replace('.', ',')} %` : '0,00 %',
    }));

    const columns = [
        { accessorKey: 'warengruppe', header: 'Warengruppe' },
        { accessorKey: 'verkaufsmenge', header: 'Verkaufsmenge' },
        { accessorKey: 'verkaufsmengeVJ', header: 'Verkaufsmenge VJ' },
        { accessorKey: 'steigerungProzent', header: 'Abweichung VJ %' },
    ];

    const retourenColumns = [
        { accessorKey: 'warengruppe', header: 'Warengruppe' },
        { accessorKey: 'retourQuote', header: 'Retourquote Gesamt' },
        { accessorKey: 'retourQuoteA', header: 'Retourquote A' },
        { accessorKey: 'retourQuoteBC', header: 'Retourquote BC' },
    ];

    return (
        <Layout>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', mt: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <PageHeader
                        title="Wochen Scorecards Unternehmen"
                        subtitle="Wöchentliche Übersicht der Kernkennzahlen für das gesamte Unternehmen, um relevante Fokus-Themen abzuleiten und in die strategische Planung zu integrieren."
                        Icon={BarChartIcon}
                    />
                </Box>

                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <DropdownFilter
                        label="Datum"
                        options={datumOptions}
                        value={filterState.datumWoche}
                        onChange={handleDatumWocheChange}
                    />
                </Box>
            </Box>

            <Grid container spacing={3} mt={4}>
                {/* Erste Spalte */}
                <Grid item xs={12} md={3}>
                    <Stack direction="column" spacing={2} sx={{ minHeight: '200px' }}>
                        {/* Umsatz */}
                        <DataCard
                            category="Umsatz"
                            value={performanceData.umsatz ? tausenderTrennung(performanceData.umsatz.toFixed(2), 0) : '0,00'}
                            sector="performance"
                            subHeaders={[
                                `${performanceData.umsatzAbweichungVW?.toFixed(2) || '0,00'}% zum Vorwoche`,
                                `${performanceData.umsatzAbweichung6W?.toFixed(2) || '0,00'}% zum 6 Wochen Schnitt`,
                                `${performanceData.umsatzAbweichungVJ?.toFixed(2) || '0,00'}% zum Vorjahr`
                            ]}
                            chartData={performanceLast6Weeks.map(item => item.umsatz)}
                        />

                        {/* Kundenanzahl */}
                        <DataCard
                            category="Kundenanzahl"
                            sector="performance"
                            value={performanceData.kundenanzahl ? tausenderTrennung(performanceData.kundenanzahl.toFixed(2), 0) : '0,00'}
                            subHeaders={[
                                `${performanceData.kundenanzahlAbweichungVW?.toFixed(2) || '0,00'}% zum Vorwoche`,
                                `${performanceData.kundenanzahlAbweichung6W?.toFixed(2) || '0,00'}% zum 6 Wochen Schnitt`,
                                `${performanceData.kundenanzahlAbweichungVJ?.toFixed(2) || '0,00'}% zum Vorjahr`
                            ]}
                            chartData={performanceLast6Weeks.map(item => item.kundenanzahl)}
                        />

                        {/* Kundenbon */}
                        <DataCard
                            category="Kundenbon"
                            sector="performance"
                            value={performanceData.kundenbon ? formatCurrency(performanceData.kundenbon) : '0,00 €'}
                            subHeaders={[
                                `${formatCurrency(performanceData.kundenbonVJ)} im letzten Jahr`,
                                `${performanceData.kundenbonVJ > 0 ? ((performanceData.kundenbon - performanceData.kundenbonVJ) / performanceData.kundenbonVJ * 100).toFixed(2).replace('.', ',') : '0,00'}% zum Vorjahr`
                            ]}
                            chartData={performanceLast6Weeks.map(item => item.kundenbon)}
                        />

                        {/* Leistung */}
                        <DataCard
                            category="Leistung"
                            sector="performance"
                            value={performanceData.leistung ? performanceData.leistung.toFixed(0) : '0.00'}
                            subHeaders={[
                                `${performanceData.leistungVJ?.toFixed(2) || '0,00'} € im letzten Jahr`,
                                `${performanceData.leistungVJ > 0 ? ((performanceData.leistung - performanceData.leistungVJ) / performanceData.leistungVJ * 100).toFixed(2) : '0,00'}% zum Vorjahr`
                            ]}
                            chartData={performanceLast6Weeks.map(item => item.leistung)}
                        />
                    </Stack>
                </Grid>

                {/* Zweite Spalte */}
                <Grid item xs={12} md={3}>
                    <Stack direction="column" spacing={2} sx={{ minHeight: '200px' }}>
                        {/* Krankenquote */}
                        <DataCard
                            category="Krankenquote"
                            sector="personal"
                            value={personalData.krankenquote.length > 0 ? `${personalData.krankenquote[personalData.krankenquote.length - 1].krankenquote.toFixed(2).replace('.', ',')} %` : '0,00 %'}
                            subHeaders={[
                                `${personalData.krankenquote.length > 0 ? personalData.krankenquote[personalData.krankenquote.length - 1].krankenquoteVM.toFixed(2).replace('.', ',') : '0,00'} % zum Vorwoche`,
                                `${personalData.krankenquote.length > 0 ? personalData.krankenquote[personalData.krankenquote.length - 1].krankenquoteVJ.toFixed(2).replace('.', ',') : '0,00'} % zum Vorjahr`
                            ]}
                            chartData={personalData.krankenquote.map(item => item.krankenquote)}
                        />

                        {/* Fluktuation */}
                        <DataCard
                            category="Fluktuation"
                            sector="personal"
                            value={personalData.fluktuation.length > 0 ? `${personalData.fluktuation[personalData.fluktuation.length - 1].fluktuation.toFixed(2).replace('.', ',')} %` : '0,00 %'}
                            subHeaders={[
                                `${personalData.fluktuation.length > 0 ? personalData.fluktuation[personalData.fluktuation.length - 1].eintritteM.toFixed(0).replace('.', ',') : '0,00'} Eintritte`,
                                `${personalData.fluktuation.length > 0 ? personalData.fluktuation[personalData.fluktuation.length - 1].austritteM.toFixed(0).replace('.', ',') : '0,00'} Austritte`
                            ]}
                            chartData={personalData.fluktuation.map(item => item.fluktuation)}
                        />

                        {/* Bedarf */}
                        <DataCard
                            category="Bedarf"
                            sector="personal"
                            value={
                                <>
                                    <Typography variant="h4" component="span">
                                        {personalData.fluktuation.length > 0 ? personalData.fluktuation[personalData.fluktuation.length - 1].köpfeDifferenz : '0'}
                                    </Typography>
                                    <Typography variant="body2" component="span" sx={{ fontSize: '0.8rem', ml: 1 }}>
                                        MA
                                    </Typography>
                                    <Typography variant="h4" component="span" sx={{ ml: 2 }}>
                                        {personalData.fluktuation.length > 0 ? personalData.fluktuation[personalData.fluktuation.length - 1].stundenDifferenz : '0'}
                                    </Typography>
                                    <Typography variant="body2" component="span" sx={{ fontSize: '0.8rem', ml: 1 }}>
                                        h
                                    </Typography>
                                </>
                            }
                            chartData={personalData.fluktuation.map(item => item.köpfeDifferenz)}
                        />
                        {/* Auslastung */}
                        <DataCard
                            category="Auslastung"
                            sector="personal"
                            value={personalData.fluktuation.length > 0 ? `${tausenderTrennung(personalData.fluktuation[personalData.fluktuation.length - 1].überstundenAbs, 0)} h` : '0,00 h'}
                            subHeaders={[]}
                            chartData={personalData.fluktuation.map(item => item.überstundenAbs)}
                        />
                    </Stack>
                </Grid>

                {/* Dritte Spalte */}
                <Grid item xs={12} md={6}>
                    <Stack direction="column" spacing={3}>
                        {/* Tabelle mit Verkaufsdaten nach Warengruppe */}
                        <Card
                            sx={{
                                padding: theme.spacing(2),
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                backdropFilter: 'blur(10px)',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <BarChartIcon />
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            fontWeight: theme.typography.h5.fontWeight,
                                            fontSize: theme.typography.h6.fontSize,
                                            color: theme.palette.primary.main,
                                        }}
                                    >
                                        Verkaufsmenge nach Warengruppe
                                    </Typography>
                                </Stack>
                            </Box>

                            <Box>
                                <VariableTable
                                    columns={columns}
                                    data={formattedWarengruppenData}
                                    onRowClick={(row) => console.log('Clicked row:', row)}
                                />
                            </Box>
                        </Card>

                        {/* Tabelle für Retourenwerte */}
                        <Card
                            sx={{
                                padding: theme.spacing(2),
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                backdropFilter: 'blur(10px)',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <BarChartIcon />
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            fontWeight: theme.typography.h5.fontWeight,
                                            fontSize: theme.typography.h6.fontSize,
                                            color: theme.palette.primary.main,
                                        }}
                                    >
                                        Retourenwerte nach Warengruppe
                                    </Typography>
                                </Stack>
                            </Box>

                            <Box>
                                <VariableTable
                                    columns={retourenColumns}
                                    data={formattedRetourenData}
                                    onRowClick={(row) => console.log('Clicked row:', row)}
                                />
                            </Box>
                        </Card>
                    </Stack>
                </Grid>
            </Grid>
        </Layout>
    );
};

export default Cockpit;
