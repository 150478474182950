import React, { createContext, useState } from 'react';

export const FilterContext = createContext();

export const FilterProvider = ({ children }) => {
    const [filterState, setFilterState] = useState({
        filiale: null,
        datum: null, // Für Monate
        datumWoche: null, // Für Wochen
        selectedEmployee: null,
        bestehendeFlaeche: false, // Standardwert auf false gesetzt
    });

    const handleFilialeChange = (newFiliale) => {
        setFilterState((prevState) => ({ ...prevState, filiale: newFiliale }));
    };

    const handleDatumChange = (newDatum) => {
        setFilterState((prevState) => ({ ...prevState, datum: newDatum }));
    };

    const handleDatumWocheChange = (newDatumWoche) => {
        setFilterState((prevState) => ({ ...prevState, datumWoche: newDatumWoche }));
    };

    const handleEmployeeChange = (newEmployee) => {
        setFilterState((prevState) => ({ ...prevState, selectedEmployee: newEmployee }));
    };

    const handleBestehendeFlaecheChange = (newBestehendeFlaeche) => {
        setFilterState((prevState) => ({ ...prevState, bestehendeFlaeche: newBestehendeFlaeche }));
    };

    return (
        <FilterContext.Provider
            value={{
                filterState,
                handleFilialeChange,
                handleDatumChange,
                handleDatumWocheChange,
                handleEmployeeChange,
                handleBestehendeFlaecheChange, // Bereitstellen des neuen Handlers
            }}
        >
            {children}
        </FilterContext.Provider>
    );
};
