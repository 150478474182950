import React, { useState, useEffect } from 'react';
import {
    Box,
    TextField,
    Button,
    MenuItem,
    Typography,
    Divider,
    IconButton,
    CircularProgress,
    Alert,
    Checkbox,
    FormControlLabel,
    FormControl,
    InputLabel,
    OutlinedInput,
    Select,
    Chip,
} from '@mui/material';
import Layout from '../../../components/layout/Filialfeed/Filialfeed';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import QuickreplyOutlinedIcon from '@mui/icons-material/QuickreplyOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import axiosInstance from '../../../services/axiosInstance';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import AddCircleIcon from '@mui/icons-material/AddCircle';


// Import für PictureUploadButton (Pfad anpassen, falls nötig)
import PictureUploadButton from "../../../components/pictures/PictureUploadButton";

const visibilityOptions = ['Alle', 'Spezifische Filialen', 'Verkaufsgebiete'];
const publishOptions = ['Sofort', 'Bestimmtes Datum und Uhrzeit'];

const CreatePost = () => {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [image, setImage] = useState(null);
    const [sender, setSender] = useState('');
    const [tags, setTags] = useState([]);
    const [newTag, setNewTag] = useState('');
    const [selectedTag, setSelectedTag] = useState('');
    const [visibility, setVisibility] = useState('Alle');
    const [selectedEntitiesList, setSelectedEntitiesList] = useState([]);
    const [addPoll, setAddPoll] = useState(false);
    const [pollQuestion, setPollQuestion] = useState('');
    const [pollOptions, setPollOptions] = useState(['', '']);
    const [publishOption, setPublishOption] = useState('Sofort');
    const [publishDate, setPublishDate] = useState('');
    const [publishTime, setPublishTime] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [submitSuccess, setSubmitSuccess] = useState(false);

    const [tagsLoading, setTagsLoading] = useState(true);
    const [tagsError, setTagsError] = useState(null);

    const [allFilialen, setAllFilialen] = useState([]);
    const [filialenLoading, setFilialenLoading] = useState(false);
    const [filialenError, setFilialenError] = useState(null);

    const [allVerkaufsgebiete, setAllVerkaufsgebiete] = useState([]);
    const [verkaufsgebieteLoading, setVerkaufsgebieteLoading] = useState(false);
    const [verkaufsgebieteError, setVerkaufsgebieteError] = useState(null);

    // Aktuellen User laden
    useEffect(() => {
        const fetchCurrentUser = async () => {
            try {
                const response = await axiosInstance.get('/auth/user/');
                const currentUser = response.data.username || '';
                setSender(currentUser);
            } catch (err) {
                console.error('Fehler beim Laden des aktuellen Benutzers:', err);
            }
        };
        fetchCurrentUser();
    }, []);

    // Tags laden
    useEffect(() => {
        const fetchTags = async () => {
            try {
                const response = await axiosInstance.get('/filialfeed/tags/');
                const tagData = response.data.results || response.data;
                setTags(tagData);
                setTagsLoading(false);
            } catch (error) {
                console.error('Fehler beim Laden der Tags:', error);
                setTagsError('Fehler beim Laden der Tags');
                setTagsLoading(false);
            }
        };
        fetchTags();
    }, []);

    // Filialen laden, wenn "Spezifische Filialen"
    useEffect(() => {
        if (visibility === 'Spezifische Filialen') {
            setFilialenLoading(true);
            const fetchFilialen = async () => {
                try {
                    const response = await axiosInstance.get('/stammdaten/unique-filiale/');
                    setAllFilialen(response.data);
                    setFilialenLoading(false);
                } catch (err) {
                    console.error('Fehler beim Laden der Filialen:', err);
                    setFilialenError('Fehler beim Laden der Filialen');
                    setFilialenLoading(false);
                }
            };
            fetchFilialen();
        } else {
            setAllFilialen([]);
        }
    }, [visibility]);

    // Verkaufsgebiete laden, wenn "Verkaufsgebiete"
    useEffect(() => {
        if (visibility === 'Verkaufsgebiete') {
            setVerkaufsgebieteLoading(true);
            const fetchVerkaufsgebiete = async () => {
                try {
                    const response = await axiosInstance.get('/stammdaten/unique-vk/');
                    setAllVerkaufsgebiete(response.data);
                    setVerkaufsgebieteLoading(false);
                } catch (err) {
                    console.error('Fehler beim Laden der Verkaufsgebiete:', err);
                    setVerkaufsgebieteError('Fehler beim Laden der Verkaufsgebiete');
                    setVerkaufsgebieteLoading(false);
                }
            };
            fetchVerkaufsgebiete();
        } else {
            setAllVerkaufsgebiete([]);
        }
    }, [visibility]);

    const handleAddOption = () => {
        setPollOptions([...pollOptions, '']);
    };

    const handleOptionChange = (index, value) => {
        const updatedOptions = [...pollOptions];
        updatedOptions[index] = value;
        setPollOptions(updatedOptions);
    };

    const handleAddTag = async () => {
        const trimmedTag = newTag.trim();
        if (trimmedTag !== '' && !tags.some((tag) => tag.name === trimmedTag)) {
            try {
                const response = await axiosInstance.post('/filialfeed/tags/', { name: trimmedTag });
                setTags([...tags, response.data]);
                setNewTag('');
            } catch (error) {
                console.error('Fehler beim Hinzufügen des Tags:', error);
                setTagsError('Fehler beim Hinzufügen des Tags');
            }
        }
    };

    // afterImageUploadFunction: wird aufgerufen, wenn PictureUploadButton erfolgreich ein Bild hochgeladen hat
    const afterImageUploadFunction = async (image_id) => {
        try {
            const urlResponse = await axiosInstance.get(`/pictures/get-image-url/${image_id}/`);
            const image_url = urlResponse.data.url;
            setImage({ id: image_id, url: image_url });
        } catch (error) {
            console.error('Fehler beim Abrufen der Bild-URL:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setSubmitSuccess(false);

        const newPost = {
            title,
            content,
            image_id: image ? image.id : null,
            sender,
            tag_id: selectedTag,
            visibility,
            publish_date:
                publishOption === 'Bestimmtes Datum und Uhrzeit'
                    ? `${publishDate}T${publishTime}:00`
                    : new Date().toISOString(),
            ...(visibility === 'Spezifische Filialen' && {
                filialen_ids: selectedEntitiesList,
            }),
            ...(visibility === 'Verkaufsgebiete' && {
                verkaufsgebiete_ids: selectedEntitiesList.length > 0 ? selectedEntitiesList : []
            }),
            ...(addPoll && pollQuestion && {
                poll: {
                    question: pollQuestion,
                    options: pollOptions
                        .filter((option) => option.trim() !== '')
                        .map((optionText) => ({ option_text: optionText })),
                },
            }),
        };

        try {
            const response = await axiosInstance.post('/filialfeed/posts/', newPost);
            setSubmitSuccess(true);
            alert(`Post erfolgreich erstellt: ${JSON.stringify(response.data, null, 2)}`);
        } catch (error) {
            console.error('Fehler beim Erstellen des Posts:', error);
            setError('Es ist ein Fehler beim Erstellen des Posts aufgetreten.');
        } finally {
            setLoading(false);
        }
    };

    const resetForm = () => {
        setTitle('');
        setContent('');
        setImage(null);
        setSender('');
        setNewTag('');
        setSelectedTag('');
        setVisibility('Alle');
        setSelectedEntitiesList([]);
        setAddPoll(false);
        setPollQuestion('');
        setPollOptions(['', '']);
        setPublishOption('Sofort');
        setPublishDate('');
        setPublishTime('');
    };

    return (
        <Layout>
            <Box sx={{ flexGrow: 1, p: 3, maxWidth: 800, margin: '0 auto', bgcolor: 'transparent' }}>
                <PageHeader
                    title="Neuen Post erstellen"
                    subtitle="Erstelle einen neuen Post mit Titel, Inhalt, Bild, Absender, Tag, Umfrageoptionen, Sichtbarkeit und Veröffentlichungszeitpunkt."
                    Icon={QuickreplyOutlinedIcon}
                />

                <Box component="form" sx={{ mt: 3 }} onSubmit={handleSubmit}>
                    {error && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            {error}
                        </Alert>
                    )}
                    {submitSuccess && (
                        <Alert severity="success" sx={{ mb: 2 }}>
                            Post erfolgreich erstellt!
                        </Alert>
                    )}

                    <TextField
                        label="Titel"
                        variant="outlined"
                        fullWidth
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        sx={{ mb: 2 }}
                        required
                    />

                    <Typography variant="h6" gutterBottom>Inhalt</Typography>
                    <Box sx={{ mb: 2 }}>
                        <ReactQuill
                            value={content}
                            onChange={setContent}
                            modules={{
                                toolbar: [
                                    [{ header: [1, 2, false] }],
                                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                    [{ list: 'ordered' }, { list: 'bullet' }],
                                    ['link', 'image'],
                                    ['clean'],
                                ],
                            }}
                        />
                    </Box>

                    <Box sx={{ mb: 2 }}>
                        <Typography variant="h6">Bild hochladen</Typography>
                        <PictureUploadButton
  title={title || 'Standard-Titel'}
  afterImageUploadFunction={afterImageUploadFunction}
                            iconButton={false}
                        />
                        {image && image.url && (
                            <Box mt={2}>
                                <Typography>Vorschau:</Typography>
                                <img src={image.url} alt="Bildvorschau" style={{ maxWidth: '100%', height: 'auto' }} />
                            </Box>
                        )}
                    </Box>

                    <TextField
                        label="Absender"
                        variant="outlined"
                        fullWidth
                        value={sender}
                        onChange={(e) => setSender(e.target.value)}
                        placeholder="aktueller User"
                        sx={{ mb: 2 }}
                        required
                        InputProps={{
                            readOnly: true
                        }}
                    />

                    {tagsLoading ? (
                        <CircularProgress sx={{ mb: 2 }} />
                    ) : tagsError ? (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            {tagsError}
                        </Alert>
                    ) : (
                        <TextField
                            label="Tag"
                            variant="outlined"
                            fullWidth
                            select
                            value={selectedTag}
                            onChange={(e) => setSelectedTag(e.target.value)}
                            sx={{ mb: 2 }}
                            required
                        >
                            {tags.map((tag) => (
                                <MenuItem key={tag.id} value={tag.id}>
                                    {tag.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    )}

                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <TextField
                            label="Neues Tag erstellen"
                            variant="outlined"
                            value={newTag}
                            onChange={(e) => setNewTag(e.target.value)}
                            sx={{ mr: 2, flexGrow: 1 }}
                        />
<Button
  variant="outlined"
  color="primary"
  size="small" // Basis: small
  startIcon={<AddCircleIcon />}
  onClick={handleAddTag}
  sx={{
    borderRadius: '50px',

  }}
>
Tag hinzufügen
</Button>
                    </Box>

                    <Divider sx={{ my: 2 }} />

                    <Typography variant="h6" gutterBottom>
                        Sichtbarkeit
                    </Typography>
                    <TextField
                        label="Wer soll den Post sehen?"
                        variant="outlined"
                        fullWidth
                        select
                        value={visibility}
                        onChange={(e) => {
                            setVisibility(e.target.value);
                            setSelectedEntitiesList([]);
                        }}
                        sx={{ mb: 2 }}
                        required
                    >
                        {visibilityOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>

                    {visibility === 'Spezifische Filialen' && (
                        <>
                            {filialenLoading ? (
                                <CircularProgress sx={{ mb: 2 }} />
                            ) : filialenError ? (
                                <Alert severity="error" sx={{ mb: 2 }}>
                                    {filialenError}
                                </Alert>
                            ) : (
                                <FormControl sx={{ mb: 2, width: '100%' }}>
                                    <InputLabel>Filialen auswählen</InputLabel>
                                    <Select
                                        multiple
                                        value={selectedEntitiesList}
                                        onChange={(e) => setSelectedEntitiesList(e.target.value)}
                                        input={<OutlinedInput label="Filialen auswählen" />}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => {
                                                    const filObj = allFilialen.find(f => f.filialnummer === value);
                                                    const label = filObj
                                                        ? (filObj.filiale || filObj.filialbezeichnung || filObj.filialnummer)
                                                        : value;
                                                    return <Chip key={value} label={label} />;
                                                })}
                                            </Box>
                                        )}
                                    >
                                        {allFilialen.map((fil) => (
                                            <MenuItem key={fil.filialnummer} value={fil.filialnummer}>
                                                {fil.filiale || fil.filialbezeichnung || fil.filialnummer}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        </>
                    )}

                    {visibility === 'Verkaufsgebiete' && (
                        <>
                            {verkaufsgebieteLoading ? (
                                <CircularProgress sx={{ mb: 2 }} />
                            ) : verkaufsgebieteError ? (
                                <Alert severity="error" sx={{ mb: 2 }}>
                                    {verkaufsgebieteError}
                                </Alert>
                            ) : (
                                <FormControl sx={{ mb: 2, width: '100%' }}>
                                    <InputLabel>Verkaufsgebiete auswählen</InputLabel>
                                    <Select
                                        multiple
                                        value={selectedEntitiesList}
                                        onChange={(e) => setSelectedEntitiesList(e.target.value)}
                                        input={<OutlinedInput label="Verkaufsgebiete auswählen" />}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => {
                                                    const vgObj = allVerkaufsgebiete.find(vg => vg.vkg_ID === value);
                                                    const label = vgObj ? (vgObj.verkaufsgebiet || vgObj.vkg_ID) : value;
                                                    return <Chip key={value} label={label} />;
                                                })}
                                            </Box>
                                        )}
                                    >
                                        {allVerkaufsgebiete.map((vg) => (
                                            <MenuItem key={vg.vkg_ID} value={vg.vkg_ID}>
                                                {vg.verkaufsgebiet || vg.vkg_ID}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        </>
                    )}

                    <Divider sx={{ my: 2 }} />

                    <Typography variant="h6" gutterBottom>
                        Veröffentlichungszeitpunkt
                    </Typography>
                    <TextField
                        label="Veröffentlichen"
                        variant="outlined"
                        fullWidth
                        select
                        value={publishOption}
                        onChange={(e) => setPublishOption(e.target.value)}
                        sx={{ mb: 2 }}
                        required
                    >
                        {publishOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>

                    {publishOption === 'Bestimmtes Datum und Uhrzeit' && (
                        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                            <TextField
                                label="Datum"
                                type="date"
                                fullWidth
                                value={publishDate}
                                onChange={(e) => setPublishDate(e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                required
                            />
                            <TextField
                                label="Uhrzeit"
                                type="time"
                                fullWidth
                                value={publishTime}
                                onChange={(e) => setPublishTime(e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                required
                            />
                        </Box>
                    )}

                    <Divider sx={{ my: 2 }} />

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={addPoll}
                                onChange={(e) => setAddPoll(e.target.checked)}
                                color="primary"
                            />
                        }
                        label="Umfrage hinzufügen?"
                        sx={{ mb: 2 }}
                    />

                    {addPoll && (
                        <>
                            <Typography variant="h6" gutterBottom>
                                Umfrage hinzufügen
                            </Typography>
                            <TextField
                                label="Umfragefrage"
                                variant="outlined"
                                fullWidth
                                value={pollQuestion}
                                onChange={(e) => setPollQuestion(e.target.value)}
                                sx={{ mb: 2 }}
                            />

                            {pollOptions.map((option, index) => (
                                <TextField
                                    key={index}
                                    label={`Option ${index + 1}`}
                                    variant="outlined"
                                    fullWidth
                                    value={option}
                                    onChange={(e) => handleOptionChange(index, e.target.value)}
                                    sx={{ mb: 1 }}
                                />
                            ))}

                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                <IconButton color="primary" onClick={handleAddOption}>
                                    <AddCircleOutlineIcon />
                                </IconButton>
                                <Typography variant="body2">Option hinzufügen</Typography>
                            </Box>

                            <Divider sx={{ my: 2 }} />
                        </>
                    )}

<Button
  type="submit"
  variant="outlined"
  color="primary"
  fullWidth
  disabled={loading}
  startIcon={loading ? <CircularProgress size={20} /> : <AddCircleIcon />}
  sx={{
    borderRadius: '50px',
  }}
>
  {loading ? 'Erstelle Post...' : 'Post erstellen'}
</Button>

                </Box>
            </Box>
        </Layout>
    );
};

export default CreatePost;
