import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Typography,
  IconButton,
  Tooltip,
  Box
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { LineChart } from "@mui/x-charts/LineChart";
import Score from "../../score/Score"; // Falls Score benötigt wird, bitte entfernen, wenn nicht

const BigChartCard = ({ data, title, infoContent, height, width }) => {
  if (!data || data.length === 0) {
    return null;
  }

  // Vorbereitung der Daten für das Liniendiagramm
  // Wir gehen davon aus, dass item.x im Format "YYYY.WW" vorliegt
  // und item.label entweder "Filiale" oder "Unternehmen" ist.
  const seriesData = data.reduce((acc, item) => {
    const xValue = item.x; // z. B. "2023.23"
    if (!acc[item.label]) {
      acc[item.label] = {
        label: item.label,
        data: [],
        color: item.label === "Filiale" ? "#395D67" : "#95A78D",
        yAxis: item.label === "Filiale" ? "y1" : "y2"
      };
    }
    acc[item.label].data.push({ x: xValue, y: item.y });
    return acc;
  }, {});

  const uniqueLabels = Object.keys(seriesData);
  const xValues = Array.from(new Set(data.map((item) => item.x)));

  return (
    <Paper>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          height: height || "100%", // Use height prop or default to 100%
          width: width || "100%",   // Use width prop or default to 100%
          padding: "0px",
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          backdropFilter: "blur(10px)",
          overflow: "visible"
        }}
      >
        <CardHeader
          title={
            <Typography variant="h6" component="div">
              {title}
            </Typography>
          }
          action={
            infoContent && (
              <Tooltip title={infoContent} arrow placement="top">
                <IconButton>
                  <InfoOutlinedIcon />
                </IconButton>
              </Tooltip>
            )
          }
        />
        <CardContent sx={{ flexGrow: 1 }}>
          {/* Integriertes Liniendiagramm, ohne BasicLines separat */}
          <LineChart
            xAxis={[{
              scaleType: 'band',
              data: xValues,
              show: true,
              title: "Jahr.KW",
              tickFormatter: (value) => {
                // Aus "YYYY.WW" -> "YYYY KW WW"
                const [year, wk] = value.split('.');
                return `${year} KW ${wk}`;
              }
            }]}
            yAxis={[
              { id: 'y1', show: true, title: "Filiale Wert" },
              { id: 'y2', show: true, position: 'right', title: "Unternehmen Wert" }
            ]}
            series={Object.values(seriesData).map(s => ({
              data: s.data.map(d => d.y),
              color: s.color,
              yAxisKey: s.yAxis
            }))}
            height={400}
            margin={{ top: 10, bottom: 30, left: 50, right: 50 }}
            tooltip
          />

          {/* Custom Legend */}
          <Box display="flex" justifyContent="center" mt={2}>
            {uniqueLabels.map((label) => (
              <Typography key={label} variant="body2" sx={{ marginX: 1, display: 'flex', alignItems: 'center' }}>
                <span style={{
                  display: 'inline-block',
                  width: 12,
                  height: 12,
                  borderRadius: '50%',
                  backgroundColor: seriesData[label].color,
                  marginRight: 4,
                }} />
                {label}
              </Typography>
            ))}
          </Box>
        </CardContent>
      </Card>
    </Paper>
  );
};

export default BigChartCard;
