import React from 'react';
import { Grid, Box } from '@mui/material';
import QualitaetListe from '../../../components/qualitaet/QualitaetListe';
import WarengruppeAssignment from '../../../components/qualitaet/WarengruppeAssignment'; // Neue Komponente importieren
import Layout from '../../../components/layout/Qualität/Qualität';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import ChecklistIcon from '@mui/icons-material/Checklist';

const FilialcheckKofigurationPage = () => {
    return (
        <Layout>
            <Box
                sx={{
                    flexGrow: 1,
                    p: 3,
                    maxWidth: 1200,
                    margin: '0 auto',
                    bgcolor: 'transparent',
                }}
            >
                <PageHeader 
                    title="Qualität-Settings" 
                    subtitle="Verwalten Sie Ihre Qualitätschecks und konfigurieren Sie diese nach den Bedürfnissen des Unternehmens."
                    Icon={ChecklistIcon}
                />
                
                <Grid container spacing={4} justifyContent="flex-start">
                    <Grid item xs={12} md={8}>
                        <QualitaetListe />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <WarengruppeAssignment /> {/* Neue Komponente hier hinzufügen */}
                    </Grid>
                </Grid>
            </Box>
        </Layout>
    );
};

export default FilialcheckKofigurationPage;
