import React, { useContext, useEffect, useState } from 'react';
import { Grid, Stack, Dialog, DialogContent, DialogTitle, IconButton,Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Layout from '../../../components/layout/Scorecards/FilialeMonat';
import axiosInstance from '../../../services/axiosInstance';
import DataCard from '../../../components/card/DataCard';
import dayjs from 'dayjs';
import { FilterContext } from "../../../provider/FilterProvider";
import BigTableCard from '../../../components/card/BigTableCard';
import ScorecardsNotes from '../../../components/formular/Scorecards/ScorecardsNotes';
import ButtonGroupFilter from '../../../components/filter/ButtonGroupFilter';
import BigBarChartVerfuegbarkeitCard from '../../../components/card/BigBarChartVerfuegbarkeitCard'; // Stelle sicher, dass du den korrekten Pfad verwendest
import ABCDialog from '../../../components/popup/ABC';
import AbcIcon from '@mui/icons-material/Abc';
import { tausenderTrennung } from '../../../utils/mathStuff';

const Verfügbarkeit = () => {
    const [dataCardData, setDataCardData] = useState([]);
    const { filterState } = useContext(FilterContext);
    const activeCategory = 'Verfügbarkeit';
    const [tableData, setTableData] = useState([]);
    const [tableColumns, setTableColumns] = useState([]);
    const [barChartData, setBarChartData] = useState([]);
    const [barChartLabels, setBarChartLabels] = useState([]);
    const [openTiefenanalyse, setOpenTiefenanalyse] = useState(false);
    const [tiefenanalyseTableData, setTiefenanalyseTableData] = useState([]);
    const [tiefenanalyseTableColumns, setTiefenanalyseTableColumns] = useState([]);
    const [sumTableData, setSumTableData] = useState([]);
    const [warengruppeFilter, setWarengruppeFilter] = useState(null);
    const [warengruppeOptions, setWarengruppeOptions] = useState([]);
    const [popupOpen, setPopupOpen] = useState(false);
    const [abcPopupOpen, setAbcPopupOpen] = useState(false);

    useEffect(() => {
        if (filterState.filiale && filterState.datum) {
            fetchData();
            fetchTableData();
            fetchBarChartData();
        }
    }, [filterState.filiale, filterState.datum]);

    useEffect(() => {
        if (openTiefenanalyse) {
            handleOpenTiefenAnalyse();
        }
    }, [warengruppeFilter]);

    const fetchData = async () => {
        try {
            const endDate = dayjs(`${filterState.datum?.value}-01`, 'YYYY-MM-DD');
            const startDate = endDate.subtract(6, 'month');

            const response = await axiosInstance.get('scorecards_month/ware-filiale-monat/', {
                params: {
                    startdatum: startDate.format('YYYY-MM-DD'),
                    enddatum: endDate.format('YYYY-MM-DD'),
                    filiale: filterState.filiale?.label
                }
            });

            const dataCardData = response.data.results
                .slice(-4)
                .map(item => ({
                    x: item.datum,
                    Wachstum: item.scoreProduktwachstumFiliale,
                    Verfügbarkeit: item.scoreVerfügbarkeitFiliale,
                    RetoureA: item.scoreSollRetoureFiliale,
                    RetoureBC: item.scoreRetourQuoteFilialeBC,
                }));

            setDataCardData(dataCardData);
        } catch (error) {
            console.error('An error occurred while fetching data:', error);
        }
    };

    const fetchTableData = async () => {
        try {
            const responseWarengruppe = await axiosInstance.get('scorecards_month/ware-warengruppe-monat/', {
                params: {
                    filiale: filterState.filiale?.label,
                    jahrMonat: filterState.datum?.value
                }
            });
            console.log("Response Warengruppe:", responseWarengruppe.data);

            const responseVerfuegbarkeit = await axiosInstance.get('scorecard_month_article/verfuegbarkeit-monat/', {
                params: {
                    filiale: filterState.filiale?.label,
                    jahrMonat: filterState.datum?.value,
                    ABCValue: 'A'
                }
            });
            console.log("Response Verfügbarkeit:", responseVerfuegbarkeit.data);

            const responseStammdatenWarengruppe = await axiosInstance.get('stammdaten/stammdatenwarengruppe/');
            console.log("Response Stammdaten Warengruppe:", responseStammdatenWarengruppe.data);

            if (!Array.isArray(responseStammdatenWarengruppe.data.results)) {
                console.error('Stammdaten Warengruppe ist kein Array:', responseStammdatenWarengruppe.data.results);
                return;
            }

            const articleCounts = responseVerfuegbarkeit.data.results.reduce((acc, item) => {
                const articleNum = item.artikelnummer.artikelnummer;
                acc[articleNum] = (acc[articleNum] || 0) + 1;
                return acc;
            }, {});
            console.log("Artikelcount:", articleCounts);

            const warengruppeArtikel = responseVerfuegbarkeit.data.results.reduce((acc, item) => {
                const warengruppeNummer = item.artikelnummer.warengruppennummer;
                const articleNum = item.artikelnummer.artikelnummer;

                if (!acc[warengruppeNummer]) {
                    acc[warengruppeNummer] = { count: 0, uniqueArticles: new Set() };
                }

                acc[warengruppeNummer].count += 1;
                acc[warengruppeNummer].uniqueArticles.add(articleNum);

                return acc;
            }, {});
            console.log("Warengruppe Artikel:", warengruppeArtikel);

            const avgSoldOutDays = Object.keys(warengruppeArtikel).reduce((acc, warengruppeNummer) => {
                const count = warengruppeArtikel[warengruppeNummer].count;
                const uniqueArticleCount = warengruppeArtikel[warengruppeNummer].uniqueArticles.size;
            
                if (uniqueArticleCount > 0) {
                    acc[warengruppeNummer] = (count / uniqueArticleCount).toFixed(2);  // Rundet auf 2 Dezimalstellen
                } else {
                    acc[warengruppeNummer] = "0.00";  // Setzt auf "0.00" für den Fall, dass keine uniqueArticleCount vorhanden ist
                }
            
                return acc;
            }, {});
            
            console.log("Average Sold Out Days per WarengruppeNummer:", avgSoldOutDays);

            const dataTableData = responseWarengruppe.data.results
                .filter(item => item.scoreVerfügbarkeitWarengruppe != null || item.rangVerfügbarkeitQuote != null)
                .map(item => ({
                    Warengruppe: item.warengruppe,
                    WarengruppeNummer: item.warengruppennummer,
                    ScoreVerfügbarkeit: item.scoreVerfügbarkeitWarengruppe,
                    RangVerfügbarkeit: item.rangVerfügbarkeitQuote,
                    DurchschnAusverkauf: tausenderTrennung(avgSoldOutDays[item.warengruppennummer] || 0, 2)
                }));
            console.log("Data Table Data:", dataTableData);

            setTableData(dataTableData);

            const newColumns = [
                { id: 'Warengruppe', accessorKey: 'Warengruppe', header: 'Warengruppe' },
                { id: 'ScoreVerfügbarkeit', accessorKey: 'ScoreVerfügbarkeit', header: 'Score Verfügbarkeit' },
                { id: 'RangVerfügbarkeit', accessorKey: 'RangVerfügbarkeit', header: 'Rang Verfügbarkeit' },
                { id: 'DurchschnAusverkauf', accessorKey: 'DurchschnAusverkauf', header: 'Durchschn. Ausverkauftage' }
            ];

            setTableColumns(newColumns);

            const availableWarengruppeNummern = new Set(responseVerfuegbarkeit.data.results.map(item => item.artikelnummer.warengruppennummer));
            const filteredWarengruppen = responseStammdatenWarengruppe.data.results.filter(item => availableWarengruppeNummern.has(item.warengruppennummer));
            const uniqueWarengruppen = filteredWarengruppen.map(item => ({
                value: item.warengruppennummer,
                label: item.warengruppe
            }));
            console.log("Filtered Warengruppen Filter Optionen:", uniqueWarengruppen);

            setWarengruppeOptions(uniqueWarengruppen);

        } catch (error) {
            console.error('Ein Fehler ist beim Abrufen der Tabellendaten aufgetreten:', error);
        }
    };

    const fetchBarChartData = async () => {
        try {
            const response = await axiosInstance.get('scorecard_month_article/verfuegbarkeit-monat/', {
                params: {
                    filiale: filterState.filiale?.label,
                    jahrMonat: filterState.datum?.value,
                    ABCValue: 'A'
                }
            });

            const weekDayCounts = response.data.results.reduce((acc, item) => {
                const weekDay = item.datum.wochentag;
                acc[weekDay] = (acc[weekDay] || 0) + 1;
                return acc;
            }, {});

            const orderedWeekDays = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'];
            const labels = orderedWeekDays;
            const data = orderedWeekDays.map(day => weekDayCounts[day] || 0);

            setBarChartLabels(labels);
            setBarChartData(data);


            console.log("Daten für die Chart:",data, labels)
            console.log("Chartdaten:",barChartData, barChartLabels)


        } catch (error) {
            console.error('Ein Fehler ist beim Abrufen der Chartdaten aufgetreten:', error);
        }
    };

    const handleOpenTiefenAnalyse = async () => {
        try {
            const responseVerfuegbarkeit = await axiosInstance.get('scorecard_month_article/verfuegbarkeit-monat/', {
                params: {
                    filiale: filterState.filiale?.label,
                    jahrMonat: filterState.datum?.value,
                    ABCValue: 'A'
                }
            });
    
            const responseProdukteArtikel = await axiosInstance.get('scorecard_month_article/ware-artikel-monat/', {
                params: {
                    filiale: filterState.filiale?.label,
                    jahrMonat: filterState.datum?.value
                }
            });
    
            let tiefenanalyseData = responseVerfuegbarkeit.data.results.reduce((acc, item) => {
                const articleNum = item.artikelnummer.artikelnummer;
                const articleName = item.artikelnummer.artikel;  // Annahme: Der Artikelname ist in artikelnummer.artikelname enthalten
                const weekDay = item.datum.wochentag;
                const warengruppeNummer = item.artikelnummer.warengruppennummer;
    
                if (!acc[articleNum]) {
                    acc[articleNum] = {
                        artikelnummer: articleName,  // Hier wird der Artikelname anstelle der Nummer gespeichert
                        warengruppeNummer: warengruppeNummer,
                        Montag: 0, Dienstag: 0, Mittwoch: 0, Donnerstag: 0,
                        Freitag: 0, Samstag: 0, Sonntag: 0,
                        sumAusverkauft: 0,
                        retourmengeTag: 0
                    };
                }
    
                acc[articleNum][weekDay] += 1;
                acc[articleNum].sumAusverkauft += 1;
    
                return acc;
            }, {});
    
            responseProdukteArtikel.data.results.forEach(item => {
                const articleNum = item.artikelnummer.artikelnummer;
                if (tiefenanalyseData[articleNum]) {
                    tiefenanalyseData[articleNum].retourmengeTag += item.retourmengeTag;
                }
            });
    
            if (warengruppeFilter) {
                tiefenanalyseData = Object.fromEntries(Object.entries(tiefenanalyseData)
                    .filter(([_, item]) => item.warengruppeNummer === warengruppeFilter.value));
            }
    
            const tiefenanalyseTableData = Object.values(tiefenanalyseData);
    
            const tiefenanalyseTableColumns = [
                { id: 'artikelnummer', accessorKey: 'artikelnummer', header: 'Artikel' },  // Spaltenname geändert
                { id: 'Montag', accessorKey: 'Montag', header: 'Montag' },
                { id: 'Dienstag', accessorKey: 'Dienstag', header: 'Dienstag' },
                { id: 'Mittwoch', accessorKey: 'Mittwoch', header: 'Mittwoch' },
                { id: 'Donnerstag', accessorKey: 'Donnerstag', header: 'Donnerstag' },
                { id: 'Freitag', accessorKey: 'Freitag', header: 'Freitag' },
                { id: 'Samstag', accessorKey: 'Samstag', header: 'Samstag' },
                { id: 'Sonntag', accessorKey: 'Sonntag', header: 'Sonntag' },
            ];
    
            setTiefenanalyseTableData(tiefenanalyseTableData);
            setTiefenanalyseTableColumns(tiefenanalyseTableColumns);
    
            const sumTableData = tiefenanalyseTableData.map(item => ({
                artikelnummer: item.artikelnummer,
                sumAusverkauft: item.sumAusverkauft,
                retourmengeTag: tausenderTrennung(item.retourmengeTag, 2)
            }));
    
            setSumTableData(sumTableData);
    
            setOpenTiefenanalyse(true);
        } catch (error) {
            console.error('Fehler beim Laden der Tiefenanalyse-Daten:', error);
        }
    };
    

    const handleCloseTiefenanalyse = () => {
        setOpenTiefenanalyse(false);
    };

    return (
        <Layout>
            <Grid container justifyContent="flex-end" alignItems="stretch">
                <Grid item xs={12}>
                    <Stack direction="row" spacing={2} sx={{ overflowX: 'auto' }}>
                        {['Wachstum', 'Verfügbarkeit', 'RetoureA', 'RetoureBC'].map((category, index) => (
                            <Grid
                                item
                                xs={12}
                                md={6}
                                lg={3}
                                key={index}
                                className={activeCategory === category ? 'db_datacard_active' : 'db_datacard_not_active'}
                            >
                                <DataCard
                                    data={dataCardData}
                                    category={category}
                                    sector="ware"
                                    specialTitle={
                                        category === "RetoureA"
                                            ? "Retoure A" : category === "RetoureBC"
                                                ? "Retoure B/C" : null
                                    }
                                />
                            </Grid>
                        ))}
                    </Stack>
                </Grid>

                <Grid container spacing={2} mt={3}>
                <Grid item xs={12} lg={8}>
                    <div className="db_styled_table">
                    <BigTableCard
                        title={
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography variant="h6"> Verfügbarkeit</Typography>
                            <IconButton
                            color="primary"
                            onClick={() => setAbcPopupOpen(true)}
                            sx={{ fontSize: 40 }}
                            >
                            <AbcIcon fontSize="inherit" />
                            </IconButton>
                        </Stack>
                        }
                        data={tableData}
                        columns={tableColumns}
                    />
                    </div>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <BigBarChartVerfuegbarkeitCard
                    title="Anzahl ausverkauft nach Wochentag"
                    data={barChartData}
                    labels={barChartLabels}
                    onSearchClick={handleOpenTiefenAnalyse}
                    />
                </Grid>
                </Grid>
            </Grid>
            <ScorecardsNotes category="Ware" />

            <Dialog open={openTiefenanalyse} onClose={handleCloseTiefenanalyse} maxWidth="xl" fullWidth>
    <DialogTitle>
        Tiefenanalyse
        <IconButton aria-label="close" onClick={handleCloseTiefenanalyse} sx={{ position: 'absolute', right: 8, top: 8 }}>
            <CloseIcon />
        </IconButton>
    </DialogTitle>
    <DialogContent sx={{ display: 'flex', flexDirection: 'column', height: '85vh' }}>
        {/* ButtonGroupFilter direkt unter dem Titel */}
        <ButtonGroupFilter
            options={warengruppeOptions}
            value={warengruppeFilter}
            onChange={setWarengruppeFilter}
            sx={{ mb: 2 }} // Optional: Margin Bottom hinzufügen, um Platz zwischen dem Filter und dem Inhalt zu schaffen
        />
        <Grid container spacing={2} sx={{ flexGrow: 1 }}>
            <Grid item xs={12}>
                <BigTableCard
                    title={"Summierte Ausverkauftage und Retourmenge"}
                    data={sumTableData}
                    columns={[
                        { id: 'artikelnummer', accessorKey: 'artikelnummer', header: 'Artikelnummer' },
                        { id: 'sumAusverkauft', accessorKey: 'sumAusverkauft', header: 'Anzahl Tage Ausverkauft ' },
                        { id: 'retourmengeTag', accessorKey: 'retourmengeTag', header: 'Retourmenge Tag' },
                    ]}
                />
            </Grid>

            <Grid item xs={12}>
                <BigTableCard
                    title={"Tiefenanalyse Ausverkauftage"}
                    data={tiefenanalyseTableData}
                    columns={tiefenanalyseTableColumns}
                />
            </Grid>
        </Grid>
    </DialogContent>
</Dialog>

<ABCDialog
                open={abcPopupOpen}
                onClose={() => setAbcPopupOpen(false)}
                filiale={filterState.filiale?.label}
                monat={filterState.datum?.value}
            />

        </Layout>
    );
};

export default Verfügbarkeit;
