import {Box,} from "@mui/material";
import DropZone from "./DropZone";
import {useEffect, useRef} from 'react';

const ThekeGroup = ({sectionCount, onDrop, selectedArtikel, sortimentType, handleRemove}) => {
  // console.log("ThekeGroup received sortimentType:", sortimentType);

  const isScrollable = sectionCount >= 8;
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    const handleWheel = (e) => {
      if (isScrollable && scrollContainerRef.current) {
        e.preventDefault();
        scrollContainerRef.current.scrollLeft += e.deltaY;
      }
    };

    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('wheel', handleWheel, {passive: false});
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('wheel', handleWheel);
      }
    };
  }, [isScrollable]);

  return (
      <Box
          ref={scrollContainerRef}
          sx={{
            display: "flex",
            flexWrap: "nowrap", 
            gap: 1,
            overflowX: isScrollable ? "scroll" : "visible",
            pb: isScrollable ? 2 : 0,
            width: "100%",
            mb: 2,
            borderColor: "secondary.main",
            borderRadius: 6,
            '&::-webkit-scrollbar': {
              height: 6,
              width: 6,
              display: 'block',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'rgba(0,0,0,0.05)',
              borderRadius: 3,
              margin: 1,
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,0.15)',
              borderRadius: 3,
              '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.2)',
              },
            },
            scrollbarWidth: 'thin',
            scrollbarColor: 'rgba(0,0,0,0.15) rgba(0,0,0,0.05)',
          }}
      >
        {[...Array(sectionCount)].map((_, index) => (
            <DropZone
                key={index}
                onDrop={onDrop}
                sortimentType={sortimentType}
                handleRemove={handleRemove}
                artikel={selectedArtikel.slice(index, index + 1)[0]?.artikelData}
            />
        ))}
      </Box>
  );
};

export default ThekeGroup;
