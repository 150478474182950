import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Card,
  CardContent,
  Typography,
  TextField,
  Alert,
} from '@mui/material';
import Layout from '../../../components/layout/Technik/Technik';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import QuickreplyOutlinedIcon from '@mui/icons-material/QuickreplyOutlined';

// Dummy-Daten für Filialen und Tickets
const dummyTickets = [
  { id: 1, filialnummer: 7011, name: 'Filiale A', dauer: 1 },
  { id: 2, filialnummer: 7012, name: 'Filiale B', dauer: 2 },
  { id: 3, filialnummer: 7013, name: 'Filiale C', dauer: 1.5 },
  { id: 4, filialnummer: 7014, name: 'Filiale D', dauer: 3 },
];

const calculateOptimalRoute = (tickets, availableTime) => {
  let timeUsed = 0;
  const plannedRoute = [];

  for (const ticket of tickets) {
    if (timeUsed + ticket.dauer <= availableTime) {
      plannedRoute.push(ticket);
      timeUsed += ticket.dauer;
    }
  }

  return plannedRoute;
};

const Index = () => {
  const [availableTime, setAvailableTime] = useState('');
  const [route, setRoute] = useState([]);

  const handlePlanRoute = () => {
    const time = parseFloat(availableTime);
    if (!isNaN(time) && time > 0) {
      const optimalRoute = calculateOptimalRoute(dummyTickets, time);
      setRoute(optimalRoute);
    } else {
      setRoute([]);
    }
  };

  return (
    <Layout>
      <Box
        sx={{
          flexGrow: 1,
          p: 3,
          maxWidth: 1200,
          margin: '0 auto',
          bgcolor: 'transparent',
        }}
      >
        <PageHeader
          title="Technik Routenplanung"
          subtitle="Plane deine Route basierend auf verfügbarer Zeit und geplanter Dauer."
          Icon={QuickreplyOutlinedIcon}
        />

        {/* Eingabe für verfügbare Zeit */}
        <Box sx={{ mb: 3 }}>
          <TextField
            label="Verfügbare Zeit (in Stunden)"
            value={availableTime}
            onChange={(e) => setAvailableTime(e.target.value)}
            type="number"
            fullWidth
            sx={{ mb: 2 }}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handlePlanRoute}
          >
            Route planen
          </Button>
        </Box>

        {/* Geplante Route anzeigen */}
        <Box>
          <Typography variant="h5" sx={{ mb: 2 }}>
            Geplante Route:
          </Typography>
          {route.length > 0 ? (
            <Grid container spacing={2}>
              {route.map((ticket) => (
                <Grid item xs={12} sm={6} md={4} key={ticket.id}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6">{ticket.name}</Typography>
                      <Typography variant="body2">
                        Dauer: {ticket.dauer} Stunden
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Alert severity="info">
              Keine Tickets können innerhalb der verfügbaren Zeit bearbeitet
              werden.
            </Alert>
          )}
        </Box>
      </Box>
    </Layout>
  );
};

export default Index;
