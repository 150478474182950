import axiosInstance from "../../services/axiosInstance";
import React, {useContext} from "react";
import {InfoBarContext} from "../../provider/InfoBar";
import {IconButton} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';

const PictureDeleteButton = ({ pictureId, afterPictureDeleteFunction }) => {
  const {showError, showSuccess} = useContext(InfoBarContext);

  const handleDeletePicture = async () => {
    try {
      await axiosInstance.delete(`/pictures/delete/${pictureId}/`);
      if (afterPictureDeleteFunction) {
        await afterPictureDeleteFunction(pictureId);
      }
      showSuccess('Bild erfolgreich gelöscht');
    } catch (error) {
      console.error('Fehler beim Löschen des Bildes:', error);
      showError('Fehler beim Löschen des Bildes');
    }
  };
  return(
      <IconButton onClick={handleDeletePicture}>
        <DeleteIcon/>
      </IconButton>
  )
}

export default PictureDeleteButton