import React, {useContext, useEffect, useState} from 'react';
import {
  Box, Button, Grid, Typography, TextField, InputAdornment, IconButton, CircularProgress,
  Dialog, DialogTitle, DialogContent, DialogActions, Stack, Table, TableHead, TableRow, TableCell,
  TableBody, FormControl, InputLabel, Select, MenuItem, List, ListItem, ListItemText
} from '@mui/material';

import Layout from '../../../components/layout/Wiki/Wiki';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import axiosInstance from '../../../services/axiosInstance';
import {InfoBarContext} from "../../../provider/InfoBar";
import {useLocation, useNavigate} from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';

import EditIcon from '@mui/icons-material/Edit';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteIcon from '@mui/icons-material/Delete';

const CreateManualDialog = ({open, onClose, newManual, setNewManual, handleCreateManual, creating}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Neues Manual erstellen</DialogTitle>
      <DialogContent>
        <TextField
          label="Titel"
          value={newManual.title}
          onChange={(e) => setNewManual({...newManual, title: e.target.value})}
          fullWidth
          sx={{ mb: 2 }}
        />
        <TextField
          label="Beschreibung"
          value={newManual.description}
          onChange={(e) => setNewManual({...newManual, description: e.target.value})}
          fullWidth
          multiline
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Abbrechen</Button>
        <Button variant="contained" color="primary" onClick={handleCreateManual} disabled={creating}>
          {creating ? "Erstellen..." : "Erstellen"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CreateCategoryDialog = ({ open, onClose, onCreateCategory }) => {
  const [categoryName, setCategoryName] = useState('');

  const handleCreate = async () => {
    if (!categoryName.trim()) return;
    await onCreateCategory(categoryName);
    setCategoryName('');
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Neue Kategorie erstellen</DialogTitle>
      <DialogContent>
        <TextField
          label="Name"
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
          fullWidth
          sx={{ mb: 2 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Abbrechen</Button>
        <Button variant="contained" color="primary" onClick={handleCreate}>Erstellen</Button>
      </DialogActions>
    </Dialog>
  );
};

const CategoryManagementDialog = ({open, onClose, categories, onUpdateCategory, onDeleteCategory}) => {
  const [editingCategory, setEditingCategory] = useState(null);
  const [editedName, setEditedName] = useState('');

  const handleEditClick = (cat) => {
    setEditingCategory(cat);
    setEditedName(cat.name);
  };

  const handleEditSave = async () => {
    if (editingCategory && editedName.trim()) {
      await onUpdateCategory(editingCategory.id, editedName);
      setEditingCategory(null);
      setEditedName('');
    }
  };

  const handleEditCancel = () => {
    setEditingCategory(null);
    setEditedName('');
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Kategorien verwalten</DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Aktionen</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories.map((cat) => (
              <TableRow key={cat.id}>
                <TableCell>
                  {editingCategory && editingCategory.id === cat.id ? (
                    <TextField
                      value={editedName}
                      onChange={(e) => setEditedName(e.target.value)}
                      fullWidth
                    />
                  ) : (
                    cat.name
                  )}
                </TableCell>
                <TableCell>
                  {editingCategory && editingCategory.id === cat.id ? (
                    <>
                      <Button variant="contained" color="primary" onClick={handleEditSave} sx={{mr:1}}>Speichern</Button>
                      <Button onClick={handleEditCancel}>Abbrechen</Button>
                    </>
                  ) : (
                    <>
                      <IconButton onClick={() => handleEditClick(cat)} sx={{mr:1}}>
                        <EditIcon />
                      </IconButton>
                      <IconButton color="error" onClick={() => onDeleteCategory(cat.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Schließen</Button>
      </DialogActions>
    </Dialog>
  );
};

const SettingsIndex = () => {
  const [manuals, setManuals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [newManual, setNewManual] = useState({title: '', description: ''});
  const [creating, setCreating] = useState(false);
  const {showError, showSuccess} = useContext(InfoBarContext);
  const navigate = useNavigate();
  const location = useLocation();
  const isSettings = location.pathname.includes('/wiki/settings/');

  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);

  // Edit-Funktionen
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedManualForEdit, setSelectedManualForEdit] = useState(null);
  const [editManualData, setEditManualData] = useState({ title: '', description: '', category: null, group_ids: []});

  // Kategorien und Gruppen laden
  const [categories, setCategories] = useState([]);
  const [groups, setGroups] = useState([]);

  const [createCategoryOpen, setCreateCategoryOpen] = useState(false);
  const [manageCategoryOpen, setManageCategoryOpen] = useState(false);

  // Delete-Confirmation für Manuals
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [manualToDelete, setManualToDelete] = useState(null);

  useEffect(() => {
    fetchManuals();
    fetchCategories();
    fetchGroups();
  }, []);

  const fetchManuals = async () => {
    try {
      const response = await axiosInstance.get('/wiki/manuals/');
      if (response.data.results && Array.isArray(response.data.results)) {
        setManuals(response.data.results);
      } else if (Array.isArray(response.data)) {
        setManuals(response.data);
      } else {
        console.error('Unerwartetes Format:', response.data);
        showError('Fehler beim Laden der Manuals.');
      }
    } catch (err) {
      console.error('Fehler beim Laden der Manuals:', err);
      showError('Fehler beim Laden der Manuals. Bitte später erneut versuchen.');
    } finally {
      setLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const res = await axiosInstance.get('/wiki/categories/');
      const fetchedCategories = Array.isArray(res.data.results)
        ? res.data.results
        : (Array.isArray(res.data) ? res.data : []);
      setCategories(fetchedCategories);
    } catch (err) {
      console.error('Fehler beim Laden der Kategorien:', err);
    }
  };

  const fetchGroups = async () => {
    try {
      const res = await axiosInstance.get('/auth/custom-groups/');
      setGroups(Array.isArray(res.data) ? res.data : []);
    } catch (err) {
      console.error('Fehler beim Laden der Gruppen:', err);
    }
  };

  const handleOpenCreateDialog = () => {
    setOpenCreateDialog(true);
  };

  const handleCloseCreateDialog = () => {
    setOpenCreateDialog(false);
    setNewManual({title: '', description: ''});
  };

  const handleCreateManual = async () => {
    if (!newManual.title) {
      showError('Bitte einen Titel eingeben.');
      return;
    }
    setCreating(true);
    try {
      const response = await axiosInstance.post('/wiki/manuals/', newManual);
      setManuals([...manuals, response.data]);
      handleCloseCreateDialog();
      showSuccess('Manual erfolgreich erstellt');
    } catch (err) {
      console.error('Fehler beim Erstellen des Manuals:', err);
      showError('Fehler beim Erstellen des Manuals.');
    } finally {
      setCreating(false);
    }
  };

  const handleSelectManual = (manual) => {
    navigate(`/wiki/settings/${manual.slug}`);
  };

  const handleSearch = async () => {
    if (!searchQuery || searchQuery.length < 3) {
      setError('Bitte mind. 3 Zeichen eingeben.');
      return;
    }
    setSearchLoading(true);
    try {
      const response = await axiosInstance.get(`/wiki/search/?q=${encodeURIComponent(searchQuery)}`);
      setSearchResults(response.data);
      setError(null);
    } catch (err) {
      console.error('Search error:', err);
      setError('Fehler bei der Suche.');
    } finally {
      setSearchLoading(false);
    }
  };

  const handleSearchResultClick = (type, item) => {
    setSearchResults(null);
    const base = isSettings ? '/wiki/settings' : '/wiki/overview';

    if (type === 'manual') {
      navigate(`${base}/${item.slug}`);
    } else if (type === 'chapter') {
      navigate(`${base}/${item.manual}/${item.slug}`);
    } else if (type === 'entry') {
      navigate(`${base}/${item.manual.slug}/${item.chapter}/${item.slug}`);
    }
  };

  const handleEdit = (manual) => {
    setSelectedManualForEdit(manual);
    setEditManualData({
      title: manual.title,
      description: manual.description || '',
      category: manual.category || null,
      group_ids: manual.group_ids || []
    });
    setEditDialogOpen(true);
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    setSelectedManualForEdit(null);
  };

  const handleUpdateManual = async () => {
    if (!selectedManualForEdit) return;
    try {
      await axiosInstance.put(`/wiki/manuals/${selectedManualForEdit.slug}/`, editManualData);
      showSuccess('Manual erfolgreich aktualisiert');
      fetchManuals(); // Liste aktualisieren
      handleEditDialogClose();
    } catch (error) {
      console.error('Fehler beim Aktualisieren des Manuals:', error);
      showError('Fehler beim Aktualisieren des Manuals.');
    }
  };

  const handleOpenCreateCategory = () => {
    setCreateCategoryOpen(true);
  };

  const handleCloseCreateCategory = () => {
    setCreateCategoryOpen(false);
  };

  const handleCreateCategory = async (name) => {
    try {
      const res = await axiosInstance.post('/wiki/categories/', { name });
      showSuccess('Kategorie erfolgreich erstellt');
      await fetchCategories();
      setCreateCategoryOpen(false);
    } catch (err) {
      console.error('Fehler beim Erstellen der Kategorie:', err);
      showError('Fehler beim Erstellen der Kategorie.');
    }
  };

  const handleOpenManageCategory = () => {
    setManageCategoryOpen(true);
  };

  const handleCloseManageCategory = () => {
    setManageCategoryOpen(false);
  };

  const handleUpdateCategory = async (id, newName) => {
    try {
      await axiosInstance.put(`/wiki/categories/${id}/`, { name: newName });
      showSuccess('Kategorie erfolgreich aktualisiert');
      await fetchCategories();
    } catch (err) {
      console.error('Fehler beim Aktualisieren der Kategorie:', err);
      showError('Fehler beim Aktualisieren der Kategorie.');
    }
  };

  const handleDeleteCategory = async (id) => {
    try {
      await axiosInstance.delete(`/wiki/categories/${id}/`);
      showSuccess('Kategorie gelöscht');
      await fetchCategories();
    } catch (err) {
      console.error('Fehler beim Löschen der Kategorie:', err);
      showError('Fehler beim Löschen der Kategorie.');
    }
  };

  const handleDeleteManualClick = (manual) => {
    setManualToDelete(manual);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setManualToDelete(null);
  };

  const handleDeleteManual = async () => {
    if (!manualToDelete) return;
    try {
      await axiosInstance.delete(`/wiki/manuals/${manualToDelete.slug}/`);
      showSuccess('Manual erfolgreich gelöscht');
      fetchManuals();
    } catch (err) {
      console.error('Fehler beim Löschen des Manuals:', err);
      showError('Fehler beim Löschen des Manuals.');
    } finally {
      handleCloseDeleteDialog();
    }
  };

  return (
    <Layout>
      <Box sx={{flexGrow: 1, p: 3, maxWidth: 1200, margin: '0 auto', bgcolor: 'transparent'}}>
        <Grid container alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
          <Grid item>
            <PageHeader
              title="Wiki"
              subtitle="Eure zentrale Plattform für aktuelle Themen."
              Icon={AnnouncementOutlinedIcon}
            />
          </Grid>
          <Grid item>
            <Stack direction="row" spacing={2} alignItems="center">
              <TextField
                variant="outlined"
                placeholder="Suche..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyPress={(e) => { if (e.key === 'Enter') handleSearch(); }}
                InputProps={{
                  sx: {
                    borderRadius: '8px',
                    backgroundColor: '#f9f9f9',
                    '& .MuiOutlinedInput-notchedOutline': { borderColor: '#ccc' },
                    '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#bbb' }
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleSearch}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  width: '200px',
                  transition: 'width 0.3s ease',
                  '&:focus-within': { width: '300px' },
                  '& .MuiInputBase-input': { padding: '8px 12px' }
                }}
              />
            </Stack>
          </Grid>
        </Grid>

        {searchResults && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6">Suchergebnisse</Typography>
            {searchLoading ? (
              <CircularProgress />
            ) : (
              <List>
                {searchResults.manuals && searchResults.manuals.map((m) => (
                  <ListItem key={`manual-${m.id}`} button onClick={() => handleSearchResultClick('manual', m)}>
                    <ListItemText primary={m.title} secondary="Manual" />
                  </ListItem>
                ))}
                {searchResults.chapters && searchResults.chapters.map((c) => (
                  <ListItem key={`chapter-${c.id}`} button onClick={() => handleSearchResultClick('chapter', c)}>
                    <ListItemText primary={c.title} secondary={`Kapitel in ${c.manual}`} />
                  </ListItem>
                ))}
                {searchResults.entries && searchResults.entries.map((e) => (
                  <ListItem key={`entry-${e.id}`} button onClick={() => handleSearchResultClick('entry', e)}>
                    <ListItemText primary={e.title} secondary={`Eintrag in ${e.manual.title}`} />
                  </ListItem>
                ))}
                {(searchResults.manuals?.length === 0 && searchResults.chapters?.length === 0 && searchResults.entries?.length === 0) && (
                  <Typography variant="body2">Keine Ergebnisse.</Typography>
                )}
              </List>
            )}
          </Box>
        )}

        {isSettings && (
          <Box sx={{mb: 2, textAlign: 'left'}}>
            <Button variant="contained" color="primary" onClick={handleOpenCreateDialog} sx={{mr:2}}>
              Neues Manual erstellen
            </Button>
            <Button variant="contained" color="secondary" onClick={handleOpenCreateCategory} sx={{mr:2}}>
              Neue Kategorie erstellen
            </Button>
            <Button variant="outlined" onClick={handleOpenManageCategory}>
              Kategorien verwalten
            </Button>
          </Box>
        )}

        {loading ? (
          <Typography>Laden...</Typography>
        ) : manuals.length > 0 ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Titel</TableCell>
                <TableCell>Beschreibung</TableCell>
                <TableCell>Kategorie</TableCell>
                <TableCell>Gruppen</TableCell>
                <TableCell>Aktionen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {manuals.map((manual) => (
                <TableRow key={manual.id}>
                  <TableCell>{manual.title}</TableCell>
                  <TableCell>{manual.description}</TableCell>
                  <TableCell>{manual.category ? categories.find(c => c.id === manual.category)?.name : 'Keine'}</TableCell>
                  <TableCell>
                    {manual.group_ids && manual.group_ids.length > 0
                      ? manual.group_ids.map(gid => groups.find(g => g.id === gid)?.name || gid).join(', ')
                      : 'Keine'}
                  </TableCell>
                  <TableCell>
                    <IconButton color="primary" onClick={() => handleSelectManual(manual)} sx={{mr:1}}>
                      <ArrowForwardIosIcon />
                    </IconButton>
                    {isSettings && (
                      <>
                        <IconButton onClick={() => handleEdit(manual)} sx={{mr:1}}>
                          <EditIcon />
                        </IconButton>
                        <IconButton color="error" onClick={() => handleDeleteManualClick(manual)}>
                          <DeleteIcon />
                        </IconButton>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Typography variant="body1">Keine Manuals verfügbar.</Typography>
        )}

        {isSettings && (
          <CreateManualDialog
            open={openCreateDialog}
            onClose={handleCloseCreateDialog}
            newManual={newManual}
            setNewManual={setNewManual}
            handleCreateManual={handleCreateManual}
            creating={creating}
          />
        )}

        {/* Dialog zum Bearbeiten eines Manuals */}
        <Dialog open={editDialogOpen} onClose={handleEditDialogClose}>
          <DialogTitle>Manual bearbeiten</DialogTitle>
          <DialogContent>
            <TextField
              label="Titel"
              value={editManualData.title}
              onChange={(e) => setEditManualData({ ...editManualData, title: e.target.value })}
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="Beschreibung"
              value={editManualData.description}
              onChange={(e) => setEditManualData({ ...editManualData, description: e.target.value })}
              fullWidth
              multiline
              sx={{ mb: 2 }}
            />

            {/* Kategorie-Auswahl */}
            <FormControl fullWidth sx={{mb:2}}>
              <InputLabel>Kategorie</InputLabel>
              <Select
                label="Kategorie"
                value={editManualData.category || ''}
                onChange={(e) => setEditManualData({...editManualData, category: e.target.value})}
              >
                <MenuItem value="">Keine</MenuItem>
                {categories.map((cat) => (
                  <MenuItem key={cat.id} value={cat.id}>{cat.name}</MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Gruppen-Auswahl */}
            <FormControl fullWidth>
              <InputLabel>Gruppen</InputLabel>
              <Select
                label="Gruppen"
                multiple
                value={editManualData.group_ids}
                onChange={(e) => setEditManualData({...editManualData, group_ids: e.target.value})}
              >
                {groups.map((grp) => (
                  <MenuItem key={grp.id} value={grp.id}>{grp.name}</MenuItem>
                ))}
              </Select>
            </FormControl>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditDialogClose}>Abbrechen</Button>
            <Button variant="contained" color="primary" onClick={handleUpdateManual}>Speichern</Button>
          </DialogActions>
        </Dialog>

        {/* Dialog zum Erstellen einer neuen Kategorie */}
        <CreateCategoryDialog
          open={createCategoryOpen}
          onClose={handleCloseCreateCategory}
          onCreateCategory={handleCreateCategory}
        />

        {/* Dialog zum Verwalten der Kategorien */}
        <CategoryManagementDialog
          open={manageCategoryOpen}
          onClose={handleCloseManageCategory}
          categories={categories}
          onUpdateCategory={handleUpdateCategory}
          onDeleteCategory={handleDeleteCategory}
        />

        {/* Dialog zum Löschen eines Manuals */}
        <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
          <DialogTitle>Manual löschen</DialogTitle>
          <DialogContent>
            <Typography>Möchten Sie das Manual "{manualToDelete?.title}" wirklich löschen?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog}>Abbrechen</Button>
            <Button variant="contained" color="error" onClick={handleDeleteManual}>Löschen</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Layout>
  );
};

export default SettingsIndex;
