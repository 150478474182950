import React, {useContext, useEffect, useState} from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  Divider,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Pagination,
  Switch,
} from '@mui/material';
import axiosInstance from "../../../services/axiosInstance";
import DropdownFilter from '../../../components/filter/DropdownFilter';
import PageHeader from "../../../components/layout/Title/TitelSmall";
import SortimentLayout from "../../../components/layout/Sortiment/SortimentLayout";
import SettingsIcon from '@mui/icons-material/Settings';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import {fetchTeigGeschmackOptions, handleChangeGeschmack, handleChangeTeig} from "../../../services/teigGeschmackService";
import TextField from "@mui/material/TextField";
import {InfoBarContext} from "../../../provider/InfoBar";
import CircularProgress from "@mui/material/CircularProgress";

export default function ArtikelSettings() {
  const {showError, showSuccess} = useContext(InfoBarContext);
  const [artikel, setArtikel] = useState([]);
  const [filteredArtikel, setFilteredArtikel] = useState(null);
  const [geschmackOptions, setGeschmackOptions] = useState([]);
  const [newGeschmack, setNewGeschmack] = useState('');
  const [teigOptions, setTeigOptions] = useState([]);
  const [newTeig, setNewTeig] = useState('');
  const [page, setPage] = useState(1);
  const [openDialog, setOpenDialog] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const itemsPerPage = 10;

  const fetchArtikelData = async () => {
    try {
      const response = await axiosInstance.get('/theke/artikel-properties/');
      const artikelArray = Array.isArray(response.data.results) ? response.data.results : [];
      console.log("fetched artikel", artikelArray)
      setArtikel(artikelArray);
    } catch (error) {
      console.error('Error fetching artikel:', error);
      setArtikel([]);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      await fetchArtikelData();
      setLoading(false)
      const [geschmackOptions, teigOptions] = await fetchTeigGeschmackOptions();
      setTeigOptions(teigOptions);
      setGeschmackOptions(geschmackOptions);
    };
    fetchData();
  }, []);

  const handleToggleSeasonal = async (artikelnummer, seasonal) => {
    console.log("item", artikelnummer, seasonal)
    try {
      const newSeasonalStatus = seasonal === null || seasonal === false;
      setArtikel(item => item.map((artikel) => {
        if (artikel.artikelnummer === artikelnummer) {
          return {
            ...artikel,
            seasonal: newSeasonalStatus
          };
        }
        return artikel;
      }));
      await axiosInstance.patch(`/theke/artikel-properties/${artikelnummer}/`, {
        seasonal: newSeasonalStatus
      });
      showSuccess('Saisonal Status wurde erfolgreich aktualisiert');
    } catch (error) {
      console.error('Error updating seasonal status:', error);
      showError('Fehler beim Aktualisieren des Saisonal Status');
    }
  };

  const createNewGeschmack = async () => {
    try {
      const response = await axiosInstance.post('/theke/geschmack/', {
        geschmack: newGeschmack
      });
      console.log('New geschmack created:', response.data);
      setGeschmackOptions([...geschmackOptions, newGeschmack]);
      setNewGeschmack('');
      showSuccess('Neuer Geschmack wurde erfolgreich erstellt');
    } catch (error) {
      console.error('Error creating new geschmack:', error);
      showError('Fehler beim Erstellen des neuen Geschmacks');
    }
  }

  const createNewTeig = async () => {
    try {
      const response = await axiosInstance.post('/theke/teig/', {
        teig: newTeig
      });
      console.log('New teig created:', response.data);
      setTeigOptions([...teigOptions, newTeig]);
      setNewTeig('');
      showSuccess('Neuer Teig wurde erfolgreich erstellt');
    } catch (error) {
      console.error('Error creating new teig:', error);
      showError('Fehler beim Erstellen des neuen Teigs');
    }
  }

  const handleArtikelFilter = (selectedArtikel) => {
    setFilteredArtikel(selectedArtikel);
  }

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const getCurrentPageItems = () => {
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    if (filteredArtikel?.label?.length > 0) {
      return artikel.filter(item => item.artikel === filteredArtikel.label);
    }
    return artikel.slice(startIndex, endIndex);
  };

  const handleUpload = async (artikelnummer, artikelName) => {
    const fileInput = document.getElementById(`file-upload`);
    fileInput.click();

    fileInput.onchange = async (event) => {
      const file = event.target.files[0];

      if (!file) {
        showError('Keine Datei ausgewählt');
        return;
      }

      const formData = new FormData();
      formData.append('image', file);
      formData.append('title', artikelName);

      try {
        const response = await axiosInstance.post('/pictures/upload/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        });
        const image_id = response.data.image_id;
        console.log("uploaded image", image_id)

        await axiosInstance.patch('/theke/artikel-properties/' + artikelnummer + '/', {
          image_id: image_id
        });
        fetchArtikelData();
        showSuccess('Bild wurde erfolgreich hochgeladen');
      } catch (error) {
        console.error('Upload failed:', error);
        showError('Fehler beim Hochladen des Bildes');
      }
    };
  };

  const handleImageClick = async (image_id) => {
    try {
      console.log("image_id", image_id)
      const response = await axiosInstance.get('/pictures/get-image-url/' + image_id + '/');
      setImageUrl(response.data.url);
      setOpenDialog(true);
    } catch (error) {
      console.error('Error fetching image URL:', error);
      showError('Fehler beim Laden des Bildes');
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setImageUrl('');
  };

  return (
      <SortimentLayout>
        <Box sx={{p: 3, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <div style={{width: '100%', maxWidth: '1400px'}}>
            <PageHeader title="Artikeleinstellungen"
                        subtitle="Hier können Sie Ihre Artikel konfigurieren."
                        Icon={SettingsIcon}/>
            <Card sx={{width: '100%'}}>

              <CardContent>
                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: "space-between", mb: 1}}>
                  <DropdownFilter
                      label="Artikel-Suche"
                      options={artikel ? artikel.map(item => ({label: item.artikel, value: item.artikelnummer})) : []}
                      value={filteredArtikel}
                      onChange={handleArtikelFilter}
                  />
                  <Box sx={{display: 'flex', gap: 2, alignItems: 'center'}}>
                    <TextField
                        label="Neuer Geschmack"
                        value={newGeschmack}
                        onChange={(e) => setNewGeschmack(e.target.value)}
                        variant="outlined"
                        size="small"
                        sx={{width: '180px'}}
                    />
                    <Button
                        variant="contained"
                        onClick={createNewGeschmack}
                        disabled={!newGeschmack.trim()}
                        sx={{width: '140px'}}
                    >
                      Hinzufügen
                    </Button>
                    <TextField
                        label="Neuer Teig"
                        value={newTeig}
                        onChange={(e) => setNewTeig(e.target.value)}
                        variant="outlined"
                        size="small"
                        sx={{width: '180px'}}
                    />
                    <Button
                        variant="contained"
                        onClick={createNewTeig}
                        disabled={!newTeig.trim()}
                        sx={{width: '128px'}}
                    >
                      Hinzufügen
                    </Button>
                  </Box>
                </Box>
                <input
                    type="file"
                    id={`file-upload`}
                    style={{display: 'none'}}
                />
                {loading &&
                    <Box sx={{
                      width: '100%',
                      height: '586px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                      <CircularProgress size={80}/>
                    </Box>
                }
                {!loading &&
                    <List sx={{width: '100%', bgcolor: 'background.paper'}}>
                      {getCurrentPageItems().map((item) => (
                          <React.Fragment key={item.artikelnummer}>
                            <ListItem>
                              <ListItemText
                                  primary={item.artikel}
                              />
                              <Box sx={{display: 'flex', gap: 2, alignItems: 'center'}}>
                                <DropdownFilter
                                    label="Geschmack"
                                    options={geschmackOptions ? geschmackOptions.map(option => ({label: option, value: option})) : []}
                                    value={item.geschmack ? {label: item.geschmack, value: item.geschmack} : null}
                                      onChange={(selectedOption) => handleChangeGeschmack(
                                        item.artikelnummer, 
                                        setArtikel, 
                                        selectedOption?.value,
                                        { showError, showSuccess }
                                    )}
                                    small
                                    sx={{mr: 2}}
                                />
                                <FormControlLabel
                                    control={
                                      <Switch
                                          checked={item.seasonal || false}
                                          onChange={() => handleToggleSeasonal(item.artikelnummer, item.seasonal)}
                                      />
                                    }
                                    label="Saisonal"
                                    sx={{mr: 1}}
                                />
                                <DropdownFilter
                                    label="Teig"
                                    options={teigOptions ? teigOptions.map(option => ({label: option, value: option})) : []}
                                    value={item.teig ? {label: item.teig, value: item.teig} : null}
                                    onChange={(selectedOption) => handleChangeTeig(
                                        item.artikelnummer, 
                                        setArtikel, 
                                        selectedOption?.value,
                                        { showError, showSuccess }
                                    )}
                                    small
                                    sx={{mr: 2}}
                                />
                                <IconButton
                                    color="primary"
                                    onClick={() => handleUpload(item.artikelnummer, item.artikel)}
                                >
                                  <CloudUploadIcon/>
                                </IconButton>
                                <IconButton
                                    color="primary"
                                    onClick={() => handleImageClick(item.image_id)}
                                    disabled={!item.image_id}
                                >
                                  <PhotoLibraryIcon/>
                                </IconButton>
                              </Box>
                            </ListItem>
                            <Divider/>
                          </React.Fragment>
                      ))}
                    </List>}
                {!filteredArtikel && <Pagination
                    count={Math.ceil(artikel.length / itemsPerPage)}
                    page={page}
                    onChange={handlePageChange}
                    color="primary"
                    shape="rounded"
                />}
              </CardContent>
            </Card>
          </div>
        </Box>
        <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
          <DialogContent>
            <img src={imageUrl} alt="Artikel" style={{width: '100%'}}/>
          </DialogContent>
        </Dialog>
      </SortimentLayout>
  );
}
