import React from 'react';
import { Box, useTheme } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { format, parse, isValid } from 'date-fns';
import { useMediaQuery } from '@mui/material';

const RechartsLineChart = ({ data, title, height }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

    const dateFormat = 'yyyy.MM';
    const processedData = data.reduce((acc, item) => {
        const dateString = item.x;
        const date = parse(dateString, dateFormat, new Date());
        
        if (!isValid(date)) {
            console.warn(`Invalid date: ${dateString}`);
            return acc;
        }

        const xValue = format(date, 'yyyy.MM');
        let entry = acc.find(e => e.x === xValue);
        if (!entry) {
            entry = { x: xValue };
            acc.push(entry);
        }
        entry[item.label] = item.y || 0;
        return acc;
    }, []);

    const uniqueLabels = [...new Set(data.map(item => item.label))];

    const allYValues = data.map(item => item.y || 0);
    const minYValue = Math.min(...allYValues);
    const maxYValue = Math.max(...allYValues);
    const adjustedMinYValue = minYValue > 0 
        ? Math.max(0, minYValue - (maxYValue - minYValue) * 0.2) 
        : minYValue - (maxYValue - minYValue) * 0.2;
    const adjustedMaxYValue = maxYValue + (maxYValue - minYValue) * 0.2;

    const getFontSize = () => {
        if (isSmallScreen) {
            return 8;
        } else if (isMediumScreen) {
            return 10;
        } else {
            return 14;
        }
    };

    const formatNumberWithCommas = (number, withDecimals = false) => {
        return number.toLocaleString('de-DE', {
            maximumFractionDigits: 2,
           
        });
    };

    return (
        <Box>
            <ResponsiveContainer width="100%" aspect={isSmallScreen ? 1 : 3}>
                <LineChart
                    data={processedData}
                    margin={{ top: 10, right: 10, bottom: 10, left: 20 }} // Erhöhter Abstand links
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="x"
                        tick={{ fontSize: getFontSize() }}
                        padding={{ left: 10, right: 10 }}
                    />
                    <YAxis
                        tick={{ fontSize: getFontSize() }}
                        width={isSmallScreen ? 50 : 70} // Breitere Y-Achse für Platz
                        tickFormatter={(value) => formatNumberWithCommas(value)} // Ohne Nachkommastellen
                        domain={[adjustedMinYValue, adjustedMaxYValue]} 
                    />
                    <Tooltip
                        contentStyle={{ fontSize: getFontSize() }}
                        formatter={(value) => formatNumberWithCommas(value, true)} // Mit Nachkommastellen
                    />
                    <Legend 
                        wrapperStyle={{ fontSize: getFontSize() }}
                        iconType="circle"
                        iconSize={getFontSize()}
                    />
                    {uniqueLabels.map(label => (
                        <Line
                            key={label}
                            type="monotone"
                            dataKey={label}
                            stroke={label === 'Vorjahr' ? theme.palette.chart.pink : theme.palette.primary.main}
                            activeDot={{ r: 8 }}
                        />
                    ))}
                </LineChart>
            </ResponsiveContainer>
        </Box>
    );
};

export default RechartsLineChart;
