import React from 'react';
import { Box, Button, ButtonGroup, Typography, Divider } from '@mui/material';
import ViewListIcon from '@mui/icons-material/ViewList';
import SettingsIcon from '@mui/icons-material/Settings';
import SaveIcon from '@mui/icons-material/Save';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { useNavigate } from 'react-router-dom';
import SortimentTypeSelector from "./controls/SortimentTypeSelector";

const ThekeControls = ({
  onOverviewClick,
  onSettingsClick,
  onSave,
  planStartDatum,
  planEndDatum,
  planName,
  savingData,
  sortimentType,
  setSortimentType
}) => {
  const navigate = useNavigate();

  return (
    <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={onOverviewClick}
          sx={{ mr: 2 }}
        >
          <ViewListIcon />
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onSettingsClick}
          sx={{ mr: 2 }}
        >
          <SettingsIcon />
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onSave}
          disabled={savingData}
          sx={{ mr: 2 }}
        >
          <SaveIcon />
        </Button>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {planStartDatum && planEndDatum ? (
            <>
              <Typography variant="body1" sx={{ fontSize: '1.35rem', padding: '3px' }}>
                {planStartDatum.format('DD.MM.YYYY')} - {planEndDatum.format('DD.MM.YYYY')}
              </Typography>
              {planName && (
                <>
                  <Divider orientation="vertical" flexItem sx={{ mx: 2, height: '36px' }} />
                  <Typography variant="body1" sx={{ fontSize: '1.35rem', padding: '3px' }}>
                    {planName}
                  </Typography>
                </>
              )}
            </>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate('/sortiment/overview/kalender')}
            >
              <CalendarTodayIcon />
            </Button>
          )}
        </Box>
      </Box>
      <SortimentTypeSelector
        sortimentType={sortimentType}
        setSortimentType={setSortimentType}
      />
    </Box>
  );
};

export default ThekeControls; 