// Mitarbeiter.jsx

import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Select,
  MenuItem,
  CircularProgress,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tabs,
  Tab,
  AppBar,
} from '@mui/material';
import axiosInstance from '../../../services/axiosInstance';
import Layout from '../../../components/layout/Personalentwicklung/Personalentwicklung';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { FilterContext } from '../../../provider/FilterProvider';

// Importiere die benötigten Komponenten von recharts
import {
  Radar,
  RadarChart,
  PolarGrid,
  Legend,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
} from 'recharts';

// Importiere den useAuth Hook
import { useAuth } from '../../../provider/authProvider';

// Importiere das Info-Icon
import { Info as InfoIcon, Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';

const Mitarbeiter = () => {
  const [employees, setEmployees] = useState([]);
  const [kompetenzMatrices, setKompetenzMatrices] = useState([]);
  const [kompetenzAssignments, setKompetenzAssignments] = useState([]);
  const [kompetenzFelder, setKompetenzFelder] = useState([]);
  const [bewertungen, setBewertungen] = useState({});
  const [loading, setLoading] = useState(false);

  const [gespraeche, setGespraeche] = useState([]);
  const [auditLogs, setAuditLogs] = useState([]);

  const { filterState, handleEmployeeChange } = useContext(FilterContext);
  const selectedEmployee = filterState.selectedEmployee;

  // Hole den aktuellen Benutzer
  const { user } = useAuth(); // Annahme: user enthält die Benutzerinformationen

  // Zustand für den Dialog
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogData, setDialogData] = useState({});

  // Zustand für Tabs
  const [tabIndex, setTabIndex] = useState(0);

  // Zustand für Gesprächs-Dialog
  const [openGesprächDialog, setOpenGesprächDialog] = useState(false);
  const [gesprächData, setGesprächData] = useState({});

  // Mitarbeiterdaten laden
  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get('personaldaten/stammdaten-mitarbeiter/')
      .then((response) => {
        setEmployees(response.data.results || response.data);
      })
      .catch((error) => {
        console.error('Fehler beim Laden der Mitarbeiter:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // Kompetenzmatrizen und Zuweisungen laden
  useEffect(() => {
    axiosInstance
      .get('/personalentwicklung/kompetenz-matrix/')
      .then((response) => {
        setKompetenzMatrices(response.data.results || response.data);
      })
      .catch((error) => {
        console.error('Fehler beim Laden der Kompetenzmatrizen:', error);
      });

    axiosInstance
      .get('/personalentwicklung/mitarbeiter-kompetenz/')
      .then((response) => {
        setKompetenzAssignments(response.data.results || response.data);
      })
      .catch((error) => {
        console.error('Fehler beim Laden der Zuweisungen:', error);
      });
  }, []);

  // Kompetenzfelder und Bewertungen laden
  useEffect(() => {
    if (!selectedEmployee) return;

    const assignment = kompetenzAssignments.find((a) => a.employee === selectedEmployee);

    if (assignment && assignment.matrix) {
      const matrixId = assignment.matrix;

      // Kompetenzfelder laden
      axiosInstance
        .get(`/personalentwicklung/kompetenz-feld/?matrix=${matrixId}`)
        .then((response) => {
          const felder = response.data.results || response.data;
          setKompetenzFelder(felder);

          // Bewertungen laden
          axiosInstance
            .get(`/personalentwicklung/kompetenz-bewertung/`, {
              params: { employee: selectedEmployee },
            })
            .then((response) => {
              const bewertungenData = response.data.results || response.data;
              const bewertungenMap = {};

              bewertungenData.forEach((bewertung) => {
                if (!bewertungenMap[bewertung.feld]) {
                  bewertungenMap[bewertung.feld] = {};
                }
                bewertungenMap[bewertung.feld][bewertung.bewertungstyp] = {
                  id: bewertung.id,
                  wert: bewertung.wert,
                  bewertet_von: bewertung.bewertet_von,
                  bewertet_am: bewertung.bewertet_am,
                };
              });

              setBewertungen(bewertungenMap);
            })
            .catch((error) => {
              console.error('Fehler beim Laden der Bewertungen:', error);
            });
        })
        .catch((error) => {
          console.error('Fehler beim Laden der Kompetenzfelder:', error);
        });
    }

    // Gespräche laden
    axiosInstance
      .get('/personalentwicklung/gespraech/', {
        params: { employee: selectedEmployee },
      })
      .then((response) => {
        setGespraeche(response.data.results || response.data);
      })
      .catch((error) => {
        console.error('Fehler beim Laden der Gespräche:', error);
      });

    // AuditLogs laden
    axiosInstance
      .get('/personalentwicklung/audit-log/', {
        params: { objekt_id: selectedEmployee, model_name: 'StammdatenMitarbeiter' },
      })
      .then((response) => {
        setAuditLogs(response.data.results || response.data);
      })
      .catch((error) => {
        console.error('Fehler beim Laden der Audit Logs:', error);
      });
  }, [selectedEmployee, kompetenzAssignments]);

  // Daten für das Radar-Diagramm vorbereiten
  const radarData = kompetenzFelder.map((feld) => ({
    Kompetenz: feld.name,
    Selbsteinschätzung: bewertungen[feld.id]?.Selbsteinschätzung?.wert || 0,
    Fremdeinschätzung: bewertungen[feld.id]?.Fremdeinschätzung?.wert || 0,
    fullMark: feld.skala_max,
  }));

  // Bewertung aktualisieren oder erstellen
  const handleBewertungChange = (feldId, bewertungstyp, wert, skalaMax) => {
    // Validierung: Wert darf nicht größer als skalaMax sein
    if (wert > skalaMax) {
      alert(`Der Wert darf nicht größer als ${skalaMax} sein.`);
      return;
    }

    const existingBewertung = bewertungen[feldId]?.[bewertungstyp];

    if (existingBewertung) {
      // Update bestehender Bewertung
      axiosInstance
        .patch(`/personalentwicklung/kompetenz-bewertung/${existingBewertung.id}/`, {
          wert: wert,
        })
        .then((response) => {
          const updatedBewertung = response.data;
          setBewertungen((prevState) => ({
            ...prevState,
            [feldId]: {
              ...prevState[feldId],
              [bewertungstyp]: {
                id: updatedBewertung.id,
                wert: updatedBewertung.wert,
                bewertet_von: updatedBewertung.bewertet_von,
                bewertet_am: updatedBewertung.bewertet_am,
              },
            },
          }));
        })
        .catch((error) => {
          console.error('Fehler beim Aktualisieren der Bewertung:', error);
        });
    } else {
      // Neue Bewertung erstellen
      axiosInstance
        .post(`/personalentwicklung/kompetenz-bewertung/`, {
          employee: selectedEmployee,
          feld: feldId,
          wert: wert,
          bewertungstyp: bewertungstyp,
        })
        .then((response) => {
          const newBewertung = response.data;
          setBewertungen((prevState) => ({
            ...prevState,
            [feldId]: {
              ...prevState[feldId],
              [bewertungstyp]: {
                id: newBewertung.id,
                wert: newBewertung.wert,
                bewertet_von: newBewertung.bewertet_von,
                bewertet_am: newBewertung.bewertet_am,
              },
            },
          }));
        })
        .catch((error) => {
          console.error('Fehler beim Erstellen der Bewertung:', error);
        });
    }
  };

  // Funktionen zum Öffnen und Schließen des Dialogs
  const handleOpenDialog = (bewertung) => {
    setDialogData(bewertung);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDialogData({});
  };

  // Tab-Wechsel
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  // Gespräch hinzufügen oder bearbeiten
  const handleOpenGesprächDialog = (gespraech = {}) => {
    setGesprächData(gespraech);
    setOpenGesprächDialog(true);
  };

  const handleCloseGesprächDialog = () => {
    setOpenGesprächDialog(false);
    setGesprächData({});
  };

  const handleSaveGespräch = () => {
    if (gesprächData.id) {
      // Update bestehendes Gespräch
      axiosInstance
        .patch(`/personalentwicklung/gespraech/${gesprächData.id}/`, gesprächData)
        .then((response) => {
          setGespraeche((prevState) =>
            prevState.map((item) => (item.id === response.data.id ? response.data : item))
          );
          handleCloseGesprächDialog();
        })
        .catch((error) => {
          console.error('Fehler beim Aktualisieren des Gesprächs:', error);
        });
    } else {
      // Neues Gespräch erstellen
      axiosInstance
        .post(`/personalentwicklung/gespraech/`, {
          ...gesprächData,
          employee: selectedEmployee,
        })
        .then((response) => {
          setGespraeche((prevState) => [...prevState, response.data]);
          handleCloseGesprächDialog();
        })
        .catch((error) => {
          console.error('Fehler beim Erstellen des Gesprächs:', error);
        });
    }
  };

  // Gespräch löschen
  const handleDeleteGespräch = (id) => {
    if (window.confirm('Möchten Sie dieses Gespräch wirklich löschen?')) {
      axiosInstance
        .delete(`/personalentwicklung/gespraech/${id}/`)
        .then(() => {
          setGespraeche((prevState) => prevState.filter((item) => item.id !== id));
        })
        .catch((error) => {
          console.error('Fehler beim Löschen des Gesprächs:', error);
        });
    }
  };

  // Mitarbeiterdaten finden
  const selectedEmployeeData = employees.find(
    (employee) => employee.mitarbeiterId === selectedEmployee
  );

  return (
    <Layout>
      <Box sx={{ maxWidth: 1000, mx: 'auto', mt: 4 }}>
        {/* Header der Seite */}

        <Typography variant="h5" gutterBottom>
          Mitarbeiter Kompetenzübersicht
        </Typography>

        {/* Mitarbeiter-Auswahl */}
        {!selectedEmployee && (
          <Select
            value={selectedEmployee || ''}
            onChange={(e) => handleEmployeeChange(e.target.value)}
            displayEmpty
            sx={{ mb: 4, width: '100%' }}
          >
            <MenuItem value="" disabled>
              Mitarbeiter auswählen
            </MenuItem>
            {employees.map((employee) => (
              <MenuItem key={employee.mitarbeiterId} value={employee.mitarbeiterId}>
                {employee.name}
              </MenuItem>
            ))}
          </Select>
        )}

        {loading && <CircularProgress />}

        {/* Wenn Mitarbeiter ausgewählt */}
        {selectedEmployee && selectedEmployeeData && (
          <>
            <Typography variant="h6" gutterBottom>
              Ausgewählter Mitarbeiter: {selectedEmployeeData.name}
            </Typography>

            {/* Tabs hinzufügen */}
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabIndex} onChange={handleTabChange}>
                <Tab label="Kompetenzmatrix" />
                <Tab label="Gespräche & Audit Log" />
              </Tabs>
              </Box>

            {tabIndex === 0 && (
              <>
                {/* Radar-Diagramm */}
                <Paper sx={{ mt: 4, p: 4, boxShadow: 3 }}>
                  <Typography variant="h6" gutterBottom>
                    Kompetenzübersicht
                  </Typography>
                  <ResponsiveContainer width="100%" height={400}>
                    <RadarChart data={radarData}>
                      <PolarGrid />
                      <PolarAngleAxis dataKey="Kompetenz" />
                      <PolarRadiusAxis
                        angle={30}
                        domain={[0, Math.max(...kompetenzFelder.map((feld) => feld.skala_max))]}
                      />
                      <Radar
                        name="Selbsteinschätzung"
                        dataKey="Selbsteinschätzung"
                        stroke="#8884d8"
                        fill="#8884d8"
                        fillOpacity={0.6}
                      />
                      <Radar
                        name="Fremdeinschätzung"
                        dataKey="Fremdeinschätzung"
                        stroke="#82ca9d"
                        fill="#82ca9d"
                        fillOpacity={0.6}
                      />
                      <Legend />
                    </RadarChart>
                  </ResponsiveContainer>
                </Paper>

                {/* Kompetenzmatrix anzeigen */}
                <Paper sx={{ mt: 4, p: 4, boxShadow: 3 }}>
                  <Typography variant="h6" gutterBottom>
                    Kompetenzmatrix:{' '}
                    {kompetenzMatrices.find(
                      (matrix) =>
                        matrix.id ===
                        kompetenzAssignments.find((a) => a.employee === selectedEmployee)?.matrix
                    )?.name || 'Keine Matrix zugewiesen'}
                  </Typography>

                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Kompetenzfeld</TableCell>
                        <TableCell>Beschreibung</TableCell>
                        <TableCell>Selbsteinschätzung</TableCell>
                        <TableCell>Fremdeinschätzung</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {kompetenzFelder.map((feld) => (
                        <TableRow key={feld.id}>
                          <TableCell>{feld.name}</TableCell>
                          <TableCell>{feld.beschreibung || 'Keine Beschreibung'}</TableCell>
                          <TableCell>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <TextField
                                type="number"
                                InputProps={{ inputProps: { min: 1, max: feld.skala_max } }}
                                value={bewertungen[feld.id]?.Selbsteinschätzung?.wert || ''}
                                onChange={(e) => {
                                  const inputValue = e.target.value ? parseInt(e.target.value) : '';
                                  // Zusätzliche Validierung im Frontend
                                  if (inputValue > feld.skala_max) {
                                    alert(`Der Wert darf nicht größer als ${feld.skala_max} sein.`);
                                    return;
                                  }
                                  handleBewertungChange(
                                    feld.id,
                                    'Selbsteinschätzung',
                                    inputValue,
                                    feld.skala_max
                                  );
                                }}
                              />
                              {bewertungen[feld.id]?.Selbsteinschätzung && (
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    handleOpenDialog(bewertungen[feld.id]?.Selbsteinschätzung)
                                  }
                                >
                                  <InfoIcon />
                                </IconButton>
                              )}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <TextField
                                type="number"
                                InputProps={{ inputProps: { min: 1, max: feld.skala_max } }}
                                value={bewertungen[feld.id]?.Fremdeinschätzung?.wert || ''}
                                onChange={(e) => {
                                  const inputValue = e.target.value ? parseInt(e.target.value) : '';
                                  // Zusätzliche Validierung im Frontend
                                  if (inputValue > feld.skala_max) {
                                    alert(`Der Wert darf nicht größer als ${feld.skala_max} sein.`);
                                    return;
                                  }
                                  handleBewertungChange(
                                    feld.id,
                                    'Fremdeinschätzung',
                                    inputValue,
                                    feld.skala_max
                                  );
                                }}
                              />
                              {bewertungen[feld.id]?.Fremdeinschätzung && (
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    handleOpenDialog(bewertungen[feld.id]?.Fremdeinschätzung)
                                  }
                                >
                                  <InfoIcon />
                                </IconButton>
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </>
            )}

            {tabIndex === 1 && (
              <>
                {/* Gespräche anzeigen */}
                <Paper sx={{ mt: 4, p: 4, boxShadow: 3 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6" gutterBottom>
                      Gespräche
                    </Typography>
                    <Button variant="contained" onClick={() => handleOpenGesprächDialog()}>
                      Neues Gespräch hinzufügen
                    </Button>
                  </Box>

                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Datum</TableCell>
                        <TableCell>Typ</TableCell>
                        <TableCell>Notizen</TableCell>
                        <TableCell>Wiedervorlage am</TableCell>
                        <TableCell>Aktionen</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {gespraeche.map((gespraech) => (
                        <TableRow key={gespraech.id}>
                          <TableCell>{gespraech.datum}</TableCell>
                          <TableCell>{gespraech.typ}</TableCell>
                          <TableCell>{gespraech.notizen}</TableCell>
                          <TableCell>{gespraech.wiedervorlage_am || 'N/A'}</TableCell>
                          <TableCell>
                            <IconButton onClick={() => handleOpenGesprächDialog(gespraech)}>
                              <EditIcon />
                            </IconButton>
                            <IconButton onClick={() => handleDeleteGespräch(gespraech.id)}>
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>

                {/* Audit Logs anzeigen */}
                <Paper sx={{ mt: 4, p: 4, boxShadow: 3 }}>
                  <Typography variant="h6" gutterBottom>
                    Audit Logs
                  </Typography>

                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Aktion</TableCell>
                        <TableCell>Model</TableCell>
                        <TableCell>Benutzer</TableCell>
                        <TableCell>Geändert am</TableCell>
                        <TableCell>Details</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {auditLogs.map((log) => (
                        <TableRow key={log.id}>
                          <TableCell>{log.aktion}</TableCell>
                          <TableCell>{log.model_name}</TableCell>
                          <TableCell>{log.benutzer}</TableCell>
                          <TableCell>
                            {new Date(log.geändert_am).toLocaleString()}
                          </TableCell>
                          <TableCell>{log.details}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>

                {/* Dialog für Gespräch hinzufügen/bearbeiten */}
                <Dialog open={openGesprächDialog} onClose={handleCloseGesprächDialog} maxWidth="sm" fullWidth>
                  <DialogTitle>
                    {gesprächData.id ? 'Gespräch bearbeiten' : 'Neues Gespräch hinzufügen'}
                  </DialogTitle>
                  <DialogContent>
                    <TextField
                      margin="dense"
                      label="Datum"
                      type="date"
                      fullWidth
                      value={gesprächData.datum || ''}
                      onChange={(e) =>
                        setGesprächData({ ...gesprächData, datum: e.target.value })
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      margin="dense"
                      label="Typ"
                      select
                      fullWidth
                      value={gesprächData.typ || ''}
                      onChange={(e) =>
                        setGesprächData({ ...gesprächData, typ: e.target.value })
                      }
                    >
                      <MenuItem value="Jahresgespräch">Jahresgespräch</MenuItem>
                      <MenuItem value="Feedback">Feedback</MenuItem>
                      <MenuItem value="Sonstiges">Sonstiges</MenuItem>
                    </TextField>
                    <TextField
                      margin="dense"
                      label="Notizen"
                      multiline
                      rows={4}
                      fullWidth
                      value={gesprächData.notizen || ''}
                      onChange={(e) =>
                        setGesprächData({ ...gesprächData, notizen: e.target.value })
                      }
                    />
                    <TextField
                      margin="dense"
                      label="Wiedervorlage am"
                      type="date"
                      fullWidth
                      value={gesprächData.wiedervorlage_am || ''}
                      onChange={(e) =>
                        setGesprächData({ ...gesprächData, wiedervorlage_am: e.target.value })
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseGesprächDialog}>Abbrechen</Button>
                    <Button onClick={handleSaveGespräch} variant="contained">
                      Speichern
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            )}

            {/* Dialog für Bewertungsdetails */}
            <Dialog open={openDialog} onClose={handleCloseDialog}>
              <DialogTitle>Bewertungsdetails</DialogTitle>
              <DialogContent>
                <Typography>Geändert von: {dialogData.bewertet_von || 'Unbekannt'}</Typography>
                <Typography>
                  Geändert am:{' '}
                  {dialogData.bewertet_am
                    ? new Date(dialogData.bewertet_am).toLocaleString()
                    : 'Unbekannt'}
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog}>Schließen</Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Box>
    </Layout>
  );
};

export default Mitarbeiter;
