// components/popup/unternehmen/ArticleDetailsPopup.js

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  IconButton,
} from '@mui/material';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import { Close as CloseIcon } from '@mui/icons-material';
import axiosInstance from '../../../services/axiosInstance';
import dayjs from 'dayjs';
import BigTableCard from '../../card/BigTableCard';

export default function ArticleDetailsPopup({
  open,
  onClose,
  warengruppe,
  jahrMonat,
  displayMode,
}) {
  const [articleData, setArticleData] = useState([]);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (open) {
      fetchTableData();
      fetchChartData();
    }
  }, [open, displayMode]);

  const fetchTableData = async () => {
    try {
      const previousYear =
        (parseInt(jahrMonat.split('.')[0]) - 1).toString() +
        '.' +
        jahrMonat.split('.')[1];

      const fieldName =
        displayMode === 'Verkaufsmenge' ? 'verkaufsmenge' : 'umsatz';
      const fieldNameVJ =
        displayMode === 'Verkaufsmenge' ? 'verkaufsmengeVJ' : 'umsatzVJ';

      const currentYearData = await axiosInstance.get(
        '/scorecard_month_article/ware-artikel-monat/',
        {
          params: {
            warengruppe,
            jahrMonat,
          },
        }
      );

      const previousYearData = await axiosInstance.get(
        '/scorecard_month_article/ware-artikel-monat/',
        {
          params: {
            warengruppe,
            jahrMonat: previousYear,
          },
        }
      );

      const articleMap = {};

      currentYearData.data.results.forEach((item) => {
        const { artikelnummer } = item;
        const value = item[fieldName] || 0;
        const artikel = artikelnummer.artikel;
        if (!articleMap[artikel]) {
          articleMap[artikel] = { value: 0, previousValue: 0 };
        }
        articleMap[artikel].value += value;
      });

      previousYearData.data.results.forEach((item) => {
        const { artikelnummer } = item;
        const previousValue = item[fieldName] || 0;
        const artikel = artikelnummer.artikel;
        if (!articleMap[artikel]) {
          articleMap[artikel] = { value: 0, previousValue: 0 };
        }
        articleMap[artikel].previousValue += previousValue;
      });

      const formattedTableData = Object.entries(articleMap)
        .map(([artikel, data]) => {
          const differenz = data.value - data.previousValue;
          const differenzProzent = data.previousValue
            ? ((differenz / data.previousValue) * 100).toFixed(2) + '%'
            : 'N/A';

          return {
            artikel,
            value: data.value.toLocaleString('de-DE'),
            previousValue: data.previousValue.toLocaleString('de-DE'),
            differenz: differenz.toLocaleString('de-DE'),
            differenzProzent,
          };
        })
        .filter(
          (item) => item.value !== '0' || item.previousValue !== '0'
        );

      setArticleData(formattedTableData);
    } catch (error) {
      console.error('Fehler beim Abrufen der Artikeldaten:', error);
    }
  };

  const fetchChartData = async () => {
    try {
      const last6Months = getLast6Months(jahrMonat);

      const fieldName =
        displayMode === 'Verkaufsmenge' ? 'verkaufsmenge' : 'umsatz';

      const dataPromises = last6Months.map((month) =>
        axiosInstance
          .get('/scorecard_month_article/ware-artikel-monat/', {
            params: {
              warengruppe,
              jahrMonat: month,
            },
          })
          .then((response) => ({ month, data: response.data.results }))
      );

      const results = await Promise.all(dataPromises);

      const articleMap = {};

      results.forEach(({ month, data }) => {
        data.forEach((item) => {
          const { artikelnummer } = item;
          const value = item[fieldName] || 0;
          const artikel = artikelnummer.artikel;

          if (!articleMap[artikel]) {
            articleMap[artikel] = {};
          }

          if (!articleMap[artikel][month]) {
            articleMap[artikel][month] = 0;
          }
          articleMap[artikel][month] += value;
        });
      });

      const topArticles = Object.entries(articleMap)
        .sort(
          ([, a], [, b]) => (b[jahrMonat] || 0) - (a[jahrMonat] || 0)
        )
        .slice(0, 10)
        .map(([artikel]) => artikel);

      const formattedChartData = last6Months.map((month) => {
        const monthData = { name: month };
        topArticles.forEach((artikel) => {
          monthData[artikel] = articleMap[artikel][month] || 0;
        });
        return monthData;
      });

      setChartData(formattedChartData);
    } catch (error) {
      console.error('Fehler beim Abrufen der Verkaufsdaten für das Chart:', error);
    }
  };

  const getLast6Months = (currentMonth) => {
    const months = [];
    let month = dayjs(currentMonth, 'YYYY.MM');
    for (let i = 0; i < 6; i++) {
      months.push(month.format('YYYY.MM'));
      month = month.subtract(1, 'month');
    }
    return months.reverse();
  };

  const columns = [
    { accessorKey: 'artikel', header: 'Artikel' },
    {
      accessorKey: 'value',
      header: displayMode === 'Verkaufsmenge' ? 'Verkaufsmenge' : 'Umsatz',
      align: 'right',
    },
    {
      accessorKey: 'previousValue',
      header: displayMode === 'Verkaufsmenge' ? 'Verkaufsmenge VJ' : 'Umsatz VJ',
      align: 'right',
    },
    { accessorKey: 'differenz', header: 'Differenz', align: 'right' },
    { accessorKey: 'differenzProzent', header: 'Differenz %', align: 'right' },
  ];

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        Artikel Details - {warengruppe}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ width: '100%', height: 300, mb: 3 }}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={chartData}
              margin={{ left: 20, right: 20, top: 5, bottom: 5 }}
            >
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip
                formatter={(val) => (val ? val.toLocaleString('de-DE') : '')}
              />
              {Object.keys(chartData[0] || {})
                .filter((key) => key !== 'name')
                .map((artikel, index) => (
                  <Line
                    key={index}
                    type="monotone"
                    dataKey={artikel}
                    stroke={`hsl(${(index * 60) % 360}, 70%, 50%)`}
                    name={artikel}
                    dot={false}
                  />
                ))}
            </LineChart>
          </ResponsiveContainer>
        </Box>

        <BigTableCard
          title="Artikeldetails"
          data={articleData}
          columns={columns}
          onRowClick={(row) => console.log('Row clicked:', row)}
          muiTableBodyCellProps={{ align: 'right' }}
        />
      </DialogContent>
    </Dialog>
  );
}
