import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Grid, Stack, Paper, Card, CardHeader, Typography, CardContent, Box } from '@mui/material';
import Layout from '../../../components/layout/Scorecards/Unternehmen/UnternehmenMonat';
import DataCard from '../../../components/card/scorecardsmonat/DataCardMonatPage';
import VariableTreeMUI from '../../../components/table/VariableTableTreeMUI'; // Aktualisierter Import
import { FilterContext } from '../../../provider/FilterProvider';
import axiosInstance from '../../../services/axiosInstance';
import dayjs from 'dayjs';
import { calculatePerformanceData } from '../../../utils/calculationPerformance';
import BigChartCard from "../../../components/card/scorecardsmonat/BigChartCardUnternehmen";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import StoreIcon from '@mui/icons-material/Store';
import BigPieChartCardUnternehmen from '../../../components/card/scorecardsmonat/BigPieChartCardUnternehmen';
import {
    tausenderTrennung,
} from '../../../utils/mathStuff';

const Kundenanzahl = () => {
    const { filterState } = useContext(FilterContext);
    const [performanceData, setPerformanceData] = useState({
        umsatz: 0,
        umsatzVJ: 0,
        umsatzAbweichungVJ: 0,
        kundenanzahl: 0,
        kundenanzahlVJ: 0,
        kundenanzahlAbweichungVJ: 0,
        kundenbon: 0,
        kundenbonVJ: 0,
        leistung: 0,
        leistungVJ: 0,
        chartData: [],
        verkaufsgebieteResult: [],
        filialenMitKundenanzahl: 0,
        filialtypenResult: [],
        durchschnittKundenanzahl: 0,
    });
    const [tableData, setTableData] = useState([]);
    const [performanceLast6Months, setPerformanceLast6Months] = useState([]);

    useEffect(() => {
        if (filterState.datum) {
            fetchPerformanceData();
            fetchPerformanceDataLast6Months();
        }
    }, [filterState]);

    const fetchPerformanceData = async () => {
        try {
            // Abrufen der Daten von der API
            const response = await axiosInstance.get('/scorecards_month/performance-monat/', {
                params: {
                    jahrMonat: filterState.datum?.value,
                },
            });

            const allFilialenData = response.data.results;

            // Abrufen der "scoreKundenanzahl"-Daten von der zweiten API
            const scoreResponse = await axiosInstance.get('/scorecards_month/performance-monat/', {
                params: {
                    jahrMonat: filterState.datum?.value,
                },
            });

            const scoreKundenanzahlData = scoreResponse.data.results;

            // Berechnungen durchführen
            const calculatedData = calculatePerformanceData(allFilialenData);

            // Anzahl der Filialen mit Kundenanzahl > 0 berechnen
            const filialenMitKundenanzahl = allFilialenData.filter((filiale) => filiale.kundenanzahl > 0).length;

            // Durchschnittliche Kundenanzahl pro Filiale berechnen
            const durchschnittKundenanzahl = filialenMitKundenanzahl > 0 ? calculatedData.kundenanzahlSumme / filialenMitKundenanzahl : 0;

            // Zusammenfügen der Daten von beiden APIs
            const mergedData = allFilialenData.map((filiale) => {
                const scoreFiliale = scoreKundenanzahlData.find((score) => score.filialnummer === filiale.filialnummer);
                return {
                    ...filiale,
                    scoreKundenanzahl: scoreFiliale ? scoreFiliale.scoreKundenanzahl : 0,
                };
            });

            // Berechnung der filialtypenResult für das PieChart
            const filialtypenResult = allFilialenData.reduce((acc, filiale) => {
                const existing = acc.find(item => item.filialtyp === filiale.filialtyp);
                if (existing) {
                    existing.kundenanzahlSumme += filiale.kundenanzahl || 0;
                    existing.kundenanzahlVJSumme += filiale.kundenanzahlVJ || 0;
                } else {
                    acc.push({
                        filialtyp: filiale.filialtyp,
                        kundenanzahlSumme: filiale.kundenanzahl || 0,
                        kundenanzahlVJSumme: filiale.kundenanzahlVJ || 0,
                    });
                }
                return acc;
            }, []);

            // Berechnung der Kundenanzahlabweichung für jeden Filialtyp
            filialtypenResult.forEach(item => {
                item.kundenanzahlAbweichung = item.kundenanzahlVJSumme > 0
                    ? ((item.kundenanzahlSumme - item.kundenanzahlVJSumme) / item.kundenanzahlVJSumme) * 100
                    : 0;
            });

            // Setzen der Performance-Daten
            setPerformanceData({
                umsatz: calculatedData.umsatzSumme,
                umsatzVJ: calculatedData.umsatzVJSumme,
                umsatzAbweichungVJ: calculatedData.umsatzAbweichungVJ,
                kundenanzahl: calculatedData.kundenanzahlSumme,
                kundenanzahlVJ: calculatedData.kundenanzahlVJSumme,
                kundenanzahlAbweichungVJ: calculatedData.kundenanzahlAbweichungVJ,
                kundenbon: calculatedData.kundenbon,
                kundenbonVJ: calculatedData.kundenbonVJ,
                leistung: calculatedData.leistung,
                leistungVJ: calculatedData.leistungVJ,
                chartData: allFilialenData.map((item) => ({
                    kundenanzahl: item.kundenanzahl || 0,
                })),
                verkaufsgebieteResult: calculatedData.verkaufsgebieteResult,
                filialenMitKundenanzahl,
                durchschnittKundenanzahl,
                filialtypenResult,
            });

            // Tabelle formatieren
            const formattedTableData = mergedData
                .filter(filiale => filiale.kundenanzahl > 0)
                .map((filiale, index) => ({
                    id: `${filiale.verkaufsgebiet}-${filiale.filialnummer}-${index}`,
                    filialnummer: filiale.filialnummer,
                    filiale: filiale.filiale,
                    verkaufsgebiet: filiale.verkaufsgebiet,
                    filialtyp: filiale.filialtyp,
                    kundenanzahl: filiale.kundenanzahl,
                    kundenanzahlVM: filiale.kundenanzahlVM,
                    kundenanzahlVJ: filiale.kundenanzahlVJ,
                    kundenanzahl6M: filiale.kundenanzahl6M,
                    score: filiale.scoreKundenanzahl,
                    kundenanzahlAbweichungVM: filiale.kundenanzahlVM > 0 ? ((filiale.kundenanzahl - filiale.kundenanzahlVM) / filiale.kundenanzahlVM) * 100 : 0,
                    kundenanzahlAbweichung6M: filiale.kundenanzahl6M > 0 ? ((filiale.kundenanzahl - filiale.kundenanzahl6M) / filiale.kundenanzahl6M) * 100 : 0,
                    kundenanzahlAbweichungVJ: filiale.kundenanzahlVJ > 0 ? ((filiale.kundenanzahl - filiale.kundenanzahlVJ) / filiale.kundenanzahlVJ) * 100 : 0,
                }));

            setTableData(formattedTableData);

        } catch (error) {
            console.error('Error fetching performance data:', error);
        }
    };

    const getLast6Months = (currentMonth) => {
        const last6Months = [];
        const formattedMonth = dayjs(currentMonth, 'YYYY.MM');
        for (let i = 0; i < 6; i++) {
            last6Months.push(formattedMonth.subtract(i, 'month').format('YYYY.MM'));
        }
        return last6Months.reverse();
    };

    const fetchPerformanceDataLast6Months = async () => {
        const currentMonth = filterState.datum?.value || dayjs().format('YYYY.MM');
        const last6Months = getLast6Months(currentMonth);

        try {
            const responses = await Promise.all(
                last6Months.map(month => axiosInstance.get('/scorecards_month/performance-monat/', {
                    params: { jahrMonat: month },
                }))
            );

            const last6MonthsData = responses.map((response, index) => {
                const kundenanzahlSumme = response.data.results
                    .reduce((sum, filiale) => sum + (filiale.kundenanzahl || 0), 0);
                const kundenanzahlVJSumme = response.data.results
                    .reduce((sum, filiale) => sum + (filiale.kundenanzahlVJ || 0), 0);

                const umsatzSumme = response.data.results
                    .filter(filiale => filiale.umsatz > 1)
                    .reduce((sum, filiale) => sum + (filiale.umsatz || 0), 0);

                const umsatzVJSumme = response.data.results
                    .filter(filiale => filiale.umsatzVJ > 1)
                    .reduce((sum, filiale) => sum + (filiale.umsatzVJ || 0), 0);

                const produktivstundenSumme = response.data.results
                    .reduce((sum, filiale) => sum + (filiale.produktivstunden || 0), 0);

                const kundenbon = kundenanzahlSumme > 0 ? umsatzSumme / kundenanzahlSumme : 0;
                const leistung = produktivstundenSumme > 0 ? umsatzSumme / produktivstundenSumme : 0;

                return {
                    month: last6Months[index],
                    kundenanzahl: kundenanzahlSumme,
                    kundenanzahlVJ: kundenanzahlVJSumme,
                    umsatz: umsatzSumme,
                    umsatzVJ: umsatzVJSumme,
                    kundenbon: kundenbon,
                    leistung: leistung,
                };
            });

            setPerformanceLast6Months(last6MonthsData);
        } catch (error) {
            console.error('Error fetching data for last 6 months:', error);
        }
    };

    // Daten für das Liniendiagramm vorbereiten
    const chartData = performanceLast6Months.flatMap((monthData) => [
        { x: monthData.month, y: monthData.kundenanzahl, label: 'aktuelles Jahr' },
        { x: monthData.month, y: monthData.kundenanzahlVJ, label: 'Vorjahr' }
    ]);

    // Definition der Spalten für die Tabelle
    const columns = useMemo(
        () => [
            {
                field: 'verkaufsgebiet',
                headerName: 'Verkaufsgebiet',
                align: 'left',
                hide: false,
            },
            {
                field: 'filiale',
                headerName: 'Filiale',
                align: 'left',
                hide: false,
            },
            {
                field: 'score',
                headerName: 'Score',
                type: 'number',
                align: 'right',
                valueFormatter: (params) => tausenderTrennung(params.value, 2),
                hide: false,
            },
            {
                field: 'kundenanzahl',
                headerName: 'Kundenanzahl',
                type: 'number',
                align: 'right',
                valueFormatter: (params) => tausenderTrennung(params.value, 0),
                hide: false,
            },
            {
                field: 'kundenanzahlAbweichungVM',
                headerName: 'Kundenanzahl Abw. VM',
                type: 'number',
                align: 'right',
                valueFormatter: (params) =>
                    params.value !== null
                        ? tausenderTrennung(params.value, 2) + '%'
                        : '0.00%',
                hide: false,
            },
            {
                field: 'kundenanzahlAbweichung6M',
                headerName: 'Kundenanzahl Abw. 6M',
                type: 'number',
                align: 'right',
                valueFormatter: (params) =>
                    params.value !== null
                        ? tausenderTrennung(params.value, 2) + '%'
                        : '0.00%',
                hide: false,
            },
            {
                field: 'kundenanzahlAbweichungVJ',
                headerName: 'Kundenanzahl Abw. VJ',
                type: 'number',
                align: 'right',
                valueFormatter: (params) =>
                    params.value !== null
                        ? tausenderTrennung(params.value, 2) + '%'
                        : '0.00%',
                hide: false,
            },
        ],
        []
    );

    // Definition der Aggregationsfunktionen
    const aggregationFunctions = {
        score: (items) => {
            const totalScore = items.reduce((sum, item) => sum + (item.score || 0), 0);
            const count = items.length;
            return count > 0 ? totalScore / count : 0;
        },
        kundenanzahl: (items) => {
            return items.reduce((sum, item) => sum + (item.kundenanzahl || 0), 0);
        },
        kundenanzahlAbweichungVM: (items) => {
            const aggregatedKundenanzahl = items.reduce((sum, item) => sum + (item.kundenanzahl || 0), 0);
            const aggregatedKundenanzahlVM = items.reduce((sum, item) => sum + (item.kundenanzahlVM || 0), 0);
            const abweichung = aggregatedKundenanzahlVM > 0 ? ((aggregatedKundenanzahl - aggregatedKundenanzahlVM) / aggregatedKundenanzahlVM) * 100 : 0;
            return abweichung;
        },
        kundenanzahlAbweichung6M: (items) => {
            const aggregatedKundenanzahl = items.reduce((sum, item) => sum + (item.kundenanzahl || 0), 0);
            const aggregatedKundenanzahl6M = items.reduce((sum, item) => sum + (item.kundenanzahl6M || 0), 0);
            const abweichung = aggregatedKundenanzahl6M > 0 ? ((aggregatedKundenanzahl - aggregatedKundenanzahl6M) / aggregatedKundenanzahl6M) * 100 : 0;
            return abweichung;
        },
        kundenanzahlAbweichungVJ: (items) => {
            const aggregatedKundenanzahl = items.reduce((sum, item) => sum + (item.kundenanzahl || 0), 0);
            const aggregatedKundenanzahlVJ = items.reduce((sum, item) => sum + (item.kundenanzahlVJ || 0), 0);
            const abweichung = aggregatedKundenanzahlVJ > 0 ? ((aggregatedKundenanzahl - aggregatedKundenanzahlVJ) / aggregatedKundenanzahlVJ) * 100 : 0;
            return abweichung;
        },
    };

    return (
        <Layout>
            <Grid container justifyContent="flex-end" alignItems="stretch">
                <Grid item xs={12}>
                    <Stack direction="row" alignItems="stretch" spacing={2} sx={{ overflowX: 'auto' }}>
                        {/* DataCards */}
                        <Grid item xs={12} md={6} lg={3} className="db_datacard_not_active">
                            <DataCard
                                category="Umsatz"
                                sector="performance"
                                value={performanceData.umsatz ? tausenderTrennung(performanceData.umsatz, 0) : '0'}
                                subHeaders={[
                                    `${tausenderTrennung(performanceData.umsatzAbweichungVJ, 2)}% zum Vorjahr`
                                ]}
                                chartData={performanceLast6Months.map(item => item.umsatz)}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3} className="db_datacard_active">
                            <DataCard
                                category="Kundenanzahl"
                                sector="performance"
                                value={performanceData.kundenanzahl ? tausenderTrennung(performanceData.kundenanzahl, 0) : '0'}
                                subHeaders={[
                                    `${tausenderTrennung(performanceData.kundenanzahlAbweichungVJ, 2)}% zum Vorjahr`
                                ]}
                                chartData={performanceLast6Months.map(item => item.kundenanzahl)}
                            />
                        </Grid>

                        {/* Weitere DataCards */}
                        <Grid item xs={12} md={6} lg={3} className="db_datacard_not_active">
                            <DataCard
                                category="Kundenbon"
                                sector="performance"
                                value={performanceData.kundenbon ? tausenderTrennung(performanceData.kundenbon, 2) : '0.00'}
                                subHeaders={[
                                    `${tausenderTrennung(((performanceData.kundenbon - performanceData.kundenbonVJ) / performanceData.kundenbonVJ * 100), 2)}% zum Vorjahr`
                                ]}
                                chartData={performanceLast6Months.map(item => item.kundenbon)}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3} className="db_datacard_not_active">
                            <DataCard
                                category="Leistung"
                                sector="performance"
                                value={performanceData.leistung ? tausenderTrennung(performanceData.leistung, 2) : '0.00'}
                                subHeaders={[
                                    `${tausenderTrennung(((performanceData.leistung - performanceData.leistungVJ) / performanceData.leistungVJ * 100), 2)}% zum Vorjahr`
                                ]}
                                chartData={performanceLast6Months.map(item => item.leistung)}
                            />
                        </Grid>
                    </Stack>
                </Grid>

                <Grid container spacing={2} mt={3}>
                    {/* Linke Spalte: Informationen und PieChart */}
                    <Grid item xs={12} lg={5} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        {/* Box mit Paper für Filialen und Kundenanzahl */}
                        <Box>
                            <Paper elevation={3} sx={{ padding: 3 }}>
                                <Stack spacing={3.5}>
                                    {/* Anzahl der Filialen mit Kundenanzahl */}
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <StoreIcon fontSize="large" color="primary" />
                                        <Box>
                                            <Typography variant="h6">
                                                Anzahl Filialen mit Kunden im gefilterten Monat
                                            </Typography>
                                            <Typography variant="h4">
                                                {performanceData.filialenMitKundenanzahl ? performanceData.filialenMitKundenanzahl : '0'}
                                            </Typography>
                                        </Box>
                                    </Stack>

                                    {/* Durchschnittliche Kundenanzahl */}
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <MonetizationOnIcon fontSize="large" color="primary" />
                                        <Box>
                                            <Typography variant="h6">Durchschnittliche Kundenanzahl pro Filiale</Typography>
                                            <Typography variant="h4">
                                                {performanceData.durchschnittKundenanzahl ? tausenderTrennung(performanceData.durchschnittKundenanzahl, 0) : '0'}
                                            </Typography>
                                        </Box>
                                    </Stack>
                                </Stack>
                            </Paper>
                        </Box>

                        {/* PieChart für Filialtypen */}
                        <Box sx={{ flexGrow: 1 }}>
                            <BigPieChartCardUnternehmen
                                data={performanceData.filialtypenResult}
                                title="Kundenanzahlanteil nach Filialtyp"
                                metric="kundenanzahl"
                            />
                        </Box>
                    </Grid>

                    {/* Rechte Spalte: BigChartCard */}
                    <Grid item xs={12} lg={7}>
                        <BigChartCard
                            data={chartData}
                            title="Kundenanzahl und Kundenanzahl Vorjahr der letzten 6 Monate"
                        />
                    </Grid>
                </Grid>

                {/* Tabelle */}
                <Grid item xs={12} md={12} mt={3} sx={{ mt: 2 }}>
                <VariableTreeMUI
                                columns={columns}
                                data={tableData}
                                title="Kundenanzahl"
                                initialGrouping={['verkaufsgebiet']}
                                groupingHeaders={{ 'verkaufsgebiet': 'Verkaufsgebiet' }} // Neue Prop zur Anpassung des Gruppierungs-Headers
                                aggregationFunctions={aggregationFunctions}
                                onRowClick={(row) => {
                                    console.log('Zeile geklickt:', row);
                                }}
                            />
                </Grid>
            </Grid>
        </Layout>
    );
};

export default Kundenanzahl;
