import React, { useState, useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  Box,
  Typography,
  Button,
  Menu,
  MenuItem,
  Card,
  CardHeader,
  CardContent,
} from '@mui/material';
import {
  ExpandLess,
  ExpandMore,
  Fullscreen as FullscreenIcon,
  Close as CloseIcon,
  MoreVert as MoreVertIcon,
  ArrowUpward,
  ArrowDownward,
} from '@mui/icons-material';
import Dialog from '@mui/material/Dialog';
import { useTheme } from '@mui/material/styles';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const VariableTreeMUI = ({
  data,
  columns,
  initialGrouping,
  onRowClick,
  aggregationFunctions,
  title, // Neue Prop für die Card-Überschrift
  groupingHeaders = {}, // Neues Prop für Gruppierungs-Headernamen
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [expanded, setExpanded] = useState({});
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [allExpanded, setAllExpanded] = useState(false);
  const theme = useTheme();

  const [groupingEnabled, setGroupingEnabled] = useState(true);

  // Sortierzustand
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  // Exportmenü
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  // Gefilterte Daten basierend auf dem Suchbegriff
  const filteredData = useMemo(() => {
    if (!searchTerm) return data;
    return data.filter((item) =>
      columns.some((column) => {
        const value = item[column.field];
        return (
          value &&
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        );
      })
    );
  }, [data, searchTerm, columns]);

  // Sortierte Daten
  const sortedData = useMemo(() => {
    const sortableItems = [...filteredData];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        // Spezielle Sortierung für die "Filiale"-Spalte
        if (sortConfig.key === 'filiale') {
          const extractNumber = (str) => {
            const numberPart = str.split(' ')[0];
            return parseInt(numberPart, 10);
          };
          aValue = extractNumber(aValue);
          bValue = extractNumber(bValue);
        }

        if (aValue === bValue) return 0;

        if (sortConfig.direction === 'asc') {
          return aValue > bValue ? 1 : -1;
        } else if (sortConfig.direction === 'desc') {
          return aValue < bValue ? 1 : -1;
        }
      });
    }
    return sortableItems;
  }, [filteredData, sortConfig]);

  // Gruppierung der Daten und Berechnung aggregierter Werte
  const groupedData = useMemo(() => {
    if (!groupingEnabled || !initialGrouping || initialGrouping.length === 0) {
      return sortedData;
    }

    const groupField = initialGrouping[0];
    const groups = {};

    sortedData.forEach((item) => {
      const groupKey = item[groupField] || 'Ohne Gruppe';
      if (!groups[groupKey]) {
        groups[groupKey] = {
          items: [],
          aggregates: {},
        };
      }
      groups[groupKey].items.push(item);
    });

    // Berechnung der Aggregationen pro Gruppe
    Object.keys(groups).forEach((groupKey) => {
      const group = groups[groupKey];

      columns.forEach((col) => {
        const field = col.field;
        const aggregationFn = aggregationFunctions?.[field];

        if (aggregationFn) {
          group.aggregates[field] = aggregationFn(group.items);
        } else {
          group.aggregates[field] = null; // Keine Aggregation für dieses Feld
        }
      });
    });

    return groups;
  }, [sortedData, initialGrouping, columns, aggregationFunctions, groupingEnabled]);

  const handleExpandClick = (groupKey) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [groupKey]: !prevExpanded[groupKey],
    }));
  };

  const toggleFullscreen = () => setIsFullscreen((prev) => !prev);

  // Funktion zum Auf- und Zuklappen aller Gruppen
  const toggleExpandAll = () => {
    const newExpandedState = {};
    if (groupedData && typeof groupedData === 'object') {
      Object.keys(groupedData).forEach((groupKey) => {
        newExpandedState[groupKey] = !allExpanded;
      });
    }
    setExpanded(newExpandedState);
    setAllExpanded(!allExpanded);
  };

  // Funktion zur Bestimmung des Stils basierend auf dem Wert
  const getScoreCellStyle = (value) => {
    let color;
    if (value >= 7) {
      color = theme.palette.success.main; // Grün
    } else if (value >= 4) {
      color = theme.palette.warning.main; // Gelb
    } else {
      color = theme.palette.error.main; // Rot
    }
    return { color };
  };

  // Funktion zum Umschalten der Gruppierung
  const toggleGrouping = () => {
    setGroupingEnabled((prev) => !prev);
  };

  // Angepasste Funktion zum Sortieren
  const handleSort = (columnKey) => {
    if (sortConfig.key === columnKey) {
      if (sortConfig.direction === 'asc') {
        // Ändere zu absteigend
        setSortConfig({ key: columnKey, direction: 'desc' });
      } else if (sortConfig.direction === 'desc') {
        // Entferne Sortierung
        setSortConfig({ key: null, direction: 'asc' });
      }
    } else {
      // Neue Spalte, setze auf aufsteigend
      setSortConfig({ key: columnKey, direction: 'asc' });
    }
  };

  // Funktionen für das Exportmenü
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const exportToCSV = () => {
    const exportData = data.map((item) => {
      const exportItem = {};
      columns.forEach((col) => {
        exportItem[col.headerName] = item[col.field];
      });
      return exportItem;
    });

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Daten');

    const excelBuffer = XLSX.write(workbook, {
      bookType: 'csv',
      type: 'array',
    });

    const blob = new Blob([excelBuffer], {
      type: 'text/csv;charset=utf-8;',
    });
    saveAs(blob, 'tabelle.csv');
    handleMenuClose();
  };

  // Angepasste Exportfunktion für Excel mit Formatierung
  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const wsData = [];
    const wsColumns = columns
      .filter((col) => col.field !== initialGrouping[0])
      .map((col) => col.headerName);

    wsData.push(wsColumns);

    // Daten hinzufügen
    data.forEach((item) => {
      const rowData = columns
        .filter((col) => col.field !== initialGrouping[0])
        .map((col) => {
          const value = item[col.field];
          return col.valueFormatter
            ? col.valueFormatter({ value })
            : value;
        });
      wsData.push(rowData);
    });

    const ws = XLSX.utils.aoa_to_sheet(wsData);

    // Formatierung anwenden
    const wscols = wsColumns.map(() => ({
      wch: 15, // Spaltenbreite
    }));
    ws['!cols'] = wscols;

    // Kopfzeile formatieren
    const range = XLSX.utils.decode_range(ws['!ref']);
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const address = XLSX.utils.encode_cell({ r: 0, c: C });
      if (!ws[address]) continue;
      ws[address].s = {
        font: { bold: true },
        alignment: {
          horizontal: C === 0 ? 'left' : 'right',
        },
      };
    }

    // Datenzellen formatieren
    for (let R = 1; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const address = XLSX.utils.encode_cell({ r: R, c: C });
        if (!ws[address]) continue;
        ws[address].s = {
          alignment: {
            horizontal: C === 0 ? 'left' : 'right',
          },
        };
      }
    }

    const wbout = XLSX.write(
      {
        SheetNames: ['Daten'],
        Sheets: { Daten: ws },
      },
      {
        bookType: 'xlsx',
        type: 'array',
        cellStyles: true,
      }
    );

    saveAs(
      new Blob([wbout], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }),
      'tabelle.xlsx'
    );

    handleMenuClose();
  };

  // Angepasste Exportfunktion für PDF mit Formatierung
  const exportToPDF = () => {
    const doc = new jsPDF();

    const tableColumn = columns
      .filter((col) => col.field !== initialGrouping[0])
      .map((col) => ({
        header: col.headerName,
        dataKey: col.field,
      }));

    const tableRows = data.map((item) => {
      const rowData = {};
      columns
        .filter((col) => col.field !== initialGrouping[0])
        .forEach((col) => {
          let value = item[col.field];
          if (col.valueFormatter) {
            value = col.valueFormatter({ value });
          }
          rowData[col.field] = value;
        });
      return rowData;
    });

    doc.autoTable({
      columns: tableColumn,
      body: tableRows,
      styles: {
        halign: 'right', // Standard-Ausrichtung auf rechts
      },
      headStyles: {
        fillColor: [211, 211, 211], // Hellgrau
        textColor: [0, 0, 0],
        halign: 'right',
      },
      columnStyles: {
        0: { halign: 'left' }, // Erste Spalte linksbündig
      },
      didParseCell: function (data) {
        if (data.section === 'body' && data.column.dataKey === 'Score') {
          // Farben basierend auf dem Wert anwenden
          const value = data.cell.raw;
          if (value >= 7) {
            data.cell.styles.textColor = [0, 128, 0]; // Grün
          } else if (value >= 4) {
            data.cell.styles.textColor = [255, 165, 0]; // Orange
          } else {
            data.cell.styles.textColor = [255, 0, 0]; // Rot
          }
        }
      },
    });

    doc.save('tabelle.pdf');
    handleMenuClose();
  };

  return (
    <>
      {/* Vollbild-Dialog */}
      <Dialog
        open={isFullscreen}
        onClose={toggleFullscreen}
        maxWidth={false}
        fullScreen
      >
        <Card
          sx={{
            backgroundColor: 'white',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <CardHeader
            title={
              <Typography variant="h6" component="div">
                {title}
              </Typography>
            }
            action={
              <IconButton
                aria-label="close"
                onClick={toggleFullscreen}
              >
                <CloseIcon />
              </IconButton>
            }
          />
          <CardContent sx={{ flex: 1 }}>
            <Box display="flex" justifyContent="space-between" mb={2}>
              <TextField
                label="Suchen"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                size="small"
              />
              <Box>
                <Button
                  onClick={toggleGrouping}
                  variant="outlined"
                  size="small"
                  sx={{
                    marginRight: 1,
                    color: 'grey.700',
                    borderColor: 'grey.700',
                  }}
                >
                  {groupingEnabled ? 'Gruppierung auflösen' : 'Gruppieren'}
                </Button>
                {groupingEnabled && (
                  <Button
                    onClick={toggleExpandAll}
                    variant="outlined"
                    size="small"
                    sx={{
                      marginRight: 1,
                      color: 'grey.700',
                      borderColor: 'grey.700',
                    }}
                  >
                    {allExpanded ? 'Alle zuklappen' : 'Alle aufklappen'}
                  </Button>
                )}
              </Box>
            </Box>
            {renderTable()}
          </CardContent>
        </Card>
      </Dialog>

      {/* Hauptinhalt */}
      <Card sx={{ backgroundColor: 'white' }}>
        <CardHeader
          title={
            <Typography variant="h6" component="div">
              {title}
            </Typography>
          }
        />
        <CardContent>
          <Box display="flex" justifyContent="space-between" mb={2}>
            <TextField
              label="Suchen"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              size="small"
            />
            <Box>
              <Button
                onClick={toggleGrouping}
                variant="outlined"
                size="small"
                sx={{
                  marginRight: 1,
                  color: 'grey.700',
                  borderColor: 'grey.700',
                }}
              >
                {groupingEnabled ? 'Gruppierung auflösen' : 'Gruppieren'}
              </Button>
              {groupingEnabled && (
                <Button
                  onClick={toggleExpandAll}
                  variant="outlined"
                  size="small"
                  sx={{
                    marginRight: 1,
                    color: 'grey.700',
                    borderColor: 'grey.700',
                  }}
                >
                  {allExpanded ? 'Alle zuklappen' : 'Alle aufklappen'}
                </Button>
              )}
              <IconButton onClick={handleMenuClick}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={exportToPDF}>Export als PDF</MenuItem>
                <MenuItem onClick={exportToExcel}>Export als Excel</MenuItem>
                <MenuItem onClick={exportToCSV}>Export als CSV</MenuItem>
              </Menu>
              <IconButton onClick={toggleFullscreen}>
                <FullscreenIcon />
              </IconButton>
            </Box>
          </Box>
          {renderTable()}
        </CardContent>
      </Card>
    </>
  );

  function renderTable() {
    // Berechne die Spalten nach dem Filtern des Gruppierungsfeldes
    const displayedColumns = columns.filter(
      (col) => col.field !== initialGrouping[0]
    );

    return (
      <div className="db_styled_table">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {groupingEnabled &&
                  initialGrouping &&
                  initialGrouping.length > 0 && (
                    <TableCell align="left">
                      {groupingHeaders[initialGrouping[0]] || initialGrouping[0]}
                    </TableCell>
                  )}
                {displayedColumns.map((col, index) => (
                  <TableCell
                    key={col.field}
                    align={index === 0 ? 'left' : 'right'} // Erste Spalte linksbündig, Rest rechtsbündig
                    onClick={() => handleSort(col.field)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent={index === 0 ? 'flex-start' : 'flex-end'}
                    >
                      {sortConfig.key === col.field &&
                      sortConfig.direction !== null ? (
                        sortConfig.direction === 'asc' ? (
                          <ArrowUpward fontSize="small" />
                        ) : (
                          <ArrowDownward fontSize="small" />
                        )
                      ) : null}
                      {col.headerName}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {groupingEnabled &&
              initialGrouping &&
              initialGrouping.length > 0 ? (
                Object.entries(groupedData).map(([groupKey, groupData]) => (
                  <React.Fragment key={groupKey}>
                    {/* Gruppenzeile mit aggregierten Werten */}
                    <TableRow>
                      {/* Gruppenüberschrift */}
                      <TableCell align="left">
                        <Box display="flex" alignItems="center">
                          <IconButton
                            onClick={() => handleExpandClick(groupKey)}
                            size="small"
                          >
                            {expanded[groupKey] ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </IconButton>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: expanded[groupKey]
                                ? 'bold'
                                : 'normal',
                            }}
                          >
                            {groupKey}
                          </Typography>
                        </Box>
                      </TableCell>
                      {/* Aggregierte Werte */}
                      {displayedColumns.map((col, index) => (
                        <TableCell
                          key={col.field}
                          align={index === 0 ? 'left' : 'right'} // Erste Spalte linksbündig, Rest rechtsbündig
                          sx={{ fontWeight: 'bold' }}
                        >
                          {groupData.aggregates[col.field] !== null
                            ? col.valueFormatter
                              ? renderCellContent(
                                  col,
                                  groupData.aggregates[col.field]
                                )
                              : groupData.aggregates[col.field]
                            : ''}
                        </TableCell>
                      ))}
                    </TableRow>
                    {/* Zeilen der Unterelemente */}
                    {expanded[groupKey] &&
                      groupData.items.map((item, index) => (
                        <TableRow
                          key={index}
                          onClick={() => onRowClick && onRowClick(item)}
                          sx={{ cursor: 'pointer' }}
                        >
                          {/* Leere Zelle für Ausrichtung */}
                          <TableCell align="left" />
                          {displayedColumns.map((col, index) => (
                            <TableCell
                              key={col.field}
                              align={index === 0 ? 'left' : 'right'} // Erste Spalte linksbündig, Rest rechtsbündig
                            >
                              {renderCellContent(col, item[col.field])}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                  </React.Fragment>
                ))
              ) : (
                sortedData.map((item, index) => (
                  <TableRow
                    key={index}
                    onClick={() => onRowClick && onRowClick(item)}
                    sx={{ cursor: 'pointer' }}
                  >
                    {displayedColumns.map((col, index) => (
                      <TableCell
                        key={col.field}
                        align={index === 0 ? 'left' : 'right'} // Erste Spalte linksbündig, Rest rechtsbündig
                      >
                        {renderCellContent(col, item[col.field])}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }

  // Funktion zum Rendern des Zellinhalts mit Farbgebung
  function renderCellContent(col, value) {
    if (col.headerName === 'Score') {
      const style = getScoreCellStyle(value);
      const formattedValue = col.valueFormatter
        ? col.valueFormatter({ value })
        : value;
      return <span style={style}>{formattedValue}</span>;
    } else {
      return col.valueFormatter
        ? col.valueFormatter({ value })
        : value;
    }
  }
};

export default VariableTreeMUI;
