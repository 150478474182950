// Overview.jsx

import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  IconButton,
  CardHeader,
  Typography,
  Button
} from '@mui/material';
import axiosInstance from '../../../services/axiosInstance';
import Layout from '../../../components/layout/Onboarding/Onboarding';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import HailOutlinedIcon from '@mui/icons-material/HailOutlined';
import { useNavigate } from 'react-router-dom';
import { FilterContext } from '../../../provider/FilterProvider'; 
import EditIcon from '@mui/icons-material/Edit';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';

const Overview = () => {
  const [employees, setEmployees] = useState([]);
  const [onboardingPlans, setOnboardingPlans] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [customGroups, setCustomGroups] = useState([]);

  const [filterState, setFilterState] = useState({ filiale: null });
  const [openAssignDialog, setOpenAssignDialog] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedCustomGroup, setSelectedCustomGroup] = useState('');
  const [editingAssignment, setEditingAssignment] = useState(null);

  const navigate = useNavigate();
  const { handleEmployeeChange } = useContext(FilterContext);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axiosInstance.get('personaldaten/stammdaten-mitarbeiter/', {
          params: {
            filiale: filterState.filiale?.label,
          },
        });

        const fourMonthsAgo = new Date();
        fourMonthsAgo.setMonth(fourMonthsAgo.getMonth() - 4);

        const filteredEmployees = response.data.results.filter((employee) => {
          const eintrittDate = new Date(employee.eintritt);
          return eintrittDate >= fourMonthsAgo;
        });

        setEmployees(filteredEmployees);
      } catch (error) {
        console.error('Fehler beim Laden der Mitarbeiterdaten:', error);
      }
    };

    const fetchOnboardingPlans = async () => {
      try {
        const response = await axiosInstance.get('/onboarding/onboarding-plans/');
        setOnboardingPlans(response.data.results);
      } catch (error) {
        console.error('Fehler beim Laden der Onboarding-Pläne:', error);
      }
    };

    const fetchAssignments = async () => {
      try {
        const response = await axiosInstance.get('/onboarding/employee-assignments/');
        setAssignments(response.data.results);
      } catch (error) {
        console.error('Fehler beim Laden der Zuweisungen:', error);
      }
    };

    const fetchCustomGroups = async () => {
      try {
        const response = await axiosInstance.get('/auth/custom-groups/');
        setCustomGroups(response.data);
      } catch (error) {
        console.error('Fehler beim Laden der CustomGroups:', error);
      }
    };

    fetchEmployees();
    fetchOnboardingPlans();
    fetchAssignments();
    fetchCustomGroups();
  }, [filterState]);

  const handleAssignPlan = (employee) => {
    const assignment = assignments.find((a) => a.employee === employee.mitarbeiterId);
    setSelectedEmployee(employee);
    setSelectedPlan(assignment ? assignment.onboarding_plan : '');
    setSelectedStatus(assignment ? assignment.status : 'in_progress');
    setSelectedCustomGroup(assignment ? assignment.custom_group_id || '' : '');
    setEditingAssignment(assignment || null);
    setOpenAssignDialog(true);
  };

  const handleSaveAssignment = async () => {
    try {
      const data = {
        employee: selectedEmployee.mitarbeiterId,
        onboarding_plan: selectedPlan,
        status: selectedStatus,
      };

      if (selectedCustomGroup) {
        data.custom_group_id = selectedCustomGroup;
      } else {
        data.custom_group_id = null;
      }

      if (editingAssignment) {
        await axiosInstance.patch(`/onboarding/employee-assignments/${editingAssignment.id}/`, data);
      } else {
        await axiosInstance.post('/onboarding/employee-assignments/', data);
      }

      const response = await axiosInstance.get('/onboarding/employee-assignments/');
      setAssignments(response.data.results);

      setOpenAssignDialog(false);
      setSelectedEmployee(null);
      setSelectedPlan('');
      setSelectedStatus('');
      setSelectedCustomGroup('');
      setEditingAssignment(null);
    } catch (error) {
      if (error.response) {
        console.error('Fehler beim Zuweisen des Onboarding-Plans:', error.response.data);
      } else {
        console.error('Fehler beim Zuweisen des Onboarding-Plans:', error);
      }
    }
  };

  const handleViewOnboarding = (employeeId) => {
    handleEmployeeChange(employeeId);
    navigate('/onboarding/overview/mitarbeiter');
  };

  return (
    <Layout>
      <Box sx={{ flexGrow: 1, p: 3, maxWidth: 1200, margin: '0 auto', bgcolor: 'transparent' }}>
        <PageHeader
          title="Onboarding"
          subtitle="Verwalte deine Onboarding-Pläne und überprüfe den Stand der aktuellen Einarbeitung."
          Icon={HailOutlinedIcon}
        />

        <Paper sx={{ mb: 4 }}>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              backdropFilter: 'blur(10px)',
            }}
          >
                  <CardHeader
        title={
          <Typography variant="h6" component="div">
Neue Kollegen          </Typography>
        }
      />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Mitarbeiternr.</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Filiale/Abteilung</TableCell>
                    <TableCell>Eintritt</TableCell>
                    <TableCell>Onboarding-Plan</TableCell>
                    <TableCell>Vorgesetzt</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Aktionen</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {employees.map((employee) => {
                    const assignment = assignments.find((a) => a.employee === employee.mitarbeiterId);

                    return (
                      <TableRow key={employee.mitarbeiterId}>
                        <TableCell>{employee.mitarbeiterId}</TableCell>
                        <TableCell>{employee.name}</TableCell>
                        <TableCell>{employee.stammkostenstelle}</TableCell>
                        <TableCell>
                          {employee.eintritt
                            ? new Date(employee.eintritt).toLocaleDateString('de-DE')
                            : 'N/A'}
                        </TableCell>
                        <TableCell>
                          {assignment ? assignment.onboarding_plan_name : 'Kein Plan zugewiesen'}
                        </TableCell>
                        <TableCell>
                          {assignment && assignment.custom_group_name
                            ? assignment.custom_group_name
                            : 'Keine Gruppe'}
                        </TableCell>
                        <TableCell>
                          {assignment
                            ? assignment.status === 'in_progress'
                              ? 'begonnen'
                              : 'Abgeschlossen'
                            : 'N/A'}
                        </TableCell>
                        <TableCell>
                          <Box sx={{ display: 'flex', gap: 1 }}>
                            <IconButton
                              color="action"
                              onClick={() => handleAssignPlan(employee)}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              color="action"
                              onClick={() => handleViewOnboarding(employee.mitarbeiterId)}
                            >
                              <ArrowCircleRightOutlinedIcon />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Paper>
      </Box>

      <Dialog open={openAssignDialog} onClose={() => setOpenAssignDialog(false)}>
        <DialogTitle>
          {editingAssignment ? 'Zuweisung bearbeiten' : 'Onboarding-Plan zuweisen'}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="plan-label">Onboarding-Plan</InputLabel>
            <Select
              labelId="plan-label"
              value={selectedPlan}
              onChange={(e) => setSelectedPlan(e.target.value)}
              label="Onboarding-Plan"
            >
              {onboardingPlans.map((plan) => (
                <MenuItem key={plan.id} value={plan.id}>
                  {plan.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="status-label">Status</InputLabel>
            <Select
              labelId="status-label"
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
              label="Status"
            >
              <MenuItem value="in_progress">In Bearbeitung</MenuItem>
              <MenuItem value="completed">Abgeschlossen</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="custom-group-label">Benutzergruppe</InputLabel>
            <Select
              labelId="custom-group-label"
              value={selectedCustomGroup}
              onChange={(e) => setSelectedCustomGroup(e.target.value)}
              label="Benutzergruppe"
            >
              <MenuItem value="">Keine Gruppe</MenuItem>
              {customGroups.map((group) => (
                <MenuItem key={group.id} value={group.id}>
                  {group.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAssignDialog(false)}>Abbrechen</Button>
          <Button onClick={handleSaveAssignment} variant="contained" disabled={!selectedPlan}>
            {editingAssignment ? 'Speichern' : 'Zuweisen'}
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};

export default Overview;
