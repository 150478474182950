import {Box, Typography, Paper, Button} from "@mui/material";

const ThekeOverview = ({ warengruppen = [], selectedArtikel = [], setFilter=null, setShowOverview=null }) => {
    console.log("ThekeOverview - warengruppen:", warengruppen);
    console.log("ThekeOverview - selectedArtikel:", selectedArtikel);

    if (!warengruppen.length) {
        return <Box>Keine Artikel verfügbar.</Box>;
    }

    const handleCategoryClick = (category) => {
        if (setFilter) {
            setFilter(category);
        }
        if (setShowOverview) {
            setShowOverview(false);
        }
    };

    const processText = (text) => {
        // Remove leading numbers and whitespace
        let processed = text.replace(/^\d{4}\s+/, '');
        
        // Check if the text is all uppercase
        const isAllCaps = processed === processed.toUpperCase();
        
        // Convert to title case, or lowercase with first letter uppercase if it was all caps
        // Adjust regex to avoid capitalizing letters after umlauts and "ß"
        processed = processed.toLowerCase().replace(/(^|\s|[^a-zäöüß])\w/g, (match) => match.toUpperCase());

        // If it was all caps, ensure the first letter is uppercase (in case the word is shorter than 2 letters)
        if (isAllCaps && processed.length > 0) {
            processed = processed.charAt(0).toUpperCase() + processed.slice(1);
        }

        return processed;
    };

    return (
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'row',
            gap: 2, 
            width: '100%',
            minHeight: '400px'
        }}>
            {warengruppen.map((warengruppe) => {
                const artikelForWarengruppe = selectedArtikel.find(
                    wg => wg.id === warengruppe.warengruppennummer
                )?.artikel || [];

                // console.log(`Warengruppe ${warengruppe.warengruppennummer} - artikelForWarengruppe:`, artikelForWarengruppe);

                return (
                    <Button
                        key={warengruppe.warengruppennummer}
                        onClick={() => handleCategoryClick(warengruppe.warengruppennummer)}
                        sx={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'stretch',
                            padding: 0,
                            textAlign: 'left',
                            height: 'auto',
                            minHeight: '400px',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                            },
                            textTransform: 'none', // Add this line to prevent automatic uppercase
                        }}
                    >
                        <Paper 
                            elevation={2} 
                            sx={{ 
                                p: 1, 
                                flexGrow: 1, 
                                display: 'flex', 
                                flexDirection: 'column',
                                // backgroundColor: 'inherit',
                                minHeight: '400px'
                            }}
                        >
                            <Typography sx={{mb: 2}}>
                                {warengruppe.warengruppe}
                            </Typography>
                            {artikelForWarengruppe.map((artikel, index) => (
                                <Typography 
                                    key={index} 
                                    variant="body2" 
                                    sx={{ 
                                        display: 'block', 
                                        fontSize: '0.9rem',
                                        lineHeight: 1.6,
                                        overflow: 'hidden',
                                        wordWrap: 'break-word',
                                        overflowWrap: 'break-word',
                                    }}
                                >
                                    {processText(artikel.artikelData?.artikelbezeichnung || 'Unbekannter Artikel')}
                                </Typography>
                            ))}
                            {artikelForWarengruppe.length === 0 && (
                                <Typography variant="body2" sx={{ fontStyle: '' }}>
                                    Keine Artikel
                                </Typography>
                            )}
                        </Paper>
                    </Button>
                );
            })}
        </Box>
    );
};

export default ThekeOverview;
