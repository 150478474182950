import axiosInstance from "./axiosInstance";

export const fetchTeigGeschmackOptions = async () => {
  try {
    const [geschmackResponse, teigResponse] = await Promise.all([
      axiosInstance.get('/theke/geschmack/'),
      axiosInstance.get('/theke/teig/')
    ]);

    const geschmackResult = geschmackResponse.data.results || [];
    const teigResult = teigResponse.data.results || [];

    const geschmackOptions = geschmackResult.map(option => option.geschmack);
    const teigOptions = teigResult.map(option => option.teig);

    console.log('Geschmack options:', geschmackOptions);
    console.log('Teig options:', teigOptions);
    return [geschmackOptions, teigOptions];
  } catch (error) {
    console.error('Error fetching options:', error);
    return [[], []]; // Return empty arrays on error
  }
};

export const handleChangeTeig = async (
    artikelnummer, 
    setArtikel, 
    teig = null,
    { showError, showSuccess }
) => {
    try {
        setArtikel(item => item.map((item) => {
            if (item.artikelnummer === artikelnummer) {
                return {...item, teig: teig};
            }
            return item;
        }))
        await axiosInstance.patch(`/theke/artikel-properties/${artikelnummer}/`, {
            teig: teig
        });
        showSuccess("Teig wurde erfolgreich aktualisiert");
    } catch (error) {
        console.error('Error updating teig status:', error);
        showError('Fehler beim Aktualisieren des Teigs');
    }
};

export const handleChangeGeschmack = async (
    artikelnummer, 
    setArtikel, 
    geschmack = null,
    { showError, showSuccess }
) => {
    try {
        const updatedArtikel = item => item.map((item) => {
            if (item.artikelnummer === artikelnummer) {
                return {...item, geschmack: geschmack};
            }
            return item;
        });
        setArtikel(updatedArtikel);
        await axiosInstance.patch(`/theke/artikel-properties/${artikelnummer}/`, {
            geschmack: geschmack
        });
        showSuccess('Geschmack wurde erfolgreich aktualisiert');
    } catch (error) {
        console.error('Error updating geschmack status:', error);
        showError('Fehler beim Aktualisieren des Geschmacks');
    }
};
