export const calculatePerformanceData = (allFilialenData) => {
    // Initialisierung der Summenvariablen
    let umsatzSumme = 0;
    let umsatzVJSumme = 0;
    let umsatzVMSumme = 0;
    let umsatz6MSumme = 0;
    let umsatzVWSumme = 0;  // Neu
    let umsatz6WSumme = 0;  // Neu

    let kundenanzahlSumme = 0;
    let kundenanzahlVJSumme = 0;
    let kundenanzahlVMSumme = 0;
    let kundenanzahl6MSumme = 0;
    let kundenanzahlVWSumme = 0; // Neu
    let kundenanzahl6WSumme = 0; // Neu

    let produktivstundenSumme = 0;
    let produktivstundenVJSumme = 0;

    // Hilfsobjekte zur Gruppierung der Daten nach Verkaufsgebiet und Filialtyp
    const verkaufsgebiete = {};
    const filialtypen = {};

    // Kumulierung der Daten über alle Filialen hinweg
    allFilialenData.forEach((filiale) => {
        const {
            verkaufsgebiet,
            umsatz,
            umsatzVJ,
            umsatzVM,
            umsatz6M,
            umsatzVW,    // Neu
            umsatz6W,    // Neu
            kundenanzahl,
            kundenanzahlVJ,
            kundenanzahlVM,
            kundenanzahl6M,
            kundenanzahlVW, // Neu
            kundenanzahl6W, // Neu
            produktivstunden,
            produktivstundenVJ,
        } = filiale;
        const filialtyp = filiale.filialtyp ? filiale.filialtyp : 'Keine Zuordnung'; // Setze 'Keine Zuordnung' wenn leer

        // Gesamtsummen für alle Filialen kumulieren
        umsatzSumme += umsatz || 0;
        umsatzVJSumme += umsatzVJ || 0;
        umsatzVMSumme += umsatzVM || 0;
        umsatz6MSumme += umsatz6M || 0;
        umsatzVWSumme += umsatzVW || 0;    // Neu
        umsatz6WSumme += umsatz6W || 0;    // Neu

        kundenanzahlSumme += kundenanzahl || 0;
        kundenanzahlVJSumme += kundenanzahlVJ || 0;
        kundenanzahlVMSumme += kundenanzahlVM || 0;
        kundenanzahl6MSumme += kundenanzahl6M || 0;
        kundenanzahlVWSumme += kundenanzahlVW || 0; // Neu
        kundenanzahl6WSumme += kundenanzahl6W || 0; // Neu

        produktivstundenSumme += produktivstunden || 0;
        produktivstundenVJSumme += produktivstundenVJ || 0;

        // Verkaufsgebiet-Summen kumulieren
        if (!verkaufsgebiete[verkaufsgebiet]) {
            verkaufsgebiete[verkaufsgebiet] = {
                umsatzSumme: 0,
                umsatzVJSumme: 0,
                umsatzVMSumme: 0,
                umsatz6MSumme: 0,
                umsatzVWSumme: 0,  // Neu
                umsatz6WSumme: 0,  // Neu
                kundenanzahlSumme: 0,
                kundenanzahlVJSumme: 0,
                kundenanzahlVMSumme: 0,
                kundenanzahl6MSumme: 0,
                kundenanzahlVWSumme: 0, // Neu
                kundenanzahl6WSumme: 0, // Neu
            };
        }
        verkaufsgebiete[verkaufsgebiet].umsatzSumme += umsatz || 0;
        verkaufsgebiete[verkaufsgebiet].umsatzVJSumme += umsatzVJ || 0;
        verkaufsgebiete[verkaufsgebiet].umsatzVMSumme += umsatzVM || 0;
        verkaufsgebiete[verkaufsgebiet].umsatz6MSumme += umsatz6M || 0;
        verkaufsgebiete[verkaufsgebiet].umsatzVWSumme += umsatzVW || 0;    // Neu
        verkaufsgebiete[verkaufsgebiet].umsatz6WSumme += umsatz6W || 0;    // Neu

        verkaufsgebiete[verkaufsgebiet].kundenanzahlSumme += kundenanzahl || 0;
        verkaufsgebiete[verkaufsgebiet].kundenanzahlVJSumme += kundenanzahlVJ || 0;
        verkaufsgebiete[verkaufsgebiet].kundenanzahlVMSumme += kundenanzahlVM || 0;
        verkaufsgebiete[verkaufsgebiet].kundenanzahl6MSumme += kundenanzahl6M || 0;
        verkaufsgebiete[verkaufsgebiet].kundenanzahlVWSumme += kundenanzahlVW || 0; // Neu
        verkaufsgebiete[verkaufsgebiet].kundenanzahl6WSumme += kundenanzahl6W || 0; // Neu

        // Filialtyp-Summen kumulieren
        if (!filialtypen[filialtyp]) {
            filialtypen[filialtyp] = {
                umsatzSumme: 0,
                umsatzVJSumme: 0,
                umsatzVMSumme: 0,
                umsatz6MSumme: 0,
                umsatzVWSumme: 0,  // Neu
                umsatz6WSumme: 0,  // Neu
                kundenanzahlSumme: 0,
                kundenanzahlVJSumme: 0,
                kundenanzahlVMSumme: 0,
                kundenanzahl6MSumme: 0,
                kundenanzahlVWSumme: 0, // Neu
                kundenanzahl6WSumme: 0, // Neu
            };
        }
        filialtypen[filialtyp].umsatzSumme += umsatz || 0;
        filialtypen[filialtyp].umsatzVJSumme += umsatzVJ || 0;
        filialtypen[filialtyp].umsatzVMSumme += umsatzVM || 0;
        filialtypen[filialtyp].umsatz6MSumme += umsatz6M || 0;
        filialtypen[filialtyp].umsatzVWSumme += umsatzVW || 0;    // Neu
        filialtypen[filialtyp].umsatz6WSumme += umsatz6W || 0;    // Neu

        filialtypen[filialtyp].kundenanzahlSumme += kundenanzahl || 0;
        filialtypen[filialtyp].kundenanzahlVJSumme += kundenanzahlVJ || 0;
        filialtypen[filialtyp].kundenanzahlVMSumme += kundenanzahlVM || 0;
        filialtypen[filialtyp].kundenanzahl6MSumme += kundenanzahl6M || 0;
        filialtypen[filialtyp].kundenanzahlVWSumme += kundenanzahlVW || 0; // Neu
        filialtypen[filialtyp].kundenanzahl6WSumme += kundenanzahl6W || 0; // Neu
    });

    // Berechne den Kundenbon: Umsatz / Kundenanzahl
    const kundenbon = kundenanzahlSumme > 0 ? umsatzSumme / kundenanzahlSumme : 0;
    const kundenbonVJ = kundenanzahlVJSumme > 0 ? umsatzVJSumme / kundenanzahlVJSumme : 0;
    const kundenbonVM = kundenanzahlVMSumme > 0 ? umsatzVMSumme / kundenanzahlVMSumme : 0;
    const kundenbon6M = kundenanzahl6MSumme > 0 ? umsatz6MSumme / kundenanzahl6MSumme : 0;
    const kundenbonVW = kundenanzahlVWSumme > 0 ? umsatzVWSumme / kundenanzahlVWSumme : 0; // Neu
    const kundenbon6W = kundenanzahl6WSumme > 0 ? umsatz6WSumme / kundenanzahl6WSumme : 0; // Neu

    // Berechne die Leistung: Umsatz / Produktivstunden
    const leistung = produktivstundenSumme > 0 ? umsatzSumme / produktivstundenSumme : 0;
    const leistungVJ = produktivstundenVJSumme > 0 ? umsatzVJSumme / produktivstundenVJSumme : 0;

    // Berechne prozentuale Abweichungen für Umsatz
    const umsatzAbweichungVM = umsatzVMSumme > 0 ? ((umsatzSumme - umsatzVMSumme) / umsatzVMSumme) * 100 : 0;
    const umsatzAbweichung6M = umsatz6MSumme > 0 ? ((umsatzSumme - umsatz6MSumme) / umsatz6MSumme) * 100 : 0;
    const umsatzAbweichungVJ = umsatzVJSumme > 0 ? ((umsatzSumme - umsatzVJSumme) / umsatzVJSumme) * 100 : 0;
    const umsatzAbweichungVW = umsatzVWSumme > 0 ? ((umsatzSumme - umsatzVWSumme) / umsatzVWSumme) * 100 : 0; // Neu
    const umsatzAbweichung6W = umsatz6WSumme > 0 ? ((umsatzSumme - umsatz6WSumme) / umsatz6WSumme) * 100 : 0; // Neu

    // Berechne prozentuale Abweichungen für Kundenanzahl
    const kundenanzahlAbweichungVM =
        kundenanzahlVMSumme > 0 ? ((kundenanzahlSumme - kundenanzahlVMSumme) / kundenanzahlVMSumme) * 100 : 0;
    const kundenanzahlAbweichung6M =
        kundenanzahl6MSumme > 0 ? ((kundenanzahlSumme - kundenanzahl6MSumme) / kundenanzahl6MSumme) * 100 : 0;
    const kundenanzahlAbweichungVJ =
        kundenanzahlVJSumme > 0 ? ((kundenanzahlSumme - kundenanzahlVJSumme) / kundenanzahlVJSumme) * 100 : 0;
    const kundenanzahlAbweichungVW =
        kundenanzahlVWSumme > 0 ? ((kundenanzahlSumme - kundenanzahlVWSumme) / kundenanzahlVWSumme) * 100 : 0; // Neu
    const kundenanzahlAbweichung6W =
        kundenanzahl6WSumme > 0 ? ((kundenanzahlSumme - kundenanzahl6WSumme) / kundenanzahl6WSumme) * 100 : 0; // Neu

    // Berechne prozentuale Abweichungen für Kundenbon
    const kundenbonAbweichungVM = kundenbonVM > 0 ? ((kundenbon - kundenbonVM) / kundenbonVM) * 100 : 0;
    const kundenbonAbweichung6M = kundenbon6M > 0 ? ((kundenbon - kundenbon6M) / kundenbon6M) * 100 : 0;
    const kundenbonAbweichungVJ = kundenbonVJ > 0 ? ((kundenbon - kundenbonVJ) / kundenbonVJ) * 100 : 0;
    const kundenbonAbweichungVW = kundenbonVW > 0 ? ((kundenbon - kundenbonVW) / kundenbonVW) * 100 : 0; // Neu
    const kundenbonAbweichung6W = kundenbon6W > 0 ? ((kundenbon - kundenbon6W) / kundenbon6W) * 100 : 0; // Neu

    // Berechnung des Umsatzwachstums und Kundenbons für jedes Verkaufsgebiet
    const verkaufsgebieteResult = Object.keys(verkaufsgebiete).map((gebiet) => {
        const data = verkaufsgebiete[gebiet];
        const {
            umsatzSumme,
            umsatzVJSumme,
            umsatzVMSumme,
            umsatz6MSumme,
            umsatzVWSumme,  // Neu
            umsatz6WSumme,  // Neu
            kundenanzahlSumme,
            kundenanzahlVJSumme,
            kundenanzahlVMSumme,
            kundenanzahl6MSumme,
            kundenanzahlVWSumme, // Neu
            kundenanzahl6WSumme, // Neu
        } = data;

        const umsatzWachstum = umsatzVJSumme > 0 ? ((umsatzSumme - umsatzVJSumme) / umsatzVJSumme) * 100 : 0;
        const umsatzWachstumVM = umsatzVMSumme > 0 ? ((umsatzSumme - umsatzVMSumme) / umsatzVMSumme) * 100 : 0;
        const umsatzWachstum6M = umsatz6MSumme > 0 ? ((umsatzSumme - umsatz6MSumme) / umsatz6MSumme) * 100 : 0;
        const umsatzWachstumVW = umsatzVWSumme > 0 ? ((umsatzSumme - umsatzVWSumme) / umsatzVWSumme) * 100 : 0; // Neu
        const umsatzWachstum6W = umsatz6WSumme > 0 ? ((umsatzSumme - umsatz6WSumme) / umsatz6WSumme) * 100 : 0; // Neu

        // Kundenbon berechnen
        const kundenbon = kundenanzahlSumme > 0 ? umsatzSumme / kundenanzahlSumme : 0;
        const kundenbonVJ = kundenanzahlVJSumme > 0 ? umsatzVJSumme / kundenanzahlVJSumme : 0;
        const kundenbonVM = kundenanzahlVMSumme > 0 ? umsatzVMSumme / kundenanzahlVMSumme : 0;
        const kundenbon6M = kundenanzahl6MSumme > 0 ? umsatz6MSumme / kundenanzahl6MSumme : 0;
        const kundenbonVW = kundenanzahlVWSumme > 0 ? umsatzVWSumme / kundenanzahlVWSumme : 0; // Neu
        const kundenbon6W = kundenanzahl6WSumme > 0 ? umsatz6WSumme / kundenanzahl6WSumme : 0; // Neu

        // Kundenbon Abweichungen
        const kundenbonAbweichungVJ = kundenbonVJ > 0 ? ((kundenbon - kundenbonVJ) / kundenbonVJ) * 100 : 0;
        const kundenbonAbweichungVM = kundenbonVM > 0 ? ((kundenbon - kundenbonVM) / kundenbonVM) * 100 : 0;
        const kundenbonAbweichung6M = kundenbon6M > 0 ? ((kundenbon - kundenbon6M) / kundenbon6M) * 100 : 0;
        const kundenbonAbweichungVW = kundenbonVW > 0 ? ((kundenbon - kundenbonVW) / kundenbonVW) * 100 : 0; // Neu
        const kundenbonAbweichung6W = kundenbon6W > 0 ? ((kundenbon - kundenbon6W) / kundenbon6W) * 100 : 0; // Neu

        return {
            verkaufsgebiet: gebiet,
            umsatzSumme,
            umsatzVJSumme,
            umsatzWachstum: umsatzWachstum.toFixed(2),
            umsatzWachstumVM: umsatzWachstumVM.toFixed(2),
            umsatzWachstum6M: umsatzWachstum6M.toFixed(2),
            umsatzWachstumVW: umsatzWachstumVW.toFixed(2), // Neu
            umsatzWachstum6W: umsatzWachstum6W.toFixed(2), // Neu
            kundenbon: kundenbon.toFixed(2),
            kundenbonVJ: kundenbonVJ.toFixed(2),
            kundenbonAbweichungVJ: kundenbonAbweichungVJ.toFixed(2),
            kundenbonAbweichungVM: kundenbonAbweichungVM.toFixed(2),
            kundenbonAbweichung6M: kundenbonAbweichung6M.toFixed(2),
            kundenbonAbweichungVW: kundenbonAbweichungVW.toFixed(2), // Neu
            kundenbonAbweichung6W: kundenbonAbweichung6W.toFixed(2), // Neu
        };
    });

    // Berechnung des Umsatzwachstums und Kundenbons für jeden Filialtyp
    const filialtypenResult = Object.keys(filialtypen).map((typ) => {
        const data = filialtypen[typ];
        const {
            umsatzSumme,
            umsatzVJSumme,
            umsatzVMSumme,
            umsatz6MSumme,
            umsatzVWSumme,  // Neu
            umsatz6WSumme,  // Neu
            kundenanzahlSumme,
            kundenanzahlVJSumme,
            kundenanzahlVMSumme,
            kundenanzahl6MSumme,
            kundenanzahlVWSumme, // Neu
            kundenanzahl6WSumme, // Neu
        } = data;

        const umsatzAbweichung = umsatzVJSumme > 0 ? ((umsatzSumme - umsatzVJSumme) / umsatzVJSumme) * 100 : 0;
        const umsatzAbweichungVM = umsatzVMSumme > 0 ? ((umsatzSumme - umsatzVMSumme) / umsatzVMSumme) * 100 : 0;
        const umsatzAbweichung6M = umsatz6MSumme > 0 ? ((umsatzSumme - umsatz6MSumme) / umsatz6MSumme) * 100 : 0;
        const umsatzAbweichungVW = umsatzVWSumme > 0 ? ((umsatzSumme - umsatzVWSumme) / umsatzVWSumme) * 100 : 0; // Neu
        const umsatzAbweichung6W = umsatz6WSumme > 0 ? ((umsatzSumme - umsatz6WSumme) / umsatz6WSumme) * 100 : 0; // Neu

        // Kundenbon berechnen
        const kundenbon = kundenanzahlSumme > 0 ? umsatzSumme / kundenanzahlSumme : 0;
        const kundenbonVJ = kundenanzahlVJSumme > 0 ? umsatzVJSumme / kundenanzahlVJSumme : 0;
        const kundenbonVM = kundenanzahlVMSumme > 0 ? umsatzVMSumme / kundenanzahlVMSumme : 0;
        const kundenbon6M = kundenanzahl6MSumme > 0 ? umsatz6MSumme / kundenanzahl6MSumme : 0;
        const kundenbonVW = kundenanzahlVWSumme > 0 ? umsatzVWSumme / kundenanzahlVWSumme : 0; // Neu
        const kundenbon6W = kundenanzahl6WSumme > 0 ? umsatz6WSumme / kundenanzahl6WSumme : 0; // Neu

        // Kundenbon Abweichungen
        const kundenbonAbweichungVJ = kundenbonVJ > 0 ? ((kundenbon - kundenbonVJ) / kundenbonVJ) * 100 : 0;
        const kundenbonAbweichungVM = kundenbonVM > 0 ? ((kundenbon - kundenbonVM) / kundenbonVM) * 100 : 0;
        const kundenbonAbweichung6M = kundenbon6M > 0 ? ((kundenbon - kundenbon6M) / kundenbon6M) * 100 : 0;
        const kundenbonAbweichungVW = kundenbonVW > 0 ? ((kundenbon - kundenbonVW) / kundenbonVW) * 100 : 0; // Neu
        const kundenbonAbweichung6W = kundenbon6W > 0 ? ((kundenbon - kundenbon6W) / kundenbon6W) * 100 : 0; // Neu

        return {
            filialtyp: typ,
            umsatzSumme,
            umsatzVJSumme,
            umsatzAbweichung: umsatzAbweichung.toFixed(2),
            umsatzAbweichungVM: umsatzAbweichungVM.toFixed(2),
            umsatzAbweichung6M: umsatzAbweichung6M.toFixed(2),
            umsatzAbweichungVW: umsatzAbweichungVW.toFixed(2), // Neu
            umsatzAbweichung6W: umsatzAbweichung6W.toFixed(2), // Neu
            kundenbon: kundenbon.toFixed(2),
            kundenbonVJ: kundenbonVJ.toFixed(2),
            kundenbonAbweichung: kundenbonAbweichungVJ.toFixed(2),
            kundenbonAbweichungVM: kundenbonAbweichungVM.toFixed(2),
            kundenbonAbweichung6M: kundenbonAbweichung6M.toFixed(2),
            kundenbonAbweichungVW: kundenbonAbweichungVW.toFixed(2), // Neu
            kundenbonAbweichung6W: kundenbonAbweichung6W.toFixed(2), // Neu
        };
    });

    return {
        umsatzSumme,
        umsatzVJSumme,
        umsatzVMSumme,
        umsatz6MSumme,
        umsatzVWSumme,      // Neu
        umsatz6WSumme,      // Neu
        umsatzAbweichungVM,
        umsatzAbweichung6M,
        umsatzAbweichungVJ,
        umsatzAbweichungVW, // Neu
        umsatzAbweichung6W, // Neu
        kundenanzahlSumme,
        kundenanzahlVJSumme,
        kundenanzahlVMSumme,
        kundenanzahl6MSumme,
        kundenanzahlVWSumme, // Neu
        kundenanzahl6WSumme, // Neu
        kundenanzahlAbweichungVM,
        kundenanzahlAbweichung6M,
        kundenanzahlAbweichungVJ,
        kundenanzahlAbweichungVW, // Neu
        kundenanzahlAbweichung6W, // Neu
        kundenbon,
        kundenbonVJ,
        kundenbonVM,
        kundenbon6M,
        kundenbonVW,  // Neu
        kundenbon6W,  // Neu
        kundenbonAbweichungVM,
        kundenbonAbweichung6M,
        kundenbonAbweichungVJ,
        kundenbonAbweichungVW, // Neu
        kundenbonAbweichung6W, // Neu
        leistung,
        leistungVJ,
        verkaufsgebieteResult,
        filialtypenResult,
    };
};
