import React, {useContext, useEffect, useState} from 'react';
import Layout from '../../../components/layout/Scorecards/FilialeMonat';
import {Grid, } from '@mui/material';
import Stack from '@mui/material/Stack';
import DataCard from '../../../components/card/DataCard';
import axiosInstance from '../../../services/axiosInstance';
import dayjs from 'dayjs';
import ComparisonCard from '../../../components/card/ComparisonCard';
import {FilterContext} from "../../../provider/FilterProvider";
import ScorecardsNotes from "../../../components/formular/Scorecards/ScorecardsNotes";
import BigChartCard from "../../../components/card/BigChartCard"; // Hinzugefügt
import {Alert} from "@mui/material";
import {getAlertSeverity} from "../../../components/utility/AlertPopup";
import TAPerformanceKunden from "../../../components/scorecards/tiefenanalyse/TAPerformanceKunden";
import { tausenderTrennung } from "../../../utils/mathStuff";




const Kundenanzahl = () => {
    const [comparisonCardData, setComparisonCardData] = useState([]);
    const [dataCardData, setDataCardData] = useState([]);
    const [lineChartData, setLineChartData] = useState([]);
    const {filterState} = useContext(FilterContext);
    const activeCategory = 'Kundenanzahl';

    useEffect(() => {
        if (filterState.filiale && filterState.datum) {
            fetchData();
        }
    }, [filterState.filiale, filterState.datum]);

    const fetchData = async () => {
        try {
            const endDate = dayjs(`${filterState.datum?.value}-01`, 'YYYY-MM-DD');
            const startDate = endDate.subtract(12, 'month');

            const response = await axiosInstance.get('scorecards_month/performance-monat/', {
                params: {
                    startdatum: startDate.format('YYYY-MM-DD'),
                    enddatum: endDate.format('YYYY-MM-DD'),
                    filiale: filterState.filiale?.label
                }
            });

            const responseUnternehmen = await axiosInstance.get('scorecards_month/performance-unternehmen-monat/', {
                params: {
                    startdatum: startDate.format('YYYY-MM-DD'),
                    enddatum: endDate.format('YYYY-MM-DD')
                }
            });

            console.log("branchResponse", response);
            console.log("companyResponse", responseUnternehmen);

            const dataCardData = response.data.results
                .slice(-4)
                .map(item => ({
                    x: item.datum,
                    Umsatz: item.scoreUmsatz,
                    Kundenanzahl: item.scoreKundenanzahl,
                    Kundenbon: item.scoreKundenbon,
                    Leistung: item.scoreLeistung,
                }));

                const branchLineChartData = response.data.results
                .map(item => ({
                    x: item.datum,
                    y: item.kundenanzahl,
                    label: 'Filiale'
                }))
                .sort((a, b) => dayjs(a.x).isAfter(dayjs(b.x)) ? 1 : -1);  // Sortiere die Daten nach Datum
            
            const companyLineChartData = responseUnternehmen.data.results
                .map(item => ({
                    x: item.datum,
                    y: item.kundenanzahl,
                    label: 'Unternehmen'
                }))
                .sort((a, b) => dayjs(a.x).isAfter(dayjs(b.x)) ? 1 : -1);  // Sortiere die Daten nach Datum
            

            const combinedLineChartData = [...branchLineChartData, ...companyLineChartData];

            const additionalComparisonCardData = responseUnternehmen.data.results
                .slice(-1)
                .map(item => ({
                    gesamtKundenanzahl: item.kundenanzahl,
                    gesamtKundenanzahlWachstumM: item.kundenanzahlWachstumM,
                    gesamtKundenanzahlWachstum6M: item.kundenanzahlWachstum6M,
                    gesamtKundenanzahlWachstumVJ: item.kundenanzahlWachstumJ,
                }));

            console.log("additionalComparisonCardData", additionalComparisonCardData);
            const baseComparisonCardData = response.data.results.slice(-1);

            const comparisonCardData = [...baseComparisonCardData, ...additionalComparisonCardData]


            console.log("comparisoncarddata", comparisonCardData);

            setComparisonCardData(comparisonCardData);
            setDataCardData(dataCardData);
            setLineChartData(combinedLineChartData);
        } catch (error) {
            console.error('An error occurred while fetching data:', error);
        }
    };

    return (
        <Layout>
<Grid container justifyContent="flex-end" alignItems="stretch">
                <Grid item xs={12}>
                    <Stack direction="row" spacing={2} sx={{overflowX: 'auto'}}>
                        {['Umsatz', 'Kundenanzahl', 'Kundenbon', 'Leistung'].map((category, index) => (
                            <Grid item xs={12} md={6} lg={3} key={index}
                                  className={activeCategory === category ? 'db_datacard_active' : 'db_datacard_not_active'}>
                                <DataCard data={dataCardData} category={category} sector={"performance"}
                                          categoryData={activeCategory === category ? `${comparisonCardData.length > 0 ? tausenderTrennung(comparisonCardData[0].kundenanzahl) : null} ` : ''}/>
                            </Grid>
                        ))}
                    </Stack>
                </Grid>

                <Grid container item xs={12} md={12} spacing={2}>  
                            <Grid item xs={12} sm={12} md={6} mt={2} sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
                            <Alert
                                    severity={getAlertSeverity(comparisonCardData.length > 0 ? comparisonCardData[0]["scoreKundenanzahl"] : null)}
                                >
                                    {comparisonCardData.length > 0 ? comparisonCardData[0]["feedbackKundenanzahl"] : 'Keine Rückmeldung verfügbar'}
                                </Alert>
                            <ComparisonCard
                                zeitraum="Vorjahr"
                                data={comparisonCardData}
                                rangField="rangKundenanzahlWachstumJ"
                                percentageField="kundenanzahlWachstumJ"
                                scoreField="scoreKundenanzahlWachstumJ"
                                TiefenanalyseComponent={TAPerformanceKunden}
                            />
                            <ComparisonCard
                                zeitraum="∅ der letzten 6 Monate"
                                data={comparisonCardData}
                                rangField="rangKundenanzahlWachstum6M"
                                percentageField="kundenanzahlWachstum6M"
                                scoreField="scoreKundenanzahlWachstum6M"
                                TiefenanalyseComponent={TAPerformanceKunden}
                            />
                            <ComparisonCard
                                zeitraum="Vormonat"
                                data={comparisonCardData}
                                rangField="rangKundenanzahlWachstumM"
                                percentageField="kundenanzahlWachstumM"
                                scoreField="scoreKundenanzahlWachstumM"
                                TiefenanalyseComponent={TAPerformanceKunden}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} mt={2}>
                            <BigChartCard
                                data={lineChartData}
                                title={"Kundenentwicklung Unternehmen und Filiale"}
                                infoContent={
                                    <>
                                        <p>Kundenverlauf - Informationen</p>
                                        <p>Dieses Diagramm zeigt den Verlauf der Kunden deiner ausgewählten Filiale im Vergleich zum gesamten Unternehmen.</p>
                                        <p><strong>Filiale:</strong> Die dunklere Linie zeigt die Kundenentwicklung deiner Filiale über die Zeit.</p>
                                        <p><strong>Unternehmen:</strong> Die hellere Linie repräsentiert die Kundenentwicklung des gesamten Unternehmens.</p>
                                        <p>So kannst du Trends und Unterschiede zwischen deiner Filiale und dem Unternehmen erkennen.</p>
                                    </>
                                }
                                
                            />
                        </Grid>
                    
                </Grid>
            </Grid>
            <ScorecardsNotes category="Performance" />

        </Layout>
    );
};

export default Kundenanzahl;
