import React, { useState, useEffect, useContext, useMemo } from 'react';
import Layout from '../../../components/layout/personalcontrolling/personalcontrolling';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import Battery3Bar from '@mui/icons-material/Battery3Bar';
import { Card, Typography, Box, Paper, CardHeader, CardContent, Grid } from '@mui/material';
import { FilterContext } from '../../../provider/FilterProvider';
import axiosInstance from '../../../services/axiosInstance';
import VariableTableTree from '../../../components/table/VariableTableTree';

const Struktur = () => {
  const { filterState } = useContext(FilterContext);
  const [employees, setEmployees] = useState([]);
  const [employeeCounts, setEmployeeCounts] = useState({});
  const [totalEmployees, setTotalEmployees] = useState(0);
  const [aggregatedData, setAggregatedData] = useState([]);
  const [employeeTypes, setEmployeeTypes] = useState([]); // Dynamische Arbeitnehmertypen
  const [filialeVerkaufsgebietMap, setFilialeVerkaufsgebietMap] = useState({}); // Mapping von Filiale zu Verkaufsgebiet

  useEffect(() => {
    fetchFilialeVerkaufsgebietMapping();
  }, []);

  useEffect(() => {
    if (Object.keys(filialeVerkaufsgebietMap).length > 0) {
      fetchEmployees();
    }
  }, [filterState, filialeVerkaufsgebietMap]);

  const fetchFilialeVerkaufsgebietMapping = async () => {
    try {
      const response = await axiosInstance.get('/stammdaten/stammdatenfiliale/'); // API-Endpunkt anpassen
      const filialenData = response.data.results;

      // Erstellen eines Mappings von Filiale zu Verkaufsgebiet
      const mapping = {};
      filialenData.forEach((filiale) => {
        mapping[filiale.filiale] = filiale.verkaufsgebiet;
      });

      setFilialeVerkaufsgebietMap(mapping);
    } catch (error) {
      console.error('Fehler beim Laden der Filialdaten:', error);
    }
  };

  const fetchEmployees = async () => {
    try {
      const response = await axiosInstance.get('personaldaten/stammdaten-mitarbeiter/', {
        params: {
          filiale: filterState.filiale?.label,
        },
      });
      const employeeData = response.data.results;

      // Anreichern der Mitarbeiterdaten mit dem korrekten Verkaufsgebiet
      const enrichedEmployeeData = employeeData.map((emp) => {
        const filiale = emp.filiale || emp.stammkostenstelle?.filiale;
        const verkaufsgebiet = filialeVerkaufsgebietMap[filiale] || 'Unbekannt';
        return {
          ...emp,
          filiale,
          verkaufsgebiet,
        };
      });

      setEmployees(enrichedEmployeeData);

      // Zählen der Mitarbeiter pro Arbeitnehmertyp und Berechnung des Gesamts
      const counts = enrichedEmployeeData.reduce((acc, curr) => {
        acc[curr.arbeitnehmertyp] = (acc[curr.arbeitnehmertyp] || 0) + 1;
        return acc;
      }, {});
      setEmployeeCounts(counts);
      setTotalEmployees(enrichedEmployeeData.length); // Gesamtanzahl der Mitarbeiter

      // Ermitteln der Arbeitnehmertypen (dynamisch)
      const types = [...new Set(enrichedEmployeeData.map((emp) => emp.arbeitnehmertyp))];
      setEmployeeTypes(types);

      // Aggregation nach Verkaufsgebiet, Filiale und Arbeitnehmertyp
      const aggregation = enrichedEmployeeData.reduce((acc, curr) => {
        const verkaufsgebiet = curr.verkaufsgebiet || 'Unbekannt';
        const filiale = curr.filiale || 'Unbekannt';
        const key = `${verkaufsgebiet}-${filiale}`;

        if (!acc[key]) {
          acc[key] = {
            verkaufsgebiet,
            filiale,
            mitarbeiter: 0,
          };
          types.forEach((type) => (acc[key][type] = 0)); // Initialisiere Arbeitnehmertypen
        }
        acc[key].mitarbeiter += 1;
        acc[key][curr.arbeitnehmertyp] += 1;
        return acc;
      }, {});

      // Entfernen von null-Werten für jeden Arbeitnehmertyp
      const filteredAggregation = Object.values(aggregation).map((item) => {
        const filteredItem = { ...item };
        employeeTypes.forEach((type) => {
          if (filteredItem[type] === 0) {
            delete filteredItem[type]; // Entfernen, wenn der Wert null ist
          }
        });
        return filteredItem;
      });

      setAggregatedData(filteredAggregation); // Array der gefilterten aggregierten Daten
    } catch (error) {
      console.error('Fehler beim Laden der Mitarbeiterdaten:', error);
    }
  };

  // Spaltenkonfiguration für die aggregierten Daten nach Verkaufsgebiet und Filiale
  const aggregatedColumns = useMemo(() => {
    const baseColumns = [
      {
        accessorKey: 'verkaufsgebiet',
        header: 'Verkaufsgebiet',
        enableGrouping: true,
        isVisible: true,
      },
      {
        accessorKey: 'filiale',
        header: 'Filiale',
        enableGrouping: false,
        isVisible: true,
      },
      {
        accessorKey: 'mitarbeiter',
        header: 'Anzahl der Mitarbeiter',
        enableGrouping: false,
        aggregationFn: 'sum',
        Cell: ({ cell }) => cell.getValue(),
        AggregatedCell: ({ cell, row }) => {
          const subRows = row.subRows || [];
          const total = subRows.reduce(
            (sum, subRow) => sum + (subRow.getValue('mitarbeiter') || 0),
            0
          );
          return total;
        },
        isVisible: true,
      },
    ];

    // Dynamisch Arbeitnehmertypen als Spalten hinzufügen
    const typeColumns = employeeTypes.map((type) => ({
      accessorKey: type,
      header: `${type} Mitarbeiter`,
      enableGrouping: false,
      aggregationFn: 'sum',
      Cell: ({ cell, row }) => {
        const value = cell.getValue();
        if (!value) return '';
        const totalEmployeesAtLevel = row.getValue('mitarbeiter') || 0;
        const percentage =
          totalEmployeesAtLevel > 0 ? ((value / totalEmployeesAtLevel) * 100).toFixed(2) : '0.00';
        return `${value} (${percentage}%)`;
      },
      AggregatedCell: ({ cell, row }) => {
        const subRows = row.subRows || [];
        const totalForType = subRows.reduce(
          (sum, subRow) => sum + (subRow.getValue(type) || 0),
          0
        );
        const totalEmployeesAtLevel = subRows.reduce(
          (sum, subRow) => sum + (subRow.getValue('mitarbeiter') || 0),
          0
        );
        const percentage =
          totalEmployeesAtLevel > 0
            ? ((totalForType / totalEmployeesAtLevel) * 100).toFixed(2)
            : '0.00';
        return `${totalForType} (${percentage}%)`;
      },
      isVisible: true,
    }));

    return [...baseColumns, ...typeColumns];
  }, [employeeTypes]);

  return (
    <Layout>
      <PageHeader
        title="Strukturanalyse"
        subtitle="Dieser Bericht bietet eine Übersicht über die aktuelle Verteilung der Mitarbeiter pro Verkaufsgebiet. Er zeigt auf, wie viele Mitarbeiter in den einzelnen Verkaufsgebieten beschäftigt sind und wie sich diese auf verschiedene Anstellungstypen (z.B. Vollzeit, Teilzeit, Aushilfen) verteilen. Diese Analyse ermöglicht es, einen klaren Überblick über die Personalstruktur zu erhalten und unterstützt bei der Planung sowie Optimierung der Ressourcennutzung in den jeweiligen Verkaufsgebieten."
        Icon={Battery3Bar}
      />

      {/* Card für Mitarbeiterzahlen und Prozentanteile pro Arbeitnehmertyp */}
      <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
        {Object.entries(employeeCounts).map(([type, count]) => {
          const percentage = ((count / totalEmployees) * 100).toFixed(2); // Prozentanteil berechnen
          return (
            <Card key={type} sx={{ padding: 2 }}>
              <Typography variant="h6">{type}</Typography>
              <Typography variant="body1">{count} Mitarbeiter</Typography>
              <Typography variant="body2">{percentage}% vom Gesamt</Typography> {/* Prozentanzeige */}
            </Card>
          );
        })}
      </Box>

      {/* Tabelle für aggregierte Daten nach Verkaufsgebiet und Filiale */}
      <Grid item xs={12} md={12} mt={3}>
        <Paper>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              padding: '0px',
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              backdropFilter: 'blur(10px)',
            }}
          >
            <CardHeader
              title={
                <Typography variant="h6" component="div">
                  Struktur nach Verkaufsgebiet und Filiale
                </Typography>
              }
            />
            <CardContent sx={{ flexGrow: 1 }}>
              <VariableTableTree
                columns={aggregatedColumns}
                data={aggregatedData}
                initialGrouping={['verkaufsgebiet', 'filiale']}
              />
            </CardContent>
          </Card>
        </Paper>
      </Grid>
    </Layout>
  );
};

export default Struktur;
