import React, { useState, useEffect } from 'react';
import {
    Box,
    Tabs,
    Tab,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    CircularProgress,
    MenuItem,
    Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Layout from '../../../components/layout/Technik/Technik';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import QuickreplyOutlinedIcon from '@mui/icons-material/QuickreplyOutlined';
import axiosInstance from '../../../services/axiosInstance';
import dayjs from 'dayjs';
import { QRCodeCanvas } from 'qrcode.react'; // QRCodeCanvas importieren
import { saveAs } from 'file-saver'; // FileSaver importieren
import Dienstleister from '../../../components/technik/Dienstleister'; // Neue Dienstleister-Komponente importieren

const Index = () => {
    const [tabIndex, setTabIndex] = useState(0);
    const [data, setData] = useState([]); // Inventar-Daten
    const [loading, setLoading] = useState(true);
    const [filialeOptions, setFilialeOptions] = useState([]); // Filialoptionen
    const [error, setError] = useState(null); // Fehlernachricht
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [newItemDialogOpen, setNewItemDialogOpen] = useState(false);
    const [newItem, setNewItem] = useState({
        name: '',
        kategorie: '',
        filialnummer: '', // Wird für das Backend genutzt
        seriennummer: '',
        hersteller: '',
        gekauft_am: '',
        garantie_bis: '',
        zustand: '',
        standort: '',
        kontakt_hersteller: '',
        notizen: '',
    });

    // API-Daten abrufen
    const fetchData = async () => {
        try {
            const response = await axiosInstance.get('technik/inventar/');
            setData(response.data.results || response.data || []); // Sicherstellen, dass ein Array genutzt wird
            setLoading(false);
        } catch (error) {
            console.error('Fehler beim Abrufen der Inventar-Daten:', error);
            setData([]);
            setLoading(false);
        }
    };

    // Filialen abrufen
    useEffect(() => {
        const fetchFilialeOptions = async () => {
            try {
                const filialeResponse = await axiosInstance.get('stammdaten/unique-filiale/');
                const filialeOpts = filialeResponse.data.map((item) => ({
                    label: item.filiale,
                    value: item.filialnummer,
                }));
                setFilialeOptions(filialeOpts);
            } catch (error) {
                setError('Fehler beim Laden der Filialen.');
            }
        };

        fetchFilialeOptions();
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    // Öffnet den Dialog mit den Details
    const handleRowClick = (item) => {
        setSelectedItem({ ...item });
        setOpenDialog(true);
    };

    // Schließt den Dialog
    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedItem(null);
    };

    // Speichern von Änderungen im Dialog
    const handleSaveChanges = async () => {
        try {
            const formattedItem = {
                ...selectedItem,
                gekauft_am: selectedItem.gekauft_am ? dayjs(selectedItem.gekauft_am).format('YYYY-MM-DD') : null,
                garantie_bis: selectedItem.garantie_bis ? dayjs(selectedItem.garantie_bis).format('YYYY-MM-DD') : null,
            };
            await axiosInstance.put(`technik/inventar/${selectedItem.id}/`, formattedItem);
            fetchData(); // Aktualisiert die Tabelle
            setOpenDialog(false);
            setSelectedItem(null);
        } catch (error) {
            console.error('Fehler beim Aktualisieren des Inventars:', error.response?.data || error.message);
        }
    };

    // Öffnet den Dialog für neues Inventar
    const handleNewItemDialogOpen = () => {
        setNewItemDialogOpen(true);
    };

    // Schließt den Dialog für neues Inventar
    const handleNewItemDialogClose = () => {
        setNewItemDialogOpen(false);
    };

    // Fügt neues Inventar hinzu
    const handleAddNewItem = async () => {
        try {
            const formattedItem = {
                ...newItem,
                gekauft_am: newItem.gekauft_am ? dayjs(newItem.gekauft_am).format('YYYY-MM-DD') : null,
                garantie_bis: newItem.garantie_bis ? dayjs(newItem.garantie_bis).format('YYYY-MM-DD') : null,
            };

            const response = await axiosInstance.post('technik/inventar/', formattedItem);
            setData([...data, response.data]); // Neuen Eintrag hinzufügen
            setNewItem({
                name: '',
                kategorie: '',
                filialnummer: '',
                seriennummer: '',
                hersteller: '',
                gekauft_am: '',
                garantie_bis: '',
                zustand: '',
                standort: '',
                kontakt_hersteller: '',
                notizen: '',
            });
            setNewItemDialogOpen(false);
        } catch (error) {
            console.error('Fehler beim Hinzufügen des Inventars:', error.response?.data || error.message);
        }
    };

    // Funktion zum Herunterladen des QR-Codes
    const downloadQRCode = (id, name) => {
        const canvas = document.getElementById(`qrCode-${id}`);
        if (canvas) {
            canvas.toBlob((blob) => {
                saveAs(blob, `${name}-QRCode.png`);
            });
        }
    };

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    return (
        <Layout>
            <Box
                sx={{
                    flexGrow: 1,
                    p: 3,
                    maxWidth: 1200,
                    margin: '0 auto',
                    bgcolor: 'transparent',
                }}
            >
                <PageHeader
                    title="Technik Verwaltung"
                    subtitle="Verwalte Inventar und Dienstleister."
                    Icon={QuickreplyOutlinedIcon}
                />

                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabIndex} onChange={handleTabChange}>
                        <Tab label="Inventar" />
                        <Tab label="Dienstleister" />
                    </Tabs>
                </Box>

                {/* Inventar Tab */}
                {tabIndex === 0 && (
                    <Box sx={{ mt: 2 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddIcon />}
                            sx={{ mb: 2 }}
                            onClick={handleNewItemDialogOpen}
                        >
                            Neues Inventar hinzufügen
                        </Button>

                        {/* Tabelle */}
                        {loading ? (
                            <CircularProgress />
                        ) : (
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Kategorie</TableCell>
                                            <TableCell>Filiale</TableCell>
                                            <TableCell align="center">Details</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Array.isArray(data) && data.length > 0 ? (
                                            data.map((item) => (
                                                <TableRow key={item.id}>
                                                    <TableCell>{item.name}</TableCell>
                                                    <TableCell>{item.kategorie}</TableCell>
                                                    <TableCell>
                                                        {
                                                            filialeOptions.find(
                                                                (option) => option.value === item.filialnummer
                                                            )?.label || 'Unbekannt'
                                                        }
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        <IconButton
                                                            color="primary"
                                                            onClick={() => handleRowClick(item)}
                                                        >
                                                            <InfoOutlinedIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={5} align="center">
                                                    Keine Daten verfügbar.
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}

                        {/* Neues Inventar-Dialog */}
                        <Dialog open={newItemDialogOpen} onClose={handleNewItemDialogClose}>
                            <DialogTitle>Neues Inventar hinzufügen</DialogTitle>
                            <DialogContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            select
                                            fullWidth
                                            label="Filiale"
                                            value={newItem.filialnummer}
                                            onChange={(e) => setNewItem({ ...newItem, filialnummer: e.target.value })}
                                            margin="dense"
                                        >
                                            {filialeOptions.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    {Object.keys(newItem).map(
                                        (key) =>
                                            key !== 'filialnummer' && (
                                                <Grid item xs={12} sm={6} key={key}>
                                                    <TextField
                                                        fullWidth
                                                        label={key.replace('_', ' ').toUpperCase()}
                                                        value={newItem[key]}
                                                        onChange={(e) =>
                                                            setNewItem({ ...newItem, [key]: e.target.value })
                                                        }
                                                        margin="dense"
                                                    />
                                                </Grid>
                                            )
                                    )}
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleNewItemDialogClose} color="secondary">
                                    Abbrechen
                                </Button>
                                <Button onClick={handleAddNewItem} color="primary">
                                    Hinzufügen
                                </Button>
                            </DialogActions>
                        </Dialog>

                        {/* Details-Dialog */}
                        <Dialog open={openDialog} onClose={handleCloseDialog}>
                            <DialogTitle>Inventar bearbeiten</DialogTitle>
                            <DialogContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            select
                                            fullWidth
                                            label="Filiale"
                                            value={selectedItem?.filialnummer || ''}
                                            onChange={(e) =>
                                                setSelectedItem({ ...selectedItem, filialnummer: e.target.value })
                                            }
                                            margin="dense"
                                        >
                                            {filialeOptions.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    {selectedItem &&
                                        Object.keys(selectedItem).map(
                                            (key) =>
                                                key !== 'filialnummer' && key !== 'id_qrcode' && (
                                                    <Grid item xs={12} sm={6} key={key}>
                                                        <TextField
                                                            fullWidth
                                                            label={key.replace('_', ' ').toUpperCase()}
                                                            value={selectedItem[key]}
                                                            onChange={(e) =>
                                                                setSelectedItem({
                                                                    ...selectedItem,
                                                                    [key]: e.target.value,
                                                                })
                                                            }
                                                            margin="dense"
                                                        />
                                                    </Grid>
                                                )
                                        )}
                                    {/* QR-Code im Dialog anzeigen */}
                                    {selectedItem && (
                                        <Grid item xs={12} style={{ textAlign: 'center', marginTop: '20px' }}>
                                            <Typography variant="h6">QR-Code</Typography>
                                            <QRCodeCanvas
                                                id={`qrCode-${selectedItem.id_qrcode}`}
                                                value={selectedItem.id_qrcode}
                                                size={128}
                                            />
                                            <Button
                                                onClick={() => downloadQRCode(selectedItem.id_qrcode, selectedItem.name)}
                                                color="primary"
                                                variant="outlined"
                                                size="small"
                                                sx={{ mt: 1 }}
                                            >
                                                QR-Code herunterladen
                                            </Button>
                                        </Grid>
                                    )}
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseDialog} color="secondary">
                                    Abbrechen
                                </Button>
                                <Button onClick={handleSaveChanges} color="primary">
                                    Speichern
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Box>
                )}

                {/* Dienstleister Tab */}
                {tabIndex === 1 && (
                    <Box sx={{ mt: 2 }}>
                        <Dienstleister />
                    </Box>
                )}
            </Box>
        </Layout>
    );
};

export default Index;
