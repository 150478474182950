import React, { useContext, useEffect, useState } from 'react';
import { Table, TableBody, Stack, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, Grid, Tabs, Tab, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Layout from '../../../components/layout/Scorecards/FilialeMonat';
import axiosInstance from '../../../services/axiosInstance';
import dayjs from 'dayjs';
import { FilterContext } from "../../../provider/FilterProvider";
import Score from '../../../components/score/Score';
import DropdownFilter from '../../../components/filter/DropdownFilter';
import VerkaufsgebietDropdown from '../../../components/filter/VerkaufsgebietDropdown';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import {useAuth} from "../../../provider/authProvider";
import StackedBarChartOutlinedIcon from '@mui/icons-material/StackedBarChartOutlined';

const Overview = () => {
    const [data, setData] = useState([]);
    const [performanceData, setPerformanceData] = useState([]);
    const [personalData, setPersonalData] = useState([]);
    const [wareData, setWareData] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);
    const [verkaufsgebietOptions, setVerkaufsgebietOptions] = useState([]);
    const [verkaufsgebietFilter, setVerkaufsgebietFilter] = useState(null);
    const [datumOptions, setDatumOptions] = useState([]);
    const [greeting, setGreeting] = useState('Hallo');

    const { filterState, handleFilialeChange, handleDatumChange } = useContext(FilterContext);
    const navigate = useNavigate();

    useEffect(() => {
        setGreetingMessage();
        fetchVerkaufsgebietOptions();
        fetchDatumOptions();
    }, []);

    useEffect(() => {
        console.log("Datum oder VerkaufsgebietFilter geändert:", filterState.datum, verkaufsgebietFilter);
        if (filterState.datum) {
            fetchAllData();
        }
    }, [filterState.datum, verkaufsgebietFilter]);

    const setGreetingMessage = () => {
        const currentHour = dayjs().hour();
        if (currentHour < 12) {
            setGreeting('Guten Morgen');
        } else if (currentHour < 18) {
            setGreeting('Hallo');
        } else {
            setGreeting('Guten Abend');
        }
    };

    const fetchVerkaufsgebietOptions = async () => {
        try {
            const response = await axiosInstance.get('/stammdaten/unique-verkaufsgebiet/');
            setVerkaufsgebietOptions(response.data.map(item => ({ label: item, value: item })));
        } catch (error) {
            console.error('Error while fetching VerkaufsgebietOptions', error);
        }
    };

    const fetchDatumOptions = async () => {
        try {
            const response = await axiosInstance.get('/stammdaten/unique-jahr-monat/');
            setDatumOptions(response.data.map(item => ({ label: item, value: item })));
        } catch (error) {
            console.error('Error while fetching datum options', error);
        }
    };

    const fetchAllData = async () => {
        try {
            const params = {
                jahrMonat: filterState.datum?.value,
                aktiv: 'True',
            };
            if (verkaufsgebietFilter) {
                params.verkaufsgebiet = verkaufsgebietFilter.value;
            }

            const [performanceResponse, personalResponse, wareResponse] = await Promise.all([
                axiosInstance.get('scorecards_month/performance-monat/', { params }),
                axiosInstance.get('scorecards_month/personal-kennzahl/', { params }),
                axiosInstance.get('scorecards_month/ware-filiale-monat/', { params }),
            ]);

            // Wir gehen davon aus, dass performanceResponse.data.results ein Feld `filialnummer` enthält.
            // Sollte die API anders aufgebaut sein, bitte entsprechend anpassen.
            const combinedData = performanceResponse.data.results.map((performanceItem) => {
                const filiale = performanceItem.filiale;
                const filialnummer = performanceItem.filialnummer; // Muss ggf. an die echte Response angepasst werden
                const personalItem = personalResponse.data.results.find(item => item.filiale === filiale) || {};
                const wareItem = wareResponse.data.results.find(item => item.filiale === filiale) || {};

                return {
                    filiale: filiale,
                    filialnummer: filialnummer,
                    performanceScore: performanceItem.scorePerformance || 0,
                    personalScore: personalItem.scorePersonal || 0,
                    wareScore: wareItem.scoreWareFiliale || 0,
                    umsatz: performanceItem.scoreUmsatz || 0,
                    kundenanzahl: performanceItem.scoreKundenanzahl || 0,
                    kundenbon: performanceItem.scoreKundenbon || 0,
                    leistung: performanceItem.scoreLeistung || 0,
                    krankenquote: personalItem.scoreKrankenquote || 0,
                    fluktuation: personalItem.scoreFluktuation || 0,
                    bedarf: personalItem.scoreKöpfeDifferenzQuote || 0,
                    auslastung: personalItem.scoreÜberstunden || 0,
                    wachstum: wareItem.scoreProduktwachstumFiliale || 0,
                    retoureA: wareItem.scoreSollRetoureFiliale || 0,
                    verfügbarkeit: wareItem.scoreVerfügbarkeitFiliale || 0,
                    retoureBC: wareItem.scoreRetourQuoteFilialeBC || 0,
                };
            });

            setData(combinedData);
            setPerformanceData(combinedData);
            setPersonalData(combinedData);
            setWareData(combinedData);
        } catch (error) {
            console.error('An error occurred while fetching data:', error);
        }
    };

    const handleTabChange = (event, newIndex) => {
        setTabIndex(newIndex);
    };

    const handleRowClick = (item) => {
        // Hier nun mit Filialnummer
        const selectedFiliale = { label: item.filiale, value: item.filialnummer };
        handleFilialeChange(selectedFiliale);
        navigate('/scorecards/overview/cockpit');
    };

    const renderTabContent = () => {
        switch (tabIndex) {
            case 0:
                return renderPerformanceTable();
            case 1:
                return renderPersonalTable();
            case 2:
                return renderWareTable();
            default:
                return null;
        }
    };

    const renderPerformanceTable = () => (
        <TableContainer component={Paper} sx={{ maxHeight: 800, overflowY: 'auto' }}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell align="left">
                            <Typography>Filiale</Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography>Umsatz</Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography>Kundenanzahl</Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography>Kundenbon</Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography>Leistung</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {performanceData.map((item, index) => (
                        <TableRow 
                            key={index}
                            onClick={() => handleRowClick(item)}
                            sx={{ cursor: 'pointer' }}
                        >
                            <TableCell align="left">
                                <Typography>{item.filiale}</Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Score score={item.umsatz} />
                            </TableCell>
                            <TableCell align="center">
                                <Score score={item.kundenanzahl} />
                            </TableCell>
                            <TableCell align="center">
                                <Score score={item.kundenbon} />
                            </TableCell>
                            <TableCell align="center">
                                <Score score={item.leistung} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

    const renderPersonalTable = () => (
        <TableContainer component={Paper} sx={{ maxHeight: 800, overflowY: 'auto' }}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell align="left">
                            <Typography>Filiale</Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography>Krankenquote</Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography>Fluktuation</Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography>Bedarf</Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography>Auslastung</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {personalData.map((item, index) => (
                        <TableRow
                            key={index}
                            onClick={() => handleRowClick(item)}
                            sx={{ cursor: 'pointer' }}
                        >
                            <TableCell align="left">
                                <Typography>{item.filiale}</Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Score score={item.krankenquote} />
                            </TableCell>
                            <TableCell align="center">
                                <Score score={item.fluktuation} />
                            </TableCell>
                            <TableCell align="center">
                                <Score score={item.bedarf} />
                            </TableCell>
                            <TableCell align="center">
                                <Score score={item.auslastung} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

    const renderWareTable = () => (
        <TableContainer component={Paper} sx={{ maxHeight: 800, overflowY: 'auto' }}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell align="left">
                            <Typography>Filiale</Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography>Wachstum</Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography>Retoure A</Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography>Verfügbarkeit</Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography>Retoure B/C</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {wareData.map((item, index) => (
                        <TableRow
                            key={index}
                            onClick={() => handleRowClick(item)}
                            sx={{ cursor: 'pointer' }}
                        >
                            <TableCell align="left">
                                <Typography>{item.filiale}</Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Score score={item.wachstum} />
                            </TableCell>
                            <TableCell align="center">
                                <Score score={item.retoureA} />
                            </TableCell>
                            <TableCell align="center">
                                <Score score={item.verfügbarkeit} />
                            </TableCell>
                            <TableCell align="center">
                                <Score score={item.retoureBC} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

    return (
        <Layout>
            <Grid container item spacing ={2} alignItems={"center"}>
                <Grid item xs={8} >
                    <PageHeader
                        title="Monats Scorecards "
                        subtitle=" Monatliche Auswertung von Kennzahlen, um Fokus-Themen abzuleiten und in die Planung zu integrieren. Der Score gibt Aufschluss über die Einordnung im Vergleich zum Benchmark."
                        Icon={StackedBarChartOutlinedIcon}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={2}
                    >
                        <DropdownFilter
                            label="Datum"
                            options={datumOptions}
                            value={filterState.datum}
                            onChange={handleDatumChange}
                        />
                        <VerkaufsgebietDropdown
                            options={verkaufsgebietOptions}
                            value={verkaufsgebietFilter}
                            onChange={setVerkaufsgebietFilter}
                        />
                    </Stack>
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <TableContainer component={Paper} sx={{ maxHeight: 900, overflowY: 'auto' }}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">
                                        <Typography>Filiale</Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography>Performance</Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography>Personal</Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography>Ware</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((item, index) => (
                                    <TableRow
                                        key={index}
                                        onClick={() => handleRowClick(item)}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <TableCell align="left">
                                            <Typography>{item.filiale}</Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Score score={item.performanceScore} />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Score score={item.personalScore} />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Score score={item.wareScore} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Paper>
                        <Tabs
                            value={tabIndex}
                            onChange={handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                        >
                            <Tab label="Performance" />
                            <Tab label="Personal" />
                            <Tab label="Ware" />
                        </Tabs>
                        <Box p={2}>
                            {renderTabContent()}
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Layout>
    );
};

export default Overview;
