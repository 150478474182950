import React from 'react';
import {Autocomplete, TextField} from '@mui/material';

const DropdownFilter = ({
                          label,
                          options,
                          value,
                          onChange,
                          error,
                          helperText,
                          sx,
                          disabled = false,
                          small = false,
                          useNameAsLabel = false,
                          noExplicitLabel = false
                        }) => {
  // Transform simple array to object array if noExplicitLabel is true
  const processedOptions = noExplicitLabel 
    ? options.map(option => typeof option === 'string' ? { label: option } : option)
    : options;

  return (
      <Autocomplete
          disablePortal
          id={`${label.toLowerCase()}-combo-box`}
          options={processedOptions}
          getOptionLabel={(option) => {
            if (noExplicitLabel) return option.label || ''
            return useNameAsLabel ? option.name || '' : option.label || ''
          }}
          value={value}
          onChange={(event, newValue) => onChange(newValue)}
          isOptionEqualToValue={(option, value) => {
            if (noExplicitLabel) return option.label === value.label
            return option.name === value.name
          }}
          sx={{...sx, width: small ? 180 : 230, marginBottom: 2}}
          disabled={disabled}
          renderInput={(params) => (
              <TextField
                  {...params}
                  label={label}
                  variant="outlined"
                  size="small"
                  sx={{fontSize: '0.8rem'}}
                  error={error}
                  helperText={helperText}
                  disabled={disabled}
              />
          )}
      />
  );
};

export default DropdownFilter;
