import React, { useState, useEffect, useContext } from 'react';
import {
    Box, Typography, List, Select, MenuItem, ListItem, ListItemText, CircularProgress, Button, Alert, Divider, Paper, Dialog, DialogTitle, DialogContent, DialogActions, ButtonGroup, IconButton, TextField
} from '@mui/material';
import axiosInstance from '../../services/axiosInstance';
import { FilterContext } from '../../provider/FilterProvider';
import AddCommentIcon from '@mui/icons-material/AddComment';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import DeleteIcon from '@mui/icons-material/Delete';

const CategoryCheckpointsForm = ({ checkId, onSaveSuccess }) => {
    const { filterState, handleFilialeChange } = useContext(FilterContext);
    const [categories, setCategories] = useState([]);
    const [ratings, setRatings] = useState({});
    const [comments, setComments] = useState({});
    const [images, setImages] = useState({});
    const [imagePreviews, setImagePreviews] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [filialeOptions, setFilialeOptions] = useState([]);
    const [artikelOptions, setArtikelOptions] = useState([]);
    const [maxRating, setMaxRating] = useState(10);
    const [checkName, setCheckName] = useState('');
    const [selectionDialogOpen, setSelectionDialogOpen] = useState(true);
    const [commentDialogOpen, setCommentDialogOpen] = useState(false);
    const [currentCheckpoint, setCurrentCheckpoint] = useState(null);
    const [currentComment, setCurrentComment] = useState("");
    const [imageDialogOpen, setImageDialogOpen] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const [selectedArtikelnummer, setSelectedArtikelnummer] = useState(null);

    // Neues State für Sollgewicht
    const [sollgewicht, setSollgewicht] = useState(null);

    const formatDate = (date) => {
        const d = new Date(date);
        return `${String(d.getDate()).padStart(2, '0')}.${String(d.getMonth() + 1).padStart(2, '0')}.${d.getFullYear()}`;
    };

    const currentDate = formatDate(new Date());

    useEffect(() => {
        const fetchCategoriesAndCheckpoints = async () => {
            try {
                const checkResponse = await axiosInstance.get(`/qualitaet/checks/${checkId}`);
                setMaxRating(checkResponse.data.max);
                setCheckName(checkResponse.data.name);

                const categoryResponse = await axiosInstance.get('/qualitaet/categories/');
                const filteredCategories = categoryResponse.data.results.filter(category => category.check_related === checkId);

                const checkpointResponse = await axiosInstance.get('/qualitaet/checkpoints/');
                const categorizedCheckpoints = filteredCategories.map(category => ({
                    ...category,
                    checkpoints: checkpointResponse.data.results.filter(checkpoint => checkpoint.category === category.id)
                }));

                setCategories(categorizedCheckpoints);
            } catch (error) {
                setError('Fehler beim Laden der Daten.');
            } finally {
                setLoading(false);
            }
        };

        fetchCategoriesAndCheckpoints();
    }, [checkId]);

    useEffect(() => {
        const fetchFilialeOptions = async () => {
            try {
                const filialeResponse = await axiosInstance.get('stammdaten/unique-filiale/');
                const filialeOpts = filialeResponse.data.map(item => ({ label: item.filiale, value: item.filialnummer }));
                setFilialeOptions(filialeOpts);
            } catch (error) {
                setError('Fehler beim Laden der Filialen.');
            }
        };

        fetchFilialeOptions();
    }, []);

    useEffect(() => {
        const fetchArtikelOptions = async () => {
            try {
                const artikelResponse = await axiosInstance.get('stammdaten/unique-artikel/');
                const artikelOpts = artikelResponse.data.map(item => ({ label: item.artikel, value: item.artikelnummer }));
                setArtikelOptions(artikelOpts);
            } catch (error) {
                setError('Fehler beim Laden der Artikel.');
            }
        };

        fetchArtikelOptions();
    }, []);

    // Hole Sollgewicht, wenn ein Artikel ausgewählt wird
    useEffect(() => {
        const fetchArtikelDetails = async () => {
            if (selectedArtikelnummer) {
                try {
                    // Hier nutzen wir den Endpoint mit Query-Parameter artikelnummer
                    const response = await axiosInstance.get(`/stammdaten/stammdatenartikel/?artikelnummer=${selectedArtikelnummer}`);
                    
                    if (response.data.results && response.data.results.length > 0) {
                        const artikelData = response.data.results[0];
                        const parsedGewicht = parseFloat(artikelData.gewicht);
                        setSollgewicht(parsedGewicht);
                    } else {
                        setSollgewicht(null);
                    }
                } catch (error) {
                    console.error('Fehler beim Laden der Artikeldetails (Sollgewicht):', error);
                    setSollgewicht(null);
                }
            } else {
                setSollgewicht(null);
            }
        };
        fetchArtikelDetails();
    }, [selectedArtikelnummer]);

    const handleRatingChange = (checkpointId, value) => {
        setRatings(prevRatings => ({
            ...prevRatings,
            [checkpointId]: value,
        }));
    };

    const openCommentDialog = (checkpointId) => {
        setCurrentCheckpoint(checkpointId);
        setCurrentComment(comments[checkpointId] || "");
        setCommentDialogOpen(true);
    };

    const handleCommentSave = () => {
        setComments(prevComments => ({
            ...prevComments,
            [currentCheckpoint]: currentComment,
        }));
        setCommentDialogOpen(false);
    };

    const handleImageUpload = async (checkpointId) => {
        const fileInput = document.getElementById(`file-upload-${checkpointId}`);
        fileInput.click();

        fileInput.onchange = async (event) => {
            const file = event.target.files[0];

            if (!file) {
                console.error('Keine Datei ausgewählt');
                return;
            }

            const formData = new FormData();
            formData.append('image', file);
            formData.append('title', `Checkpoint ${checkpointId} Bild`);

            try {
                const response = await axiosInstance.post('/pictures/upload/', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                });
                console.log('Upload erfolgreich:', response.data);
                const image_id = response.data.image_id;

                setImages(prevImages => ({
                    ...prevImages,
                    [checkpointId]: image_id,
                }));

                const imageUrl = URL.createObjectURL(file);
                setImagePreviews(prevPreviews => ({
                    ...prevPreviews,
                    [checkpointId]: imageUrl,
                }));
            } catch (error) {
                console.error('Upload fehlgeschlagen:', error);
            }
        };
    };

    const handleImageDelete = (checkpointId) => {
        setImages(prevImages => {
            const newImages = { ...prevImages };
            delete newImages[checkpointId];
            return newImages;
        });

        setImagePreviews(prevPreviews => {
            const newPreviews = { ...prevPreviews };
            delete newPreviews[checkpointId];
            return newPreviews;
        });
    };

    const handleImageClick = async (checkpointId) => {
        const imageId = images[checkpointId];
        if (!imageId) {
            console.error('Keine Bild-ID verfügbar');
            return;
        }

        try {
            const response = await axiosInstance.get(`/pictures/get-image-url/${imageId}/`);
            setImageUrl(response.data.url);
            setImageDialogOpen(true);
        } catch (error) {
            console.error('Fehler beim Abrufen der Bild-URL:', error);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const ratingEntries = Object.entries(ratings);
        if (ratingEntries.length === 0) {
            setError('Bitte bewerten Sie mindestens einen Checkpoint.');
            return;
        }

        if (!filterState.filiale || !selectedArtikelnummer) {
            setError('Bitte wählen Sie eine Filiale und einen Artikel aus, bevor Sie fortfahren.');
            return;
        }

        try {
            const payload = ratingEntries.map(([checkpointId, value]) => {
                const checkpoint = categories
                    .flatMap(category => category.checkpoints)
                    .find(check => check.id === parseInt(checkpointId));

                let transformedValue = value;
                if (checkpoint.rating_type === 'yesNo') {
                    transformedValue = (value === "yes" ? 1 : 0);
                } else if (checkpoint.rating_type === 'gewicht') {
                    transformedValue = parseFloat(value);
                    if (isNaN(transformedValue)) {
                        transformedValue = 0;
                    }
                }

                return {
                    value: transformedValue,
                    datum: currentDate.split('.').reverse().join('-'),
                    user: "databites",
                    filialnummer: parseInt(filterState.filiale),
                    artikelnummer: parseInt(selectedArtikelnummer),
                    checkpoint: checkpointId,
                    check_related: checkId,
                    comment: comments[checkpointId] || "",
                    image_id: images[checkpointId] || null,
                };
            });

            await axiosInstance.post('/qualitaet/ratings/', payload);

            setRatings({});
            setComments({});
            setImages({});
            setImagePreviews({});
            setSubmitSuccess(true);
            setError(null);
            onSaveSuccess();
        } catch (error) {
            setError('Fehler beim Speichern der Bewertungen.');
        }
    };

    const handleSelectionDialogClose = () => {
        if (filterState.filiale && selectedArtikelnummer) {
            setSelectionDialogOpen(false);
        } else {
            setError('Bitte wählen Sie eine Filiale und einen Artikel aus, bevor Sie fortfahren.');
        }
    };

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <>
            {/* Dialog zur Filial- und Artikelauswahl */}
            <Dialog open={selectionDialogOpen} onClose={handleSelectionDialogClose}>
                <DialogTitle>Filiale und Artikel auswählen</DialogTitle>
                <DialogContent>
                    {error && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            {error}
                        </Alert>
                    )}
                    <Select
                        value={filterState.filiale || ""}
                        onChange={(e) => handleFilialeChange(e.target.value)}
                        displayEmpty
                        fullWidth
                        inputProps={{ 'aria-label': 'Filiale auswählen' }}
                        sx={{ mb: 2 }}
                    >
                        <MenuItem value="" disabled>
                            Filiale auswählen
                        </MenuItem>
                        {filialeOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>

                    <Select
                        value={selectedArtikelnummer || ""}
                        onChange={(e) => setSelectedArtikelnummer(e.target.value)}
                        displayEmpty
                        fullWidth
                        inputProps={{ 'aria-label': 'Artikel auswählen' }}
                    >
                        <MenuItem value="" disabled>
                            Artikel auswählen
                        </MenuItem>
                        {artikelOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSelectionDialogClose} color="primary" variant="contained">
                        Bestätigen
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Dialog für Kommentare */}
            <Dialog open={commentDialogOpen} onClose={() => setCommentDialogOpen(false)}>
                <DialogTitle>Kommentar hinzufügen</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Kommentar"
                        fullWidth
                        multiline
                        rows={4}
                        value={currentComment}
                        onChange={(e) => setCurrentComment(e.target.value)}
                        variant="outlined"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setCommentDialogOpen(false)} color="secondary">
                        Abbrechen
                    </Button>
                    <Button onClick={handleCommentSave} color="primary" variant="contained">
                        Speichern
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Dialog zur Bildanzeige */}
            <Dialog open={imageDialogOpen} onClose={() => setImageDialogOpen(false)} maxWidth="md" fullWidth>
                <DialogContent>
                    <img src={imageUrl} alt="Checkpoint Bild" style={{ width: '100%' }} />
                </DialogContent>
            </Dialog>

            {/* Hauptformular */}
            <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: { xs: '100%', sm: 800 }, mx: 'auto', mt: 4, p: { xs: 2, sm: 4 } }}>
                <Typography variant="h5" sx={{ fontSize: { xs: '1.5rem', sm: '2rem' } }} >
                    {checkName}
                </Typography>

                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', mb: 4 }}>
                    <Typography variant="body1">
                        <strong>Filiale:</strong> {filterState.filiale && filialeOptions.find(option => option.value === filterState.filiale)?.label}
                    </Typography>
                    <Typography variant="body1">
                        <strong>Artikel:</strong> {selectedArtikelnummer && artikelOptions.find(option => option.value === selectedArtikelnummer)?.label}
                    </Typography>
                    <Typography variant="body1">
                        <strong>Datum:</strong> {currentDate}
                    </Typography>
                </Box>

                <Typography variant="h6" gutterBottom sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }}>
                    Checkpoints bewerten
                </Typography>

                {submitSuccess && (
                    <Alert severity="success" sx={{ mb: 2 }}>
                        Bewertungen erfolgreich gespeichert!
                    </Alert>
                )}

                {error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                    </Alert>
                )}

                {categories.length > 0 ? (
                    <List>
                        {categories.map((category) => (
                            <Paper key={category.id} sx={{ mb: 4, p: 2, border: '1px solid #ccc', borderRadius: 2 }}>
                                <Typography variant="h5" gutterBottom sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }}>
                                    {category.name}
                                </Typography>
                                <List>
                                    {category.checkpoints.map((checkpoint, index) => {
                                        const currentValue = parseFloat(ratings[checkpoint.id]) || 0;
                                        let warnung = false;
                                        let differenz = null;
                                        let differenz_in_gramm = null;
                                        let prozentAbweichung = null;

                                        // Nur bei gewicht soll das Sollgewicht angezeigt und überprüft werden
                                        if (checkpoint.rating_type === 'gewicht' && sollgewicht !== null && !isNaN(currentValue)) {
                                            differenz = Math.abs(currentValue - sollgewicht); // Differenz in kg
                                            differenz_in_gramm = differenz * 1000; // Umrechnung in Gramm
                                            prozentAbweichung = (differenz / sollgewicht) * 100;

                                            if (prozentAbweichung > 10) {
                                                warnung = true;
                                            }
                                        }

                                        return (
                                            <React.Fragment key={checkpoint.id}>
                                                <ListItem sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                        <ListItemText primary={checkpoint.name} sx={{ flexGrow: 1 }} />
                                                        <IconButton
                                                            onClick={() => openCommentDialog(checkpoint.id)}
                                                            color="primary"
                                                        >
                                                            <AddCommentIcon />
                                                        </IconButton>
                                                        {checkpoint.allow_image_upload && (
                                                            <>
                                                                <input
                                                                    accept="image/*"
                                                                    id={`file-upload-${checkpoint.id}`}
                                                                    type="file"
                                                                    style={{ display: 'none' }}
                                                                />
                                                                <IconButton
                                                                    onClick={() => handleImageUpload(checkpoint.id)}
                                                                    color="secondary"
                                                                >
                                                                    <PhotoCamera />
                                                                </IconButton>
                                                                {images[checkpoint.id] && (
                                                                    <>
                                                                        <IconButton
                                                                            color="primary"
                                                                            onClick={() => handleImageClick(checkpoint.id)}
                                                                        >
                                                                            <PhotoLibraryIcon />
                                                                        </IconButton>
                                                                        <IconButton
                                                                            onClick={() => handleImageDelete(checkpoint.id)}
                                                                            color="error"
                                                                        >
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                    </Box>

                                                    {checkpoint.description && (
                                                        <Typography variant="body2" sx={{ mt: 1 }}>
                                                            <strong>Beschreibung:</strong> {checkpoint.description}
                                                        </Typography>
                                                    )}

                                                    {checkpoint.rating_type === 'gewicht' && sollgewicht !== null && (
                                                        <Typography variant="body2" sx={{ mt: 1 }}>
                                                            Sollgewicht: {sollgewicht.toFixed(3)} kg
                                                        </Typography>
                                                    )}

                                                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'center', mt: 1, width: '100%' }}>
                                                        {checkpoint.rating_type === 'yesNo' ? (
                                                            <ButtonGroup variant="outlined" color="primary" sx={{ mt: { xs: 1, sm: 0 }, mr: { sm: 2 } }}>
                                                                <Button
                                                                    variant={ratings[checkpoint.id] === "yes" ? "contained" : "outlined"}
                                                                    onClick={() => handleRatingChange(checkpoint.id, "yes")}
                                                                >
                                                                    Ja
                                                                </Button>
                                                                <Button
                                                                    variant={ratings[checkpoint.id] === "no" ? "contained" : "outlined"}
                                                                    onClick={() => handleRatingChange(checkpoint.id, "no")}
                                                                >
                                                                    Nein
                                                                </Button>
                                                            </ButtonGroup>
                                                        ) : checkpoint.rating_type === 'gewicht' ? (
                                                            <>
                                                                <TextField
                                                                    type="number"
                                                                    label="Gewicht eingeben"
                                                                    variant="outlined"
                                                                    sx={{ mt: { xs: 1, sm: 0 }, mr: { sm: 2 }, width: '150px' }}
                                                                    value={ratings[checkpoint.id] || ''}
                                                                    onChange={(e) => handleRatingChange(checkpoint.id, e.target.value)}
                                                                    inputProps={{ step: "0.001" }} // erlaubt Kommazahlen bis auf 3 Nachkommastellen
                                                                />
                                                                {differenz_in_gramm !== null && !isNaN(differenz_in_gramm) && (
                                                                    <Typography variant="body2" sx={{ mt: { xs: 1, sm: 0 } }}>
                                                                        Abweichung: {differenz_in_gramm.toFixed(1)} g
                                                                    </Typography>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <ButtonGroup variant="outlined" color="primary" sx={{ mt: { xs: 1, sm: 0 }, mr: { sm: 2 }, flexWrap: 'wrap' }}>
                                                                {[...Array(maxRating).keys()].map((num) => (
                                                                    <Button
                                                                        key={num + 1}
                                                                        variant={ratings[checkpoint.id] === num + 1 ? "contained" : "outlined"}
                                                                        onClick={() => handleRatingChange(checkpoint.id, num + 1)}
                                                                        sx={{ minWidth: { xs: 30, sm: 40 }, mb: { xs: 1, sm: 0 } }}
                                                                    >
                                                                        {num + 1}
                                                                    </Button>
                                                                ))}
                                                            </ButtonGroup>
                                                        )}
                                                    </Box>

                                                    {warnung && (
                                                        <Alert severity="warning" sx={{ mt: 1 }}>
                                                            Achtung: Das eingegebene Gewicht weicht um mehr als 10% vom Sollgewicht ab!
                                                        </Alert>
                                                    )}

                                                    {comments[checkpoint.id] && (
                                                        <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                                                            <strong>Kommentar:</strong> {comments[checkpoint.id]}
                                                        </Typography>
                                                    )}
                                                    {imagePreviews[checkpoint.id] && (
                                                        <Box sx={{ mt: 1 }}>
                                                            <img
                                                                src={imagePreviews[checkpoint.id]}
                                                                alt={`Checkpoint ${checkpoint.id}`}
                                                                style={{ maxWidth: '100%', maxHeight: '200px' }}
                                                            />
                                                        </Box>
                                                    )}
                                                    {index < category.checkpoints.length - 1 && <Divider sx={{ mt: 2 }} />}
                                                </ListItem>
                                            </React.Fragment>
                                        )
                                    })}
                                </List>
                            </Paper>
                        ))}
                    </List>
                ) : (
                    <Typography variant="body1">
                        Keine Kategorien und Checkpoints gefunden.
                    </Typography>
                )}

                <Button type="submit" variant="contained" color="primary" sx={{ mt: 2, width: '100%' }}>
                    Speichern
                </Button>
            </Box>
        </>
    );
};

export default CategoryCheckpointsForm;
