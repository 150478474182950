import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  CircularProgress,
  MenuItem,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import axiosInstance from '../../services/axiosInstance';

const Dienstleister = () => {
  const [data, setData] = useState([]); // Dienstleister-Daten
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); // Fehlernachricht
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [newItemDialogOpen, setNewItemDialogOpen] = useState(false);
  const [newItem, setNewItem] = useState({
    name: '',
    kontaktperson: '',
    email: '',
    telefon: '',
    dienstleistungen: '',
    region: '',
    notizen: '',
  });

  // API-Daten abrufen
  const fetchData = async () => {
    try {
      const response = await axiosInstance.get('technik/dienstleister/');
      setData(response.data.results || response.data || []);
      setLoading(false);
    } catch (error) {
      console.error('Fehler beim Abrufen der Dienstleister-Daten:', error);
      setData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Öffnet den Dialog mit den Details
  const handleRowClick = (item) => {
    setSelectedItem({ ...item });
    setOpenDialog(true);
  };

  // Schließt den Dialog
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedItem(null);
  };

  // Speichern von Änderungen im Dialog
  const handleSaveChanges = async () => {
    try {
      await axiosInstance.put(`technik/dienstleister/${selectedItem.id}/`, selectedItem);
      fetchData(); // Aktualisiert die Tabelle
      setOpenDialog(false);
      setSelectedItem(null);
    } catch (error) {
      console.error('Fehler beim Aktualisieren des Dienstleisters:', error.response?.data || error.message);
    }
  };

  // Öffnet den Dialog für neuen Dienstleister
  const handleNewItemDialogOpen = () => {
    setNewItemDialogOpen(true);
  };

  // Schließt den Dialog für neuen Dienstleister
  const handleNewItemDialogClose = () => {
    setNewItemDialogOpen(false);
  };

  // Fügt neuen Dienstleister hinzu
  const handleAddNewItem = async () => {
    try {
      const response = await axiosInstance.post('technik/dienstleister/', newItem);
      setData([...data, response.data]); // Neuen Eintrag hinzufügen
      setNewItem({
        name: '',
        kontaktperson: '',
        email: '',
        telefon: '',
        dienstleistungen: '',
        region: '',
        notizen: '',
      });
      setNewItemDialogOpen(false);
    } catch (error) {
      console.error('Fehler beim Hinzufügen des Dienstleisters:', error.response?.data || error.message);
    }
  };

  return (
    <Box>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        sx={{ mb: 2 }}
        onClick={handleNewItemDialogOpen}
      >
        Neuen Dienstleister hinzufügen
      </Button>

      {/* Tabelle */}
      {loading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Kontaktperson</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Telefon</TableCell>
                <TableCell>Region</TableCell>
                <TableCell align="center">Details</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(data) && data.length > 0 ? (
                data.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.kontaktperson}</TableCell>
                    <TableCell>{item.email}</TableCell>
                    <TableCell>{item.telefon}</TableCell>
                    <TableCell>{item.region}</TableCell>
                    <TableCell align="center">
                      <IconButton
                        color="primary"
                        onClick={() => handleRowClick(item)}
                      >
                        <InfoOutlinedIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    Keine Daten verfügbar.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Neuer Dienstleister-Dialog */}
      <Dialog open={newItemDialogOpen} onClose={handleNewItemDialogClose}>
        <DialogTitle>Neuen Dienstleister hinzufügen</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {Object.keys(newItem).map((key) => (
              <Grid item xs={12} sm={6} key={key}>
                <TextField
                  fullWidth
                  label={key.replace('_', ' ').toUpperCase()}
                  value={newItem[key]}
                  onChange={(e) => setNewItem({ ...newItem, [key]: e.target.value })}
                  margin="dense"
                />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNewItemDialogClose} color="secondary">
            Abbrechen
          </Button>
          <Button onClick={handleAddNewItem} color="primary">
            Hinzufügen
          </Button>
        </DialogActions>
      </Dialog>

      {/* Details-Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Dienstleister bearbeiten</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {selectedItem &&
              Object.keys(selectedItem).map((key) => (
                <Grid item xs={12} sm={6} key={key}>
                  <TextField
                    fullWidth
                    label={key.replace('_', ' ').toUpperCase()}
                    value={selectedItem[key]}
                    onChange={(e) =>
                      setSelectedItem({
                        ...selectedItem,
                        [key]: e.target.value,
                      })
                    }
                    margin="dense"
                  />
                </Grid>
              ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Abbrechen
          </Button>
          <Button onClick={handleSaveChanges} color="primary">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Dienstleister;
