// pages/verkaufsmenge/Verkaufsmenge.js

import React, { useContext, useEffect, useState, useMemo } from 'react';
import {
  Box,
  Grid,
  Typography,
  Paper,
  CardHeader,
  CardContent,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Switch,
  FormControlLabel,
} from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SearchIcon from '@mui/icons-material/Search';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CloseIcon from '@mui/icons-material/Close';
import Layout from '../../../components/layout/Scorecards/Unternehmen/UnternehmenMonat';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import DataCard from '../../../components/card/scorecardsmonat/WarengruppenCard';
import { FilterContext } from '../../../provider/FilterProvider';
import axiosInstance from '../../../services/axiosInstance';
import dayjs from 'dayjs';
import DropdownFilter from '../../../components/filter/DropdownFilter';
import BigTableCard from '../../../components/card/BigTableCard';
import BarChartCardFlexibleYAxis from '../../../components/card/scorecardsmonat/BarChartCardFlexibleYAxis';
import LineChartVariableYAxis from '../../../components/charts/LineChartVariableYAxis';

export default function Verkaufsmenge() {
  const { filterState, handleDatumChange } = useContext(FilterContext);
  const [datumOptions, setDatumOptions] = useState([]);
  const [warengruppenData, setWarengruppenData] = useState([]);
  const [articleCounts, setArticleCounts] = useState({});
  const [soldArticleCounts, setSoldArticleCounts] = useState({});
  const [yAxisMax, setYAxisMax] = useState(1000000);
  const [yAxisSliderMax, setYAxisSliderMax] = useState(1000000);
  const [combinedLineData, setCombinedLineData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [openTableModal, setOpenTableModal] = useState(false);
  const [openFullscreenChart, setOpenFullscreenChart] = useState(false);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [displayMode, setDisplayMode] = useState('Verkaufsmenge'); // Initialmodus

  // Handler für den Switch
  const handleDisplayModeChange = (event) => {
    setDisplayMode(event.target.checked ? 'Umsatz' : 'Verkaufsmenge');
  };

  // Fenstergröße überwachen für Vollbildmodus
  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Datum-Optionen abrufen
  useEffect(() => {
    fetchDatumOptions();
  }, []);

  // Daten abrufen, wenn sich das Datum oder der Anzeige-Modus ändert
  useEffect(() => {
    if (filterState.datum) {
      fetchMonthlyWarengruppenData();
    }
  }, [filterState, displayMode]);

  // Funktion zum Abrufen der Datum-Optionen
  const fetchDatumOptions = async () => {
    try {
      const response = await axiosInstance.get('/stammdaten/unique-jahr-monat/');
      setDatumOptions(
        response.data.map((item) => ({ label: item, value: item }))
      );
    } catch (error) {
      console.error('Fehler beim Abrufen der Datumsoptionen:', error);
    }
  };

  // Funktion zur Ermittlung der letzten 6 Monate
  const getLast6Months = (currentMonth) => {
    const last6Months = [];
    const formattedMonth = dayjs(currentMonth, 'YYYY.MM');

    for (let i = 0; i < 6; i++) {
      last6Months.push(formattedMonth.subtract(i, 'month').format('YYYY.MM'));
    }

    return last6Months.reverse();
  };

  // Funktion zum Abrufen der monatlichen Warengruppendaten
  const fetchMonthlyWarengruppenData = async () => {
    const last6MonthsArray = getLast6Months(filterState.datum?.value);
    const fieldName = displayMode === 'Verkaufsmenge' ? 'verkaufsmenge' : 'umsatz';
    const fieldNameVJ = displayMode === 'Verkaufsmenge' ? 'verkaufsmengeVJ' : 'umsatzVJ';

    try {
      const promises = last6MonthsArray.map((month) =>
        axiosInstance
          .get('/scorecards_month/ware-warengruppe-monat/', {
            params: {
              jahrMonat: month,
            },
          })
          .then((response) => ({ month, data: response.data.results }))
      );

      const results = await Promise.all(promises);

      const aggregatedData = {};

      results.forEach(({ month, data }) => {
        data.forEach((item) => {
          const warengruppennummer = item.warengruppennummer;
          const warengruppe = item.warengruppe;
          const value = item[fieldName] || 0;
          const previousValue = item[fieldNameVJ] || 0;

          if (!aggregatedData[warengruppennummer]) {
            aggregatedData[warengruppennummer] = {
              warengruppe,
              data: {},
            };
          }

          if (!aggregatedData[warengruppennummer].data[month]) {
            aggregatedData[warengruppennummer].data[month] = {
              value: 0,
              previousValue: 0,
            };
          }

          aggregatedData[warengruppennummer].data[month].value += value;
          aggregatedData[warengruppennummer].data[month].previousValue += previousValue;
        });
      });

      const formattedData = Object.values(aggregatedData).map((warengruppe) => ({
        warengruppe: warengruppe.warengruppe,
        data: last6MonthsArray.map((month) => ({
          name: month,
          value: warengruppe.data[month]?.value || 0,
          previousValue: warengruppe.data[month]?.previousValue || 0,
        })),
        value:
          warengruppe.data[filterState.datum?.value]?.value || 0,
        previousValue:
          warengruppe.data[filterState.datum?.value]?.previousValue || 0,
      }));

      const maxDataValue = Math.max(
        ...formattedData.flatMap((wg) => wg.data.map((d) => d.value))
      );
      const bufferedMax = Math.ceil(maxDataValue * 1.1);
      setYAxisSliderMax(bufferedMax);
      setYAxisMax(bufferedMax);

      setWarengruppenData(formattedData);

      const combinedData = last6MonthsArray.map((month) => {
        const entry = { name: month };
        formattedData.forEach((warengruppe) => {
          entry[warengruppe.warengruppe] =
            warengruppe.data.find((d) => d.name === month)?.value || 0;
        });
        return entry;
      });

      setCombinedLineData(combinedData);

      const newTableColumns = [
        { accessorKey: 'warengruppe', header: 'Warengruppe' },
        ...last6MonthsArray.map((month) => ({
          accessorKey: month,
          header: month,
        })),
      ];
      setTableColumns(newTableColumns);

      const newTableData = formattedData.map((warengruppe) => {
        const row = { warengruppe: warengruppe.warengruppe };
        warengruppe.data.forEach((point) => {
          row[point.name] = point.value;
        });
        return row;
      });
      setTableData(newTableData);

      fetchArticleCounts(formattedData);
      fetchSoldArticleCounts(formattedData);
    } catch (error) {
      console.error('Fehler beim Abrufen der monatlichen Warengruppen-Daten:', error);
    }
  };

  // Funktion zum Abrufen der Artikelanzahl
  const fetchArticleCounts = async (warengruppenData) => {
    try {
      const counts = await Promise.all(
        warengruppenData.map(async (warengruppe) => {
          const response = await axiosInstance.get(
            '/scorecard_month_article/abc-unternehmen/',
            {
              params: {
                ABC: 'A',
                warengruppe: warengruppe.warengruppe,
                jahrMonat: filterState.datum?.value,
              },
            }
          );
          return {
            warengruppe: warengruppe.warengruppe,
            count: response.data.results.length,
          };
        })
      );

      const articleCountMap = counts.reduce((acc, { warengruppe, count }) => {
        acc[warengruppe] = count;
        return acc;
      }, {});

      setArticleCounts(articleCountMap);
    } catch (error) {
      console.error('Fehler beim Abrufen der Artikelanzahl:', error);
    }
  };

  // Funktion zum Abrufen der verkauften Artikelanzahl
  const fetchSoldArticleCounts = async (warengruppeData) => {
    try {
      const counts = await Promise.all(
        warengruppeData.map(async (warengruppe) => {
          const response = await axiosInstance.get(
            '/scorecard_month_article/ware-artikel-monat/',
            {
              params: {
                warengruppe: warengruppe.warengruppe,
                jahrMonat: filterState.datum?.value,
              },
            }
          );

          const uniqueArticleNumbers = new Set(
            response.data.results
              .filter((item) => item.verkaufsmenge > 0)
              .map((item) => item.artikelnummer.artikelnummer)
          );

          return {
            warengruppe: warengruppe.warengruppe,
            count: uniqueArticleNumbers.size,
          };
        })
      );

      const soldArticleCountMap = counts.reduce((acc, { warengruppe, count }) => {
        acc[warengruppe] = count;
        return acc;
      }, {});

      setSoldArticleCounts(soldArticleCountMap);
    } catch (error) {
      console.error('Fehler beim Abrufen der verkauften Artikelanzahl:', error);
    }
  };

  // Handler für Tabellenzeilen-Klick
  const handleRowClick = (row) => {
    console.log('Zeile geklickt:', row);
  };

  // Top 7 Warengruppen basierend auf aktuellen Werten
  const top7WarengruppenData = useMemo(() => {
    return [...warengruppenData]
      .sort((a, b) => b.value - a.value)
      .slice(0, 7);
  }, [warengruppenData]);

  const top7DataKeys = useMemo(() => {
    return top7WarengruppenData.map((wg) => wg.warengruppe);
  }, [top7WarengruppenData]);

  const top7CombinedData = useMemo(() => {
    return getLast6Months(filterState.datum?.value).map((month) => {
      const entry = { name: month };
      top7WarengruppenData.forEach((wg) => {
        const dataPoint = wg.data.find((d) => d.name === month);
        entry[wg.warengruppe] = dataPoint ? dataPoint.value : 0;
      });
      return entry;
    });
  }, [top7WarengruppenData, filterState.datum?.value]);

  const barChartData = useMemo(() => {
    return top7WarengruppenData.map((item) => ({
      warengruppe: item.warengruppe,
      value: item.value,
      previousValue: item.previousValue,
    }));
  }, [top7WarengruppenData]);

  return (
    <Layout>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          mt: 2,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <PageHeader
            title={displayMode === 'Verkaufsmenge' ? 'Verkaufsmengen' : 'Umsätze'}
            subtitle={
              displayMode === 'Verkaufsmenge'
                ? 'Übersicht über die Verkaufsmengen im Sortiment'
                : 'Übersicht über die Umsätze im Sortiment'
            }
            Icon={BarChartIcon}
          />
        </Box>

        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          {/* Switch links neben dem Datumsfilter */}
          <FormControlLabel
            control={
              <Switch
                checked={displayMode === 'Umsatz'}
                onChange={handleDisplayModeChange}
                name="displayModeSwitch"
                color="primary"
                inputProps={{ 'aria-label': 'Display Mode Switch' }}
              />
            }
            label={displayMode === 'Verkaufsmenge' ? 'Verkaufsmenge' : 'Umsatz'}
            labelPlacement="start" // Label links vom Switch platzieren
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              '& .MuiFormControlLabel-label': {
                marginRight: 1, // Abstand zwischen Label und Switch
              },
            }}
          />
          <DropdownFilter
            label="Datum"
            options={datumOptions}
            value={filterState.datum}
            onChange={handleDatumChange}
          />
        </Box>
      </Box>

      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box sx={{ height: '100%' }}>
              <BarChartCardFlexibleYAxis
                data={barChartData}
                dataKeys={['value', 'previousValue']}
                xAxisKey="warengruppe"
                title={
                  displayMode === 'Verkaufsmenge'
                    ? 'Verkaufsmengen der Warengruppen'
                    : 'Umsätze der Warengruppen'
                }
                infoContent={
                  displayMode === 'Verkaufsmenge'
                    ? 'Top 7 Warengruppen nach Verkaufsmenge'
                    : 'Top 7 Warengruppen nach Umsatz'
                }
                height={300}
                legendNames={[
                  displayMode === 'Verkaufsmenge' ? 'Verkaufsm. Aktuell' : 'Umsatz Aktuell',
                  displayMode === 'Verkaufsmenge' ? 'Verkaufsm. VJ' : 'Umsatz VJ',
                ]}
                yAxisMin={0}
                yAxisMax={yAxisSliderMax}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ height: '100%' }}>
              <Paper
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  padding: '0px',
                  backgroundColor: 'rgba(255, 255, 255, 0.7)',
                  backdropFilter: 'blur(10px)',
                }}
              >
                <CardHeader
                  title={
                    <Typography variant="h6">
                      {displayMode === 'Verkaufsmenge'
                        ? 'Verkaufsmengen der letzten 6 Monate'
                        : 'Umsätze der letzten 6 Monate'}
                    </Typography>
                  }
                  action={
                    <Box>
                      <Tooltip
                        title={
                          displayMode === 'Verkaufsmenge'
                            ? 'Entwicklung der Verkaufsmengen pro Warengruppe über die letzten 6 Monate'
                            : 'Entwicklung der Umsätze pro Warengruppe über die letzten 6 Monate'
                        }
                        arrow
                        placement="top"
                      >
                        <IconButton>
                          <InfoOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        title="Details in Tabellenform anzeigen"
                        arrow
                        placement="top"
                      >
                        <IconButton onClick={() => setOpenTableModal(true)}>
                          <SearchIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        title="Diagramm im Vollbildmodus anzeigen"
                        arrow
                        placement="top"
                      >
                        <IconButton onClick={() => setOpenFullscreenChart(true)}>
                          <FullscreenIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  }
                />
                <CardContent
                  sx={{
                    flexGrow: 1,
                    height: '100%',
                    width: '100%',
                  }}
                >
                  {combinedLineData && combinedLineData.length > 0 ? (
                    <LineChartVariableYAxis
                      data={combinedLineData}
                      dataKeys={top7DataKeys}
                      xAxisKey="name"
                      yAxisMax={yAxisMax}
                      yAxisMin={0}
                      height={300}
                      width="100%"
                      lineColors={[
                        '#8884d8',
                        '#82ca9d',
                        '#ffc658',
                        '#ff7300',
                        '#d0ed57',
                        '#a4de6c',
                      ]}
                      legendNames={top7WarengruppenData.map((wg) => wg.warengruppe)}
                    />
                  ) : (
                    <Typography variant="body2" color="text.secondary">
                      Keine Daten verfügbar
                    </Typography>
                  )}
                </CardContent>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Vollbild-Diagramm Dialog */}
      <Dialog
        open={openFullscreenChart}
        onClose={() => setOpenFullscreenChart(false)}
        fullScreen
        aria-labelledby="fullscreen-chart-title"
      >
        <DialogTitle id="fullscreen-chart-title">
          {displayMode === 'Verkaufsmenge'
            ? 'Verkaufsmengen der letzten 6 Monate'
            : 'Umsätze der letzten 6 Monate'}
          <IconButton
            aria-label="schließen"
            onClick={() => setOpenFullscreenChart(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {combinedLineData && combinedLineData.length > 0 ? (
            <LineChartVariableYAxis
              data={combinedLineData}
              dataKeys={top7DataKeys}
              xAxisKey="name"
              yAxisMax={yAxisMax}
              yAxisMin={0}
              height={windowHeight * 0.8}
              width="100%"
              lineColors={[
                '#8884d8',
                '#82ca9d',
                '#ffc658',
                '#ff7300',
                '#d0ed57',
                '#a4de6c',
              ]}
              legendNames={top7WarengruppenData.map((wg) => wg.warengruppe)}
            />
          ) : (
            <Typography variant="body2" color="text.secondary">
              Keine Daten verfügbar
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenFullscreenChart(false)} color="primary">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>

      {/* Tabellen-Dialog */}
      <Dialog
        open={openTableModal}
        onClose={() => setOpenTableModal(false)}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>
          {displayMode === 'Verkaufsmenge'
            ? 'Verkaufsdaten in Tabellenform'
            : 'Umsatzdaten in Tabellenform'}
        </DialogTitle>
        <DialogContent>
          <BigTableCard
            title={displayMode === 'Verkaufsmenge' ? 'Verkaufsdaten' : 'Umsatzdaten'}
            data={tableData}
            columns={tableColumns}
            onRowClick={handleRowClick}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenTableModal(false)} color="primary">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>

      {/* Datenkarten für jede Warengruppe */}
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {warengruppenData.map((warengruppe) => (
          <Grid item xs={12} sm={6} md={4} key={warengruppe.warengruppe}>
            <DataCard
              chartData={warengruppe.data}
              value={warengruppe.warengruppe}
              selectedMonth={filterState.datum?.value}
              articleCount={articleCounts[warengruppe.warengruppe] || 0}
              soldArticleCount={soldArticleCounts[warengruppe.warengruppe] || 0}
              displayMode={displayMode}
            />
          </Grid>
        ))}
      </Grid>
    </Layout>
  );
}
