import React, {useContext, useEffect, useState} from 'react';
import {
    Box, Button, Grid, Typography, TextField, Stack, InputAdornment, IconButton, List, ListItem, ListItemText, CircularProgress
} from '@mui/material';
import Layout from '../../../components/layout/Wiki/Wiki';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import axiosInstance from '../../../services/axiosInstance';
import {InfoBarContext} from "../../../provider/InfoBar";
import {useLocation, useNavigate} from "react-router-dom";
import ManualCard from '../../../components/wiki/settings/ManualCard';
import CreateManualDialog from '../../../components/wiki/settings/CreateManualDialog';
import SearchIcon from '@mui/icons-material/Search';
import {useAuth} from "../../../provider/authProvider"; // Annahme: useAuth liefert den angemeldeten User

const SettingsIndex = () => {
    const [manuals, setManuals] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [newManual, setNewManual] = useState({title: '', description: ''});
    const [creating, setCreating] = useState(false);
    const {showError} = useContext(InfoBarContext);
    const navigate = useNavigate();
    const location = useLocation();
    const isSettings = location.pathname.includes('/wiki/settings/');

    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState(null);
    const [searchLoading, setSearchLoading] = useState(false);

    const {user} = useAuth(); // Angenommen: user-Objekt ist hier verfügbar
    // user.custom_groups ist ein Array von Objekten mit 'id', z.B. [{id: 'uuid1', name:'Gruppe1'}, ...]
    const userGroupIds = user?.custom_groups?.map(g => g.id) || [];

    useEffect(() => {
        fetchManuals();
    }, []);

    const fetchManuals = async () => {
        try {
            const response = await axiosInstance.get('/wiki/manuals/');
            let fetchedManuals = [];
            if (response.data.results && Array.isArray(response.data.results)) {
                fetchedManuals = response.data.results;
            } else if (Array.isArray(response.data)) {
                fetchedManuals = response.data;
            } else {
                console.error('Unerwartetes Format:', response.data);
                showError('Fehler beim Laden der Manuals.');
            }

            // Filter nach Berechtigungen
            const visibleManuals = fetchedManuals.filter(manual => {
                // Manual ist sichtbar, wenn keine gruppen gesetzt sind
                // oder wenn mind. eine Gruppe mit den User-Gruppen übereinstimmt
                if (!manual.group_ids || manual.group_ids.length === 0) {
                    return true;
                }
                // Prüfe Schnittmenge
                return manual.group_ids.some(gid => userGroupIds.includes(gid));
            });

            setManuals(visibleManuals);
        } catch (err) {
            console.error('Fehler beim Laden der Manuals:', err);
            showError('Fehler beim Laden der Manuals. Bitte später erneut versuchen.');
        } finally {
            setLoading(false);
        }
    };

    const handleOpenCreateDialog = () => {
        setOpenCreateDialog(true);
    };

    const handleCloseCreateDialog = () => {
        setOpenCreateDialog(false);
        setNewManual({title: '', description: ''});
    };

    const handleCreateManual = async () => {
        if (!newManual.title) {
            showError('Bitte einen Titel eingeben.');
            return;
        }
        setCreating(true);
        try {
            const response = await axiosInstance.post('/wiki/manuals/', newManual);
            const createdManual = response.data;

            // Prüfen, ob der User Zugriff auf das neu erstellte Manual hat
            if ((!createdManual.group_ids || createdManual.group_ids.length === 0) ||
                createdManual.group_ids.some(gid => userGroupIds.includes(gid))) {
                setManuals([...manuals, createdManual]);
            }
            handleCloseCreateDialog();
        } catch (err) {
            console.error('Fehler beim Erstellen des Manuals:', err);
            showError('Fehler beim Erstellen des Manuals.');
        } finally {
            setCreating(false);
        }
    };

    const handleSelectManual = (manual) => {
        navigate(`/wiki/overview/${manual.slug}`);
    };

    const handleSearch = async () => {
        if (!searchQuery || searchQuery.length < 3) {
            setError('Bitte mind. 3 Zeichen eingeben.');
            return;
        }
        setSearchLoading(true);
        try {
            const response = await axiosInstance.get(`/wiki/search/?q=${encodeURIComponent(searchQuery)}`);
            setSearchResults(response.data);
            setError(null);
        } catch (err) {
            console.error('Search error:', err);
            setError('Fehler bei der Suche.');
        } finally {
            setSearchLoading(false);
        }
    };

    const handleSearchResultClick = (type, item) => {
        setSearchResults(null);
        const base = isSettings ? '/wiki/settings' : '/wiki/overview';

        if (type === 'manual') {
            navigate(`${base}/${item.slug}`);
        } else if (type === 'chapter') {
            navigate(`${base}/${item.manual}/${item.slug}`);
        } else if (type === 'entry') {
            navigate(`${base}/${item.manual.slug}/${item.chapter}/${item.slug}`);
        }
    };

    // Manuals nach Kategorien gruppieren
    const groupedByCategory = manuals.reduce((acc, manual) => {
        const categoryName = manual.category_name || "";
        if (!acc[categoryName]) {
            acc[categoryName] = [];
        }
        acc[categoryName].push(manual);
        return acc;
    }, {});

    // Kategorien sortieren, leere Kategorie zuerst
    const categoryNames = Object.keys(groupedByCategory).filter(name => name !== '').sort();
    const hasUncategorized = groupedByCategory[''] && groupedByCategory[''].length > 0;

    return (
        <Layout>
            <Box sx={{flexGrow: 1, p: 3, maxWidth: 1200, margin: '0 auto', bgcolor: 'transparent'}}>
                <Grid container alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
                    <Grid item>
                        <PageHeader
                            title="Wiki"
                            subtitle="Eure zentrale Plattform für aktuelle Themen."
                            Icon={AnnouncementOutlinedIcon}
                        />
                    </Grid>
                    <Grid item>
                        <Stack direction="row" spacing={2} alignItems="center">
                            <TextField
                                variant="outlined"
                                placeholder="Suche..."
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                onKeyPress={(e) => { if (e.key === 'Enter') handleSearch(); }}
                                InputProps={{
                                    sx: {
                                        borderRadius: '8px',
                                        backgroundColor: '#f9f9f9',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#ccc',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#bbb',
                                        }
                                    },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleSearch}>
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    width: '200px',
                                    transition: 'width 0.3s ease',
                                    '&:focus-within': {
                                        width: '300px',
                                    },
                                    '& .MuiInputBase-input': {
                                        padding: '8px 12px',
                                    }
                                }}
                            />
                        </Stack>
                    </Grid>
                </Grid>


                {searchResults && (
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="h6">Suchergebnisse</Typography>
                        {searchLoading ? (
                            <CircularProgress />
                        ) : (
                            <List>
                                {searchResults.manuals && searchResults.manuals.map((m) => (
                                    <ListItem key={`manual-${m.id}`} button onClick={() => handleSearchResultClick('manual', m)}>
                                        <ListItemText primary={m.title} secondary="Manual" />
                                    </ListItem>
                                ))}
                                {searchResults.chapters && searchResults.chapters.map((c) => (
                                    <ListItem key={`chapter-${c.id}`} button onClick={() => handleSearchResultClick('chapter', c)}>
                                        <ListItemText primary={c.title} secondary={`Kapitel in ${c.manual}`} />
                                    </ListItem>
                                ))}
                                {searchResults.entries && searchResults.entries.map((e) => (
                                    <ListItem key={`entry-${e.id}`} button onClick={() => handleSearchResultClick('entry', e)}>
                                        <ListItemText primary={e.title} secondary={`Eintrag in ${e.manual.title}`} />
                                    </ListItem>
                                ))}
                                {searchResults.manuals?.length === 0 && searchResults.chapters?.length === 0 && searchResults.entries?.length === 0 && (
                                    <Typography variant="body2">Keine Ergebnisse.</Typography>
                                )}
                            </List>
                        )}
                    </Box>
                )}

                {isSettings && (
                    <Box sx={{mb: 2, textAlign: 'left'}}>
                        <Button variant="contained" color="primary" onClick={handleOpenCreateDialog}>
                            Neues Manual erstellen
                        </Button>
                    </Box>
                )}

                {loading ? (
                    <Typography>Laden...</Typography>
                ) : manuals.length > 0 ? (
                    <>
                        {hasUncategorized && (
                            <Box sx={{mb:4}}>
                                <Grid container spacing={4} justifyContent="center">
                                    {groupedByCategory[''].map((manual) => (
                                        <Grid item key={manual.id}>
                                            <ManualCard manual={manual} onSelect={handleSelectManual}/>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        )}
                        {categoryNames.map((catName) => (
                            <Box key={catName} sx={{mb:4}}>
                                <Typography
                                    variant="h5"
                                    gutterBottom
                                    sx={{textAlign: 'center'}}
                                >
                                    {catName}
                                </Typography>
                                <Grid container spacing={4} justifyContent="center">
                                    {groupedByCategory[catName].map((manual) => (
                                        <Grid item key={manual.id}>
                                            <ManualCard manual={manual} onSelect={handleSelectManual}/>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        ))}
                    </>
                ) : (
                    <Typography variant="body1">Keine Manuals verfügbar.</Typography>
                )}

                {isSettings && (
                    <CreateManualDialog
                        open={openCreateDialog}
                        onClose={handleCloseCreateDialog}
                        newManual={newManual}
                        setNewManual={setNewManual}
                        handleCreateManual={handleCreateManual}
                        creating={creating}
                    />
                )}
            </Box>
        </Layout>
    );
};

export default SettingsIndex;
