// CreateManualDialog.jsx
import React, { useContext } from 'react';
import { Dialog, DialogTitle, DialogContent, Box, TextField, Button } from '@mui/material';
import {InfoBarContext} from "../../../provider/InfoBar";

const CreateManualDialog = ({
    open,
    onClose,
    newManual,
    setNewManual,
    handleCreateManual,
    creating,
}) => {
    const { showError } = useContext(InfoBarContext);

    const handleSubmit = () => {
        if (!newManual.title) {
            showError('Bitte geben Sie einen Titel für das Manual ein.');
            return;
        }
        handleCreateManual();
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: { backgroundColor: '#f5f9f7' },
            }}
        >
            <DialogTitle>Neues Manual erstellen</DialogTitle>
            <DialogContent>
                <Box component="form" sx={{ mt: 2 }}>
                    <TextField
                        required
                        fullWidth
                        label="Titel"
                        value={newManual.title}
                        onChange={(e) => setNewManual({ ...newManual, title: e.target.value })}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        fullWidth
                        label="Beschreibung"
                        value={newManual.description}
                        onChange={(e) => setNewManual({ ...newManual, description: e.target.value })}
                        multiline
                        rows={4}
                        sx={{ mb: 2 }}
                    />
                    <Box sx={{ textAlign: 'right' }}>
                        <Button onClick={onClose} sx={{ mr: 1 }}>
                            Abbrechen
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            disabled={creating}
                        >
                            {creating ? 'Erstellen...' : 'Erstellen'}
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default CreateManualDialog;
