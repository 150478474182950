import React, { useState, useEffect } from 'react';
import {
    Box, Slider, Button, MenuItem, Select, FormControlLabel, Checkbox,
    TextField, Typography, Dialog, DialogTitle, DialogContent, DialogActions,
    Stepper, Step, StepLabel, List, ListItem, ListItemText, IconButton,
    Collapse, Alert, Divider
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../services/axiosInstance';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

const QualitaetHinzufuegen = ({ open, handleClose }) => {
    const [activeStep, setActiveStep] = useState(0);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [isActive, setIsActive] = useState(true);
    const [checkId, setCheckId] = useState(null);
    const [categories, setCategories] = useState([]);
    const [categoryName, setCategoryName] = useState('');
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const navigate = useNavigate();
    const [maxRating, setMaxRating] = useState(10);
    const [ratingType, setRatingType] = useState('scale');
    const [allowImageUpload, setAllowImageUpload] = useState(false);

    // Zustände für Checkpoints
    const [checkpointName, setCheckpointName] = useState('');
    const [checkpointDescription, setCheckpointDescription] = useState('');
    const [checkpoints, setCheckpoints] = useState([]);
    const [error, setError] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
    const [openCheckpointDialog, setOpenCheckpointDialog] = useState(false);
    const [editingCategory, setEditingCategory] = useState(null);
    const [editingCheckpoint, setEditingCheckpoint] = useState(null);

    const steps = ['Name und Beschreibung', 'Kategorien hinzufügen', 'Check-Konfiguration'];

    const handleNext = async () => {
        if (activeStep === 0) {
            if (checkId === null) {
                // Nur dann POST-Request senden, wenn kein checkId vorhanden ist
                try {
                    const response = await axiosInstance.post('/qualitaet/checks/', {
                        name,
                        description,
                        is_active: isActive,
                        max: maxRating
                    });
                    setCheckId(response.data.id);
                } catch (error) {
                    console.error('Fehler beim Hinzufügen des Qualitätschecks:', error);
                    return;
                }
            } else {
                // Falls checkId vorhanden ist, PATCH-Request senden, um Änderungen zu speichern
                try {
                    await axiosInstance.patch(`/qualitaet/checks/${checkId}/`, {
                        name,
                        description,
                        is_active: isActive,
                        max: maxRating
                    });
                } catch (error) {
                    console.error('Fehler beim Aktualisieren des Qualitätschecks:', error);
                    return;
                }
            }
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSaveAndClose = () => {
        resetForm(); // Setze den Zustand zurück
        handleClose();
        navigate('/qualitaet/settings/index');
    };

    const handleAddCategory = async () => {
        if (categoryName.trim() === '') {
            return;
        }

        try {
            const newCategoryOrder = categories.length;
            const response = await axiosInstance.post('/qualitaet/categories/', {
                name: categoryName,
                check_related: checkId,
                order: newCategoryOrder,
            });
            setCategories([...categories, response.data]);
            setCategoryName('');
        } catch (error) {
            console.error('Fehler beim Hinzufügen der Kategorie:', error);
        }
    };

    const handleDeleteCategory = async (categoryId) => {
        try {
            await axiosInstance.delete(`/qualitaet/categories/${categoryId}/`);
            const updatedCategories = categories.filter((category) => category.id !== categoryId);

            // Reihenfolge neu setzen
            const reorderedCategories = updatedCategories.map((category, index) => ({
                ...category,
                order: index,
            }));
            setCategories(reorderedCategories);
            updateCategoryOrder(reorderedCategories);
        } catch (error) {
            console.error('Fehler beim Löschen der Kategorie:', error);
        }
    };

    // Funktionen zum Aktualisieren der Kategorienreihenfolge im Backend
    const updateCategoryOrder = async (categories) => {
        try {
            const updatedCategories = categories.map((category, index) => ({
                id: category.id,
                order: index,
            }));

            await axiosInstance.post('/qualitaet/categories/update_order/', {
                categories: updatedCategories,
            });
        } catch (error) {
            console.error('Fehler beim Aktualisieren der Kategorienreihenfolge:', error);
        }
    };

    // Funktion zum Aktualisieren der Checkpoint-Reihenfolge im Backend
    const updateCheckpointOrder = async (updatedCheckpoints) => {
        try {
            await axiosInstance.post('/qualitaet/checkpoints/update_order/', {
                checkpoints: updatedCheckpoints.map(cp => ({
                    id: cp.id,
                    order: cp.order,
                })),
            });
        } catch (error) {
            console.error('Fehler beim Aktualisieren der Checkpoint-Reihenfolge:', error);
        }
    };

    useEffect(() => {
        if (activeStep === 2 && checkId) {
            // Kategorien für den Check abrufen
            axiosInstance.get('/qualitaet/categories/')
                .then(response => {
                    const filteredCategories = response.data.results
                        .filter(category => category.check_related === checkId)
                        .sort((a, b) => a.order - b.order);
                    setCategories(filteredCategories);
                })
                .catch(error => {
                    console.error('Fehler beim Abrufen der Kategorien:', error);
                });

            // Checkpoints abrufen
            axiosInstance.get('/qualitaet/checkpoints/')
                .then(response => {
                    const sortedCheckpoints = response.data.results.sort((a, b) => a.order - b.order);
                    setCheckpoints(sortedCheckpoints);
                })
                .catch(error => {
                    console.error('Fehler beim Abrufen der Checkpoints:', error);
                });
        }
    }, [activeStep, checkId]);

    const handleSaveCategoryInStep3 = () => {
        if (categoryName.trim() === '') {
            setError('Kategoriename darf nicht leer sein.');
            return;
        }

        if (editingCategory) {
            // Bearbeitete Kategorie speichern
            axiosInstance.patch(`/qualitaet/categories/${editingCategory.id}/`, { name: categoryName })
                .then((response) => {
                    const updatedCategories = categories.map(category => category.id === editingCategory.id ? response.data : category);
                    setCategories(updatedCategories);
                    setEditingCategory(null);
                    setCategoryName('');
                    setOpenCategoryDialog(false);
                })
                .catch((error) => {
                    setError('Fehler beim Bearbeiten der Kategorie.');
                    console.error('Fehler beim Bearbeiten der Kategorie:', error);
                });
        } else {
            // Neue Kategorie speichern
            const newCategoryOrder = categories.length;
            axiosInstance.post('/qualitaet/categories/', {
                name: categoryName,
                check_related: checkId,
                order: newCategoryOrder,
            })
                .then((response) => {
                    setCategories([...categories, response.data]);
                    setCategoryName('');
                    setError(null);
                    setOpenCategoryDialog(false);
                })
                .catch((error) => {
                    setError('Fehler beim Speichern der Kategorie.');
                    console.error('Fehler beim Speichern der Kategorie:', error);
                });
        }
    };

    const handleSaveCheckpoint = () => {
        if (checkpointName.trim() === '') {
            setError('Überprüfungspunktname darf nicht leer sein.');
            return;
        }

        const checkpointData = {
            name: checkpointName,
            description: checkpointDescription,
            category: selectedCategory,
            rating_type: ratingType,
            allow_image_upload: allowImageUpload,
            order: checkpoints.filter(cp => cp.category === selectedCategory).length,
        };

        if (editingCheckpoint) {
            // Bearbeiteten Checkpoint speichern
            axiosInstance.patch(`/qualitaet/checkpoints/${editingCheckpoint.id}/`, checkpointData)
                .then((response) => {
                    const updatedCheckpoints = checkpoints.map(checkpoint => checkpoint.id === editingCheckpoint.id ? response.data : checkpoint);
                    setCheckpoints(updatedCheckpoints);
                    setEditingCheckpoint(null);
                    setCheckpointName('');
                    setCheckpointDescription('');
                    setRatingType('scale');
                    setAllowImageUpload(false);
                    setOpenCheckpointDialog(false);
                })
                .catch((error) => {
                    setError('Fehler beim Bearbeiten des Überprüfungspunkts.');
                    console.error('Fehler beim Bearbeiten des Überprüfungspunkts:', error);
                });
        } else {
            // Neuen Checkpoint speichern
            axiosInstance.post('/qualitaet/checkpoints/', checkpointData)
                .then((response) => {
                    setCheckpoints([...checkpoints, response.data]);
                    setCheckpointName('');
                    setCheckpointDescription('');
                    setRatingType('scale');
                    setAllowImageUpload(false);
                    setError(null);
                    setOpenCheckpointDialog(false);
                })
                .catch((error) => {
                    setError('Fehler beim Speichern des Überprüfungspunkts.');
                    console.error('Fehler beim Speichern des Überprüfungspunkts:', error);
                });
        }
    };

    const handleDeleteCategoryInStep3 = async (id) => {
        try {
            await axiosInstance.delete(`/qualitaet/categories/${id}/`);
            const updatedCategories = categories.filter(category => category.id !== id);

            // Reihenfolge neu setzen
            const reorderedCategories = updatedCategories.map((category, index) => ({
                ...category,
                order: index,
            }));
            setCategories(reorderedCategories);
            updateCategoryOrder(reorderedCategories);
        } catch (error) {
            console.error('Fehler beim Löschen der Kategorie:', error);
        }
    };

    const handleDeleteCheckpoint = async (id) => {
        try {
            await axiosInstance.delete(`/qualitaet/checkpoints/${id}/`);
            setCheckpoints(checkpoints.filter(checkpoint => checkpoint.id !== id));
        } catch (error) {
            console.error('Fehler beim Löschen des Checkpoints:', error);
        }
    };

    const handleEditCategory = (category) => {
        setEditingCategory(category);
        setCategoryName(category.name);
        setOpenCategoryDialog(true);
    };

    const handleEditCheckpoint = (checkpoint) => {
        setEditingCheckpoint(checkpoint);
        setCheckpointName(checkpoint.name);
        setCheckpointDescription(checkpoint.description || '');
        setRatingType(checkpoint.rating_type);
        setAllowImageUpload(checkpoint.allow_image_upload);
        setOpenCheckpointDialog(true);
    };

    const handleAddCheckpointPlaceholderClick = (categoryId) => {
        setSelectedCategory(categoryId);
        setCheckpointName('');
        setCheckpointDescription('');
        setRatingType('scale');
        setAllowImageUpload(false);
        setOpenCheckpointDialog(true);
    };

    // Funktion zum Löschen des erstellten Checks und Zurücksetzen des Formulars
    const deleteCheck = async () => {
        if (checkId) {
            try {
                // Lösche alle zugehörigen Kategorien und Checkpoints
                for (let category of categories) {
                    const categoryCheckpoints = checkpoints.filter(cp => cp.category === category.id);
                    for (let checkpoint of categoryCheckpoints) {
                        await axiosInstance.delete(`/qualitaet/checkpoints/${checkpoint.id}/`);
                    }
                    await axiosInstance.delete(`/qualitaet/categories/${category.id}/`);
                }
                // Lösche den Check
                await axiosInstance.delete(`/qualitaet/checks/${checkId}/`);
            } catch (error) {
                console.error('Fehler beim Löschen des Qualitätschecks:', error);
            }
        }
    };

    const resetForm = () => {
        setActiveStep(0);
        setName('');
        setDescription('');
        setIsActive(true);
        setCheckId(null);
        setCategories([]);
        setCategoryName('');
        setCheckpointName('');
        setCheckpointDescription('');
        setCheckpoints([]);
        setError(null);
        setSelectedCategory(null);
        setOpenCategoryDialog(false);
        setOpenCheckpointDialog(false);
        setEditingCategory(null);
        setEditingCheckpoint(null);
        setMaxRating(10);
        setRatingType('scale');
        setAllowImageUpload(false);
    };

    // Funktion zum Öffnen des Bestätigungsdialogs beim Schließen
    const handleCloseWithConfirmation = () => {
        setOpenConfirmDialog(true);
    };

    // Funktion, die ausgeführt wird, wenn der Nutzer das Schließen bestätigt
    const handleConfirmClose = async () => {
        await deleteCheck(); // Lösche den angelegten Check inkl. Kategorien
        resetForm();
        setOpenConfirmDialog(false);
        handleClose();
    };

    // Funktion, die ausgeführt wird, wenn der Nutzer das Schließen abbricht
    const handleCancelClose = () => {
        setOpenConfirmDialog(false);
    };

    // Anpassung des onClose-Events, um Schließen über Klick außerhalb oder Escape-Taste abzufangen
    const handleDialogClose = (event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            handleCloseWithConfirmation();
        }
    };

    // Funktion für Drag-and-Drop
    const onDragEnd = async (result) => {
        const { source, destination, type } = result;

        if (!destination) return;

        if (type === 'category') {
            if (source.index === destination.index) return;

            const reorderedCategories = Array.from(categories);
            const [movedCategory] = reorderedCategories.splice(source.index, 1);
            reorderedCategories.splice(destination.index, 0, movedCategory);

            setCategories(reorderedCategories);
            await updateCategoryOrder(reorderedCategories);
        } else if (type === 'checkpoint') {
            if (source.droppableId !== destination.droppableId) {
                // Falls das Verschieben zwischen Kategorien nicht erlaubt ist, einfach zurücksetzen
                return;
            }

            const categoryId = parseInt(source.droppableId, 10);
            const categoryCheckpoints = checkpoints.filter(cp => cp.category === categoryId);
            const reorderedCheckpoints = Array.from(categoryCheckpoints);
            const [movedCheckpoint] = reorderedCheckpoints.splice(source.index, 1);
            reorderedCheckpoints.splice(destination.index, 0, movedCheckpoint);

            const updatedReorderedCheckpoints = reorderedCheckpoints.map((cp, idx) => ({
                ...cp,
                order: idx,
            }));

            // Entferne alle Checkpoints der Kategorie und füge die neu geordneten hinzu
            const otherCheckpoints = checkpoints.filter(cp => cp.category !== categoryId);
            const newCheckpoints = [...otherCheckpoints, ...updatedReorderedCheckpoints];

            setCheckpoints(newCheckpoints);

            try {
                await updateCheckpointOrder(updatedReorderedCheckpoints);
            } catch (error) {
                console.error('Fehler beim Aktualisieren der Checkpoint-Reihenfolge:', error);
            }
        }
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleDialogClose}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>Neuen Qualitätscheck hinzufügen</DialogTitle>
                <DialogContent>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <Box sx={{ mt: 3 }}>
                        {activeStep === 0 && (
                            <Box component="form" sx={{ width: '100%' }}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="name"
                                    label="Name des Qualitätschecks"
                                    name="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <Typography gutterBottom>Maximale Bewertungsskala</Typography>
                                <Slider
                                    value={maxRating}
                                    onChange={(e, newValue) => setMaxRating(newValue)}
                                    aria-labelledby="max-rating-slider"
                                    min={1}
                                    max={10}
                                    step={1}
                                    marks
                                    valueLabelDisplay="auto"
                                />
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="description"
                                    label="Beschreibung"
                                    name="description"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    multiline
                                    rows={4}
                                />
                            </Box>
                        )}
                        {activeStep === 1 && (
                            <Box>
                                <Typography variant="h6" gutterBottom>Kategorien hinzufügen</Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                    <TextField
                                        label="Kategoriename"
                                        value={categoryName}
                                        onChange={(e) => setCategoryName(e.target.value)}
                                        fullWidth
                                    />
                                    <IconButton color="primary" onClick={handleAddCategory}>
                                        <AddIcon />
                                    </IconButton>
                                </Box>
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId="categories" type="category">
                                        {(provided) => (
                                            <List
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {categories.map((category, index) => (
                                                    <Draggable
                                                        key={category.id}
                                                        draggableId={`category-${category.id}`}
                                                        index={index}
                                                    >
                                                        {(provided) => (
                                                            <ListItem
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                secondaryAction={
                                                                    <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteCategory(category.id)}>
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                }
                                                                sx={{ border: '1px solid #ddd', borderRadius: '8px', mb: 1 }}
                                                            >
                                                                <ListItemText primary={category.name} />
                                                                <span {...provided.dragHandleProps}>
                                                                    <DragIndicatorIcon />
                                                                </span>
                                                            </ListItem>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </List>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </Box>
                        )}
                        {activeStep === 2 && checkId && (
                            <Box>
                                {error && (
                                    <Alert severity="error" sx={{ mb: 2 }}>
                                        {error}
                                    </Alert>
                                )}
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Box component="form" sx={{ mb: 2 }}>
                                        {categories.length > 0 ? (
                                            <Droppable droppableId="categories" type="category">
                                                {(provided) => (
                                                    <List
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                    >
                                                        {categories.map((category, index) => (
                                                            <Draggable
                                                                key={category.id}
                                                                draggableId={`category-${category.id}`}
                                                                index={index}
                                                            >
                                                                {(provided) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                    >
                                                                        <CategoryItem
                                                                            category={category}
                                                                            selectedCategory={selectedCategory}
                                                                            setSelectedCategory={setSelectedCategory}
                                                                            checkpoints={checkpoints}
                                                                            handleDeleteCategory={handleDeleteCategoryInStep3}
                                                                            handleEditCategory={handleEditCategory}
                                                                            handleDeleteCheckpoint={handleDeleteCheckpoint}
                                                                            handleEditCheckpoint={handleEditCheckpoint}
                                                                            handleAddCheckpointPlaceholderClick={handleAddCheckpointPlaceholderClick}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provided.placeholder}
                                                    </List>
                                                )}
                                            </Droppable>
                                        ) : (
                                            <Typography variant="body2" color="textSecondary">Keine Kategorien vorhanden.</Typography>
                                        )}
                                        <Box
                                            sx={{
                                                border: '2px dashed #ddd',
                                                borderRadius: '8px',
                                                padding: '16px',
                                                textAlign: 'center',
                                                cursor: 'pointer',
                                                color: '#888',
                                                mt: 2,
                                                width: '100%',
                                                '&:hover': {
                                                    backgroundColor: '#f5f5f5'
                                                },
                                                boxSizing: 'border-box',
                                            }}
                                            onClick={() => setOpenCategoryDialog(true)}
                                        >
                                            <AddIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                                            Neue Kategorie anlegen
                                        </Box>
                                    </Box>
                                </DragDropContext>
                            </Box>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        variant="outlined"
                    >
                        Zurück
                    </Button>
                    <Button
                        onClick={handleCloseWithConfirmation}
                        variant="outlined"
                        color="secondary"
                    >
                        Abbrechen
                    </Button>
                    {activeStep === steps.length - 1 ? (
                        <Button
                            onClick={handleSaveAndClose}
                            variant="contained"
                            color="primary"
                        >
                            Fertigstellen
                        </Button>
                    ) : (
                        <Button
                            onClick={handleNext}
                            variant="contained"
                            color="primary"
                            disabled={activeStep === 1 && categories.length === 0}
                        >
                            Weiter
                        </Button>
                    )}
                </DialogActions>
            </Dialog>

            {/* Bestätigungsdialog */}
            <Dialog
                open={openConfirmDialog}
                onClose={handleCancelClose}
            >
                <DialogTitle>Bestätigung</DialogTitle>
                <DialogContent>
                    <Typography>
                        Möchtest du wirklich abbrechen? Alle nicht gespeicherten Änderungen gehen verloren.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelClose} color="primary">
                        Nein
                    </Button>
                    <Button onClick={handleConfirmClose} color="secondary">
                        Ja, abbrechen
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Kategoriedialog */}
            <CategoryDialog
                open={openCategoryDialog}
                onClose={() => setOpenCategoryDialog(false)}
                categoryName={categoryName}
                setCategoryName={setCategoryName}
                handleSaveCategory={handleSaveCategoryInStep3}
            />

            {/* Checkpointdialog */}
            <CheckpointDialog
                open={openCheckpointDialog}
                onClose={() => setOpenCheckpointDialog(false)}
                checkpointName={checkpointName}
                setCheckpointName={setCheckpointName}
                checkpointDescription={checkpointDescription}
                setCheckpointDescription={setCheckpointDescription}
                handleSaveCheckpoint={handleSaveCheckpoint}
                ratingType={ratingType}
                setRatingType={setRatingType}
                allowImageUpload={allowImageUpload}
                setAllowImageUpload={setAllowImageUpload}
            />
        </>
    );
};

const CategoryItem = ({
    category,
    selectedCategory,
    setSelectedCategory,
    checkpoints,
    handleDeleteCategory,
    handleEditCategory,
    handleDeleteCheckpoint,
    handleEditCheckpoint,
    handleAddCheckpointPlaceholderClick
}) => (
    <Box sx={{ border: '1px solid #ddd', borderRadius: '8px', p: 2, mb: 2, backgroundColor: '#fafafa' }}>
        <ListItem
            secondaryAction={
                <>
                    <IconButton edge="end" aria-label="edit" onClick={() => handleEditCategory(category)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteCategory(category.id)}>
                        <DeleteIcon />
                    </IconButton>
                </>
            }
            onClick={() => setSelectedCategory(selectedCategory === category.id ? null : category.id)}
            selected={selectedCategory === category.id}
            sx={{ cursor: 'pointer' }}
        >
            <ListItemText primary={category.name} />
        </ListItem>
        <Collapse in={selectedCategory === category.id} timeout="auto" unmountOnExit>
            <Box sx={{ mt: 2, mb: 2 }}>
                <CheckpointList
                    categoryId={category.id}
                    checkpoints={checkpoints.filter(checkpoint => checkpoint.category === category.id)}
                    handleDeleteCheckpoint={handleDeleteCheckpoint}
                    handleEditCheckpoint={handleEditCheckpoint}
                />
                <Box
                    sx={{
                        border: '2px dashed #ddd',
                        borderRadius: '8px',
                        padding: '16px',
                        textAlign: 'center',
                        cursor: 'pointer',
                        color: '#888',
                        mt: 2,
                        width: '100%',
                        '&:hover': {
                            backgroundColor: '#f5f5f5'
                        },
                        boxSizing: 'border-box',
                    }}
                    onClick={() => handleAddCheckpointPlaceholderClick(category.id)}
                >
                    <AddIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                    Checkpoint anlegen
                </Box>
            </Box>
        </Collapse>
    </Box>
);

const CheckpointList = ({ categoryId, checkpoints, handleDeleteCheckpoint, handleEditCheckpoint }) => (
    <Droppable droppableId={`${categoryId}`} type="checkpoint">
        {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
                <List sx={{ width: '100%' }}>
                    {checkpoints.length > 0 ? (
                        checkpoints.map((checkpoint, index) => (
                            <Draggable key={checkpoint.id} draggableId={`checkpoint-${checkpoint.id}`} index={index}>
                                {(provided) => (
                                    <Box
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        sx={{
                                            border: '1px solid #ddd',
                                            borderRadius: '8px',
                                            p: 2,
                                            mb: 1,
                                            width: '100%',
                                            boxSizing: 'border-box',
                                            backgroundColor: '#fff',
                                        }}
                                    >
                                        <ListItem
                                            secondaryAction={
                                                <>
                                                    <IconButton edge="end" aria-label="edit" onClick={() => handleEditCheckpoint(checkpoint)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteCheckpoint(checkpoint.id)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </>
                                            }
                                        >
                                            <ListItemText
                                                primary={checkpoint.name}
                                                secondary={
                                                    <>
                                                        <Typography variant="body2">
                                                            Bewertungsart: {checkpoint.rating_type === 'scale' ? 'Skala' : (checkpoint.rating_type === 'yesNo' ? 'Ja/Nein' : 'Gewichtskontrolle')}
                                                        </Typography>
                                                        {checkpoint.description && (
                                                            <Typography variant="body2">
                                                                Beschreibung: {checkpoint.description}
                                                            </Typography>
                                                        )}
                                                        <Typography variant="body2">
                                                            Bild-Upload: {checkpoint.allow_image_upload ? 'Erlaubt' : 'Nicht erlaubt'}
                                                        </Typography>
                                                    </>
                                                }
                                            />
                                        </ListItem>
                                    </Box>
                                )}
                            </Draggable>
                        ))
                    ) : (
                        <Typography variant="body2" color="textSecondary">
                            Keine Überprüfungspunkte vorhanden.
                        </Typography>
                    )}
                    {provided.placeholder}
                </List>
            </div>
        )}
    </Droppable>
);

// Kategoriedialog-Komponente
const CategoryDialog = ({ open, onClose, categoryName, setCategoryName, handleSaveCategory }) => (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>{categoryName ? 'Kategorie bearbeiten' : 'Neue Kategorie anlegen'}</DialogTitle>
        <DialogContent>
            <TextField
                label="Kategoriename"
                fullWidth
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
                margin="normal"
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleSaveCategory} variant="contained" color="primary">
                Speichern
            </Button>
            <Button onClick={onClose} variant="outlined">
                Abbrechen
            </Button>
        </DialogActions>
    </Dialog>
);

// Checkpointdialog-Komponente
const CheckpointDialog = ({
    open,
    onClose,
    checkpointName,
    setCheckpointName,
    checkpointDescription,
    setCheckpointDescription,
    ratingType,
    setRatingType,
    allowImageUpload,
    setAllowImageUpload,
    handleSaveCheckpoint
}) => (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>{checkpointName ? 'Überprüfungspunkt bearbeiten' : 'Neuen Überprüfungspunkt anlegen'}</DialogTitle>
        <DialogContent>
            <TextField
                label="Überprüfungspunkt Name"
                fullWidth
                value={checkpointName}
                onChange={(e) => setCheckpointName(e.target.value)}
                margin="normal"
            />
            <TextField
                label="Beschreibung"
                fullWidth
                value={checkpointDescription}
                onChange={(e) => setCheckpointDescription(e.target.value)}
                margin="normal"
                multiline
                rows={3}
            />
            <Typography variant="body2" sx={{ mt: 2 }}>Bewertungsart</Typography>
            <Select
                value={ratingType}
                onChange={(e) => setRatingType(e.target.value)}
                fullWidth
            >
                <MenuItem value="scale">Skala</MenuItem>
                <MenuItem value="yesNo">Ja/Nein</MenuItem>
                <MenuItem value="gewicht">Gewichtskontrolle</MenuItem>
            </Select>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={allowImageUpload}
                        onChange={(e) => setAllowImageUpload(e.target.checked)}
                    />
                }
                label="Bild-Upload erlauben"
                sx={{ mt: 2 }}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleSaveCheckpoint} variant="contained" color="primary">
                Speichern
            </Button>
            <Button onClick={onClose} variant="outlined">
                Abbrechen
            </Button>
        </DialogActions>
    </Dialog>
);

export default QualitaetHinzufuegen;
