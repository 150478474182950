import { Button, Box, Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import React from "react";

const GeneralError = () => {
    const navigate = useNavigate();

    const handleNavigation = () => {
        navigate('/login');
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh', pt: '0vh' }}>
            <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h1" color="error.main">Unerwarteter Fehler</Typography>
                <Box sx={{ my: 2 }}>
                    <Typography variant="h5">Es ist ein unerwarteter Fehler aufgetreten.<br/> Bitte versuche es später erneut.</Typography>
                </Box>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ fontSize: '1.15rem', padding: '6px 12px', minWidth: '120px' }}
                    onClick={handleNavigation}
                >
                    Zur Anmeldeseite
                </Button>
            </Box>
        </Box>
    )
}

export default GeneralError;
