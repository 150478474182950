import React, { useState } from 'react';
import { Card, Paper, Typography, Box, IconButton } from '@mui/material';
import Score from '../score/Score';
import SearchIcon from '@mui/icons-material/QueryStats';

const ComparisonCard = ({ zeitraum, data, fields, formatValue, TiefenanalyseComponent }) => {
  const [openTiefenanalyse, setOpenTiefenanalyse] = useState(false);

  const handleOpenTiefenanalyse = () => {
    setOpenTiefenanalyse(true);
  };

  return (
    <Paper className="db_comparison_card">
      <Card
        sx={{
          padding: '16px',
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          backdropFilter: 'blur(10px)',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography variant="h6" component="div" sx={{ textAlign: 'center', flexGrow: 1 }}>
            {zeitraum}
          </Typography>
          {TiefenanalyseComponent && (
            <IconButton onClick={handleOpenTiefenanalyse} sx={{ color: 'primary.main' }}>
              <SearchIcon />
            </IconButton>
          )}
        </Box>

        {/* Container für die Felder */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 2,
            width: '100%',
            overflow: 'hidden'
          }}
        >
          {fields.map((field, index) => {
            const rawValue = data && data[field.valueField] !== undefined ? data[field.valueField] : 'N/A';
            let displayedValue = formatValue ? formatValue(rawValue, field.valueField) : rawValue;

            const isScoreField = field.label.toLowerCase().includes('score');

            // Für Scores: auf ganze Zahl runden und kein Prozentzeichen
            if (isScoreField && displayedValue !== 'N/A') {
              const numberValue = parseFloat(displayedValue);
              if (!isNaN(numberValue)) {
                displayedValue = Math.round(numberValue).toString();
              }
            }

            return (
              <Box
                key={index}
                sx={{
                  flex: 1,
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                {isScoreField ? (
                  <Score score={displayedValue} />
                ) : (
                  <Typography variant="h6" noWrap>
                    {displayedValue}
                  </Typography>
                )}
                <Typography variant="caption" sx={{ color: 'gray', mt: 0.5 }} noWrap>
                  {field.label}
                </Typography>
              </Box>
            );
          })}
        </Box>

        {openTiefenanalyse && TiefenanalyseComponent && (
          <TiefenanalyseComponent
            data={data}
            zeitraum={zeitraum}
            open={openTiefenanalyse}
            setOpen={setOpenTiefenanalyse}
          />
        )}
      </Card>
    </Paper>
  );
};

export default ComparisonCard;
