// FilialcheckAnalyseManagementPage.jsx
import React, { useState, useEffect, useContext } from 'react';
import {
    Grid, Box, Typography, Paper, CircularProgress, IconButton, Card, CardContent,
    LinearProgress, CardHeader, Dialog, DialogTitle, DialogContent, Table, TableBody,
    TableCell, TableContainer, TableHead, TableRow, Button, Collapse, Stack
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ChecklistIcon from '@mui/icons-material/Checklist';
import axiosInstance from '../../../services/axiosInstance';
import dayjs from 'dayjs';
import Layout from '../../../components/layout/Filialcheck/Filialcheck';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import BigChartCardRecharts from '../../../components/card/scorecardsmonat/BigChartCardRecharts';
import DropdownFilter from '../../../components/filter/DropdownFilter';
import { FilterContext } from '../../../provider/FilterProvider';

const FilialcheckAnalyseManagementPage = () => {
    const [loading, setLoading] = useState(true);
    const [activeChecks, setActiveChecks] = useState([]);
    const [allChecks, setAllChecks] = useState([]);
    const [error, setError] = useState(null);
    const [filialen, setFilialen] = useState([]);
    const [scaleCheckpoints, setScaleCheckpoints] = useState([]);
    const [filialeData, setFilialeData] = useState([]);
    const [verkaufsgebietCounts, setVerkaufsgebietCounts] = useState([]);
    const [checkCounts, setCheckCounts] = useState({});
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedCheck, setSelectedCheck] = useState(null);
    const [verkaufsleiterChecks, setVerkaufsleiterChecks] = useState([]);
    const [expandedRows, setExpandedRows] = useState({});
    const [chartData, setChartData] = useState([]);
    const [datumOptions, setDatumOptions] = useState([]);
    const [checkOptions, setCheckOptions] = useState([]);
    const maxRating = 10;

    const { filterState, handleDatumChange } = useContext(FilterContext);

    const [selectedFilterCheck, setSelectedFilterCheck] = useState({ label: 'Alle Checks', value: 'all' });

    // Laden der Filialdaten
    useEffect(() => {
        const fetchFilialen = async () => {
            try {
                const response = await axiosInstance.get('/stammdaten/stammdatenfiliale/');
                setFilialen(response.data.results || []);
            } catch (error) {
                console.error('Fehler beim Abrufen der Filialdaten:', error);
                setError('Fehler beim Abrufen der Filialdaten.');
            }
        };

        fetchFilialen();
    }, []);

    // Laden der aktiven Checks und aller Checks
    useEffect(() => {
        const fetchActiveChecks = async () => {
            try {
                const response = await axiosInstance.get('/filialcheck/checks/');
                const activeChecksData = response.data.results.filter(check => check.is_active);
                setActiveChecks(activeChecksData);
                setAllChecks(response.data.results);
                setLoading(false);

                // Check-Optionen für den Filter vorbereiten
                const checkOptionsData = [
                    { label: 'Alle Checks', value: 'all' },
                    ...activeChecksData.map(check => ({ label: check.name, value: check.id }))
                ];
                setCheckOptions(checkOptionsData);

            } catch (error) {
                console.error('Fehler beim Laden der aktiven Checks:', error);
                setError('Fehler beim Laden der aktiven Checks.');
                setLoading(false);
            }
        };

        fetchActiveChecks();
    }, []);

    // Laden der Scale Checkpoints
    useEffect(() => {
        const fetchScaleCheckpoints = async () => {
            try {
                const response = await axiosInstance.get('/filialcheck/checkpoints/');
                const scaleCps = response.data.results.filter(cp => cp.rating_type === 'scale');
                setScaleCheckpoints(scaleCps);
            } catch (error) {
                console.error('Fehler beim Abrufen der Checkpoints:', error);
                setError('Fehler beim Abrufen der Checkpoints.');
            }
        };

        fetchScaleCheckpoints();
    }, []);

    // Laden der Datum-Optionen
    useEffect(() => {
        const fetchDatumOptions = async () => {
            try {
                const response = await axiosInstance.get('/stammdaten/unique-jahr-monat/');
                setDatumOptions(response.data.map(item => ({ label: item, value: item })));
            } catch (error) {
                console.error('Fehler beim Abrufen der Datum-Optionen:', error);
                setError('Fehler beim Abrufen der Datum-Optionen.');
            }
        };

        fetchDatumOptions();
    }, []);

    // Laden der Ratings basierend auf den Filtern
    useEffect(() => {
        const fetchChecksPerMonth = async () => {
            try {
                if (!filterState.datum) return;

                const selectedMonth = dayjs(filterState.datum.value, 'YYYY.MM');
                const jahrMonat = selectedMonth.format('YYYY.MM');

                // API-Parameter vorbereiten
                const params = {
                    jahrMonat: jahrMonat
                };

                if (selectedFilterCheck.value !== 'all') {
                    params.check_related = selectedFilterCheck.value;
                }

                // Verkaufsgebiet-Filter hinzufügen, falls ausgewählt
                if (filterState.verkaufsgebiet) {
                    params.verkaufsgebiet = filterState.verkaufsgebiet.value;
                }

                const response = await axiosInstance.get('/filialcheck/ratings/', { params });
                const ratings = response.data.results;

                // Anreicherung der Ratings mit Filial- und Verkaufsgebiets-Daten
                const enrichedRatings = ratings.map(rating => {
                    const filialeData = filialen.find(f => f.filialnummer === rating.filialnummer);
                    return {
                        ...rating,
                        filiale: filialeData ? filialeData.filiale : 'Unbekannt',
                        verkaufsgebiet: filialeData ? filialeData.verkaufsgebiet : 'Unbekannt',
                    };
                });

                // Filtern der Ratings, um nur 'scale'-Checkpoints zu berücksichtigen
                const scaleCheckpointIds = new Set(scaleCheckpoints.map(cp => cp.id));
                const scaleRatings = enrichedRatings.filter(rating => scaleCheckpointIds.has(rating.checkpoint));

                // Anwendung des Check-Filters
                const filteredScaleRatings = selectedFilterCheck.value === 'all'
                    ? scaleRatings
                    : scaleRatings.filter(rating => rating.check_related === selectedFilterCheck.value);

                // Gruppierung der Daten pro Filiale
                const filialeDataMap = {};
                const checkCountsMap = {};

                filteredScaleRatings.forEach(rating => {
                    const { filialnummer, filiale, verkaufsgebiet, session_id, value, check_related } = rating;

                    if (!filialeDataMap[filialnummer]) {
                        filialeDataMap[filialnummer] = {
                            filialnummer,
                            filiale,
                            verkaufsgebiet,
                            sessions: new Set(),
                            totalValue: 0,
                            ratingCount: 0,
                            checks: {},
                        };
                    }

                    filialeDataMap[filialnummer].sessions.add(session_id);
                    filialeDataMap[filialnummer].totalValue += value;
                    filialeDataMap[filialnummer].ratingCount += 1;

                    if (!filialeDataMap[filialnummer].checks[check_related]) {
                        filialeDataMap[filialnummer].checks[check_related] = {
                            checkId: check_related,
                            count: 0,
                            sessionIds: new Set(),
                        };
                    }
                    filialeDataMap[filialnummer].checks[check_related].count += 1;
                    filialeDataMap[filialnummer].checks[check_related].sessionIds.add(session_id);

                    if (!checkCountsMap[check_related]) {
                        checkCountsMap[check_related] = new Set();
                    }
                    checkCountsMap[check_related].add(session_id);
                });

                const filialeDataArray = Object.values(filialeDataMap).map(filiale => ({
                    ...filiale,
                    checks: Object.values(filiale.checks),
                }));

                setFilialeData(filialeDataArray);

                // Setzen der Check Counts
                setCheckCounts(checkCountsMap);

                // Berechnung der Checks und Durchschnittswerte pro Verkaufsgebiet
                const verkaufsgebietCountsData = {};
                filialeDataArray.forEach(filiale => {
                    const verkaufsgebiet = filiale.verkaufsgebiet || 'Unbekannt';
                    const sessionCount = filiale.sessions.size;

                    if (!verkaufsgebietCountsData[verkaufsgebiet]) {
                        verkaufsgebietCountsData[verkaufsgebiet] = {
                            sessionCount: 0,
                            totalValue: 0,
                            ratingCount: 0,
                            filialen: [],
                        };
                    }

                    verkaufsgebietCountsData[verkaufsgebiet].sessionCount += sessionCount;
                    verkaufsgebietCountsData[verkaufsgebiet].totalValue += filiale.totalValue;
                    verkaufsgebietCountsData[verkaufsgebiet].ratingCount += filiale.ratingCount;
                    verkaufsgebietCountsData[verkaufsgebiet].filialen.push(filiale);
                });

                const verkaufsgebietCountsArray = Object.keys(verkaufsgebietCountsData).map(key => {
                    const data = verkaufsgebietCountsData[key];
                    const averagePercentage = (data.totalValue / (data.ratingCount * maxRating)) * 100;

                    return {
                        verkaufsgebiet: key,
                        sessionCount: data.sessionCount,
                        averagePercentage: averagePercentage.toFixed(2),
                        filialen: data.filialen,
                    };
                });

                setVerkaufsgebietCounts(verkaufsgebietCountsArray);
            } catch (error) {
                console.error('Fehler beim Laden der Bewertungsdaten:', error);
                setError('Fehler beim Laden der Bewertungsdaten.');
            }
        };

        if (filialen.length > 0 && scaleCheckpoints.length > 0 && filterState.datum) {
            fetchChecksPerMonth();
        }
    }, [filialen, scaleCheckpoints, selectedFilterCheck, filterState.datum]);

    // Laden der Chartdaten mit separaten API-Aufrufen für die letzten sechs Monate
    useEffect(() => {
        const fetchChartData = async () => {
            try {
                if (!filterState.datum) return;

                const selectedMonth = dayjs(filterState.datum.value, 'YYYY.MM');
                const endDate = selectedMonth.endOf('month');
                const months = [];

                // Generiere die letzten 6 Monate im 'YYYY.MM'-Format
                for (let i = 5; i >= 0; i--) {
                    months.push(endDate.subtract(i, 'months').format('YYYY.MM'));
                }

                // Erstelle API-Aufrufe für jeden Monat
                const chartPromises = months.map(month => {
                    const params = {
                        jahrMonat: month
                    };

                    if (selectedFilterCheck.value !== 'all') {
                        params.check_related = selectedFilterCheck.value;
                    }

                    // Verkaufsgebiet-Filter hinzufügen, falls ausgewählt
                    if (filterState.verkaufsgebiet) {
                        params.verkaufsgebiet = filterState.verkaufsgebiet.value;
                    }

                    return axiosInstance.get('/filialcheck/ratings/', { params });
                });

                // Führe alle API-Aufrufe parallel aus
                const responses = await Promise.all(chartPromises);

                // Initialisiere ein Objekt zur Aggregation der Daten nach Verkaufsgebiet
                const groupedData = {};

                responses.forEach((response, index) => {
                    const month = months[index];
                    const ratings = response.data.results;

                    // Anreicherung der Ratings mit Filial- und Verkaufsgebiets-Daten
                    const enrichedRatings = ratings.map(rating => {
                        const filialeData = filialen.find(f => f.filialnummer === rating.filialnummer);
                        return {
                            ...rating,
                            filiale: filialeData ? filialeData.filiale : 'Unbekannt',
                            verkaufsgebiet: filialeData ? filialeData.verkaufsgebiet : 'Unbekannt',
                        };
                    });

                    // Filtern der Ratings, um nur 'scale'-Checkpoints zu berücksichtigen
                    const scaleCheckpointIds = new Set(scaleCheckpoints.map(cp => cp.id));
                    const scaleRatings = enrichedRatings.filter(rating => scaleCheckpointIds.has(rating.checkpoint));

                    // Anwendung des Check-Filters
                    const filteredScaleRatings = selectedFilterCheck.value === 'all'
                        ? scaleRatings
                        : scaleRatings.filter(rating => rating.check_related === selectedFilterCheck.value);

                    // Gruppierung nach Verkaufsgebiet
                    filteredScaleRatings.forEach(rating => {
                        const verkaufsgebiet = rating.verkaufsgebiet || 'Unbekannt';

                        if (!groupedData[verkaufsgebiet]) {
                            groupedData[verkaufsgebiet] = {};
                        }

                        if (!groupedData[verkaufsgebiet][month]) {
                            groupedData[verkaufsgebiet][month] = new Set();
                        }

                        groupedData[verkaufsgebiet][month].add(rating.session_id);
                    });
                });

                // Vorbereitung der Daten für das Diagramm
                const seriesData = [];

                Object.keys(groupedData).forEach(verkaufsgebiet => {
                    const series = {
                        id: verkaufsgebiet,
                        data: months.map(month => ({
                            x: month,
                            y: groupedData[verkaufsgebiet][month] ? groupedData[verkaufsgebiet][month].size : 0
                        }))
                    };
                    seriesData.push(series);
                });

                setChartData(seriesData);

            } catch (error) {
                console.error('Fehler beim Laden der Chartdaten:', error);
                setError('Fehler beim Laden der Chartdaten.');
            }
        };

        if (filialen.length > 0 && scaleCheckpoints.length > 0 && filterState.datum) {
            fetchChartData();
        }
    }, [filialen, scaleCheckpoints, filterState.datum, selectedFilterCheck, filterState.verkaufsgebiet]);

    // Handler für Check-Klicks
    const handleCheckClick = (check) => {
        setSelectedCheck(check);
        const checkData = filialeData.filter(filiale => filiale.checks.some(c => c.checkId === check.id));
        const verkaufsleiterCounts = {};

        checkData.forEach(filiale => {
            const verkaufsgebiet = filiale.verkaufsgebiet || 'Unbekannt';
            const checkInfo = filiale.checks.find(c => c.checkId === check.id);
            if (checkInfo) {
                if (!verkaufsleiterCounts[verkaufsgebiet]) {
                    verkaufsleiterCounts[verkaufsgebiet] = 0;
                }
                verkaufsleiterCounts[verkaufsgebiet] += checkInfo.sessionIds.size;
            }
        });

        const verkaufsleiterCountsArray = Object.keys(verkaufsleiterCounts).map(key => ({
            verkaufsgebiet: key,
            count: verkaufsleiterCounts[key],
        }));

        setVerkaufsleiterChecks(verkaufsleiterCountsArray);
        setDialogOpen(true);
    };

    // Handler zum Schließen des Dialogs
    const handleCloseDialog = () => {
        setDialogOpen(false);
        setSelectedCheck(null);
    };

    // Handler zum Umschalten der erweiterten Reihen in der Tabelle
    const handleRowClick = (index) => {
        setExpandedRows(prev => ({
            ...prev,
            [index]: !prev[index],
        }));
    };

    if (loading) {
        return (
            <Layout>
                <Grid container spacing={2} sx={{ p: 3 }}>
                    <Grid item xs={12}>
                        <PageHeader
                            title="Management-Analyse"
                            subtitle="Behalte die Aktivitäten deiner Führungskräfte im Blick und nutze die Management-Übersicht, um deren Engagement und Fortschritte gezielt zu steuern und zu fördern."
                            Icon={ChecklistIcon}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        <CircularProgress />
                    </Grid>
                </Grid>
            </Layout>
        );
    }

    if (error) {
        return (
            <Layout>
                <Grid container spacing={2} sx={{ p: 3 }}>
                    <Grid item xs={12}>
                        <PageHeader
                            title="Management-Analyse"
                            subtitle="Behalte die Aktivitäten deiner Führungskräfte im Blick und nutze die Management-Übersicht, um deren Engagement und Fortschritte gezielt zu steuern und zu fördern."
                            Icon={ChecklistIcon}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1" color="error">{error}</Typography>
                    </Grid>
                </Grid>
            </Layout>
        );
    }

    const maxChecks = Math.max(...verkaufsgebietCounts.map(item => item.sessionCount), 0);

    return (
        <Layout>
            <Grid container item spacing={2} alignItems={"center"} sx={{ p: 3 }}>
                <Grid item xs={8}>
                    <PageHeader
                        title="Management-Analyse"
                        subtitle="Behalte die Aktivitäten deiner Führungskräfte im Blick und nutze die Management-Übersicht, um deren Engagement und Fortschritte gezielt zu steuern und zu fördern."
                        Icon={ChecklistIcon}
                    />
                </Grid>

                {/* Auswahl für den Datum- und Check-Filter */}
                <Grid item xs={12} md={4}>
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={2}
                    >
                        <DropdownFilter
                            label="Datum"
                            options={datumOptions}
                            value={filterState.datum}
                            onChange={handleDatumChange}
                        />
                        <DropdownFilter
                            label="Check filtern"
                            options={checkOptions}
                            value={selectedFilterCheck}
                            onChange={setSelectedFilterCheck}
                        />
                        <DropdownFilter
                            label="Verkaufsgebiet"
                            options={filialen
                                .map(f => f.verkaufsgebiet)
                                .filter((vg, index, self) => vg && self.indexOf(vg) === index)
                                .map(vg => ({ label: vg, value: vg }))
                            }
                            value={filterState.verkaufsgebiet}
                            onChange={(value) => handleDatumChange({ ...filterState, verkaufsgebiet: value })}
                        />
                    </Stack>
                </Grid>

                {/* Zeile mit Linechart und den aktiven Checks */}
                <Grid container item spacing={2}>
                    {/* Chart */}
                    <Grid item xs={12} md={8}>
                        <BigChartCardRecharts
                            data={chartData}
                            title="Anzahl der Checks (Sessions) pro Verkaufsgebiet der letzten sechs Monate"
                            infoContent="Dieses Diagramm zeigt die Anzahl der durchgeführten Checks (basierend auf Sessions) pro Verkaufsgebiet über die letzten sechs Monate."
                        />
                    </Grid>

                    {/* Aktive Checks in Cards neben dem Chart */}
                    <Grid item xs={12} md={4}>
                        <Grid container spacing={2}>
                            {activeChecks.length > 0 ? (
                                activeChecks.map((check) => (
                                    <Grid item xs={12} key={check.id}>
                                        <Paper sx={{ p: 3, cursor: 'pointer' }} onClick={() => handleCheckClick(check)}>
                                            <Typography variant="h6" gutterBottom>
                                                {check.name}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary">
                                                Checks: {checkCounts[check.id] ? checkCounts[check.id].size : 0}
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                ))
                            ) : (
                                <Grid item xs={12}>
                                    <Typography variant="body1">Keine aktiven Checks verfügbar.</Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>

                {/* Dialog für Details */}
                <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="md" fullWidth>
                    <DialogTitle>Details zu {selectedCheck?.name}</DialogTitle>
                    <DialogContent>
                        {verkaufsleiterChecks.length > 0 ? (
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Verkaufsgebiet</TableCell>
                                            <TableCell>Anzahl Checks</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {verkaufsleiterChecks.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{item.verkaufsgebiet}</TableCell>
                                                <TableCell>{item.count}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <Typography>Keine Daten verfügbar.</Typography>
                        )}
                    </DialogContent>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                        <Button onClick={handleCloseDialog} variant="contained">
                            Schließen
                        </Button>
                    </Box>
                </Dialog>

                {/* Anzeige der Checks nach Verkaufsgebiet mit Progress Charts */}
                <Grid item xs={12}>
                    <Card
                        sx={{
                            padding: '0px',
                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            backdropFilter: 'blur(10px)',
                            mt: 4,
                        }}
                    >
                        <CardHeader
                            title={
                                <Typography variant="h6" component="div">
                                    Anzahl der Checks nach Verkaufsgebiet im {filterState.datum?.label || '...'}
                                </Typography>
                            }
                        />
                        <CardContent>
                            {verkaufsgebietCounts.length > 0 ? (
                                verkaufsgebietCounts.map((item, index) => (
                                    <Paper key={index} sx={{ my: 2 }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                p: 2,
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => handleRowClick(index)}
                                        >
                                            {expandedRows[index] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                            <Box sx={{ flexGrow: 1, ml: 2 }}>
                                                <Typography variant="body1">
                                                    <strong>{item.verkaufsgebiet}: {item.sessionCount} Checks </strong>
                                                </Typography>
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={maxChecks > 0 ? (item.sessionCount / maxChecks) * 100 : 0}
                                                    sx={{ height: 10, borderRadius: 5, mt: 1 }}
                                                />
                                            </Box>
                                        </Box>
                                        <Collapse in={expandedRows[index]} timeout="auto" unmountOnExit>
                                            <Box sx={{ p: 2 }}>
                                                <TableContainer component={Paper}>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Filiale</TableCell>
                                                                <TableCell>Anzahl Checks</TableCell>
                                                                <TableCell>Durchschnittliche Bewertung (%)</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {item.filialen.map((filiale, idx) => {
                                                                const averagePercentage = filiale.ratingCount > 0
                                                                    ? (filiale.totalValue / (filiale.ratingCount * maxRating)) * 100
                                                                    : 0;
                                                                return (
                                                                    <TableRow key={idx}>
                                                                        <TableCell>{filiale.filiale}</TableCell>
                                                                        <TableCell>{filiale.sessions.size}</TableCell>
                                                                        <TableCell>{averagePercentage.toFixed(2)}%</TableCell>
                                                                    </TableRow>
                                                                );
                                                            })}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Box>
                                        </Collapse>
                                    </Paper>
                                ))
                            ) : (
                                <Typography variant="body1">Keine Verkaufsgebiets-Daten verfügbar.</Typography>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Layout>
    );
};

export default FilialcheckAnalyseManagementPage;
