// src/pages/unternehmenwoche/performance/leistung.jsx

import React, { useContext, useEffect, useState } from 'react';
import { Grid, Stack, CircularProgress, Typography, Box, IconButton } from '@mui/material';
import Layout from '../../../components/layout/Scorecards/Woche/Unternehmen/UnternehmenMonatWoche';
import DataCard from '../../../components/card/scorecardsmonat/DataCardWochePage';
import BigChartCardUnternehmenWoche from '../../../components/card/scorecardsmonat/BigChartCardUnternehmenWoche';
import VariableTreeMUI from '../../../components/table/VariableTableTreeMUI';
import FilialePopup from '../../../components/popup/unternehmenWoche/FilialePopup';
import LeistungPopup from '../../../components/popup/unternehmenWoche/LeistungPopup'; // Import des neuen Popups
import axiosInstance from '../../../services/axiosInstance';
import { FilterContext } from '../../../provider/FilterProvider';
import { tausenderTrennung } from '../../../utils/mathStuff'; // Angenommen, diese Funktion formatiert Zahlen korrekt
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import isBetween from 'dayjs/plugin/isBetween';
import 'dayjs/locale/de';
import { useTheme } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search'; // Import des Lupensymbols

dayjs.extend(isoWeek);
dayjs.extend(isBetween);
dayjs.locale('de');

// Hilfsfunktion zur Formatierung von Zahlen mit Komma als Dezimaltrennzeichen
const formatNumber = (value, fractionDigits = 2) => {
    return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
    }).format(value);
};

// Definition der Spalten für die Haupttabelle (VariableTreeMUI)
const mainTableColumns = [
    { field: 'verkaufsgebiet', headerName: 'Verkaufsgebiet', align: 'left' },
    { field: 'filiale', headerName: 'Filiale', align: 'left' },
    {
        field: 'score',
        headerName: 'Score',
        type: 'number',
        align: 'right',
        valueFormatter: (params) =>
            params.value !== null && params.value !== undefined ? formatNumber(params.value, 2) : '',
        hide: false,
    },
    {
        field: 'umsatz',
        headerName: 'Umsatz',
        type: 'number',
        align: 'right',
        valueFormatter: (params) =>
            params.value !== null && params.value !== undefined ? `${formatNumber(params.value, 2)} €` : '',
        hide: false,
    },
    {
        field: 'produktivstunden',
        headerName: 'Produktivstunden',
        type: 'number',
        align: 'right',
        valueFormatter: (params) =>
            params.value !== null && params.value !== undefined ? formatNumber(params.value, 2) : '',
        hide: false,
    },
    {
        field: 'sollstunden',
        headerName: 'Sollstunden',
        type: 'number',
        align: 'right',
        valueFormatter: (params) =>
            params.value !== null && params.value !== undefined ? formatNumber(params.value, 2) : '',
        hide: false,
    },
    {
        field: 'differenz',
        headerName: 'Differenz',
        type: 'number',
        align: 'right',
        valueFormatter: (params) =>
            params.value !== null && params.value !== undefined ? formatNumber(params.value, 2) : '',
        hide: false,
    },
    {
        field: 'leistung',
        headerName: 'Leistung',
        type: 'number',
        align: 'right',
        valueFormatter: (params) =>
            params.value !== null && params.value !== undefined ? `${formatNumber(params.value, 2)} €` : '',
        hide: false,
    },
    {
        field: 'leistungWachstumVJ',
        headerName: 'VJ',
        type: 'number',
        align: 'right',
        valueFormatter: (params) =>
            params.value !== null && params.value !== undefined ? `${formatNumber(params.value, 2)}%` : '',
        hide: false,
    },
];

// Aggregationsfunktionen für die Haupttabelle
const aggregationFunctions = {
    score: (items) => {
        const totalScore = items.reduce((sum, item) => sum + (item.score || 0), 0);
        const count = items.length;
        return count > 0 ? parseFloat((totalScore / count).toFixed(2)) : 0.0;
    },
    umsatz: (items) => {
        const totalUmsatz = items.reduce((sum, item) => sum + (item.umsatz || 0), 0);
        return totalUmsatz;
    },
    produktivstunden: (items) => {
        const totalProduktivstunden = items.reduce((sum, item) => sum + (item.produktivstunden || 0), 0);
        return parseFloat(totalProduktivstunden.toFixed(2));
    },
    sollstunden: (items) => {
        const totalSollstunden = items.reduce((sum, item) => sum + (item.sollstunden || 0), 0);
        return parseFloat(totalSollstunden.toFixed(2));
    },
    differenz: (items) => {
        const totalDifferenz = items.reduce((sum, item) => sum + (item.differenz || 0), 0);
        return parseFloat(totalDifferenz.toFixed(2));
    },
    leistung: (items) => {
        const totalUmsatz = items.reduce((sum, item) => sum + (item.umsatz || 0), 0);
        const totalProduktivstunden = items.reduce((sum, item) => sum + (item.produktivstunden || 0), 0);
        const leistung =
            totalProduktivstunden > 0 ? parseFloat((totalUmsatz / totalProduktivstunden).toFixed(2)) : 0.0;
        return leistung;
    },
    leistungWachstumVJ: (items) => {
        const validItems = items.filter(
            (item) => item.leistungWachstumVJ !== undefined && item.leistungWachstumVJ !== null
        );
        const totalLeistungWachstumVJ = validItems.reduce(
            (sum, item) => sum + (parseFloat(item.leistungWachstumVJ) || 0),
            0
        );
        const count = validItems.length;
        const durchschnittWachstum = count > 0 ? parseFloat((totalLeistungWachstumVJ / count).toFixed(2)) : 0.0;
        return durchschnittWachstum;
    },
};

const Leistung = () => {
    const { filterState } = useContext(FilterContext);
    const theme = useTheme();
    const [performanceData, setPerformanceData] = useState({
        umsatz: 0,
        kundenanzahl: 0,
        kundenbon: 0,
        leistung: 0,
    });
    const [growthData, setGrowthData] = useState({
        umsatzWachstumJ: '0.00',
        kundenanzahlWachstumJ: '0.00',
        kundenbonWachstum: '0.00',
        leistungsWachstum: '0.00',
    });
    const [chartData, setChartData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [popupOpen, setPopupOpen] = useState(false);
    const [popupChartData, setPopupChartData] = useState([]);
    const [popupTableData, setPopupTableData] = useState([]);
    const [popupLines, setPopupLines] = useState([]);
    const [leistungPopupOpen, setLeistungPopupOpen] = useState(false); // Neuer State für das LeistungPopup
    const [leistungPopupData, setLeistungPopupData] = useState([]); // Daten für das LeistungPopup
    const [leistungPopupColumns, setLeistungPopupColumns] = useState([]); // Spalten für das LeistungPopup
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (filterState.datumWoche) {
            fetchData();
            fetchChartData();
        }
    }, [filterState.datumWoche, filterState.bestehendeFlaeche]); // Aktualisierte Dependency-Liste

    const fetchData = async () => {
        setLoading(true);
        setError(false);
        try {
            await Promise.all([fetchPerformanceData(), fetchTableData()]);
        } catch (err) {
            setError(true);
            console.error('Error fetching data:', err);
        } finally {
            setLoading(false);
        }
    };

    // Funktion zur Aggregation der aktuellen Woche und Berechnung der Wachstumsraten
    const fetchPerformanceData = async () => {
        try {
            const response = await axiosInstance.get('/scorecard_week/performance-woche/', {
                params: {
                    jahrKw: filterState.datumWoche?.value,
                    ...(filterState.bestehendeFlaeche && { vorjahr: true }),
                },
            });

            const aggregatedData = response.data.results.reduce(
                (acc, item) => {
                    acc.umsatz += item.umsatz || 0;
                    acc.kundenanzahl += item.kundenanzahl || 0;
                    acc.produktivstunden += item.produktivstunden || 0;
                    acc.umsatzVJ += item.umsatzVJ || 0;
                    acc.kundenanzahlVJ += item.kundenanzahlVJ || 0;
                    acc.produktivstundenVJ += item.produktivstundenVJ || 0;
                    return acc;
                },
                {
                    umsatz: 0,
                    kundenanzahl: 0,
                    produktivstunden: 0,
                    umsatzVJ: 0,
                    kundenanzahlVJ: 0,
                    produktivstundenVJ: 0,
                }
            );

            // Berechnung von Kundenbon und Leistung
            const kundenbon =
                aggregatedData.kundenanzahl > 0 ? aggregatedData.umsatz / aggregatedData.kundenanzahl : 0;
            const leistung =
                aggregatedData.produktivstunden > 0
                    ? aggregatedData.umsatz / aggregatedData.produktivstunden
                    : 0;

            // Berechnung der Wachstumsraten basierend auf VJ-Feldern
            const umsatzWachstumJ =
                aggregatedData.umsatzVJ > 0
                    ? ((aggregatedData.umsatz / aggregatedData.umsatzVJ - 1) * 100).toFixed(2)
                    : '0.00';
            const kundenanzahlWachstumJ =
                aggregatedData.kundenanzahlVJ > 0
                    ? ((aggregatedData.kundenanzahl / aggregatedData.kundenanzahlVJ - 1) * 100).toFixed(2)
                    : '0.00';
            const kundenbonVJ =
                aggregatedData.kundenanzahlVJ > 0
                    ? aggregatedData.umsatzVJ / aggregatedData.kundenanzahlVJ
                    : 0;
            const kundenbonWachstum =
                kundenbonVJ > 0 ? ((kundenbon / kundenbonVJ - 1) * 100).toFixed(2) : '0.00';
            const leistungVJ =
                aggregatedData.produktivstundenVJ > 0
                    ? aggregatedData.umsatzVJ / aggregatedData.produktivstundenVJ
                    : 0;
            const leistungsWachstum =
                leistungVJ > 0 ? ((leistung / leistungVJ - 1) * 100).toFixed(2) : '0.00';

            setPerformanceData({
                umsatz: formatNumber(aggregatedData.umsatz, 2),
                kundenanzahl: formatNumber(aggregatedData.kundenanzahl, 0),
                kundenbon: formatNumber(kundenbon, 2),
                leistung: formatNumber(leistung, 2),
            });

            setGrowthData({
                umsatzWachstumJ: umsatzWachstumJ,
                kundenanzahlWachstumJ: kundenanzahlWachstumJ,
                kundenbonWachstum: kundenbonWachstum,
                leistungsWachstum: leistungsWachstum,
            });
        } catch (error) {
            console.error('Error fetching performance data:', error);
            throw error;
        }
    };

    // Funktion zur Aggregation der Tabellendaten
    const fetchTableData = async () => {
        try {
            const [performanceResponse, produktivstundenResponse] = await Promise.all([
                axiosInstance.get('/scorecard_week/performance-woche/', {
                    params: {
                        jahrKw: filterState.datumWoche?.value,
                        ...(filterState.bestehendeFlaeche && { vorjahr: true }),
                    },
                }),
                axiosInstance.get('/scorecard_week/performance-produktivstunden/', {
                    params: {
                        jahrKw: filterState.datumWoche?.value,
                        ...(filterState.bestehendeFlaeche && { vorjahr: true }),
                    },
                }),
            ]);

            const performanceResults = performanceResponse.data.results;
            const produktivstundenResults = produktivstundenResponse.data.results;

            const produktivstundenMap = {};
            produktivstundenResults.forEach((item) => {
                produktivstundenMap[item.filiale] = item.produktivstunden6W || 0;
            });

            const formattedData = performanceResults
                .filter((item) => item.kundenanzahl > 0 || item.scoreKundenanzahl > 0)
                .map((item, index) => ({
                    id: index,
                    verkaufsgebiet: item.verkaufsgebiet || 'Unbekannt',
                    filiale: item.filiale,
                    score: item.scoreKundenanzahl || 0,
                    umsatz: item.umsatz || 0,
                    produktivstunden: item.produktivstunden || 0,
                    sollstunden: produktivstundenMap[item.filiale] || 0,
                    differenz:
                        (item.produktivstunden || 0) - (produktivstundenMap[item.filiale] || 0),
                    leistung:
                        item.produktivstunden > 0
                            ? parseFloat((item.umsatz / item.produktivstunden).toFixed(2))
                            : 0,
                    leistungVJ:
                        item.produktivstundenVJ > 0
                            ? parseFloat((item.umsatzVJ / item.produktivstundenVJ).toFixed(2))
                            : 0,
                    leistungWachstumVJ:
                        item.produktivstundenVJ > 0 && item.leistungVJ !== 0
                            ? parseFloat(((item.leistung / item.leistungVJ - 1) * 100).toFixed(2))
                            : 0.0,
                }));

            setTableData(formattedData);
        } catch (error) {
            console.error('Error fetching table data:', error);
            throw error;
        }
    };

    // Funktion zur Aggregation der Chartdaten
    const fetchChartData = async () => {
        try {
            const [year, week] = filterState.datumWoche.value.split('.').map(Number);
            const currentWeek = dayjs().year(year).isoWeek(week);
            const last6Weeks = Array.from({ length: 6 }, (_, i) =>
                currentWeek.subtract(i, 'week').format('YYYY.WW')
            ).reverse();

            const responses = await Promise.all(
                last6Weeks.map((wk) =>
                    axiosInstance.get('/scorecard_week/performance-woche/', {
                        params: {
                            jahrKw: wk,
                            ...(filterState.bestehendeFlaeche && { vorjahr: true }),
                        },
                    })
                )
            );

            const chartDataArr = responses.map((response, index) => {
                const week = last6Weeks[index];
                const aggregatedData = response.data.results.reduce(
                    (acc, item) => {
                        const leistung = item.produktivstunden > 0 ? item.umsatz / item.produktivstunden : 0;
                        const leistungVJ =
                            item.produktivstundenVJ > 0 ? item.umsatzVJ / item.produktivstundenVJ : 0;
                        acc.leistung += leistung;
                        acc.leistungVJ += leistungVJ;
                        acc.count += leistung > 0 ? 1 : 0;
                        acc.countVJ += leistungVJ > 0 ? 1 : 0;
                        return acc;
                    },
                    { leistung: 0, leistungVJ: 0, count: 0, countVJ: 0 }
                );

                return {
                    x: week,
                    Leistung:
                        aggregatedData.count > 0
                            ? parseFloat((aggregatedData.leistung / aggregatedData.count).toFixed(2))
                            : 0,
                    'Leistung Vorjahr':
                        aggregatedData.countVJ > 0
                            ? parseFloat((aggregatedData.leistungVJ / aggregatedData.countVJ).toFixed(2))
                            : 0,
                };
            });

            setChartData(chartDataArr);
        } catch (error) {
            console.error('Error fetching chart data:', error);
        }
    };

    // Handler für das Klicken auf eine Tabellenzeile
    const handleRowClick = async (row) => {
        try {
            const [year, week] = filterState.datumWoche.value.split('.').map(Number);
            const currentWeek = dayjs().year(year).isoWeek(week);

            const weekStart = currentWeek.startOf('isoWeek');
            const weekEnd = currentWeek.endOf('isoWeek');

            const last6Weeks = Array.from({ length: 6 }, (_, i) =>
                currentWeek.subtract(i, 'week').format('YYYY.WW')
            ).reverse();

            const chartResponses = await Promise.all(
                last6Weeks.map((wk) =>
                    axiosInstance.get('/scorecard_week/performance-woche/', {
                        params: {
                            jahrKw: wk,
                            filiale: row.filiale,
                            ...(filterState.bestehendeFlaeche && { vorjahr: true }),
                        },
                    })
                )
            );

            const popupChartDataArr = chartResponses.map((response, index) => {
                const week = last6Weeks[index];
                const aggregatedData = response.data.results.reduce(
                    (acc, item) => {
                        const leistung = item.produktivstunden > 0 ? item.umsatz / item.produktivstunden : 0;
                        const leistungVJ =
                            item.produktivstundenVJ > 0 ? item.umsatzVJ / item.produktivstundenVJ : 0;
                        acc.leistung += leistung;
                        acc.leistungVJ += leistungVJ;
                        acc.count += leistung > 0 ? 1 : 0;
                        acc.countVJ += leistungVJ > 0 ? 1 : 0;
                        return acc;
                    },
                    { leistung: 0, leistungVJ: 0, count: 0, countVJ: 0 }
                );

                return {
                    x: week,
                    Leistung:
                        aggregatedData.count > 0
                            ? parseFloat((aggregatedData.leistung / aggregatedData.count).toFixed(2))
                            : 0,
                    'Leistung Vorjahr':
                        aggregatedData.countVJ > 0
                            ? parseFloat((aggregatedData.leistungVJ / aggregatedData.countVJ).toFixed(2))
                            : 0,
                };
            });

            const tableResponse = await axiosInstance.get('/fact/kundenstatistik/', {
                params: { filiale: row.filiale, jahrKw: filterState.datumWoche?.value },
            });

            const popupTableDataArr = tableResponse.data.results
                .filter((item) => {
                    const itemDate = dayjs(item.datum);
                    return itemDate.isBetween(weekStart, weekEnd, 'day', '[]');
                })
                .sort((a, b) => (dayjs(a.datum).isAfter(dayjs(b.datum)) ? 1 : -1))
                .map((item) => ({
                    id: `${item.filiale}-${item.datum}`,
                    datum: dayjs(item.datum).format('dddd DD.MM.YY'),
                    umsatz: item.umsatz || 0,
                    produktivstunden: item.produktivstunden || 0,
                    leistung:
                        item.produktivstunden > 0
                            ? parseFloat((item.umsatz / item.produktivstunden).toFixed(2))
                            : 0,
                }));

            const formattedPopupTableDataArr = popupTableDataArr.map((item) => ({
                ...item,
                umsatz: `${formatNumber(item.umsatz, 2)} €`,
                produktivstunden: formatNumber(item.produktivstunden, 2),
                leistung: `${formatNumber(item.leistung, 2)} €`,
            }));

            const popupChartLines = [
                { dataKey: 'Leistung', name: 'Leistung', color: theme.palette.primary.main },
                {
                    dataKey: 'Leistung Vorjahr',
                    name: 'Leistung Vorjahr',
                    color: theme.palette.chart.pink,
                },
            ];

            setPopupChartData(popupChartDataArr);
            setPopupTableData(formattedPopupTableDataArr);
            setPopupLines(popupChartLines);
            setPopupOpen(true);
        } catch (error) {
            console.error('Error fetching popup data:', error);
        }
    };

    const closePopup = () => {
        setPopupOpen(false);
    };

    // Funktion zum Öffnen des LeistungPopup
    const openLeistungPopup = async () => {
        try {
            const [year, week] = filterState.datumWoche.value.split('.').map(Number);
            const currentWeek = dayjs().year(year).isoWeek(week);
            const weekStart = currentWeek.startOf('isoWeek');
            const weekEnd = currentWeek.endOf('isoWeek');

            // Ermitteln der Tage der ausgewählten Woche
            const daysInWeek = [];
            for (let i = 0; i < 7; i++) {
                daysInWeek.push(weekStart.add(i, 'day'));
            }

            // Daten für alle Filialen der ausgewählten Woche abrufen
            const response = await axiosInstance.get('/fact/kundenstatistik/', {
                params: {
                    jahrKw: filterState.datumWoche?.value,
                    ...(filterState.bestehendeFlaeche && { vorjahr: true }), // Optionaler Filter, falls benötigt
                },
            });

            const data = response.data.results;

            // Einzigartige Filialen ermitteln
            const filialenSet = new Set();
            data.forEach((item) => {
                filialenSet.add(item.filiale);
            });
            const filialen = Array.from(filialenSet);

            // Tabellendaten initialisieren
            const tableData = filialen
                .map((filiale) => {
                    const rowData = { filiale };
                    let hasValue = false; // Flag, um zu prüfen, ob es mindestens einen Wert gibt
                    daysInWeek.forEach((date) => {
                        const dateString = date.format('YYYY-MM-DD');
                        const item = data.find((d) => {
                            const itemDateString = dayjs(d.datum).format('YYYY-MM-DD');
                            return d.filiale === filiale && itemDateString === dateString;
                        });
                        const leistung =
                            item && item.produktivstunden > 0 ? item.umsatz / item.produktivstunden : null;
                        if (leistung !== null) {
                            hasValue = true;
                        }
                        rowData[dateString] = leistung !== null ? parseFloat(leistung.toFixed(2)) : null;
                    });
                    return hasValue ? rowData : null; // Nur Zeilen zurückgeben, die mindestens einen Wert haben
                })
                .filter((row) => row !== null); // Zeilen ohne Werte entfernen

            // Spalten für die Popup-Tabelle definieren
            const columns = [
                { accessorKey: 'filiale', header: 'Filiale', align: 'left' },
                ...daysInWeek.map((date) => {
                    const dateString = date.format('YYYY-MM-DD');
                    return {
                        accessorKey: dateString,
                        header: date.format('dddd DD.MM.YY'),
                        align: 'right',
                        valueFormatter: (value) =>
                            value !== null && value !== undefined
                                ? `${formatNumber(value, 2)} €`
                                : '',
                    };
                }),
            ];

            setLeistungPopupColumns(columns);
            setLeistungPopupData(tableData);
            setLeistungPopupOpen(true);
        } catch (error) {
            console.error('Error fetching LeistungPopup data:', error);
        }
    };

    const closeLeistungPopup = () => {
        setLeistungPopupOpen(false);
    };

    const mainChartLines = [
        { dataKey: 'Leistung', name: 'Leistung', color: theme.palette.primary.main },
        {
            dataKey: 'Leistung Vorjahr',
            name: 'Leistung Vorjahr',
            color: theme.palette.chart.pink,
        },
    ];

    return (
        <Layout>
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <CircularProgress />
                </Box>
            ) : error ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <Typography color="error">Fehler beim Laden der Daten.</Typography>
                </Box>
            ) : (
                <Grid container justifyContent="flex-end" alignItems="stretch" spacing={2}>
                    {/* Data Cards */}
                    <Grid item xs={12}>
                        <Stack direction="row" alignItems="stretch" spacing={2} sx={{ overflowX: 'auto' }}>
                            {/* Umsatz-Datenkarte (inaktiv) */}
                            <Grid item xs={12} md={6} lg={3} className="db_datacard_not_active">
                                <DataCard
                                    category="Umsatz"
                                    sector="performance"
                                    value={`${performanceData.umsatz} €`}
                                    subHeaders={[`${formatNumber(growthData.umsatzWachstumJ, 2)}% VJ`]}
                                />
                            </Grid>

                            {/* Kundenanzahl-Datenkarte (inaktiv) */}
                            <Grid item xs={12} md={6} lg={3} className="db_datacard_not_active">
                                <DataCard
                                    category="Kundenanzahl"
                                    sector="performance"
                                    value={performanceData.kundenanzahl}
                                    subHeaders={[`${formatNumber(growthData.kundenanzahlWachstumJ, 2)}% VJ`]}
                                />
                            </Grid>

                            {/* Kundenbon-Datenkarte (inaktiv) */}
                            <Grid item xs={12} md={6} lg={3} className="db_datacard_not_active">
                                <DataCard
                                    category="Kundenbon"
                                    sector="performance"
                                    value={`${performanceData.kundenbon} €`}
                                    subHeaders={[`${formatNumber(growthData.kundenbonWachstum, 2)}% VJ`]}
                                />
                            </Grid>

                            {/* Leistung-Datenkarte (aktiv) */}
                            <Grid item xs={12} md={6} lg={3} className="db_datacard_active">
                                <DataCard
                                    category="Leistung"
                                    sector="performance"
                                    value={`${performanceData.leistung} €`}
                                    subHeaders={[`${formatNumber(growthData.leistungsWachstum, 2)}% VJ`]}
                                />
                            </Grid>
                        </Stack>
                    </Grid>

                    {/* Haupt Line Chart */}
                    <Grid item xs={12} mt={3}>
                        <BigChartCardUnternehmenWoche
                            data={chartData}
                            title="Leistung und Leistung Vorjahr der letzten 6 Wochen"
                            lines={mainChartLines}
                            height={400}
                            infoContent="Dies ist eine Info über den Leistungschart."
                        />
                    </Grid>

                    {/* Haupttabelle mit Lupensymbol */}
                    <Grid item xs={12} mt={3}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="h6">Übersicht nach Verkaufsgebieten</Typography>
                            <IconButton onClick={openLeistungPopup} size="large">
                                <SearchIcon />
                            </IconButton>
                        </Box>
                        <VariableTreeMUI
                            data={tableData}
                            columns={mainTableColumns}
                            initialGrouping={['verkaufsgebiet']}
                            groupingHeaders={{ verkaufsgebiet: 'Verkaufsgebiet' }}
                            aggregationFunctions={aggregationFunctions}
                            onRowClick={handleRowClick}
                        />
                    </Grid>

                    {/* Popup für Zeilenklick */}
                    <FilialePopup
                        open={popupOpen}
                        onClose={closePopup}
                        chartData={popupChartData}
                        tableData={popupTableData}
                        tableColumns={[
                            { accessorKey: 'datum', header: 'Datum', align: 'left' },
                            {
                                accessorKey: 'umsatz',
                                header: 'Umsatz',
                                align: 'right',
                                valueFormatter: (value) => value,
                            },
                            {
                                accessorKey: 'produktivstunden',
                                header: 'Produktivstunden',
                                align: 'right',
                                valueFormatter: (value) => value,
                            },
                            {
                                accessorKey: 'leistung',
                                header: 'Leistung',
                                align: 'right',
                                valueFormatter: (value) => value,
                            },
                        ]}
                        chartTitle="Leistungsentwicklung der letzten 6 Wochen"
                        tableTitle="Tagesübersicht"
                        lines={popupLines}
                    />

                    {/* Neues LeistungPopup */}
                    <LeistungPopup
                        open={leistungPopupOpen}
                        onClose={closeLeistungPopup}
                        tableData={leistungPopupData}
                        tableColumns={leistungPopupColumns}
                        title="Leistung der Filialen pro Tag"
                    />
                </Grid>
            )}
        </Layout>
    );
};

export default Leistung;
