import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Stepper, Step, StepLabel, List, ListItem, ListItemText, IconButton, Alert, Divider } from '@mui/material';
import axiosInstance from '../../services/axiosInstance';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

// Dialog-Komponente für Kompetenzfeld
const KompetenzFeldDialog = ({ open, onClose, feldData, setFeldData, handleSaveFeld }) => (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>{feldData.id ? 'Kompetenzfeld bearbeiten' : 'Neues Kompetenzfeld anlegen'}</DialogTitle>
        <DialogContent>
            <TextField
                label="Name des Kompetenzfelds"
                fullWidth
                value={feldData.name}
                onChange={(e) => setFeldData({ ...feldData, name: e.target.value })}
                margin="normal"
                required
            />
            <TextField
                label="Beschreibung"
                fullWidth
                value={feldData.beschreibung}
                onChange={(e) => setFeldData({ ...feldData, beschreibung: e.target.value })}
                margin="normal"
                multiline
                rows={2}
            />
            <TextField
                label="Maximale Skala"
                type="number"
                fullWidth
                value={feldData.skala_max}
                onChange={(e) => setFeldData({ ...feldData, skala_max: e.target.value })}
                margin="normal"
                required
                inputProps={{ min: 1 }}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleSaveFeld} variant="contained" color="primary">
                Speichern
            </Button>
            <Button onClick={onClose} variant="outlined">
                Abbrechen
            </Button>
        </DialogActions>
    </Dialog>
);

const KompetenzMatrixHinzufuegen = ({ open, handleClose }) => {
    const [activeStep, setActiveStep] = useState(0);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [isActive, setIsActive] = useState(true);
    const [matrixId, setMatrixId] = useState(null);
    const [felder, setFelder] = useState([]);
    const [feldData, setFeldData] = useState({ name: '', beschreibung: '', skala_max: 5 });
    const [openFeldDialog, setOpenFeldDialog] = useState(false);
    const [error, setError] = useState(null);
    const [editingFeld, setEditingFeld] = useState(null);

    const steps = ['Name und Beschreibung', 'Kompetenzfelder hinzufügen'];

    const handleNext = async () => {
        if (activeStep === 0) {
            if (!name.trim()) {
                setError('Der Name der Kompetenzmatrix ist erforderlich.');
                return;
            }
            setError(null);
            if (!matrixId) {
                // Kompetenzmatrix erstellen
                try {
                    const response = await axiosInstance.post('/personalentwicklung/kompetenz-matrix/', {
                        name,
                        description,
                        is_active: isActive
                    });
                    setMatrixId(response.data.id);
                } catch (error) {
                    console.error('Fehler beim Hinzufügen der Kompetenzmatrix:', error);
                    setError('Fehler beim Hinzufügen der Kompetenzmatrix.');
                    return;
                }
            } else {
                // Kompetenzmatrix aktualisieren
                try {
                    await axiosInstance.patch(`/personalentwicklung/kompetenz-matrix/${matrixId}/`, {
                        name,
                        description,
                        is_active: isActive
                    });
                } catch (error) {
                    console.error('Fehler beim Aktualisieren der Kompetenzmatrix:', error);
                    setError('Fehler beim Aktualisieren der Kompetenzmatrix.');
                    return;
                }
            }
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSaveAndClose = () => {
        resetForm();
        handleClose();
    };

    const handleAddFeld = () => {
        setFeldData({ name: '', beschreibung: '', skala_max: 5 });
        setEditingFeld(null);
        setOpenFeldDialog(true);
    };

    const handleSaveFeld = async () => {
        if (!feldData.name.trim() || !feldData.skala_max) {
            setError('Name und maximale Skala sind erforderlich.');
            return;
        }
        if (felder.length >= 6 && !editingFeld) {
            setError('Es können maximal 6 Kompetenzfelder hinzugefügt werden.');
            return;
        }
        setError(null);
        if (editingFeld) {
            // Kompetenzfeld aktualisieren
            try {
                const response = await axiosInstance.patch(`/personalentwicklung/kompetenz-feld/${editingFeld.id}/`, {
                    name: feldData.name,
                    beschreibung: feldData.beschreibung,
                    skala_max: feldData.skala_max,
                    matrix: matrixId
                });
                setFelder(felder.map(feld => feld.id === editingFeld.id ? response.data : feld));
                setEditingFeld(null);
                setFeldData({ name: '', beschreibung: '', skala_max: 5 });
                setOpenFeldDialog(false);
            } catch (error) {
                console.error('Fehler beim Aktualisieren des Kompetenzfelds:', error);
                setError('Fehler beim Aktualisieren des Kompetenzfelds.');
            }
        } else {
            // Kompetenzfeld erstellen
            try {
                const response = await axiosInstance.post('/personalentwicklung/kompetenz-feld/', {
                    name: feldData.name,
                    beschreibung: feldData.beschreibung,
                    skala_max: feldData.skala_max,
                    matrix: matrixId
                });
                setFelder([...felder, response.data]);
                setFeldData({ name: '', beschreibung: '', skala_max: 5 });
                setOpenFeldDialog(false);
            } catch (error) {
                console.error('Fehler beim Hinzufügen des Kompetenzfelds:', error);
                setError('Fehler beim Hinzufügen des Kompetenzfelds.');
            }
        }
    };

    const handleEditFeld = (feld) => {
        setEditingFeld(feld);
        setFeldData({ name: feld.name, beschreibung: feld.beschreibung, skala_max: feld.skala_max });
        setOpenFeldDialog(true);
    };

    const handleDeleteFeld = async (feldId) => {
        try {
            await axiosInstance.delete(`/personalentwicklung/kompetenz-feld/${feldId}/`);
            setFelder(felder.filter(feld => feld.id !== feldId));
        } catch (error) {
            console.error('Fehler beim Löschen des Kompetenzfelds:', error);
        }
    };

    const resetForm = () => {
        setActiveStep(0);
        setName('');
        setDescription('');
        setIsActive(true);
        setMatrixId(null);
        setFelder([]);
        setFeldData({ name: '', beschreibung: '', skala_max: 5 });
        setOpenFeldDialog(false);
        setError(null);
        setEditingFeld(null);
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>Neue Kompetenzmatrix hinzufügen</DialogTitle>
                <DialogContent>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <Box sx={{ mt: 3 }}>
                        {error && (
                            <Alert severity="error" sx={{ mb: 2 }}>
                                {error}
                            </Alert>
                        )}
                        {activeStep === 0 && (
                            <Box component="form" sx={{ width: '100%' }}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="name"
                                    label="Name der Kompetenzmatrix"
                                    name="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="description"
                                    label="Beschreibung"
                                    name="description"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    multiline
                                    rows={4}
                                />
                            </Box>
                        )}
                        {activeStep === 1 && (
                            <Box>
                                <Typography variant="h6" gutterBottom>Kompetenzfelder hinzufügen</Typography>
                                <List>
                                    {felder.map((feld) => (
                                        <React.Fragment key={feld.id}>
                                            <ListItem
                                                secondaryAction={
                                                    <>
                                                        <IconButton edge="end" aria-label="edit" onClick={() => handleEditFeld(feld)}>
                                                            <EditIcon />
                                                        </IconButton>
                                                        <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteFeld(feld.id)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </>
                                                }
                                            >
                                                <ListItemText
                                                    primary={`${feld.name} (Maximale Skala: ${feld.skala_max})`}
                                                    secondary={feld.beschreibung}
                                                />
                                            </ListItem>
                                            <Divider />
                                        </React.Fragment>
                                    ))}
                                </List>
                                {felder.length < 6 && (
                                    <Box
                                        sx={{
                                            border: '2px dashed #ddd',
                                            borderRadius: '8px',
                                            padding: '16px',
                                            textAlign: 'center',
                                            cursor: 'pointer',
                                            color: '#888',
                                            mt: 2,
                                            width: '100%',
                                            '&:hover': {
                                                backgroundColor: '#f5f5f5'
                                            },
                                            boxSizing: 'border-box',
                                        }}
                                        onClick={handleAddFeld}
                                    >
                                        <AddIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                                        Neues Kompetenzfeld anlegen
                                    </Box>
                                )}
                                {felder.length >= 6 && (
                                    <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                                        Maximale Anzahl von 6 Kompetenzfeldern erreicht.
                                    </Typography>
                                )}
                            </Box>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        variant="outlined"
                    >
                        Zurück
                    </Button>
                    <Button
                        onClick={handleClose}
                        variant="outlined"
                        color="secondary"
                    >
                        Abbrechen
                    </Button>
                    {activeStep === steps.length - 1 ? (
                        <Button
                            onClick={handleSaveAndClose}
                            variant="contained"
                            color="primary"
                            disabled={felder.length === 0}
                        >
                            Fertigstellen
                        </Button>
                    ) : (
                        <Button
                            onClick={handleNext}
                            variant="contained"
                            color="primary"
                        >
                            Weiter
                        </Button>
                    )}
                </DialogActions>
            </Dialog>

            {/* Kompetenzfeld-Dialog */}
            <KompetenzFeldDialog
                open={openFeldDialog}
                onClose={() => {
                    setOpenFeldDialog(false);
                    setEditingFeld(null);
                    setFeldData({ name: '', beschreibung: '', skala_max: 5 });
                    setError(null);
                }}
                feldData={feldData}
                setFeldData={setFeldData}
                handleSaveFeld={handleSaveFeld}
            />
        </>
    );
};

export default KompetenzMatrixHinzufuegen;
