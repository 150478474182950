// Analyse.jsx
import React, { useState, useEffect } from 'react';
import {
  Grid,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Stack,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  TextField,
  Collapse,
  Box,
  TableSortLabel
} from '@mui/material';
import ChecklistIcon from '@mui/icons-material/Checklist';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import Layout from '../../../components/layout/Qualität/Qualität';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import axiosInstance from '../../../services/axiosInstance';

const Analyse = () => {
  const [todos, setTodos] = useState([]);
  const [loadingTodos, setLoadingTodos] = useState(true);

  // Filterzustände
  const [filterArtikel, setFilterArtikel] = useState('');
  const [filterWarengruppe, setFilterWarengruppe] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [filterBenutzergruppe, setFilterBenutzergruppe] = useState('');

  // Sortierung
  const [sortColumn, setSortColumn] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');

  // Dropdown-Optionen
  const [artikelOptions, setArtikelOptions] = useState([]);
  const [warengruppeOptions, setWarengruppeOptions] = useState([]);
  const [benutzergruppeOptions, setBenutzergruppeOptions] = useState([]);

  // Dialog-Zustände für Ratings
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState([]);
  const [loadingDialog, setLoadingDialog] = useState(false);

  // Karten- und Filterzustände
  const [showErledigt, setShowErledigt] = useState(false);
  const [activeFilter, setActiveFilter] = useState('');

  // Unsaved Changes
  const [unsavedChanges, setUnsavedChanges] = useState({});

  // Dialog-Zustände für Bilder
  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  const fetchTodos = async () => {
    try {
      const response = await axiosInstance.get('/qualitaet/todos/');
      const todosData = Array.isArray(response.data)
        ? response.data
        : response.data.results || [];
      setTodos(todosData);

      const artikelSet = new Set();
      const warengruppeSet = new Set();
      const benutzergruppeSet = new Set();

      todosData.forEach((todo) => {
        if (todo.artikel_name) artikelSet.add(todo.artikel_name);
        if (todo.warengruppe_name) warengruppeSet.add(todo.warengruppe_name);
        if (todo.benutzergruppen && todo.benutzergruppen.length > 0) {
          todo.benutzergruppen.forEach((bg) => benutzergruppeSet.add(bg));
        }
      });

      setArtikelOptions(Array.from(artikelSet));
      setWarengruppeOptions(Array.from(warengruppeSet));
      setBenutzergruppeOptions(Array.from(benutzergruppeSet));
    } catch (error) {
      console.error('Fehler beim Abrufen der To-Dos:', error);
    } finally {
      setLoadingTodos(false);
    }
  };

  const updateTodo = async (todoId, updatedTodo) => {
    try {
      await axiosInstance.put(`/qualitaet/todos/${todoId}/`, updatedTodo);
      console.log(`To-Do mit ID ${todoId} erfolgreich aktualisiert.`);
      fetchTodos();
      setUnsavedChanges((prevChanges) => {
        const newChanges = { ...prevChanges };
        delete newChanges[todoId];
        return newChanges;
      });
    } catch (error) {
      console.error('Fehler beim Aktualisieren des To-Dos:', error.response?.data || error.message);
    }
  };

  useEffect(() => {
    fetchTodos();
  }, []);

  const handleCardClick = (status) => {
    if (activeFilter === status) {
      setActiveFilter('');
    } else {
      setActiveFilter(status);
    }
  };

  const filteredTodos = todos.filter((todo) => {
    return (
      (filterArtikel ? todo.artikel_name === filterArtikel : true) &&
      (filterWarengruppe ? todo.warengruppe_name === filterWarengruppe : true) &&
      (filterStatus ? todo.status === filterStatus : true) &&
      (filterBenutzergruppe
        ? todo.benutzergruppen && todo.benutzergruppen.includes(filterBenutzergruppe)
        : true) &&
      (showErledigt || todo.status !== 'erledigt') &&
      (activeFilter ? todo.status === activeFilter : true)
    );
  });

  const sortedTodos = [...filteredTodos].sort((a, b) => {
    if (sortColumn) {
      const aValue = a[sortColumn];
      const bValue = b[sortColumn];

      if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    }
    return 0;
  });

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const handleOpenDialog = async (todo) => {
    setLoadingDialog(true);
    setDialogOpen(true);

    try {
      const formattedDate = todo.datum.split('T')[0];
      const response = await axiosInstance.get('/qualitaet/ratings/', {
        params: {
          datum: formattedDate,
          artikelnummer: todo.artikelnummer,
        },
      });
      const ratingsData = Array.isArray(response.data)
        ? response.data
        : response.data.results || [];
      setDialogData(ratingsData);
    } catch (error) {
      console.error('Fehler beim Abrufen der Ratings:', error);
    } finally {
      setLoadingDialog(false);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogData([]);
  };

  const groupByFiliale = (data) => {
    const map = new Map();
    data.forEach((rating) => {
      const filiale = rating.filiale || 'Unbekannte Filiale';
      if (!map.has(filiale)) {
        map.set(filiale, []);
      }
      map.get(filiale).push(rating);
    });
    return map;
  };

  const handleImageClickLocal = async (imageId) => {
    try {
      const response = await axiosInstance.get(`/pictures/get-image-url/${imageId}/`);
      setImageUrl(response.data.url);
      setImageDialogOpen(true);
    } catch (error) {
      console.error('Fehler beim Abrufen der Bild-URL:', error);
    }
  };

  const FilialeRow = ({ filiale, ratings }) => {
    const [open, setOpen] = useState(false);
    const checkName = ratings.length > 0 ? ratings[0].check_name : 'N/A';

    return (
      <>
        <TableRow>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell><strong>{filiale}</strong></TableCell>
          <TableCell><strong>{checkName}</strong></TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Checkpoints
                </Typography>
                <Table size="small" aria-label="Checkpoints">
                  <TableHead>
                    <TableRow>
                      <TableCell><strong>Checkpoint</strong></TableCell>
                      <TableCell><strong>Wert</strong></TableCell>
                      <TableCell><strong>Kommentar</strong></TableCell>
                      <TableCell><strong>Meldung durch</strong></TableCell>
                      <TableCell><strong>Bild</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ratings.map((rating) => (
                      <TableRow key={rating.id}>
                        <TableCell>{rating.checkpoint_name}</TableCell>
                        <TableCell>{rating.value}</TableCell>
                        <TableCell>{rating.comment || ''}</TableCell>
                        <TableCell>{rating.user}</TableCell>
                        <TableCell>
                          {rating.image_id ? (
                            <IconButton
                              onClick={() => handleImageClickLocal(rating.image_id)}
                              color="primary"
                            >
                              <PhotoLibraryIcon />
                            </IconButton>
                          ) : (
                            '-'
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  let titleArtikel = '';
  let titleDatum = '';
  if (dialogData.length > 0) {
    titleArtikel = dialogData[0].artikel || '';
    titleDatum = dialogData[0].datum ? new Date(dialogData[0].datum).toLocaleDateString() : '';
  }

  return (
    <Layout>
      <Grid container spacing={2} sx={{ p: 3 }}>
        <Grid item xs={12}>
          <PageHeader
            title="To-Do Übersicht"
            subtitle="Verwalte deine Aufgaben effektiv und behalte den Überblick."
            Icon={ChecklistIcon}
          />
        </Grid>

        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} sm={4}>
            <Card
              sx={{ cursor: 'pointer', backgroundColor: activeFilter === 'offen' ? '#E7EBE5' : 'white' }}
              onClick={() => handleCardClick('offen')}
            >
              <CardContent>
                <Typography variant="h6">Offene To-Dos</Typography>
                <Typography variant="h4">
                  {todos.filter((todo) => todo.status === 'offen').length}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card
              sx={{ cursor: 'pointer', backgroundColor: activeFilter === 'in_bearbeitung' ? '#E7EBE5' : 'white' }}
              onClick={() => handleCardClick('in_bearbeitung')}
            >
              <CardContent>
                <Typography variant="h6">In Bearbeitung</Typography>
                <Typography variant="h4">
                  {todos.filter((todo) => todo.status === 'in_bearbeitung').length}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card
              sx={{ cursor: 'pointer', backgroundColor: activeFilter === 'erledigt' ? '#E7EBE5' : 'white' }}
              onClick={() => handleCardClick('erledigt')}
            >
              <CardContent>
                <Typography variant="h6">Erledigte To-Dos</Typography>
                <Typography variant="h4">
                  {todos.filter((todo) => todo.status === 'erledigt').length}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <FormControlLabel
          control={
            <Checkbox
              checked={showErledigt}
              onChange={(e) => setShowErledigt(e.target.checked)}
            />
          }
          label="Erledigte To-Dos anzeigen"
          sx={{ mb: 2 }}
        />

        <Grid item xs={12}>
          <Stack direction="row" spacing={2} alignItems="center" flexWrap="wrap">
            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel>Artikel</InputLabel>
              <Select
                value={filterArtikel}
                label="Artikel"
                onChange={(e) => setFilterArtikel(e.target.value)}
              >
                <MenuItem value="">
                  <em>Alle</em>
                </MenuItem>
                {artikelOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ minWidth: 150 }}>
              <InputLabel>Warengruppe</InputLabel>
              <Select
                value={filterWarengruppe}
                label="Warengruppe"
                onChange={(e) => setFilterWarengruppe(e.target.value)}
              >
                <MenuItem value="">
                  <em>Alle</em>
                </MenuItem>
                {warengruppeOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ minWidth: 150 }}>
              <InputLabel>Status</InputLabel>
              <Select
                value={filterStatus}
                label="Status"
                onChange={(e) => setFilterStatus(e.target.value)}
              >
                <MenuItem value="">
                  <em>Alle</em>
                </MenuItem>
                <MenuItem value="offen">Offen</MenuItem>
                <MenuItem value="in_bearbeitung">In Bearbeitung</MenuItem>
                <MenuItem value="erledigt">Erledigt</MenuItem>
              </Select>
            </FormControl>

            <FormControl sx={{ minWidth: 180 }}>
              <InputLabel>Benutzergruppe</InputLabel>
              <Select
                value={filterBenutzergruppe}
                label="Benutzergruppe"
                onChange={(e) => setFilterBenutzergruppe(e.target.value)}
              >
                <MenuItem value="">
                  <em>Alle</em>
                </MenuItem>
                {benutzergruppeOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Grid>

        <Grid item xs={12}>
          {loadingTodos ? (
            <CircularProgress />
          ) : sortedTodos.length > 0 ? (
            <TableContainer component={Paper} sx={{ mb: 2 }}>
              <Table aria-label="To-Do Tabelle">
                <TableHead>
                  <TableRow>
                    <TableCell onClick={() => handleSort('datum')} style={{ cursor: 'pointer' }}>
                      <strong>Datum</strong>
                      {sortColumn === 'datum' && <TableSortLabel active direction={sortDirection} />}
                    </TableCell>
                    <TableCell
                      onClick={() => handleSort('artikel_name')}
                      style={{ cursor: 'pointer' }}
                    >
                      <strong>Artikel</strong>
                      {sortColumn === 'artikel_name' && <TableSortLabel active direction={sortDirection} />}
                    </TableCell>
                    <TableCell
                      onClick={() => handleSort('warengruppe_name')}
                      style={{ cursor: 'pointer' }}
                    >
                      <strong>Warengruppe</strong>
                      {sortColumn === 'warengruppe_name' && <TableSortLabel active direction={sortDirection} />}
                    </TableCell>
                    <TableCell>
                      <strong>Anzahl Meldungen</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Benutzergruppen</strong>
                    </TableCell>
                    <TableCell>
                      <strong>To-Do</strong>
                    </TableCell>
                    <TableCell
                      onClick={() => handleSort('status')}
                      style={{ cursor: 'pointer' }}
                    >
                      <strong>Status</strong>
                      {sortColumn === 'status' && <TableSortLabel active direction={sortDirection} />}
                    </TableCell>
                    <TableCell>
                      <strong>Aktionen</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedTodos.map((todo) => {
                    const anzahlMeldungen = todo.anzahl_meldungen || 'N/A';
                    const unsavedTodo = unsavedChanges[todo.id] || {};

                    return (
                      <TableRow key={todo.id}>
                        <TableCell>{new Date(todo.datum).toLocaleDateString()}</TableCell>
                        <TableCell>{todo.artikel_name}</TableCell>
                        <TableCell>{todo.warengruppe_name}</TableCell>
                        <TableCell>{anzahlMeldungen}</TableCell>
                        <TableCell>
                          {todo.benutzergruppen && todo.benutzergruppen.length > 0
                            ? todo.benutzergruppen.join(', ')
                            : 'Keine Benutzergruppen'}
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={unsavedTodo.aufgabe ?? todo.aufgabe ?? ''}
                            onChange={(e) => {
                              setUnsavedChanges({
                                ...unsavedChanges,
                                [todo.id]: {
                                  ...unsavedChanges[todo.id],
                                  aufgabe: e.target.value,
                                },
                              });
                            }}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          <FormControl fullWidth>
                            <Select
                              value={unsavedTodo.status ?? todo.status}
                              onChange={(e) => {
                                setUnsavedChanges({
                                  ...unsavedChanges,
                                  [todo.id]: {
                                    ...unsavedChanges[todo.id],
                                    status: e.target.value,
                                  },
                                });
                              }}
                            >
                              <MenuItem value="offen">Offen</MenuItem>
                              <MenuItem value="in_bearbeitung">In Bearbeitung</MenuItem>
                              <MenuItem value="erledigt">Erledigt</MenuItem>
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          <Stack direction="row" spacing={1}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                const updateData = {
                                  ...todo,
                                  ...unsavedChanges[todo.id],
                                };
                                updateTodo(todo.id, updateData);
                              }}
                            >
                              Speichern
                            </Button>
                            <IconButton
                              color="primary"
                              onClick={() => handleOpenDialog(todo)}
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography variant="body1">Keine To-Dos verfügbar.</Typography>
          )}
        </Grid>
      </Grid>

      {/* Dialog zum Anzeigen der Ratings, gruppiert nach Filiale */}
      <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle>
          {`Ratings für ${titleArtikel} am ${titleDatum}`}
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {loadingDialog ? (
            <CircularProgress />
          ) : dialogData.length > 0 ? (
            <TableContainer component={Paper}>
              <Table aria-label="Ratings Tabelle">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell><strong>Filiale</strong></TableCell>
                    <TableCell><strong>Check</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {[...groupByFiliale(dialogData).entries()].map(([filiale, ratings]) => (
                    <FilialeRow key={filiale} filiale={filiale} ratings={ratings} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography variant="body1">Keine Ratings verfügbar.</Typography>
          )}
        </DialogContent>
      </Dialog>

      {/* Dialog für die Bildanzeige */}
      <Dialog open={imageDialogOpen} onClose={() => setImageDialogOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>
          Bild
          <IconButton
            aria-label="close"
            onClick={() => setImageDialogOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <img src={imageUrl} alt="Checkpoint Bild" style={{ width: '100%' }} />
        </DialogContent>
      </Dialog>
    </Layout>
  );
};

export default Analyse;
