import React, { useState, useEffect } from 'react';
import {
    Dialog, MenuItem, Select, DialogTitle, DialogContent, DialogActions,
    Button, TextField, Box, Typography, List, ListItem, ListItemText,
    IconButton, Collapse, Alert, Checkbox, FormControlLabel
} from '@mui/material';
import axiosInstance from '../../services/axiosInstance';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

const QualitaetKonfiguration = ({ open, handleClose, checkId, checkName }) => {
    const [categoryName, setCategoryName] = useState('');
    const [checkpointName, setCheckpointName] = useState('');
    const [checkpointDescription, setCheckpointDescription] = useState('');
    const [categories, setCategories] = useState([]);
    const [checkpoints, setCheckpoints] = useState([]);
    const [error, setError] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
    const [openCheckpointDialog, setOpenCheckpointDialog] = useState(false);
    const [editingCategory, setEditingCategory] = useState(null);
    const [editingCheckpoint, setEditingCheckpoint] = useState(null);
    const [ratingType, setRatingType] = useState('scale');
    const [allowImageUpload, setAllowImageUpload] = useState(false);

    useEffect(() => {
        if (open) {
            axiosInstance.get(`/qualitaet/categories/`)
                .then(response => {
                    const filteredCategories = response.data.results
                        .filter(category => category.check_related === checkId)
                        .sort((a, b) => a.order - b.order);
                    setCategories(filteredCategories);
                })
                .catch(error => {
                    console.error('Fehler beim Abrufen der Kategorien:', error);
                });

            axiosInstance.get(`/qualitaet/checkpoints/`)
                .then(response => {
                    const sortedCheckpoints = response.data.results.sort((a, b) => a.order - b.order);
                    setCheckpoints(sortedCheckpoints);
                })
                .catch(error => {
                    console.error('Fehler beim Abrufen der Checkpoints:', error);
                });
        }
    }, [open, checkId]);

    const handleSaveCategory = () => {
        if (categoryName.trim() === '') {
            setError('Kategoriename darf nicht leer sein.');
            return;
        }

        if (editingCategory) {
            axiosInstance.patch(`/qualitaet/categories/${editingCategory.id}/`, { name: categoryName })
                .then((response) => {
                    const updatedCategories = categories.map(category => category.id === editingCategory.id ? response.data : category);
                    setCategories(updatedCategories);
                    setEditingCategory(null);
                    setCategoryName('');
                    setOpenCategoryDialog(false);
                })
                .catch((error) => {
                    setError('Fehler beim Bearbeiten der Kategorie.');
                    console.error('Fehler beim Bearbeiten der Kategorie:', error);
                });
        } else {
            const newCategoryOrder = categories.length;
            axiosInstance.post('/qualitaet/categories/', {
                name: categoryName,
                check_related: checkId,
                order: newCategoryOrder,
            })
                .then((response) => {
                    setCategories([...categories, response.data]);
                    setCategoryName('');
                    setError(null);
                    setOpenCategoryDialog(false);
                })
                .catch((error) => {
                    setError('Fehler beim Speichern der Kategorie.');
                    console.error('Fehler beim Speichern der Kategorie:', error);
                });
        }
    };

    const handleSaveCheckpoint = () => {
        if (checkpointName.trim() === '') {
            setError('Überprüfungspunktname darf nicht leer sein.');
            return;
        }

        const checkpointData = {
            name: checkpointName,
            description: checkpointDescription,
            category: selectedCategory,
            rating_type: ratingType,
            allow_image_upload: allowImageUpload,
            order: checkpoints.filter(cp => cp.category === selectedCategory).length,
        };

        if (editingCheckpoint) {
            axiosInstance.patch(`/qualitaet/checkpoints/${editingCheckpoint.id}/`, checkpointData)
                .then((response) => {
                    const updatedCheckpoints = checkpoints.map(checkpoint => checkpoint.id === editingCheckpoint.id ? response.data : checkpoint);
                    setCheckpoints(updatedCheckpoints);
                    setEditingCheckpoint(null);
                    setCheckpointName('');
                    setCheckpointDescription('');
                    setRatingType('scale');
                    setAllowImageUpload(false);
                    setOpenCheckpointDialog(false);
                })
                .catch((error) => {
                    setError('Fehler beim Bearbeiten des Überprüfungspunkts.');
                    console.error('Fehler beim Bearbeiten des Überprüfungspunkts:', error);
                });
        } else {
            axiosInstance.post('/qualitaet/checkpoints/', checkpointData)
                .then((response) => {
                    setCheckpoints([...checkpoints, response.data]);
                    setCheckpointName('');
                    setCheckpointDescription('');
                    setRatingType('scale');
                    setAllowImageUpload(false);
                    setError(null);
                    setOpenCheckpointDialog(false);
                })
                .catch((error) => {
                    setError('Fehler beim Speichern des Überprüfungspunkts.');
                    console.error('Fehler beim Speichern des Überprüfungspunkts:', error);
                });
        }
    };

    const handleDeleteCategory = async (id) => {
        try {
            await axiosInstance.delete(`/qualitaet/categories/${id}/`);
            const updatedCategories = categories.filter(category => category.id !== id);

            // Reihenfolge neu setzen
            const reorderedCategories = updatedCategories.map((category, index) => ({
                ...category,
                order: index,
            }));
            setCategories(reorderedCategories);
            updateCategoryOrder(reorderedCategories);
        } catch (error) {
            console.error('Fehler beim Löschen der Kategorie:', error);
        }
    };

    const handleDeleteCheckpoint = (id) => {
        axiosInstance.delete(`/qualitaet/checkpoints/${id}/`)
            .then(() => {
                setCheckpoints(checkpoints.filter(checkpoint => checkpoint.id !== id));
            })
            .catch(error => {
                console.error('Fehler beim Löschen des Checkpoints:', error);
            });
    };

    const handleEditCategory = (category) => {
        setEditingCategory(category);
        setCategoryName(category.name);
        setOpenCategoryDialog(true);
    };

    const handleEditCheckpoint = (checkpoint) => {
        setEditingCheckpoint(checkpoint);
        setCheckpointName(checkpoint.name);
        setCheckpointDescription(checkpoint.description || '');
        setRatingType(checkpoint.rating_type);
        setAllowImageUpload(checkpoint.allow_image_upload);
        setOpenCheckpointDialog(true);
    };

    const handleAddCheckpointPlaceholderClick = (categoryId) => {
        setSelectedCategory(categoryId);
        setCheckpointName('');
        setCheckpointDescription('');
        setRatingType('scale');
        setAllowImageUpload(false);
        setOpenCheckpointDialog(true);
    };

    // Funktion zum Aktualisieren der Kategorienreihenfolge im Backend
    const updateCategoryOrder = async (categories) => {
        try {
            const updatedCategories = categories.map((category, index) => ({
                id: category.id,
                order: index,
            }));

            await axiosInstance.post('/qualitaet/categories/update_order/', {
                categories: updatedCategories,
            });
        } catch (error) {
            console.error('Fehler beim Aktualisieren der Kategorienreihenfolge:', error);
        }
    };

    // Funktion zum Aktualisieren der Checkpoint-Reihenfolge im Backend
    const updateCheckpointOrder = async (updatedCheckpoints) => {
        try {
            const response = await axiosInstance.post('/qualitaet/checkpoints/update_order/', {
                checkpoints: updatedCheckpoints.map(cp => ({
                    id: cp.id,
                    order: cp.order,
                })),
            });
        } catch (error) {
            console.error('Fehler beim Aktualisieren der Checkpoint-Reihenfolge:', error);
            throw error; 
        }
    };

    const onDragEnd = async (result) => {
        const { source, destination, type } = result;

        if (!destination) return;

        if (type === 'category') {
            if (source.index === destination.index) return;

            const reorderedCategories = Array.from(categories);
            const [movedCategory] = reorderedCategories.splice(source.index, 1);
            reorderedCategories.splice(destination.index, 0, movedCategory);

            setCategories(reorderedCategories);
            await updateCategoryOrder(reorderedCategories);
        } else if (type === 'checkpoint') {
            if (source.droppableId !== destination.droppableId) {
                return;
            }

            const categoryId = parseInt(source.droppableId, 10);
            const categoryCheckpoints = checkpoints.filter(cp => cp.category === categoryId);
            const reorderedCheckpoints = Array.from(categoryCheckpoints);
            const [movedCheckpoint] = reorderedCheckpoints.splice(source.index, 1);
            reorderedCheckpoints.splice(destination.index, 0, movedCheckpoint);

            const updatedReorderedCheckpoints = reorderedCheckpoints.map((cp, idx) => ({
                ...cp,
                order: idx,
            }));

            const otherCheckpoints = checkpoints.filter(cp => cp.category !== categoryId);
            const newCheckpoints = [...otherCheckpoints, ...updatedReorderedCheckpoints];

            setCheckpoints(newCheckpoints);

            try {
                await updateCheckpointOrder(updatedReorderedCheckpoints);
            } catch (error) {
                console.error('Fehler beim Aktualisieren der Checkpoint-Reihenfolge:', error);
            }
        }
    };

    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                    <DialogTitle>Qualitätscheck Konfiguration - {checkName}</DialogTitle>
                    <DialogContent>
                        {error && (
                            <Alert severity="error" sx={{ mb: 2 }}>
                                {error}
                            </Alert>
                        )}
                        <Box component="form" sx={{ mb: 2 }}>
                            {categories.length > 0 ? (
                                <Droppable droppableId="categories" type="category">
                                    {(provided) => (
                                        <List
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            {categories.map((category, index) => (
                                                <Draggable
                                                    key={category.id}
                                                    draggableId={`category-${category.id}`}
                                                    index={index}
                                                >
                                                    {(provided) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <CategoryItem
                                                                category={category}
                                                                index={index}
                                                                selectedCategory={selectedCategory}
                                                                setSelectedCategory={setSelectedCategory}
                                                                checkpoints={checkpoints}
                                                                handleDeleteCategory={handleDeleteCategory}
                                                                handleEditCategory={handleEditCategory}
                                                                handleDeleteCheckpoint={handleDeleteCheckpoint}
                                                                handleEditCheckpoint={handleEditCheckpoint}
                                                                handleAddCheckpointPlaceholderClick={handleAddCheckpointPlaceholderClick}
                                                            />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </List>
                                    )}
                                </Droppable>
                            ) : (
                                <Typography variant="body2" color="textSecondary">Keine Kategorien vorhanden.</Typography>
                            )}
                            <Box
                                sx={{
                                    border: '2px dashed #ddd',
                                    borderRadius: '8px',
                                    padding: '16px',
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                    color: '#888',
                                    mt: 2,
                                    width: '100%',
                                    '&:hover': { backgroundColor: '#f5f5f5' },
                                    boxSizing: 'border-box',
                                }}
                                onClick={() => setOpenCategoryDialog(true)}
                            >
                                <AddIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                                Neue Kategorie anlegen
                            </Box>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="contained">Schließen</Button>
                    </DialogActions>
                </Dialog>

                {/* Dialoge für Kategorie und Checkpoint */}
                <CategoryDialog
                    open={openCategoryDialog}
                    onClose={() => setOpenCategoryDialog(false)}
                    categoryName={categoryName}
                    setCategoryName={setCategoryName}
                    handleSaveCategory={handleSaveCategory}
                />

                <CheckpointDialog
                    open={openCheckpointDialog}
                    onClose={() => setOpenCheckpointDialog(false)}
                    checkpointName={checkpointName}
                    setCheckpointName={setCheckpointName}
                    checkpointDescription={checkpointDescription}
                    setCheckpointDescription={setCheckpointDescription}
                    handleSaveCheckpoint={handleSaveCheckpoint}
                    ratingType={ratingType}
                    setRatingType={setRatingType}
                    allowImageUpload={allowImageUpload}
                    setAllowImageUpload={setAllowImageUpload}
                />
            </DragDropContext>
        </>
    );
};

const CategoryItem = ({
    category, index, selectedCategory, setSelectedCategory, checkpoints,
    handleDeleteCategory, handleEditCategory, handleDeleteCheckpoint,
    handleEditCheckpoint, handleAddCheckpointPlaceholderClick
}) => (
    <Box sx={{ border: '1px solid #ddd', borderRadius: '8px', p: 2, mb: 2, backgroundColor: '#fafafa' }}>
        <ListItem
            secondaryAction={
                <>
                    <IconButton edge="end" aria-label="expand" onClick={() => setSelectedCategory(selectedCategory === category.id ? null : category.id)}>
                        <ExpandMoreIcon />
                    </IconButton>
                    <IconButton edge="end" aria-label="edit" onClick={() => handleEditCategory(category)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteCategory(category.id)}>
                        <DeleteIcon />
                    </IconButton>
                </>
            }
        >
            <ListItemText primary={category.name} />
        </ListItem>
        <Collapse in={selectedCategory === category.id} timeout="auto" unmountOnExit>
            <Box sx={{ mt: 2, mb: 2 }}>
                <CheckpointList
                    categoryId={category.id}
                    checkpoints={checkpoints.filter(checkpoint => checkpoint.category === category.id)}
                    handleDeleteCheckpoint={handleDeleteCheckpoint}
                    handleEditCheckpoint={handleEditCheckpoint}
                />
                <Box
                    sx={{
                        border: '2px dashed #ddd',
                        borderRadius: '8px',
                        padding: '16px',
                        textAlign: 'center',
                        cursor: 'pointer',
                        color: '#888',
                        mt: 2,
                        width: '100%',
                        '&:hover': { backgroundColor: '#f5f5f5' },
                        boxSizing: 'border-box',
                    }}
                    onClick={() => handleAddCheckpointPlaceholderClick(category.id)}
                >
                    <AddIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                    Checkpoint anlegen
                </Box>
            </Box>
        </Collapse>
    </Box>
);

const CheckpointList = ({ categoryId, checkpoints, handleDeleteCheckpoint, handleEditCheckpoint }) => (
    <Droppable droppableId={`${categoryId}`} type="checkpoint">
        {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
                <List sx={{ width: '100%' }}>
                    {checkpoints.length > 0 ? (
                        checkpoints.map((checkpoint, index) => (
                            <Draggable key={checkpoint.id} draggableId={`checkpoint-${checkpoint.id}`} index={index}>
                                {(provided) => (
                                    <Box
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        sx={{
                                            border: '1px solid #ddd',
                                            borderRadius: '8px',
                                            p: 2,
                                            mb: 1,
                                            width: '100%',
                                            boxSizing: 'border-box',
                                            backgroundColor: '#fff',
                                        }}
                                    >
                                        <ListItem
                                            secondaryAction={
                                                <>
                                                    <IconButton edge="end" aria-label="edit" onClick={() => handleEditCheckpoint(checkpoint)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteCheckpoint(checkpoint.id)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </>
                                            }
                                        >
                                            <ListItemText
                                                primary={checkpoint.name}
                                                secondary={
                                                    <>
                                                        <Typography variant="body2">
                                                            Bewertungsart: {checkpoint.rating_type === 'scale' ? 'Skala' : checkpoint.rating_type === 'yesNo' ? 'Ja/Nein' : 'Gewichtskontrolle'}
                                                        </Typography>
                                                        {checkpoint.description && (
                                                            <Typography variant="body2">
                                                                Beschreibung: {checkpoint.description}
                                                            </Typography>
                                                        )}
                                                        <Typography variant="body2">
                                                            Bild-Upload: {checkpoint.allow_image_upload ? 'Erlaubt' : 'Nicht erlaubt'}
                                                        </Typography>
                                                    </>
                                                }
                                            />
                                        </ListItem>
                                    </Box>
                                )}
                            </Draggable>
                        ))
                    ) : (
                        <Typography variant="body2" color="textSecondary">Keine Überprüfungspunkte vorhanden.</Typography>
                    )}
                    {provided.placeholder}
                </List>
            </div>
        )}
    </Droppable>
);

const CategoryDialog = ({ open, onClose, categoryName, setCategoryName, handleSaveCategory }) => (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>{categoryName ? 'Kategorie bearbeiten' : 'Neue Kategorie anlegen'}</DialogTitle>
        <DialogContent>
            <TextField
                label="Kategoriename"
                fullWidth
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
                margin="normal"
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleSaveCategory} variant="contained" color="primary">Speichern</Button>
            <Button onClick={onClose} variant="outlined">Abbrechen</Button>
        </DialogActions>
    </Dialog>
);

const CheckpointDialog = ({
    open, onClose, checkpointName, setCheckpointName, checkpointDescription, setCheckpointDescription,
    handleSaveCheckpoint, ratingType, setRatingType, allowImageUpload, setAllowImageUpload
}) => (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>{checkpointName ? 'Überprüfungspunkt bearbeiten' : 'Neuen Überprüfungspunkt anlegen'}</DialogTitle>
        <DialogContent>
            <TextField
                label="Überprüfungspunkt Name"
                fullWidth
                value={checkpointName}
                onChange={(e) => setCheckpointName(e.target.value)}
                margin="normal"
            />
            <TextField
                label="Beschreibung"
                fullWidth
                value={checkpointDescription}
                onChange={(e) => setCheckpointDescription(e.target.value)}
                margin="normal"
                multiline
                rows={3}
            />
            <Typography variant="body2" sx={{ mt: 2 }}>Bewertungsart</Typography>
            <Select
                value={ratingType}
                onChange={(e) => setRatingType(e.target.value)}
                fullWidth
            >
                <MenuItem value="scale">Skala</MenuItem>
                <MenuItem value="yesNo">Ja/Nein</MenuItem>
                <MenuItem value="gewicht">Gewichtskontrolle</MenuItem>
            </Select>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={allowImageUpload}
                        onChange={(e) => setAllowImageUpload(e.target.checked)}
                    />
                }
                label="Bild-Upload erlauben"
                sx={{ mt: 2 }}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleSaveCheckpoint} variant="contained" color="primary">Speichern</Button>
            <Button onClick={onClose} variant="outlined">Abbrechen</Button>
        </DialogActions>
    </Dialog>
);

export default QualitaetKonfiguration;
