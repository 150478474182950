// TreeViewNavigation.jsx
import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { RichTreeViewPro } from '@mui/x-tree-view-pro/RichTreeViewPro';
import { useTreeViewApiRef } from '@mui/x-tree-view/hooks';
import axiosInstance from '../../../services/axiosInstance';
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey('96e93ece4fd7c9c05c2528e39fa78ebeTz0xMDAyNTAsRT0xNzYwODYxODQwMDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y');

const TreeViewNavigation = ({
    treeData,
    handleItemClick,
    selectedNodeId,
    manualSlug,
    onUpdateOrder,
}) => {
    const [items, setItems] = useState([]);
    const apiRef = useTreeViewApiRef();

    // Initialisiere items, wenn treeData sich ändert
    useEffect(() => {
        console.log('Initial treeData:', treeData);
        setItems(treeData);
    }, [treeData]);

    const handleItemPositionChange = async ({ itemId, oldPosition, newPosition }) => {
        console.log('Item position changed:', itemId, oldPosition, newPosition);

        // Erstelle eine tiefe Kopie der Items
        const updatedItems = JSON.parse(JSON.stringify(items));

        // Hilfsfunktionen zum Entfernen und Einfügen von Items
        const removeItem = (nodes, parentId, index) => {
            if (parentId === null) {
                // Item ist auf Root-Ebene
                const [removedItem] = nodes.splice(index, 1);
                return removedItem;
            } else {
                // Finde den Elternknoten
                const parentNode = findNodeById(nodes, parentId);
                if (parentNode && parentNode.children) {
                    const [removedItem] = parentNode.children.splice(index, 1);
                    return removedItem;
                }
            }
        };

        const insertItem = (nodes, parentId, index, item) => {
            if (parentId === null) {
                // Füge auf Root-Ebene ein
                nodes.splice(index, 0, item);
            } else {
                // Finde den Elternknoten
                const parentNode = findNodeById(nodes, parentId);
                if (parentNode) {
                    if (!parentNode.children) parentNode.children = [];
                    parentNode.children.splice(index, 0, item);
                }
            }
        };

        const findNodeById = (nodes, id) => {
            for (const node of nodes) {
                if (node.id === id) {
                    return node;
                } else if (node.children) {
                    const childResult = findNodeById(node.children, id);
                    if (childResult) return childResult;
                }
            }
            return null;
        };

        // Entferne das Item von der alten Position
        const removedItem = removeItem(updatedItems, oldPosition.parentId, oldPosition.index);

        // Füge das Item an der neuen Position ein
        insertItem(updatedItems, newPosition.parentId, newPosition.index, removedItem);

        // Aktualisiere den Zustand
        setItems(updatedItems);

        // Aktualisiere die Reihenfolge der Kapitel
        if (oldPosition.parentId === null && newPosition.parentId === null) {
            // Verschiebung auf Root-Ebene (Kapitel)
            const chaptersOrder = updatedItems.map((chapter, index) => ({
                slug: chapter.chapterSlug,
                order: Number(index + 1), // Sicherstellen, dass 'order' eine Zahl ist
            }));

            console.log('Neue Kapitelreihenfolge:', chaptersOrder);

            // Aktualisiere jedes Kapitel einzeln
            for (const chapter of chaptersOrder) {
                try {
                    console.log(`Sende PATCH für Kapitel ${chapter.slug} mit order ${chapter.order}`);
                    await axiosInstance.patch(
                        `/wiki/manuals/${manualSlug}/chapters/${chapter.slug}/`,
                        { order: chapter.order },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        }
                    );
                    console.log(`Kapitel mit Slug ${chapter.slug} aktualisiert`);
                } catch (error) {
                    console.error('Fehler beim Aktualisieren des Kapitels:', error.response || error);
                }
            }
        } else if (oldPosition.parentId !== null && newPosition.parentId !== null) {
            // Verschiebung innerhalb eines Kapitels (Einträge)
            const parentChapterNode = findNodeById(updatedItems, newPosition.parentId);
            if (parentChapterNode && parentChapterNode.children) {
                const entriesOrder = parentChapterNode.children.map((entry, index) => ({
                    id: parseInt(entry.id.replace('entry-', '')),
                    order: Number(index + 1), // Sicherstellen, dass 'order' eine Zahl ist
                    slug: entry.entrySlug,
                }));

                console.log(`Neue Eintragsreihenfolge für Kapitel ${parentChapterNode.label}:`, entriesOrder);

                // Aktualisiere jeden Eintrag einzeln
                for (const entry of entriesOrder) {
                    try {
                        console.log(`Sende PATCH für Eintrag ${entry.slug} mit order ${entry.order}`);
                        await axiosInstance.patch(
                            `/wiki/manuals/${manualSlug}/chapters/${parentChapterNode.chapterSlug}/entries/${entry.slug}/`,
                            { order: entry.order },
                            {
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                            }
                        );
                        console.log(`Eintrag mit ID ${entry.id} aktualisiert`);
                    } catch (error) {
                        console.error(
                            `Fehler beim Aktualisieren des Eintrags mit ID ${entry.id}:`,
                            error.response || error
                        );
                    }
                }
            }
        }

        if (onUpdateOrder) {
            onUpdateOrder();
        }
    };

    // Funktion, um Drag-and-Drop einzuschränken (kein Verschieben zwischen Ebenen)
    const isItemReorderingIntoItemAllowed = (originItemId, destinationParentItemId) => {
        const currentParentId = apiRef.current.getItemParentId(originItemId);
        // Erlaube Verschieben nur, wenn der Ziel-Elternknoten derselbe ist wie der aktuelle
        return currentParentId === destinationParentItemId;
    };

    return items.length > 0 ? (
        <Box sx={{ minHeight: 400, borderRight: '1px solid #ccc' }}>
            <RichTreeViewPro
                items={items}
                onItemClick={(event, itemId) => {
                    console.log('Item clicked:', itemId);
                    handleItemClick(event, itemId);
                }}
                selectedItems={selectedNodeId ? [selectedNodeId] : []}
                multiSelect={false}
                itemsReordering
                onItemPositionChange={handleItemPositionChange}
                experimentalFeatures={{
                    indentationAtItemLevel: true,
                    itemsReordering: true,
                }}
                apiRef={apiRef}
                isItemReorderingIntoItemAllowed={isItemReorderingIntoItemAllowed}
            />
        </Box>
    ) : (
        <Typography variant="body2">Keine Daten für das Inhaltsverzeichnis verfügbar.</Typography>
    );
};

export default TreeViewNavigation;
