// src/components/popup/unternehmenWoche/retoure/WarengruppePopup.jsx

import React, { useEffect, useState } from 'react';
import Popup from '../../Popup';
import BigTableCard from '../../../card/BigTableCard';
import axiosInstance from '../../../../services/axiosInstance';
import { Tab, Tabs, Box } from '@mui/material';

// Hilfsfunktion zur Formatierung von Zahlen
const formatNumber = (value, fractionDigits = 2) => {
  if (value === null || value === undefined) return '';
  return value
    .toFixed(fractionDigits)
    .replace('.', ',')
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

const WarengruppePopup = ({ open, onClose, warengruppe, filterState }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [filialeData, setFilialeData] = useState([]);
  const [filialeColumns, setFilialeColumns] = useState([]);
  const [artikelData, setArtikelData] = useState([]);
  const [artikelColumns, setArtikelColumns] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      fetchFilialeData();
      fetchArtikelData();
    }
  }, [open]);

  const fetchFilialeData = async () => {
    try {
      setLoading(true);
      const jahrKw = filterState.datumWoche?.value;

      // API-Aufruf für Filialdaten gefiltert auf die Warengruppe
      const response = await axiosInstance.get('/scorecard_week/ware-warengruppe-woche/', {
        params: {
          jahrKw: jahrKw,
          warengruppe: warengruppe,
          ...(filterState.bestehendeFlaeche && { vorjahr: true }),
        },
      });

      // Verarbeitung der Daten
      const data = response.data.results.map((item, index) => {
        return {
          id: index,
          filiale: item.filiale || 'Unbekannt',
          retourquote:
            item.retourQuote !== null && item.retourQuote !== undefined
              ? parseFloat((item.retourQuote * 100).toFixed(2))
              : 0,
          retourquoteA:
            item.retourQuoteA !== null && item.retourQuoteA !== undefined
              ? parseFloat((item.retourQuoteA * 100).toFixed(2))
              : 0,
          retourquoteBC:
            item.retourQuoteBC !== null && item.retourQuoteBC !== undefined
              ? parseFloat((item.retourQuoteBC * 100).toFixed(2))
              : 0,
        };
      });

      // Filtern der Filialen mit Retourquote > 0
      const filteredData = data.filter((item) => item.retourquote > 0);

      // Formatieren der Retourquoten für die Anzeige
      const formattedData = filteredData.map((item) => ({
        ...item,
        retourquote: `${formatNumber(item.retourquote)}%`,
        retourquoteA: `${formatNumber(item.retourquoteA)}%`,
        retourquoteBC: `${formatNumber(item.retourquoteBC)}%`,
      }));

      const columns = [
        { accessorKey: 'filiale', header: 'Filiale', align: 'left' },
        { accessorKey: 'retourquote', header: 'Retourquote', align: 'right' },
        { accessorKey: 'retourquoteA', header: 'Retourquote A', align: 'right' },
        { accessorKey: 'retourquoteBC', header: 'Retourquote BC', align: 'right' },
      ];

      setFilialeData(formattedData);
      setFilialeColumns(columns);
      setLoading(false);
    } catch (error) {
      console.error('Fehler beim Abrufen der Filialdaten:', error);
      setLoading(false);
    }
  };

  const fetchArtikelData = async () => {
    try {
      setLoading(true);
      const jahrKw = filterState.datumWoche?.value;

      // API-Aufruf für Artikeldaten gefiltert auf die Warengruppe
      const response = await axiosInstance.get('/scorecard_week_article/ware-artikel-woche/', {
        params: {
          jahrKw: jahrKw,
          warengruppe: warengruppe,
          ...(filterState.bestehendeFlaeche && { vorjahr: true }),
        },
      });

      // Aggregation der Daten pro Artikel
      const aggregatedData = {};
      response.data.results.forEach((item) => {
        const artikel = item.artikelnummer.artikel || 'Unbekannt';
        if (!aggregatedData[artikel]) {
          aggregatedData[artikel] = {
            retourwert: 0,
            lieferwert: 0,
            korrekturwert: 0,
          };
        }
        aggregatedData[artikel].retourwert += item.retourwert || 0;
        aggregatedData[artikel].lieferwert += item.lieferwert || 0;
        aggregatedData[artikel].korrekturwert += item.korrekturwert || 0;
      });

      // Berechnung der Retourquote pro Artikel
      const data = Object.keys(aggregatedData).map((artikel, index) => {
        const agg = aggregatedData[artikel];
        const denominator = agg.lieferwert + agg.korrekturwert;
        const retourquote = denominator > 0 ? (agg.retourwert / denominator) * 100 : 0;

        return {
          id: index,
          artikel: artikel,
          retourquote: `${formatNumber(retourquote)}%`,
          retourwert: formatNumber(agg.retourwert),
          lieferwert: formatNumber(agg.lieferwert),
          korrekturwert: formatNumber(agg.korrekturwert),
        };
      });

      const columns = [
        { accessorKey: 'artikel', header: 'Artikel', align: 'left' },
        { accessorKey: 'retourquote', header: 'Retourquote', align: 'right' },
        { accessorKey: 'retourwert', header: 'Retourwert', align: 'right' },
        { accessorKey: 'lieferwert', header: 'Lieferwert', align: 'right' },
        { accessorKey: 'korrekturwert', header: 'Korrekturwert', align: 'right' },
      ];

      setArtikelData(data);
      setArtikelColumns(columns);
      setLoading(false);
    } catch (error) {
      console.error('Fehler beim Abrufen der Artikeldaten:', error);
      setLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Popup
      open={open}
      title={`Details für Warengruppe ${warengruppe}`}
      onClose={onClose}
      fullWidth
      maxWidth={false}
    >
      <Tabs value={tabIndex} onChange={handleTabChange}>
        <Tab label="Filiale" />
        <Tab label="Artikel" />
      </Tabs>
      <Box sx={{ mt: 2 }}>
        {tabIndex === 0 && (
          <BigTableCard data={filialeData} columns={filialeColumns} loading={loading} />
        )}
        {tabIndex === 1 && (
          <BigTableCard data={artikelData} columns={artikelColumns} loading={loading} />
        )}
      </Box>
    </Popup>
  );
};

export default WarengruppePopup;
