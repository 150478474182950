import {useDrop} from "react-dnd";
import {Box, Typography} from "@mui/material";
import React, {useContext, useEffect, useState} from 'react';
import axiosInstance from "../../services/axiosInstance";
import {InfoBarContext} from "../../provider/InfoBar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const DropZone = ({onDrop, artikel, handleRemove, sortimentType}) => {
  // console.log("DropZone received sortimentType:", sortimentType);

  // const [, forceUpdate] = useState({});
  const [imageUrl, setImageUrl] = useState('');
  const {showError} = useContext(InfoBarContext);

  // Generate consistent fake data for Wareneinsatz and Retoure durchschnitt
  const fakeWareneinsatz = artikel
      ? (10 + (artikel.artikelnummer % 10) + (artikel.umsatz % 123) / 100).toFixed(2)
      : null; // Between 10% and 20%

  // Determine if drag-and-drop functionality should be enabled
  const [{isOver, canDrop}, drop] = onDrop ? useDrop(() => ({
    accept: 'text',
    drop: (item) => {
      const modifiedItem = {...item, artikelData: {...item.artikelData, sortiment_type: sortimentType || 'wechsel'}};
      onDrop(modifiedItem);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  }), [sortimentType]) : [{}, null];

  const handleRemoveClick = () => {
    console.log("removing artikel", artikel)
    handleRemove(artikel.warengruppennummer, artikel);
  }

  // const changeSortimentType = () => {
  //   if (artikel) {
  //     artikel.sortiment_type = artikel.sortiment_type === 'standard' ? 'wechsel' : 'standard';
  //     forceUpdate({});
  //   }
  // };

  const loadImage = async (image_id) => {
    try {
      const response = await axiosInstance.get('/pictures/get-image-url/' + image_id + '/');
      setImageUrl(response.data.url);
    } catch (error) {
      console.error('Error fetching image URL:', error);
      showError('Fehler beim Laden des Bildes');
    }
  };

  // loads in artikel image, if artikel has an image_id
  useEffect(() => {
    if (artikel && artikel.image_id) {
      console.log("loading image for ", artikel.artikelbezeichnung)
      loadImage(artikel.image_id);
    }
  }, [artikel]);

  const displayedText = artikel ? artikel.artikelbezeichnung.replace(/^(\d+\s+)?(\w)(.*)/, (_, prefix, firstChar, rest) => {
    const processedText = (prefix || '') + firstChar.toUpperCase() + rest.toLowerCase();
    return processedText.replace(/(?<![\u00C0-\u017F])\b\w/g, c => c.toUpperCase());
  }) : '';

  return (
      <Box
          sx={{
            display: 'flex',
            minWidth: 250,
            width: 250,
            minHeight: 230,
            height: 400,
            padding: 1,
            borderRadius: 3,
            cursor: canDrop ? 'pointer' : 'default',
            border: isOver ? '2px dashed #ccc' :  'none',
            transition: 'background-color 0.2s ease-in-out',
            backgroundColor: 'background.paper',
            overflow: 'hidden',
            position: 'relative'
          }}
      >
        {artikel && (
            <>
              <Box
                  sx={{
                    width: '5px',
                    backgroundColor: artikel.sortiment_type === 'standard' ? 'primary.light' : 'background.mint',
                    borderTopLeftRadius: 4,
                    borderBottomLeftRadius: 4,
                    mr: 1,
                  }}
              />
              {/*<Box*/}
              {/*    onClick={changeSortimentType}*/}
              {/*    sx={{*/}
              {/*      position: 'absolute',*/}
              {/*      top: 8,*/}
              {/*      right: 12,*/}
              {/*      width: 16,*/}
              {/*      height: 16,*/}
              {/*      borderRadius: '50%',*/}
              {/*      cursor: 'pointer',*/}
              {/*      background: 'linear-gradient(90deg, blue 48%, transparent 48%, transparent 52%, orange 52%)',*/}
              {/*      '&:hover': {*/}
              {/*        opacity: 0.8*/}
              {/*      }*/}
              {/*    }}*/}
              {/*/>*/}
            </>
        )}
        <Box
            ref={onDrop ? drop : null}
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'stretch',
            }}
        >
          {artikel ? (
              <Box sx={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%'}}>
                <IconButton
                    aria-label="close"
                    onClick={handleRemoveClick}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                    }}
                >
                  <CloseIcon/>
                </IconButton>
                <Box sx={{ height: '200px', minHeight: '200px', width: '100%', mb: 1, border: '1px solid #e0e0e0', borderRadius: 2 }}>
                    {(artikel?.image_id && imageUrl) && (
                        <img
                            src={imageUrl}
                            alt={`Bild von ${artikel.artikelbezeichnung}`}
                            loading="eager"
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              borderRadius: 8,
                            }}
                        />
                    ) }
                </Box>
                <Typography
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: 'vertical',
                      wordBreak: 'break-word',
                      hyphens: 'auto',
                      WebkitHyphens: 'auto',
                      msHyphens: 'auto',
                      // marginBottom: 1,
                      height: '3.5em',
                      mt: 1,
                    }}
                >
                  {displayedText}
                </Typography>
                <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: 0.5}}>
                  <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                    <Typography sx={{color: 'text.main', fontSize: '0.8rem'}}>Wareneinsatz:</Typography>
                    <Typography sx={{color: 'text.main', fontSize: '0.8rem'}}>{fakeWareneinsatz}%</Typography>
                  </Box>
                  <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                    <Typography sx={{color: 'text.main', fontSize: '0.8rem'}}>Retoure:</Typography>
                    <Typography sx={{color: 'text.main', fontSize: '0.8rem'}}>{artikel.retoure}%</Typography>
                  </Box>
                  <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                    <Typography sx={{color: 'text.secondary', fontSize: '0.8rem'}}>Geschmack:</Typography>
                    <Typography sx={{color: 'text.secondary', fontSize: '0.8rem'}}>{artikel.geschmack ? artikel.geschmack : "-"}</Typography>
                  </Box>
                  <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                    <Typography sx={{color: 'text.secondary', fontSize: '0.8rem'}}>Teig:</Typography>
                    <Typography sx={{color: 'text.secondary', fontSize: '0.8rem'}}>{artikel.teig ? artikel.teig : "-"}</Typography>
                  </Box>
                  <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                    <Typography sx={{color: 'success.main', fontSize: '0.8rem'}}>Umsatz:</Typography>
                    <Typography sx={{color: 'success.main', fontSize: '0.8rem'}}>{artikel.umsatz} €</Typography>
                  </Box>
                </Box>
              </Box>
          ) : (
              <Typography color="text.secondary"></Typography>
          )}
        </Box>
      </Box>
  );
};

export default DropZone;
