// CreateChapterDialog.jsx
import React, { useContext } from 'react';
import { Dialog, DialogTitle, DialogContent, Box, TextField, Button } from '@mui/material';
import {InfoBarContext} from "../../../provider/InfoBar";

const CreateChapterDialog = ({
    open,
    onClose,
    newChapter,
    setNewChapter,
    handleCreateChapter,
    selectedManual,
}) => {
    const { showError } = useContext(InfoBarContext);

    const handleSubmit = () => {
        if (!newChapter.title) {
            showError('Bitte geben Sie einen Titel für das Kapitel ein.');
            return;
        }
        handleCreateChapter();
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: { backgroundColor: '#f5f9f7' },
            }}
        >
            <DialogTitle>
                Neues Kapitel erstellen in "{selectedManual ? selectedManual.title : ''}"
            </DialogTitle>
            <DialogContent>
                <Box component="form" sx={{ mt: 2 }}>
                    <TextField
                        required
                        fullWidth
                        label="Titel"
                        value={newChapter.title}
                        onChange={(e) => setNewChapter({ ...newChapter, title: e.target.value })}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        fullWidth
                        label="Beschreibung"
                        value={newChapter.description}
                        onChange={(e) => setNewChapter({ ...newChapter, description: e.target.value })}
                        multiline
                        rows={4}
                        sx={{ mb: 2 }}
                    />
                    <Box sx={{ textAlign: 'right' }}>
                        <Button onClick={onClose} sx={{ mr: 1 }}>
                            Abbrechen
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleSubmit}>
                            Erstellen
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default CreateChapterDialog;
