import React, { useContext, useEffect, useState } from 'react';
import { Grid, Alert } from '@mui/material';
import Layout from '../../../components/layout/Scorecards/Woche/Filiale/FilialeMonat';
import axiosInstance from '../../../services/axiosInstance';
import { FilterContext } from "../../../provider/FilterProvider";
import ComparisonCard from '../../../components/card/ComparisonCardFilialeWoche';
import BigChartCard from "../../../components/charts/scorecardsWoche/LineChartWoche";
import VariableTreeMUI from '../../../components/table/VariableTableTreeMUI'; 
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import TAPerformanceUmsatz from "../../../components/scorecards/tiefenanalyse/TAPerformanceUmsatz";
import { getAlertSeverity } from "../../../components/utility/AlertPopup";

dayjs.extend(weekOfYear);

// Deutsche Formatierung mit Intl.NumberFormat
function formatGermanNumber(value, decimals = 0) {
  if (value === null || value === undefined || isNaN(value)) return 'N/A';
  const number = parseFloat(value);
  if (isNaN(number)) return 'N/A';
  const formatter = new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  });
  return formatter.format(number); 
}

// Nur Felder mit 'wachstum' als Prozente interpretieren
const isPercentageField = (fieldName) =>
  fieldName.toLowerCase().includes('wachstum');

// Formatierung für Comparison Cards
function formatComparisonValue(value, fieldName) {
  if (value === 'N/A' || value === undefined || value === null) return 'N/A';
  const num = parseFloat(value);
  if (isNaN(num)) return value;

  // Kundenbon & Leistung immer 2 Nachkommastellen
  if (fieldName.toLowerCase().includes('kundenbon') || fieldName.toLowerCase().includes('leistung')) {
    return formatGermanNumber(num, 2);
  }

  // Wachstumsfelder sind in Prozent
  if (isPercentageField(fieldName)) {
    return `${num.toFixed(2)} %`;
  }

  // Umsatz oder Kundenanzahl in Comparison Cards
  // Rundung auf ganze Zahl für Kundenanzahl, Umsatz hier ohne explizite Vorgaben?  
  // Wir behalten Umsatz in Cards ohne Nachkommastellen (ganze Zahl):
  if (fieldName === 'umsatz' || fieldName === 'kundenanzahl') {
    return formatGermanNumber(num, 0);
  }

  // Sonstige Felder direkt zurückgeben
  return value;
}

const Umsatz = () => {
  const { filterState } = useContext(FilterContext);
  const [comparisonCardData, setComparisonCardData] = useState([]);
  const [lineChartData, setLineChartData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [mergedObj, setMergedObj] = useState({});
  
  const activeCategory = 'Umsatz';

  useEffect(() => {
    if (filterState.filiale && filterState.datumWoche) {
      fetchData();
    }
  }, [filterState.filiale, filterState.datumWoche]);

  const fetchData = async () => {
    try {
      const [year, week] = filterState.datumWoche.value.split('.').map(Number);
      const startDate = dayjs().year(year).week(week).startOf('week').subtract(5, 'week');
      const endDate = dayjs().year(year).week(week).endOf('week');

            const response = await axiosInstance.get('scorecards_month/performance-monat/', {
                params: {
                    startdatum: startDate.format('YYYY-MM-DD'),
                    enddatum: endDate.format('YYYY-MM-DD'),
                    filiale: filterState.filiale?.label
                }
            });

      const responseUnternehmen = await axiosInstance.get('scorecard_week/performance-unternehmen-woche/', {
        params: {
          startdatum: startDate.format('YYYY-MM-DD'),
          enddatum: endDate.format('YYYY-MM-DD')
        }
      });

      // fact/kundenstatistik Daten
      const responseKundenstatistik = await axiosInstance.get('fact/kundenstatistik', {
        params: {
          startdatum: startDate.format('YYYY-MM-DD'),
          enddatum: endDate.format('YYYY-MM-DD'),
          filiale: filterState.filiale?.value
        }
      });

      const baseObj = response.data.results.slice(-1)[0] || {};
      const additionalObj = responseUnternehmen.data.results.slice(-1)[0] || {};
      let merged = { ...baseObj, ...additionalObj };

      // Linienchart-Daten
      const branchLineChartData = response.data.results
        .map(item => ({
          x: `${dayjs(item.datum).year()}.${String(dayjs(item.datum).week()).padStart(2,'0')}`,
          y: item.umsatz,
          label: 'Filiale'
        }))
        .sort((a, b) => {
          const [ayear, aweek] = a.x.split('.').map(Number);
          const [byear, bweek] = b.x.split('.').map(Number);
          return ayear !== byear ? ayear - byear : aweek - bweek;
        });

      const companyLineChartData = responseUnternehmen.data.results
        .map(item => ({
          x: `${dayjs(item.datum).year()}.${String(dayjs(item.datum).week()).padStart(2,'0')}`,
          y: item.umsatz,
          label: 'Unternehmen'
        }))
        .sort((a, b) => {
          const [ayear, aweek] = a.x.split('.').map(Number);
          const [byear, bweek] = b.x.split('.').map(Number);
          return ayear !== byear ? ayear - byear : aweek - bweek;
        });

      const combinedLineChartData = [...branchLineChartData, ...companyLineChartData];
      setLineChartData(combinedLineChartData);

      // fact/kundenstatistik Daten verarbeiten
      const factData = responseKundenstatistik.data.results || [];
      const factTableData = factData.map(item => {
        const jahr = dayjs(item.datum).year();
        const kw = String(dayjs(item.datum).week()).padStart(2,'0');
        const umsatz = item.umsatz || 0;
        const kundenanzahl = item.kundenanzahl || 0;
        const produktivstunden = item.produktivstunden || 1;
        const kundenbon = kundenanzahl > 0 ? (umsatz / kundenanzahl) : 0;
        const leistung = produktivstunden > 0 ? (umsatz / produktivstunden) : 0;

        return {
          jahrKw: `${jahr}.${kw}`,
          datum: item.datum, // wird später formatiert
          umsatz,
          kundenanzahl,
          kundenbon,
          leistung,
          produktivstunden
        };
      });
      setTableData(factTableData);

      // Aggregation für aktuell gewählte Woche für Comparison Cards
      const selectedWeekData = factTableData.filter(row => {
        const [y, w] = row.jahrKw.split('.').map(Number);
        return y === year && w === week;
      });

      const sumUmsatz = selectedWeekData.reduce((sum, i) => sum + i.umsatz, 0);
      const sumKunden = selectedWeekData.reduce((sum, i) => sum + i.kundenanzahl, 0);
      const sumProd = selectedWeekData.reduce((sum, i) => sum + (i.produktivstunden || 0), 0);

      const aggKundenbon = sumKunden > 0 ? (sumUmsatz / sumKunden) : 0;
      const aggLeistung = sumProd > 0 ? (sumUmsatz / sumProd) : 0;

      merged.umsatz = sumUmsatz;
      merged.kundenanzahl = sumKunden;
      merged.kundenbon = aggKundenbon;
      merged.leistung = aggLeistung;

      setMergedObj(merged);
      setComparisonCardData([merged]);

    } catch (error) {
      console.error('Ein Fehler ist beim Abrufen der Daten aufgetreten:', error);
    }
  };

  // Spalten für die Tabelle
  const columns = [
    {
      field: 'datum',
      headerName: 'Datum',
      valueFormatter: ({ value }) => {
        // Datum auf DD.MM.YY formatieren
        return dayjs(value).format('DD.MM.YY');
      }
    },
    {
      field: 'umsatz',
      headerName: 'Umsatz',
      valueFormatter: ({value}) => formatGermanNumber(value, 2)
    },
    {
      field: 'kundenanzahl',
      headerName: 'Kunden',
      valueFormatter: ({value}) => formatGermanNumber(value, 0)
    },
    {
      field: 'kundenbon',
      headerName: 'Kundenbon',
      valueFormatter: ({value}) => {
        // 2 Nachkommastellen mit Komma
        return formatGermanNumber(value, 2);
      }
    },
    {
      field: 'leistung',
      headerName: 'Leistung',
      valueFormatter: ({value}) => formatGermanNumber(value, 2)
    },
  ];

  const initialGrouping = ['jahrKw'];

  const aggregationFunctions = {
    umsatz: (items) => {
      const sum = items.reduce((sum, i) => sum + (i.umsatz || 0), 0);
      return sum; // numeric
    },
    kundenanzahl: (items) => {
      const sum = items.reduce((sum, i) => sum + (i.kundenanzahl || 0), 0);
      return sum; // numeric
    },
    kundenbon: (items) => {
      const sumUmsatz = items.reduce((sum, i) => sum + (i.umsatz || 0), 0);
      const sumKunden = items.reduce((sum, i) => sum + (i.kundenanzahl || 0), 0);
      const val = sumKunden > 0 ? (sumUmsatz / sumKunden) : 0;
      return val; // Numeric. valueFormatter formatiert später.
    },
    leistung: (items) => {
      const sumUmsatz = items.reduce((sum, i) => sum + (i.umsatz || 0), 0);
      const sumProd = items.reduce((sum, i) => sum + (i.produktivstunden || 0), 0);
      const val = sumProd > 0 ? (sumUmsatz / sumProd) : 0;
      return val; // Numeric
    },
    datum: () => null 
  };

  const groupingHeaders = {
    jahrKw: 'Jahr.KW'
  };

  return (
    <Layout>
      <Grid container justifyContent="flex-start" alignItems="stretch" spacing={2}>
        {/* Linke Seite */}
        <Grid item xs={12} md={6} sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
          <Alert severity={getAlertSeverity(mergedObj["scoreUmsatz"])}>
            {mergedObj["feedbackUmsatz"] ? mergedObj["feedbackUmsatz"] : 'Keine Rückmeldung verfügbar'}
          </Alert>

          <ComparisonCard
            zeitraum="Umsatz"
            data={mergedObj}
            fields={[
              { label: 'Umsatz', valueField: 'umsatz' },
              { label: 'Score VJ', valueField: 'scoreUmsatzWachstumJ' },
              { label: 'VJ', valueField: 'umsatzWachstumJ' },
              { label: 'Score Ø\n6W', valueField: 'scoreUmsatzWachstum6W' },
              { label: 'Ø 6W %', valueField: 'umsatzWachstum6W' }
            ]}
            formatValue={formatComparisonValue}
          />

          <ComparisonCard
            zeitraum="Kundenanzahl"
            data={mergedObj}
            fields={[
              { label: 'Kundenanzahl', valueField: 'kundenanzahl' },
              { label: 'Score VJ', valueField: 'scoreKundenanzahlWachstumJ' },
              { label: 'VJ', valueField: 'kundenanzahlWachstumJ' },
              { label: 'Score Ø\n6W', valueField: 'scoreKundenanzahlWachstum6W' },
              { label: 'Ø 6W %', valueField: 'kundenanzahlWachstum6W' }
            ]}
            formatValue={formatComparisonValue}
          />

          <ComparisonCard
            zeitraum="Kundenbon"
            data={mergedObj}
            fields={[
              { label: 'Kundenbon', valueField: 'kundenbon' },
              { label: 'Score VJ', valueField: 'scoreKundenbonWachstumJ' },
              { label: 'VJ', valueField: 'kundenbonVJ' },
              { label: 'VJ %', valueField: 'kundenbonWachstumJ' }
            ]}
            formatValue={formatComparisonValue}
          />

          <ComparisonCard
            zeitraum="Leistung"
            data={mergedObj}
            fields={[
              { label: 'Leistung', valueField: 'leistung' },
              { label: 'Score VJ', valueField: 'scoreLeistungWachstumJ' },
              { label: 'VJ', valueField: 'leistungVJ' },
              { label: 'VJ %', valueField: 'leistungWachstumJ' }
            ]}
            formatValue={formatComparisonValue}
          />
        </Grid>

        {/* Rechte Seite: Diagramm */}
        <Grid item xs={12} md={6} mt={2}>
          <BigChartCard
            data={lineChartData}
            title="Umsatzverlauf (letzte 6 Kalenderwochen)"
            infoContent={
              <>
                Umsatzverlauf - Informationen<br /><br />
                Dieses Diagramm zeigt die Umsatzentwicklung der letzten 6 Kalenderwochen.<br />
                Die ausgewählte Woche im Filter ist die letzte dargestellte Woche.<br /><br />
                <strong>Filiale:</strong> Dunklere Linie<br />
                <strong>Unternehmen:</strong> Hellere Linie<br /><br />
                Die X-Achse zeigt Jahr.KW, z.B. 2023.23 = Jahr 2023, KW 23.
              </>
            }
          />
        </Grid>

        {/* Tabelle darunter */}
        <Grid item xs={12} mt={2}>
          <VariableTreeMUI
            data={tableData}
            columns={columns}
            initialGrouping={initialGrouping}
            aggregationFunctions={aggregationFunctions}
            title="Detailtabelle (Jahr.KW > Tage)"
            groupingHeaders={groupingHeaders}
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Umsatz;
