import {useEffect, useState, useMemo} from "react";
import {Box, Paper, TextField, Typography} from "@mui/material";
import DraggableText from "./DraggableText";

const ArtikelListe = ({artikel, handleOpenArtikelDetail}) => {
  const [displayedArtikel, setDisplayedArtikel] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const filteredAndSortedArtikel = useMemo(() => {
    const filteredArtikel = artikel.filter(item =>
        item.artikelbezeichnung.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return [...filteredArtikel].sort((a, b) => b.umsatz - a.umsatz);
  }, [artikel, searchTerm]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDisplayedArtikel(filteredAndSortedArtikel);
    }, 150);

    return () => clearTimeout(timeoutId);
  }, [filteredAndSortedArtikel]);

  return (
      <Paper elevation={3} sx={{padding: 2, margin: 0, width: '100%'}}>
        {/*<Typography variant="h6" gutterBottom>Artikel</Typography>*/}
        <TextField
            variant="outlined"
            placeholder="Artikel suchen"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{mb: 1, width: '300px', '& .MuiOutlinedInput-root': {height: '32px'}}}
            size="small"
        />
        {displayedArtikel.length > 0 ? (
            <Box sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
              gap: 0.5,
              alignItems: 'start',
            }}>
              {displayedArtikel.map((item, index) => (
                  <DraggableText
                      key={`${item.artikelbezeichnung || item}-${index}`}
                      artikel={item}
                      handleOpenArtikelDetail={handleOpenArtikelDetail}
                  />
              ))}
            </Box>
        ) : (
            <Typography variant="body2" align="center">
              keine Artikel gefunden
            </Typography>
        )}
      </Paper>
  );
};

export default ArtikelListe;
