import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {useContext, useCallback, useState} from 'react';
import {InfoBarContext} from "../../provider/InfoBar";

const ThekeSettingsPopup = ({ open, onClose, warengruppen, sectionCounts, onSectionCountChange, selectedArtikel }) => {
    const {showError} = useContext(InfoBarContext);
    const [localCounts, setLocalCounts] = useState(sectionCounts);
    
    const handleSectionCountChange = useCallback((warengruppennummer, value) => {
        if (value > 42) {
            showError('Die maximale Anzahl an Sektionen ist 42');
            return;
        }

        const currentWarengruppeArtikel = selectedArtikel.find(wg => wg.id === warengruppennummer);
        const currentArticleCount = currentWarengruppeArtikel?.artikel?.length || 0;

        if (value < currentArticleCount) {
            showError(`Die minimale Anzahl an Sektionen für diese Warengruppe ist ${currentArticleCount}`);
            return;
        }

        setLocalCounts(prev => ({...prev, [warengruppennummer]: value}));
        const timeoutId = setTimeout(() => {
            onSectionCountChange(warengruppennummer, value);
        }, 500);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [onSectionCountChange, selectedArtikel, showError]);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Settings</DialogTitle>
            <DialogContent>
                {warengruppen.map((wg) => (
                    <TextField
                        key={wg.warengruppennummer}
                        label={wg.warengruppe}
                        type="number"
                        value={localCounts[wg.warengruppennummer] || 9}
                        onChange={(e) => handleSectionCountChange(wg.warengruppennummer, parseInt(e.target.value, 10))}
                        fullWidth
                        margin="normal"
                    />
                ))}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ThekeSettingsPopup;
