// src/components/charts/RechartsLineChart.jsx

import React from 'react';
import { Box, useTheme, useMediaQuery, Typography } from '@mui/material';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from 'recharts';

const RechartsLineChart = ({ data = [], title = '', height = 400, lines = [] }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

    console.log('RechartsLineChart Props:', { data, lines });

    // Bestimmen aller Y-Werte basierend auf den definierten Linien
    const allYValues = lines.flatMap(line => data.map(item => item[line.dataKey]));
    const minYValue = Math.min(...allYValues);
    const maxYValue = Math.max(...allYValues);
    const adjustedMinYValue = minYValue > 0 
        ? Math.max(0, minYValue - (maxYValue - minYValue) * 0.2) 
        : minYValue - (maxYValue - minYValue) * 0.2;
    const adjustedMaxYValue = maxYValue + (maxYValue - minYValue) * 0.2;

    const getFontSize = () => {
        if (isSmallScreen) {
            return 8;
        } else if (isMediumScreen) {
            return 10;
        } else {
            return 14;
        }
    };

    const formatNumberWithCommas = (number, withDecimals = false) => {
        return number.toLocaleString('de-DE', {
            maximumFractionDigits: withDecimals ? 2 : 0,
        });
    };

    return (
        <Box>
            
            <ResponsiveContainer width="100%" height={height}>
                <LineChart
                    data={data}
                    margin={{ top: 10, right: 10, bottom: 10, left: 20 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="x"
                        tick={{ fontSize: getFontSize() }}
                        padding={{ left: 10, right: 10 }}
                    />
                    <YAxis
                        tick={{ fontSize: getFontSize() }}
                        width={isSmallScreen ? 50 : 70}
                        tickFormatter={(value) => formatNumberWithCommas(value)}
                        domain={[adjustedMinYValue, adjustedMaxYValue]} 
                    />
                    <Tooltip
                        contentStyle={{ fontSize: getFontSize() }}
                        formatter={(value) => formatNumberWithCommas(value, true)}
                    />
                    <Legend 
                        wrapperStyle={{ fontSize: getFontSize() }}
                        iconType="circle"
                        iconSize={getFontSize()}
                    />
                    {lines.map(line => (
                        <Line
                            key={line.dataKey}
                            type="monotone"
                            dataKey={line.dataKey}
                            name={line.name || line.dataKey}
                            stroke={line.color || theme.palette.primary.main}
                            activeDot={{ r: 8 }}
                        />
                    ))}
                </LineChart>
            </ResponsiveContainer>
        </Box>
    );
};

export default RechartsLineChart;
