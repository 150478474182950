import React, { useContext, useEffect, useState } from 'react';
import { Grid, Stack, Typography, IconButton } from '@mui/material';
import Layout from '../../../components/layout/Scorecards/FilialeMonat';
import axiosInstance from '../../../services/axiosInstance';
import dayjs from 'dayjs';
import { FilterContext } from "../../../provider/FilterProvider";
import ScorecardsNotes from "../../../components/formular/Scorecards/ScorecardsNotes";
import BigBarChartHorizontalCard from '../../../components/card/BigBarChartHorizontalCard';
import DataCard from '../../../components/card/DataCard';
import BigTableCard from '../../../components/card/BigTableCard';
import ProgressBarList from '../../../components/card/ProgressBarList';
import WarengruppeDetailsPopup from '../../../components/popup/WarengruppeDetailsPopup'; // WarengruppeDetailsPopup Komponente importieren
import TAWareRetoureBC from '../../../components/scorecards/tiefenanalyse/TAWareRetoureBC'; // Import the new popup component
import SearchIcon from '@mui/icons-material/QueryStats';
import { tausenderTrennung } from "../../../utils/mathStuff";



const RetoureBC = () => {
    const [warengruppeTableData, setWarengruppeTableData] = useState([]);
    const [positiveDiffTableData, setPositiveDiffTableData] = useState([]);
    const [negativeDiffTableData, setNegativeDiffTableData] = useState([]);
    const [warengruppeColumns, setWarengruppeColumns] = useState([]);
    const [dataCardData, setDataCardData] = useState([]);
    const [taWareRetoureBCOpen, setTaWareRetoureBCOpen] = useState(false); // State for new Popup
    const [comparisonCardData, setComparisonCardData] = useState([]);
    const [chartData, setChartData] = useState({ labels: [], data: [] });
    const [selectedWarengruppe, setSelectedWarengruppe] = useState(null);  // Zustand für die ausgewählte Warengruppe
    const [isPopupOpen, setIsPopupOpen] = useState(false);  // Zustand für das Öffnen des Popups
    const { filterState } = useContext(FilterContext);
    const activeCategory = 'RetoureBC';

    useEffect(() => {
        if (filterState.filiale && filterState.datum) {
            fetchData();
            fetchWarengruppeData();
            fetchTableData();
        }
    }, [filterState.filiale, filterState.datum]);

    const fetchData = async () => {
        try {
            const endDate = dayjs(`${filterState.datum?.value}-01`, 'YYYY-MM-DD');
            const startDate = endDate.subtract(6, 'month');

            const response = await axiosInstance.get('scorecards_month/ware-filiale-monat/', {
                params: {
                    startdatum: startDate.format('YYYY-MM-DD'),
                    enddatum: endDate.format('YYYY-MM-DD'),
                    filiale: filterState.filiale?.label
                }
            });

            const dataCardData = response.data.results
                .slice(-4)
                .map(item => ({
                    x: item.datum,
                    Wachstum: item.scoreProduktwachstumFiliale,
                    Verfügbarkeit: item.scoreVerfügbarkeitFiliale,
                    RetoureA: item.scoreSollRetoureFiliale,
                    RetoureBC: item.scoreRetourQuoteFilialeBC,
                }));

            const comparisonCardData = response.data.results.slice(-1);

            setComparisonCardData(comparisonCardData);
            setDataCardData(dataCardData);
        } catch (error) {
            console.error('Ein Fehler ist beim Abrufen der Daten aufgetreten:', error);
        }
    };

    const fetchWarengruppeData = async () => {
        try {
            const response = await axiosInstance.get('scorecard_month_article/ware-artikel-monat/', {
                params: {
                    filiale: filterState.filiale?.label,
                    jahrMonat: filterState.datum?.value,
                    ABC: 'B,C'   // Nur Artikel mit ABCValue "B" berücksichtigen
                }
            });
    
            // Aggregiere Daten nach Warengruppe
            const warengruppeAggregation = response.data.results.reduce((acc, item) => {
                // Verwende den Warengruppen-Namen anstelle der Nummer
                const warengruppe = item.warengruppe || 'Unbekannte Warengruppe';
    
                if (!acc[warengruppe]) {
                    acc[warengruppe] = {
                        Verkaufsmenge: 0,
                        Retourmenge: 0,
                        Retourwert: 0,
                        Lieferwert: 0,
                        Korrekturwert: 0
                    };
                }
    
                acc[warengruppe].Verkaufsmenge += item.verkaufsmenge || 0;
                acc[warengruppe].Retourmenge += item.retourmenge || 0;
                acc[warengruppe].Retourwert += item.retourwert || 0;
                acc[warengruppe].Lieferwert += item.lieferwert || 0;
                acc[warengruppe].Korrekturwert += item.korrekturwert || 0;
    
                return acc;
            }, {});
    
            const warengruppeData = Object.keys(warengruppeAggregation).map(warengruppe => {
                const verkaufsmenge = Math.round(warengruppeAggregation[warengruppe].Verkaufsmenge);
                const retourmenge = Math.round(warengruppeAggregation[warengruppe].Retourmenge);
                const retourwert = Math.round(warengruppeAggregation[warengruppe].Retourwert);
                const lieferwert = Math.round(warengruppeAggregation[warengruppe].Lieferwert);
                const korrekturwert = Math.round(warengruppeAggregation[warengruppe].Korrekturwert);
                const retourQuote = (lieferwert + retourwert) > 0
                    ? (retourwert / (lieferwert + korrekturwert)) * 100
                    : 0;
            
                return {
                    Warengruppe: warengruppe,
                    Verkaufsmenge: tausenderTrennung(verkaufsmenge,0),  // Rundet auf 0 Dezimalstellen
                    Retourmenge: tausenderTrennung(retourmenge, 0),  // Rundet auf 0 Dezimalstellen
                    Retourquote: tausenderTrennung(retourQuote, 2) + "%"  // Beibehaltung von 2 Dezimalstellen für die Quote
                };
            });
                
    
            setWarengruppeTableData(warengruppeData);
    
            const warengruppeColumns = [
                { id: 'Warengruppe', accessorKey: 'Warengruppe', header: 'Warengruppe' },
                { id: 'Verkaufsmenge', accessorKey: 'Verkaufsmenge', header: 'Verkaufsmenge Stk.' },
                { id: 'Retourmenge', accessorKey: 'Retourmenge', header: 'Retoure Stk.' },
                { id: 'Retourquote', accessorKey: 'Retourquote', header: 'Retourquote %' }
            ];
    
            setWarengruppeColumns(warengruppeColumns);
    
            // Daten für die Bar-Chart vorbereiten
            const chartLabels = warengruppeData.map(item => item.Warengruppe);
            const chartValues = warengruppeData.map(item => parseFloat(item.Retourquote));
    
            setChartData({ labels: chartLabels, data: chartValues });
    
        } catch (error) {
            console.error('Ein Fehler ist beim Abrufen der Warengruppendaten aufgetreten:', error);
        }
    };

    const fetchTableData = async () => {
        try {
            const response = await axiosInstance.get('scorecard_month_article/ware-artikel-monat/', {
                params: {
                    filiale: filterState.filiale?.label,
                    jahrMonat: filterState.datum?.value
                }
            });

            const positiveData = [];
            const negativeData = [];

            response.data.results.forEach(item => {
                const diff = item.retoureSoll - item.retourmengeTag;
                const rowData = {
                    Artikel: item.artikelnummer.artikel,
RetoureSoll: item.retoureSoll !== null && item.retoureSoll !== undefined ? parseFloat(item.retoureSoll).toFixed(2) : '0.00',
RetourmengeTag: item.retourmengeTag !== null && item.retourmengeTag !== undefined ? parseFloat(item.retourmengeTag).toFixed(2) : '0.00',
Differenz: diff !== null && diff !== undefined ? parseFloat(diff).toFixed(2) : '0.00',
ScoreRetoureArtikelA: item.scoreRetoureArtikelA !== null && item.scoreRetoureArtikelA !== undefined ? parseFloat(item.scoreRetoureArtikelA).toFixed(2) : '0.00',
retourQuoteAbsA: item.retourQuoteAbsA !== null && item.retourQuoteAbsA !== undefined ? parseFloat(item.retourQuoteAbsA).toFixed(2) : '0.00'


                };

                // Daten nach positiver und negativer Differenz aufteilen
                if (diff > 0) {
                    positiveData.push(rowData);
                } else if (diff < 0) {
                    negativeData.push(rowData);
                }
            });

            // Sortiere nach Score in absteigender Reihenfolge
            positiveData.sort((a, b) => parseFloat(b.ScoreRetoureArtikelA) - parseFloat(a.ScoreRetoureArtikelA));
            negativeData.sort((a, b) => parseFloat(b.ScoreRetoureArtikelA) - parseFloat(a.ScoreRetoureArtikelA));

            setPositiveDiffTableData(positiveData);
            setNegativeDiffTableData(negativeData);

        } catch (error) {
            console.error('Ein Fehler ist beim Abrufen der Tabellendaten aufgetreten:', error);
        }
    };

    const handleRowClick = (row) => {
        setSelectedWarengruppe(row.Warengruppe);
        setIsPopupOpen(true);
    };

    const handleTaWareRetoureBCOpen = () => {
        setTaWareRetoureBCOpen(true);
    };

    const handleTaWareRetoureBCClose = () => {
        setTaWareRetoureBCOpen(false);
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
        setSelectedWarengruppe(null);
    };

    return (
        <Layout>
            <Grid container justifyContent="flex-end" alignItems="stretch">
                <Grid item xs={12}>
                    <Stack direction="row" alignItems="stretch" spacing={2} sx={{ overflowX: 'auto' }}>
                        {['Wachstum', 'Verfügbarkeit', 'RetoureA', 'RetoureBC'].map((category, index) => (
                            <Grid
                                item
                                xs={12}
                                md={6}
                                lg={3}
                                key={index}
                                className={activeCategory === category ? 'db_datacard_active' : 'db_datacard_not_active'}
                            >
                                <DataCard
                                    data={dataCardData}
                                    category={category}
                                    sector="ware"
                                    categoryData={
                                        activeCategory === category
                                            ? comparisonCardData.length > 0 ? comparisonCardData[0].bedarf : null
                                            : ''
                                    }
                                    specialTitle={
                                        category === "RetoureA"
                                            ? "Retoure A" : category === "RetoureBC"
                                                ? "Retoure B/C" : null
                                    }
                                />
                            </Grid>
                        ))}
                    </Stack>
                </Grid>
    
                <Grid container item xs={12} mt={3} spacing ={2}>
                                <Grid item xs={12} md={6}>
                                    <BigTableCard

                                        title={
                                            <Stack direction="row" alignItems="center" justifyContent="space-between">
                                                <Typography variant="h6">Warengruppen Übersicht</Typography>
                                                    <IconButton
                                                        color="primary"
                                                        onClick={handleTaWareRetoureBCOpen} // Open the new Popup
                                                        sx={{ fontSize: 30 }}
                                                    >
                                                        <SearchIcon fontSize="inherit" />
                                                    </IconButton>
                                            </Stack>
                                        }
                                        data={warengruppeTableData}
                                        columns={warengruppeColumns}
                                        onRowClick={handleRowClick}  // Klick-Handler hinzugefügt
                                    />
                                </Grid>

                                <Grid item xs={12} md={6} >
                                <BigBarChartHorizontalCard
                                    title="Retourquote nach Warengruppe"
                                    labels={chartData.labels}
                                    data={chartData.data}
                                />
                                </Grid>
                </Grid>

            </Grid>

            <ScorecardsNotes category="Ware" />

            {/* Popup-Komponente für Warengruppe-Details */}
            <WarengruppeDetailsPopup
                open={isPopupOpen}
                onClose={handleClosePopup}
                warengruppe={selectedWarengruppe}
                filiale={filterState.filiale?.label}
                jahrMonat={filterState.datum?.value}
                ABC='B,C'
            />


            <TAWareRetoureBC
                open={taWareRetoureBCOpen} // Make sure this state changes correctly
                handleClose={handleTaWareRetoureBCClose}
                filiale={filterState.filiale?.label}
                jahrMonat={filterState.datum?.value}
            />
        </Layout>
    );
    
    
    
};

export default RetoureBC;
