// EntryEditor.jsx
import React, {useContext} from 'react';
import {Box, Button, Divider, List, ListItem, ListItemText, Typography, IconButton} from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import PictureUploadButton from "../../pictures/PictureUploadButton";
import {InfoBarContext} from "../../../provider/InfoBar";
import FilePreviewCard from "../../pictures/FilePreviewCard";
import EditIcon from '@mui/icons-material/Edit';

const EntryEditor = ({
  selectedChapter,
  selectedEntry,
  entries,
  handleItemClick,
  afterImageUploadFunction,
  mediaList,
  handleImageClick,
  afterPictureDeleteFunction,
  editedContent,
  handleContentChange,
  handleSaveEntry,
  isSettings,
  onEditChapter,
  onEditEntry,
}) => {
  const {showError} = useContext(InfoBarContext);

  const handleSave = () => {
    if (!editedContent) {
      showError('Bitte geben Sie einen Inhalt ein.');
      return;
    }
    handleSaveEntry();
  };

  return (
      <>
        {selectedChapter && !selectedEntry && (
            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h5" gutterBottom sx={{ flexGrow: 1 }}>
                  {selectedChapter.title || selectedChapter.label}
                </Typography>
                {isSettings && (
                  <IconButton onClick={() => onEditChapter(selectedChapter)}>
                    <EditIcon />
                  </IconButton>
                )}
              </Box>
              <List>
                {entries
                    .filter((entry) => entry.chapterSlug === selectedChapter.chapterSlug)
                    .map((entry) => (
                        <React.Fragment key={entry.id}>
                          <ListItem
                              button
                              onClick={() => handleItemClick(null, `entry-${entry.id}`)}
                              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                          >
                            <ListItemText primary={entry.title}/>
                            {isSettings && (
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  onEditEntry(entry);
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                            )}
                          </ListItem>
                          <Divider/>
                        </React.Fragment>
                    ))}
              </List>
            </Box>
        )}
        {selectedEntry ? (
            <Box>
              {mediaList.length > 0 && (
                  <Box
                      sx={{
                        display: 'flex',
                        overflowX: 'auto',
                        mb: 2,
                      }}
                  >
                    {mediaList.map((media) => (
                        <FilePreviewCard
                            key={media.id}
                            file={{
                              url: media.url,
                              type: "application/pdf",
                              id: media.image_id,
                            }}
                            handleFileClick={handleImageClick}
                            afterPictureDeleteFunction={afterPictureDeleteFunction}
                        />
                    ))}
                  </Box>
              )}
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2, mb: 2 }}>
                <Typography variant="h5" gutterBottom>
                  {selectedEntry.title}
                </Typography>
                {isSettings && (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      onEditEntry(selectedEntry);
                    }}
                    sx={{ ml: 2 }}
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </Box>

              {/* PictureUploadButton jetzt unterhalb von Titel/Media, vor dem Editor */}
              {isSettings && (
                <Box sx={{ mb: 2 }}>
                  <PictureUploadButton
                      title={selectedEntry.title}
                      afterImageUploadFunction={afterImageUploadFunction}
                      iconButton={false}
                  />
                  <input type="file" id={`file-upload`} style={{ display: 'none' }} />
                </Box>
              )}

              {isSettings ? (
                  <>
                    <ReactQuill
                        value={editedContent}
                        onChange={handleContentChange}
                        modules={{
                          toolbar: [
                            [{header: [1, 2, false]}],
                            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                            [{list: 'ordered'}, {list: 'bullet'}],
                            ['link', 'image'],
                            ['clean'],
                          ],
                        }}
                    />
                    <Box sx={{textAlign: 'right', mt: 2}}>
                      <Button variant="contained" color="primary" onClick={handleSave}>
                        Änderungen speichern
                      </Button>
                    </Box>
                  </>
              ) : (
                  <Box
                      dangerouslySetInnerHTML={{__html: selectedEntry.content}}
                      sx={{typography: 'body1'}}
                  />
              )}
            </Box>
        ) : null}
        {!selectedChapter && !selectedEntry && (
            <Typography variant="body2">
              Bitte wählen Sie ein Kapitel oder einen Eintrag aus dem Inhaltsverzeichnis aus.
            </Typography>
        )}
      </>
  );
};

export default EntryEditor;
