// src/components/popup/unternehmenWoche/FilialePopup.jsx

import React from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import BigChartCardUnternehmenWoche from '../../card/scorecardsmonat/BigChartCardUnternehmenWoche'; // Pfad anpassen
import BigTableCard from '../../card/BigTableCard'; // Pfad zu BigTableCard anpassen

const FilialePopup = ({
    open,
    onClose,
    chartData,
    tableData,
    tableColumns,
    chartTitle,
    tableTitle,
    lines, // Für die Linienkonfiguration des Charts
}) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
            <DialogTitle>
                {chartTitle}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                {/* Diagramm */}
                <BigChartCardUnternehmenWoche
                    data={chartData}
                    title={chartTitle}
                    height={400}
                    lines={lines} // Linienkonfiguration
                />
                
                {/* Tabelle */}
                <Typography variant="h6" gutterBottom mt={4}>
                    {tableTitle}
                </Typography>
                <BigTableCard
                    data={tableData}
                    columns={tableColumns}
                    // Weitere benötigte Props hinzufügen
                />
            </DialogContent>
        </Dialog>
    );
};

export default FilialePopup;
