import React, { useContext, useEffect, useState, useMemo } from 'react';
import {
  Grid,
  Stack,
  Card,
  CardHeader,
  Typography,
  CardContent,
} from '@mui/material';
import Layout from '../../../components/layout/Scorecards/Unternehmen/UnternehmenMonat';
import DataCard from '../../../components/card/scorecardsmonat/DataCardMonatPage';
import VariableTreeMUI from '../../../components/table/VariableTableTreeMUI';
import { FilterContext } from '../../../provider/FilterProvider';
import axiosInstance from '../../../services/axiosInstance';
import dayjs from 'dayjs';
import { calculatePerformanceData } from '../../../utils/calculationPerformance';
import BigChartCard from '../../../components/card/scorecardsmonat/BigChartCardUnternehmen';
import BigChartCardSecondYAxis from '../../../components/card/scorecardsmonat/BigChartCardSecondYAxis';
import BigPieChartCardTotal from '../../../components/card/scorecardsmonat/BigPieChartCardTotal';

const Leistung = () => {
  const { filterState } = useContext(FilterContext);
  const [allFilialenData, setAllFilialenData] = useState([]);
  const [filialtypData, setFilialtypData] = useState([]);

  const [performanceData, setPerformanceData] = useState({
    umsatz: 0,
    umsatzVJ: 0,
    umsatzAbweichungVJ: 0,
    kundenanzahl: 0,
    kundenanzahlVJ: 0,
    kundenanzahlAbweichungVJ: 0,
    produktivstunden: 0,
    produktivstundenVJ: 0,
    produktivstundenAbweichungVJ: 0,
    leistung: 0,
    leistungVJ: 0,
    leistungAbweichungVJ: 0,
    kundenbon: 0,
    kundenbonVJ: 0,
    kundenbonAbweichungVJ: 0,
    chartData: [],
    verkaufsgebieteResult: [],
    filialenMitLeistung: 0,
    filialtypenResult: [],
    durchschnittLeistung: 0,
  });
  const [tableData, setTableData] = useState([]);
  const [performanceLastMonths, setPerformanceLastMonths] = useState([]);

  useEffect(() => {
    if (filterState.datum) {
      fetchPerformanceData();
      fetchPerformanceDataLastMonths();
    }
  }, [filterState]);

  const formatNumberWithCommas = (number) => {
    if (typeof number !== 'number' || isNaN(number)) {
      return '0,00';
    }
    return number.toLocaleString('de-DE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const formatPercentage = (value) => {
    if (typeof value !== 'number' || isNaN(value)) {
      return '0,00%';
    }
    return `${value.toLocaleString('de-DE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}%`;
  };

  const fetchScoreLeistungData = async () => {
    try {
      const response = await axiosInstance.get(
        '/scorecards_month/performance-monat/',
        {
          params: {
            jahrMonat: filterState.datum?.value,
          },
        }
      );
      return response.data.results;
    } catch (error) {
      console.error('Error fetching scoreLeistung data:', error);
      return [];
    }
  };

  const fetchPerformanceData = async () => {
    try {
      // Abrufen der Daten von der API
      const response = await axiosInstance.get(
        '/scorecards_month/performance-monat/',
        {
          params: {
            jahrMonat: filterState.datum?.value,
          },
        }
      );

      // Abrufen der scoreLeistung-Daten
      const scoreLeistungData = await fetchScoreLeistungData();

      // Setzen der gesamten Filialdaten in den State
      setAllFilialenData(response.data.results);

      // Berechnungen durchführen
      const calculatedData = calculatePerformanceData(response.data.results);

      // Anzahl der Filialen mit Leistung > 0 berechnen
      const filialenMitLeistung = response.data.results.filter(
        (filiale) => filiale.leistung > 0
      ).length;

      // Durchschnittliche Leistung berechnen
      const durchschnittLeistung =
        filialenMitLeistung > 0 ? calculatedData.leistung : 0;

      // Setzen der Performance-Daten
      setPerformanceData({
        umsatz: calculatedData.umsatzSumme,
        umsatzVJ: calculatedData.umsatzVJSumme,
        umsatzAbweichungVJ: calculatedData.umsatzAbweichungVJ,
        kundenanzahl: calculatedData.kundenanzahlSumme,
        kundenanzahlVJ: calculatedData.kundenanzahlVJSumme,
        kundenanzahlAbweichungVJ: calculatedData.kundenanzahlAbweichungVJ,
        produktivstunden: calculatedData.produktivstundenSumme,
        produktivstundenVJ: calculatedData.produktivstundenVJSumme,
        produktivstundenAbweichungVJ:
          calculatedData.produktivstundenAbweichungVJ,
        leistung: calculatedData.leistung,
        leistungVJ: calculatedData.leistungVJ,
        leistungAbweichungVJ:
          calculatedData.leistungVJ > 0
            ? ((calculatedData.leistung - calculatedData.leistungVJ) /
                calculatedData.leistungVJ) *
              100
            : 0,
        kundenbon: calculatedData.kundenbon,
        kundenbonVJ: calculatedData.kundenbonVJ,
        kundenbonAbweichungVJ:
          calculatedData.kundenbonVJ > 0
            ? ((calculatedData.kundenbon - calculatedData.kundenbonVJ) /
                calculatedData.kundenbonVJ) *
              100
            : 0,
        chartData: response.data.results.map((item) => ({
          leistung: item.leistung || 0,
        })),
        verkaufsgebieteResult: calculatedData.verkaufsgebieteResult,
        filialenMitLeistung,
        durchschnittLeistung,
        filialtypenResult: calculatedData.filialtypenResult,
      });

      // Tabelle formatieren
      const formattedTableData = response.data.results
        .filter((filiale) => filiale.leistung > 0)
        .map((filiale) => {
          const scoreData =
            scoreLeistungData.find(
              (item) => item.filialnummer === filiale.filialnummer
            ) || {};
          return {
            filialnummer: filiale.filialnummer,
            filiale: `${filiale.filialnummer} ${filiale.filiale}`, // Filialnummer + Filialname
            verkaufsgebiet: filiale.verkaufsgebiet,
            filialtyp: filiale.filialtyp,
            leistung: filiale.leistung || 0,
            leistungVJ: filiale.leistungVJ || 0,
            leistungAbweichungVJ:
              filiale.leistungVJ > 0
                ? ((filiale.leistung - filiale.leistungVJ) /
                    filiale.leistungVJ) *
                  100
                : 0,
            scoreLeistung: scoreData.scoreLeistung || 0, // Hier wird scoreLeistung hinzugefügt
          };
        });

      setTableData(formattedTableData);
    } catch (error) {
      console.error('Error fetching performance data:', error);
    }
  };

  const getLastMonths = (currentMonth) => {
    const lastMonths = [];
    const formattedMonth = dayjs(currentMonth, 'YYYY.MM');
    for (let i = 0; i < 6; i++) {
      lastMonths.push(formattedMonth.subtract(i, 'month').format('YYYY.MM'));
    }
    return lastMonths.reverse();
  };

  const fetchPerformanceDataLastMonths = async () => {
    const currentMonth =
      filterState.datum?.value || dayjs().format('YYYY.MM');
    const lastMonths = getLastMonths(currentMonth);

    try {
      const responses = await Promise.all(
        lastMonths.map((month) =>
          axiosInstance.get('/scorecards_month/performance-monat/', {
            params: { jahrMonat: month },
          })
        )
      );

      const lastMonthsData = responses.map((response, index) => {
        const produktivstundenSumme = response.data.results.reduce(
          (sum, filiale) => sum + (filiale.produktivstunden || 0),
          0
        );

        const umsatzSumme = response.data.results.reduce(
          (sum, filiale) => sum + (filiale.umsatz || 0),
          0
        );

        const leistung =
          produktivstundenSumme > 0
            ? umsatzSumme / produktivstundenSumme
            : 0;

        const produktivstundenVJSumme = response.data.results.reduce(
          (sum, filiale) => sum + (filiale.produktivstundenVJ || 0),
          0
        );

        const umsatzVJSumme = response.data.results.reduce(
          (sum, filiale) => sum + (filiale.umsatzVJ || 0),
          0
        );

        const leistungVJ =
          produktivstundenVJSumme > 0
            ? umsatzVJSumme / produktivstundenVJSumme
            : 0;

        // Kundenanzahl und Kundenbon für die DataCards
        const kundenanzahlSumme = response.data.results.reduce(
          (sum, filiale) => sum + (filiale.kundenanzahl || 0),
          0
        );

        const kundenanzahlVJSumme = response.data.results.reduce(
          (sum, filiale) => sum + (filiale.kundenanzahlVJ || 0),
          0
        );

        const kundenbon =
          kundenanzahlSumme > 0 ? umsatzSumme / kundenanzahlSumme : 0;
        const kundenbonVJ =
          kundenanzahlVJSumme > 0
            ? umsatzVJSumme / kundenanzahlVJSumme
            : 0;

        return {
          month: lastMonths[index],
          leistung: leistung,
          leistungVJ: leistungVJ,
          umsatz: umsatzSumme,
          umsatzVJ: umsatzVJSumme,
          produktivstunden: produktivstundenSumme,
          produktivstundenVJ: produktivstundenVJSumme,
          kundenanzahl: kundenanzahlSumme,
          kundenanzahlVJ: kundenanzahlVJSumme,
          kundenbon: kundenbon,
          kundenbonVJ: kundenbonVJ,
        };
      });

      setPerformanceLastMonths(lastMonthsData);
    } catch (error) {
      console.error('Error fetching data for last months:', error);
    }
  };

  // **Angepasste Daten für Umsatz und Produktivstunden Diagramm**
  const chartDataUmsatzProduktivstunden = [
    {
      id: 'Umsatz',
      data: performanceLastMonths.map((item) => ({
        x: item.month,
        y: item.umsatz,
      })),
    },
    {
      id: 'Produktivstunden',
      data: performanceLastMonths.map((item) => ({
        x: item.month,
        y: item.produktivstunden,
      })),
    },
  ];

  // Daten für das Leistung-Diagramm
  const chartDataLeistung = performanceLastMonths.flatMap((monthData) => [
    { x: monthData.month, y: monthData.leistung, label: 'aktuelles Jahr' },
    { x: monthData.month, y: monthData.leistungVJ, label: 'Vorjahr' },
  ]);

  // Angepasste Spaltendefinition für VariableTreeMUI
  const columns = useMemo(
    () => [
      {
        field: 'verkaufsgebiet',
        headerName: 'Verkaufsgebiet',
        align: 'left',
      },
      {
        field: 'filiale',
        headerName: 'Filiale',
        align: 'left',
      },
      {
        field: 'scoreLeistung',
        headerName: 'Score',
        align: 'right',
        valueFormatter: ({ value }) => formatNumberWithCommas(value),
      },
      {
        field: 'leistung',
        headerName: 'Leistung',
        align: 'right',
        valueFormatter: ({ value }) => formatNumberWithCommas(value),
      },
      {
        field: 'leistungVJ',
        headerName: 'Leistung VJ',
        align: 'right',
        valueFormatter: ({ value }) => formatNumberWithCommas(value),
      },
      {
        field: 'leistungAbweichungVJ',
        headerName: 'Leistung Abw. VJ',
        align: 'right',
        valueFormatter: ({ value }) => formatPercentage(value),
      },
    ],
    []
  );

  // Aggregationsfunktionen für die Gruppierung
  const aggregationFunctions = {
    scoreLeistung: (items) => {
      const validItems = items.filter((item) => item.scoreLeistung > 0);
      const totalScoreLeistung = validItems.reduce(
        (sum, item) => sum + (item.scoreLeistung || 0),
        0
      );
      const averageScoreLeistung =
        validItems.length > 0 ? totalScoreLeistung / validItems.length : 0;
      return parseFloat(averageScoreLeistung.toFixed(2));
    },
    leistung: (items) => {
      const totalLeistung = items.reduce(
        (sum, item) => sum + (item.leistung || 0),
        0
      );
      const averageLeistung =
        items.length > 0 ? totalLeistung / items.length : 0;
      return parseFloat(averageLeistung.toFixed(2));
    },
    leistungVJ: (items) => {
      const totalLeistungVJ = items.reduce(
        (sum, item) => sum + (item.leistungVJ || 0),
        0
      );
      const averageLeistungVJ =
        items.length > 0 ? totalLeistungVJ / items.length : 0;
      return parseFloat(averageLeistungVJ.toFixed(2));
    },
    leistungAbweichungVJ: (items) => {
      const totalLeistung = items.reduce(
        (sum, item) => sum + (item.leistung || 0),
        0
      );
      const totalLeistungVJ = items.reduce(
        (sum, item) => sum + (item.leistungVJ || 0),
        0
      );
      const leistungAbweichungVJ =
        totalLeistungVJ > 0
          ? ((totalLeistung - totalLeistungVJ) / totalLeistungVJ) * 100
          : 0;
      return parseFloat(leistungAbweichungVJ.toFixed(2));
    },
  };

  // Berechnung von filialtypData bei Änderungen von allFilialenData
  useEffect(() => {
    if (allFilialenData.length > 0) {
      // Daten vorbereiten
      const filialtypData = allFilialenData.reduce((acc, filiale) => {
        const existing = acc.find(
          (item) => item.filialtyp === filiale.filialtyp
        );
        if (existing) {
          existing.totalUmsatz += filiale.umsatz || 0;
          existing.totalProduktivstunden += filiale.produktivstunden || 0;
        } else {
          acc.push({
            filialtyp: filiale.filialtyp,
            totalUmsatz: filiale.umsatz || 0,
            totalProduktivstunden: filiale.produktivstunden || 0,
          });
        }
        return acc;
      }, []);

      // Leistung für jeden Filialtyp berechnen
      filialtypData.forEach((item) => {
        item.leistung =
          item.totalProduktivstunden > 0
            ? item.totalUmsatz / item.totalProduktivstunden
            : 0;
      });

      // Debugging: Überprüfen Sie die Daten
      console.log('filialtypData:', filialtypData);

      setFilialtypData(filialtypData);
    }
  }, [allFilialenData]);

  return (
    <Layout>
      <Grid container justifyContent="flex-end" alignItems="stretch">
        <Grid item xs={12}>
          <Stack
            direction="row"
            alignItems="stretch"
            spacing={2}
            sx={{ overflowX: 'auto' }}
          >
            {/* DataCards */}
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              className="db_datacard_not_active"
            >
              <DataCard
                category="Umsatz"
                sector="performance"
                value={
                  performanceData.umsatz
                    ? formatNumberWithCommas(performanceData.umsatz)
                    : '0,00'
                }
                subHeaders={[
                  `${formatPercentage(performanceData.umsatzAbweichungVJ)} zum Vorjahr`,
                ]}
                chartData={performanceLastMonths.map((item) => item.umsatz)}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              className="db_datacard_not_active"
            >
              <DataCard
                category="Kundenanzahl"
                sector="performance"
                value={
                  performanceData.kundenanzahl
                    ? formatNumberWithCommas(performanceData.kundenanzahl)
                    : '0'
                }
                subHeaders={[
                  `${formatPercentage(
                    performanceData.kundenanzahlAbweichungVJ
                  )} zum Vorjahr`,
                ]}
                chartData={performanceLastMonths.map(
                  (item) => item.kundenanzahl
                )}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              className="db_datacard_not_active"
            >
              <DataCard
                category="Kundenbon"
                sector="performance"
                value={
                  performanceData.kundenbon
                    ? formatNumberWithCommas(performanceData.kundenbon)
                    : '0,00'
                }
                subHeaders={[
                  `${formatPercentage(
                    performanceData.kundenbonAbweichungVJ
                  )} zum Vorjahr`,
                ]}
                chartData={performanceLastMonths.map(
                  (item) => item.kundenbon
                )}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={3} className="db_datacard_active">
              <DataCard
                category="Leistung"
                sector="performance"
                value={
                  performanceData.leistung
                    ? formatNumberWithCommas(performanceData.leistung)
                    : '0,00'
                }
                subHeaders={[
                  `${formatPercentage(
                    performanceData.leistungAbweichungVJ
                  )} zum Vorjahr`,
                ]}
                chartData={performanceLastMonths.map(
                  (item) => item.leistung
                )}
              />
            </Grid>
          </Stack>
        </Grid>

        <Grid container spacing={2} mt={3}>
          {/* Linke Seite */}
          <Grid item xs={12} lg={6}>
            <Stack
              direction="column"
              alignItems="stretch"
              spacing={2}
              sx={{ overflowX: 'auto' }}
            >
              {/* Umsatz und Produktivstunden Chart mit Second Y-Achse */}
              <BigChartCardSecondYAxis
                data={chartDataUmsatzProduktivstunden}
                title="Umsatz und Produktivstunden der letzten Monate"
                height={300}
              />

              {/* PieChart für Filialtypen */}
              {filialtypData && filialtypData.length > 0 && (
                <BigPieChartCardTotal
                  data={filialtypData}
                  title="Leistung nach Filialtyp"
                  height={350}
                  metric="leistung" // Verwenden Sie das Metric "leistung"
                />
              )}
            </Stack>
          </Grid>

          {/* Rechte Seite */}
          <Grid item xs={12} lg={6}>
            {/* Leistung Chart */}
            <BigChartCard
              data={chartDataLeistung}
              title="Leistung und Leistung Vorjahr der letzten Monate"
            />
          </Grid>
        </Grid>

        <Grid item xs={12} md={12} mt={3} sx={{ mt: 2 }}>
        <VariableTreeMUI
                columns={columns}
                data={tableData}
                title="Leistung"
                initialGrouping={['verkaufsgebiet']}
                groupingHeaders={{ 'verkaufsgebiet': 'Verkaufsgebiet' }} // Neue Prop zur Anpassung des Gruppierungs-Headers
                aggregationFunctions={aggregationFunctions}
                onRowClick={(item) => console.log(item)}
              />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Leistung;
