import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
} from 'recharts';
import { useTheme } from '@mui/material/styles';
import { Typography, useMediaQuery } from '@mui/material';
import { tausenderTrennung } from '../../utils/mathStuff';

const LineChartComponentSecondYAxis = ({ data }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const chartColors = [
    theme.palette.chart.yellow,
    theme.palette.chart.purpleLight,
    theme.palette.chart.orange,
    theme.palette.chart.pink,
    theme.palette.chart.red,
    theme.palette.chart.purpleDark,
  ];

  // Überprüfen Sie, ob Daten vorhanden sind
  if (!data || data.length === 0 || !data[0] || !data[0].data || data[0].data.length === 0) {
    return (
      <Typography variant="body2" color="text.secondary">
        Keine Daten verfügbar
      </Typography>
    );
  }

  // Transform the data into a format that Recharts understands
  const chartData = [];
  const months = data[0].data.map((d) => d.x); // Get the months
  months.forEach((month, index) => {
    const dataPoint = { month };
    data.forEach((series) => {
      dataPoint[series.id] = series.data[index]?.y || 0;
    });
    chartData.push(dataPoint);
  });
  

  // Calculate min and max Y values for each series
  const seriesStats = data.map((series) => {
    const yValues = series.data.map((point) => point.y || 0);
    const minY = Math.min(...yValues);
    const maxY = Math.max(...yValues);
    return { id: series.id, minY, maxY };
  });

  // Calculate overall min and max Y values
  const allYValues = data.flatMap((series) =>
    series.data.map((point) => point.y || 0)
  );
  const overallMinYValue = Math.min(...allYValues);
  const overallMaxYValue = Math.max(...allYValues);

  // Determine threshold to separate series into two Y-axes
  const threshold = overallMaxYValue / 5;

  // Assign series to left or right Y-axis based on their max Y value
  const leftAxisSeries = seriesStats
    .filter((stats) => stats.maxY <= threshold)
    .map((stats) => stats.id);

  const rightAxisSeries = seriesStats
    .filter((stats) => stats.maxY > threshold)
    .map((stats) => stats.id);

  // Get Y values for left and right axes
  const leftYValues = data
    .filter((series) => leftAxisSeries.includes(series.id))
    .flatMap((series) => series.data.map((point) => point.y || 0));

  const rightYValues = data
    .filter((series) => rightAxisSeries.includes(series.id))
    .flatMap((series) => series.data.map((point) => point.y || 0));

  // Calculate min and max Y values for left axis
  const leftMinYValue =
    leftYValues.length > 0 ? Math.min(...leftYValues) : 0;
  const leftMaxYValue =
    leftYValues.length > 0 ? Math.max(...leftYValues) : 0;

  // Calculate min and max Y values for right axis
  const rightMinYValue =
    rightYValues.length > 0 ? Math.min(...rightYValues) : 0;
  const rightMaxYValue =
    rightYValues.length > 0 ? Math.max(...rightYValues) : 0;

  // Adjust min and max Y values with 20% padding for left axis
  const adjustedLeftMinYValue =
    leftMinYValue > 0
      ? Math.max(0, leftMinYValue - (leftMaxYValue - leftMinYValue) * 0.2)
      : leftMinYValue - (leftMaxYValue - leftMinYValue) * 0.2;
  const adjustedLeftMaxYValue =
    leftMaxYValue + (leftMaxYValue - leftMinYValue) * 0.2;

  // Adjust min and max Y values with 20% padding for right axis
  const adjustedRightMinYValue =
    rightMinYValue > 0
      ? Math.max(0, rightMinYValue - (rightMaxYValue - rightMinYValue) * 0.2)
      : rightMinYValue - (rightMaxYValue - rightMinYValue) * 0.2;
  const adjustedRightMaxYValue =
    rightMaxYValue + (rightMaxYValue - rightMinYValue) * 0.2;

  // Function to consistently choose colors based on series ID
  const stringToColor = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      // eslint-disable-next-line no-bitwise
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
      // eslint-disable-next-line no-bitwise
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    return color;
  };

  const getColor = (index, seriesId) => {
    if (index < chartColors.length) {
      return chartColors[index];
    }
    // Generate a consistent color based on the series ID
    return stringToColor(seriesId);
  };

  // Dynamically adjust font sizes based on screen size
  const getFontSize = () => {
    if (isSmallScreen) {
      return 8;
    } else if (isMediumScreen) {
      return 10;
    } else {
      return 14;
    }
  };

  const formatNumberWithCommas = (number) => {
    return tausenderTrennung(number, 0);
  };

  return (
    <ResponsiveContainer width="100%" aspect={isSmallScreen ? 1 : 3}>
      <LineChart 
      data={chartData} 
      margin={{ top: 10, right: 50, left: 50, bottom: 0 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="month"
          tick={{ fontSize: getFontSize() }}
          padding={{ left: 5, right: 10 }}
        />
        {leftAxisSeries.length > 0 && (
          <YAxis
            yAxisId="left"
            orientation="left"
            tick={{ fontSize: getFontSize() }}
            tickFormatter={formatNumberWithCommas}
            domain={[adjustedLeftMinYValue, adjustedLeftMaxYValue]}
            width={isSmallScreen ? 40 : 60}
          />
        )}
        {rightAxisSeries.length > 0 && (
          <YAxis
            yAxisId="right"
            orientation="right"
            tick={{ fontSize: getFontSize() }}
            tickFormatter={formatNumberWithCommas}
            domain={[adjustedRightMinYValue, adjustedRightMaxYValue]}
            width={isSmallScreen ? 40 : 60}
          />
        )}
        <Tooltip
          contentStyle={{
            fontSize: getFontSize(),
          }}
          formatter={(value) => formatNumberWithCommas(value)}
        />
        <Legend
          wrapperStyle={{
            fontSize: getFontSize(),
          }}
          iconType="circle"
          iconSize={getFontSize()}
        />
        {data.map((series, index) => {
          const yAxisId = leftAxisSeries.includes(series.id)
            ? 'left'
            : 'right';
          return (
            <Line
              key={series.id}
              yAxisId={yAxisId}
              type="monotone"
              dataKey={series.id}
              stroke={getColor(index, series.id)}
              connectNulls
              activeDot={{ r: 8 }}
            />
          );
        })}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineChartComponentSecondYAxis;
