import React, {useContext, useState} from 'react';
import {Form, useNavigate} from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import useLogin from '../../hooks/useLogin';
import {InfoBarContext} from "../../provider/InfoBar";

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const {showError} = useContext(InfoBarContext);  // show error and success messages
  const {login} = useLogin();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    const result = await login(username, password);
    if (result && result.error) {
      setTimeout(() => {
        setError(result.error);
        showError("Login fehlgeschlagen");
        setLoading(false);
      }, 1000);
    } else {
      setLoading(false);
      navigate('/module');
    }
  };

  return (
      <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
          }}
      >
        <Paper
            sx={{
              padding: 6,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              maxWidth: '430px',
              width: '100%',
              backgroundColor: '#ffffff',
              borderRadius: '12px',
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
            }}
        >
          <h1 style={{color: '#00796b', fontSize: '2rem', fontWeight: 'bold'}}>Login</h1>
          {error && (
            <div style={{color: 'red', marginTop: '16px'}}>
              {error.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </div>
          )}
          <Form onSubmit={handleSubmit} style={{width: '100%', marginTop: '16px'}}>
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Nutzername"
                name="username"
                autoComplete="username"
                autoFocus
                value={username}
                onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Passwort"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{
                  marginTop: 3,
                  marginBottom: 2,
                  padding: 1.5,
                  backgroundColor: '#00796b',
                  '&:hover': {
                    backgroundColor: '#004d40',
                  },
                }}
                disabled={loading}
            >
              {loading ? <CircularProgress size={24}/> : 'Login'}
            </Button>
          </Form>
        </Paper>
      </div>
  );
};

export default LoginPage;
