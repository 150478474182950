import React, { useEffect, useState, useContext, useMemo } from 'react';
import Layout from '../../../components/layout/Scorecards/Unternehmen/UnternehmenMonat';
import { FilterContext } from '../../../provider/FilterProvider';
import axiosInstance from '../../../services/axiosInstance';
import VariableTableTree from '../../../components/table/VariableTableTree'; // Importiere die neue Komponente

const Tree = () => {
    const { filterState } = useContext(FilterContext);
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get('/scorecards_month/performance-monat/', {
                    params: {
                        jahrMonat: filterState.datum?.value,
                    },
                });
                setData(response.data.results);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (filterState.datum?.value) {
            fetchData();
        }
    }, [filterState.datum]);

    const columns = useMemo(() => [
        {
            accessorKey: 'verkaufsgebiet',
            header: 'Verkaufsgebiet',
            enableGrouping: true, // Aktiviert Gruppierung für diese Spalte
            isVisible: true,
        },
        {
            accessorKey: 'filiale',
            header: 'Filiale',
            enableGrouping: false, // Filialen sollen nicht gruppiert werden
            isVisible: true,
        },
        {
            accessorKey: 'umsatz',
            header: 'Umsatz',
            enableGrouping: false, // Keine Gruppierung für Umsätze
            aggregationFn: 'sum',
            AggregatedCell: ({ cell }) => (
                <span>Gesamtumsatz: {cell.getValue()?.toFixed(2)}</span>
            ),
            isVisible: true,
        },
    ], []);

    return (
        <Layout>
            <VariableTableTree
                data={data}
                columns={columns}
                initialGrouping={['verkaufsgebiet']} // Gruppierung nach Verkaufsgebiet
            />
        </Layout>
    );
};

export default Tree;
