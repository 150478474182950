import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  CircularProgress,
  MenuItem,
  Typography,
  Snackbar,
  Alert,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import Layout from '../../../components/layout/Technik/Technik';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import QuickreplyOutlinedIcon from '@mui/icons-material/QuickreplyOutlined';
import axiosInstance from '../../../services/axiosInstance';
// Importiere den korrekten Scanner
import { Scanner } from '@yudiel/react-qr-scanner';

const Index = () => {
  const FILIALNUMMER = 7010;
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [inventarOptions, setInventarOptions] = useState([]);
  const [newTicketDialogOpen, setNewTicketDialogOpen] = useState(false);
  const [editTicketDialogOpen, setEditTicketDialogOpen] = useState(false);
  const [newTicket, setNewTicket] = useState({
    filialnummer: FILIALNUMMER,
    inventar: '',
    problem_beschreibung: '',
    status: 'Offen',
  });
  const [selectedTicket, setSelectedTicket] = useState(null);

  const [scannerOpen, setScannerOpen] = useState(false);
  const [inventarName, setInventarName] = useState('');

  const hasProcessedScanRef = useRef(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  // Zustände für Bild-Upload
  const [imageFile, setImageFile] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [openImageDialog, setOpenImageDialog] = useState(false);

  const fetchTickets = async () => {
    try {
      const response = await axiosInstance.get(`technik/tickets/?filialnummer=${FILIALNUMMER}`);
      const tickets = response.data.results || response.data;
      setTickets(tickets);
      setLoading(false);
    } catch (error) {
      console.error('Fehler beim Abrufen der Tickets:', error);
      setTickets([]);
      setLoading(false);
    }
  };

  const fetchInventarOptions = async () => {
    try {
      const response = await axiosInstance.get(`technik/inventar/?filialnummer=${FILIALNUMMER}`);
      const results = response.data.results || response.data;
      const options = results.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setInventarOptions(options);
    } catch (error) {
      console.error('Fehler beim Abrufen der Inventar-Optionen:', error);
    }
  };

  useEffect(() => {
    fetchTickets();
    fetchInventarOptions();
  }, []);

  const handleAddNewTicket = async () => {
    try {
      let image_id = null;
      if (imageFile) {
        // Bild hochladen und image_id erhalten
        const formData = new FormData();
        formData.append('image', imageFile);
        formData.append('title', newTicket.problem_beschreibung);

        const imageResponse = await axiosInstance.post('/pictures/upload/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        image_id = imageResponse.data.image_id;
      }

      // image_id zum Ticket-Daten hinzufügen
      const ticketData = {
        ...newTicket,
        image_id: image_id,
      };

      const response = await axiosInstance.post('technik/tickets/', ticketData);
      setTickets([...tickets, response.data]);
      setSnackbarMessage('Ticket erfolgreich erstellt');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      handleCloseNewTicketDialog();
    } catch (error) {
      console.error('Fehler beim Erstellen eines neuen Tickets:', error.response?.data || error.message);
      setSnackbarMessage('Fehler beim Erstellen des Tickets');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleCloseNewTicketDialog = () => {
    setNewTicketDialogOpen(false);
    setScannerOpen(false);
    hasProcessedScanRef.current = false;
    setInventarName('');
    setImageFile(null); // Bild-Datei zurücksetzen
    setNewTicket({
      filialnummer: FILIALNUMMER,
      inventar: '',
      problem_beschreibung: '',
      status: 'Offen',
    });
  };

  const handleEditTicket = async () => {
    try {
      const response = await axiosInstance.put(`technik/tickets/${selectedTicket.id}/`, selectedTicket);
      setTickets(tickets.map((ticket) => (ticket.id === selectedTicket.id ? response.data : ticket)));
      setSelectedTicket(null);
      setEditTicketDialogOpen(false);
    } catch (error) {
      console.error('Fehler beim Aktualisieren des Tickets:', error.response?.data || error.message);
    }
  };

  const handleScan = async (detectedCodes) => {
    if (detectedCodes && detectedCodes.length > 0 && !hasProcessedScanRef.current && scannerOpen) {
      hasProcessedScanRef.current = true;
      const decodedText = detectedCodes[0].rawValue; // Nehmen wir den ersten erkannten Barcode

      try {
        const response = await axiosInstance.get(`technik/inventar/?id_qrcode=${decodedText}`);
        const results = response.data.results || response.data;
        const inventarItem = results[0];

        if (inventarItem) {
          setNewTicket((prevState) => ({
            ...prevState,
            inventar: inventarItem.id,
          }));
          setInventarName(inventarItem.name);
          setScannerOpen(false);
        } else {
          alert('Kein Inventar mit diesem QR-Code gefunden.');
          hasProcessedScanRef.current = false;
        }
      } catch (error) {
        console.error('Fehler beim Abrufen des Inventars:', error);
        alert('Fehler beim Abrufen des Inventars.');
        hasProcessedScanRef.current = false;
      }
    }
  };

  const handleError = (error) => {
    console.error('Fehler beim QR-Code-Scannen:', error);
  };

  const handleOpenScanner = () => {
    setScannerOpen(true);
    hasProcessedScanRef.current = false;
  };

  const handleImageClick = async (image_id) => {
    try {
      const response = await axiosInstance.get(`/pictures/get-image-url/${image_id}/`);
      setImageUrl(response.data.url);
      setOpenImageDialog(true);
    } catch (error) {
      console.error('Fehler beim Laden des Bildes:', error);
      setSnackbarMessage('Fehler beim Laden des Bildes');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleCloseImageDialog = () => {
    setOpenImageDialog(false);
    setImageUrl('');
  };

  return (
    <Layout>
      <Box
        sx={{
          flexGrow: 1,
          p: 3,
          maxWidth: 1200,
          margin: '0 auto',
          bgcolor: 'transparent',
        }}
      >
        <PageHeader 
          title="Technik" 
          subtitle="Verwalte deine Tickets und konfiguriere diese nach den Bedürfnissen deiner Filiale."
          Icon={QuickreplyOutlinedIcon}  
        />

        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          sx={{ mb: 2 }}
          onClick={() => {
            setNewTicketDialogOpen(true);
            hasProcessedScanRef.current = false;
          }}
        >
          Neues Ticket erstellen
        </Button>

        {/* Tickets Tabelle */}
        {loading ? (
          <CircularProgress />
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Ticket ID</TableCell>
                  <TableCell>Problem Beschreibung</TableCell>
                  <TableCell>Inventar</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell align="center">Bild</TableCell> {/* Neue Spalte */}
                  <TableCell align="center">Aktionen</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tickets.length > 0 ? (
                  tickets.map((ticket) => (
                    <TableRow key={ticket.ticket_id}>
                      <TableCell>{ticket.ticket_id}</TableCell>
                      <TableCell>{ticket.problem_beschreibung}</TableCell>
                      <TableCell>
                        {inventarOptions.find((i) => i.value === ticket.inventar)?.label ||
                          'Kein Inventar'}
                      </TableCell>
                      <TableCell>{ticket.status}</TableCell>
                      <TableCell align="center">
                        {ticket.image_id ? (
                          <IconButton
                            color="primary"
                            onClick={() => handleImageClick(ticket.image_id)}
                          >
                            <PhotoLibraryIcon />
                          </IconButton>
                        ) : (
                          'Kein Bild'
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          color="primary"
                          onClick={() => {
                            setSelectedTicket(ticket);
                            setEditTicketDialogOpen(true);
                          }}
                        >
                          <EditOutlinedIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      Keine Tickets verfügbar.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {/* Neues Ticket Dialog */}
        <Dialog open={newTicketDialogOpen} onClose={handleCloseNewTicketDialog} fullWidth>
          <DialogTitle>Neues Ticket erstellen</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              label="Problem Beschreibung"
              value={newTicket.problem_beschreibung}
              onChange={(e) => setNewTicket({ ...newTicket, problem_beschreibung: e.target.value })}
              multiline
              rows={3}
              margin="dense"
            />
            <TextField
              select
              fullWidth
              label="Inventar"
              value={newTicket.inventar}
              onChange={(e) => {
                setNewTicket({ ...newTicket, inventar: e.target.value });
                const selectedInventar = inventarOptions.find((option) => option.value === e.target.value);
                setInventarName(selectedInventar ? selectedInventar.label : '');
              }}
              margin="dense"
            >
              <MenuItem value="">Kein Inventar</MenuItem>
              {inventarOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>

            <Button
              variant="outlined"
              color="primary"
              onClick={handleOpenScanner}
              sx={{ mt: 2 }}
            >
              QR-Code scannen
            </Button>

            {scannerOpen && (
              <Box sx={{ mt: 2 }}>
                <Scanner
                  onScan={handleScan}
                  onError={handleError}
                  constraints={{ facingMode: 'environment' }}
                  style={{ width: '100%' }}
                />
                <Button onClick={() => setScannerOpen(false)} color="secondary" sx={{ mt: 1 }}>
                  Scanner schließen
                </Button>
              </Box>
            )}

            {inventarName && (
              <Typography variant="body1" sx={{ mt: 2 }}>
                Ausgewähltes Inventar: {inventarName}
              </Typography>
            )}

            {/* Bild-Upload */}
            <Button
              variant="outlined"
              component="label"
              sx={{ mt: 2 }}
            >
              Bild hochladen
              <input
                type="file"
                accept="image/*"
                hidden
                onChange={(e) => {
                  if (e.target.files[0]) {
                    setImageFile(e.target.files[0]);
                  }
                }}
              />
            </Button>
            {imageFile && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                Ausgewählte Datei: {imageFile.name}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseNewTicketDialog} color="secondary">
              Abbrechen
            </Button>
            <Button onClick={handleAddNewTicket} color="primary">
              Erstellen
            </Button>
          </DialogActions>
        </Dialog>

        {/* Ticket bearbeiten Dialog */}
        {selectedTicket && (
          <Dialog open={editTicketDialogOpen} onClose={() => setEditTicketDialogOpen(false)} fullWidth>
            <DialogTitle>Ticket bearbeiten</DialogTitle>
            <DialogContent>
              <TextField
                fullWidth
                label="Problem Beschreibung"
                value={selectedTicket.problem_beschreibung}
                onChange={(e) =>
                  setSelectedTicket({ ...selectedTicket, problem_beschreibung: e.target.value })
                }
                multiline
                rows={3}
                margin="dense"
              />
              <TextField
                select
                fullWidth
                label="Status"
                value={selectedTicket.status}
                onChange={(e) =>
                  setSelectedTicket({ ...selectedTicket, status: e.target.value })
                }
                margin="dense"
              >
                <MenuItem value="Offen">Offen</MenuItem>
                <MenuItem value="In Bearbeitung">In Bearbeitung</MenuItem>
                <MenuItem value="Erledigt">Erledigt</MenuItem>
                <MenuItem value="Delegiert">Delegiert</MenuItem>
              </TextField>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setEditTicketDialogOpen(false)} color="secondary">
                Abbrechen
              </Button>
              <Button onClick={handleEditTicket} color="primary">
                Speichern
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {/* Bild-Dialog */}
        <Dialog open={openImageDialog} onClose={handleCloseImageDialog} maxWidth="md" fullWidth>
          <DialogContent>
            {imageUrl ? (
              <img src={imageUrl} alt="Ticket Bild" style={{ width: '100%' }} />
            ) : (
              <CircularProgress />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseImageDialog} color="primary">
              Schließen
            </Button>
          </DialogActions>
        </Dialog>

        {/* Snackbar für Feedback */}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Layout>
  );
};

export default Index;
