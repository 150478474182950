import React, {useContext, useEffect, useState} from 'react';
import {Box, Button, Typography, useTheme} from '@mui/material';
import {Calendar, momentLocalizer} from 'react-big-calendar';
import moment from 'moment';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import axiosInstance from "../../../services/axiosInstance";
import CircularProgress from "@mui/material/CircularProgress";
import ThekeOverview from '../../../components/theke/ThekeOverview';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import SortimentLayout from "../../../components/layout/Sortiment/SortimentLayout";
import {useNavigate} from "react-router-dom";
import {InfoBarContext} from "../../../provider/InfoBar";

const localizer = momentLocalizer(moment);

const KalenderPage = () => {
    const theme = useTheme();
    const [events, setEvents] = useState([]);
    const [selectedRange, setSelectedRange] = useState(null);
    const [loadingEvents, setLoadingEvents] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [plans, setPlans] = useState([]);
    const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);
    const [awaitDelete, setAwaitDelete] = useState(false);
    const {showError, showSuccess} = useContext(InfoBarContext);
    const navigate = useNavigate();

    // Function to handle event deletion
    const handleDeleteEvent = async () => {
        if (selectedEvent) {
            setAwaitDelete(true);
            try {
                console.log("Selected event", selectedEvent);
                await axiosInstance.delete(`/theke/sortiment-plans/${selectedEvent.id}`);
                console.log('Event deleted successfully');

                // Update state to remove the deleted event
                setPlans(prevPlans => prevPlans.filter(plan => plan.sortimentId !== selectedEvent.id));
                setEvents(prevEvents => prevEvents.filter(event => event.id !== selectedEvent.id));
                setOpenDialog(false);
                setSelectedEvent(null);
                showSuccess("Event erfolgreich gelöscht.");
            } catch (error) {
                showError("Fehler beim Löschen des Events. Bitte versuchen Sie es erneut.");
            }
            setAwaitDelete(false);
        }
    };

    // Function to open the confirmation dialog for deletion
    const openConfirmDeleteDialog = () => {
        setConfirmDeleteDialog(true);
    };

    useEffect(() => {
        setLoadingEvents(true);
        const fetchSortimentsPlans = async () => {
            try {
                const response = await axiosInstance.get('/theke/sortiment-plans/');
                const plans = response.data.results;
                console.log("Raw plans", plans);

                // Format the plans data
                const formattedPlans = plans.map(plan => ({
                    ...plan,
                    warengruppen_plans: plan.warengruppen_plans.map(wg => ({
                        warengruppennummer: wg.warengruppennummer,
                        warengruppe: wg.warengruppe_name,
                        artikel: wg.artikel,
                        slot_count: wg.slot_count,
                    })),
                }));

                setPlans(formattedPlans);
                console.log('Formatted Plans:', formattedPlans);

                // Set events based on the formatted plans
                const events = formattedPlans.map(plan => ({
                    title: plan.name,
                    id: plan.sortimentId,
                    start: new Date(plan.start_datum),
                    end: new Date(plan.end_datum),
                    allDay: true,
                }));
                console.log("Events", events);
                setEvents(events);
            } catch (error) {
                console.error('Error fetching sortiments plans:', error);
                showError("Fehler beim Laden der Sortimentspläne. Bitte versuchen Sie es erneut.");
            } finally {
                setLoadingEvents(false);
            }
        };

        fetchSortimentsPlans();
    }, []);

    // Function to handle slot selection in the calendar
    const handleSelectSlot = ({start, end}) => {
        setSelectedRange({start, end});
        console.log('Selected range:', start, end);
    };

    // Function to customize event styles based on selection
    const eventPropGetter = (event) => {
        if (selectedRange && event.start >= selectedRange.start && event.end <= selectedRange.end) {
            return {style: {backgroundColor: theme.palette.primary.main}};
        }
        return {};
    };

    // Create a range event for the selected time slot
    const rangeEvent = selectedRange ? [{
        start: selectedRange.start,
        end: selectedRange.end,
        title: 'Ausgewählter Zeitraum (Zum Erstellen klicken)'
    }] : [];

    // Function to handle event clicks
    const handleEventClick = (event) => {
        if (event.title === 'Ausgewählter Zeitraum (Zum Erstellen klicken)') {
            navigate(`/sortiment/overview/theke?startDate=${event.start.toISOString()}&endDate=${event.end.toISOString()}`);
        } else {
            setSelectedEvent(event);
            setOpenDialog(true);
        }
    };

    const handleEditClick = () => {
        navigate(`/sortiment/overview/theke?startDate=${selectedEvent.start.toISOString()}&endDate=${selectedEvent.end.toISOString()}&id=${selectedEvent.id}`);
    }

    return (
        <SortimentLayout>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <PageHeader
                    title="Kalender"
                    subtitle="Dieser Kalender zeigt alle wichtigen Termine."
                    Icon={CalendarTodayOutlinedIcon}
                />
                {loadingEvents && (
                    <Box sx={{display: 'flex', gap: 4, justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                        <Typography>Lade Sortimentspläne... </Typography>
                        <CircularProgress size={60}/>
                    </Box>
                )}
            </Box>
            <Box sx={{height: '80vh', mt: 3}}>
                <Calendar
                    localizer={localizer}
                    events={[...events, ...rangeEvent]}
                    startAccessor="start"
                    endAccessor="end"
                    style={{height: '100%'}}
                    selectable
                    onSelectSlot={handleSelectSlot}
                    onSelectEvent={handleEventClick}
                    eventPropGetter={eventPropGetter}
                    messages={{
                        next: "Weiter",
                        previous: "Zurück",
                        today: "Heute",
                        month: "Monat",
                        week: "Woche",
                        day: "Tag",
                        agenda: "Agenda",
                        date: "Datum",
                        time: "Uhrzeit",
                        event: "Termin",
                        noEventsInRange: "Keine Termine in diesem Zeitraum",
                        allDay: "Ganztägig",
                        yesterday: "Gestern",
                        tomorrow: "Morgen",
                        work_week: "Arbeitswoche",
                    }}
                    views={['month']}
                    culture="de"
                    formats={{
                        monthHeaderFormat: (date) => {
                            const months = {
                                0: 'Januar',
                                1: 'Februar',
                                2: 'März',
                                3: 'April',
                                4: 'Mai',
                                5: 'Juni',
                                6: 'Juli',
                                7: 'August',
                                8: 'September',
                                9: 'Oktober',
                                10: 'November',
                                11: 'Dezember'
                            };
                            return `${months[date.getMonth()]} ${date.getFullYear()}`;
                        },
                        weekdayFormat: (date) => {
                            const days = {
                                0: 'So',
                                1: 'Mo',
                                2: 'Di',
                                3: 'Mi',
                                4: 'Do',
                                5: 'Fr',
                                6: 'Sa'
                            };
                            return days[date.getDay()];
                        },
                        dayFormat: (date) => date.getDate()
                    }}
                    components={{
                        toolbar: props => (
                            <div className="rbc-toolbar" style={{ justifyContent: 'space-between' }}>
                                <span className="rbc-btn-group">
                                    <button type="button" onClick={() => props.onNavigate('PREV')}>Zurück</button>
                                    <button type="button" onClick={() => props.onNavigate('TODAY')}>Heute</button>
                                    <button type="button" onClick={() => props.onNavigate('NEXT')}>Weiter</button>
                                </span>
                                <span className="rbc-toolbar-label" style={{ flex: '1', textAlign: 'center' }}>
                                    {props.label}
                                </span>
                                <span style={{ width: '200px' }}></span>
                            </div>
                        )
                    }}
                />
            </Box>

            {/* Dialog for displaying ThekeOverview */}
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="xl" fullWidth>
                <Typography variant="h4" sx={{textAlign: 'center', mb: 2, textDecoration: 'underline'}}>
                    Theken Übersicht
                </Typography>
                <DialogContent>
                    {selectedEvent && (() => {
                        const plan = plans.find(plan => plan.sortimentId === selectedEvent.id)?.warengruppen_plans;
                        console.log("Plan", plan);
                        // Handle case where there is no plan data
                        if (!plan || plan.length === 0) {
                            return <Typography variant="body1" sx={{textAlign: 'center'}}>Keine Daten verfügbar.</Typography>;
                        }

                        return (
                            <ThekeOverview

                                warengruppen={plan.map(wg => ({
                                    warengruppennummer: wg.warengruppennummer,
                                    warengruppe: wg.warengruppe,
                                }))}
                                sectionCounts={plan.reduce((acc, wg) => ({...acc, [wg.warengruppennummer]: wg.slot_count}), {})}
                                selectedArtikel={plan.map(wg => ({
                                    id: wg.warengruppennummer,
                                    artikel: wg.artikel.map(artikel => ({artikelData: {...artikel, artikelbezeichnung: artikel.artikel_name}})),
                                }))}
                            />
                        );
                    })()}
                </DialogContent>
                <DialogActions>
                    <Box sx={{position: 'absolute', top: 16, right: 16}}>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={() => setOpenDialog(false)}
                            aria-label="close"
                        >
                            <CloseIcon/>
                        </IconButton>
                    </Box>
                    {selectedEvent &&  (
                        <div style={{display: 'flex', justifyContent: 'space-between', gap: '16px'}}>
                            {plans.find(plan => plan.sortimentId === selectedEvent.id)?.warengruppen_plans.length > 0 && (
                            <Button variant="contained" color="primary" onClick={handleEditClick}>
                                Bearbeiten
                            </Button>)}
                            <Button variant="contained" color="error" onClick={openConfirmDeleteDialog} disabled={awaitDelete}>
                                Löschen
                            </Button>
                        </div>
                    )}
                </DialogActions>
            </Dialog>

            {/* Confirmation Dialog for event deletion */}
            <Dialog open={confirmDeleteDialog} onClose={() => setConfirmDeleteDialog(false)}>
                <DialogContent>
                    <Typography>Are you sure you want to delete this event?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmDeleteDialog(false)} color="primary">
                        Abbrechen
                    </Button>
                    <Button onClick={() => {
                        handleDeleteEvent();
                        setConfirmDeleteDialog(false);
                    }} color="error">
                        Bestätigen
                    </Button>
                </DialogActions>
            </Dialog>
        </SortimentLayout>
    );
};

export default KalenderPage;
