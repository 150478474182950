// src/pages/Bedarf.jsx

import React, { useContext, useEffect, useState, useMemo } from 'react';
import {
  Grid,
  Typography,
  Stack,
  Card,
  CardHeader,
  CardContent,
  Paper,
} from '@mui/material';
import Layout from '../../../components/layout/Scorecards/Unternehmen/UnternehmenMonat';
import DataCard from '../../../components/card/scorecardsmonat/DataCardMonatPage';
import { FilterContext } from '../../../provider/FilterProvider';
import axiosInstance from '../../../services/axiosInstance';
import dayjs from 'dayjs';
import VariableTreeMUI from '../../../components/table/VariableTableTreeMUI'; // Neue Komponente einbinden
import BigChartCardRecharts from '../../../components/card/scorecardsmonat/BigChartCardSecondYAxis';
import BigGaugeCard from '../../../components/card/BigGaugeCard';
import { tausenderTrennung } from '../../../utils/mathStuff';

const Bedarf = () => {
  const { filterState } = useContext(FilterContext);
  const [datumOptions, setDatumOptions] = useState([]);
  const [personalData, setPersonalData] = useState({
    krankenquote: [],
    fluktuation: [],
    bedarf: [],
    auslastung: [],
  });
  const [tableData, setTableData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [gaugeData, setGaugeData] = useState({
    sollStunden: 0,
    wochenSollBerechnetSum: 0,
    köpfe: 0,
    anzahlSollMitarbeiter: 0,
  });

  useEffect(() => {
    fetchDatumOptions();
  }, []);

  useEffect(() => {
    if (filterState.datum) {
      fetchPersonalData(); // Daten abrufen
      fetchTableData(); // Daten für die Tabelle abrufen
    }
  }, [filterState]);

  const fetchDatumOptions = async () => {
    try {
      const response = await axiosInstance.get('/stammdaten/unique-jahr-monat/');
      setDatumOptions(
        response.data.map((item) => ({ label: item, value: item }))
      );
    } catch (error) {
      console.error('Error while fetching datum options:', error);
    }
  };

  // Hilfsfunktion zur Berechnung der letzten 6 Monate basierend auf dem ausgewählten Datum
  const getLast6Months = (currentMonth) => {
    const last6Months = [];
    const formattedMonth = dayjs(currentMonth, 'YYYY.MM');

    for (let i = 0; i < 6; i++) {
      last6Months.push(formattedMonth.subtract(i, 'month').format('YYYY.MM'));
    }

    return last6Months.reverse();
  };

  const fetchPersonalData = async () => {
    const currentMonth = filterState.datum?.value || dayjs().format('YYYY.MM');
    const last6Months = getLast6Months(currentMonth);

    try {
      const responses = await Promise.all(
        last6Months.map((month) =>
          axiosInstance.get('/scorecards_month/personal-kennzahl/', {
            params: { jahrMonat: month },
          })
        )
      );

      // Kumulieren der Krankenquote-Daten
      const krankenquoteData = responses.map((response, index) => {
        const data = response.data.results;

        const arbeitszeitKrankenqoute = data.reduce(
          (sum, item) => sum + (item.arbeitszeitKrankenqoute || 0),
          0
        );
        const krankenzeit = data.reduce(
          (sum, item) => sum + (item.krankenzeit || 0),
          0
        );
        const arbeitszeitKrankenqouteVM = data.reduce(
          (sum, item) => sum + (item.arbeitszeitKrankenqouteVM || 0),
          0
        );
        const krankenzeitVM = data.reduce(
          (sum, item) => sum + (item.krankenzeitVM || 0),
          0
        );
        const arbeitszeitKrankenqouteVJ = data.reduce(
          (sum, item) => sum + (item.arbeitszeitKrankenqouteVJ || 0),
          0
        );
        const krankenzeitVJ = data.reduce(
          (sum, item) => sum + (item.krankenzeitVJ || 0),
          0
        );

        // Berechne die Krankenquote
        const krankenquote =
          arbeitszeitKrankenqoute > 0
            ? (krankenzeit / arbeitszeitKrankenqoute) * 100
            : 0;
        const krankenquoteVM =
          arbeitszeitKrankenqouteVM > 0
            ? (krankenzeitVM / arbeitszeitKrankenqouteVM) * 100
            : 0;
        const krankenquoteVJ =
          arbeitszeitKrankenqouteVJ > 0
            ? (krankenzeitVJ / arbeitszeitKrankenqouteVJ) * 100
            : 0;

        return {
          month: last6Months[index],
          arbeitszeitKrankenqoute,
          krankenzeit,
          krankenquote,
          krankenquoteVM,
          krankenquoteVJ,
        };
      });

      // Kumulieren der Fluktuation-Daten
      const fluktuationData = responses.map((response, index) => {
        const data = response.data.results;

        const austritte6M = data.reduce(
          (sum, item) => sum + (item.austritte6M || 0),
          0
        );
        const mitarbeiterbestand6M = data.reduce(
          (sum, item) => sum + (item.mitarbeiterbestand6M || 0),
          0
        );
        const eintritte6M = data.reduce(
          (sum, item) => sum + (item.eintritte6M || 0),
          0
        );
        const köpfeDifferenz = data.reduce(
          (sum, item) => sum + (item.köpfeDifferenz || 0),
          0
        );
        const stundenDifferenz = data.reduce(
          (sum, item) => sum + (item.stundenDifferenz || 0),
          0
        );
        const überstundenAbs = data.reduce(
          (sum, item) => sum + (item.überstundenAbs || 0),
          0
        );

        // Berechne die Fluktuation
        const fluktuation =
          mitarbeiterbestand6M + eintritte6M > 0
            ? (austritte6M / (mitarbeiterbestand6M + eintritte6M)) * 100
            : 0;

        // Eintritte und Austritte im gefilterten Monat
        const eintritteM = data.reduce(
          (sum, item) => sum + (item.eintritteM || 0),
          0
        );
        const austritteM = data.reduce(
          (sum, item) => sum + (item.austritteM || 0),
          0
        );

        return {
          month: last6Months[index],
          austritte6M,
          mitarbeiterbestand6M,
          eintritte6M,
          fluktuation,
          eintritteM,
          austritteM,
          köpfeDifferenz,
          stundenDifferenz,
          überstundenAbs,
        };
      });

      // Kumulieren der Bedarf-Daten
      const bedarfData = responses.map((response, index) => {
        const data = response.data.results;

        const scoreKöpfeStunden = data.reduce(
          (sum, item) => sum + (item.scoreKöpfeStunden || 0),
          0
        );
        const anzahlSollMitarbeiter = data.reduce(
          (sum, item) => sum + (item.anzahlSollMitarbeiter || 0),
          0
        );
        const köpfe = data.reduce((sum, item) => sum + (item.köpfe || 0), 0);
        const köpfeDifferenz = data.reduce(
          (sum, item) => sum + (item.köpfeDifferenz || 0),
          0
        );
        const sollStunden = data.reduce(
          (sum, item) => sum + (item.sollStunden || 0),
          0
        );
        const wochenSollBerechnetSum = data.reduce(
          (sum, item) => sum + (item.wochensollBerechnetSum || 0),
          0
        );
        const stundenDifferenz = data.reduce(
          (sum, item) => sum + (item.stundenDifferenz || 0),
          0
        );

        return {
          month: last6Months[index],
          scoreKöpfeStunden,
          anzahlSollMitarbeiter,
          köpfe,
          köpfeDifferenz,
          sollStunden,
          wochenSollBerechnetSum,
          stundenDifferenz,
        };
      });

      // Kumulieren der Auslastung-Daten
      const auslastungData = responses.map((response, index) => {
        const data = response.data.results;

        const überstundenAbs = data.reduce(
          (sum, item) => sum + (item.überstundenAbs || 0),
          0
        );

        return {
          month: last6Months[index],
          überstundenAbs,
        };
      });

      // Setzen des Zustands mit allen Daten
      setPersonalData((prev) => ({
        ...prev,
        krankenquote: krankenquoteData,
        fluktuation: fluktuationData,
        bedarf: bedarfData,
        auslastung: auslastungData,
      }));

      // Daten für das Liniendiagramm vorbereiten (Beispiel für Bedarf)
      const chartData = bedarfData.map((item) => ({
        x: item.month,
        y: item.scoreKöpfeStunden,
        label: 'Score Köpfe Stunden',
      }));

      setChartData(chartData);

      // Daten für die GaugeCard vorbereiten (letzter Monat)
      const latestData = bedarfData[bedarfData.length - 1] || {};
      setGaugeData({
        sollStunden: latestData.sollStunden || 0,
        wochenSollBerechnetSum: latestData.wochenSollBerechnetSum || 0,
        köpfe: latestData.köpfe || 0,
        anzahlSollMitarbeiter: latestData.anzahlSollMitarbeiter || 0,
      });

      // Debugging: Konsolenausgabe der Daten
      console.log('Personal Data:', {
        krankenquoteData,
        fluktuationData,
        bedarfData,
        auslastungData,
      });
    } catch (error) {
      console.error('Error fetching Personal data:', error);
    }
  };

  const fetchTableData = async () => {
    const currentMonth = filterState.datum?.value || dayjs().format('YYYY.MM');

    try {
      const response = await axiosInstance.get(
        '/scorecards_month/personal-kennzahl/',
        {
          params: { jahrMonat: currentMonth },
        }
      );

      const data = response.data.results;

      // Tabelle formatieren
      const formattedTableData = data.map((item, index) => ({
        id: `${item.verkaufsgebiet}-${item.filiale}-${index}`, // Generieren einer eindeutigen ID
        verkaufsgebiet: item.verkaufsgebiet,
        filiale: item.filiale,
        scoreKöpfeStunden: item.scoreKöpfeStunden || 0,
        anzahlSollMitarbeiter: item.anzahlSollMitarbeiter || 0,
        köpfe: item.köpfe || 0,
        köpfeDifferenz: item.köpfeDifferenz || 0,
        sollStunden: item.sollStunden || 0,
        wochenSollBerechnetSum: item.wochensollBerechnetSum || 0,
        stundenDifferenz: item.stundenDifferenz || 0,
      }));

      setTableData(formattedTableData);

      // Debugging: Konsolenausgabe der Tabelle
      console.log('Table Data:', formattedTableData);
    } catch (error) {
      console.error('Error fetching table data:', error);
    }
  };

  // Definition der Spalten für die Tabelle
  const columns = useMemo(
    () => [
      {
        field: 'verkaufsgebiet',
        headerName: 'Verkaufsgebiet',
        align: 'left',
        hide: false,
      },
      {
        field: 'filiale',
        headerName: 'Filiale',
        align: 'left',
        hide: false,
      },
      {
        field: 'scoreKöpfeStunden',
        headerName: 'Score',
        type: 'number',
        align: 'right',
        valueFormatter: (params) => tausenderTrennung(params.value, 2),
        hide: false,
      },
      {
        field: 'anzahlSollMitarbeiter',
        headerName: 'Soll MA',
        type: 'number',
        align: 'right',
        valueFormatter: (params) => tausenderTrennung(params.value, 0),
        hide: false,
      },
      {
        field: 'köpfe',
        headerName: 'Ist MA',
        type: 'number',
        align: 'right',
        valueFormatter: (params) => tausenderTrennung(params.value, 0),
        hide: false,
      },
      {
        field: 'köpfeDifferenz',
        headerName: 'Bedarf Köpfe',
        type: 'number',
        align: 'right',
        valueFormatter: (params) => tausenderTrennung(params.value, 0),
        hide: false,
      },
      {
        field: 'sollStunden',
        headerName: 'Soll-Stunden',
        type: 'number',
        align: 'right',
        valueFormatter: (params) => tausenderTrennung(params.value, 0),
        hide: false,
      },
      {
        field: 'wochenSollBerechnetSum',
        headerName: 'Vertragsstunden',
        type: 'number',
        align: 'right',
        valueFormatter: (params) => tausenderTrennung(params.value, 0),
        hide: false,
      },
      {
        field: 'stundenDifferenz',
        headerName: 'Bedarf Stunden',
        type: 'number',
        align: 'right',
        valueFormatter: (params) => tausenderTrennung(params.value, 0),
        hide: false,
      },
    ],
    []
  );

  // Definition der Aggregationsfunktionen
  const aggregationFunctions = {
    scoreKöpfeStunden: (items) => {
      // Durchschnitt der scoreKöpfeStunden
      const total = items.reduce(
        (sum, item) => sum + (item.scoreKöpfeStunden || 0),
        0
      );
      return items.length > 0 ? total / items.length : 0;
    },
    anzahlSollMitarbeiter: (items) => {
      // Summe der anzahlSollMitarbeiter
      return items.reduce(
        (sum, item) => sum + (item.anzahlSollMitarbeiter || 0),
        0
      );
    },
    köpfe: (items) => {
      // Summe der köpfe
      return items.reduce((sum, item) => sum + (item.köpfe || 0), 0);
    },
    köpfeDifferenz: (items) => {
      // Differenz der Summen aus Soll MA - Ist MA
      const sumSollMA = items.reduce(
        (sum, item) => sum + (item.anzahlSollMitarbeiter || 0),
        0
      );
      const sumIstMA = items.reduce(
        (sum, item) => sum + (item.köpfe || 0),
        0
      );
      return sumSollMA - sumIstMA;
    },
    sollStunden: (items) => {
      // Summe der sollStunden
      return items.reduce((sum, item) => sum + (item.sollStunden || 0), 0);
    },
    wochenSollBerechnetSum: (items) => {
      // Summe der wochenSollBerechnetSum
      return items.reduce(
        (sum, item) => sum + (item.wochenSollBerechnetSum || 0),
        0
      );
    },
    stundenDifferenz: (items) => {
      // Differenz der Summen aus SollStunden - Vertragsstunden
      const sumSollStunden = items.reduce(
        (sum, item) => sum + (item.sollStunden || 0),
        0
      );
      const sumVertragsstunden = items.reduce(
        (sum, item) => sum + (item.wochenSollBerechnetSum || 0),
        0
      );
      return sumSollStunden - sumVertragsstunden;
    },
  };

  return (
    <Layout>
      <Grid container justifyContent="flex-end" alignItems="stretch">
        <Grid item xs={12}>
          <Stack
            direction="row"
            alignItems="stretch"
            spacing={2}
            sx={{ overflowX: 'auto' }}
          >
            {/* Krankenquote DataCard */}
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              className="db_datacard_not_active"
            >
              <DataCard
                category="Krankenquote"
                sector="personal"
                value={
                  personalData.krankenquote.length > 0
                    ? `${personalData.krankenquote[
                        personalData.krankenquote.length - 1
                      ].krankenquote.toFixed(2)}%`
                    : '0.00%'
                }
                subHeaders={[
                  personalData.krankenquote.length > 0
                    ? `${personalData.krankenquote[
                        personalData.krankenquote.length - 1
                      ].krankenquoteVM.toFixed(2)}% zum Vormonat`
                    : '0.00% zum Vormonat',
                  personalData.krankenquote.length > 0
                    ? `${personalData.krankenquote[
                        personalData.krankenquote.length - 1
                      ].krankenquoteVJ.toFixed(2)}% zum Vorjahr`
                    : '0.00% zum Vorjahr',
                ]}
                chartData={personalData.krankenquote.map(
                  (item) => item.krankenquote
                )}
              />
            </Grid>

            {/* Fluktuation DataCard */}
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              className="db_datacard_not_active"
            >
              <DataCard
                category="Fluktuation"
                sector="personal"
                value={
                  personalData.fluktuation.length > 0
                    ? `${personalData.fluktuation[
                        personalData.fluktuation.length - 1
                      ].fluktuation.toFixed(2)}%`
                    : '0.00%'
                }
                subHeaders={[
                  personalData.fluktuation.length > 0
                    ? `${personalData.fluktuation[
                        personalData.fluktuation.length - 1
                      ].eintritteM} Eintritte im gefilterten Monat`
                    : '0 Eintritte im gefilterten Monat',
                  personalData.fluktuation.length > 0
                    ? `${personalData.fluktuation[
                        personalData.fluktuation.length - 1
                      ].austritteM} Austritte im gefilterten Monat`
                    : '0 Austritte im gefilterten Monat',
                ]}
                chartData={personalData.fluktuation.map(
                  (item) => item.fluktuation
                )}
              />
            </Grid>

            {/* Bedarf DataCard */}
            <Grid item xs={12} md={6} lg={3} className="db_datacard_active">
              <DataCard
                category="Bedarf"
                sector="personal"
                value={
                  personalData.bedarf.length > 0
                    ? `${personalData.bedarf[
                        personalData.bedarf.length - 1
                      ].köpfeDifferenz} Köpfe und ${
                        personalData.bedarf[
                          personalData.bedarf.length - 1
                        ].stundenDifferenz
                      } Stunden`
                    : '0 Köpfe und 0 Stunden'
                }
                chartData={personalData.bedarf.map(
                  (item) => item.köpfeDifferenz
                )}
              />
            </Grid>

            {/* Auslastung DataCard */}
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              className="db_datacard_not_active"
            >
              <DataCard
                category="Auslastung"
                sector="personal"
                value={
                  personalData.auslastung.length > 0
                    ? `${personalData.auslastung[
                        personalData.auslastung.length - 1
                      ].überstundenAbs} Überstunden`
                    : '0 Überstunden'
                }
                subHeaders={[]}
                chartData={personalData.auslastung.map(
                  (item) => item.überstundenAbs
                )}
              />
            </Grid>
          </Stack>
        </Grid>

        <Grid container spacing={2} mt={3}>
          {/* Liniendiagramm */}
          <Grid item xs={12} md={12} lg={8}>
            <BigChartCardRecharts
              data={[
                {
                  id: 'Bedarf Stunden',
                  data: personalData.bedarf.map((item) => ({
                    x: item.month,
                    y: item.stundenDifferenz,
                  })),
                },
                {
                  id: 'Bedarf Köpfe',
                  data: personalData.bedarf.map((item) => ({
                    x: item.month,
                    y: item.köpfeDifferenz,
                  })),
                },
              ]}
              title="Anzahl Soll Mitarbeiter und Köpfe Differenz über die letzten 6 Monate"
            />
          </Grid>

          {/* GaugeChart für Soll-Stunden */}
          <Grid
            item
            xs={12}
            sm={6}
            lg={2}
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
          >
            <BigGaugeCard
              title="Bedarf an Soll Stunden"
              infoContent={
                <>
                  Bedarf an Soll Stunden - Informationen
                  <br />
                  <br />
                  Die Soll-Stunden werden berechnet, indem die wöchentlichen
                  Sollstunden summiert werden. Dieses Gauge zeigt den aktuellen
                  Bedarf an Soll-Stunden im Vergleich zum Zielwert.
                </>
              }
              value={gaugeData.wochenSollBerechnetSum}
              target={gaugeData.sollStunden}
              maxValue={gaugeData.sollStunden * 2} // Optional, kann angepasst werden
              description={`Aktuell wurden ${gaugeData.wochenSollBerechnetSum} Ist-Stunden berechnet, während das Ziel bei ${gaugeData.sollStunden} Soll-Stunden liegt.`}
            />
          </Grid>

          {/* GaugeChart für Soll-Köpfe */}
          <Grid
            item
            xs={12}
            sm={6}
            lg={2}
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
          >
            <BigGaugeCard
              title="Bedarf an Soll Köpfe"
              infoContent={
                <>
                  Bedarf an Soll Köpfe - Informationen
                  <br />
                  <br />
                  Die Soll-Köpfe werden berechnet, indem die Soll-Stunden durch
                  30 geteilt werden, da 30 Stunden pro Kopf als idealer Wert
                  angenommen werden. Da in jeder Filiale aufgerundet werden
                  muss, kann es vorkommen, dass die Summe der Soll-Köpfe auf
                  Unternehmensebene geringfügig von der direkten Berechnung der
                  Soll-Stunden geteilt durch 30 abweicht. Dieses Gauge zeigt den
                  aktuellen Bedarf an Soll-Stunden im Vergleich zum Zielwert.
                </>
              }
              value={gaugeData.köpfe}
              target={gaugeData.anzahlSollMitarbeiter}
              maxValue={gaugeData.anzahlSollMitarbeiter * 2} // Optional, kann angepasst werden
              description={`Aktuell wurden ${gaugeData.köpfe} Ist-Köpfe berechnet, während das Ziel bei ${gaugeData.anzahlSollMitarbeiter} Soll-Köpfen liegt.`}
            />
          </Grid>
        </Grid>

        {/* Tabelle */}
        <Grid item xs={12} md={12} mt={3}>
        <VariableTreeMUI
                  columns={columns}
                  data={tableData}
                  title="Bedarf Übersicht"
                  initialGrouping={['verkaufsgebiet']}
                  groupingHeaders={{ 'verkaufsgebiet': 'Verkaufsgebiet' }} // Neue Prop zur Anpassung des Gruppierungs-Headers
                  aggregationFunctions={aggregationFunctions}
                  onRowClick={(row) => {
                    // Handhabung des Klicks auf eine Zeile
                    console.log('Zeile geklickt:', row);
                  }}
                />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Bedarf;
