import {Box, Typography} from "@mui/material";
import {useDrag} from "react-dnd";


const DraggableText = ({artikel, handleOpenArtikelDetail}) => {
  const [{isDragging}, drag] = useDrag(() => ({
    type: 'text',
    item: {artikelData: artikel},
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const displayedText = artikel.artikel.replace(/[a-zäöüß]+/gi, word =>
      word.toLowerCase().replace(/^./, c => c.toUpperCase())
  );

  return (
      <>
        <Box
            ref={drag}
            sx={{
              flexShrink: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              opacity: isDragging ? 0.5 : 1,
              cursor: 'pointer',
              padding: 0.5,
              borderRadius: 1,
            }}
            onClick={() => handleOpenArtikelDetail(artikel)}
        >
          <Typography
              variant="body2"
              sx={{
                fontSize: '0.9rem',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                wordBreak: 'break-word',
                hyphens: 'auto',
                WebkitHyphens: 'auto',
                msHyphens: 'auto'
              }}
          >
            {displayedText}
          </Typography>
          <Typography
              variant="body2"
              sx={{
                fontSize: '0.9rem',
                color: 'success.main'
              }}
          >
            {artikel.umsatz}€
          </Typography>
        </Box>
      </>
  );
};

export default DraggableText
