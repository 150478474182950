import React, {useContext, useEffect, useState} from 'react';
import {Box, Button, Checkbox, Container, Divider, Paper, Switch, TextField, Typography,} from '@mui/material';
import DropdownFilter from "../../../components/filter/DropdownFilter";
import axiosInstance from "../../../services/axiosInstance";
import {useAuth} from "../../../provider/authProvider";
import SettingsLayout from "../../../components/layout/settings/SettingsLayout"; // Import ErrorIcon
import {InfoBarContext} from "../../../provider/InfoBar"; // Add this import
import DeleteIcon from '@mui/icons-material/Delete';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'; // Add this import


const RollenVerwalten = () => {
    const [roles, setRoles] = useState([]);  // saves all roles and their permissions
    const [roleOptions, setRoleOptions] = useState([]);  // save role names for dropdown
    const [selectedRole, setSelectedRole] = useState(null);  // stores the currently selected role for editing
    const [availableModules, setAvailableModules] = useState([]);  // stores all available modules and their permissions
    const [newRoleName, setNewRoleName] = useState('');  // stores the new role name
    const [newRoleNameVisible, setNewRoleNameVisible] = useState(false)
    const {user} = useAuth();

    const [expandedModules, setExpandedModules] = useState({});
    const [roleModules, setRoleModules] = useState([]);  // Holds module_permissions of the selected role
    const {showError, showSuccess} = useContext(InfoBarContext);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    useEffect(() => {
        const userModules = Array.isArray(user?.role?.module_permissions) ? user.role.module_permissions : [];
        setAvailableModules(userModules);
        fetchRoles();
    }, []);

    const fetchRoles = async () => {
        try {
            const response = await axiosInstance.get('/auth/roles/');
            // filter out the users role from configuration
            const userRole = user?.role?.name;
            const filteredRoles = response.data.filter(role => role.name !== userRole);
            setRoles(filteredRoles);
            setRoleOptions(filteredRoles.map(item => ({label: item.name, value: item.name, name: item.name})));
        } catch (error) {
            console.error("Error while fetching roles", error.message);
        }
    };

    const handleNewRoleName = () => {
        if (!newRoleName.trim()) {
            showError('Die Rolle muss einen Namen haben');
            setNewRoleNameVisible(false);
            return;
        }

        if (roles.some(role => role.name === newRoleName)) {
            showError(`Der Rollenname '${newRoleName}' existiert bereits`);
            setNewRoleNameVisible(false);
            return;
        }

        const newRole = {label: newRoleName, value: newRoleName, name: newRoleName};

        // Add new role to both roleOptions and roles
        setRoleOptions(prevRoleOptions => [...prevRoleOptions, newRole]);
        setRoles(prevRoles => [...prevRoles, {name: newRoleName, module_permissions: []}]);

        // Reset the new role name input
        setNewRoleName('');
        setNewRoleNameVisible(false);
        handleRoleChange(newRole)
    };

    const handleRoleChange = (role) => {
        setSelectedRole(role);

        if (role) {
            // Find the selected role in the roles array
            const selectedRoleData = roles.find(r => r.name === role.name);
            if (selectedRoleData) {
                setRoleModules(selectedRoleData.module_permissions);
            } else {
                setRoleModules([]);
            }
        } else {
            // Reset roleModules if no role is selected
            setRoleModules([]);
        }
        setExpandedModules({})
    };

    const toggleModuleExpansion = (moduleName, moduleEnabled) => {
        if (moduleEnabled) {
            setExpandedModules((prev) => ({
                ...prev,
                [moduleName]: !prev[moduleName],
            }))
        }
    };

    const openModule = (moduleName) => {
        setExpandedModules((prev) => ({
            ...prev,
            [moduleName]: true,
        }));
    };

    const closeModule = (moduleName) => {
        setExpandedModules((prev) => ({
            ...prev,
            [moduleName]: false,
        }));
    };

    const handleModuleChange = (module, moduleEnabled) => {
        if (moduleEnabled) {
            setRoleModules(prevModules => prevModules.filter(m => m.name !== module.name));
            closeModule(module.name)
        } else {
            setRoleModules(prevModules => [...prevModules, {name: module.name, submodules: [...module.submodules]}]);
            openModule(module.name)
        }
    };

    const handleSubmoduleChange = (module, submodule, submoduleEnabled) => {
        setRoleModules(prevModules => {
            const moduleIndex = prevModules.findIndex(m => m.name === module.name);
            if (moduleIndex >= 0) {
                // Module is already in roleModules
                const moduleInRole = prevModules[moduleIndex];
                if (submoduleEnabled) {
                    // Submodule is currently enabled, so disable it
                    // Remove submodule from moduleInRole.submodules
                    const newSubmodules = moduleInRole.submodules.filter(s => s !== submodule);
                    if (newSubmodules.length > 0) {
                        // Update the module's submodules
                        const newModules = [...prevModules];
                        newModules[moduleIndex] = {...moduleInRole, submodules: newSubmodules};
                        return newModules;
                    } else {
                        // No submodules left, remove the module from roleModules
                        closeModule(module.name)
                        return prevModules.filter((_, idx) => idx !== moduleIndex);
                    }
                } else {
                    // Submodule is currently disabled, so enable it
                    // Add submodule to moduleInRole.submodules
                    const newSubmodules = [...moduleInRole.submodules, submodule];
                    const newModules = [...prevModules];
                    newModules[moduleIndex] = {...moduleInRole, submodules: newSubmodules};
                    return newModules;
                }
            } else {
                // Module is not in roleModules yet
                // Add module with the submodule
                return [...prevModules, {name: module.name, submodules: [submodule]}];
            }
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("submit");

        // Ensure role name is not empty, null, or undefined
        if (!selectedRole || !selectedRole.name?.trim()) {
            console.error("Role name is empty, null, or undefined");
            showError('Rollenname darf nicht leer sein!');
            return;
        }

        // Update the roles array
        setRoles(prevRoles => {
            const roleIndex = prevRoles.findIndex(r => r.name === selectedRole.name);
            if (roleIndex >= 0) {
                const newRoles = [...prevRoles];
                newRoles[roleIndex] = {...prevRoles[roleIndex], module_permissions: roleModules};
                return newRoles;
            } else {
                return prevRoles;
            }
        });

        console.log("selectedRole", selectedRole)
        axiosInstance.put(`/auth/roles/${selectedRole.name}/update/`, {module_permissions: roleModules})
            .then(response => {
                console.log("Role updated successfully");
                showSuccess('Rolle erfolgreich aktualisiert!');
            })
            .catch(error => {
                console.error("Error updating role", error);
                showError('Fehler beim Aktualisieren der Rolle!');
            });
    };

    const handleDeleteClick = () => {
        setDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = async () => {
        setDeleteDialogOpen(false);
        if (!selectedRole) return;
        
        try {
            await axiosInstance.delete(`/auth/roles/${selectedRole.name}/delete/`);
            setRoles(prevRoles => prevRoles.filter(role => role.name !== selectedRole.name));
            setRoleOptions(prevOptions => prevOptions.filter(option => option.name !== selectedRole.name));
            setSelectedRole(null);
            showSuccess('Rolle erfolgreich gelöscht!');
        } catch (error) {
            console.error("Error deleting role", error);
            if (error.response?.data?.detail === "Cannot delete role as it is assigned to users.") {
                showError('Die Rolle kann nicht gelöscht werden, da sie Nutzern zugewiesen ist.');
            } else {
                showError('Fehler beim Löschen der Rolle!');
            }
        }
    };

    return (
        <SettingsLayout>
            <Container maxWidth="md" sx={{maxWidth: '700px !important'}}>
                <Paper>
                    <Box component="form" onSubmit={handleSubmit}>
                        <Typography variant="h4" component="h1" gutterBottom fontWeight="bold">
                            Rollen verwalten
                        </Typography>
                        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <Box sx={{display: 'flex', alignItems: 'center', flex: 1}}>
                                <DropdownFilter
                                    label={"Rollen"}
                                    options={roleOptions}
                                    value={selectedRole}
                                    onChange={handleRoleChange}
                                />
                                {selectedRole && (
                                    <Button
                                        variant="contained"
                                        color="error"
                                        onClick={handleDeleteClick}
                                        sx={{ ml: 1, minWidth: 'auto', p: 1 }}
                                    >
                                        <DeleteIcon />
                                    </Button>
                                )}
                            </Box>
                            {!newRoleNameVisible && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setNewRoleNameVisible(true)}
                                    sx={{ml: 2, mb: 1, p: 1}}
                                >
                                    Neue Rolle hinzufügen
                                </Button>
                            )}
                        </Box>
                        {newRoleNameVisible && (
                            <Box sx={{mt: 2, mb: 3, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <TextField
                                    label="Neue Rolle hinzufügen"
                                    variant="outlined"
                                    value={newRoleName}
                                    size="small"
                                    onChange={(e) => setNewRoleName(e.target.value)}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNewRoleName}
                                    sx={{mt: 0, p: 1, ml: 3}}
                                >
                                    Hinzufügen
                                </Button>
                            </Box>
                        )}


                        {selectedRole ? (
                            <Paper sx={{mt: 3, p: 1}}>
                                <Typography variant="h5" component="h2" sx={{mb: 1}}>
                                    Rechte Verwalten
                                </Typography>

                                {/* Loop through the modules */}
                                {availableModules
                                    .filter(module => module.name !== 'settings') // Filter out the settings module
                                    .map((module, index) => {
                                        // Check if this module is in roleModules
                                        const moduleInRole = roleModules.find(m => m.name === module.name);
                                        const moduleEnabled = !!moduleInRole;
                                        const submodulesInRole = moduleInRole ? moduleInRole.submodules : [];

                                        // Determine if the module has more than one submodule
                                        const hasMultipleSubmodules = module.submodules && module.submodules.length > 1;

                                        return (
                                            <React.Fragment key={module.name}>
                                                <Box sx={{mb: 2}}>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center',
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={() => {
                                                            toggleModuleExpansion(module.name, moduleEnabled);
                                                        }}
                                                    >
                                                        <Typography variant="h6">
                                                            {module.name}
                                                            {hasMultipleSubmodules && (
                                                                <span style={{
                                                                    marginLeft: '8px',
                                                                    transform: expandedModules[module.name] ? 'rotate(180deg)' : 'rotate(0deg)',
                                                                    transition: 'transform 0.2s'
                                                                }}>
                                                            ▼
                                                        </span>
                                                            )}
                                                        </Typography>
                                                        <Switch
                                                            checked={moduleEnabled}
                                                            onChange={() => handleModuleChange(module, moduleEnabled)}
                                                            color="primary"
                                                        />
                                                    </Box>
                                                    {/* Conditionally render submodules if the module is expanded */}
                                                    {expandedModules[module.name] && module.submodules?.map((submodule) => {
                                                        const submoduleEnabled = moduleEnabled && submodulesInRole.some(s => s.name === submodule.name);
                                                        return (
                                                            <Box key={submodule.name} sx={{display: 'flex', alignItems: 'center', ml: 4}}>
                                                                <Checkbox
                                                                    checked={submoduleEnabled}
                                                                    onChange={() => handleSubmoduleChange(module, submodule, submoduleEnabled)}
                                                                    color="primary"
                                                                    disabled={!moduleEnabled}
                                                                />
                                                                <Typography>{submodule.name}</Typography> {/* Accessing the 'name' property */}
                                                            </Box>
                                                        );
                                                    })}
                                                </Box>
                                                {/* Add a divider between modules, except after the last one */}
                                                {index < availableModules.length - 2 && <Divider/>}
                                            </React.Fragment>
                                        );
                                    })}
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 2}}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        Änderungen Speichern
                                    </Button>
                                </Box>
                            </Paper>

                        ) : (
                            <Typography variant="body1" color="error" sx={{mt: 2}}>
                                Bitte wähle eine Rolle aus
                            </Typography>
                        )}
                    </Box>
                </Paper>
            </Container>
            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
            >
                <DialogTitle>Rolle löschen</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Möchten Sie die Rolle "{selectedRole?.name}" wirklich löschen?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)}>Abbrechen</Button>
                    <Button onClick={handleDeleteConfirm} color="error" autoFocus>
                        Löschen
                    </Button>
                </DialogActions>
            </Dialog>
        </SettingsLayout>
    );
};

export default RollenVerwalten;
