import React, { useState } from 'react';
import { Card, Stack, Paper, Typography, IconButton, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/QueryStats';
import StarIcon from '@mui/icons-material/FormatListNumbered';
import PercentIcon from '@mui/icons-material/Percent';
import Score from '../score/Score';
import { formatPercentage, normaliseValue, roundToDecimals } from '../../utils/mathStuff';

const ComparisonCard = ({
  zeitraum,
  data,
  scoreField,
  percentageField,
  extraField1,
  extraIcon1,
  extraLabel1, 
  wachstumLabel,
  wachstumIcon,
  extraField2,
  extraIcon2,
  extraLabel2, 
  rangField,
  TiefenanalyseComponent
}) => {
  const [openTiefenAnalyse, setOpenTiefenanalyse] = useState(false);

  const scoreValue = data.length > 0 ? data[0][scoreField] : 'N/A';
  const percentageValue = data.length > 0 && percentageField ? formatPercentage(data[0][percentageField]) : 'N/A';
  const extraValue1 = data.length > 0 && extraField1 ? roundToDecimals(data[0][extraField1], 2) : 'N/A';
  const extraValue2 = data.length > 0 && extraField2 ? roundToDecimals(data[0][extraField2], 2) : 'N/A';
  const rangValue = data.length > 0 ? data[0][rangField] : 'N/A';

  const handleOpenTiefenAnalyse = () => {
    setOpenTiefenanalyse(true);
  };

  return (
    <Paper className="db_comparison_card">
      <Card
        sx={{
          padding: '0px',
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          backdropFilter: 'blur(10px)',
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            flexWrap: 'wrap',
            '@media (max-width: 900px)': {
              spacing: 2,
            },
            '@media (max-width: 600px)': {
              spacing: 1,
            },
          }}
        >
          <Typography
            variant="h6"
            sx={{
              minWidth: '100%',
              mb: 1,
              ml: 2,
            }}
          >
            {`Im Vergleich zum ${zeitraum}`}
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              flexGrow: 1,
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              spacing={0}
              sx={{
                flexWrap: 'wrap',
                justifyContent: 'center',
              }}
            >

              {/* Score mit Label "Score" darunter */}
              <Box
                sx={{
                  minWidth: '100px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  '@media (max-width: 600px)': {
                    minWidth: '80px',
                  },
                }}
              >
                <Score score={scoreValue} />
                <Typography variant="caption" sx={{ color: 'gray', mt: 0.2 }}>
                  Score
                </Typography>
              </Box>

              {/* Extra Wert 1 */}
              {extraValue1 !== 'N/A' && extraValue1 !== 0 && (
                <Box
                  sx={{
                    minWidth: '100px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '@media (max-width: 600px)': {
                      minWidth: '80px',
                    },
                  }}
                >
                  <Box display="flex" alignItems="center">
                    {extraIcon1 && extraIcon1}
                    <Typography>{extraValue1}</Typography>
                  </Box>
                  {extraLabel1 && (
                    <Typography variant="caption" sx={{ color: 'gray', mt: 0.5 }}>
                      {extraLabel1}
                    </Typography>
                  )}
                </Box>
              )}

              {/* Extra Wert 2 */}
              {extraValue2 !== 'N/A' && extraValue2 !== 0 && (
                <Box
                  sx={{
                    minWidth: '100px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '@media (max-width: 600px)': {
                      minWidth: '80px',
                    },
                  }}
                >
                  <Box display="flex" alignItems="center">
                    {extraIcon2 && extraIcon2}
                    <Typography>{extraValue2}</Typography>
                  </Box>
                  {extraLabel2 && (
                    <Typography variant="caption" sx={{ color: 'gray', mt: 0.5 }}>
                      {extraLabel2}
                    </Typography>
                  )}
                </Box>
              )}

              {/* Prozentwert */}
              {percentageValue !== 'N/A' && (
                <Box
                  sx={{
                    minWidth: '100px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '@media (max-width: 600px)': {
                      minWidth: '80px',
                    },
                  }}
                >
                  <Box display="flex" alignItems="center">
                    {wachstumIcon ? wachstumIcon : <PercentIcon sx={{ color: 'lightgray' }} />}
                    <Typography>{percentageValue}</Typography>
                  </Box>
                  <Typography variant="caption" sx={{ color: 'gray', mt: 0.5 }}>
                  {wachstumLabel? wachstumLabel : "Wachstum %"}
                  </Typography>
                </Box>
              )}

              {/* Rang */}
              <Box
                sx={{
                  minWidth: '100px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  '@media (max-width: 600px)': {
                    minWidth: '80px',
                  },
                }}
              >
                <Box display="flex" alignItems="center">
                  <StarIcon sx={{ color: 'lightgray' }} />
                  <Typography>{rangValue}</Typography>
                </Box>
                <Typography variant="caption" sx={{ color: 'gray', mt: 0.5 }}>
                  Rang
                </Typography>
              </Box>

            </Stack>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={handleOpenTiefenAnalyse} sx={{ color: 'primary.main' }}>
              <SearchIcon />
            </IconButton>
          </Box>
        </Stack>

        {openTiefenAnalyse && TiefenanalyseComponent && (
          <TiefenanalyseComponent
            data={data}
            zeitraum={zeitraum}
            scoreValue={scoreValue}
            percentageValue={percentageValue}
            rangValue={rangValue}
            open={openTiefenAnalyse}
            setOpen={setOpenTiefenanalyse}
          />
        )}
      </Card>
    </Paper>
  );
};

export default ComparisonCard;
