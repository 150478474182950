import React from 'react';
import dayjs from 'dayjs';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Typography,
  Paper,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SearchIcon from '@mui/icons-material/Search';

const FilialTable = ({
  filialeData,
  expandedRows,
  handleRowClick,
  handleOpenFilialeDialog,
}) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Filiale</TableCell>
            <TableCell>Anzahl Checks</TableCell>
            <TableCell>Durchschnittliche Bewertung</TableCell>
            <TableCell>Letztes Check-Datum</TableCell>
            <TableCell>Aktion</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filialeData.map((filiale, index) => {
            console.log(`Filiale ${index}:`, filiale); // Logge die gesamte Filialdaten

            // Berechnung des Durchschnitts basierend auf check_max
            const averageRating = filiale.totalCheckMax > 0
              ? ((filiale.totalValue / filiale.totalCheckMax) * 100).toFixed(2) + '%'
              : 'N/A';
            console.log(`Average Rating (Filiale ${index}):`, averageRating); // Logge den berechneten Durchschnitt

            const lastCheckDate = filiale.lastCheckDate
              ? dayjs(filiale.lastCheckDate).format('DD.MM.YYYY')
              : 'N/A';
            console.log(`Last Check Date (Filiale ${index}):`, lastCheckDate); // Logge das letzte Check-Datum

            return (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell>
                    <IconButton size="small" onClick={() => handleRowClick(index)}>
                      {expandedRows[index] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                  </TableCell>
                  <TableCell>{filiale.filiale} ({filiale.filialnummer})</TableCell>
                  <TableCell>{filiale.sessions.size}</TableCell>
                  <TableCell>{averageRating}</TableCell>
                  <TableCell>{lastCheckDate}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleOpenFilialeDialog(filiale)}>
                      <SearchIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
                {expandedRows[index] && (
                  <TableRow>
                    <TableCell colSpan={6}>
                      {/* Details pro Check-Art */}
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Check-Art</TableCell>
                            <TableCell>Anzahl Checks</TableCell>
                            <TableCell>Durchschnittliche Bewertung</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filiale.checks.map((check, idx) => {
                            console.log(`Filiale ${index} - Check ${idx}:`, check);

                            // Berechnung des Durchschnitts basierend auf check_max
                            const averageCheckRating = check.totalCheckMax > 0
                              ? ((check.totalValue / check.totalCheckMax) * 100).toFixed(2) + '%'
                              : 'N/A';
                            console.log(`Average Check Rating (Filiale ${index}, Check ${idx}):`, averageCheckRating);

                            return (
                              <TableRow key={idx}>
                                <TableCell>{check.checkName}</TableCell>
                                <TableCell>{check.sessions.size}</TableCell>
                                <TableCell>{averageCheckRating}</TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FilialTable;
