import React, { useContext, useEffect, useState, useMemo } from 'react';
import {
  Box,
  Grid,
  Typography,
  Stack,
  Card,
  Paper,
  CardHeader,
  CardContent,
} from '@mui/material';
import Layout from '../../../components/layout/Scorecards/Unternehmen/UnternehmenMonat';
import DataCard from '../../../components/card/scorecardsmonat/DataCardMonatPage';
import { FilterContext } from '../../../provider/FilterProvider';
import axiosInstance from '../../../services/axiosInstance';
import dayjs from 'dayjs';
import VariableTreeMUI from '../../../components/table/VariableTableTreeMUI'; // Angepasste Komponente importieren
import BigChartCard from '../../../components/card/scorecardsmonat/BigChartCardUnternehmen';
import { useTheme } from '@mui/material/styles'; // Importiere das Thema
import BigChartCardRecharts from '../../../components/card/scorecardsmonat/BigChartCardRecharts';
import {
  formatPercentage,
  tausenderTrennung,
  formatCurrency,
} from '../../../utils/mathStuff';

const Krankenquote = () => {
  const { filterState } = useContext(FilterContext);
  const [datumOptions, setDatumOptions] = useState([]);
  const theme = useTheme(); // Zugriff auf das Theme
  const [personalData, setPersonalData] = useState({
    krankenquote: [],
    fluktuation: [],
  });
  const [tableData, setTableData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [salesAreaData, setSalesAreaData] = useState([]); // Neuer State für die Krankenquote pro Verkaufsgebiet
  const [lineChartData, setLineChartData] = useState([]); // Daten für das Liniendiagramm der Verkaufsgebiete

  useEffect(() => {
    fetchDatumOptions();
  }, []);

  useEffect(() => {
    if (filterState.datum) {
      fetchPersonalData(); // Krankenquote- und Fluktuationsdaten abrufen
      fetchTableData(); // Daten für die Tabelle abrufen
    }
  }, [filterState]);

  const fetchDatumOptions = async () => {
    try {
      const response = await axiosInstance.get(
        '/stammdaten/unique-jahr-monat/'
      );
      setDatumOptions(
        response.data.map((item) => ({ label: item, value: item }))
      );
    } catch (error) {
      console.error('Error while fetching datum options:', error);
    }
  };

  // Helper function to calculate the last 6 months based on the selected date
  const getLast6Months = (currentMonth) => {
    const last6Months = [];
    const formattedMonth = dayjs(currentMonth, 'YYYY.MM'); // Das gefilterte Datum korrekt formatieren

    for (let i = 0; i < 6; i++) {
      last6Months.push(formattedMonth.subtract(i, 'month').format('YYYY.MM')); // Rückwirkend vom gefilterten Monat die letzten 6 Monate berechnen
    }

    return last6Months.reverse(); // Optional, um die Monate in chronologischer Reihenfolge anzuzeigen (älteste zuerst)
  };

  const fetchPersonalData = async () => {
    const currentMonth =
      filterState.datum?.value || dayjs().format('YYYY.MM'); // Verwende das gefilterte Datum
    const last6Months = getLast6Months(currentMonth); // Letzten 6 Monate berechnen

    try {
      const responses = await Promise.all(
        last6Months.map((month) =>
          axiosInstance.get('/scorecards_month/personal-kennzahl/', {
            params: { jahrMonat: month },
          })
        )
      );

      // Kumulieren der Krankenquote-Daten
      const krankenquoteData = responses.map((response, index) => {
        const data = response.data.results;

        const arbeitszeitKrankenqoute = data.reduce(
          (sum, item) => sum + (item.arbeitszeitKrankenqoute || 0),
          0
        );
        const krankenzeit = data.reduce(
          (sum, item) => sum + (item.krankenzeit || 0),
          0
        );
        const arbeitszeitKrankenqouteVM = data.reduce(
          (sum, item) => sum + (item.arbeitszeitKrankenqouteVM || 0),
          0
        );
        const krankenzeitVM = data.reduce(
          (sum, item) => sum + (item.krankenzeitVM || 0),
          0
        );
        const arbeitszeitKrankenqouteVJ = data.reduce(
          (sum, item) => sum + (item.arbeitszeitKrankenqouteVJ || 0),
          0
        );
        const krankenzeitVJ = data.reduce(
          (sum, item) => sum + (item.krankenzeitVJ || 0),
          0
        );

        // Berechne die Krankenquote
        const krankenquote =
          arbeitszeitKrankenqoute > 0
            ? (krankenzeit / arbeitszeitKrankenqoute) * 100
            : 0;
        const krankenquoteVM =
          arbeitszeitKrankenqouteVM > 0
            ? (krankenzeitVM / arbeitszeitKrankenqouteVM) * 100
            : 0;
        const krankenquoteVJ =
          arbeitszeitKrankenqouteVJ > 0
            ? (krankenzeitVJ / arbeitszeitKrankenqouteVJ) * 100
            : 0;

        return {
          month: last6Months[index],
          arbeitszeitKrankenqoute,
          krankenzeit,
          krankenquote,
          krankenquoteVM,
          krankenquoteVJ,
        };
      });

      // Kumulieren der Fluktuation-Daten (wie in der funktionierenden Seite)
      const fluktuationData = responses.map((response, index) => {
        const data = response.data.results;

        const austritte6M = data.reduce(
          (sum, item) => sum + (item.austritte6M || 0),
          0
        );
        const mitarbeiterbestand6M = data.reduce(
          (sum, item) => sum + (item.mitarbeiterbestand6M || 0),
          0
        );
        const eintritte6M = data.reduce(
          (sum, item) => sum + (item.eintritte6M || 0),
          0
        );
        const köpfeDifferenz = data.reduce(
          (sum, item) => sum + (item.köpfeDifferenz || 0),
          0
        );
        const stundenDifferenz = data.reduce(
          (sum, item) => sum + (item.stundenDifferenz || 0),
          0
        );
        const überstundenAbs = data.reduce(
          (sum, item) => sum + (item.überstundenAbs || 0),
          0
        );

        // Berechne die Fluktuation
        const fluktuation =
          mitarbeiterbestand6M + eintritte6M > 0
            ? (austritte6M / (mitarbeiterbestand6M + eintritte6M)) * 100
            : 0;

        // Eintritte und Austritte im gefilterten Monat
        const eintritteM = data.reduce(
          (sum, item) => sum + (item.eintritteM || 0),
          0
        );
        const austritteM = data.reduce(
          (sum, item) => sum + (item.austritteM || 0),
          0
        );

        return {
          month: last6Months[index],
          fluktuation,
          eintritteM,
          austritteM,
          köpfeDifferenz,
          stundenDifferenz,
          überstundenAbs,
        };
      });

      // Berechnung der Krankenquote pro Verkaufsgebiet
      const salesAreaData = last6Months.map((month, index) => {
        const data = responses[index].data.results;

        // Gruppiere die Daten nach Verkaufsgebiet
        const salesAreaGroup = {};

        data.forEach((item) => {
          const salesArea = item.verkaufsgebiet;
          if (!salesAreaGroup[salesArea]) {
            salesAreaGroup[salesArea] = {
              arbeitszeitKrankenqoute: 0,
              krankenzeit: 0,
            };
          }
          salesAreaGroup[salesArea].arbeitszeitKrankenqoute +=
            item.arbeitszeitKrankenqoute || 0;
          salesAreaGroup[salesArea].krankenzeit += item.krankenzeit || 0;
        });

        // Berechne die Krankenquote pro Verkaufsgebiet
        const salesAreaRates = {};
        Object.keys(salesAreaGroup).forEach((salesArea) => {
          const arbeitszeitKrankenqoute =
            salesAreaGroup[salesArea].arbeitszeitKrankenqoute;
          const krankenzeit = salesAreaGroup[salesArea].krankenzeit;
          const krankenquote =
            arbeitszeitKrankenqoute > 0
              ? (krankenzeit / arbeitszeitKrankenqoute) * 100
              : 0;
          salesAreaRates[salesArea] = {
            arbeitszeitKrankenqoute,
            krankenzeit,
            krankenquote,
          };
        });

        return {
          month,
          salesAreaRates,
        };
      });

      setPersonalData({
        krankenquote: krankenquoteData,
        fluktuation: fluktuationData,
      });

      setSalesAreaData(salesAreaData); // Setze die Daten für die Krankenquote pro Verkaufsgebiet

      // Daten für das Liniendiagramm vorbereiten
      // Erstelle für jedes Verkaufsgebiet eine Datenserie
      const salesAreas = new Set();
      salesAreaData.forEach((monthData) => {
        Object.keys(monthData.salesAreaRates).forEach((salesArea) => {
          salesAreas.add(salesArea);
        });
      });

      const lineChartData = Array.from(salesAreas).map((salesArea) => {
        return {
          id: salesArea,
          data: salesAreaData.map((monthData) => {
            const krankenquote = monthData.salesAreaRates[salesArea]
              ? monthData.salesAreaRates[salesArea].krankenquote
              : null; // Falls keine Daten vorhanden sind
            return {
              x: monthData.month,
              y: krankenquote,
            };
          }),
        };
      });

      setLineChartData(lineChartData); // Setze die Daten für das Liniendiagramm

      // Daten für das Chart vorbereiten (gesamt)
      const chartData = krankenquoteData.filter((item) => item.month).flatMap(
        (item) => [
          { x: item.month, y: item.krankenquote, label: 'Krankenquote' },
          { x: item.month, y: item.krankenquoteVJ, label: 'Vorjahr' },
        ]
      );

      setChartData(chartData);
    } catch (error) {
      console.error('Error fetching personal data:', error);
    }
  };

  const fetchTableData = async () => {
    const currentMonth = filterState.datum?.value || dayjs().format('YYYY.MM');

    try {
      const response = await axiosInstance.get(
        '/scorecards_month/personal-kennzahl/',
        {
          params: { jahrMonat: currentMonth },
        }
      );

      const data = response.data.results;

      // Tabelle formatieren
      const formattedTableData = data.map((item, index) => {
        const arbeitszeitKrankenqoute = item.arbeitszeitKrankenqoute || 0;
        const krankenzeit = item.krankenzeit || 0;
        const arbeitszeitKrankenqouteVM = item.arbeitszeitKrankenqouteVM || 0;
        const krankenzeitVM = item.krankenzeitVM || 0;
        const arbeitszeitKrankenqouteVJ = item.arbeitszeitKrankenqouteVJ || 0;
        const krankenzeitVJ = item.krankenzeitVJ || 0;

        // Berechne die Krankenquote
        const krankenquote =
          arbeitszeitKrankenqoute > 0
            ? (krankenzeit / arbeitszeitKrankenqoute) * 100
            : 0;
        const krankenquoteVM =
          arbeitszeitKrankenqouteVM > 0
            ? (krankenzeitVM / arbeitszeitKrankenqouteVM) * 100
            : 0;
        const krankenquoteVJ =
          arbeitszeitKrankenqouteVJ > 0
            ? (krankenzeitVJ / arbeitszeitKrankenqouteVJ) * 100
            : 0;

        return {
          id: `${item.verkaufsgebiet}-${item.filiale}-${index}`, // Eindeutige ID
          verkaufsgebiet: item.verkaufsgebiet,
          filiale: item.filiale,
          scoreKrankenquote: item.scoreKrankenquote || 0,
          krankenquote: krankenquote,
          krankenquoteVM: krankenquoteVM,
          krankenquoteVJ: krankenquoteVJ,
          krankenzeit: krankenzeit,
          krankenzeitVM: krankenzeitVM,
          krankenzeitVJ: krankenzeitVJ,
          arbeitszeit: arbeitszeitKrankenqoute,
          arbeitszeitVM: arbeitszeitKrankenqouteVM,
          arbeitszeitVJ: arbeitszeitKrankenqouteVJ,
        };
      });

      setTableData(formattedTableData);
    } catch (error) {
      console.error('Error fetching table data:', error);
    }
  };

  // Definition der Spalten für die Tabelle
  const columns = useMemo(
    () => [
      {
        field: 'verkaufsgebiet',
        headerName: 'Verkaufsgebiet',
        align: 'left',
        hide: false,
      },
      {
        field: 'filiale',
        headerName: 'Filiale',
        align: 'right',
        hide: false,
      },
      {
        field: 'scoreKrankenquote',
        headerName: 'Score',
        type: 'number',
        align: 'right',
        valueFormatter: (params) => tausenderTrennung(params.value, 2),
        hide: false,
      },
      {
        field: 'krankenquote',
        headerName: 'Krankenquote',
        type: 'number',
        align: 'right',
        valueFormatter: (params) =>
          tausenderTrennung(params.value, 2) + '%',
        hide: false,
      },
      {
        field: 'krankenquoteVM',
        headerName: 'Krankenquote VM',
        type: 'number',
        align: 'right',
        valueFormatter: (params) =>
          tausenderTrennung(params.value, 2) + '%',
        hide: false,
      },
      {
        field: 'krankenquoteVJ',
        headerName: 'Krankenquote VJ',
        type: 'number',
        align: 'right',
        valueFormatter: (params) =>
          tausenderTrennung(params.value, 2) + '%',
        hide: false,
      },
      {
        field: 'krankenzeit',
        headerName: 'Krankenzeit',
        type: 'number',
        align: 'right',
        valueFormatter: (params) => tausenderTrennung(params.value, 2),
        hide: false,
      },
      {
        field: 'arbeitszeit',
        headerName: 'Arbeitszeit',
        type: 'number',
        align: 'right',
        valueFormatter: (params) => tausenderTrennung(params.value, 2),
        hide: false,
      },
    ],
    []
  );

  // Definition der Aggregationsfunktionen
  const aggregationFunctions = {
    scoreKrankenquote: (items) => {
      // Durchschnitt der scoreKrankenquote
      const total = items.reduce(
        (sum, item) => sum + (item.scoreKrankenquote || 0),
        0
      );
      return items.length > 0 ? total / items.length : 0;
    },
    krankenquote: (items) => {
      // Krankenquote basierend auf aggregierten Werten
      const aggregatedKrankenzeit = items.reduce(
        (sum, item) => sum + (item.krankenzeit || 0),
        0
      );
      const aggregatedArbeitszeit = items.reduce(
        (sum, item) => sum + (item.arbeitszeit || 0),
        0
      );
      const krankenquote =
        aggregatedArbeitszeit > 0
          ? (aggregatedKrankenzeit / aggregatedArbeitszeit) * 100
          : 0;
      return krankenquote;
    },
    krankenquoteVM: (items) => {
      // Krankenquote VM basierend auf aggregierten Werten
      const aggregatedKrankenzeitVM = items.reduce(
        (sum, item) => sum + (item.krankenzeitVM || 0),
        0
      );
      const aggregatedArbeitszeitVM = items.reduce(
        (sum, item) => sum + (item.arbeitszeitVM || 0),
        0
      );
      const krankenquoteVM =
        aggregatedArbeitszeitVM > 0
          ? (aggregatedKrankenzeitVM / aggregatedArbeitszeitVM) * 100
          : 0;
      return krankenquoteVM;
    },
    krankenquoteVJ: (items) => {
      // Krankenquote VJ basierend auf aggregierten Werten
      const aggregatedKrankenzeitVJ = items.reduce(
        (sum, item) => sum + (item.krankenzeitVJ || 0),
        0
      );
      const aggregatedArbeitszeitVJ = items.reduce(
        (sum, item) => sum + (item.arbeitszeitVJ || 0),
        0
      );
      const krankenquoteVJ =
        aggregatedArbeitszeitVJ > 0
          ? (aggregatedKrankenzeitVJ / aggregatedArbeitszeitVJ) * 100
          : 0;
      return krankenquoteVJ;
    },
    krankenzeit: (items) => {
      // Summe der krankenzeit
      return items.reduce((sum, item) => sum + (item.krankenzeit || 0), 0);
    },
    arbeitszeit: (items) => {
      // Summe der arbeitszeit
      return items.reduce((sum, item) => sum + (item.arbeitszeit || 0), 0);
    },
  };

  return (
    <Layout>
      <Grid container justifyContent="flex-end" alignItems="stretch">
        <Grid item xs={12}>
          <Stack
            direction="row"
            alignItems="stretch"
            spacing={2}
            sx={{ overflowX: 'auto' }}
          >
            {/* Krankenquote */}
            <Grid item xs={12} md={6} lg={3} className="db_datacard_active">
              <DataCard
                category="Krankenquote"
                sector="personal"
                value={
                  personalData.krankenquote.length > 0
                    ? personalData.krankenquote[
                        personalData.krankenquote.length - 1
                      ].krankenquote.toFixed(2) + '%'
                    : '0.00%'
                }
                subHeaders={[
                  `${
                    personalData.krankenquote.length > 0
                      ? personalData.krankenquote[
                          personalData.krankenquote.length - 1
                        ].krankenquoteVM.toFixed(2)
                      : '0.00'
                  }% zum Vormonat`,
                  `${
                    personalData.krankenquote.length > 0
                      ? personalData.krankenquote[
                          personalData.krankenquote.length - 1
                        ].krankenquoteVJ.toFixed(2)
                      : '0.00'
                  }% zum Vorjahr`,
                ]}
                chartData={personalData.krankenquote.map(
                  (item) => item.krankenquote
                )} // Nutze die Krankenquote-Daten für den Sparkline-Chart
              />
            </Grid>

            {/* Fluktuation */}
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              className="db_datacard_not_active"
            >
              <DataCard
                category="Fluktuation"
                sector="personal"
                value={
                  personalData.fluktuation.length > 0
                    ? personalData.fluktuation[
                        personalData.fluktuation.length - 1
                      ].fluktuation.toFixed(2) + '%'
                    : '0.00%'
                }
                subHeaders={[
                  `${
                    personalData.fluktuation.length > 0
                      ? personalData.fluktuation[
                          personalData.fluktuation.length - 1
                        ].eintritteM
                      : '0'
                  } Eintritte im gefilterten Monat`,
                  `${
                    personalData.fluktuation.length > 0
                      ? personalData.fluktuation[
                          personalData.fluktuation.length - 1
                        ].austritteM
                      : '0'
                  } Austritte im gefilterten Monat`,
                ]}
                chartData={personalData.fluktuation.map(
                  (item) => item.fluktuation
                )} // Nutze die Fluktuation-Daten für den Sparkline-Chart
              />
            </Grid>

            {/* Bedarf */}
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              className="db_datacard_not_active"
            >
              <DataCard
                category="Bedarf"
                sector="personal"
                value={`${
                  personalData.fluktuation.length > 0
                    ? personalData.fluktuation[
                        personalData.fluktuation.length - 1
                      ].köpfeDifferenz
                    : '0'
                } Köpfe und ${
                  personalData.fluktuation.length > 0
                    ? personalData.fluktuation[
                        personalData.fluktuation.length - 1
                      ].stundenDifferenz
                    : '0'
                } Stunden`}
                chartData={personalData.fluktuation.map(
                  (item) => item.köpfeDifferenz
                )}
              />
            </Grid>

            {/* Auslastung */}
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              className="db_datacard_not_active"
            >
              <DataCard
                category="Auslastung"
                sector="personal"
                value={`${
                  personalData.fluktuation.length > 0
                    ? personalData.fluktuation[
                        personalData.fluktuation.length - 1
                      ].überstundenAbs
                    : '0'
                } Überstunden`}
                subHeaders={[]}
                chartData={personalData.fluktuation.map(
                  (item) => item.überstundenAbs
                )}
              />
            </Grid>
          </Stack>
        </Grid>

        {/* Liniendiagramm */}
        <Grid container mt={3} spacing={2}>
          <Grid item xs={12} lg={6}>
            <BigChartCard
              data={chartData}
              title="Krankenquote und Krankenquote Vorjahr der letzten 6 Monate"
              xKey="month"
              yKeys={['krankenquote', 'krankenquoteVJ']}
              labels={['Krankenquote', 'Krankenquote VJ']}
            />
          </Grid>

          {/* Liniendiagramm für Krankenquote pro Verkaufsgebiet */}
          <Grid item xs={12} lg={6}>
            <BigChartCardRecharts
              data={lineChartData}
              title="Krankenquote pro Verkaufsgebiet über die letzten 6 Monate"
            />
          </Grid>
        </Grid>

        {/* Tabelle */}
        <Grid item xs={12} md={12} mt={3}>
        <VariableTreeMUI
                  columns={columns}
                  data={tableData}
                  title="Krankenquote Übersicht"
                  initialGrouping={['verkaufsgebiet']}
                  groupingHeaders={{ 'verkaufsgebiet': 'Verkaufsgebiet' }} // Neue Prop zur Anpassung des Gruppierungs-Headers
                  aggregationFunctions={aggregationFunctions}
                  onRowClick={(row) => {
                    // Handhabung des Klicks auf eine Zeile
                    console.log('Zeile geklickt:', row);
                  }}
                />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Krankenquote;
