// Bericht.jsx
import React, { useState, useEffect } from 'react';
import {
  Grid,
  CircularProgress,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  TextField,
  Button,
} from '@mui/material';
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import Layout from '../../../components/layout/Qualität/Qualität';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import axiosInstance from '../../../services/axiosInstance';

const Bericht = () => {
  const [loading, setLoading] = useState(true);
  const [artikelBerichte, setArtikelBerichte] = useState([]);
  const [groupBy, setGroupBy] = useState('artikel'); // 'artikel', 'warengruppe', 'filiale'
  const [timeRange, setTimeRange] = useState('day'); // 'day', 'week', 'month'
  const [startDate, setStartDate] = useState(''); // Startdatum für den Zeitraum
  const [endDate, setEndDate] = useState(''); // Enddatum für den Zeitraum

  // Optionen für Dropdowns
  const [warengruppeOptions, setWarengruppeOptions] = useState([]);
  const [artikelOptions, setArtikelOptions] = useState([]);
  const [filialeOptions, setFilialeOptions] = useState([]);

  // Filterzustände
  const [filterWarengruppe, setFilterWarengruppe] = useState('');
  const [filterArtikel, setFilterArtikel] = useState('');
  const [filterFiliale, setFilterFiliale] = useState('');

  // Daten für das Diagramm
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    fetchArtikelBerichte();
  }, []);

  useEffect(() => {
    prepareChartData();
  }, [artikelBerichte, groupBy, timeRange, filterWarengruppe, filterArtikel, filterFiliale]);

  // Funktion zum Abrufen der Artikel-Tagesberichte
  const fetchArtikelBerichte = async () => {
    try {
      const response = await axiosInstance.get('/qualitaet/artikel-tagesberichte/');
      const data = Array.isArray(response.data) ? response.data : response.data.results || [];
      setArtikelBerichte(data);
      setLoading(false);

      // Extrahieren der Optionen für Dropdowns
      const warengruppenSet = new Set();
      const artikelSet = new Set();
      const filialeSet = new Set();

      data.forEach((item) => {
        if (item.warengruppe_name) warengruppenSet.add(item.warengruppe_name);
        if (item.artikel_name) artikelSet.add(item.artikel_name);
        if (item.filiale) filialeSet.add(item.filiale);
      });

      setWarengruppeOptions(Array.from(warengruppenSet));
      setArtikelOptions(Array.from(artikelSet));
      setFilialeOptions(Array.from(filialeSet));
    } catch (error) {
      console.error('Fehler beim Abrufen der Artikel-Tagesberichte:', error);
      setLoading(false);
    }
  };

  // Funktion zur Vorbereitung der Daten für das Diagramm
  const prepareChartData = () => {
    let filteredData = artikelBerichte;

    // Filter anwenden
    if (filterWarengruppe) {
      filteredData = filteredData.filter(
        (item) => item.warengruppe_name === filterWarengruppe
      );
    }

    if (filterArtikel) {
      filteredData = filteredData.filter((item) => item.artikel_name === filterArtikel);
    }

    if (filterFiliale) {
      filteredData = filteredData.filter((item) => item.filiale === filterFiliale);
    }

    // Zeitraumauswahl anwenden
    if (startDate && endDate) {
      filteredData = filteredData.filter((item) => {
        const itemDate = new Date(item.datum);
        return itemDate >= new Date(startDate) && itemDate <= new Date(endDate);
      });
    }

    // Gruppierung anwenden
    const groupedData = {};
    filteredData.forEach((item) => {
      let key = '';
      switch (groupBy) {
        case 'artikel':
          key = item.artikel_name;
          break;
        case 'warengruppe':
          key = item.warengruppe_name;
          break;
        case 'filiale':
          key = item.filiale;
          break;
        default:
          key = item.artikel_name;
      }

      if (!groupedData[key]) {
        groupedData[key] = 0;
      }
      groupedData[key] += item.anzahl_meldungen;
    });

    // Daten für das Diagramm vorbereiten
    const chartDataArray = Object.keys(groupedData).map((key) => ({
      name: key,
      anzahlMeldungen: groupedData[key],
    }));

    setChartData(chartDataArray);
  };

  return (
    <Layout>
      <Grid container spacing={2} sx={{ p: 3 }}>
        <Grid item xs={12}>
          <PageHeader
            title="Qualitätsberichte"
            subtitle="Analysiere Artikelmeldungen über verschiedene Zeiträume und Kategorien."
          />
        </Grid>

        {/* Filter und Optionen */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Grid container spacing={2} alignItems="center">
              {/* Gruppierungsauswahl */}
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Gruppieren nach</InputLabel>
                  <Select
                    value={groupBy}
                    label="Gruppieren nach"
                    onChange={(e) => setGroupBy(e.target.value)}
                  >
                    <MenuItem value="artikel">Artikel</MenuItem>
                    <MenuItem value="warengruppe">Warengruppe</MenuItem>
                    <MenuItem value="filiale">Filiale</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Zeitraumauswahl */}
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Zeitraum</InputLabel>
                  <Select
                    value={timeRange}
                    label="Zeitraum"
                    onChange={(e) => setTimeRange(e.target.value)}
                  >
                    <MenuItem value="day">Tag</MenuItem>
                    <MenuItem value="week">Woche</MenuItem>
                    <MenuItem value="month">Monat</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Startdatum */}
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Startdatum"
                  type="date"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </Grid>

              {/* Enddatum */}
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Enddatum"
                  type="date"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </Grid>

              {/* Warengruppe Filter */}
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Warengruppe</InputLabel>
                  <Select
                    value={filterWarengruppe}
                    label="Warengruppe"
                    onChange={(e) => setFilterWarengruppe(e.target.value)}
                  >
                    <MenuItem value="">
                      <em>Alle</em>
                    </MenuItem>
                    {warengruppeOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Artikel Filter */}
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Artikel</InputLabel>
                  <Select
                    value={filterArtikel}
                    label="Artikel"
                    onChange={(e) => setFilterArtikel(e.target.value)}
                  >
                    <MenuItem value="">
                      <em>Alle</em>
                    </MenuItem>
                    {artikelOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Filiale Filter */}
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Filiale</InputLabel>
                  <Select
                    value={filterFiliale}
                    label="Filiale"
                    onChange={(e) => setFilterFiliale(e.target.value)}
                  >
                    <MenuItem value="">
                      <em>Alle</em>
                    </MenuItem>
                    {filialeOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Aktualisieren Button */}
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={prepareChartData}
                >
                  Aktualisieren
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Diagramm */}
        <Grid item xs={12}>
          {loading ? (
            <CircularProgress />
          ) : chartData.length > 0 ? (
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>
                Anzahl der Meldungen nach {groupBy}
              </Typography>
              <ResponsiveContainer width="100%" height={400}>
                {timeRange === 'day' || timeRange === 'week' ? (
                  <BarChart data={chartData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis allowDecimals={false} />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="anzahlMeldungen" fill="#8884d8" />
                  </BarChart>
                ) : (
                  <LineChart data={chartData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis allowDecimals={false} />
                    <Tooltip />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="anzahlMeldungen"
                      stroke="#8884d8"
                      activeDot={{ r: 8 }}
                    />
                  </LineChart>
                )}
              </ResponsiveContainer>
            </Paper>
          ) : (
            <Typography variant="body1">Keine Daten verfügbar.</Typography>
          )}
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Bericht;
