import React, { useState } from "react";
import { Box, Card, CardContent, CardHeader, IconButton, Dialog, DialogContent } from "@mui/material";
import PictureDeleteButton from "./PictureDeleteButton";
import FullscreenIcon from '@mui/icons-material/Fullscreen';

const FilePreviewCard = ({ file, afterPictureDeleteFunction }) => {
  const { url, type, id } = file;
  
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenLargeDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          width: 400,
          height: 400,
          mr: 2,
          position: "relative",
          flexShrink: 0
        }}
      >
        <CardHeader
          action={
            <Box>
              <IconButton onClick={handleOpenLargeDialog} aria-label="fullscreen-dialog">
                <FullscreenIcon />
              </IconButton>

              <PictureDeleteButton
                pictureId={id}
                afterPictureDeleteFunction={afterPictureDeleteFunction}
              />
            </Box>
          }
          sx={{
            padding: '8px'
          }}
        />

        <CardContent sx={{ flex: 1, padding: 0 }}>
          <object
            data={url}
            type={type}
            width="100%"
            height="100%"
            style={{ cursor: "pointer", objectFit: "contain" }}
          >
            <p>Your browser does not support media viewing.</p>
          </object>
        </CardContent>
      </Card>

      {/* Großer Dialog zur Anzeige des Mediums */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="xl"
        fullWidth
        // fullScreen // optional, um den gesamten Bildschirm zu nutzen
        sx={{
          '& .MuiDialog-paper': {
            height: '90vh', // Höhe des Dialogs steuern
            maxHeight: '90vh' // Optional: maximale Höhe
          }
        }}
      >
        <DialogContent sx={{ p: 0 }}>
          <object
            data={url}
            type={type}
            width="100%"
            height="100%"
            style={{ objectFit: "contain" }}
          >
            <p>Your browser does not support media viewing.</p>
          </object>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FilePreviewCard;
