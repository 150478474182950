import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    CircularProgress,
    Grid,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Paper,
    Snackbar,
    Alert,
    Checkbox,
    ListItemText,
    Card,
    CardHeader,
    CardContent
} from '@mui/material';
import axiosInstance from '../../services/axiosInstance';

const WarengruppeAssignment = () => {
    const [warengruppen, setWarengruppen] = useState([]);
    const [customGroups, setCustomGroups] = useState([]);
    const [assignments, setAssignments] = useState({});
    const [loading, setLoading] = useState(true);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

    const fetchData = async () => {
        try {
            const [warengruppeResponse, customGroupResponse, assignmentsResponse] = await Promise.all([
                axiosInstance.get('/stammdaten/unique-warengruppe/'),
                axiosInstance.get('/auth/custom-groups/'),
                axiosInstance.get('/qualitaet/artikel-warengruppe-customgroup/'),
            ]);

            // Warengruppen
            setWarengruppen(warengruppeResponse.data);

            // Benutzergruppen
            setCustomGroups(customGroupResponse.data);

            // Bestehende Zuweisungen
            let assignmentsData = assignmentsResponse.data;

            // Überprüfen, ob assignmentsData ein Array ist
            if (!Array.isArray(assignmentsData)) {
                if (Array.isArray(assignmentsData.results)) {
                    assignmentsData = assignmentsData.results;
                } else {
                    console.error('Unerwartetes Format der assignments-Antwort:', assignmentsData);
                    assignmentsData = [];
                }
            }

            const assignmentMapping = {};
            assignmentsData.forEach((assignment) => {
                const wgId = assignment.warengruppe;
                const cgId = assignment.custom_group;
                if (assignmentMapping[wgId]) {
                    assignmentMapping[wgId].push(cgId);
                } else {
                    assignmentMapping[wgId] = [cgId];
                }
            });
            setAssignments(assignmentMapping);
        } catch (error) {
            console.error('Fehler beim Laden der Daten:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleAssignmentChange = async (warengruppeId, selectedCustomGroupIds) => {
        try {
            // Aktuelle Zuweisungen
            const currentAssignments = assignments[warengruppeId] || [];

            // Hinzuzufügende Zuweisungen
            const toAdd = selectedCustomGroupIds.filter((id) => !currentAssignments.includes(id));
            // Zu entfernende Zuweisungen
            const toRemove = currentAssignments.filter((id) => !selectedCustomGroupIds.includes(id));

            // Neue Zuweisungen erstellen
            for (const customGroupId of toAdd) {
                await axiosInstance.post('/qualitaet/artikel-warengruppe-customgroup/', {
                    warengruppe: warengruppeId,
                    custom_group: customGroupId,
                });
            }

            // Bestehende Zuweisungen löschen
            for (const customGroupId of toRemove) {
                // Finde die Assignment-ID
                const assignment = await axiosInstance.get('/qualitaet/artikel-warengruppe-customgroup/', {
                    params: {
                        warengruppe: warengruppeId,
                        custom_group: customGroupId,
                    },
                });
                if (assignment.data && assignment.data.length > 0) {
                    const assignmentId = assignment.data[0].id;
                    await axiosInstance.delete(`/qualitaet/artikel-warengruppe-customgroup/${assignmentId}/`);
                }
            }

            // Zustand aktualisieren
            setAssignments({
                ...assignments,
                [warengruppeId]: selectedCustomGroupIds,
            });
            setSnackbar({ open: true, message: 'Zuweisungen erfolgreich gespeichert', severity: 'success' });
        } catch (error) {
            console.error('Fehler beim Aktualisieren der Zuweisungen:', error);
            setSnackbar({ open: true, message: 'Fehler beim Speichern der Zuweisungen', severity: 'error' });
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <Paper>
            <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    padding: '0px',
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    backdropFilter: 'blur(10px)',
                }}
            >
                <CardHeader
                    title={
                        <Typography variant="h6" component="div">
                            Warengruppen Zuweisung zu Benutzergruppen
                        </Typography>
                    }
                />
                <CardContent sx={{ flexGrow: 1 }}>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <Box sx={{ mt: 4 }}>
                            <Grid container spacing={2}>
                                {warengruppen.map((wg) => (
                                    <Grid item xs={12} md={6} key={wg.warengruppennummer}>
                                        <FormControl fullWidth sx={{ mb: 2 }}>
                                            <InputLabel id={`warengruppe-${wg.warengruppennummer}-label`}>
                                                {wg.warengruppe}
                                            </InputLabel>
                                            <Select
                                                labelId={`warengruppe-${wg.warengruppennummer}-label`}
                                                multiple
                                                value={assignments[wg.warengruppennummer] || []}
                                                label={wg.warengruppe}
                                                onChange={(e) =>
                                                    handleAssignmentChange(
                                                        wg.warengruppennummer,
                                                        e.target.value
                                                    )
                                                }
                                                renderValue={(selected) =>
                                                    selected
                                                        .map(
                                                            (id) =>
                                                                customGroups.find((group) => group.id === id)?.name
                                                        )
                                                        .join(', ')
                                                }
                                            >
                                                {customGroups.map((group) => (
                                                    <MenuItem key={group.id} value={group.id}>
                                                        <Checkbox
                                                            checked={
                                                                assignments[wg.warengruppennummer]?.includes(group.id) ||
                                                                false
                                                            }
                                                        />
                                                        <ListItemText primary={group.name} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    )}
                    <Snackbar
                        open={snackbar.open}
                        autoHideDuration={3000}
                        onClose={handleCloseSnackbar}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    >
                        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                            {snackbar.message}
                        </Alert>
                    </Snackbar>
                </CardContent>
            </Card>
        </Paper>
    );
};

export default WarengruppeAssignment;
