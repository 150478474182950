import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Box} from '@mui/material';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import dayjs from "dayjs";
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import {useLocation, useNavigate} from 'react-router-dom';
import {InfoBarContext} from "../../../provider/InfoBar";

import PageHeader from '../../../components/layout/Title/TitelSmall';
import SortimentLayout from "../../../components/layout/Sortiment/SortimentLayout";
import ThekeHeader from "../../../components/header/ThekeHeader";
import ThekeControls from "../../../components/theke/ThekeControls";
import ThekeContent from "../../../components/theke/ThekeContent";
import ThekeSettingsPopup from "../../../components/popup/ThekeSettingsPopup";
import ArtikelDetailPopup from "../../../components/popup/ArtikelDetailPopup";
import PlanNamePopup from '../../../components/popup/PlanNamePopup';

import axiosInstance from "../../../services/axiosInstance";
import {fetchTeigGeschmackOptions} from "../../../services/teigGeschmackService";

const ThekeNewPage = () => {
  const [allArtikel, setAllArtikel] = useState([]); // all possible artikel to choose from
  const [selectedArtikel, setSelectedArtikel] = useState([])  // artikel in the planner
  const [filteredArtikel, setFilteredArtikel] = useState([]); // artikel filtered by sortiment type and warengruppe
  const [sortimentType, setSortimentType] = useState(''); // type of sortiment to filter by
  const [filter, setFilter] = useState(null); // warengruppe to filter by
  const [warengruppen, setWarengruppen] = useState([]);  // available warengruppen
  const [fetchedWarengruppen, setFetchedWarengruppen] = useState([]); // to track what warengruppen already have been fetched
  const [teigOptions, setTeigOptions] = useState([]);  // teig options to choose from
  const [geschmackOptions, setGeschmackOptions] = useState([]); // geschmack options to choose from
  const [initialLoading, setInitialLoading] = useState(true); // to track if the initial data is still loading
  const [additionalDataLoading, setAdditionalDataLoading] = useState(false); // to track if additional data is still loading
  const [currentlySavingData, setCurrentlySavingData] = useState(false)  // to track if data is being saved
  const [planStartDatum, setPlanStartDatum] = useState(null);  // start date of the plan
  const [planEndDatum, setPlanEndDatum] = useState(null);  // end date of the plan
  const [showOverview, setShowOverview] = useState(false);  // trigger to show the overview of all warengruppen
  const [showArtikelDetail, setShowArtikelDetail] = useState(false); // trigger to show the artikel detail popup
  const [artikelToShow, setArtikelToShow] = useState(null);  // specifices the artikel to show in detail view
  const [settingsOpen, setSettingsOpen] = useState(false); // trigger to show the settings popup
  const [sectionCounts, setSectionCounts] = useState({}); // section counts for each warengruppe
  const [sortimentsID, setSortimentsID] = useState(null); // ID of the sortiments plan currently editing
  const {showError, showSuccess} = useContext(InfoBarContext);  // show error and success messages
  const location = useLocation();
  const aggDataEndDatum = dayjs().startOf('month'); // Start of the current month
  const aggDataStartDatum = aggDataEndDatum.subtract(6, 'months');
  const defaultSectionCount = 9
  const navigate = useNavigate();
  const [showNamePopup, setShowNamePopup] = useState(false);
  const [pendingSortimentsPlanData, setPendingSortimentsPlanData] = useState(null);
  const [planName, setPlanName] = useState('');

  // fetches all artikel, with teig, Geschmack etc, and the Warengruppen
  const fetchData = async () => {
    try {
      const [warengruppenResponse, thekeArtikel] = await Promise.all([
        axiosInstance.get('/stammdaten/stammdatenwarengruppe/'),
        axiosInstance.get('/theke/artikel-properties/')
      ]);

      const warengruppenData = warengruppenResponse.data.results;
      const thekeArtikelData = thekeArtikel.data.results || thekeArtikel.data;

      // console.log("warengruppenData base", warengruppenData)
      // console.log("thekeArtikelData base", thekeArtikelData)

      setAllArtikel(thekeArtikelData)

      if (Array.isArray(warengruppenData)) {
        setWarengruppen(warengruppenData.filter(warengruppe => warengruppe.warengruppennummer < 7));
      } else {
        console.error('Unerwartetes Antwortformat für Warengruppen:', warengruppenResponse.data);
        showError("Fehler beim Laden der Daten");
        setWarengruppen([]);
      }
    } catch (error) {
      console.error('Fehler beim Laden der Daten:', error);
      showError("Fehler beim Laden der Daten");
      setWarengruppen([]);
      setAllArtikel([]);
    }
  };

  // fetches existing data based on the ID provided and then set the selected artikel to that
  const fetchExistingArtikel = async (ID) => {
    try {
      const response = await axiosInstance.get(`/theke/sortiment-plans/${ID}/`);
      const responseSortimentPlan = response.data.warengruppen_plans;
      setPlanName(response.data.name || '');

      // console.log("raw sortiments response", responseSortimentPlan);

      const fetchedSectionCounts = responseSortimentPlan
          .filter(item => item.slot_count !== defaultSectionCount)
          .reduce((acc, item) => ({
            ...acc,
            [item.warengruppennummer]: item.slot_count
          }), {});
      setSectionCounts(fetchedSectionCounts)

      // First group by warengruppe
      const groupedByWarengruppe = responseSortimentPlan.reduce((acc, warengruppe) => {
        acc[warengruppe.warengruppennummer] = warengruppe.artikel;
        return acc;
      }, {});

      // Then process each group into final format
      const finalSelectedArtikel = Object.entries(groupedByWarengruppe).map(([warengruppennummer, artikel]) => ({
        id: parseInt(warengruppennummer),
        artikel: artikel.map(wArtikel => {
          // Find matching artikel in allArtikel array
          const artikelData = allArtikel.find(a => a.artikelnummer === wArtikel.artikelnummer);

          // Merge the data from both sources
          const mergedArtikelData = {
            ...wArtikel, // Keep the data from the sortiment plan (including sortiment_type)
            ...artikelData, // Overlay with any matching data from allArtikel
            artikelnummer: wArtikel.artikelnummer,
            artikelbezeichnung: artikelData?.artikelbezeichnung || wArtikel.artikel_name,
            sortiment_type: wArtikel.sortiment_type || 'standard'
          };

          return {
            artikelData: mergedArtikelData
          };
        })
      }));
      // console.log("final existing artikel", finalSelectedArtikel)
      setSelectedArtikel(finalSelectedArtikel);
    } catch (error) {
      console.error('Fehler beim Laden der Daten:', error);
      showError("Fehler beim Laden der Daten");
      setAllArtikel([]);
    }
  }

  // Init function to fetch data based on the query params
  const initializeData = async () => {
    setInitialLoading(true);
    const params = new URLSearchParams(location.search);
    const start = params.get('startDate');
    const end = params.get('endDate');
    const ID = params.get('id');
    // if a date is already set
    if (start && end) {
      setPlanStartDatum(dayjs(start));
      setPlanEndDatum(dayjs(end));
    }
    if (ID) {
      setSortimentsID(ID);
    }
    await fetchData();
    const [fetchedTeigOptions, fetchedGeschmackOptions] = await fetchTeigGeschmackOptions()
    setTeigOptions(fetchedTeigOptions);
    setGeschmackOptions(fetchedGeschmackOptions);
  };

  useEffect(() => {
    initializeData();
  }, [location.search]);

  useEffect(() => {
    if (sortimentsID) {
      fetchExistingArtikel(sortimentsID);
    } else {
      // if no ID given, initialize selectedArtikel with empty arrays
      setSelectedArtikel(
          warengruppen.map(wg => ({id: wg.warengruppennummer, artikel: []}))
      );
    }
    setInitialLoading(false);
  }, [warengruppen, sortimentsID]);

  useEffect(() => {
    if (!additionalDataLoading && !initialLoading) {
      const filteredArtikel = selectedArtikel.filter(wg => wg.id === filter);
      const filteredArtikelData = filteredArtikel[0]?.artikel.filter(artikel =>
          sortimentType === '' || artikel.artikelData.sortiment_type === sortimentType
      ) || [];

      setFilteredArtikel(filteredArtikelData);
    }
  }, [sortimentType, filter, selectedArtikel]);

  async function fetchWarengruppenArtikel({warengruppennummer}) {
    setAdditionalDataLoading(true);

    try {
      const response = await axiosInstance.get('/fact/aggregated/by-week-warengruppe-artikel/', {
        params: {
          start_date: aggDataStartDatum.format('YYYY-MM-DD'),
          end_date: aggDataEndDatum.format('YYYY-MM-DD'),
          warengruppennummer: JSON.stringify(warengruppennummer),
        }
      });

      const weekData = response.data.results || response.data;

      // Aggregate data in a single pass
      const processedData = weekData.reduce((acc, item) => {
        const key = item.artikelnummer__artikelbezeichnung;
        if (!acc[key]) {
          acc[key] = {
            artikelbezeichnung: key,
            total_umsatz: 0,
            total_retourwert: 0,
            total_lieferwert: 0,
            total_korrekturwert: 0,
            count: 0
          };
        }

        const entry = acc[key];
        entry.total_umsatz += item.total_umsatz;
        entry.total_retourwert += item.total_retourwert;
        entry.total_lieferwert += item.total_lieferwert;
        entry.total_korrekturwert += item.total_korrekturwert;
        entry.count++;
        return acc;
      }, {});

      // Convert processedData to a Map for O(1) access
      const newArtikelMap = new Map();
      for (const item of Object.values(processedData)) {
        newArtikelMap.set(item.artikelbezeichnung, item);
      }

      // Reusable function to merge and calculate data for allArtikel
      const mergeAllArtikel = (artikelList) => {
        return artikelList
            .map(oldArtikel => {
              const newArtikel = newArtikelMap.get(oldArtikel.artikelbezeichnung);
              if (!newArtikel) {
                return {
                  ...oldArtikel,
                  umsatz: oldArtikel.umsatz || 0,
                  retoure: oldArtikel.retoure || 0,
                };
              }
              return {
                ...oldArtikel,
                umsatz: Math.round(newArtikel.total_umsatz / newArtikel.count),
                retoure: Math.round(
                    (newArtikel.total_retourwert /
                        (newArtikel.total_lieferwert + newArtikel.total_korrekturwert)) * 1000
                ) / 10,
              };
            })
            .sort((a, b) => b.umsatz - a.umsatz);
      };

      // THis looks really wierd and complicated.
      const mergeSelectedArtikel = (selectedArtikelList) => {
        return selectedArtikelList.map(item => {
          return {
            ...item,
            artikel: item.artikel.map(artikelItem => {
              return {
                ...artikelItem,
                artikelData: {
                  ...artikelItem.artikelData,
                  artikel: artikelItem.artikelData.artikel,
                  umsatz: (() => {
                    const newArtikel = newArtikelMap.get(artikelItem.artikelData.artikelbezeichnung);
                    return newArtikel
                        ? Math.round(newArtikel.total_umsatz / newArtikel.count)
                        : artikelItem.artikelData.umsatz || 0;
                  })(),
                  retoure: (() => {
                    const newArtikel = newArtikelMap.get(artikelItem.artikelData.artikelbezeichnung);
                    return newArtikel
                        ? Math.round(
                        (newArtikel.total_retourwert /
                            (newArtikel.total_lieferwert + newArtikel.total_korrekturwert)) *
                        1000
                    ) / 10
                        : artikelItem.artikelData.retoure || 0;
                  })(),
                },
              };
            }),
          };
        });
      };

      const mergedData = mergeAllArtikel(allArtikel);
      const mergedSelectedArtikel = mergeSelectedArtikel(selectedArtikel);

      // Update state
      setAllArtikel(mergedData);
      setSelectedArtikel(mergedSelectedArtikel);
      setFetchedWarengruppen(prev => [...prev, warengruppennummer]);

    } catch (error) {
      console.error("Fehler beim Laden der Daten", error);
      showError("Fehler beim Laden der Daten");
    } finally {
      setAdditionalDataLoading(false);
    }
  }

  const handleSectionCountChange = (warengruppeId, count) => {
    setSectionCounts(prev => ({...prev, [warengruppeId]: count}));
  };

  const handleOpenArtikelDetail = useCallback((artikel) => {
    setArtikelToShow(artikel);
    setShowArtikelDetail(true);
  }, []);

  const handleCloseArtikelDetail = useCallback(() => {
    setShowArtikelDetail(false);
    // Clear the artikel data after animation completes
    setTimeout(() => setArtikelToShow(null), 300);
  }, []);

  const handleDrop = (warengruppeId, artikelData) => {
    setSelectedArtikel(prevSelected =>
        prevSelected.map(wg =>
            wg.id === warengruppeId
                ? {...wg, artikel: [...wg.artikel, artikelData]}
                : wg,
        ),
    );
  };

  const handleRemove = (warengruppeId, artikelToRemove) => {
    if (!warengruppeId || !artikelToRemove) return

    const updatedArtikel = selectedArtikel.map(wg => {
      if (wg.id === warengruppeId) {
        return {
          ...wg,
          artikel: wg.artikel.filter(artikel =>
              artikel.artikelData.artikelnummer !== artikelToRemove.artikelnummer
          )
        }
      }
      return wg;
    })
    setSelectedArtikel(updatedArtikel)
  };

  const handleWarengruppenChange = async (warengruppennummer) => {
    if (!additionalDataLoading && !initialLoading) {
      setFilter(warengruppennummer);
      setShowOverview(false);
      // only fetch new Data if not already fetched
      if (!fetchedWarengruppen.includes(warengruppennummer)) {
        await fetchWarengruppenArtikel({warengruppennummer: warengruppennummer});
      }
    } else {
      showError("Bitte warten Sie, bis die Daten geladen sind.");
    }
  }

  const handleArtikelUpdate = (updatedArtikel) => {
    setAllArtikel(updatedArtikel);
  };

  const handleSave = async () => {
    if (currentlySavingData || initialLoading || additionalDataLoading) {
      showError("Bitte warten Sie, bis die Daten geladen sind.");
      return;
    }
    if (!planStartDatum || !planEndDatum) {
      showError("Bitte wählen Sie zuerst ein Start- und Enddatum.");
      return;
    }

    const sortimentsPlanData = {
      start_datum: planStartDatum.format('YYYY-MM-DD'),
      end_datum: planEndDatum.format('YYYY-MM-DD'),
      global_plan: true,
      filialnummer: null,
      warengruppen_plans: warengruppen.map(wg => {
        const selected = selectedArtikel.find(sa => sa.id === wg.warengruppennummer);
        return {
          warengruppennummer: wg.warengruppennummer,
          slot_count: sectionCounts[wg.warengruppennummer] || 9,
          artikel: selected?.artikel.map(artikelWrapper => {
            const artikelData = artikelWrapper.artikelData;
            return {
              artikelnummer: artikelData?.artikelnummer,
              artikel_name: artikelData?.artikelbezeichnung,
              angebot: false,
              sortiment_type: artikelData?.sortiment_type || 'wechsel',
            };
          }) || []
        };
      })
    };

    if (!sortimentsID) {
      setPendingSortimentsPlanData(sortimentsPlanData);
      setShowNamePopup(true);
      return;
    }

    await savePlan(sortimentsPlanData);
  };

  const savePlan = async (planData) => {
    setCurrentlySavingData(true);
    try {
      if (sortimentsID) {
        await axiosInstance.put(`/theke/sortiment-plans/${sortimentsID}/`, planData);
      } else {
        const response = await axiosInstance.post('/theke/sortiment-plans/', planData);
        const newId = response.data.id;
        setSortimentsID(newId);
        const params = new URLSearchParams(location.search);
        params.set('id', newId);
        navigate(`?${params.toString()}`, {replace: true});
      }
      showSuccess('Sortimentsplan erfolgreich gespeichert.');
    } catch (error) {
      console.error('Fehler beim Speichern:', error);
      showError('Fehler beim Speichern der Daten.');
    } finally {
      setCurrentlySavingData(false);
    }
  };

  const handleNameSave = async (name) => {
    if (pendingSortimentsPlanData) {
      const dataWithName = {
        ...pendingSortimentsPlanData,
        name: name
      };
      setPlanName(name);
      setShowNamePopup(false);
      await savePlan(dataWithName);
      setPendingSortimentsPlanData(null);
    }
  };

  return (
      <SortimentLayout>
        <Box sx={{p: 1, mr: 2}}>
          <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2}}>
            <PageHeader
                title="Sortimentsplanung"
                subtitle="Dieses Modul unterstützt dich bei der Sortimentsplanung."
                Icon={StorefrontOutlinedIcon}
            />
          </Box>

          <ThekeControls
              onOverviewClick={() => {
                setShowOverview(true);
                setFilter(null);
              }}
              onSettingsClick={() => setSettingsOpen(true)}
              onSave={handleSave}
              planStartDatum={planStartDatum}
              planEndDatum={planEndDatum}
              planName={planName}
              savingData={currentlySavingData}
              sortimentType={sortimentType}
              setSortimentType={setSortimentType}
          />

          <ThekeHeader
              warengruppen={warengruppen}
              filter={filter}
              handleWarengruppenChange={handleWarengruppenChange}
          />

          <DndProvider backend={HTML5Backend}>
            <ThekeContent
                showOverview={showOverview}
                filter={filter}
                warengruppen={warengruppen}
                sectionCounts={sectionCounts}
                handleDrop={handleDrop}
                selectedArtikel={selectedArtikel}
                setFilter={setFilter}
                setShowOverview={setShowOverview}
                allArtikel={allArtikel}
                sortimentType={sortimentType}
                handleOpenArtikelDetail={handleOpenArtikelDetail}
                handleRemove={handleRemove}
                filteredArtikel={filteredArtikel}
            />

            <ThekeSettingsPopup
                open={settingsOpen}
                onClose={() => setSettingsOpen(false)}
                warengruppen={warengruppen}
                sectionCounts={sectionCounts}
                selectedArtikel={selectedArtikel}
                onSectionCountChange={handleSectionCountChange}
            />
            {showArtikelDetail && (
                <ArtikelDetailPopup
                    open={showArtikelDetail}
                    onClose={handleCloseArtikelDetail}
                    artikel={artikelToShow}
                    teigOptions={teigOptions}
                    geschmackOptions={geschmackOptions}
                    setArtikel={handleArtikelUpdate}
                />
            )}
            <PlanNamePopup
              open={showNamePopup}
              onClose={() => {
                setShowNamePopup(false);
                setPendingSortimentsPlanData(null);
              }}
              onSave={handleNameSave}
            />
          </DndProvider>
        </Box>
      </SortimentLayout>
  );
};

export default ThekeNewPage;
