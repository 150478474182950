// FilialcheckAnalyseManagementPage.jsx
import React, { useState, useEffect, useContext } from 'react';
import {
  Grid, Typography, CircularProgress, Card,
  CardHeader, Stack, CardContent, Dialog, DialogTitle, IconButton, DialogContent,
  TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody,
} from '@mui/material';
import ChecklistIcon from '@mui/icons-material/Checklist';
import axiosInstance from '../../../services/axiosInstance';
import dayjs from 'dayjs';
import Layout from '../../../components/layout/Filialcheck/Filialcheck';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import BigChartCardRecharts from '../../../components/card/scorecardsmonat/BigChartCardRecharts';
import DropdownFilter from '../../../components/filter/DropdownFilter';
import { FilterContext } from '../../../provider/FilterProvider';
import CloseIcon from '@mui/icons-material/Close';

// Import der neuen Komponenten
import FilialDetailDialog from '../../../components/Filialcheck/Analyse/FilialDetailDialog';
import ImageDialog from '../../../components/Filialcheck/Analyse/ImageDialog';
import FilialTable from '../../../components/Filialcheck/Analyse/FilialTable';
import ActiveChecks from '../../../components/Filialcheck/Analyse/ActiveChecks';

const FilialcheckAnalyseManagementPage = () => {
  const [loading, setLoading] = useState(true);
  const [activeChecks, setActiveChecks] = useState([]);
  const [checkpointsMap, setCheckpointsMap] = useState({});
  const [categoriesMap, setCategoriesMap] = useState({});
  const [error, setError] = useState(null);
  const [filialen, setFilialen] = useState([]);
  const [filialeData, setFilialeData] = useState([]);
  const [checkCounts, setCheckCounts] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedCheck, setSelectedCheck] = useState(null);
  const [verkaufsleiterChecks, setVerkaufsleiterChecks] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});
  const [chartData, setChartData] = useState([]);
  const [datumOptions, setDatumOptions] = useState([]);
  const [checkOptions, setCheckOptions] = useState([]);
  const [verkaufsgebiete, setVerkaufsgebiete] = useState([]);
  const [selectedVerkaufsgebiet, setSelectedVerkaufsgebiet] = useState(null);
  const [selectedFiliale, setSelectedFiliale] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);

  const { filterState, handleDatumChange } = useContext(FilterContext);
  const [selectedFilterCheck, setSelectedFilterCheck] = useState({ label: 'Alle Checks', value: 'all' });

  // Zustände für die Bildanzeige
  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState('');



  
  useEffect(() => {
    const fetchFilialen = async () => {
      try {
        const response = await axiosInstance.get('/stammdaten/stammdatenfiliale/');
        const filialenData = response.data.results || [];
        setFilialen(filialenData);

        // Extrahieren der einzigartigen Verkaufsgebiete
        const uniqueVerkaufsgebiete = Array.from(new Set(filialenData.map(filiale => filiale.verkaufsgebiet))).filter(Boolean);
        setVerkaufsgebiete(uniqueVerkaufsgebiete.map(vg => ({ label: vg, value: vg })));
      } catch (error) {
        console.error('Fehler beim Abrufen der Filialdaten:', error);
        setError('Fehler beim Laden der Filialdaten.');
      } finally {
        setLoading(false);
      }
    };

    fetchFilialen();
  }, []);

  useEffect(() => {
    const fetchActiveChecks = async () => {
      try {
        const response = await axiosInstance.get('/filialcheck/checks/');
        const activeChecksData = response.data.results.filter(check => check.is_active);
        setActiveChecks(activeChecksData);
        setLoading(false);

        // Check-Optionen für den Filter vorbereiten
        const checkOptionsData = [
          { label: 'Alle Checks', value: 'all' },
          ...activeChecksData.map(check => ({ label: check.name, value: check.id }))
        ];
        setCheckOptions(checkOptionsData);

      } catch (error) {
        setError('Fehler beim Laden der aktiven Checks.');
        setLoading(false);
      }
    };

    fetchActiveChecks();
  }, []);

  useEffect(() => {
    const fetchCheckpointsAndCategories = async () => {
      try {
        const response = await axiosInstance.get('/filialcheck/checkpoints/');
        const checkpointsData = response.data.results;

        // Map der Checkpoints erstellen
        const cpMap = {};
        checkpointsData.forEach(cp => {
          cpMap[cp.id] = cp;
        });
        setCheckpointsMap(cpMap);

        // Kategorien extrahieren
        const categories = checkpointsData.map(cp => cp.category).filter(Boolean);
        const uniqueCategories = Array.from(new Set(categories.map(cat => cat.id)));
        const categoryMap = {};
        uniqueCategories.forEach(catId => {
          const cat = categories.find(c => c.id === catId);
          if (cat) {
            categoryMap[cat.id] = cat;
          }
        });
        setCategoriesMap(categoryMap);

      } catch (error) {
        console.error('Fehler beim Abrufen der Checkpoints:', error);
      }
    };

    fetchCheckpointsAndCategories();
  }, []);

  useEffect(() => {
    const fetchDatumOptions = async () => {
      try {
        const response = await axiosInstance.get('/stammdaten/unique-jahr-monat/');
        setDatumOptions(response.data.map(item => ({ label: item, value: item })));
      } catch (error) {
        console.error('Fehler beim Abrufen der Datum-Optionen:', error);
      }
    };

    fetchDatumOptions();
  }, []);

  useEffect(() => {
    const fetchChecksPerMonth = async () => {
      try {
        if (!filterState.datum) return;

        // API-Parameter vorbereiten
        const params = {
          jahrMonat: filterState.datum.value
        };

        if (selectedFilterCheck.value !== 'all') {
          params.check_related = selectedFilterCheck.value;
        }

        if (selectedVerkaufsgebiet) {
          params.verkaufsgebiet = selectedVerkaufsgebiet.value;
        }

        const response = await axiosInstance.get('/filialcheck/ratings/', { params });
        const ratings = response.data.results;

        // Anreicherung der Ratings mit Filial- und Verkaufsgebiets-Daten
        const enrichedRatings = ratings.map(rating => {
          const filialeData = filialen.find(f => f.filialnummer === rating.filialnummer);
          return {
            ...rating,
            filialeName: filialeData ? filialeData.filiale : 'Unbekannt',
            verkaufsgebiet: filialeData ? filialeData.verkaufsgebiet : 'Unbekannt',
          };
        });

        // Gruppierung der Daten pro Filiale
        const filialeDataMap = {};
        const checkCountsMap = {};

        enrichedRatings.forEach(rating => {
          const { filialnummer, filialeName, verkaufsgebiet, session_id, value, check_related, checkpoint, datum, comment, image_id, rating_type, check_max } = rating;

          // Berechnung des prozentualen Wertes basierend auf check_max
          const percentageValue = check_max > 0 ? (value / check_max) * 100 : 0;

          // Initialisierung der Filialdaten, falls noch nicht vorhanden
          if (!filialeDataMap[filialnummer]) {
            filialeDataMap[filialnummer] = {
              filialnummer,
              filiale: filialeName,
              verkaufsgebiet,
              sessions: new Set(),
              totalValue: 0,
              totalCheckMax: 0,
              yesNoCounts: { ja: 0, nein: 0 },
              checks: {},
              lastCheckDate: null,
            };
          }

          filialeDataMap[filialnummer].sessions.add(session_id);

          if (rating_type === 'yesNo') {
            if (value === 1) {
              filialeDataMap[filialnummer].yesNoCounts.ja += 1;
            } else if (value === 0) {
              filialeDataMap[filialnummer].yesNoCounts.nein += 1;
            }
          } else {
            filialeDataMap[filialnummer].totalValue += value;
            filialeDataMap[filialnummer].totalCheckMax += check_max;
          }

          // Aktualisierung des letzten Check-Datums
          if (!filialeDataMap[filialnummer].lastCheckDate || dayjs(datum).isAfter(filialeDataMap[filialnummer].lastCheckDate)) {
            filialeDataMap[filialnummer].lastCheckDate = datum;
          }

          // Check-Daten initialisieren, falls noch nicht vorhanden
          if (!filialeDataMap[filialnummer].checks[check_related]) {
            const checkInfo = activeChecks.find(ac => ac.id === check_related);
            filialeDataMap[filialnummer].checks[check_related] = {
              checkId: check_related,
              checkName: checkInfo ? checkInfo.name : 'Unbekannt',
              sessions: new Set(),
              totalValue: 0,
              totalCheckMax: 0,
              yesNoCounts: { ja: 0, nein: 0 },
              categories: {},
              ratingType: checkInfo ? checkInfo.rating_type : 'unknown',
            };
          }
          const checkData = filialeDataMap[filialnummer].checks[check_related];
          checkData.sessions.add(session_id);

          if (rating_type === 'yesNo') {
            if (value === 1) {
              checkData.yesNoCounts.ja += 1;
            } else if (value === 0) {
              checkData.yesNoCounts.nein += 1;
            }
          } else {
            checkData.totalValue += value;
            checkData.totalCheckMax += check_max;
          }

          // Checkpoint-Info
          const checkpointInfo = checkpointsMap[checkpoint];
          if (!checkpointInfo) return;

          const categoryId = checkpointInfo.category ? checkpointInfo.category.id : 'Unbekannt';
          const categoryName = checkpointInfo.category ? checkpointInfo.category.name : 'Unbekannt';

          // Kategorie-Daten initialisieren, falls noch nicht vorhanden
          if (!checkData.categories[categoryId]) {
            checkData.categories[categoryId] = {
              categoryId,
              categoryName,
              checkpoints: {},
            };
          }
          const categoryData = checkData.categories[categoryId];

          // Checkpoint-Daten initialisieren, falls noch nicht vorhanden
          if (!categoryData.checkpoints[checkpoint]) {
            categoryData.checkpoints[checkpoint] = {
              checkpointId: checkpoint,
              checkpointName: checkpointInfo.name,
              ratings: [],
            };
          }
          const checkpointData = categoryData.checkpoints[checkpoint];

          // Bewertung hinzufügen
          checkpointData.ratings.push({
            value,
            percentageValue,
            datum,
            comment,
            image_id,
            ratingType: rating_type,
          });

          // Check-Counts aktualisieren
          if (!checkCountsMap[check_related]) {
            checkCountsMap[check_related] = new Set();
          }
          checkCountsMap[check_related].add(session_id);
        });

        const filialeDataArray = Object.values(filialeDataMap).map(filiale => {
          return {
            ...filiale,
            checks: Object.values(filiale.checks),
          };
        });

        setFilialeData(filialeDataArray);

        // Setzen der Check Counts
        setCheckCounts(checkCountsMap);

      } catch (error) {
        console.error('Fehler beim Laden der Bewertungsdaten:', error);
        setError('Fehler beim Laden der Bewertungsdaten.');
      }
    };

    if (filialen.length > 0 && Object.keys(checkpointsMap).length > 0) {
      fetchChecksPerMonth();
    }
  }, [filialen, checkpointsMap, selectedFilterCheck, filterState.datum, selectedVerkaufsgebiet]);

  useEffect(() => {
    const fetchChartData = async () => {
      try {
        if (!filterState.datum) return;

        const selectedMonth = dayjs(filterState.datum.value, 'YYYY.MM');
        const months = [];

        // Generiere die letzten 6 Monate
        for (let i = 5; i >= 0; i--) {
          months.push(selectedMonth.subtract(i, 'month').format('YYYY.MM'));
        }

        const allChartData = [];

        for (const month of months) {
          // API-Parameter vorbereiten
          const params = {
            jahrMonat: month
          };

          if (selectedFilterCheck.value !== 'all') {
            params.check_related = selectedFilterCheck.value;
          }

          if (selectedVerkaufsgebiet) {
            params.verkaufsgebiet = selectedVerkaufsgebiet.value;
          }

          const response = await axiosInstance.get('/filialcheck/ratings/', { params });
          const ratings = response.data.results;

          // Anreicherung der Ratings mit Filial- und Verkaufsgebiets-Daten
          const enrichedRatings = ratings.map(rating => {
            const filialeData = filialen.find(f => f.filialnummer === rating.filialnummer);
            return {
              ...rating,
              filialeName: filialeData ? filialeData.filiale : 'Unbekannt',
              verkaufsgebiet: filialeData ? filialeData.verkaufsgebiet : 'Unbekannt',
            };
          });

          // Gruppierung der Daten nach Filiale und Monat, Zählen einzigartiger session_ids
          const groupedData = {};

          enrichedRatings.forEach(rating => {
            const filialeName = rating.filialeName || 'Unbekannt';

            if (!groupedData[filialeName]) {
              groupedData[filialeName] = {
                filialeName,
                sessions: new Set(),
              };
            }

            groupedData[filialeName].sessions.add(rating.session_id);
          });

          // Vorbereitung der Daten für BigChartCardRecharts
          Object.values(groupedData).forEach(data => {
            const existingSeries = allChartData.find(series => series.id === data.filialeName);
            if (existingSeries) {
              existingSeries.data.push({
                x: month,
                y: data.sessions.size,
              });
            } else {
              allChartData.push({
                id: data.filialeName,
                data: [{
                  x: month,
                  y: data.sessions.size,
                }],
              });
            }
          });
        }

        // Sicherstellen, dass alle Serien für alle Monate Werte haben
        allChartData.forEach(series => {
          months.forEach(month => {
            if (!series.data.find(d => d.x === month)) {
              series.data.push({ x: month, y: 0 });
            }
          });
          // Sortieren der Datenpunkte nach Monat
          series.data.sort((a, b) => months.indexOf(a.x) - months.indexOf(b.x));
        });

        setChartData(allChartData);

      } catch (error) {
        console.error('Fehler beim Laden der Chartdaten:', error);
      }
    };

    if (filialen.length > 0 && filterState.datum) {
      fetchChartData();
    }

  }, [filialen, filterState.datum, selectedFilterCheck, selectedVerkaufsgebiet]);

  const handleCheckClick = (check) => {
    setSelectedCheck(check);
    const checkData = filialeData.filter(filiale => filiale.checks.some(c => c.checkId === check.id));
    const filialeCounts = {};
    checkData.forEach(filiale => {
      const checkInfo = filiale.checks.find(c => c.checkId === check.id);
      if (checkInfo) {
        if (!filialeCounts[filiale.filialnummer]) {
          filialeCounts[filiale.filialnummer] = {
            filialeName: filiale.filiale,
            count: 0,
          };
        }
        filialeCounts[filiale.filialnummer].count += checkInfo.sessions.size; // Anzahl der Checks auf Filialebene
      }
    });

    const filialeCountsArray = Object.values(filialeCounts);

    setVerkaufsleiterChecks(filialeCountsArray);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedCheck(null);
    setSelectedFiliale(null);
    setSelectedTab(0);
  };

  const handleOpenFilialeDialog = (filiale) => {
    setSelectedFiliale(filiale);
    setDialogOpen(true);
    setSelectedTab(0);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleRowClick = (index) => {
    setExpandedRows(prev => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  // Funktion zum Abrufen der Bild-URL
  const handleImageClick = async (imageId) => {
    if (!imageId) {
      console.error('Keine Bild-ID verfügbar');
      return;
    }

    try {
      const response = await axiosInstance.get(`/pictures/get-image-url/${imageId}/`);
      setImageUrl(response.data.url);
      setImageDialogOpen(true);
    } catch (error) {
      console.error('Fehler beim Abrufen der Bild-URL:', error);
    }
    

  };

  if (loading) {
    return (
      <Layout>
        <Grid container spacing={2} sx={{ p: 3 }}>
          <Grid item xs={12}>
            <PageHeader
              title="Verkaufsgebiet-Analyse"
              subtitle="Nutze die Analysen der durchgeführten Filialchecks, um wertvolle Einblicke zu gewinnen und gezielt Verbesserungen voranzutreiben."
              Icon={ChecklistIcon}
            />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <CircularProgress />
          </Grid>
        </Grid>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <Grid container spacing={2} sx={{ p: 3 }}>
          <Grid item xs={12}>
            <PageHeader
              title="Verkaufsgebiet-Analyse"
              subtitle="Nutze die Analysen der durchgeführten Filialchecks, um wertvolle Einblicke zu gewinnen und gezielt Verbesserungen voranzutreiben."
              Icon={ChecklistIcon}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" color="error">{error}</Typography>
          </Grid>
        </Grid>
      </Layout>
    );
  }

  return (
    <Layout>
      <Grid container item spacing={2} alignItems={"center"} sx={{ p: 3 }}>
        <Grid item xs={6} >
          <PageHeader
            title="Verkaufsgebiet-Analyse"
            subtitle="Nutze die Analysen der durchgeführten Filialchecks, um wertvolle Einblicke zu gewinnen und gezielt Verbesserungen voranzutreiben."
            Icon={ChecklistIcon}
          />
        </Grid>

        {/* Auswahl für die Filter */}
        <Grid item xs={12} md={6}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={2}
          >
            <DropdownFilter
              label="Datum"
              options={datumOptions}
              value={filterState.datum}
              onChange={handleDatumChange}
            />
            <DropdownFilter
              label="Check filtern"
              options={checkOptions}
              value={selectedFilterCheck}
              onChange={setSelectedFilterCheck}
            />
            <DropdownFilter
              label="Verkaufsgebiet"
              options={verkaufsgebiete}
              value={selectedVerkaufsgebiet}
              onChange={setSelectedVerkaufsgebiet}
            />
          </Stack>
        </Grid>

        {/* Zeile mit Linechart und den aktiven Checks */}
        <Grid container item spacing={2}>
          {/* Chart */}
          <Grid item xs={12} md={8}>
            <BigChartCardRecharts
              data={chartData}
              title="Anzahl der Checks (Sessions) pro Filiale der letzten sechs Monate"
              infoContent="Dieses Diagramm zeigt die Anzahl der durchgeführten Checks (basierend auf Sessions) pro Filiale über die letzten sechs Monate."
            />
          </Grid>

          {/* Aktive Checks */}
          <Grid item xs={12} md={4}>
            <ActiveChecks
              activeChecks={activeChecks}
              handleCheckClick={handleCheckClick}
              checkCounts={checkCounts}
            />
          </Grid>
        </Grid>

        {/* Dialog für Details */}
        <Dialog open={dialogOpen && !!selectedCheck} onClose={handleCloseDialog} maxWidth="lg" fullWidth>
          {selectedCheck && (
            <>
              <DialogTitle>
                Details zu {selectedCheck?.name}
                <IconButton
                  aria-label="close"
                  onClick={handleCloseDialog}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                {verkaufsleiterChecks.length > 0 ? (
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Filiale</TableCell>
                          <TableCell>Anzahl Checks</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {verkaufsleiterChecks.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>{item.filialeName}</TableCell>
                            <TableCell>{item.count}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Typography>Keine Daten verfügbar.</Typography>
                )}
              </DialogContent>
            </>
          )}
        </Dialog>

        {/* FilialDetailDialog */}
        <FilialDetailDialog
          open={dialogOpen && !!selectedFiliale}
          onClose={handleCloseDialog}
          filiale={selectedFiliale}
          selectedTab={selectedTab}
          handleTabChange={handleTabChange}
          handleImageClick={handleImageClick}
        />

        {/* ImageDialog */}
        <ImageDialog
          open={imageDialogOpen}
          onClose={() => setImageDialogOpen(false)}
          imageUrl={imageUrl}
        />

        {/* Anzeige der Filialen in einer Tabelle */}
        <Grid item xs={12}>
          <Card
            sx={{
              padding: '0px',
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              backdropFilter: 'blur(10px)',
              mt: 4,
            }}
          >
            <CardHeader
              title={
                <Typography variant="h6" component="div">
                  Filialübersicht im {filterState.datum?.label || '...'}
                </Typography>
              }
            />
            <CardContent>
              {filialeData.length > 0 ? (
                <FilialTable
                  filialeData={filialeData}
                  expandedRows={expandedRows}
                  handleRowClick={handleRowClick}
                  handleOpenFilialeDialog={handleOpenFilialeDialog}
                />
              ) : (
                <Typography variant="body1">Keine Filial-Daten verfügbar.</Typography>
              )}
            </CardContent>
          </Card>
        </Grid>

      </Grid>
    </Layout>
  );
};

export default FilialcheckAnalyseManagementPage;
