// src/components/popup/unternehmenWoche/retoure/MainTablePopup.jsx

import React, { useEffect, useState } from 'react';
import Popup from '../../Popup';
import BigTableCard from '../../../card/BigTableCard';
import axiosInstance from '../../../../services/axiosInstance';
import MainTableArtikelPopup from './MainTableArtikelPopup'; // Import the second popup

// Helper function to format numbers with '.' as thousand separator and ',' as decimal separator
const formatNumber = (value, fractionDigits = 2) => {
  if (value === null || value === undefined) return '';
  return value
    .toFixed(fractionDigits) // Round to desired decimal places
    .replace('.', ',') // Replace decimal point with comma
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Insert thousand separators
};

const MainTablePopup = ({ open, onClose, filiale, filterState }) => {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);

  // State for the second popup
  const [artikelPopupOpen, setArtikelPopupOpen] = useState(false);
  const [selectedWarengruppe, setSelectedWarengruppe] = useState('');

  useEffect(() => {
    if (open) {
      fetchData();
    }
  }, [open]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const jahrKw = filterState.datumWoche?.value;

      // API call with Filiale and jahrKw filters
      const response = await axiosInstance.get('/scorecard_week/ware-warengruppe-woche/', {
        params: {
          filiale: filiale,
          jahrKw: jahrKw,
        },
      });

      // Data processing
      const popupData = response.data.results.map((item, index) => {
        // Extract retour quotes and format them
        const retourquote =
          item.retourQuote !== null && item.retourQuote !== undefined
            ? `${formatNumber(item.retourQuote * 100)}%`
            : '0,00%';

        const retourquoteA =
          item.retourQuoteA !== null && item.retourQuoteA !== undefined
            ? `${formatNumber(item.retourQuoteA * 100)}%`
            : '0,00%';

        const retourquoteBC =
          item.retourQuoteBC !== null && item.retourQuoteBC !== undefined
            ? `${formatNumber(item.retourQuoteBC * 100)}%`
            : '0,00%';

        // Format the values
        const retourwert = formatNumber(item.retourwert || 0);
        const lieferwert = formatNumber(item.lieferwert || 0);
        const korrekturwert = formatNumber(item.korrekturwert || 0);

        return {
          id: index,
          warengruppe: item.warengruppe || 'Unbekannt',
          retourquote,
          retourquoteA,
          retourquoteBC,
          retourwert,
          lieferwert,
          korrekturwert,
        };
      });

      // Optional: Filter entries with Retourquote > 0
      const filteredData = popupData.filter(
        (item) => parseFloat(item.retourquote.replace(',', '.')) > 0
      );

      const popupColumns = [
        { accessorKey: 'warengruppe', header: 'Warengruppe', align: 'left' },
        {
          accessorKey: 'retourquote',
          header: 'Retourquote',
          align: 'right',
        },
        {
          accessorKey: 'retourquoteA',
          header: 'Retourquote A',
          align: 'right',
        },
        {
          accessorKey: 'retourquoteBC',
          header: 'Retourquote BC',
          align: 'right',
        },
        {
          accessorKey: 'retourwert',
          header: 'Retourwert',
          align: 'right',
        },
        {
          accessorKey: 'lieferwert',
          header: 'Lieferwert',
          align: 'right',
        },
        {
          accessorKey: 'korrekturwert',
          header: 'Korrekturwert',
          align: 'right',
        },
      ];

      setData(filteredData);
      setColumns(popupColumns);
      setLoading(false);
    } catch (error) {
      console.error('Fehler beim Abrufen der Popup-Daten:', error);
      setLoading(false);
    }
  };

  // Handler when a Warengruppe row is clicked
  const handleRowClick = (row) => {
    const warengruppe = row.warengruppe;
    setSelectedWarengruppe(warengruppe);
    setArtikelPopupOpen(true);
  };

  return (
    <>
      <Popup
        open={open}
        title={`Warengruppen für Filiale ${filiale}`}
        onClose={onClose}
        fullWidth // Make the popup full width
        maxWidth={false} // Remove maxWidth constraint
      >
        <BigTableCard
          data={data}
          columns={columns}
          loading={loading}
          onRowClick={handleRowClick} // Handle row clicks
        />
      </Popup>

      {/* Second Popup */}
      {artikelPopupOpen && (
        <MainTableArtikelPopup
          open={artikelPopupOpen}
          onClose={() => setArtikelPopupOpen(false)}
          filiale={filiale}
          warengruppe={selectedWarengruppe}
          filterState={filterState}
        />
      )}
    </>
  );
};

export default MainTablePopup;
