import React from 'react';
import {Box, Typography} from '@mui/material';
import ThekeGroup from './ThekeGroup';
import ArtikelListe from './ArtikelListe';
import ThekeOverview from './ThekeOverview';

const ThekeContent = ({
                        showOverview,
                        filter,
                        warengruppen,
                        sectionCounts,
                        handleDrop,
                        selectedArtikel,
                        setFilter,
                        setShowOverview,
                        allArtikel,
                        sortimentType,
                        handleOpenArtikelDetail,
                        handleRemove,
                        filteredArtikel
                      }) => {
  return (
      <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
        <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
          {showOverview ? (
              <ThekeOverview
                  warengruppen={warengruppen}
                  sectionCounts={sectionCounts}
                  handleDrop={handleDrop}
                  selectedArtikel={selectedArtikel}
                  setFilter={setFilter}
                  setShowOverview={setShowOverview}
              />
          ) : filter ? (
              <Box>
                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                  <ThekeGroup
                      key={filter}
                      sectionCount={sectionCounts[filter] || 9}
                      onDrop={artikelData => handleDrop(filter, artikelData)}
                      sortimentType={sortimentType}
                      handleRemove={handleRemove}
                      selectedArtikel={filteredArtikel}
                  />
                </Box>
                <ArtikelListe
                    artikel={allArtikel.filter(artikel => artikel.warengruppennummer === filter)}
                    handleOpenArtikelDetail={handleOpenArtikelDetail}
                />
              </Box>
          ) : (
              <Box sx={{display: 'flex', justifyContent: 'center', width: '100%', mt: 4}}>
                <Typography variant="h6">
                  Bitte wählen Sie eine Warengruppe aus
                </Typography>
              </Box>
          )}
        </Box>
      </Box>
  );
};

export default ThekeContent; 