import axiosInstance from "../../services/axiosInstance";
import {Button, IconButton} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import React, {useContext, useRef} from "react";
import {InfoBarContext} from "../../provider/InfoBar";

const MAX_FILE_SIZE_MB = 10; // 10MB

const PictureUploadButton = ({title, afterImageUploadFunction, iconButton = true}) => {
  const {showError, showSuccess} = useContext(InfoBarContext);
  const fileInputRef = useRef(null);

  const handleUpload = () => {
    // Statt document.getElementById(...) nutzen wir nun ein Ref.
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (!file) {
      showError('Keine Datei ausgewählt');
      return;
    }

    if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
      showError(`Datei ist größer als ${MAX_FILE_SIZE_MB} MB`);
      return;
    }

    const formData = new FormData();
    formData.append('image', file);
    formData.append('title', title);

    try {
      // Schritt 1: Bild hochladen und image_id erhalten
      const response = await axiosInstance.post('/pictures/upload/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const image_id = response.data.image_id;

      if (afterImageUploadFunction) {
        await afterImageUploadFunction(image_id);
      }
      showSuccess('Bild erfolgreich hochgeladen');
    } catch (error) {
      console.error('Upload failed:', error);
      showError('Fehler beim Hochladen des Bildes');
    }
  };

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        style={{display: 'none'}}
        onChange={handleFileChange}
      />
      {iconButton ? (
        <IconButton color="primary" onClick={handleUpload} sx={{mb: 2}}>
          <CloudUploadIcon/>
        </IconButton>
      ) : (
        <Button
          onClick={handleUpload}
          variant="contained"
          color="primary"
          sx={{mb: 2}}
        >
          Bild hochladen
        </Button>
      )}
    </>
  );
}

export default PictureUploadButton;
