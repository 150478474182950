// Frontend - Overview.js

import React, { useState, useEffect } from 'react';
import {
  Box, Paper, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Collapse, IconButton, CircularProgress,
} from '@mui/material';
import Layout from '../../../components/layout/Planning/Planning';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import axiosInstance from '../../../services/axiosInstance';
import { format } from 'date-fns';
import deLocale from 'date-fns/locale/de';

// Import der Hilfsfunktionen
import {
  tausenderTrennung,
} from '../../../utils/mathStuff'; // Passen Sie den Pfad entsprechend an

const Overview = () => {
  const [data, setData] = useState([]);
  const [branchesData, setBranchesData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openRows, setOpenRows] = useState({});

  // Datenabruf für alle Monate mit kumulierten Planumsätzen
  const fetchMonthlyData = async () => {
    setLoading(true);
    setError(null);
    try {
      // API-Aufruf, um alle Monate mit kumulierten Planumsätzen zu erhalten
      const response = await axiosInstance.get(`/planzahlen/cumulative-planumsatz/`);
      const result = Array.isArray(response.data)
        ? response.data
        : response.data.results || [];
      setData(result);
    } catch (error) {
      console.error('Fehler beim Abrufen der Daten:', error);
      setData([]);
      setError('Fehler beim Abrufen der Daten.');
    } finally {
      setLoading(false);
    }
  };

  // Datenabruf für die Planumsätze der Filialen pro Monat
  const fetchBranchesData = async () => {
    try {
      // API-Aufruf, um die Planumsätze der Filialen zu erhalten
      const response = await axiosInstance.get(`/planzahlen/monatsumsatz/`);
      const result = Array.isArray(response.data)
        ? response.data
        : response.data.results || [];

      // Daten nach Jahr und Monat gruppieren und Filialen mit planumsatz = 0 herausfiltern
      const groupedData = {};
      result.forEach((item) => {
        const planumsatz = parseFloat(item.planumsatz) || 0;

        // Nur Filialen berücksichtigen, deren planumsatz nicht 0 ist
        if (planumsatz !== 0) {
          const dateObj = new Date(item.datum);
          const year = dateObj.getFullYear();
          const month = dateObj.getMonth() + 1; // Monate sind nullbasiert
          const key = `${year}-${month}`;

          if (!groupedData[key]) {
            groupedData[key] = [];
          }
          groupedData[key].push({
            filialnummer: item.filialnummer,
            filiale: item.filiale,
            planumsatz: planumsatz,
          });
        }
      });
      setBranchesData(groupedData);
    } catch (error) {
      console.error('Fehler beim Abrufen der Filialdaten:', error);
      setError('Fehler beim Abrufen der Filialdaten.');
    }
  };

  useEffect(() => {
    fetchMonthlyData();
    fetchBranchesData();
  }, []);

  // Handler zum Umschalten des Collapse pro Zeile
  const handleRowClick = (monthKey) => {
    setOpenRows((prevOpenRows) => ({
      ...prevOpenRows,
      [monthKey]: !prevOpenRows[monthKey],
    }));
  };

  return (
    <Layout>
      <Box sx={{
        flexGrow: 1, p: 3, maxWidth: 1200, margin: '0 auto',
        bgcolor: 'transparent'
      }}>
        <PageHeader
          title="Unternehmensübersicht"
          subtitle="Kumulierte monatliche Planumsätze aller Filialen."
          Icon={AnnouncementOutlinedIcon}
        />

        {/* Fehlermeldung */}
        {error && (
          <Box sx={{ color: 'red', mb: 2 }}>
            {error}
          </Box>
        )}

        {/* Haupttabelle */}
        {loading ? (
          <Box sx={{
            display: 'flex', justifyContent: 'center',
            alignItems: 'center', height: '50vh'
          }}>
            <CircularProgress />
          </Box>
        ) : data.length > 0 ? (
          <TableContainer component={Paper} sx={{ mt: 4 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Monat</TableCell>
                  <TableCell align="right">Kumulierter Planumsatz</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => {
                  const { year, month, total_planumsatz } = row;
                  const formattedDate = format(new Date(year, month - 1), 'MMMM yyyy', { locale: deLocale });
                  const planumsatz = parseFloat(total_planumsatz) || 0;
                  const monthKey = `${year}-${month}`;

                  return (
                    <React.Fragment key={monthKey}>
                      <TableRow>
                        <TableCell>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => handleRowClick(monthKey)}
                          >
                            {openRows[monthKey] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          </IconButton>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {formattedDate}
                        </TableCell>
                        <TableCell align="right">
                          {tausenderTrennung(planumsatz, 0)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                          <Collapse in={openRows[monthKey]} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                              {/* Untertabelle mit den Filialen */}
                              <Table size="small" aria-label="filialen">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Filialnummer</TableCell>
                                    <TableCell>Filiale</TableCell>
                                    <TableCell align="right">Planumsatz</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {branchesData[monthKey] && branchesData[monthKey].length > 0 ? (
                                    branchesData[monthKey].map((filiale) => (
                                      <TableRow key={filiale.filialnummer}>
                                        <TableCell component="th" scope="row">
                                          {filiale.filialnummer}
                                        </TableCell>
                                        <TableCell>{filiale.filiale}</TableCell>
                                        <TableCell align="right">
                                          {tausenderTrennung(filiale.planumsatz, 0)}
                                        </TableCell>
                                      </TableRow>
                                    ))
                                  ) : (
                                    <TableRow>
                                      <TableCell colSpan={3} align="center">
                                        Keine Daten verfügbar.
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </TableBody>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box sx={{ mt: 4 }}>
            Keine Daten gefunden.
          </Box>
        )}
      </Box>
    </Layout>
  );
};

export default Overview;
