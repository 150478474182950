import {Outlet, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import CircularIndeterminate from "../components/utility/Loading";
import axiosInstance from "../services/axiosInstance";
import {useAuth} from "../provider/authProvider";


export const ProtectedRoute = ({module, submodule, children}) => {
  const [loading, setLoading] = useState(true);
  const {getToken, refreshTokenExpired} = useAuth();
  const navigate = useNavigate();
  const token = getToken();

  useEffect(() => {
    async function checkPermission() {
      if (!token && !refreshTokenExpired) {
        return
      }

      setLoading(true)
      try {
        if (module) {
          const response = await axiosInstance.get('/auth/check-permission/', {params: {module, submodule}});
          const {data: {has_permission = false, detail = ''} = {}} = response;
          if (!has_permission) {
            console.log("Permission error:", detail);
          }
        }
      } catch (error) {
        console.error("Authentication error:", error);

        const status = error.response?.status;
        const detail = error.response?.data?.detail;
        console.log("Error:", error);
        console.log("Status:", status, "Detail:", detail);

        if (status === 401 && detail === "Invalid or expired auth token.") {
          console.log("ref to login-expired");
          navigate("/login-expired");
        } else if (status === 403 && detail === "Access denied") {
          console.log("ref to access-denied");
          navigate("/access-denied");
        } else {
          console.log("ref to general-error");
          navigate("/general-error");
        }
      } finally {
        setLoading(false)
      }
    }

    checkPermission();
  }, [module, submodule, token, refreshTokenExpired, navigate]);

  if (loading) {
    return <CircularIndeterminate/>;
  } else {
    return children ? children : <Outlet/>;
  }
};

export default ProtectedRoute;
