import axiosInstance from "../services/axiosInstance";
import {useAuth} from "../provider/authProvider";

const useLogin = () => {
    const {setToken, setRefreshTokenExpiredWithStorage, fetchUserData} = useAuth();

    const login = async (username, password) => {
        try {
            const response = await axiosInstance.post('/auth/login/', {username, password});
            setRefreshTokenExpiredWithStorage(false);
            // Set the access token in React state
            setToken(response.data.access);
            console.log("fetch data login")
            await fetchUserData()

            return {success: true};
        } catch (error) {
            console.error('Login failed:', error.message);
            let errorMessage = 'Login fehlgeschlagen.\n';
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                if (error.response.status === 401) {
                    errorMessage += 'Ungültiger Benutzername oder Passwort. Bitte versuchen Sie es erneut.';
                } else {
                    errorMessage += 'Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.';
                }
            } else if (error.request) {
                // The request was made but no response was received
                errorMessage += 'Keine Antwort vom Server erhalten. Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es erneut.';
            } else {
                // Something happened in setting up the request that triggered an Error
                errorMessage += 'Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.';
            }
            return {error: errorMessage};
        }
    };

    return {login};
};

export default useLogin;
