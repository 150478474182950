// BreadcrumbsNavigation.jsx
import React from 'react';
import { Breadcrumbs, Link, Typography } from '@mui/material';

const BreadcrumbsNavigation = ({
    selectedManual,
    selectedChapter,
    selectedEntry,
    handleBreadcrumbClick,
}) => {
    return (
        <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
            <Link
                underline="hover"
                color="inherit"
                href="#"
                onClick={(e) => handleBreadcrumbClick(e, 'manual')}
            >
                {selectedManual.title}
            </Link>
            {selectedChapter && (
                <Link
                    underline="hover"
                    color="inherit"
                    href="#"
                    onClick={(e) => handleBreadcrumbClick(e, 'chapter')}
                >
                    {selectedChapter.title || selectedChapter.label}
                </Link>
            )}
            {selectedEntry && <Typography color="textPrimary">{selectedEntry.title}</Typography>}
        </Breadcrumbs>
    );
};

export default BreadcrumbsNavigation;
