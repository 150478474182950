import React, {useState } from "react";
import {Box, Button, Dialog, DialogContent, DialogTitle, Typography, TextField, DialogActions} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import DropdownFilter from "../../../components/filter/DropdownFilter";
import axiosInstance from "../../../services/axiosInstance";
import {useAuth} from "../../../provider/authProvider"; // Import the popup component
import Tooltip from '@mui/material/Tooltip'; // Add this import
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const NutzerPopup = ({user, onClose, roleOptions, isCreating = false}) => {
    const { user: currentUser } = useAuth(); // Add this line to get the current user
    const [userData, setUserData] = useState(user || {
        username: '',
        email: '',
        first_name: '',
        last_name: '',
        role: null
    });
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [errors, setErrors] = useState({});
    const [deleteConfirmation, setDeleteConfirmation] = useState('');
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const validateForm = () => {
        const newErrors = {};
        if (!userData.username) newErrors.username = "Benutzername ist erforderlich";
        if (userData.username.includes(" ")) newErrors.username = "Benutzername darf keine Leerzeichen enthalten";
        if (isCreating) {
            if (!password) newErrors.password = "Passwort ist erforderlich";
            else if (password.length < 8) newErrors.password = "Passwort muss mindestens 8 Zeichen lang sein";
            if (password !== confirmPassword) newErrors.confirmPassword = "Passwörter stimmen nicht überein";
        }
        if (!userData.role) newErrors.role = "Rolle ist erforderlich";
        
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission
        if (!validateForm()) {
            setSnackbarMessage("Bitte füllen Sie alle erforderlichen Felder aus.");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        console.log("userData", userData)

        // Add this check
        if (!isCreating && user.id === currentUser.id && user.role.name !== userData.role.name) {
            setSnackbarMessage("Sie können Ihre eigene Rolle nicht ändern");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        try {
            const userDataToSend = {
                username: userData.username,
                email: userData.email,
                first_name: userData.first_name,
                last_name: userData.last_name,
                role_name: userData.role ? userData.role.name : null
            };

            if (isCreating) {
                userDataToSend.password = password;
                const response = await axiosInstance.post('/auth/users/', userDataToSend);
                console.log("User created successfully:", response.data);
                setSnackbarMessage("Benutzer erfolgreich erstellt");
            } else {
                const response = await axiosInstance.put(`/auth/users/${userData.id}/`, userDataToSend);
                console.log("User updated successfully:", response.data);
                setSnackbarMessage("Benutzer erfolgreich aktualisiert");
            }
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
            onClose(true);
        } catch (error) {
            console.error("Error updating/creating user:", error);
            handleErrorMessages(error.response?.data);
        }
    };

    const handleErrorMessages = (errorData) => {
        if (errorData) {
            if (errorData.password) {
                setErrors(prev => ({ ...prev, password: "Passwort: " + errorData.password.join(", ") }));
            }
            if (errorData.username) {
                setErrors(prev => ({ ...prev, username: "Ein Benutzer mit diesem Benutzernamen existiert bereits." }));
                setSnackbarMessage("Ein Benutzer mit diesem Benutzernamen existiert bereits.");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            }
            if (errorData.non_field_errors) {
                setSnackbarMessage(errorData.non_field_errors.join(", "));
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            }
        } else {
            setSnackbarMessage("Ein unerwarteter Fehler ist aufgetreten.");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        }
    };

    const handleDelete = async () => {
        if (deleteConfirmation !== userData.username) {
            setSnackbarMessage("Benutzername stimmt nicht überein. Löschvorgang abgebrochen.");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        try {
            await axiosInstance.delete(`/auth/users/${userData.id}/`);
            setSnackbarMessage("Benutzer erfolgreich gelöscht");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
            onClose(true);
        } catch (error) {
            console.error("Error deleting user:", error);
            setSnackbarMessage("Fehler beim Löschen des Benutzers");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        }
    };

    return (
        <Dialog open={true} onClose={() => onClose(false)} fullWidth maxWidth="sm">
            <DialogTitle>
                {isCreating ? "Neuen Nutzer erstellen" : "Nutzer bearbeiten"}
            </DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <TextField
                        label="Benutzername"
                        value={userData.username || ''}
                        onChange={(e) => setUserData({...userData, username: e.target.value})}
                        fullWidth
                        margin="normal"
                        error={!!errors.username}
                        helperText={errors.username}
                        disabled={!isCreating}
                        required
                        size="small"
                        autoComplete="username"
                    />
                    {isCreating && (
                        <>
                            <TextField
                                label="Passwort"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                fullWidth
                                margin="normal"
                                error={!!errors.password}
                                helperText={errors.password}
                                required
                                size="small"
                                autoComplete="new-password"
                            />
                            <TextField
                                label="Passwort bestätigen"
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                fullWidth
                                margin="normal"
                                error={!!errors.confirmPassword}
                                helperText={errors.confirmPassword}
                                required
                                size="small"
                                autoComplete="new-password"
                            />
                        </>
                    )}
                    <TextField
                        label="E-Mail"
                        value={userData.email || ''}
                        onChange={(e) => setUserData({...userData, email: e.target.value})}
                        fullWidth
                        margin="normal"
                        size="small"
                        autoComplete="email"
                    />  
                    <TextField
                        label="Vorname"
                        value={userData.first_name || ''}
                        onChange={(e) => setUserData({...userData, first_name: e.target.value})}
                        fullWidth
                        margin="normal"
                        size="small"
                        autoComplete="given-name"
                    />
                    <TextField
                        label="Nachname"
                        value={userData.last_name || ''}
                        onChange={(e) => setUserData({...userData, last_name: e.target.value})}
                        fullWidth
                        margin="normal"
                        size="small"
                        autoComplete="family-name"
                    />
                    <Box sx={{mt: 2}}>
                        <Tooltip title={!isCreating && user.id === currentUser.id ? "Sie können Ihre eigene Rolle nicht ändern" : ""}>
                            <span>
                                <DropdownFilter
                                    label="Rolle *"
                                    options={roleOptions}
                                    value={userData.role}
                                    onChange={(newValue) => setUserData({...userData, role: newValue})}
                                    error={!!errors.role}
                                    helperText={errors.role}
                                    sx={{mt: 1}}
                                    useNameAsLabel={true}
                                    disabled={!isCreating && user.id === currentUser.id}
                                />
                            </span>
                        </Tooltip>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ px: '16px' }}>
                    {!isCreating && (
                        <Button
                            onClick={() => setShowDeleteConfirmation(true)}
                            color="error"
                            startIcon={<DeleteIcon />}
                            sx={{
                                marginRight: 'auto',
                                marginLeft: '5px',
                                '&:hover': {
                                    backgroundColor: 'error.dark',
                                    color: 'error.contrastText',
                                },
                            }}
                        >
                            Löschen
                        </Button>
                    )}
                    <Button 
                        type="submit" 
                        color="primary"
                        sx={{
                            '&:hover': {
                                backgroundColor: 'primary.dark',
                                color: 'primary.contrastText',
                            },
                        }}
                    >
                        {isCreating ? "Erstellen" : "Aktualisieren"}
                    </Button>
                    <Button 
                        onClick={() => onClose(false)} 
                        color="secondary"
                        sx={{
                            '&:hover': {
                                backgroundColor: 'secondary.dark',
                                color: 'secondary.contrastText',
                            },
                        }}
                    >
                        Abbrechen
                    </Button>
                </DialogActions>
            </form>
            {showDeleteConfirmation && (
                <Box sx={{ p: 2, borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}>
                    <Typography variant="body2" color="error" gutterBottom>
                        Zum Löschen, geben Sie bitte den Benutzernamen "{userData.username}" ein:
                    </Typography>
                    <TextField
                        value={deleteConfirmation}
                        onChange={(e) => setDeleteConfirmation(e.target.value)}
                        fullWidth
                        margin="normal"
                        size="small"
                        placeholder="Benutzername zur Bestätigung"
                    />
                    <Button
                        onClick={handleDelete}
                        color="error"
                        variant="contained"
                        sx={{ mt: 1 }}
                    >
                        Benutzer löschen
                    </Button>
                </Box>
            )}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Dialog>
    );
};

export default NutzerPopup;
