import React from 'react';
import { Box, Grid, Stack, Typography, Paper, Switch, Button, IconButton, Card, CardHeader, CardContent, List, ListItem, ListItemText, Divider } from '@mui/material';
import Layout from '../../../components/layout/Qualität/Qualität';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import ChecklistIcon from '@mui/icons-material/Checklist';

// Action Icons
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

// Additional Icons
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SettingsIcon from '@mui/icons-material/Settings';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import InfoIcon from '@mui/icons-material/Info';
import AbcIcon from '@mui/icons-material/Abc';
import MenuIcon from '@mui/icons-material/Menu';
import CollectionsIcon from '@mui/icons-material/Collections';

// Newly added Icons
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import FilterOutlinedIcon from '@mui/icons-material/FilterOutlined';

const pillStyle = {
  borderRadius: '50px',
};

// Farben aus dem Theme
const colors = [
  { name: 'db_green', code: '#C3D98C' },
  { name: 'db_green_light', code: '#D1E69B' },
  { name: 'db_green_dark', code: '#B6CB83' },
  { name: 'db_olive', code: '#95A78D' },
  { name: 'db_olive_light', code: '#A0B79B' },
  { name: 'db_olive_dark', code: '#84967B' },
  { name: 'db_petrol', code: '#395D67' },
  { name: 'db_petrol_light', code: '#5E827B' },
  { name: 'db_petrol_dark', code: '#3A5B5D' },
  { name: 'db_grey', code: '#737373' },
  { name: 'db_grey_light', code: '#F5F5F5' },
  { name: 'db_grey_dark', code: '#575756' },
  { name: 'db_mint', code: '#E7EBE5' },
  { name: 'db_error', code: '#D03B56' },
  { name: 'db_warning', code: '#EFB358' },
  { name: 'db_success', code: '#63AC73' },
  { name: 'db_yellow', code: '#EFB258' },
  { name: 'db_orange', code: '#D48E3A' },
  { name: 'db_red', code: '#D05F3B' },
  { name: 'db_pink', code: '#CA6790' },
  { name: 'db_purple_light', code: '#74659C' },
  { name: 'db_purple_dark', code: '#4B568F' },
];

const ButtonRow = ({children, label, IconComp}) => (
  <Grid container spacing={2} alignItems="center" sx={{ mb:1 }}>
    <Grid item xs={12} md={6}>
      <Stack direction="row" spacing={2}>
        {children}
      </Stack>
    </Grid>
    <Grid item xs={12} md={6}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="body2">{label}</Typography>
        {IconComp && <IconComp sx={{color:'grey.600'}} />}
      </Stack>
    </Grid>
  </Grid>
);

const StandardActions = () => {
  return (
    <Paper sx={{ p: 2, mb:2 }}>
      <Typography variant="h6" sx={{ mb:2 }}>Übersicht der Standard-Buttons</Typography>
      
      <ButtonRow 
        label="Erstellen: Neuer Eintrag hinzufügen" 
        IconComp={AddIcon}
      >
        <Button variant="contained" startIcon={<AddIcon />} sx={pillStyle}>Erstellen </Button>
        <Button variant="outlined" startIcon={<AddIcon />} sx={pillStyle}>Erstellen </Button>
        <Button variant="text" startIcon={<AddIcon />} sx={pillStyle}>Erstellen </Button>
      </ButtonRow>

      <ButtonRow 
        label="Löschen: Einen bestehenden Eintrag entfernen" 
        IconComp={DeleteIcon}
      >
        <Button variant="contained" color="error" startIcon={<DeleteIcon />} sx={pillStyle}>Löschen </Button>
        <Button variant="outlined" color="error" startIcon={<DeleteIcon />} sx={pillStyle}>Löschen </Button>
        <Button variant="text" color="error" startIcon={<DeleteIcon />} sx={pillStyle}>Löschen </Button>
      </ButtonRow>

      <ButtonRow 
        label="Bearbeiten: Einen bestehenden Eintrag anpassen" 
        IconComp={EditIcon}
      >
        <Button variant="contained" startIcon={<EditIcon />} sx={pillStyle}>Bearbeiten</Button>
        <Button variant="outlined" startIcon={<EditIcon />} sx={pillStyle}>Bearbeiten </Button>
        <Button variant="text" startIcon={<EditIcon />} sx={pillStyle}>Bearbeiten </Button>
      </ButtonRow>

      <ButtonRow 
        label="Zusammenfassung: Eine Übersicht oder Reporting anzeigen" 
        IconComp={SummarizeOutlinedIcon}
      >
        <Button variant="contained" startIcon={<SummarizeOutlinedIcon />} sx={pillStyle}>Zusammenfassung </Button>
        <Button variant="outlined" startIcon={<SummarizeOutlinedIcon />} sx={pillStyle}>Zusammenfassung</Button>
        <Button variant="text" startIcon={<SummarizeOutlinedIcon />} sx={pillStyle}>Zusammenfassung </Button>
      </ButtonRow>

      <ButtonRow 
        label="Foto hochladen: Ein Bild/Foto in das System laden" 
        IconComp={PhotoCameraIcon}
      >
        <Button variant="contained" startIcon={<PhotoCameraIcon />} sx={pillStyle}> hochladen </Button>
        <Button variant="outlined" startIcon={<PhotoCameraIcon />} sx={pillStyle}> hochladen </Button>
        <Button variant="text" startIcon={<PhotoCameraIcon />} sx={pillStyle}> hochladen </Button>
      </ButtonRow>

      <ButtonRow 
        label="Alternative: Nur Icons für kompakte Aktionen" 
        IconComp={null}
      >
        <IconButton sx={pillStyle}><AddIcon sx={{color:'grey.600'}} /></IconButton>
        <IconButton sx={pillStyle}><DeleteIcon sx={{color:'grey.600'}} /></IconButton>
        <IconButton sx={pillStyle}><EditIcon sx={{color:'grey.600'}} /></IconButton>
        <IconButton sx={pillStyle}><SummarizeOutlinedIcon sx={{color:'grey.600'}} /></IconButton>
        <IconButton sx={pillStyle}><PhotoCameraIcon sx={{color:'grey.600'}} /></IconButton>
      </ButtonRow>
    </Paper>
  );
};

const IconRow = ({ IconComp, label, iconProps }) => (
  <Grid container spacing={2} alignItems="center" sx={{ mb:1 }}>
    <Grid item xs={1}>
      <IconComp {...iconProps} />
    </Grid>
    <Grid item xs={11}>
      <Typography variant="body2">{label}</Typography>
    </Grid>
  </Grid>
);

const AdditionalIcons = () => (
  <Paper sx={{ p:2, mb:2 }}>
    <Typography variant="h6" sx={{ mb:2 }}>Weitere Symbole & Aktionen</Typography>

    <IconRow IconComp={FullscreenIcon} label="Vollbildmodus aktivieren" />
    <IconRow IconComp={MoreVertIcon} label="Mehr Optionen anzeigen" />
    <IconRow IconComp={SettingsIcon} label="Konfiguration öffnen (Schraubenschlüssel)" />
    <IconRow IconComp={QueryStatsIcon} label="Tiefenanalyse (QueryStats)" />
    <IconRow IconComp={InfoIcon} label="Informationen anzeigen (Info i)" />
    <IconRow IconComp={AbcIcon} label="ABC Analyse" />
    <IconRow IconComp={MenuIcon} label="Menü öffnen" iconProps={{color:'primary'}} />
    <IconRow IconComp={CollectionsIcon} label="Galerie anzeigen" />

    {/* Icons previously under buttons */}
    <IconRow IconComp={AddIcon} label="Neuen Eintrag erstellen (Add)" />
    <IconRow IconComp={DeleteIcon} label="Eintrag löschen (Delete)" />
    <IconRow IconComp={EditIcon} label="Eintrag bearbeiten (Edit)" />
    <IconRow IconComp={SummarizeOutlinedIcon} label="Übersicht/Reporting anzeigen (Summarize)" />
    <IconRow IconComp={PhotoCameraIcon} label="Foto hochladen (PhotoCamera)" />

    <Grid container spacing={2} alignItems="center" sx={{ mt:2 }}>
      <Grid item>
        <Switch />
      </Grid>
      <Grid item>
        <Typography variant="body2">Einstellung aktivieren/deaktivieren (Switch Slider)</Typography>
      </Grid>
    </Grid>
  </Paper>
);

const AdditionalNewIcons = () => (
  <Paper sx={{ p:2, mb:2 }}>
    <Typography variant="h6" sx={{ mb:2 }}>Neu hinzugefügte Icons</Typography>

    <IconRow IconComp={AddAPhotoOutlinedIcon} label="Foto hinzufügen (AddAPhotoOutlined)" />
    <IconRow IconComp={CloudUploadOutlinedIcon} label="In die Cloud hochladen (CloudUploadOutlined)" />
    <IconRow IconComp={CloudDownloadOutlinedIcon} label="Aus der Cloud herunterladen (CloudDownloadOutlined)" />
    <IconRow IconComp={ArrowCircleRightOutlinedIcon} label="Weiter (ArrowCircleRightOutlined)" />
    <IconRow IconComp={BuildCircleOutlinedIcon} label="Konfiguration / Wartung (BuildCircleOutlined)" />
    <IconRow IconComp={BuildOutlinedIcon} label="Wartung (BuildOutlined)" />
    <IconRow IconComp={ExpandMoreOutlinedIcon} label="Mehr anzeigen (ExpandMoreOutlined)" />
    <IconRow IconComp={FilterListOutlinedIcon} label="Filterliste (FilterListOutlined)" />
    <IconRow IconComp={FilterOutlinedIcon} label="Filter (FilterOutlined)" />
  </Paper>
);

const ColorRow = ({name, code}) => (
  <Grid container spacing={2} alignItems="center" sx={{ mb:1 }}>
    <Grid item>
      <Box
        sx={{
          width: 24,
          height: 24,
          borderRadius: '50%',
          backgroundColor: code,
          border: '1px solid #ccc',
        }}
      />
    </Grid>
    <Grid item>
      <Typography variant="body2">{name} - {code}</Typography>
    </Grid>
  </Grid>
);

const ColorPalette = () => (
  <Paper sx={{ p:2 }}>
    <Typography variant="h6" sx={{ mb:2 }}>Farbpalette</Typography>
    {colors.map(color => (
      <ColorRow key={color.name} name={color.name} code={color.code} />
    ))}
  </Paper>
);

// Fake-Daten für die Liste
const fakeData = [
  { id: 1, name: 'Eintrag A', description: 'Beschreibung für A' },
  { id: 2, name: 'Eintrag B', description: 'Beschreibung für B' },
  { id: 3, name: 'Eintrag C', description: 'Beschreibung für C' },
];

const ListeMitCard = () => (
  <Paper sx={{ mb:4 }}>
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        backdropFilter: 'blur(10px)',
      }}
    >
      <CardHeader
        title={
          <Typography variant="h6" component="div">
            Beispiel-Liste
          </Typography>
        }
        action={
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            sx={{ borderRadius: '50px' }}
          >
            Neuen Eintrag hinzufügen
          </Button>
        }
      />
      <CardContent>
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {fakeData.map((item, index) => (
            <React.Fragment key={item.id}>
              <ListItem>
                <ListItemText
                  primary={item.name}
                  secondary={item.description}
                />
                <IconButton><EditIcon /></IconButton>
                <IconButton color="error"><DeleteIcon /></IconButton>
              </ListItem>
              {index < fakeData.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </CardContent>
    </Card>
  </Paper>
);

const Overview = () => {
  return (
    <Layout>
      <Box
        sx={{
          flexGrow: 1,
          p: 3,
          maxWidth: 1200,
          margin: '0 auto',
          bgcolor: 'transparent',
        }}
      >
        <PageHeader 
          title="Vorlagen" 
          subtitle="Verwalten Sie Ihre Qualitätschecks und konfigurieren Sie diese nach den Bedürfnissen des Unternehmens."
          Icon={ChecklistIcon}
        />

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ListeMitCard />
          </Grid>
          <Grid item xs={12}>
            <StandardActions />
          </Grid>
          <Grid item xs={12}>
            <AdditionalIcons />
          </Grid>
          <Grid item xs={12}>
            <AdditionalNewIcons />
          </Grid>
          <Grid item xs={12}>
            <ColorPalette />
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
};

export default Overview;
