// src/components/popup/unternehmenWoche/retoure/MainTableArtikelPopup.jsx

import React, { useEffect, useState } from 'react';
import Popup from '../../Popup';
import BigTableCard from '../../../card/BigTableCard';
import axiosInstance from '../../../../services/axiosInstance';

// Helper function to format numbers
const formatNumber = (value, fractionDigits = 2) => {
  if (value === null || value === undefined) return '';
  return value
    .toFixed(fractionDigits)
    .replace('.', ',')
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

const MainTableArtikelPopup = ({ open, onClose, filiale, warengruppe, filterState }) => {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      fetchData();
    }
  }, [open]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const jahrKw = filterState.datumWoche?.value;

      // API call with Filiale, Warengruppe, and jahrKw filters
      const response = await axiosInstance.get('/scorecard_week_article/ware-artikel-woche/', {
        params: {
          filiale: filiale,
          warengruppe: warengruppe,
          jahrKw: jahrKw,
        },
      });

      // Process data to calculate Retourquote and format values
      const nestedPopupData = response.data.results.map((item, index) => {
        // Calculate Retourquote
        const denominator = (item.lieferwert || 0) + (item.korrekturwert || 0);
        const retourquote = denominator > 0 ? (item.retourwert / denominator) * 100 : 0;

        // Format values
        const formattedRetourquote = `${formatNumber(retourquote)}%`;
        const retourwert = formatNumber(item.retourwert || 0);
        const lieferwert = formatNumber(item.lieferwert || 0);
        const korrekturwert = formatNumber(item.korrekturwert || 0);

        // Get ABC value
        const abc = item.ABCValue || '';

        return {
          id: index,
          artikel: item.artikelnummer.artikel || 'Unbekannt',
          abc: abc,
          retourquote: formattedRetourquote,
          retourwert: retourwert,
          lieferwert: lieferwert,
          korrekturwert: korrekturwert,
        };
      });

      // Define columns, including the new 'ABC' column
      const nestedPopupColumns = [
        { accessorKey: 'artikel', header: 'Artikel', align: 'left' },
        { accessorKey: 'abc', header: 'ABC', align: 'left' },
        {
          accessorKey: 'retourquote',
          header: 'Retourquote',
          align: 'right',
        },
        {
          accessorKey: 'retourwert',
          header: 'Retourwert',
          align: 'right',
        },
        {
          accessorKey: 'lieferwert',
          header: 'Lieferwert',
          align: 'right',
        },
        {
          accessorKey: 'korrekturwert',
          header: 'Korrekturwert',
          align: 'right',
        },
      ];

      setData(nestedPopupData);
      setColumns(nestedPopupColumns);
      setLoading(false);
    } catch (error) {
      console.error('Fehler beim Abrufen der verschachtelten Popup-Daten:', error);
      setLoading(false);
    }
  };

  return (
    <Popup
      open={open}
      title={`Artikel für Filiale ${filiale} und Warengruppe ${warengruppe}`}
      onClose={onClose}
      fullWidth // Make the popup full width
      maxWidth={false} // Remove maxWidth constraint
    >
      <BigTableCard data={data} columns={columns} loading={loading} />
    </Popup>
  );
};

export default MainTableArtikelPopup;
