import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, Stack } from '@mui/material';
import Layout from '../../../components/layout/Scorecards/Unternehmen/UnternehmenMonat';
import DataCard from '../../../components/card/scorecardsmonat/DataCardMonatPage';
import { FilterContext } from '../../../provider/FilterProvider';
import axiosInstance from '../../../services/axiosInstance';
import dayjs from 'dayjs';
import { calculatePerformanceData } from '../../../utils/calculationPerformance';

const Uebersicht = () => {
    const { filterState } = useContext(FilterContext);
    const [performanceData, setPerformanceData] = useState({
        umsatz: 0,
        umsatzVJ: 0,
        umsatzAbweichungVM: 0,
        umsatzAbweichung6M: 0,
        umsatzAbweichungVJ: 0,
        kundenanzahl: 0,
        kundenanzahlVJ: 0,
        kundenanzahlAbweichungVM: 0,
        kundenanzahlAbweichung6M: 0,
        kundenanzahlAbweichungVJ: 0,
        leistung: 0,
        leistungVJ: 0,
        kundenbon: 0,
        kundenbonVJ: 0,
        chartData: [],
    });
    const [performanceLast6Months, setPerformanceLast6Months] = useState([]); 
    const activeCategory = 'Umsatz';


    useEffect(() => {
        if (filterState.datum) {
            fetchPerformanceData();
            fetchPerformanceDataLast6Months();
        }
    }, [filterState]);

    const fetchPerformanceData = async () => {
        try {
            const response = await axiosInstance.get('/scorecards_month/performance-monat/', {
                params: {
                    jahrMonat: filterState.datum?.value,
                },
            });

            const allFilialenData = response.data.results;
            const {
                umsatzSumme, umsatzVJSumme, umsatzAbweichungVM, umsatzAbweichung6M, umsatzAbweichungVJ,
                kundenanzahlSumme, kundenanzahlVJSumme, kundenanzahlAbweichungVM, kundenanzahlAbweichung6M,
                kundenanzahlAbweichungVJ, leistung, leistungVJ, kundenbon, kundenbonVJ
            } = calculatePerformanceData(allFilialenData);

            setPerformanceData({
                umsatz: umsatzSumme,
                umsatzVJ: umsatzVJSumme,
                umsatzAbweichungVM,
                umsatzAbweichung6M,
                umsatzAbweichungVJ,
                kundenanzahl: kundenanzahlSumme,
                kundenanzahlVJ: kundenanzahlVJSumme,
                kundenanzahlAbweichungVM,
                kundenanzahlAbweichung6M,
                kundenanzahlAbweichungVJ,
                leistung,
                leistungVJ,
                kundenbon,
                kundenbonVJ,
                chartData: allFilialenData.map((item) => ({
                    umsatz: item.umsatz || 0,
                    kundenanzahl: item.kundenanzahl || 0,
                    leistung: item.leistung || 0,
                    kundenbon: item.kundenbon || 0,
                })),
            });
        } catch (error) {
            console.error('Error fetching performance data:', error);
        }
    };

    const getLast6Months = (currentMonth) => {
        const last6Months = [];
        const formattedMonth = dayjs(currentMonth, 'YYYY.MM');
        for (let i = 0; i < 6; i++) {
            last6Months.push(formattedMonth.subtract(i, 'month').format('YYYY.MM'));
        }
        return last6Months.reverse();
    };

    const fetchPerformanceDataLast6Months = async () => {
        const currentMonth = filterState.datum?.value || dayjs().format('YYYY.MM');
        const last6Months = getLast6Months(currentMonth);
        
        try {
            const responses = await Promise.all(
                last6Months.map(month => axiosInstance.get('/scorecards_month/performance-monat/', {
                    params: { jahrMonat: month },
                }))
            );
    
            const last6MonthsData = responses.map((response, index) => {
                const umsatzSumme = response.data.results
                    .filter(filiale => filiale.umsatz > 1)
                    .reduce((sum, filiale) => sum + (filiale.umsatz || 0), 0);
                const kundenanzahlSumme = response.data.results
                    .reduce((sum, filiale) => sum + (filiale.kundenanzahl || 0), 0);
                const produktivstundenSumme = response.data.results
                    .reduce((sum, filiale) => sum + (filiale.produktivstunden || 0), 0);
                
                const kundenbon = kundenanzahlSumme > 0 ? umsatzSumme / kundenanzahlSumme : 0;
                const leistung = produktivstundenSumme > 0 ? umsatzSumme / produktivstundenSumme : 0;

                return {
                    month: last6Months[index],
                    umsatz: umsatzSumme,
                    kundenanzahl: kundenanzahlSumme,
                    kundenbon: kundenbon,
                    leistung: leistung
                };
            });

            setPerformanceLast6Months(last6MonthsData);
        } catch (error) {
            console.error('Error fetching data for last 6 months:', error);
        }
    };

    return (
        <Layout>
            <Grid container justifyContent="flex-end" alignItems="stretch">
                <Grid item xs={12}>
                    <Stack direction="row" alignItems="stretch" spacing={2} sx={{ overflowX: 'auto' }}>
                    <Grid item xs={12} md={6} lg={3} className="db_datacard_not_active">
                    <DataCard
                                category="Umsatz"
                                value={performanceData.umsatz ? performanceData.umsatz.toFixed(2) : '0.00'}
                                subHeaders={[
                                    // `${performanceData.umsatzAbweichungVM.toFixed(2)}% zum Vormonat`,
                                    // `${performanceData.umsatzAbweichung6M.toFixed(2)}% zum 6 Monats Schnitt`,
                                    `${performanceData.umsatzAbweichungVJ.toFixed(2)}% zum Vorjahr`
                                ]}
                                chartData={performanceLast6Months.map(item => item.umsatz)}
                            />
                        </Grid>
                        
                        <Grid item xs={12} md={6} lg={3} className="db_datacard_not_active">
                            <DataCard
                                category="Kundenanzahl"
                                value={performanceData.kundenanzahl ? performanceData.kundenanzahl.toFixed(2) : '0.00'}
                                subHeaders={[
                                    // `${performanceData.kundenanzahlAbweichungVM.toFixed(2)}% zum Vormonat`,
                                    // `${performanceData.kundenanzahlAbweichung6M.toFixed(2)}% zum 6 Monats Schnitt`,
                                    `${performanceData.kundenanzahlAbweichungVJ.toFixed(2)}% zum Vorjahr`
                                ]}
                                chartData={performanceLast6Months.map(item => item.kundenanzahl)}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3} className="db_datacard_not_active">
                            <DataCard
                                category="Kundenbon"
                                value={performanceData.kundenbon ? performanceData.kundenbon.toFixed(2) : '0.00'}
                                subHeaders={[
                                    // `${performanceData.kundenbonVJ.toFixed(2)}€ im letzten Jahr`,
                                    `${((performanceData.kundenbon - performanceData.kundenbonVJ) / performanceData.kundenbonVJ * 100).toFixed(2)}% zum Vorjahr`
                                ]}
                                chartData={performanceLast6Months.map(item => item.kundenbon)}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3} className="db_datacard_not_active">
                            <DataCard
                                category="Leistung"
                                value={performanceData.leistung ? performanceData.leistung.toFixed(2) : '0.00'}
                                subHeaders={[
                                    // `${performanceData.leistungVJ.toFixed(2)} € im letzten Jahr`,
                                    `${((performanceData.leistung - performanceData.leistungVJ) / performanceData.leistungVJ * 100).toFixed(2)}% zum Vorjahr`
                                ]}
                                chartData={performanceLast6Months.map(item => item.leistung)}
                            />
                        </Grid>
                    </Stack>
                </Grid>
            </Grid>
        </Layout>
    );
};

export default Uebersicht;
