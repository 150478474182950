// CreateEntryDialog.jsx
import React, { useContext } from 'react';
import { Dialog, DialogTitle, DialogContent, Box, TextField, Button, Typography } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {InfoBarContext} from "../../../provider/InfoBar";

const CreateEntryDialog = ({
    open,
    onClose,
    newEntry,
    setNewEntry,
    handleCreateEntry,
    selectedChapter,
}) => {
    const { showError } = useContext(InfoBarContext);

    const handleSubmit = () => {
        if (!newEntry.title) {
            showError('Bitte geben Sie einen Titel für den Eintrag ein.');
            return;
        }
        if (!newEntry.content) {
            showError('Bitte geben Sie einen Inhalt für den Eintrag ein.');
            return;
        }
        handleCreateEntry();
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="md"
            PaperProps={{
                sx: { backgroundColor: '#f5f9f7' },
            }}
        >
            <DialogTitle>
                Neuen Eintrag erstellen in Kapitel "{selectedChapter ? selectedChapter.title : ''}"
            </DialogTitle>
            <DialogContent>
                <Box component="form" sx={{ mt: 2 }}>
                    <TextField
                        required
                        fullWidth
                        label="Titel"
                        value={newEntry.title}
                        onChange={(e) => setNewEntry({ ...newEntry, title: e.target.value })}
                        sx={{ mb: 2 }}
                    />
                    <Typography variant="body1" gutterBottom>
                        Inhalt
                    </Typography>
                    <ReactQuill
                        value={newEntry.content}
                        onChange={(content) => setNewEntry({ ...newEntry, content })}
                        modules={{
                            toolbar: [
                                [{ header: [1, 2, false] }],
                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                [{ list: 'ordered' }, { list: 'bullet' }],
                                ['link'],
                                ['clean'],
                            ],
                        }}
                    />
                    <Box sx={{ textAlign: 'right', mt: 2 }}>
                        <Button onClick={onClose} sx={{ mr: 1 }}>
                            Abbrechen
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleSubmit}>
                            Erstellen
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default CreateEntryDialog;
