// src/components/popup/unternehmenWoche/retoure/WarengruppeArtikelPopup.jsx

import React, { useEffect, useState } from 'react';
import Popup from '../../Popup';
import BigTableCard from '../../../card/BigTableCard';
import axiosInstance from '../../../../services/axiosInstance';
import dayjs from 'dayjs';

const WarengruppeArtikelPopup = ({ open, onClose, filiale, warengruppe, filterState }) => {
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (open) {
            fetchData();
        }
    }, [open]);

    const fetchData = async () => {
        try {
            setLoading(true);
            const [year, weekNumber] = filterState.datumWoche.value.split('.').map(Number);
            const weekStart = dayjs().year(year).isoWeek(weekNumber).startOf('isoWeek');
            const weekEnd = dayjs().year(year).isoWeek(weekNumber).endOf('isoWeek');

            // Erstellung der Tage der Woche
            const daysOfWeek = [];
            for (let i = 0; i < 7; i++) {
                daysOfWeek.push(weekStart.add(i, 'day').format('YYYY-MM-DD'));
            }

            // API-Abfrage mit Filiale- und Warengruppe-Filter
            const response = await axiosInstance.get('/fact/facts/', {
                params: {
                    filiale: filiale,
                    warengruppenbezeichnung: warengruppe,
                    startdatum: weekStart.format('YYYY-MM-DD'),
                    enddatum: weekEnd.format('YYYY-MM-DD'),
                },
            });

            // Gruppierung der Daten nach Artikel und Datum
            const dataByArtikel = {};

            response.data.results.forEach((item) => {
                const artikel = item.artikel || 'Unbekannt';
                const date = dayjs(item.datum).format('YYYY-MM-DD');

                if (!dataByArtikel[artikel]) {
                    dataByArtikel[artikel] = { artikel };
                }

                // Summiere die retourwert pro Artikel und Datum
                if (!dataByArtikel[artikel][date]) {
                    dataByArtikel[artikel][date] = 0;
                }

                dataByArtikel[artikel][date] += item.retourwert || 0;
            });

            // Berechnung der Totals und Formatierung
            const popupData = Object.values(dataByArtikel)
                .map(artikelData => {
                    let total = 0;
                    daysOfWeek.forEach(date => {
                        if (artikelData[date]) {
                            total += artikelData[date];
                            artikelData[date] = formatNumber(artikelData[date]);
                        } else {
                            artikelData[date] = formatNumber(0);
                        }
                    });
                    artikelData['Total'] = formatNumber(total);
                    return artikelData;
                })
                // Filtern der Artikel mit einem Gesamtwert > 0
                .filter(artikelData => {
                    const totalValue = parseFloat(artikelData['Total'].replace(/\./g, '').replace(',', '.'));
                    return totalValue > 0;
                });

            // Erstellung der Spalten für das verschachtelte Popup, inkl. Total
            const nestedPopupColumns = [
                { accessorKey: 'artikel', header: 'Artikel', align: 'left' },
                ...daysOfWeek.map((date) => ({
                    accessorKey: date,
                    header: dayjs(date).format('ddd DD.MM.'),
                    align: 'right',
                })),
                { accessorKey: 'Total', header: 'Total', align: 'right' }, // Hinzufügen der Total-Spalte
            ];

            setData(popupData);
            setColumns(nestedPopupColumns);
            setLoading(false);
        } catch (error) {
            console.error('Fehler beim Abrufen der verschachtelten Popup-Daten:', error);
            setLoading(false);
        }
    };

    // Hilfsfunktion zur Formatierung von Zahlen
    const formatNumber = (value, fractionDigits = 2) => {
        if (value === null || value === undefined) return '0,00';
        return value
            .toFixed(fractionDigits)
            .replace('.', ',')
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    };

    return (
        <Popup
            open={open}
            title={`Retourenwerte für Filiale ${filiale} und Warengruppe ${warengruppe}`}
            onClose={onClose}
            fullWidth // Make the popup full width
            maxWidth={false} // Remove maxWidth constraint
        >
            <BigTableCard data={data} columns={columns} loading={loading} />
        </Popup>
    );
};

export default WarengruppeArtikelPopup;
