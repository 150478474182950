import React, { useContext, useEffect, useState, useMemo } from 'react';
import {
  Box,
  Grid,
  Typography,
  Stack,
  Card,
  Paper,
  CardHeader,
  CardContent,
} from '@mui/material';
import Layout from '../../../components/layout/Scorecards/Unternehmen/UnternehmenMonat';
import DataCard from '../../../components/card/scorecardsmonat/DataCardMonatPage';
import { FilterContext } from '../../../provider/FilterProvider';
import axiosInstance from '../../../services/axiosInstance';
import dayjs from 'dayjs';
import VariableTreeMUI from '../../../components/table/VariableTableTreeMUI';
import { useTheme } from '@mui/material/styles';
import BigChartCardSecondYAxis from '../../../components/card/scorecardsmonat/BigChartCardSecondYAxis';
import BigChartCardRecharts from '../../../components/card/scorecardsmonat/BigChartCardRecharts';
import { tausenderTrennung } from '../../../utils/mathStuff';

const Auslastung = () => {
  const { filterState } = useContext(FilterContext);
  const [datumOptions, setDatumOptions] = useState([]);
  const theme = useTheme();
  const [personalData, setPersonalData] = useState({
    krankenquote: [],
    fluktuation: [],
  });
  const [tableData, setTableData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [lineChartData, setLineChartData] = useState([]);

  useEffect(() => {
    fetchDatumOptions();
  }, []);

  useEffect(() => {
    if (filterState.datum) {
      fetchPersonalData();
      fetchTableData();
    }
  }, [filterState]);

  const fetchDatumOptions = async () => {
    try {
      const response = await axiosInstance.get('/stammdaten/unique-jahr-monat/');
      setDatumOptions(
        response.data.map((item) => ({ label: item, value: item }))
      );
    } catch (error) {
      console.error('Error while fetching datum options:', error);
    }
  };

  const getLast6Months = (currentMonth) => {
    const last6Months = [];
    const formattedMonth = dayjs(currentMonth, 'YYYY.MM');

    for (let i = 0; i < 6; i++) {
      last6Months.push(formattedMonth.subtract(i, 'month').format('YYYY.MM'));
    }

    return last6Months.reverse();
  };

  const fetchPersonalData = async () => {
    const currentMonth = filterState.datum?.value || dayjs().format('YYYY.MM');
    const last6Months = getLast6Months(currentMonth);

    try {
      const responses = await Promise.all(
        last6Months.map((month) =>
          axiosInstance.get('/scorecards_month/personal-kennzahl/', {
            params: { jahrMonat: month },
          })
        )
      );

      // Debugging: Log each response
      responses.forEach((response, index) => {
        console.log(`Antwort für ${last6Months[index]}:`, response.data);
      });

      // Kumulieren der Krankenquote-Daten
      const krankenquoteData = responses.map((response, index) => {
        const data = response.data.results;

        const arbeitszeitKrankenqoute = data.reduce(
          (sum, item) => sum + (item.arbeitszeitKrankenqoute || 0),
          0
        );
        const krankenzeit = data.reduce(
          (sum, item) => sum + (item.krankenzeit || 0),
          0
        );
        const arbeitszeitKrankenqouteVM = data.reduce(
          (sum, item) => sum + (item.arbeitszeitKrankenqouteVM || 0),
          0
        );
        const krankenzeitVM = data.reduce(
          (sum, item) => sum + (item.krankenzeitVM || 0),
          0
        );
        const arbeitszeitKrankenqouteVJ = data.reduce(
          (sum, item) => sum + (item.arbeitszeitKrankenqouteVJ || 0),
          0
        );
        const krankenzeitVJ = data.reduce(
          (sum, item) => sum + (item.krankenzeitVJ || 0),
          0
        );

        // Berechne die Krankenquote
        const krankenquote =
          arbeitszeitKrankenqoute > 0
            ? (krankenzeit / arbeitszeitKrankenqoute) * 100
            : 0;
        const krankenquoteVM =
          arbeitszeitKrankenqouteVM > 0
            ? (krankenzeitVM / arbeitszeitKrankenqouteVM) * 100
            : 0;
        const krankenquoteVJ =
          arbeitszeitKrankenqouteVJ > 0
            ? (krankenzeitVJ / arbeitszeitKrankenqouteVJ) * 100
            : 0;

        // Debugging: Log calculated krankenquote
        console.log(`Krankenquote für ${last6Months[index]}:`, {
          arbeitszeitKrankenqoute,
          krankenzeit,
          krankenquote,
          arbeitszeitKrankenqouteVM,
          krankenzeitVM,
          krankenquoteVM,
          arbeitszeitKrankenqouteVJ,
          krankenzeitVJ,
          krankenquoteVJ,
        });

        return {
          month: last6Months[index],
          arbeitszeitKrankenqoute,
          krankenzeit,
          krankenquote,
          krankenquoteVM,
          krankenquoteVJ,
        };
      });

      // Kumulieren der Fluktuation-Daten
      const fluktuationData = responses.map((response, index) => {
        const data = response.data.results;

        const austritte6M = data.reduce(
          (sum, item) => sum + (item.austritte6M || 0),
          0
        );
        const mitarbeiterbestand6M = data.reduce(
          (sum, item) => sum + (item.mitarbeiterbestand6M || 0),
          0
        );
        const eintritte6M = data.reduce(
          (sum, item) => sum + (item.eintritte6M || 0),
          0
        );
        const köpfeDifferenz = data.reduce(
          (sum, item) => sum + (item.köpfeDifferenz || 0),
          0
        );
        const stundenDifferenz = data.reduce(
          (sum, item) => sum + (item.stundenDifferenz || 0),
          0
        );
        const überstundenAbs = data.reduce(
          (sum, item) => sum + (item.überstundenAbs || 0),
          0
        );
        const mitarbeiterbestand = data.reduce(
          (sum, item) => sum + (item.mitarbeiterbestand || 0),
          0
        );

        const auslastungPerson =
          mitarbeiterbestand > 0 ? überstundenAbs / mitarbeiterbestand : 0;

        // Berechne die Fluktuation
        const fluktuation =
          mitarbeiterbestand6M + eintritte6M > 0
            ? (austritte6M / (mitarbeiterbestand6M + eintritte6M)) * 100
            : 0;

        // Eintritte und Austritte im gefilterten Monat
        const eintritteM = data.reduce(
          (sum, item) => sum + (item.eintritteM || 0),
          0
        );
        const austritteM = data.reduce(
          (sum, item) => sum + (item.austritteM || 0),
          0
        );

        // Debugging: Log calculated fluktuation
        console.log(`Fluktuation für ${last6Months[index]}:`, {
          austritte6M,
          mitarbeiterbestand6M,
          eintritte6M,
          fluktuation,
          eintritteM,
          austritteM,
          köpfeDifferenz,
          stundenDifferenz,
          überstundenAbs,
          auslastungPerson,
          mitarbeiterbestand,
        });

        return {
          month: last6Months[index],
          fluktuation,
          eintritteM,
          austritteM,
          köpfeDifferenz,
          stundenDifferenz,
          überstundenAbs,
          auslastungPerson,
          mitarbeiterbestand,
        };
      });

      // Vorbereitung der Daten für das erste Diagramm (Stundendifferenz und Auslastung/Person)
      const chartData = [
        {
          id: 'Stundendifferenz',
          data: fluktuationData.map((item) => ({
            x: item.month,
            y: item.überstundenAbs,
          })),
        },
        {
          id: 'Auslastung/Person',
          data: fluktuationData.map((item) => ({
            x: item.month,
            y: item.auslastungPerson,
          })),
        },
      ];

      // Berechnung der Auslastung/Person pro Verkaufsgebiet für das zweite Diagramm
      const salesAreaAuslastungData = last6Months.map((month, index) => {
        const data = responses[index].data.results;

        const salesAreaGroup = {};

        data.forEach((item) => {
          const salesArea = item.verkaufsgebiet;
          if (!salesAreaGroup[salesArea]) {
            salesAreaGroup[salesArea] = {
              überstundenAbs: 0,
              mitarbeiterbestand: 0,
            };
          }
          salesAreaGroup[salesArea].überstundenAbs +=
            item.überstundenAbs || 0;
          salesAreaGroup[salesArea].mitarbeiterbestand +=
            item.mitarbeiterbestand || 0;
        });

        const salesAreaRates = {};
        Object.keys(salesAreaGroup).forEach((salesArea) => {
          const überstundenAbs = salesAreaGroup[salesArea].überstundenAbs;
          const mitarbeiterbestand = salesAreaGroup[salesArea].mitarbeiterbestand;
          const auslastungProPerson =
            mitarbeiterbestand > 0
              ? überstundenAbs / mitarbeiterbestand
              : null;
          salesAreaRates[salesArea] = {
            überstundenAbs,
            mitarbeiterbestand,
            auslastungProPerson,
          };
        });

        return {
          month,
          salesAreaRates,
        };
      });

      const salesAreas = new Set();
      salesAreaAuslastungData.forEach((monthData) => {
        Object.keys(monthData.salesAreaRates).forEach((salesArea) => {
          salesAreas.add(salesArea);
        });
      });

      const lineChartData = Array.from(salesAreas).map((salesArea) => {
        return {
          id: salesArea,
          data: salesAreaAuslastungData.map((monthData) => {
            const auslastungProPerson = monthData.salesAreaRates[salesArea]
              ? monthData.salesAreaRates[salesArea].auslastungProPerson
              : null;
            return {
              x: monthData.month,
              y: auslastungProPerson,
            };
          }),
        };
      });

      // Setzen des Zustands mit allen Daten
      setPersonalData((prevData) => ({
        ...prevData,
        krankenquote: krankenquoteData, // Hinzugefügt
        fluktuation: fluktuationData,
      }));

      setChartData(chartData);
      setLineChartData(lineChartData);
    } catch (error) {
      console.error('Fehler beim Abrufen der persönlichen Daten:', error);
    }
  };

  const fetchTableData = async () => {
    const currentMonth = filterState.datum?.value || dayjs().format('YYYY.MM');

    try {
      const response = await axiosInstance.get(
        '/scorecards_month/personal-kennzahl/',
        {
          params: { jahrMonat: currentMonth },
        }
      );

      const data = response.data.results;

      // Debugging: Log fetched data
      console.log('Abgerufene Tabellendaten:', data);

      const formattedTableData = data.map((item, index) => {
        const überstundenAbs = item.überstundenAbs || 0;
        const mitarbeiterbestand = item.mitarbeiterbestand || 0;

        const auslastungProPerson =
          mitarbeiterbestand > 0 ? überstundenAbs / mitarbeiterbestand : 0;

        return {
          id: `${item.verkaufsgebiet}-${item.filiale}-${index}`, // Generieren einer eindeutigen ID
          verkaufsgebiet: item.verkaufsgebiet,
          filiale: item.filiale,
          scoreÜberstunden: item.scoreÜberstunden || 0,
          überstundenAbs: überstundenAbs,
          mitarbeiterbestand: mitarbeiterbestand,
          auslastungProPerson: auslastungProPerson,
        };
      });

      // Debugging: Log formatted table data
      console.log('Formatierte Tabellendaten:', formattedTableData);

      setTableData(formattedTableData);
    } catch (error) {
      console.error('Fehler beim Abrufen der Tabellendaten:', error);
    }
  };

  // Definieren der Aggregationsfunktionen
  const aggregationFunctions = {
    überstundenAbs: (items) => {
      // Summe der überstundenAbs
      return items.reduce((sum, item) => sum + (item.überstundenAbs || 0), 0);
    },
    scoreÜberstunden: (items) => {
      // Durchschnitt der scoreÜberstunden
      const total = items.reduce(
        (sum, item) => sum + (item.scoreÜberstunden || 0),
        0
      );
      return items.length > 0 ? total / items.length : 0;
    },
    auslastungProPerson: (items) => {
      // Summe der überstundenAbs geteilt durch Summe der mitarbeiterbestand
      const totalÜberstundenAbs = items.reduce(
        (sum, item) => sum + (item.überstundenAbs || 0),
        0
      );
      const totalMitarbeiterbestand = items.reduce(
        (sum, item) => sum + (item.mitarbeiterbestand || 0),
        0
      );
      return totalMitarbeiterbestand > 0
        ? totalÜberstundenAbs / totalMitarbeiterbestand
        : 0;
    },
  };

  const columns = useMemo(
    () => [
      {
        field: 'verkaufsgebiet',
        headerName: 'Verkaufsgebiet',
        hide: false,
        align: 'left',
      },
      {
        field: 'filiale',
        headerName: 'Filiale',
        hide: false,
        align: 'left',
      },
      {
        field: 'scoreÜberstunden',
        headerName: 'Score',
        type: 'number',
        align: 'right',
        valueFormatter: (params) => tausenderTrennung(params.value, 2),
        hide: false,
      },
      {
        field: 'auslastungProPerson',
        headerName: 'Auslastung/Person',
        type: 'number',
        align: 'right',
        valueFormatter: (params) => tausenderTrennung(params.value, 2),
        hide: false,
      },
      {
        field: 'überstundenAbs',
        headerName: 'Stundendifferenz',
        type: 'number',
        align: 'right',
        valueFormatter: (params) => tausenderTrennung(params.value, 2),
        hide: false,
      },
    ],
    []
  );

  // Debugging: Log personalData
  console.log('Personal Data:', personalData);

  return (
    <Layout>
      <Grid container justifyContent="flex-end" alignItems="stretch">
        <Grid item xs={12}>
          <Stack
            direction="row"
            alignItems="stretch"
            spacing={2}
            sx={{ overflowX: 'auto' }}
          >
            {/* Krankenquote */}
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              className="db_datacard_not_active"
            >
              <DataCard
                category="Krankenquote"
                sector="personal"
                value={
                  personalData.krankenquote.length > 0
                    ? tausenderTrennung(
                        personalData.krankenquote[
                          personalData.krankenquote.length - 1
                        ].krankenquote,
                        2
                      ) + '%'
                    : '0.00%'
                }
                subHeaders={[
                  `${
                    personalData.krankenquote.length > 0
                      ? tausenderTrennung(
                          personalData.krankenquote[
                            personalData.krankenquote.length - 1
                          ].krankenquoteVM,
                          2
                        )
                      : '0.00'
                  }% zum Vormonat`,
                  `${
                    personalData.krankenquote.length > 0
                      ? tausenderTrennung(
                          personalData.krankenquote[
                            personalData.krankenquote.length - 1
                          ].krankenquoteVJ,
                          2
                        )
                      : '0.00'
                  }% zum Vorjahr`,
                ]}
                chartData={personalData.krankenquote.map(
                  (item) => item.krankenquote
                )}
              />
            </Grid>

            {/* Fluktuation */}
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              className="db_datacard_not_active"
            >
              <DataCard
                category="Fluktuation"
                sector="personal"
                value={
                  personalData.fluktuation.length > 0
                    ? tausenderTrennung(
                        personalData.fluktuation[
                          personalData.fluktuation.length - 1
                        ].fluktuation,
                        2
                      ) + '%'
                    : '0.00%'
                }
                subHeaders={[
                  `${
                    personalData.fluktuation.length > 0
                      ? personalData.fluktuation[
                          personalData.fluktuation.length - 1
                        ].eintritteM
                      : '0'
                  } Eintritte im gefilterten Monat`,
                  `${
                    personalData.fluktuation.length > 0
                      ? personalData.fluktuation[
                          personalData.fluktuation.length - 1
                        ].austritteM
                      : '0'
                  } Austritte im gefilterten Monat`,
                ]}
                chartData={personalData.fluktuation.map(
                  (item) => item.fluktuation
                )}
              />
            </Grid>

            {/* Bedarf */}
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              className="db_datacard_not_active"
            >
              <DataCard
                category="Bedarf"
                sector="personal"
                value={`${
                  personalData.fluktuation.length > 0
                    ? personalData.fluktuation[
                        personalData.fluktuation.length - 1
                      ].köpfeDifferenz
                    : '0'
                } Köpfe und ${
                  personalData.fluktuation.length > 0
                    ? personalData.fluktuation[
                        personalData.fluktuation.length - 1
                      ].stundenDifferenz
                    : '0'
                } Stunden`}
                chartData={personalData.fluktuation.map(
                  (item) => item.köpfeDifferenz
                )}
              />
            </Grid>

            {/* Auslastung */}
            <Grid item xs={12} md={6} lg={3} className="db_datacard_active">
              <DataCard
                category="Auslastung"
                sector="personal"
                value={`${
                  personalData.fluktuation.length > 0
                    ? tausenderTrennung(
                        personalData.fluktuation[
                          personalData.fluktuation.length - 1
                        ].überstundenAbs,
                        2
                      )
                    : '0'
                } Überstunden`}
                subHeaders={[]}
                chartData={personalData.fluktuation.map(
                  (item) => item.überstundenAbs
                )}
              />
            </Grid>
          </Stack>
        </Grid>

        {/* Liniendiagramme */}
        <Grid container mt={3} spacing={2}>
          {/* Erstes Diagramm mit zweiter Y-Achse */}
          <Grid item xs={12} sm={12} lg={6}>
            <BigChartCardSecondYAxis
              data={chartData}
              title="Stundendifferenz und Auslastung/Person der letzten 6 Monate"
            />
          </Grid>

          {/* Zweites Diagramm */}
          <Grid item xs={12} sm={12} lg={6}>
            <BigChartCardRecharts
              data={lineChartData}
              title="Auslastung/Person pro Verkaufsgebiet über die letzten 6 Monate"
            />
          </Grid>
        </Grid>

        {/* Tabelle */}
        <Grid item xs={12} md={12} mt={3}>
          <VariableTreeMUI
            columns={columns}
            data={tableData}
            title="Auslastung Übersicht"
            initialGrouping={['verkaufsgebiet']}
            groupingHeaders={{ 'verkaufsgebiet': 'Verkaufsgebiet' }} // Neue Prop zur Anpassung des Gruppierungs-Headers
            aggregationFunctions={aggregationFunctions}
            onRowClick={(row) => {
              // Handhabung des Klicks auf eine Zeile
              console.log('Zeile geklickt:', row);
            }}
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Auslastung;
