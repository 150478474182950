import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField
} from '@mui/material';

const PlanNamePopup = ({ open, onClose, onSave }) => {
  const [name, setName] = useState('');

  const handleSubmit = () => {
    onSave(name);
    setName(''); // Reset the input
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Plan Namen eingeben</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Plan Name"
          type="text"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Abbrechen</Button>
        <Button onClick={handleSubmit} disabled={!name.trim()}>
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PlanNamePopup; 