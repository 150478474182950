// FilialDetailDialog.jsx
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Tabs,
  Tab,
  Box,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  CircularProgress,
  Button,
  Stack,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import dayjs from 'dayjs';
import axiosInstance from '../../../services/axiosInstance';

const FilialDetailDialog = ({
  open,
  onClose,
  filiale,
  selectedTab,
  handleTabChange,
  handleImageClick,
}) => {
  const [checkData, setCheckData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [monthsOption, setMonthsOption] = useState(3);

  const displayRating = (ratingType, value, maxRating) => {
    if (ratingType === 'yesNo') {
      return value === 1 ? 'Ja' : value === 0 ? 'Nein' : 'Unbekannt';
    }
    return maxRating ? `${value} / ${maxRating}` : value;
  };

  useEffect(() => {
    if (open && filiale) {
      const fetchData = async () => {
        setLoading(true);
        try {
          const today = dayjs();
          const months = [];
          for (let i = 0; i < monthsOption; i++) {
            months.push(today.subtract(i, 'month').format('YYYY.MM'));
          }

          const params = {
            filialnummer: filiale.filialnummer,
            jahrMonat: months.join(','),
          };

          const response = await axiosInstance.get('/filialcheck/ratings/', { params });
          const ratings = response.data.results;
          const processedData = processRatingsData(ratings);
          setCheckData(processedData);
          setLoading(false);
        } catch (err) {
          console.error('Error fetching data:', err);
          setError('Fehler beim Laden der Daten.');
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [open, filiale, monthsOption]);

  const processRatingsData = (ratings) => {
    const checkMap = {};

    ratings.forEach((rating) => {
      const {
        check,
        checkpoint,
        checkpoint_name,
        category_id,
        category_name,
        session_id,
        datum,
        value,
        comment,
        image_id,
        rating_type,
        check_max,
      } = rating;

      if (!checkMap[check]) {
        checkMap[check] = {
          checkId: check,
          checkName: check,
          categories: {},
        };
      }
      const checkItem = checkMap[check];

      const catId = category_id || 'Unbekannt';
      if (!checkItem.categories[catId]) {
        checkItem.categories[catId] = {
          categoryId: catId,
          categoryName: category_name || 'Unbekannt',
          checkpoints: {},
        };
      }
      const cat = checkItem.categories[catId];

      if (!cat.checkpoints[checkpoint]) {
        cat.checkpoints[checkpoint] = {
          checkpointId: checkpoint,
          checkpointName: checkpoint_name,
          ratings: [],
          check_max,
        };
      }
      const cp = cat.checkpoints[checkpoint];

      cp.ratings.push({
        session_id,
        datum,
        value,
        comment,
        image_id,
        rating_type,
        check_max,
      });
    });

    return Object.values(checkMap);
  };

  if (loading) {
    return (
      <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  }

  if (error) {
    return (
      <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
        <DialogTitle>
          Fehler
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography color="error">{error}</Typography>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      {filiale && (
        <>
          <DialogTitle>
            Details zu {filiale?.filiale} ({filiale?.filialnummer})
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
              <Button
                variant={monthsOption === 3 ? 'contained' : 'outlined'}
                onClick={() => setMonthsOption(3)}
              >
                Letzte 3 Monate
              </Button>
              <Button
                variant={monthsOption === 6 ? 'contained' : 'outlined'}
                onClick={() => setMonthsOption(6)}
              >
                Letzte 6 Monate
              </Button>
            </Stack>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="Check Tabs"
            >
              {checkData.map((check, index) => (
                <Tab key={index} label={check.checkName} />
              ))}
            </Tabs>
            {checkData.map((check, index) => (
              <div
                role="tabpanel"
                hidden={selectedTab !== index}
                key={index}
              >
                {selectedTab === index && (
                  <Box sx={{ p: 3 }}>
                    {(() => {
                      const sessions = [];
                      const sessionSet = new Set();
                      Object.values(check.categories).forEach((category) => {
                        Object.values(category.checkpoints).forEach((checkpoint) => {
                          checkpoint.ratings.forEach((rating) => {
                            const sessionId = rating.session_id;
                            if (!sessionSet.has(sessionId)) {
                              sessionSet.add(sessionId);
                              const dateStr = dayjs(rating.datum).format('DD.MM.YYYY');
                              sessions.push({
                                sessionId,
                                dateStr,
                              });
                            }
                          });
                        });
                      });

                      sessions.sort((a, b) => {
                        const dateA = dayjs(a.dateStr, 'DD.MM.YYYY');
                        const dateB = dayjs(b.dateStr, 'DD.MM.YYYY');
                        if (dateA.isBefore(dateB)) return -1;
                        if (dateA.isAfter(dateB)) return 1;
                        return a.sessionId - b.sessionId;
                      });

                      const columnLabels = sessions.map((session) => `${session.dateStr} (ID ${session.sessionId})`);

                      const sortedCategories = Object.values(check.categories).sort((a, b) =>
                        a.categoryName.localeCompare(b.categoryName)
                      );

                      return sortedCategories.map((category, catIndex) => (
                        <Box key={catIndex} sx={{ mb: 2 }}>
                          <Typography variant="h6">{category.categoryName}</Typography>
                          <TableContainer component={Paper}>
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      maxWidth: 200,
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap'
                                    }}
                                  >
                                    Checkpoint
                                  </TableCell>
                                  {columnLabels.map((label, idx) => (
                                    <TableCell key={sessions[idx].sessionId} align="right">
                                      {label}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {Object.values(category.checkpoints).map((checkpoint, cpIndex) => {
                                  const sessionIdToRating = {};
                                  checkpoint.ratings.forEach((rating) => {
                                    sessionIdToRating[rating.session_id] = rating;
                                  });

                                  return (
                                    <TableRow key={cpIndex}>
                                      <TableCell
                                        sx={{
                                          maxWidth: 200,
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                          whiteSpace: 'nowrap'
                                        }}
                                      >
                                        {checkpoint.checkpointName}
                                      </TableCell>
                                      {sessions.map((session) => {
                                        const rating = sessionIdToRating[session.sessionId];
                                        return (
                                          <TableCell key={session.sessionId} align="right">
                                            {rating ? (
                                              <div>
                                                <Typography>
                                                  {displayRating(
                                                    rating.rating_type,
                                                    rating.value,
                                                    checkpoint.check_max
                                                  )}
                                                </Typography>
                                                {rating.comment && (
                                                  <Typography variant="body2" color="textSecondary">
                                                    {rating.comment}
                                                  </Typography>
                                                )}
                                                {rating.image_id && (
                                                  <IconButton
                                                    onClick={() => handleImageClick(rating.image_id)}
                                                    color="primary"
                                                  >
                                                    <PhotoLibraryIcon />
                                                  </IconButton>
                                                )}
                                              </div>
                                            ) : (
                                              <Typography>-</Typography>
                                            )}
                                          </TableCell>
                                        );
                                      })}
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      ));
                    })()}
                  </Box>
                )}
              </div>
            ))}
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default FilialDetailDialog;
