// components/popup/unternehmen/EmployeeDetailsPopup.js

import React, { useState, useEffect, useMemo } from 'react';
import { 
    Dialog, 
    DialogTitle, 
    DialogContent, 
    IconButton, 
    Typography, 
    Tabs, 
    Tab, 
    Box, 
    List, 
    ListItem, 
    ListItemText, 
    Paper, 
    Card, 
    CardContent, 
    CardHeader 
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import VariableTable from '../table/VariableTable';  // Importiere deine VariableTable-Komponente
import axiosInstance from '../../services/axiosInstance'; // Importiere axiosInstance für API-Aufrufe
import StackedBarChart from '../charts/StackedBarCharts';  // Importiere die StackedBarChart-Komponente
import BigTableCard from '../card/BigTableCard';  // Importiere die BigTableCard-Komponente
import dayjs from 'dayjs';
import { tausenderTrennung } from "../../utils/mathStuff";

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
};

const EmployeeDetailsPopup = ({ open, onClose, employee, jahrMonat }) => {
    const [tabIndex, setTabIndex] = useState(0);
    const [employeeDetails, setEmployeeDetails] = useState(null);
    const [workTimes, setWorkTimes] = useState([]);
    const [absences, setAbsences] = useState([]);
    const [chartAbsences, setChartAbsences] = useState([]);
    const [utilizationData, setUtilizationData] = useState([]);

    const handleChange = (event, newIndex) => {
        setTabIndex(newIndex);
    };

    useEffect(() => {
        if (open && employee) {
            fetchEmployeeDetails();
        }
    }, [open, employee]);

    useEffect(() => {
        if (open && employeeDetails) {
            fetchWorktimeAbsence();
            fetchAbsenceDataForChart();
            fetchUtilizationData();
        }
    }, [open, employeeDetails, jahrMonat]);

    // Funktion, um die letzten 13 Monate vom ausgewählten Monat rückwärts zu erhalten
    const getLast13Months = (selectedMonth) => {
        const months = [];
        const baseDate = dayjs(selectedMonth, 'YYYY.MM');
        for (let i = 12; i >= 0; i--) {
            months.push(baseDate.subtract(i, 'month').format('YYYY.MM'));
        }
        return months;
    };

    // Abrufen der Arbeits- und Abwesenheitszeiten
    const fetchWorktimeAbsence = async () => {
        try {
            const [anwesenheitenResponse, abwesenheitenResponse] = await Promise.all([
                axiosInstance.get(`/personaldaten/anwesenheiten/`, {
                    params: {
                        mitarbeiterId: employee.mitarbeiterId,
                        jahrMonat: jahrMonat,
                    }
                }),
                axiosInstance.get(`/personaldaten/abwesenheiten/`, {
                    params: {
                        mitarbeiterId: employee.mitarbeiterId,
                        jahrMonat: jahrMonat,
                    }
                })
            ]);

            // Runden der Anwesenheitsstunden und Formatieren des Datums
            const roundedWorkTimes = anwesenheitenResponse.data.results.map(item => ({
                ...item,
                anwesenheitsstunden: tausenderTrennung(parseFloat(item.anwesenheitsstunden).toFixed(2), 2),  // Formatierung anwenden
                datum: dayjs(item.datum).format('DD.MM.YYYY'),
            }));

            // Runden der Abwesenheitsstunden und Formatieren des Datums
            const roundedAbsences = abwesenheitenResponse.data.results.map(item => ({
                ...item,
                abwesenheitsstunden: tausenderTrennung(parseFloat(item.abwesenheitsstunden).toFixed(2), 2),  // Formatierung anwenden
                datum: dayjs(item.datum).format('DD.MM.YYYY'),
            }));

            setWorkTimes(roundedWorkTimes || []);
            setAbsences(roundedAbsences || []);
        } catch (error) {
            console.error("Fehler beim Abrufen der Arbeits- und Abwesenheitszeiten:", error);
            setWorkTimes([]);
            setAbsences([]);
        }
    };

    // Abrufen der Stammdaten des Mitarbeiters
    const fetchEmployeeDetails = async () => {
        try {
            const response = await axiosInstance.get(`/personaldaten/stammdaten-mitarbeiter/`, {
                params: {
                    mitarbeiterId: employee.mitarbeiterId,
                }
            });

            if (response.data && response.data.results.length > 0) {
                setEmployeeDetails(response.data.results[0]);
            } else {
                console.error('Keine Stammdaten gefunden.');
            }
        } catch (error) {
            console.error('Fehler beim Abrufen der Stammdaten:', error);
            setEmployeeDetails(null);
        }
    };

    // Abrufen der Abwesenheitsdaten für das Diagramm (13 Monate)
    const fetchAbsenceDataForChart = async () => {
        try {
            const last13Months = getLast13Months(jahrMonat);

            const [startDate, endDate] = [
                dayjs(jahrMonat, 'YYYY.MM').subtract(12, 'month').startOf('month').format('YYYY-MM-DD'),
                dayjs(jahrMonat, 'YYYY.MM').endOf('month').format('YYYY-MM-DD')
            ];

            const response = await axiosInstance.get(`/personaldaten/abwesenheiten/`, {
                params: {
                    mitarbeiterId: employee.mitarbeiterId,
                    startDate: dayjs(jahrMonat, 'YYYY.MM').subtract(12, 'month').startOf('month').format('YYYY-MM-DD'),
                    endDate: dayjs(jahrMonat, 'YYYY.MM').endOf('month').format('YYYY-MM-DD'),
                }
            });

            setChartAbsences(response.data.results || []);
        } catch (error) {
            console.error("Fehler beim Abrufen der Abwesenheitsdaten für das Diagramm:", error);
            setChartAbsences([]);
        }
    };

    // Abrufen der Auslastungsdaten (13 Monate)
    const fetchUtilizationData = async () => {
        try {
            const last13Months = getLast13Months(jahrMonat);
            const [startDate, endDate] = [
                dayjs(jahrMonat, 'YYYY.MM').subtract(12, 'month').startOf('month').format('DD.MM.YYYY'),
                dayjs(jahrMonat, 'YYYY.MM').endOf('month').format('DD.MM.YYYY')
            ];

            const [anwesenheitenResponse, abwesenheitenResponse] = await Promise.all([
                axiosInstance.get(`/personaldaten/anwesenheiten/`, {
                    params: {
                        mitarbeiterId: employee.mitarbeiterId,
                        startDate: dayjs(jahrMonat, 'YYYY.MM').subtract(12, 'month').startOf('month').format('YYYY-MM-DD'),
                        endDate: dayjs(jahrMonat, 'YYYY.MM').endOf('month').format('YYYY-MM-DD'),
                    }
                }),
                axiosInstance.get(`/personaldaten/abwesenheiten/`, {
                    params: {
                        mitarbeiterId: employee.mitarbeiterId,
                        startDate: dayjs(jahrMonat, 'YYYY.MM').subtract(12, 'month').startOf('month').format('YYYY-MM-DD'),
                        endDate: dayjs(jahrMonat, 'YYYY.MM').endOf('month').format('YYYY-MM-DD'),
                    }
                })
            ]);

            const months = last13Months;

            const utilizationData = months.map(month => {
                const anwesenheitsData = anwesenheitenResponse.data.results.filter(a => dayjs(a.datum).format('YYYY.MM') === month);
                const abwesenheitsData = abwesenheitenResponse.data.results.filter(a => dayjs(a.datum).format('YYYY.MM') === month);

                const anwesenheitsstunden = anwesenheitsData.reduce((sum, item) => sum + parseFloat(item.anwesenheitsstunden), 0);
                const abwesenheitsstunden = abwesenheitsData.reduce((sum, item) => sum + parseFloat(item.abwesenheitsstunden), 0);
                const sollzeit = (employeeDetails.wochensollBerechnet || 0) * 4.25;

                return {
                    month,
                    Name: employeeDetails?.name || '',
                    Sollzeit: tausenderTrennung(sollzeit.toFixed(2), 2),  // Formatierung direkt hier anwenden
                    Anwesenheiten: anwesenheitsstunden.toFixed(2),
                    Abwesenheiten: abwesenheitsstunden.toFixed(2),
                    Differenz: (sollzeit - (anwesenheitsstunden + abwesenheitsstunden)).toFixed(2),
                };
            });

            setUtilizationData(utilizationData);
        } catch (error) {
            console.error("Fehler beim Abrufen der Auslastungsdaten:", error);
            setUtilizationData([]);
        }
    };

    // Aggregieren der Abwesenheitsdaten nach Monat und Typ (nur >0 Stunden)
    const aggregateAbsencesByMonth = useMemo(() => {
        const last13Months = getLast13Months(jahrMonat);

        const aggregatedData = last13Months.map(month => {
            const monthlyData = chartAbsences.filter(absence => dayjs(absence.datum).format('YYYY.MM') === month);

            const aggregatedByType = monthlyData.reduce((acc, item) => {
                if (parseFloat(item.abwesenheitsstunden) > 0) {  // Nur Absence hours > 0
                    if (!acc[item.abwesenheitstyp]) {
                        acc[item.abwesenheitstyp] = 0;
                    }
                    acc[item.abwesenheitstyp] += parseFloat(item.abwesenheitsstunden);
                }
                return acc;
            }, {});

            // Runde hier die Abwesenheitsstunden auf 2 Nachkommastellen und konvertiere zurück in eine Zahl
            Object.keys(aggregatedByType).forEach(key => {
                aggregatedByType[key] = parseFloat(aggregatedByType[key].toFixed(2));
            });

            return { month, ...aggregatedByType };
        });

        return aggregatedData;
    }, [chartAbsences, jahrMonat]);

    // Erstellen der Daten für das Diagramm (nur Typen mit Gesamt > 0)
    const chartDataTransformed = useMemo(() => {
        const types = [...new Set(chartAbsences.map(a => a.abwesenheitstyp))];

        // Berechne das Gesamt pro Typ
        const totalPerType = types.reduce((acc, type) => {
            const total = aggregateAbsencesByMonth.reduce((sum, item) => sum + (item[type] || 0), 0);
            if (total > 0) {
                acc.push(type);
            }
            return acc;
        }, []);

        // Transformiere die Daten in das erwartete Format der StackedBarChart-Komponente
        const transformedData = totalPerType.map(type => ({
            label: type,
            data: aggregateAbsencesByMonth.map(item => item[type] || 0),
        }));

        // Filtere Typen mit mindestens einer Abwesenheitsstunde > 0
        return transformedData.filter(typeData => typeData.data.some(value => value > 0));
    }, [aggregateAbsencesByMonth, chartAbsences]);

    // Labels für die Monate
    const labels = useMemo(() => {
        return getLast13Months(jahrMonat);
    }, [jahrMonat]);

    // Spalten für die Tabelle (nur relevante Typen und Monate mit >0 Stunden)
    const tableColumns = useMemo(() => {
        const types = [...new Set(chartAbsences.map(a => a.abwesenheitstyp))];
        const totalPerType = types.reduce((acc, type) => {
            const total = aggregateAbsencesByMonth.reduce((sum, item) => sum + (item[type] || 0), 0);
            if (total > 0) {
                acc.push(type);
            }
            return acc;
        }, []);

        return [
            { accessorKey: 'type', header: 'Abwesenheitstyp' },
            ...labels.map((label, index) => ({
                id: `col-${index}`,
                accessorKey: label,
                header: label,
            }))
        ].filter(column => {
            if (column.accessorKey === 'type') return true;
            // Überprüfe, ob in dieser Spalte irgendein Wert >0 ist
            return chartDataTransformed.some(data => data.data[labels.indexOf(column.accessorKey)] > 0);
        });
    }, [labels, chartDataTransformed, chartAbsences, aggregateAbsencesByMonth]);

    // Daten für die Tabelle (nur Typen mit Gesamt > 0)
    const tableData = useMemo(() => {
        const types = [...new Set(chartAbsences.map(a => a.abwesenheitstyp))];

        // Berechne das Gesamt pro Typ
        const totalPerType = types.reduce((acc, type) => {
            const total = aggregateAbsencesByMonth.reduce((sum, item) => sum + (item[type] || 0), 0);
            if (total > 0) {
                acc.push(type);
            }
            return acc;
        }, []);

        return totalPerType.map(type => {
            const rowData = { type };
            labels.forEach((label, index) => {
                rowData[label] = aggregateAbsencesByMonth[index][type] || 0;
            });
            return rowData;
        });
    }, [chartDataTransformed, aggregateAbsencesByMonth, chartAbsences, labels]);

    // Spalten für die Auslastungstabelle
    const utilizationColumns = useMemo(() => {
        return [
            { id: 'month', accessorKey: 'month', header: 'Monat' },
            { id: 'Sollzeit', accessorKey: 'Sollzeit', header: 'Soll h' },
            { id: 'Anwesenheiten', accessorKey: 'Anwesenheiten', header: 'Anwesend h' },
            { id: 'Abwesenheiten', accessorKey: 'Abwesenheiten', header: 'Abwesend h' },
            { id: 'Differenz', accessorKey: 'Differenz', header: 'Diff. h' },
        ];
    }, []);

    // Spalten für die Arbeitszeit-Tabelle
    const workTimesColumns = useMemo(() => [
        { accessorKey: 'datum', header: 'Datum', cell: info => dayjs(info.getValue()).format('YYYY-MM-DD') },
        { accessorKey: 'arbeitsbereichKostenstelle', header: 'Filiale' },
        { accessorKey: 'anwesenheitsstunden', header: 'Anwesenheitsstunden h', cell: info => tausenderTrennung(info.getValue(), 2) },  // Anwenden der Formatierungsfunktion
    ], []);

    // Spalten für die Abwesenheitstabelle
    const absencesColumns = useMemo(() => [
        { accessorKey: 'datum', header: 'Datum', cell: info => dayjs(info.getValue()).format('YYYY-MM-DD') },
        { accessorKey: 'abwesenheitstyp', header: 'Abwesenheitstyp' },
        { accessorKey: 'abwesenheitsstunden', header: 'Abwesenheitsstunden h', cell: info => tausenderTrennung(info.getValue(), 2) },  // Anwenden der Formatierungsfunktion

    ], []);

    // Berechnungen für die Arbeitszeiten und Abwesenheiten
    const anwesenheitsstundenSumme = workTimes.reduce((sum, item) => sum + parseFloat(item.anwesenheitsstunden), 0);
    const abwesenheitsstundenSumme = absences.reduce((sum, item) => sum + parseFloat(item.abwesenheitsstunden), 0);
    const sollstunden = (employeeDetails?.wochensollBerechnet || 0) * 4.25;
    const delta = sollstunden - (anwesenheitsstundenSumme + abwesenheitsstundenSumme);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>
                Mitarbeiter: {employeeDetails?.name || 'Unbekannt'}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Tabs value={tabIndex} onChange={handleChange} aria-label="Employee Details Tabs">
                    <Tab label="Stammdaten" />
                    <Tab label="Arbeitszeiten" />
                    <Tab label="Abwesenheiten" />
                    <Tab label="Analyse" />
                    <Tab label="Auslastung" />
                </Tabs>
                <TabPanel value={tabIndex} index={0}>
                    <List>
                        <ListItem>
                            <ListItemText primary="Name" secondary={employeeDetails?.name || 'Nicht verfügbar'} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Arbeitsbereich" secondary={employeeDetails?.arbeitsbereich || 'Nicht verfügbar'} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Arbeitnehmertyp" secondary={employeeDetails?.arbeitnehmertyp || 'Nicht verfügbar'} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Wochensoll" secondary={employeeDetails?.wochensollBerechnet || 'Nicht verfügbar'} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Eintritt" secondary={employeeDetails?.eintritt ? dayjs(employeeDetails.eintritt).format('DD.MM.YYYY') : 'Nicht verfügbar'} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Austritt" secondary={employeeDetails?.austritt && dayjs(employeeDetails.austritt).isValid() ? dayjs(employeeDetails.austritt).format('DD.MM.YYYY') : '-'} />
                        </ListItem>
                    </List>
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    <Typography variant="subtitle1">
                        Im Monat {jahrMonat} hatte {employeeDetails?.name} insgesamt {anwesenheitsstundenSumme.toFixed(2)} Anwesenheitsstunden und {abwesenheitsstundenSumme.toFixed(2)} Abwesenheitsstunden. Die Sollstunden betragen {sollstunden.toFixed(2)}. Die Differenz beträgt damit {delta.toFixed(2)}.
                    </Typography>
                    <VariableTable
                        columns={workTimesColumns}
                        data={workTimes}
                    />
                </TabPanel>
                <TabPanel value={tabIndex} index={2}>
                    <Typography variant="subtitle1">
                        Im Monat {jahrMonat} hatte {employeeDetails?.name} insgesamt {anwesenheitsstundenSumme.toFixed(2)} Anwesenheitsstunden und {abwesenheitsstundenSumme.toFixed(2)} Abwesenheitsstunden. Die Sollstunden betragen {sollstunden.toFixed(2)}. Die Differenz beträgt damit {delta.toFixed(2)}.
                    </Typography>
                    <VariableTable
                        columns={absencesColumns}
                        data={absences}
                    />
                </TabPanel>
                <TabPanel value={tabIndex} index={3}>
                    <Paper>
                        <Card
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                padding: '0px',
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                backdropFilter: 'blur(10px)',
                            }}
                        >
                            <CardHeader
                                title={
                                    <Typography variant="h6" component="div">
                                        Abwesenheitsanalyse
                                    </Typography>
                                }
                            />
                            <CardContent sx={{ flexGrow: 1 }}>
                                {chartDataTransformed.length > 0 ? (
                                    <StackedBarChart
                                        data={chartDataTransformed}
                                        labels={labels}
                                        title="Abwesenheiten der letzten 13 Monate"
                                        xLabel="Monate"
                                        yLabel="Abwesenheitsstunden"
                                    />
                                ) : (
                                    <Typography variant="body2" color="text.secondary">
                                        Keine Abwesenheitsdaten verfügbar.
                                    </Typography>
                                )}
                                {tableData.length > 0 ? (
                                    <VariableTable
                                        columns={tableColumns}
                                        data={tableData}
                                    />
                                ) : (
                                    <Typography variant="body2" color="text.secondary">
                                        Keine Abwesenheitsdaten für die Tabelle verfügbar.
                                    </Typography>
                                )}
                            </CardContent>
                        </Card>
                    </Paper>
                </TabPanel>
                <TabPanel value={tabIndex} index={4}>
                    <BigTableCard
                        title="Auslastung der letzten 13 Monate"
                        data={utilizationData}
                        columns={utilizationColumns}
                    />
                </TabPanel>
            </DialogContent>
        </Dialog>
    );

};

export default EmployeeDetailsPopup;
