import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Grid, Stack, Paper, Card, CardHeader, Typography, CardContent } from '@mui/material';
import Layout from '../../../components/layout/Scorecards/Unternehmen/UnternehmenMonat';
import DataCard from '../../../components/card/scorecardsmonat/DataCardMonatPage';
import VariableTreeMUI from '../../../components/table/VariableTableTreeMUI'; // Aktualisierter Import
import { FilterContext } from '../../../provider/FilterProvider';
import axiosInstance from '../../../services/axiosInstance';
import dayjs from 'dayjs';
import { calculatePerformanceData } from '../../../utils/calculationPerformance';
import BigChartCard from "../../../components/card/scorecardsmonat/BigChartCardUnternehmen";
import BigChartCardSecondY from "../../../components/card/scorecardsmonat/BigChartCardSecondYAxis";
import BigPieChartCardTotal from '../../../components/card/scorecardsmonat/BigPieChartCardTotal';
import { tausenderTrennung } from '../../../utils/mathStuff';

const Kundenbon = () => {
    const { filterState } = useContext(FilterContext);
    const [allFilialenData, setAllFilialenData] = useState([]);
    const [filialtypData, setFilialtypData] = useState([]);

    const [performanceData, setPerformanceData] = useState({
        umsatz: 0,
        umsatzVJ: 0,
        umsatzAbweichungVJ: 0,
        kundenanzahl: 0,
        kundenanzahlVJ: 0,
        kundenanzahlAbweichungVJ: 0,
        kundenbon: 0,
        kundenbonVJ: 0,
        kundenbonAbweichungVJ: 0,
        leistung: 0,
        leistungVJ: 0,
        leistungAbweichungVJ: 0,
        chartData: [],
        verkaufsgebieteResult: [],
        filialenMitKundenbon: 0,
        filialtypenResult: [],
        durchschnittKundenbon: 0,
    });
    const [tableData, setTableData] = useState([]);
    const [performanceLastMonths, setPerformanceLastMonths] = useState([]);

    useEffect(() => {
        if (filterState.datum) {
            fetchPerformanceData();
            fetchPerformanceDataLastMonths();
        }
    }, [filterState]);

    const fetchScoreKundenbonData = async () => {
        try {
            const response = await axiosInstance.get('/scorecards_month/performance-monat/', {
                params: {
                    jahrMonat: filterState.datum?.value,
                },
            });
            return response.data.results;
        } catch (error) {
            console.error('Error fetching scoreKundenbon data:', error);
            return [];
        }
    };

    const fetchPerformanceData = async () => {
        try {
            // Abrufen der Daten von der API
            const response = await axiosInstance.get('/scorecards_month/performance-monat', {
                params: {
                    jahrMonat: filterState.datum?.value,
                },
            });

            // Abrufen der scoreKundenbon-Daten
            const scoreKundenbonData = await fetchScoreKundenbonData();

            // Setzen der gesamten Filialdaten in den State
            setAllFilialenData(response.data.results);

            // Berechnungen durchführen
            const calculatedData = calculatePerformanceData(response.data.results);

            // Anzahl der Filialen mit Kundenbon > 0 berechnen
            const filialenMitKundenbon = response.data.results.filter((filiale) => filiale.kundenbon > 0).length;

            // Durchschnittlicher Kundenbon berechnen
            const durchschnittKundenbon = filialenMitKundenbon > 0 ? calculatedData.kundenbon : 0;

            // Setzen der Performance-Daten
            setPerformanceData({
                umsatz: calculatedData.umsatzSumme,
                umsatzVJ: calculatedData.umsatzVJSumme,
                umsatzAbweichungVJ: calculatedData.umsatzAbweichungVJ,
                kundenanzahl: calculatedData.kundenanzahlSumme,
                kundenanzahlVJ: calculatedData.kundenanzahlVJSumme,
                kundenanzahlAbweichungVJ: calculatedData.kundenanzahlAbweichungVJ,
                kundenbon: calculatedData.kundenbon,
                kundenbonVJ: calculatedData.kundenbonVJ,
                kundenbonAbweichungVJ: calculatedData.kundenbonVJ > 0 ? ((calculatedData.kundenbon - calculatedData.kundenbonVJ) / calculatedData.kundenbonVJ) * 100 : 0,
                leistung: calculatedData.leistung,
                leistungVJ: calculatedData.leistungVJ,
                leistungAbweichungVJ: calculatedData.leistungVJ > 0 ? ((calculatedData.leistung - calculatedData.leistungVJ) / calculatedData.leistungVJ) * 100 : 0,
                chartData: response.data.results.map((item) => ({
                    kundenbon: item.kundenbon || 0,
                })),
                verkaufsgebieteResult: calculatedData.verkaufsgebieteResult,
                filialenMitKundenbon,
                durchschnittKundenbon,
                filialtypenResult: calculatedData.filialtypenResult,
            });

            // Tabelle formatieren und benötigte Felder hinzufügen
            const formattedTableData = response.data.results
                .filter(filiale => filiale.kundenbon > 0)
                .map((filiale, index) => {
                    const scoreData = scoreKundenbonData.find(item => item.filialnummer === filiale.filialnummer) || {};
                    return {
                        id: `${filiale.verkaufsgebiet}-${filiale.filialnummer}-${index}`,
                        filialnummer: filiale.filialnummer,
                        filiale: filiale.filiale,
                        verkaufsgebiet: filiale.verkaufsgebiet,
                        filialtyp: filiale.filialtyp,
                        umsatz: filiale.umsatz || 0,
                        umsatzVJ: filiale.umsatzVJ || 0,
                        kundenanzahl: filiale.kundenanzahl || 0,
                        kundenanzahlVJ: filiale.kundenanzahlVJ || 0,
                        kundenbon: filiale.kundenbon || 0,
                        kundenbonVJ: filiale.kundenbonVJ || 0,
                        kundenbonAbweichungVJ: filiale.kundenbonVJ > 0 ? ((filiale.kundenbon - filiale.kundenbonVJ) / filiale.kundenbonVJ) * 100 : 0,
                        score: scoreData.scoreKundenbon || 0, // Hier wird score hinzugefügt
                    };
                });

            setTableData(formattedTableData);

        } catch (error) {
            console.error('Error fetching performance data:', error);
        }
    };

    const getLastMonths = (currentMonth) => {
        const lastMonths = [];
        const formattedMonth = dayjs(currentMonth, 'YYYY.MM');
        for (let i = 0; i < 6; i++) {
            lastMonths.push(formattedMonth.subtract(i, 'month').format('YYYY.MM'));
        }
        return lastMonths.reverse();
    };

    const fetchPerformanceDataLastMonths = async () => {
        const currentMonth = filterState.datum?.value || dayjs().format('YYYY.MM');
        const lastMonths = getLastMonths(currentMonth);

        try {
            const responses = await Promise.all(
                lastMonths.map(month => axiosInstance.get('/scorecards_month/performance-monat/', {
                    params: { jahrMonat: month },
                }))
            );

            const lastMonthsData = responses.map((response, index) => {
                const kundenanzahlSumme = response.data.results
                    .reduce((sum, filiale) => sum + (filiale.kundenanzahl || 0), 0);

                const umsatzSumme = response.data.results
                    .reduce((sum, filiale) => sum + (filiale.umsatz || 0), 0);

                const kundenbon = kundenanzahlSumme > 0 ? umsatzSumme / kundenanzahlSumme : 0;

                const kundenanzahlVJSumme = response.data.results
                    .reduce((sum, filiale) => sum + (filiale.kundenanzahlVJ || 0), 0);

                const umsatzVJSumme = response.data.results
                    .reduce((sum, filiale) => sum + (filiale.umsatzVJ || 0), 0);

                const kundenbonVJ = kundenanzahlVJSumme > 0 ? umsatzVJSumme / kundenanzahlVJSumme : 0;

                // Leistung und Leistung VJ berechnen
                const produktivstundenSumme = response.data.results
                    .reduce((sum, filiale) => sum + (filiale.produktivstunden || 0), 0);

                const leistung = produktivstundenSumme > 0 ? umsatzSumme / produktivstundenSumme : 0;

                const produktivstundenVJSumme = response.data.results
                    .reduce((sum, filiale) => sum + (filiale.produktivstundenVJ || 0), 0);

                const leistungVJ = produktivstundenVJSumme > 0 ? umsatzVJSumme / produktivstundenVJSumme : 0;

                return {
                    month: lastMonths[index],
                    kundenbon: kundenbon,
                    kundenbonVJ: kundenbonVJ,
                    umsatz: umsatzSumme,
                    umsatzVJ: umsatzVJSumme,
                    kundenanzahl: kundenanzahlSumme,
                    kundenanzahlVJ: kundenanzahlVJSumme,
                    leistung: leistung,
                    leistungVJ: leistungVJ,
                };
            });

            setPerformanceLastMonths(lastMonthsData);
        } catch (error) {
            console.error('Error fetching data for last months:', error);
        }
    };

    // Daten für das Kundenbon-Diagramm
    const chartDataKundenbon = performanceLastMonths.flatMap((monthData) => [
        { x: monthData.month, y: monthData.kundenbon, label: 'aktuelles Jahr' },
        { x: monthData.month, y: monthData.kundenbonVJ, label: 'Vorjahr' }
    ]);

    // **Angepasste Daten für Umsatz und Kundenanzahl Diagramm**
    const chartDataUmsatzKundenanzahl = [
        {
            id: 'Umsatz',
            data: performanceLastMonths.map((item) => ({ x: item.month, y: item.umsatz })),
        },
        {
            id: 'Kundenanzahl',
            data: performanceLastMonths.map((item) => ({ x: item.month, y: item.kundenanzahl })),
        },
    ];

    // Daten für Leistung (für die DataCard)
    const leistungDataCard = {
        value: performanceData.leistung,
        abweichungVJ: performanceData.leistungAbweichungVJ,
        chartData: performanceLastMonths.map(item => item.leistung),
    };

    // Definition der Spalten für die Tabelle
    const columns = useMemo(
        () => [
            {
                field: 'verkaufsgebiet',
                headerName: 'Verkaufsgebiet',
                align: 'left',
                hide: true,
            },
            {
                field: 'filiale',
                headerName: 'Filiale',
                align: 'left',
                hide: false,
            },
            {
                field: 'score',
                headerName: 'Score',
                type: 'number',
                align: 'right',
                valueFormatter: (params) => tausenderTrennung(params.value, 2),
                hide: false,
            },
            {
                field: 'umsatz',
                headerName: 'Umsatz',
                type: 'number',
                align: 'right',
                valueFormatter: (params) => tausenderTrennung(params.value, 2),
                hide: false,
            },
            {
                field: 'kundenanzahl',
                headerName: 'Kundenanzahl',
                type: 'number',
                align: 'right',
                valueFormatter: (params) => tausenderTrennung(params.value, 0),
                hide: false,
            },
            {
                field: 'kundenbon',
                headerName: 'Kundenbon',
                type: 'number',
                align: 'right',
                valueFormatter: (params) => tausenderTrennung(params.value, 2),
                hide: false,
            },
            {
                field: 'kundenbonVJ',
                headerName: 'Kundenbon VJ',
                type: 'number',
                align: 'right',
                valueFormatter: (params) => tausenderTrennung(params.value, 2),
                hide: false,
            },
            {
                field: 'kundenbonAbweichungVJ',
                headerName: 'Kundenbon Abw. VJ',
                type: 'number',
                align: 'right',
                valueFormatter: (params) =>
                    params.value !== null
                        ? tausenderTrennung(params.value, 2) + '%'
                        : '0.00%',
                hide: false,
            },
        ],
        []
    );

    // Definition der Aggregationsfunktionen
    const aggregationFunctions = {
        score: (items) => {
            const totalScore = items.reduce((sum, item) => sum + (item.score || 0), 0);
            const count = items.length;
            return count > 0 ? totalScore / count : 0;
        },
        umsatz: (items) => {
            const totalUmsatz = items.reduce((sum, item) => sum + (item.umsatz || 0), 0);
            console.log('Aggregated Umsatz:', totalUmsatz);
            return totalUmsatz;
        },
        umsatzVJ: (items) => {
            const totalUmsatzVJ = items.reduce((sum, item) => sum + (item.umsatzVJ || 0), 0);
            console.log('Aggregated Umsatz VJ:', totalUmsatzVJ);
            return totalUmsatzVJ;
        },
        kundenanzahl: (items) => {
            const totalKundenanzahl = items.reduce((sum, item) => sum + (item.kundenanzahl || 0), 0);
            console.log('Aggregated Kundenanzahl:', totalKundenanzahl);
            return totalKundenanzahl;
        },
        kundenanzahlVJ: (items) => {
            const totalKundenanzahlVJ = items.reduce((sum, item) => sum + (item.kundenanzahlVJ || 0), 0);
            console.log('Aggregated Kundenanzahl VJ:', totalKundenanzahlVJ);
            return totalKundenanzahlVJ;
        },
        kundenbon: (items) => {
            const totalUmsatz = aggregationFunctions.umsatz(items);
            const totalKundenanzahl = aggregationFunctions.kundenanzahl(items);
            const kundenbon = totalKundenanzahl > 0 ? totalUmsatz / totalKundenanzahl : 0;
            console.log('Kundenbon Berechnung:', { totalUmsatz, totalKundenanzahl, kundenbon });
            return kundenbon;
        },
        kundenbonVJ: (items) => {
            const totalUmsatzVJ = aggregationFunctions.umsatzVJ(items);
            const totalKundenanzahlVJ = aggregationFunctions.kundenanzahlVJ(items);
            const kundenbonVJ = totalKundenanzahlVJ > 0 ? totalUmsatzVJ / totalKundenanzahlVJ : 0;
            console.log('Kundenbon VJ Berechnung:', { totalUmsatzVJ, totalKundenanzahlVJ, kundenbonVJ });
            return kundenbonVJ;
        },
        kundenbonAbweichungVJ: (items) => {
            const aggregatedKundenbon = aggregationFunctions.kundenbon(items);
            const aggregatedKundenbonVJ = aggregationFunctions.kundenbonVJ(items);
            console.log('Aggregated Kundenbon:', aggregatedKundenbon);
            console.log('Aggregated Kundenbon VJ:', aggregatedKundenbonVJ);
    
            const abweichung = aggregatedKundenbonVJ > 0
                ? ((aggregatedKundenbon - aggregatedKundenbonVJ) / aggregatedKundenbonVJ) * 100
                : 0;
    
            console.log('Kundenbon Abweichung VJ Berechnung:', {
                aggregatedKundenbon,
                aggregatedKundenbonVJ,
                abweichung,
            });
    
            return abweichung;
        },
    };
    

    // Berechnung von filialtypData bei Änderungen von allFilialenData
    useEffect(() => {
        if (allFilialenData.length > 0) {
            // Daten vorbereiten
            const filialtypData = allFilialenData.reduce((acc, filiale) => {
                const existing = acc.find(item => item.filialtyp === filiale.filialtyp);
                if (existing) {
                    existing.totalUmsatz += filiale.umsatz || 0;
                    existing.totalKundenanzahl += filiale.kundenanzahl || 0;
                } else {
                    acc.push({
                        filialtyp: filiale.filialtyp,
                        totalUmsatz: filiale.umsatz || 0,
                        totalKundenanzahl: filiale.kundenanzahl || 0,
                    });
                }
                return acc;
            }, []);

            // Kundenbon für jeden Filialtyp berechnen
            filialtypData.forEach(item => {
                const totalUmsatz = item.totalUmsatz || 0;
                const totalKundenanzahl = item.totalKundenanzahl || 0;

                item.kundenbon = totalKundenanzahl > 0 ? totalUmsatz / totalKundenanzahl : 0;
            });

            setFilialtypData(filialtypData);
        }
    }, [allFilialenData]);

    return (
        <Layout>
            <Grid container justifyContent="flex-end" alignItems="stretch" mt={3}>
                <Grid item xs={12}>
                    <Stack direction="row" alignItems="stretch" spacing={2} sx={{ overflowX: 'auto' }}>
                        {/* DataCards */}
                        <Grid item xs={12} md={6} lg={3} className="db_datacard_not_active">
                            <DataCard
                                category="Umsatz"
                                sector="performance"
                                value={performanceData.umsatz ? tausenderTrennung(performanceData.umsatz, 2) : '0.00'}
                                subHeaders={[
                                    `${tausenderTrennung(performanceData.umsatzAbweichungVJ, 2)}% zum Vorjahr`
                                ]}
                                chartData={performanceLastMonths.map(item => item.umsatz)}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3} className="db_datacard_not_active">
                            <DataCard
                                category="Kundenanzahl"
                                sector="performance"
                                value={performanceData.kundenanzahl ? tausenderTrennung(performanceData.kundenanzahl, 0) : '0'}
                                subHeaders={[
                                    `${tausenderTrennung(performanceData.kundenanzahlAbweichungVJ, 2)}% zum Vorjahr`
                                ]}
                                chartData={performanceLastMonths.map(item => item.kundenanzahl)}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3} className="db_datacard_active">
                            <DataCard
                                category="Kundenbon"
                                sector="performance"
                                value={performanceData.kundenbon ? tausenderTrennung(performanceData.kundenbon, 2) : '0.00'}
                                subHeaders={[
                                    `${tausenderTrennung(performanceData.kundenbonAbweichungVJ, 2)}% zum Vorjahr`
                                ]}
                                chartData={performanceLastMonths.map(item => item.kundenbon)}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3} className="db_datacard_not_active">
                            <DataCard
                                category="Leistung"
                                sector="performance"
                                value={performanceData.leistung ? tausenderTrennung(performanceData.leistung, 2) : '0.00'}
                                subHeaders={[
                                    `${tausenderTrennung(performanceData.leistungAbweichungVJ, 2)}% zum Vorjahr`
                                ]}
                                chartData={leistungDataCard.chartData}
                            />
                        </Grid>
                    </Stack>
                </Grid>

                <Grid container spacing={2} mt={3}>
                    {/* Linke Seite */}
                    <Grid item xs={12} lg={6}>
                        <Stack direction="column" alignItems="stretch" spacing={2} sx={{ overflowX: 'auto' }}>
                            {/* Umsatz und Kundenanzahl Chart */}
                            <BigChartCardSecondY
                                data={chartDataUmsatzKundenanzahl}
                                title="Umsatz und Kundenanzahl der letzten Monate"
                                height={300}
                            />

                            {/* PieChart für Filialtypen */}
                            {filialtypData && filialtypData.length > 0 && (
                                <BigPieChartCardTotal
                                    data={filialtypData}
                                    title="Kundenbon nach Filialtyp"
                                    metric="kundenbon"
                                />
                            )}
                        </Stack>
                    </Grid>

                    {/* Rechte Seite */}
                    <Grid item xs={12} lg={6}>
                        {/* Kundenbon Chart */}
                        <BigChartCard
                            data={chartDataKundenbon}
                            title="Kundenbon und Kundenbon Vorjahr der letzten Monate"
                        />
                    </Grid>
                </Grid>

                {/* Tabelle */}
                <Grid item xs={12} md={12} mt={3} sx={{ mt: 2 }}>
                <VariableTreeMUI
                                columns={columns}
                                data={tableData}
                                title="Kundenbon"
                                initialGrouping={['verkaufsgebiet']}
                                groupingHeaders={{ 'verkaufsgebiet': 'Verkaufsgebiet' }} // Neue Prop zur Anpassung des Gruppierungs-Headers
                                aggregationFunctions={aggregationFunctions}
                                onRowClick={(row) => {
                                    console.log('Zeile geklickt:', row);
                                }}
                            />
                </Grid>
            </Grid>
        </Layout>
    );
};

export default Kundenbon;
