import React from 'react';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {routes as dynamicRoutes} from './dynamicRoutes';
import {ProtectedRoute} from "./ProtectedRoute";
import loginAction from "../hooks/useLogin";
import LoginPage from "../pages/system/LoginPage";
import LogoutPage from "../pages/system/LogoutPage";
import Module from '../pages/system/Module';
import NotFoundPage from "../pages/system/NotFoundPage";
import AccessDenied from "../pages/system/AccessDenied";
import Experimental from "../pages/system/Experimental";
import LoginExpired from "../pages/system/LoginExpired";
import GeneralError from "../pages/system/GeneralError";
import Debugging from "../pages/system/Debugging";
import Landing from "../pages/system/Landing";
import ManualPage from "../pages/wiki/settings/ManualsPage";


const Routes = () => {
  // Define public routes accessible to all users
  const routesForPublic = [{
    path: "/login", element: <LoginPage/>, action: loginAction
  }, {
    path: "/access-denied", element: <AccessDenied/>,
  }, {
    path: "/experimental", element: <Experimental/>,
  }, {
    path: "/logout", element: <LogoutPage/>,
  }, {
    path: "/debugging", element: <Debugging/>,
  }, {
    path: "/landing", element: <Landing/>,
  }, {
    path: "/login-expired", element: <LoginExpired/>,
  }, {
    path: "/general-error", element: <GeneralError/>,
  }];

  const routesForAuthenticatedOnly = [{
    path: "/module", element: (
        <ProtectedRoute module={"modules"} submodule={"modules"}>
          <Module/>
        </ProtectedRoute>)
  }, {
    path: "/", element: (
        <ProtectedRoute module={"modules"} submodule={"modules"}>
          <Module/>
        </ProtectedRoute>)
  }, {
    path: "/wiki/settings/:manualSlug/:chapterSlug?/:entrySlug?", element: (
        <ProtectedRoute module={"wiki"} submodule={"settings"}>
          <ManualPage/>
        </ProtectedRoute>)
  }, {
    path: "/wiki/overview/:manualSlug/:chapterSlug?/:entrySlug?", element: (
        <ProtectedRoute module={"wiki"} submodule={"overview"}>
          <ManualPage/>
        </ProtectedRoute>)
  }];

  const combinedRoutes = [
    ...routesForPublic,
    ...routesForAuthenticatedOnly,
    ...dynamicRoutes.map((route) => ({
      path: route.path,
      element: (
          <route.component/>
      ),
    })),
    {path: "*", element: <NotFoundPage/>}  // Catch-all route for 404
  ];

  // Combine and conditionally include routes based on authentication status
  const router = createBrowserRouter(combinedRoutes);

  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router}/>;
};

export default Routes;
