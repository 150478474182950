import React from 'react';
import { Box, Button, ButtonGroup } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const SortimentTypeSelector = ({ sortimentType, setSortimentType }) => {
  const theme = useTheme();

  const buttonStyles = (type) => ({
    backgroundColor: sortimentType === type ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
    '&:hover': {
      backgroundColor: sortimentType === type ? 'rgba(0, 0, 0, 0.2)' : 'rgba(0, 0, 0, 0.1)',
    },
    boxShadow: 'none',
    color: 'black',
  });

  return (
    <ButtonGroup variant="contained" color="inherit" aria-label="sortiment type selection">
      <Button
        onClick={() => setSortimentType('')}
        sx={buttonStyles('')}
      >
        Alles
      </Button>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Button
          onClick={() => setSortimentType('standard')}
          sx={buttonStyles('standard')}
        >
          Standard Sortiment
        </Button>
        <Box sx={{ width: '100%', height: '2px', bgcolor: theme.palette.primary.light }}></Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Button
          onClick={() => setSortimentType('wechsel')}
          sx={buttonStyles('wechsel')}
        >
          Wechsel Sortiment
        </Button>
        <Box sx={{ width: '100%', height: '2px', bgcolor: theme.palette.background.mint }}></Box>
      </Box>
    </ButtonGroup>
  );
};

export default SortimentTypeSelector; 